import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SweetAlert from "sweetalert2";
import {
  faTable,
  faDownload,
  faSearch,
  faFilter,
  faPlus,
  faRefresh,
  faBan,
  faCog,
  faBars,
  faImage,
  faUpload,
  faBarcode,
} from "@fortawesome/free-solid-svg-icons";
import { getProdList, deleteProduct, getProductType, productExcel, download_product_template, bulk_upload_product } from "../../../api/index";
import {
  calMaxPage,
  calMaxPage_new,
  getLabels,
} from "../../../common/Functions/CommonFunctions";
import { Pagination, Spinner } from "react-bootstrap";
import { Eye, Trash2, Edit2, Copy, Edit } from "react-feather";
import { Modal } from "react-bootstrap";
import NavbarComp2 from "../../../Navbar/NavbarComp2";
import { Typeahead } from "react-bootstrap-typeahead";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";
import { Tooltip } from "react-tooltip";
import { useSelector } from "react-redux";
import { Product_Col_Name } from "../../../common/Labels/labelConstant";
import ExcelJS from "exceljs";
import List from "../../../assets/images/icons/project_menu/checklist.png"
import Slide from "../../../assets/images/icons/project_menu/collapse.png"
import Image from "../../../assets/images/dummyImg.png"
import { CONSUMABLE_ITEMS, FINISHED_GOODS, FIXTURES, GAUGES, GENERAL_ITEM, MANUFACTURED_GOODS, RAW_MATERIAL, REPORT, SEMI_FINISH_GOODS, TEMPLATE } from "../../../common/Constants/constants";
import BulkImport from "../../../assets/images/icons/project_menu/bulk_import.png";
import Check from "../../../assets/images/icons/project_menu/check.png";
import info from "../../../assets/images/info.png";
import { isExcelFile } from "../../../common/Functions/CommonFunctions";

function Product() {
  const [page, setPage] = useState(1);
  const [srNo, setSrNo] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [privilage, setPrivilage] = useState({
    read: true,
    write: true,
    view: true,
    delete: true,
    report: true,
   no_access: false
  });

  const { register, handleSubmit, formState, control, setValue, reset, watch } =
    useForm({
      mode: "onChange",
    });

  let { errors } = formState;

  const [lockBtn, setLockBtn] = useState(false);
  const [importData, setImportData] = useState(false);
  const [downloadTemplate, setDownloadTemplate] = useState(false);

  const [dataList, setDataList] = useState({
    data: [],
    loading: true,
    totalRecords: "",
  });
  const [search, setSearch] = useState({ value: "", status: false });
  const [filterConfig, setFilterConfig] = useState({
    productTypename: [],
    // productName: [],
    // partNo: [],
    search_val: "",
  });
  const [resett, setResett] = useState(false);
  const [prodType, setProdType] = useState({ data: [], loading: false });
  const loginData = useSelector(
    (state) => state.auth?.loginData?.data?.response
  );

  // const product_types = ["Manufactured Goods", "Raw material", "Semi Finish Goods", "Tools", "Fixtures", "Gauges"];
  const product_types = [MANUFACTURED_GOODS, FINISHED_GOODS, RAW_MATERIAL, SEMI_FINISH_GOODS, CONSUMABLE_ITEMS, FIXTURES, GAUGES];
  const [showLabel, setShowLabel] = useState(Product_Col_Name);
  const [constLabel, setConstLabel] = useState({});

  // State to track selected product IDs across pages
    const [selectedIds, setSelectedIds] = useState(new Set());
  
    // State to track all checked items separately
    const [checkedItems, setCheckedItems] = useState([]);

  let navigate = useNavigate();

  const prod_type = watch("type");

  useEffect(() => {
    // fetchProdList(page, entriesPerPage);

    localStorage.removeItem("prod_res");

    setConstLabel(getLabels("Quality", "Product"));
    fetchProductType();
    let isUserAdmin;

    // storing modules access got in login in state

    if (
      loginData.role_name.trim() == "admin" ||
      loginData.role_name.trim() == "client_admin"
    ) {
      isUserAdmin = true;
    } else {
      isUserAdmin = false;
    }

    if (isUserAdmin) {
      setPrivilage({
        read: true,
        write: true,
        view: true,
        delete: true,
        report: true,
      });
    
    } else {
      for (let i = 0; i < loginData.module_list.length; i++) {
        if (loginData.module_list[i].module == "Product") {
          setPrivilage(loginData.module_list[i]);
        } else {
          for (let i = 0; i < loginData.module_list.length; i++) {
            if (loginData.module_list[i].module == "Product") {
              setPrivilage(loginData.module_list[i]);
            } else {
              if (loginData.module_list[i].screens !== undefined) {
                for (
                  let j = 0;
                  j < loginData.module_list[i].screens.length;
                  j++
                ) {
                  if (
                    loginData.module_list[i].screens[j].screen_name == "Product"
                  ) {
                    setPrivilage(loginData.module_list[i]);
                  }
                }
              }
            }
          }
        }
      }
    }
  }, []);

  function fetchProductType() {
    setProdType({ ...prodType, data: [], loading: true });
    getProductType().then(
      (res) => {
        const result = res.data.data.map((item) => {
          if (item.productTypename !== null && item.productTypename !== "") {
            return item;
          }
        });

        setProdType({ ...prodType, data: result, loading: false });
      },
      (err) => {
        setProdType({ ...prodType, data: [], loading: false });
      }
    );
  }

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      setPage(1);
      fetchProdList(1, entriesPerPage);
    }, 1000); // Adjust the delay as needed (e.g., 500 milliseconds)

    return () => {
      clearTimeout(debounceTimeout); // Clear the timeout if searchTerm changes before the timeout expires
    };
  }, [filterConfig]);

  function fetchProdList(pg, epp) {
    setDataList({ ...dataList, loading: true, data: [] });

    const payload = {
      pageNo: pg,
      entriesPerPage: epp,
    };

    if (filterConfig.search_val != "") {
      // payload.searchVal = filterConfig.search_val;
      payload.searchVal = filterConfig.search_val.trim(); //Search by product name / part no

      // setSrNo(0)
    } else {
      delete payload.searchVal;
    }

    if (filterConfig.productTypename.length > 0) {
      payload.product_type_id_list = JSON.stringify(
        filterConfig.productTypename.map((item) => item.uuid)
      );
      // setSrNo(0)
    } else {
      delete payload.product_type_id_list;
    }

    getProdList(payload).then(
      (res) => {
        setDataList({
          ...dataList,
          loading: false,
          data: res.data.data,
          totalRecords: res.data.total,
        });
        setMaxPage(calMaxPage_new(res.data.total, epp));
        if (pg == 1) {
          setSrNo(0);
          setPage(1);
        }
      },
      (err) => {
        setDataList({
          ...dataList,
          loading: false,
          data: [],
        });
      }
    );
  }

  function openNav() {
    document.getElementById("mySidebar").style.width = "320px";
    document.getElementById("root_div_main").style.marginLeft = "320px";
    setTimeout(() => {
      document.getElementById("mySidebar").style.overflowX = "visible";
    }, [200]);
  }

  function closeNav() {
    document.getElementById("mySidebar").style.width = "0";
    document.getElementById("root_div_main").style.marginLeft = "0";

    document.getElementById("mySidebar").style.overflowX = "hidden";
  }

  function delProduct(val) {
    SweetAlert.fire({
      title: "Are you sure to delete product ? \n",
      // text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        const payload = { uuid: val.uuid };
        deleteProduct(payload).then(
          (res) => {
            toast.success("Product deleted successfully", {
              autoClose: 2000,
            });
            if (dataList.data.length == 1 && page > 1) {
              fetchProdList(page - 1, entriesPerPage);
              setSrNo(parseInt(srNo - entriesPerPage));
              setPage(page - 1);
            } else {
              fetchProdList(page, entriesPerPage);
            }
          },
          (err) => {
            toast.error("Something went wrong", {
              autoClose: 2000,
            });
          }
        );
      }
    });
  }

  function clearFilter() {
    setFilterConfig({
      ...filterConfig,
      productTypename: [],
      // productName: [],
      // partNo: [],
      search_val: "",
    });
    setSrNo(0);
    setPage(1);
    fetchProductType();
  }

  function handleClick(ind) {
    setShowLabel((showLabel) => {
      const updatedCardShow = showLabel.map((val, i) => {
        if (ind === i) {
          // Toggle the flag for the clicked checkbox
          return { ...val, flag: !val.flag };
        }
        return val;
      });

      return updatedCardShow;
    });
  }

  async function goToDownload() {
    setLockBtn(true);

    const payload = {};

    if (filterConfig.search_val != "") {
      // payload.searchVal = filterConfig.search_val;
      payload.searchVal = filterConfig.search_val.trim(); //Search by product name / part no

      // setSrNo(0)
    } else {
      delete payload.searchVal;
    }

    if (filterConfig.productTypename.length > 0) {
      payload.product_type_id_list = JSON.stringify(
        filterConfig.productTypename.map((item) => item.uuid)
      );
      // setSrNo(0)
    } else {
      delete payload.product_type_id_list;
    }

    await productExcel(payload).then(
      (res) => {
        //
        if (res) {
          // excel_download(res.data.data);
          base64ToExcel(res.data, REPORT);

        }
      },
      (err) => {
        toast.error(err.message, {
          autoClose: 2000,
        });
      }
    );
  }

  const excel_download = async (excel_data) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet 1");

    const keyTitleMap = [
      "Sr No",
      "Product Id",
      "Product Type",
      "Part No",
      "Category",
      "Name",
      "Drawingno",
      "OEMnumber",
      "Party",
      "Remark",
      "Machine Cost",
      "Product Family",
      "Sub Category",
      "Sales Price",
      "Material",
      "Diameter in mm",
      "Cut Length in mm",
      "Cut Weight",
      "Wt. per kg  per Mtr",
      "Wt. Per Piece",
      "Finish Length",
      "CNC Weight",
      "HT Weight",
      "Finish Weight",
      "C/S Non Regular",
    ];

    const flattenedData = excel_data.map((item, index) => {
      return [
        index + 1,
        item.productId == "" || item.productId == null ? "NA" : item.productId,
        item.product_type_id?.productType == "" ||
        item.product_type_id?.productType == null
          ? "NA"
          : item.product_type_id?.productType,
        item.partNo == "" || item.partNo == null ? "NA" : item.partNo,
        item.category == "" || item.category == null ? "NA" : item.category,
        item.productName == "" || item.productName == null
          ? "NA"
          : item.productName,
        item.drawingno == "" || item.drawingno == null ? "NA" : item.drawingno,
        item.OEMnumber == "" || item.OEMnumber == null ? "NA" : item.OEMnumber,
        item.partyName == "" || item.partyName == null ? "NA" : item.partyName,
        item.remark == "" || item.remark == null ? "NA" : item.remark,
        item.machineCost == "" || item.machineCost == null
          ? "NA"
          : item.machineCost,
        item.prod_family_id?.familyname == "" ||
        item.prod_family_id?.familyname == null
          ? "NA"
          : item.prod_family_id?.familyname,
        item.subcategory == "" || item.subcategory == null
          ? "NA"
          : item.subcategory,
        item.salesPrice == "" || item.salesPrice == null
          ? "NA"
          : item.salesPrice,
        item.material == "" || item.material == null ? "NA" : item.material,
        item.diameter == "" || item.diameter == null ? "NA" : item.diameter,
        item.cutlength == "" || item.cutlength == null ? "NA" : item.cutlength,
        item.cutweight == "" || item.cutweight == null ? "NA" : item.cutweight,
        item.perKgWeight == "" || item.perKgWeight == null
          ? "NA"
          : item.perKgWeight,
        item.perPieceWeight == "" || item.perPieceWeight == null
          ? "NA"
          : item.perPieceWeight,
        item.finishLenght == "" || item.finishLenght == null
          ? "NA"
          : item.finishLenght,
        item.cncweight == "" || item.cncweight == null ? "NA" : item.cncweight,
        item.htweight == "" || item.htweight == null ? "NA" : item.htweight,
        item.finishweight == "" || item.finishweight == null
          ? "NA"
          : item.finishweight,
        item.isUsed == "" || item.isUsed == null ? "NA" : item.isUsed,
      ];
    });

    const data = [keyTitleMap, ...flattenedData];
    //

    // Add data to the worksheet
    worksheet.addRows(data);

    // Adjust cell width based on string length
    worksheet.columns.forEach((column) => {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, (cell) => {
        const length = cell.value ? String(cell.value).length : 10;
        if (length > maxLength) {
          maxLength = length;
        }
      });
      column.width = maxLength < 10 ? 10 : maxLength + 2; // Set a minimum width
    });

    // Set background color for the first row
    worksheet.getRow(1).eachCell({ includeEmpty: true }, (cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "3081D0" }, // Yellow background color
      };
      cell.font = {
        color: { argb: "ffffff" },
      };
    });

    // Save the workbook to a file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);

    // Create a link and trigger the download
    const link = document.createElement("a");
    link.href = url;
    const currentDate = new Date().toDateString().split(" ").join("_");
    link.download = `ProductReport_${currentDate}.xlsx`;
    document.body.appendChild(link);
    link.click();

    setLockBtn(false);

    // Cleanup
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  function base64ToExcel(response, stringName) {
    const { statusCode, headers, body, isBase64Encoded } = response;

    // Assume base64String contains the base64 representation of the Excel file
    const base64String = body; // Replace this with your base64 string

    // Convert the base64 string into a Uint8Array
    const bytes = Uint8Array.from(atob(base64String), (c) => c.charCodeAt(0));

    // Create a Blob object from the Uint8Array
    const blob = new Blob([bytes], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create a URL for the Blob object
    const url = URL.createObjectURL(blob);

    // Create a link element with download attribute
    const link = document.createElement("a");
    link.href = url;
    const currentDate = new Date().toDateString().split(" ").join("_");
    link.download = stringName == REPORT ? `ProductReport_${currentDate}.xlsx` : `Product_Template_${currentDate}.xlsx` ;

    // Programmatically click on the link to trigger the download
    document.body.appendChild(link);
    link.click();

    // Cleanup: remove the link and revoke the URL
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
    if(stringName == REPORT){
      setLockBtn(false);
    } else {
      setDownloadTemplate(false);
    }
    
  }

  function toggleNav() {
    const sidebar = document.getElementById("mySidebar");
    const mainDiv = document.getElementById("root_div_main");

    if (sidebar.style.width === "320px") {
      // Sidebar is open, so close it
      sidebar.style.width = "0";
      mainDiv.style.marginLeft = "0";
      sidebar.style.overflowX = "hidden";
    } else {
      // Sidebar is closed, so open it
      sidebar.style.width = "320px";
      mainDiv.style.marginLeft = "320px";
      setTimeout(() => {
        sidebar.style.overflowX = "visible";
      }, 200);
    }
  }

  // For open thumbnail image in modal view
  const [showModal, setShowModal] = useState(false); // State to handle modal visibility
  const [modalImage, setModalImage] = useState(""); // State to store the selected image URL

  const [showModal2, setShowModal2] = useState(false); // For product upload modal popup

  // Function to handle image click and open modal
  const handleImageClick = (imageUrl) => {
    setModalImage(imageUrl);
    setShowModal(true);
  };

  const handleFileChange = async (event) => {
    const fileInput = event.target;  // Get the file input element
    const file = fileInput.files[0]; // Get the selected file
  
    if (!file || !isExcelFile(file.name)) {
      toast.error("Please upload a valid Excel file!", { autoClose: 2000 });
      return;
    }
  
    try {
      const reader = new FileReader();
  
      reader.onload = async () => {
        const base64 = reader.result.split(",")[1];

        // console.log("base64", base64); return;
  
        if (!base64) {
          toast.error("Failed to generate base64 string. Please try again!", { autoClose: 2000 });
          return;
        }
  
        setImportData(true);
  
        try {
          const payload = {
            file_base_64: base64,
            type: prod_type && prod_type?.[0]?.productType,
          };
          const res = await bulk_upload_product(payload);
  
          if (res.status === 200 || res.status === 201) {
            toast.success("Product imported successfully!", { autoClose: 2000 });
            fetchProdList(1, entriesPerPage);
          }
        } catch (err) {
          console.log("err", err)
          const errorMessage =
            err?.response?.status === 400 && err?.response?.data?.message
              ? err.response.data.message
              : "Something went wrong. Please try again later!";
          toast.error(errorMessage, { autoClose: 2000 });
        } finally {
          setImportData(false);
        }
      };
  
      reader.onerror = () => {
        toast.error("Error reading the file. Please try again!", { autoClose: 2000 });
      };
      // Reset the file input after the file is processed
      fileInput.value = null;
      reader.readAsDataURL(file);
    } catch (err) {
      toast.error("An unexpected error occurred. Please try again!", { autoClose: 2000 });
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="min-vh-100" id="root_div_main">
        {/* <NavbarComp2 /> */}

        <div id="mySidebar" className="customsidebar">
          <a className="closebtn" onClick={() => closeNav()}>
            ×
          </a>

          <div className=" content">
            <div>
              <label
                className="filterLabel my-2 roboto-bold"
                style={{ fontSize: "14px" }}
              >
                {constLabel?.lbl_selProductType
                  ? constLabel.lbl_selProductType
                  : "Select Product Type"}
              </label>
              <Multiselect
                options={prodType.data}
                onSelect={(val) => {
                  if (val.length > 0) {
                    setFilterConfig({ ...filterConfig, productTypename: val });
                  } else {
                    setFilterConfig({ ...filterConfig, productTypename: [] });
                  }
                }}
                onRemove={(e) => {
                  setFilterConfig({ ...filterConfig, productTypename: e });
                }}
                selectedValues={filterConfig.productTypename}
                displayValue="productType"
                placeholder="Select product type"
                loading={prodType.loading}
                customCloseIcon={
                  <p className="ms-2 mb-0 p-0" style={{ fontStyle: "normal" }}>
                    x
                  </p>
                }
              />
            </div>

            <div className="mt-2">
              <label
                className="filterLabel mb-1 roboto-bold"
                style={{ fontSize: "14px" }}
              >
                {constLabel?.lbl_product_name
                  ? constLabel?.lbl_product_name
                  : "Product Name"}{" "}
                /{" "}
                {constLabel?.lbl_part_no ? constLabel?.lbl_part_no : "Part No"}
              </label>
              <input
                className="form-control"
                type="text"
                placeholder="Search by product name / part no"
                // value={filterConfig.search_val != ""  ? filterConfig.search_val : ""}
                value={filterConfig.search_val}
                onChange={(val) => {
                  const inputValue = val.target.value;
                  if (!inputValue.trim()) {
                    // Input contains only whitespace, don't trigger the search.
                    if (filterConfig.search_val.length == 1) {
                      setFilterConfig({ ...filterConfig, search_val: "" });
                    }
                  } else {
                    setFilterConfig({
                      ...filterConfig,
                      search_val: inputValue,
                    });
                  }
                }}
              />
            </div>

            <div className="my-3 d-flex justify-content-end">
              <button
                onClick={() => clearFilter()}
                className="btn btn-sm btn-yellow "
              >
                <FontAwesomeIcon
                  style={{ color: "#344454" }}
                  icon={faBan}
                  size="sm"
                />{" "}
                {constLabel?.lbl_clear_filter
                  ? constLabel?.lbl_clear_filter
                  : "Clear Filter"}
              </button>
            </div>
          </div>
        </div>

          <div className="content-wrapper">
            <div className="card border-0">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="d-md-flex justify-content-start col-12 col-md-6">
                    {/* <FontAwesomeIcon
                      onClick={() => toggleNav()}
                      className="cursor_pointer mb-2"
                      style={{
                        color: "Black",
                        marginRight: "10px",
                      }}
                      icon={faBars}
                      size="lg"
                    /> */}
                    <Tooltip id={"open-tooltip"} place="top" />
                    <img src={Slide} height='32' width='32' className="cursor_pointer my-1 me-2" onClick={() => toggleNav()}
                          data-tooltip-id={"open-tooltip"}
                          data-tooltip-content={"Filters"}
                    />
                    <h1 className="bold">{"Product"}</h1>
                  </div>
                  <div className="d-md-flex justify-content-end align-items-center col-12 col-md-6">

                    {loginData.loginData?.data?.response.role_name !==
                      "client_admin" &&
                    loginData.loginData?.data?.response.role_name !== "admin" ? (
                      privilage.report == true ? (
                        <button
                          className="btn btn-light-grey me-0 me-2 ms-0 ms-md-2"
                          // onClick={() => downloadExcel()}
                          disabled={lockBtn}
                          onClick={() => goToDownload()}
                        >
                          <FontAwesomeIcon
                            style={{ color: "#fff" }}
                            icon={faDownload}
                            size="sm"
                          />{" "}
                          {constLabel?.lbl_product_report
                            ? constLabel.lbl_product_report
                            : "Product Report"}{" "}
                          {lockBtn ? (
                            <Spinner animation="border" size="sm" />
                          ) : null}{" "}
                        </button>
                      ) : null
                    ) : (
                      <button
                        className="btn btn-light-grey me-0 me-2 ms-0 ms-md-2"
                        // onClick={() => downloadExcel()}
                        disabled={lockBtn}
                        onClick={() => goToDownload()}
                      >
                        <FontAwesomeIcon
                          style={{ color: "#fff" }}
                          icon={faDownload}
                          size="sm"
                        />{" "}
                        {constLabel?.lbl_product_report
                          ? constLabel.lbl_product_report
                          : "Product Report"}{" "}
                        {lockBtn ? <Spinner animation="border" size="sm" /> : null}{" "}
                      </button>
                    )}
                    
                    {privilage.write ? (
                      <button
                        className="btn button-primary me-0 me-md-2 mt-2 mt-md-0"
                        onClick={() => navigate(`/pcms/productConfigurator`)}
                      >
                        <FontAwesomeIcon
                          style={{ color: "#fff" }}
                          icon={faPlus}
                          size="sm"
                        />{" "}
                        {constLabel?.lbl_add_product
                          ? constLabel?.lbl_add_product
                          : "Add Product"}
                      </button>
                    ) : null}

                    {privilage.report &&
                      <>
                        <Tooltip id={"upload-tooltip"} place="top" />
                          <button
                            className="btn btn-grey me-0 me-md-2 mt-2 mt-md-0"
                            data-tooltip-id={"upload-tooltip"}
                            data-tooltip-content={
                              constLabel?.lbl_open_modal
                                ? constLabel.lbl_open_modal
                                : "Open Modal"}
                            onClick={() => setShowModal2(true)} 
                          >
                            <FontAwesomeIcon
                              style={{ color: "#fff" }}
                              icon={faUpload}
                              size="sm"
                            />
                          </button>
                      </>
                    }

                    {privilage.write &&
                      <>
                        <Tooltip id={"upload-tooltip"} place="top" />
                          <button
                            className="btn btn-grey me-0 me-md-2 mt-2 mt-md-0"
                            data-tooltip-id={"upload-tooltip"}
                            data-tooltip-content={
                              constLabel?.lbl_print_barcode
                                ? constLabel.lbl_print_barcode
                                : "Print Barcode"}
                            onClick={() => {
                              if(checkedItems.length <= 10){
                                navigate(`/pcms/product/barcode`, {state: { product_list: checkedItems }})
                              } else {
                                toast.error("Only select a maximum of 10 records", {autoClose: 1500});
                              }
                            }}
                            
                            disabled={selectedIds.size == 0 ? true : false}
                          >
                            <FontAwesomeIcon
                              style={{ color: "#fff" }}
                              icon={faBarcode}
                              size="sm"
                            />
                          </button>
                      </>
                    }

                    <Tooltip id={"show-tooltip"} place="top" />
                    <div
                      className="dropdown"
                      data-tooltip-id={"show-tooltip"}
                      data-tooltip-content={"Show / Hide Columns"}
                    >
                      <div
                        className="d-md-flex justify-content-start align-items-center mt-md-1"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {/* <FontAwesomeIcon icon={faCog} size="lg" /> */}
                        {/* <List size={24} className="menu_icon_grey cursor_pointer"/> */}
                        <img src={List} height='32' width='32' className="cursor_pointer" />
                      </div>

                      <ul className="dropdown-menu px-2">
                        {showLabel !== undefined && showLabel.length > 0
                          ? showLabel.map((val, ind, arr) => (
                              <li key={ind}>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    style={{ width: "20px", height: "20px" }}
                                    onChange={() => handleClick(ind)}
                                    value=""
                                    checked={val.flag}
                                  />
                                  <label
                                    className=""
                                    style={{ fontSize: "13px" }}
                                    htmlFor="flexCheckDefault"
                                  >
                                    {val.label}
                                  </label>
                                </div>
                              </li>
                            ))
                          : null}
                      </ul>
                    </div>
                  </div>
                </div>

                {privilage.read ? (
                    <>
                      {dataList.loading ? (
                        <div className="text-center">
                          <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      ) : dataList && dataList.data?.length > 0 ? (
                        <>
                          <div className="table-responsive">
                            <table className="table mt-2 table-bordered">
                              <thead className="table-grey  roboto-bold">
                                <tr className="">
                                  <th scope="col">
                                  <input
                                        className="form-check-input cursor_pointer"
                                        type="checkbox"
                                        checked={
                                          dataList.data.length > 0 &&
                                          dataList.data.every((item) => selectedIds.has(item.uuid))
                                        }
                                        onChange={(e) => {
                                          const isChecked = e.target.checked;
                              
                                          // Update all items' `checked` status based on the "common checkbox"
                                          const updatedDataList = {
                                            ...dataList,
                                            data: dataList.data.map((item) => ({
                                              ...item,
                                              checked: isChecked,
                                            })),
                                            loading: false,
                                          };
                              
                                          // Update `selectedIds` and `checkedItems` for all items
                                          const updatedSelectedIds = new Set(
                                            isChecked ? dataList.data.map((item) => item.uuid) : []
                                          );
                              
                                          const updatedCheckedItems = isChecked ? [...dataList.data] : [];

                                          // Update state
                                          setDataList(updatedDataList);
                                          setSelectedIds(updatedSelectedIds);
                                          setCheckedItems(updatedCheckedItems);
                                          
                                        }}
                                      />
                                  </th>
                                  <th scope="col">{"Sr.No"}</th>
                                  {showLabel.map((v, i) =>
                                    v.label === "Thumbnail" && v.flag ? (
                                      <th key={i} scope="col">
                                        {"Thumbnail"}
                                      </th>
                                    ) : v.label === "Product Name" && v.flag ? (
                                      <th key={i} scope="col">
                                        {"Product Name"}
                                      </th>
                                    ) : v.label === "OEM Part No" && v.flag ? (
                                      <th key={i} scope="col">
                                        {"OEM Part No"}
                                      </th>
                                    ) : v.label === "Part No" && v.flag ? (
                                      <th key={i} scope="col">
                                        {"Part No"}
                                      </th>
                                    ) : v.label === "Product Type" && v.flag ? (
                                      <th key={i} scope="col">
                                        {"Product Type"}
                                      </th>
                                    ) : v.label === "Product Family" &&
                                      v.flag ? (
                                      <th key={i} scope="col">
                                        {"Product Family"}
                                      </th>
                                    ) : v.label === "Product Category" &&
                                      v.flag ? (
                                      <th key={i} scope="col">
                                        {"Product Category"}
                                      </th>
                                    ) : v.label === "HSN" && v.flag ? (
                                      <th key={i} scope="col">
                                        {"HSN"}
                                      </th>
                                    ) : v.label === "Has BOM" && v.flag ? (
                                      <th key={i} scope="col">
                                        {"Has BOM"}
                                      </th>
                                    ) : v.label === "Can Be Expensed" &&
                                      v.flag ? (
                                      <th key={i} scope="col">
                                        {"Can Be Expensed"}
                                      </th>
                                    ) : v.label === "Can Be Purchased" &&
                                      v.flag ? (
                                      <th key={i} scope="col">
                                        {"Can Be Purchased"}
                                      </th>
                                    ) : v.label === "Can Be Sold" && v.flag ? (
                                      <th key={i} scope="col">
                                        {"Can Be Sold"}
                                      </th>
                                    ) : v.label === "Material" && v.flag ? (
                                      <th key={i} scope="col">
                                        {"Material"}
                                      </th>
                                    ) : v.label === "Sales Price" && v.flag ? (
                                      <th key={i} scope="col">
                                        {"Sales Price"}
                                      </th>
                                    ) : v.label === "Selling Price" &&
                                      v.flag ? (
                                      <th key={i} scope="col">
                                        {"Selling Price"}
                                      </th>
                                    ) : v.label === "Purchase Price" &&
                                      v.flag ? (
                                      <th key={i} scope="col">
                                        {"Purchase Price"}
                                      </th>
                                    ) : v.label === "Remark" && v.flag ? (
                                      <th key={i} scope="col">
                                        {"Remark"}
                                      </th>
                                    ) : null
                                  )}
                                  <th scope="col" width="15%">
                                    {"Action"}
                                  </th>
                                </tr>
                              </thead>
                              <tbody className=" roboto">
                                {dataList.data.map((val, i) => (
                                  <tr key={i}>
                                    <td>
                                      <input
                                        className="form-check-input cursor_pointer"
                                        type="checkbox"
                                        checked={selectedIds.has(val.uuid)}
                                        onChange={(e) => {
                                          const isChecked = e.target.checked;

                                          // Update the current item's `checked` status
                                          const updatedDataList = {
                                            ...dataList,
                                            data: dataList.data.map(
                                              (item, index) =>
                                                index === i
                                                  ? {
                                                    ...item,
                                                    checked: isChecked,
                                                  }
                                                  : item
                                            ),
                                            loading: false,
                                          };

                                          // Update the `selectedIds` set based on checkbox state
                                          const updatedSelectedIds = new Set(
                                            selectedIds
                                          );
                                          isChecked
                                            ? updatedSelectedIds.add(val.uuid)
                                            : updatedSelectedIds.delete(val.uuid);

                                          // Merge checked items from all pages
                                          const updatedCheckedItems = [
                                            ...new Map(
                                              [
                                                ...checkedItems, // Existing checked items
                                                ...(isChecked ? [val] : []), // Add or remove the current item based on `isChecked`
                                              ]
                                                .filter((item) =>
                                                  updatedSelectedIds.has(
                                                    item.uuid
                                                  )
                                                ) // Keep only items present in `selectedIds`
                                                .map((item) => [item.uuid, item]) // Use Map to ensure unique items by `uuid`
                                            ).values(),
                                          ];

                                          // Update state
                                          setDataList(updatedDataList);
                                          setSelectedIds(updatedSelectedIds);
                                          setCheckedItems(updatedCheckedItems);
                                        }}
                                        // disabled={
                                        //   // val.status?.status_name !== APPROVED &&
                                        //   val.status?.status_name !== BOMCREATED
                                        // }
                                      />
                                    </td>
                                    <td>{parseInt(i) + 1 + parseInt(srNo)}</td>
                                    {showLabel.map((item, idx) =>
                                      item.label === "Thumbnail" &&
                                      item.flag ? (
                                        <td key={idx}>
                                        {val.productattachments && val.productattachments?.length > 0 ? (
                                          // Render clickable thumbnail
                                          <a
                                            href="#"
                                            onClick={(e) => {
                                              e.preventDefault(); // Prevent the default anchor behavior
                                              handleImageClick(`${process.env.REACT_APP_IMAGE_URL}${val.productattachments?.[0]?.attachment}`);
                                            }}
                                          >
                                            <img
                                              src={`${process.env.REACT_APP_IMAGE_URL}${val.productattachments?.[0]?.attachment}`}
                                              alt="Thumbnail"
                                              className="img-thumbnail"
                                              style={{ maxWidth: "40px" }}
                                            />
                                          </a>
                                        ) : (
                                          // Render non-clickable dummy image
                                          <img
                                            src={Image} // Dummy image URL
                                            alt="Dummy Thumbnail"
                                            className="img-thumbnail"
                                            style={{ maxWidth: "40px" }}
                                          />
                                        )}
                                        </td>
                                      ) : item.label === "Product Name" &&
                                      item.flag ? (
                                        <td key={idx}>{val.productName}</td>
                                      ) : item.label === "OEM Part No" &&
                                        item.flag ? (
                                        <td key={idx}>
                                          {val.OEMnumber != "" &&
                                          val.OEMnumber != null
                                            ? val.OEMnumber
                                            : "NA"}
                                        </td>
                                      ) : item.label === "Part No" &&
                                        item.flag ? (
                                        <td key={idx}>
                                          {val.partNo != "" &&
                                          val.partNo != null
                                            ? val.partNo
                                            : "NA"}
                                        </td>
                                      ) : item.label === "Product Type" &&
                                        item.flag ? (
                                        <td key={idx}>{val.productTypename}</td>
                                      ) : item.label === "Product Family" &&
                                        item.flag ? (
                                        <td key={idx}>
                                          {val.prod_family_id != null
                                            ? val.prod_family_id.familyname
                                            : "NA"}
                                        </td>
                                      ) : item.label === "Product Category" &&
                                        item.flag ? (
                                        <td key={idx}>
                                          {val.category !== null &&
                                          val.category !== undefined &&
                                          val.category.trim().length > 0
                                            ? val.category
                                            : "NA"}
                                        </td>
                                      ) : item.label === "HSN" && item.flag ? (
                                        <td key={idx}>
                                          {val.hsn != "" && val.hsn != null
                                            ? val.hsn
                                            : "NA"}
                                        </td>
                                      ) : item.label === "Has BOM" &&
                                        item.flag ? (
                                        <td key={idx}>
                                          {val.has_bom ? "True" : "False"}
                                        </td>
                                      ) : item.label === "Can Be Expensed" &&
                                        item.flag ? (
                                        <td key={idx}>
                                          {val.can_be_expensed
                                            ? "True"
                                            : "False"}
                                        </td>
                                      ) : item.label === "Can Be Purchased" &&
                                        item.flag ? (
                                        <td key={idx}>
                                          {val.can_be_purchased
                                            ? "True"
                                            : "False"}
                                        </td>
                                      ) : item.label === "Can Be Sold" &&
                                        item.flag ? (
                                        <td key={idx}>
                                          {val.can_be_sold ? "True" : "False"}
                                        </td>
                                      ) : item.label === "Material" &&
                                        item.flag ? (
                                        <td key={idx}>
                                          {val.material != "" &&
                                          val.material != null
                                            ? val.material
                                            : "NA"}
                                        </td>
                                      ) : item.label === "Sales Price" &&
                                        item.flag ? (
                                        <td key={idx}>
                                          {val.salesPrice != "" &&
                                          val.salesPrice != null
                                            ? val.salesPrice
                                            : "NA"}
                                        </td>
                                      ) : item.label === "Selling Price" &&
                                        item.flag ? (
                                        <td key={idx}>
                                          {val.selling_price != "" &&
                                          val.selling_price != null
                                            ? val.selling_price
                                            : "NA"}
                                        </td>
                                      ) : item.label === "Purchase Price" &&
                                        item.flag ? (
                                        <td key={idx}>
                                          {val.purchase_price != "" &&
                                          val.purchase_price != null
                                            ? val.purchase_price
                                            : "NA"}
                                        </td>
                                      ) : item.label === "Remark" &&
                                        item.flag ? (
                                        <td key={idx}>
                                          {val.remark != "" &&
                                          val.remark != null
                                            ? val.remark
                                            : "NA"}
                                        </td>
                                      ) : null
                                    )}

                                    <td className="">

                                      {privilage.write ? (
                                        <>
                                          <Tooltip id={"edit-tooltip" + i} place="top" />
                                          <Edit 
                                            data-tooltip-id={"edit-tooltip" + i}
                                            data-tooltip-content={
                                              constLabel?.lbl_edit_product
                                                ? constLabel.lbl_edit_product
                                                : "Edit Product"
                                            }
                                            className="menu_icon_grey cursor_pointer me-2"
                                            size={20} 
                                            onClick={() =>
                                              navigate(
                                                // "/pcms/editProductConfigure",
                                                "/pcms/editProductConfigurator",
                                                {
                                                  state: val,
                                                }
                                              )
                                            }
                                          />
                                        </>
                                      ) : null}

                                      {privilage.read ? (
                                        <>
                                          <Tooltip id={"view-tooltip" + i} place="top"/>
                                          <Eye  
                                            data-tooltip-id={"view-tooltip" + i}
                                            data-tooltip-content={
                                              constLabel?.lbl_view_product
                                                ? constLabel.lbl_view_product
                                                : "View Product"
                                            }
                                            className="menu_icon_grey cursor_pointer me-2"
                                            size={20} 
                                            onClick={() => {
                                              if (
                                                val.productTypename === MANUFACTURED_GOODS || val.productTypename === FINISHED_GOODS
                                              ) {
                                                navigate("/pcms/viewProduct", {
                                                  state: val,
                                                });
                                              }
  
                                              if (
                                                val.productTypename ===
                                                RAW_MATERIAL
                                              ) {
                                                navigate(
                                                  "/pcms/viewRawMaterial",
                                                  {
                                                    state: val,
                                                  }
                                                );
                                              }
  
                                              if (
                                                val.productTypename ===
                                                SEMI_FINISH_GOODS
                                              ) {
                                                navigate(
                                                  "/pcms/viewSemiFinishGoods",
                                                  {
                                                    state: val,
                                                  }
                                                );
                                              }
                                              if (
                                                val.productTypename === "Tools" ||
                                                val.productTypename === CONSUMABLE_ITEMS || 
                                                val.productTypename=== FIXTURES ||
                                                val.productTypename=== GAUGES
                                              ) {
                                                navigate(
                                                  "/pcms/viewToolsFixture",
                                                  {
                                                    state: val,
                                                  }
                                                );
                                              }
  
                                              if (!product_types.includes(val.productTypename)){
                                                navigate("/pcms/viewGeneralItem", { state: val });
                                              }
  
                                            }}
                                          />
                                        </>
                                      ) : null}

                                      {privilage.write && (val.productTypename === MANUFACTURED_GOODS || val.productTypename === FINISHED_GOODS || val.productTypename === RAW_MATERIAL || val.productTypename === SEMI_FINISH_GOODS) ? (
                                        <>
                                          <Tooltip id={"clone-tooltip" + i} place="top" />
                                            <Copy
                                              data-tooltip-id={"clone-tooltip" + i}
                                              data-tooltip-content={
                                                constLabel?.lbl_clone_product
                                                  ? constLabel.lbl_clone_product
                                                  : "Clone Product"
                                              }
                                              className="menu_icon_grey cursor_pointer me-2"
                                              size={20} 
                                              onClick={() =>
                                                navigate(
                                                  // "/pcms/ProductConfigure/clone",
                                                  "/pcms/cloneProductConfigurator",
                                                  {
                                                    state: val,
                                                  }
                                                )
                                              }
                                            />
                                        </>
                                      ): null}

                                      {privilage.delete && (
                                        <>
                                          <Tooltip id={"delete-tooltip" + i} place="top" />
                                            <Trash2
                                              data-tooltip-id={"delete-tooltip" + i}
                                              data-tooltip-content={
                                                constLabel?.lbl_delete_product
                                                  ? constLabel.lbl_delete_product
                                                  : "Delete Product"
                                              }
                                              className="menu_icon_red cursor_pointer"
                                              size={20} 
                                              onClick={() => delProduct(val)}
                                            />
                                        </>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>

                          <div className="d-flex justify-content-between align-items-center mt-2">
                            <p className="mb-0">
                              Show
                              <select
                                className="mx-1"
                                defaultValue={entriesPerPage}
                                onChange={(e) => {
                                  setEntriesPerPage(e.target.value);
                                  fetchProdList(1, e.target.value);
                                }}
                              >
                                <option value={"10"}>10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                              </select>
                              Entries
                            </p>
                            <p className="mb-0">{`Showing ${
                              parseInt(srNo) + 1
                            } to ${Math.min(
                              parseInt(entriesPerPage) + parseInt(srNo),
                              dataList.totalRecords
                            )} of ${dataList.totalRecords} entries`}</p>
                            <Pagination className="my-2">
                              <Pagination.Prev
                                disabled={page === 1 ? true : false}
                                onClick={() => {
                                  fetchProdList(
                                    page - 1,
                                    entriesPerPage,
                                    parseInt(srNo) - parseInt(entriesPerPage)
                                  );
                                  setSrNo((prevC) =>
                                    page - 1 == 1
                                      ? 0
                                      : prevC - parseInt(entriesPerPage)
                                  );
                                  setPage(page - 1);
                                }}
                              >
                                {"Prev"}
                              </Pagination.Prev>

                              <Pagination.Item active>{page}</Pagination.Item>

                              <Pagination.Next
                                disabled={
                                  page === maxPage ||
                                  maxPage === 0 ||
                                  entriesPerPage > dataList.data.length
                                    ? true
                                    : false
                                }
                                onClick={() => {
                                  fetchProdList(
                                    page + 1,
                                    entriesPerPage,
                                    parseInt(srNo) + parseInt(entriesPerPage)
                                  );
                                  setSrNo(
                                    (prevC) => prevC + parseInt(entriesPerPage)
                                  );
                                  setPage(page + 1);
                                }}
                              >
                                {"Next"}
                              </Pagination.Next>
                            </Pagination>
                          </div>
                        </>
                      ) : (
                        <div
                          className="alert alert-danger mb-0 text-center my-4"
                          role="alert"
                        >
                          {"No Product Records Found !"}
                        </div>
                      )}
                    </>
                ) : null}

              </div>
            </div>
          </div>

        {/* Modal to display full image */}
        <Modal show={showModal} onHide={() => setShowModal(false)} centered>
          <Modal.Header style={{ background: "#2A3643", color: "white" }} closeButton>
            <Modal.Title>{constLabel?.lbl_product_image ? constLabel?.lbl_product_image : "Product Image"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img
              src={modalImage}
              alt="Full-size"
              className="img-fluid"
              style={{ maxHeight: '80vh', width: '100%' }}
            />
          </Modal.Body>
        </Modal>

        <Modal show={showModal2} onHide={() => { setShowModal2(false); reset()}} centered>
          <Modal.Header style={{ background: "#2A3643", color: "white" }} closeButton>
            <Modal.Title>{constLabel?.lbl_product_upload ? constLabel?.lbl_product_upload : "Product Upload"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <div className="d-flex justify-content-center pb-4">
                <div className="form-group col-12 col-md-6 typeahead-container">
                  <label className="lbl-style roboto-bold">
                    {"Product Type"}{" "}
                    <span className="text-danger"> *</span>
                    <Tooltip id={"open-tooltip"} place="top" />
                    <img
                      src={info}
                      alt="info"
                      className="cursor_pointer"
                      data-tooltip-id={"open-tooltip"}
                      data-tooltip-html="Classifies items based on their role in the manufacturing process, including categories<br />
                             such as raw materials, components, finished goods, packaging materials, tools, and gauges. This field helps in<br /> 
                             organizing and tracking inventory for streamlined production, procurement, and management."
                      height={20}
                      width={20}
                    />
                  </label>
                  <Controller
                    name="type"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        id="basic-typeahead-single"
                        labelKey="productType"
                        size="sm"
                        multiple={false}
                        options={prodType.data}
                        isLoading={prodType.loading}
                        placeholder="Select product type"
                        onChange={(selected) => {
                          onChange(selected);
                        }}
                        selected={value}
                        onBlur={onBlur}
                        isInvalid={errors.type ? true : false}
                      />
                    )}
                  />
                  {!prodType.loading && <span className="arrow-icon"></span>}
                </div>
              </div>

              {/* Card Section with Template and Import Options */}
              <div className="row justify-content-center">
                <div className="col-12 col-sm-6 col-md-4 col-lg-4 py-1">
                  <div className="card shadow-sm cursor_pointer">
                    <div
                      className="card-body py-1 text-center"
                      onClick={() => {
                        setDownloadTemplate(true);
                        download_product_template({
                          type: prod_type && prod_type?.[0]?.productType,
                        }).then(
                          (res) => {
                            // console.log("res", res);
                            base64ToExcel(res.data, TEMPLATE);
                          },
                          (err) => {
                            console.log("err", err);
                            toast.error(err.response.data.error, {
                              autoClose: 2000,
                            });
                            setDownloadTemplate(false);
                          }
                        );
                      }}
                      disabled={downloadTemplate}
                    >
                      <Tooltip id="open-tooltip1" place="top" />
                      <img
                        src={Check}
                        alt="info"
                        data-tooltip-id="open-tooltip1"
                        data-tooltip-content="Download Excel Template"
                        height={40}
                        width={40}
                      />
                      <p className="bold body2 mt-3">Template</p>{" "}
                      {downloadTemplate && (
                        <Spinner
                          animation="border"
                          size="sm"
                          className="ms-2"
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-12 col-sm-6 col-md-4 col-lg-4 py-1">
                  <div className="card shadow-sm cursor_pointer">
                    <input
                      type="file"
                      accept=".xls,.xlsx"
                      onChange={handleFileChange}
                      style={{ display: "none" }} // Hide the input element
                      id="fileInput2"
                    />
                    <div
                      className="card-body py-1 text-center"
                      onClick={() =>
                        document.getElementById("fileInput2").click()
                      }
                      onChange={handleFileChange}
                      disabled={importData}
                    >
                      <Tooltip id="open-tooltip2" place="top" />
                      <img
                        src={BulkImport}
                        alt="info"
                        className="cursor_pointer"
                        data-tooltip-id="open-tooltip2"
                        data-tooltip-content="Upload Product Excel"
                        height={40}
                        width={40}
                      />
                      <p className="bold body2 mt-3">
                        Bulk Import
                      </p>{" "}
                      {importData && (
                        <Spinner
                          animation="border"
                          size="sm"
                          className="ms-2"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
          </Modal.Body>
        </Modal>
      </div>
    </React.Fragment>
  );
}

export default Product;
