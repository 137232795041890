import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faClose } from "@fortawesome/free-solid-svg-icons";
import SweetAlert from "sweetalert2";
import { Edit, Edit2, Trash2 } from "react-feather";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { ToastContainer, toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import {
  delete_party_attributes,
  get_attrib_api,
  get_master_files,
  get_party_attributes,
  getCustomer,
} from "../../../api";
import { Spinner } from "react-bootstrap";
import Add_Customer from "./Add_Customer";
import View from "../../../assets/images/icons/project_menu/eye.png";
import Add_Measurements from "./Add_Measurements";
import { Tooltip } from "react-tooltip";
import Add_Profile from "./Add_Profile";
import Select_Customer_And_Measurement from "./Select_Customer_And_Measurement";

function Step_One(props) {
  const { main_obj, setMain_obj, nextStep } = props;
  let navigate = useNavigate();

  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [visible3, setVisible3] = useState(false);
  const [visible4, setVisible4] = useState(false);

  const [lockBtn, setLockBtn] = useState(false);

  const [cust_search, setCust_Search] = useState([]);
  const [cust_data, setCust_Data] = useState([main_obj.customer_data[0]] || []);

  const [searched, setSearched] = useState({ name: "", number: "" });

  const [attribList, setAttribList] = useState([]); // get all measurements
  const [partyAttrib, setPartyAttrib] = useState([]); // specific customer mesurements
  const [single_measured, set_single_mesured] = useState([]); // sigle mesurement create / edit

  const [filterConfig, setFilterConfig] = useState({
    searchVal: "",
    primaryContactNo: "",
  });

  const [selectedCustomer, setSelectedCustomer] = useState(
    main_obj.customer_data?.[0] || null
  );

  const [attach_files, setAttach_Files] = useState({
    data: [],
    loading: false,
  });

  const [constLabel, setConstLabel] = useState({});

  const localData = JSON.parse(localStorage.getItem("localData"));

  const {
    register,
    handleSubmit,
    formState,
    control,
    watch,
    getValues,
    setValue,
    reset,
  } = useForm({
    mode: "onChange",
  });

  let { errors } = formState;

  const { fields, append, remove } = useFieldArray({ control, name: "items" });

  useEffect(() => {
    // console.log("main_obj.customer_data", main_obj.customer_data)
    get_attrib_api().then(
      (res) => {
        setAttribList(res?.data?.data?.length > 0 ? res.data.data : []);
      },
      () => setAttribList([]) // Error case
    );
  
    if (Array.isArray(main_obj.customer_data) && main_obj.customer_data.length > 0) {
      const selected_customer = main_obj.customer_data.find((val) => val.selected === true) || main_obj.customer_data[0];
  
      setCust_Data(selected_customer ? [selected_customer] : []);
      party_mesurements([selected_customer], "only_active");
      fetchImage(selected_customer?.uuid);
    }
  }, [main_obj.customer_data]); // Runs when customer_data updates
  

  // console.log("partyAttrib", partyAttrib);
  function party_mesurements(party, called_by) {
    // const temp_main_obj = { ...main_obj };
    if (!party || party.length === 0) return; // Handle empty input early

    if (party.length > 0) {
      get_party_attributes({ party_uuid: party?.[0]?.uuid }).then(
        (res) => {
          if (res && res.data.data?.length > 0) {
            // Add isSelected to each measurement
            const updatedMeasurements = res.data.data.map((measurement) => ({
              ...measurement,
              isSelected: false,
            }));
            setPartyAttrib(updatedMeasurements);

            if (called_by == "only_active") {
              setMain_obj((prevState) => {

                prevState.customer_data.forEach((cust) => {
                  if (cust.uuid == party[0].uuid) {
                    cust.selected = true;
                  } else {
                    cust.selected = false;
                  }
                });
                return {
                  ...prevState,
                };
              });
            } else {
              setMain_obj((prevState) => {

                prevState.customer_data.forEach((cust) => {
                  if (cust.uuid == party[0].uuid) {
                    cust.selected = true;
                  } else {
                    cust.selected = false;
                  }
                });

                const partyExists = prevState.so_data.some(
                  (item) => item.customer.uuid === party[0].uuid // Assuming 'id' is a unique identifier for each party
                );

                if (partyExists) {
                  return prevState; // Return previous state if duplicate found
                }

                return {
                  ...prevState,
                  so_data: [
                    ...prevState.so_data,
                    {
                      isSelected: party[0]?.selected === true, // Set based on party[0].selected
                      customer: {
                        ...party[0], // Insert the party object
                        measurements: updatedMeasurements, // Ensure measurements have isSelected
                      },
                    },
                  ],
                };

              });
            }
          } else {
            setPartyAttrib([]);
          }
        },
        (err) => {
          // console.log("err", err);
          setPartyAttrib([]);
          if (called_by == "only_active") {
            setMain_obj((prevState) => {
              // Check if the party already exists in so_data

              prevState.customer_data.forEach((cust) => {
                if (cust.uuid == party[0].uuid) {
                  cust.selected = true;
                } else {
                  cust.selected = false;
                }
              });
              return {
                ...prevState,
              };
            });
          }
        }
      );
    }
  }

  function searchCustomer() {
    setCust_Data([]);
    setLockBtn(true);
    const payload = {
      partyType: "Customer",
    };

    if (filterConfig?.searchVal?.length > 0) {
      payload.searchVal = filterConfig.searchVal;
    } else {
      delete payload.searchVal;
    }

    if (filterConfig?.primaryContactNo?.length > 0) {
      payload.primaryContactNo = filterConfig.primaryContactNo;
    } else {
      delete payload.primaryContactNo;
    }

    if (
      filterConfig?.searchVal?.length > 0 ||
      filterConfig?.primaryContactNo?.length > 0
    ) {
      setSearched({
        name: filterConfig?.searchVal || "",
        number: filterConfig?.primaryContactNo || "",
      });
    } else {
      setSearched({ name: "", number: "" });
    }

    if (
      !filterConfig?.searchVal?.length &&
      !filterConfig?.primaryContactNo?.length
    ) {
      toast.error("Please enter a customer name or contact number!", {
        autoClose: 1200,
      });
      setLockBtn(false);
      return;
    }

    getCustomer(payload).then(
      (res) => {
        // console.log("res", res);
        if (res.data.data.length > 0) {
          setCust_Data([res.data.data[0]]);
          setCust_Search([res.data.data[0]]);
          // setMain_obj((prevState) => ({
          //   ...prevState,
          //   customer_data: [...prevState.customer_data, ...res.data.data],
          // }));
          setLockBtn(false);
          setTimeout(() => {
            setVisible(true);
          }, 500);
        } else {
          setCust_Data([]);
          setLockBtn(false);
        }
      },
      (err) => {
        // toast.error("Customer not found!", {
        //   autoClose: 1000,
        // });
        setCust_Data([]);
        setCust_Search([]);
        setMain_obj((prevState) => ({
          ...prevState,
          customer_data: [],
        }));
        setLockBtn(false);
        setTimeout(() => {
          setVisible(true);
        }, 500);
      }
    );
  }

  // Function to check if an item is checked
  const isItemChecked = (item) => {
    return main_obj.so_data.some((entry) => entry.customer.uuid === item.uuid);
  };

  // Function to handle checkbox state change
  const handleCheckboxChange = (isChecked, item) => {
    if (isChecked) {
      // Call party_measurements when checked
      party_mesurements([item]);
    } else {
      // Remove the unchecked item from so_data
      setMain_obj((prevState) => ({
        ...prevState,
        so_data: prevState.so_data.filter(
          (entry) => entry.customer.uuid !== item.uuid
        ),
      }));
    }
  };

  const delMesurement = (val) => {
    // console.log("selectedCustomer", selectedCustomer);
    SweetAlert.fire({
      title: "Are you sure to delete ?",
      // text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        delete_party_attributes({ uuid: val.uuid }).then(
          (res) => {
            toast.success("Measurement deleted successfully", {
              autoClose: 2000,
            });
            // party_mesurements([selectedCustomer]);
            // setSelectedCustomer(selectedCustomer);
            party_mesurements([
              main_obj.customer_data.find((val) => val.selected),
            ]);
          },
          (err) => {
            toast.error("Something went wrong !", {
              autoClose: 2000,
            });
          }
        );
      }
    });
  };

  async function fetchImage(customer_uuid) {
    setAttach_Files((prev) => ({ ...prev, loading: true, data: [] }));
    await get_master_files({
      ref_model_uuid: customer_uuid,
    }).then(
      (res) => {
        setAttach_Files((prev) => ({
          ...prev,
          loading: false,
          data: res.data.data,
        }));
      },
      (err) => {
        setAttach_Files((prev) => ({ ...prev, loading: true, data: [] }));
      }
    );
  }

  const getViewType = (name) => {
    const match = name.match(/^(front|back|side)/);
    return match ? match[0] + "-" + "image" : "";
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <Add_Customer
        visible={visible}
        setVisible={setVisible}
        cust_data={cust_data}
        setCust_Data={setCust_Data}
        main_obj={main_obj}
        setMain_obj={setMain_obj}
        cust_search={cust_search}
        setCust_Search={setCust_Search}
        searched={searched}
        setSearched={setSearched}
      />

      <Add_Measurements
        visible={visible2}
        setVisible={setVisible2}
        cust_data={cust_data}
        setCust_Data={setCust_Data}
        attribList={attribList}
        single_measured={single_measured}
        set_single_mesured={set_single_mesured}
        party_mesurements={party_mesurements}
        // selectedCustomer={selectedCustomer}
        selectedCustomer={main_obj.customer_data.find((val) => val.selected)}
      />

      <Select_Customer_And_Measurement
        visible={visible3}
        setVisible={setVisible3}
        nextStep={nextStep}
        main_obj={main_obj}
        setMain_obj={setMain_obj}
      />

      <Add_Profile
        visible={visible4}
        setVisible={setVisible4}
        fetchImage={fetchImage}
        // selectedCustomer={selectedCustomer}
        selectedCustomer={main_obj.customer_data.find((val) => val.selected)}
        attach_files={attach_files}
      />

      <div className="card">
        <div className="card-body">
          <div className="createPO container-fluid">
            {main_obj.customer_data?.length === 0 ? (
              <>
                <p className="roboto-bold mb-0" style={{ fontSize: "20px" }}>
                  {constLabel?.lbl_customer_overview
                    ? constLabel.lbl_customer_overview
                    : "Customer Overview"}
                </p>
                <hr className="my-2" />

                <div className="row my-4">
                  <div className="col-12 col-md-4">
                    <div className="form-group">
                      <label className="lbl-style roboto-bold">
                        {constLabel?.lbl_customer
                          ? constLabel?.lbl_customer
                          : "Customer"}
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Search by customer name"
                        value={filterConfig.searchVal}
                        onChange={(val) => {
                          const inputValue = val.target.value;
                          if (!inputValue.trim()) {
                            // Input contains only whitespace, don't trigger the search.
                            if (filterConfig.searchVal.length == 1) {
                              setFilterConfig({
                                ...filterConfig,
                                searchVal: "",
                              });
                            }

                            if (inputValue.length == 0) {
                              setFilterConfig({
                                ...filterConfig,
                                searchVal: "",
                              });
                            }
                          } else {
                            setFilterConfig({
                              ...filterConfig,
                              searchVal: inputValue,
                            });
                          }
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-12 col-md-4">
                    <div className="form-group">
                      <label className="lbl-style roboto-bold">
                        {constLabel?.lbl_contact_no
                          ? constLabel.lbl_contact_no
                          : "Contact No"}
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Search by contact no"
                        maxLength={10}
                        value={filterConfig.primaryContactNo}
                        onChange={(e) => {
                          const inputValue = e.target.value;

                          // Allow only digits
                          if (!/^\d*$/.test(inputValue)) return;

                          // Restrict numbers to 10 digits & must start with 6-9
                          if (
                            inputValue.length > 10 ||
                            (inputValue.length > 0 &&
                              !/^[6-9]/.test(inputValue))
                          ) {
                            return;
                          }

                          setFilterConfig((prev) => ({
                            ...prev,
                            primaryContactNo: inputValue,
                          }));
                        }}
                        onBlur={() => {
                          // Ensure number is exactly 10 digits long or empty
                          if (
                            filterConfig.primaryContactNo &&
                            filterConfig.primaryContactNo.length !== 10
                          ) {
                            setFilterConfig((prev) => ({
                              ...prev,
                              primaryContactNo: "",
                            }));
                          }
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-12 col-md-4">
                    <button
                      className="btn button-primary px-4 mt-4"
                      onClick={() => searchCustomer()}
                      disabled={
                        lockBtn ||
                        (!filterConfig.searchVal?.trim() &&
                          filterConfig.primaryContactNo.length !== 10)
                      }
                    >
                      {constLabel?.lbl_search
                        ? constLabel.lbl_search
                        : "Search"}{" "}
                      {lockBtn ? (
                        <Spinner animation="border" size="sm" />
                      ) : null}{" "}
                    </button>
                  </div>
                </div>
                <hr className="my-2" />
              </>
            ) : (
              <>
                <div className="d-flex justify-content-between align-items-center">
                  <p className="roboto-bold mb-0" style={{ fontSize: "20px" }}>
                    {constLabel?.lbl_family_profile
                      ? constLabel.lbl_family_profile
                      : "Family Profile"}
                  </p>
                  <button
                    className="btn btn-sm btn-outline-dark px-4 ms-0 ms-md-2"
                    onClick={() => {
                      setVisible(true);
                      setCust_Data([]);
                    }}
                  >
                    {constLabel?.lbl_add_family
                      ? constLabel.lbl_add_family
                      : "Add Family"}
                  </button>
                </div>
                <hr className="my-2" />
                <div className="d-flex justify-content-start">
                  <i>
                    <span className="text-danger roboto-bold">Note: </span>
                      <div className="mt-2">
                        <ol>
                          <li>Please select customer checkboxes to proceed with sales order creation</li>
                          <li>Click on a particular card to add family, add measurements, and add a profile</li>
                          <li>The eye icon is used to view customer details</li>
                        </ol>
                      </div>
                  </i>
                </div>
                <div className="d-flex flex-row flex-wrap my-4">
                  {main_obj.customer_data?.map((item) => (
                    <React.Fragment key={item.uuid}>
                      <input
                        type="checkbox"
                        style={{ width: "15px", height: "15px" }}
                        checked={isItemChecked(item)} // Determines if the item is checked
                        onChange={(e) => {
                          e.stopPropagation();
                          handleCheckboxChange(e.target.checked, item);
                        }}
                      />
                      <div
                        className={`card p-1 mx-2 cursor_pointer me-3 ${
                          item.selected == true ? "bg-primary" : ""
                        }`}
                        onClick={() => {
                          party_mesurements([item], "only_active");
                          setSelectedCustomer(item);
                          fetchImage(item?.uuid);
                          setCust_Data([item]);
                        }} // Set selected customer
                      >
                        <div className="d-flex align-items-center p-1">
                          <div
                            className="cursor_pointer"
                            onClick={(e) => {
                              // e.stopPropagation();
                              // setCust_Data([item]); // Set customer data
                              // setTimeout(() => setVisible2(true), 50); // Slight delay
                            }}
                          >
                            <img
                              src={View}
                              className="img-fluid me-2"
                              alt="Card"
                              width={30}
                              height={30}
                              onClick={(e) => {
                                e.stopPropagation();
                                setCust_Data([item]); // Set customer data
                                setTimeout(() => setVisible(true), 50); // Slight delay
                              }}
                            />
                          </div>
                          <div
                            className="d-flex flex-column justify-content-center py-3"
                            // onClick={(e) => {
                            //   e.stopPropagation();
                            //   setCust_Data([item]); // Set customer data
                            //   setTimeout(() => setVisible(true), 50); // Slight delay
                            // }}
                          >
                            <h6 className="card-subtitle roboto-bold">
                              {item?.organization}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
                </div>

                <hr className="my-2" />
                <div className="row">
                  <div className="col-12 col-md-6 p-2 border">
                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <p className="roboto-bold mb-0" style={{ fontSize: "20px" }}>
                        {constLabel?.lbl_measurements
                          ? constLabel.lbl_measurements
                          : "Measurements"}
                      </p>
                      <button
                        className="btn btn-sm btn-outline-dark px-4 ms-0 ms-md-2"
                        onClick={(e) => {
                          e.stopPropagation();
                          set_single_mesured([]);
                          setTimeout(() => setVisible2(true), 50); // Slight delay
                        }}
                      >
                        {constLabel?.lbl_add_measurements
                          ? constLabel.lbl_add_measurements
                          : "Add Measurements"}
                      </button>
                    </div>
                    {partyAttrib.length > 0 ? (
                      <div className="table-responsive">
                        <table className="table table-bordered">
                          <thead className="table-grey roboto-bold">
                            <tr>
                              <th width="10%">Sr.</th>
                              <th>Measurements</th>
                              <th width="15%">Action</th>
                            </tr>
                          </thead>
                          <tbody className="roboto-bold">
                            {partyAttrib?.map((val, i) => (
                              <tr key={i}>
                                <td>{i + 1}</td>
                                <td>{val.name}</td>
                                <td>
                                  <Tooltip id={"edit-tooltip" + i} place="top" />
                                  <Edit
                                    data-tooltip-id={"edit-tooltip" + i}
                                    data-tooltip-content={
                                      constLabel?.lbl_edit_measurements
                                        ? constLabel.lbl_edit_measurements
                                        : "Edit Mesurements"
                                    }
                                    className="menu_icon_grey cursor_pointer me-2"
                                    size={20}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      set_single_mesured([val]);
                                      setTimeout(() => setVisible2(true), 50); // Slight delay
                                    }}
                                  />

                                  <Tooltip id={"delete-tooltip" + i} place="top" />
                                  <Trash2
                                    data-tooltip-id={"delete-tooltip" + i}
                                    data-tooltip-content={
                                      constLabel?.lbl_delete_mesurements
                                        ? constLabel.lbl_delete_mesurements
                                        : "Delete Mesurements"
                                    }
                                    className="menu_icon_red cursor_pointer me-2"
                                    size={20}
                                    onClick={() => delMesurement(val)}
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div
                        className="alert alert-danger mb-0 text-center my-4"
                        role="alert"
                      >
                        {"Measurements not found!"}
                      </div>
                    )}
                  </div>

                  <div className="col-12 col-md-6 p-2 border">
                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <p className="roboto-bold mb-0" style={{ fontSize: "20px" }}>
                        {constLabel?.lbl_profile_image
                          ? constLabel.lbl_profile_image
                          : "Profile Image"}
                      </p>
                      <button
                        className="btn btn-sm btn-outline-dark px-4 ms-0 ms-md-2"
                        onClick={(e) => {
                          e.stopPropagation();
                          setTimeout(() => setVisible4(true), 50); // Slight delay
                        }}
                      >
                        {constLabel?.lbl_add_profile
                          ? constLabel.lbl_add_profile
                          : "Add Profile"}
                      </button>
                    </div>
                    <div>
                      {attach_files?.loading ? (
                        <div className="text-center">
                            <div className="spinner-border" role="status">
                              <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                      ) : attach_files?.data?.length > 0 ? (
                        <div className="row">
                          {attach_files?.data.map((val, i) =>
                            val.attachment !== null ? (
                              <React.Fragment key={val.uuid}>
                                <div className="col-12 col-md-4 text-center">
                                  <div className="d-flex justify-content-center mt-3">
                                    <div className="image-container text-center">
                                      <a
                                        href={`${process.env.REACT_APP_IMAGE_URL}${val.attachment}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <img
                                          src={`${process.env.REACT_APP_IMAGE_URL}${val.attachment}`}
                                          className="img-fluid"
                                          style={{ height: "100px" }}
                                          alt={"Image"}
                                        />
                                      </a>
                                    </div>
                                  </div>
                                  <span className="file-name cursor-pointer">
                                    {getViewType(val?.name)}
                                  </span>
                                </div>
                              </React.Fragment>
                            ) : null
                          )}
                        </div>
                      ) : (
                        <div
                          className="alert alert-danger mb-0 text-center my-4"
                          role="alert"
                        >
                          {"No files found!"}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="card-footer d-flex justify-content-end py-3">
          <button
            className="btn btn-grey px-4 ms-0 ms-md-2"
            onClick={() => { setVisible3(true)}}
            // disabled={main_obj.customer_data?.length == 0 ? true : false}
            disabled={!main_obj.so_data.some(item => item.isSelected)}
          >
            {"Save & Proceed"}
          </button>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Step_One;
