import axios from "axios";
import client1 from "../constant/SuperAdmin_AxiosInstance";
import client2 from "../constant/NodeAPI_AxiosInstance";
import {
    product_family_lst, product_family, country_ms, state_ms, city_ms, product_type,
    market_group_ms, prod_cat_ms, product_lst, prod_family_ms, prod_type_ms, operation_cat, operation,
    machine_cat, machine_asset, customer_type_ms, customer_ms, company_ms, loc_type_ms,
    location_ms, machine_cat_ms, single_customer, cust_contact_save, cust_bill_shipp_save,
    cust_billing_save, cust_bank_save, cust_delete, supplier_type_ms, supplier_lst, supp_addr_save, supp_contact_save, bank_ms,
    cust_contact_update, cust_contact_remove, cust_bill_shipp_update, cust_bill_shipp_remove,
    cust_bank_update, cust_bank_remove, cust_billing_update, supp_delete, single_supplier,
    customer_csv, supplier_csv, get_ContactsByCustomer, get_ProductByCustomer, get_LotsByCustomer,
    cust_edit, lotNum, prodList, operationList, machineList, disposalActionList, ncReasonList,
    rawMaterialSpec, toolList, operatorList, operation_delete, operation_reg,
    register_lst, gaugeList, SFGList, SemiFinishProdList, materialList, productTypeList, productCatList,
    currList, toolGrade, toolFamily, marketGrp, productFamily, partyList, ncReason, ncStatus,
    createproduct, partyAgainstProd, oldprodList, locationType, location, customerType, ncImage, locationMapping,
    ncExcel, customer, roles, app_user, moduleList, roleList, roleMap, saveBulkNC, nc_JobTag, product_cat,
    market_group, prod_family, product_image, routesapi, opeartaionApi, attribute,
    unit_of_measurement, product_attribute, prodConfig, multiImage, prod_config, prod_machine,
    prod_images, prod_opr_machine, prod_opr_img, supplierType, supplier, bom, bommulti, bom_list_by_product,
    bom_list_by_uuid, bomLineItem, country_api, state_api, city_api, party_contact, raw_material_api, quotation,
    quote_api, quote_products_api, po_status, internal_company, po_api, po_api_1, GST, quote_line_items,
    task_api, taskCategory, multi_master_attachment, get_master_attachment, importPO, gst_treatment, gst_slab, org_type, company_type,
    store_type, inventory_list_prod, operationListFORNC, store_api, store_api2, purchase_inward, poGet, poInward, po_inv_items, po_api_with_inv,
    transactionmaster,
    status_api,
    sales_order_api,
    so_status, bi_getTypes, bi_getReason, bi_getProductEmp, bi_getProductOpr, bi_getProdMonthly, bi_getReasonMonthly, bi_getProductReason, bi_getEmployeeOpr, bi_getEmpReason, bi_getEmployeeProd, bi_getProductReasonStack, bi_getProductOprStack, bi_getProductEmpStack, material_type_api
    , bi_nc_age,
    add_role,
    get_role,
    update_role,
    delete_role,
    add_user,
    get_user,
    get_modules,
    work_order,
    job_card,
    production_data_api,
    so_line_item, document_type_api, emp_doc_type, bi_production_data_lot, bi_production_data_product,
    bi_lot_produced_qty, bi_client_campaign, bi_create_client_campaign, bi_get_client_campaign, bi_update_client_campaign, bi_delete_client_campaign, raw_material_type, inspection_status,
    tool_type_family,
    tool_subfamily,
    tool_grade,
    po_api_with_inv_node,
    purchase_inward_node,
    production_data_excel,
    rmi_api,
    tool_fixture_product,
    tool_fixture_operation,
    delete_user,
    po_inv_items_node,
    material_code,
    available_conversion_qty,
    conversion_sheet,
    machine_model, rmiList_for_cutlot, product_by_rmi, cutlot_images, delete_cutlot_images,
    save_conversion_sheet, update_conversion_sheet, bar_length_detail, cutlot,
    challan_type,
    CHALLAN_STATE,
    CHALLAN,
    CHALLAN_TRANSPORT,
    CHALLAN_INTERNALTRANSPORT,
    CHALLAN_GOODSMOVEMENTCHALLENSTATE,
    CHALLAN_GETLOTFROMPRODUCTTYPE,
    CHALLAN_GETPRODUCTFROMLOT,
    CHALLAN_GETCURRENTSTOCKFOR_CUTLOTCHALLAN,
    LASTCHALLAN,
    nc_api,
    GET_RMI_IMAGES,
    DELETE_RMI_IMAGES,
    UPLOAD_RMI_IMAGES,
    stock_avail_api,
    workOrder,
    inspection_type,
    jobCard,
    CHALLAN_NEW,
    stock_by_product,
    stock_by_transaction,
    stock_availability_report,
    stock_ledger_report,
    work_order_excel,
    dispatch_api,
    party_contact_new,
    HSN_master,
    product_excel,
    delete_quote_product,
    po_line_item,
    transporter_api,
    dispatch_line_items,
    dispatch_pkg_store_qty,
    dispatch_prev_rec,
    stock_avail_api_v3,
    gst_details_api,
    corrective_api,
    quick_view_api,
    preventive_api, product_operation_bulk_operation, wo_product_routes, production_data_api_accumulated_qty,
    CHALLAN_EXCEL,
    job_card_excel, product_operation_template, bulk_prod_operation_upload,
    check_job_card_api, product_wise_bom_prod_plan, stock_avail_multi_prod,
    getPOTemplate,
    operation_template, production_plan,
    adjustment_reason, operatorList_availability,
    bi_jobcard_efficiency,
    material_request,
    bi_dispatch,
    quick_view_excel,
    bi_nc_reason,
    global_routing,
    bi_disposal_action,
    emp_ncCount_excl, create_mr_wo_jc, create_mr_wo_jcV3,
    shift_master_api, workOrder_count_by_status, privileges,
    dispatch_excel,
    onBoard_supplier_mst,
    onBoard_customer_mst,
    onBoard_operation_mst,
    onBoard_machine_mst,
    onBoard_product_mst,
    masters_count,
    industry_type,
    material_line_item,
    vendor_type,
    break_code,
    onBoard_location_mst,
    onBoard_store_mst,
    operatorAttendance,
    job_log_summary,
    change_password,
    designation_api, material_request_pp, material_request_ppV3,    
    CHALLAN_ITC_REPORT,
    pi_wo_po,
    pi_delete_items,
    jobCardBulk,
    sales_order_with_soid,
    customer_rpt,
    supplier_rpt,
    attrib_api,
    party_attributes,
    product_light_weight,
    contact_relation,
    rmi_report,
    proforma_invoice,
    payment_receipt,
    prod_report_fashion,
    biometric_attendance,
    biometric_attendance_report,
} from "../constant/index";
import client3 from "../constant/SuperAdmin_AxiosInstance";


export const fetchProductApi = () => {
    return axios.get(`${process.env.PUBLIC_URL}/api/product.json`);
};

export const fetchChatApi1 = () => {
    return axios.get(`${process.env.PUBLIC_URL}/api/chatMember.json`);
};

export const fetchChatApi2 = () => {
    return axios.get(`${process.env.PUBLIC_URL}/api/chat.chats.json`);
};

export const fetchEmailApi = () => {
    return axios.get(`${process.env.PUBLIC_URL}/api/email.json`);
};

export const fetchBookmaekApi = () => {
    return axios.get(`${process.env.PUBLIC_URL}/api/bookmark.json`);
};

export const fetchTodoApi = () => {
    return axios.get(`${process.env.PUBLIC_URL}/api/todo.json`);
};

export const fetchTaskApi = () => {
    return axios.get(`${process.env.PUBLIC_URL}/api/task.json`);
};


// PCMS New Development API's

export const getCountry = () => {                                           // get country 
    return client1.get(country_ms);
}

export const get_country = (payload) => {                                           // new country api 
    return client2.get(country_api, { params: payload });
}

export const getState = (payload) => {                                     // get state by country
    return client1.post(state_ms, payload);
}

export const get_state = (payload) => {                                           // new state api 
    return client2.get(state_api, { params: payload });
}

export const getCity = (payload) => {                                      // get city by state 
    return client1.post(city_ms, payload);
}

export const get_city = (payload) => {                                           // new city api 
    return client2.get(city_api, { params: payload });
}

export const getMarketGroup = () => {                                      // get Market Group 
    return client1.get(market_group_ms);
}

export const getProductCategory = () => {                                  // get Product Category 
    return client1.get(prod_cat_ms);
}

export const getProductFamilyAll = () => {                                  // get Product Family All 
    return client1.get(prod_family_ms);
}

export const getProductTypeAll = () => {                                  // get Product Type All 
    return client1.get(prod_type_ms);
}

export const getCustomerAll = () => {                                  // get Customer All 
    return client1.get(customer_ms);
}

export const getCustomerTypeAll = () => {                                  // get Customer Type All 
    return client1.get(customer_type_ms);
}

export const getSupplierTypeAll = () => {                                  // get Supplier Type All 
    return client1.get(supplier_type_ms);
}

export const getCompanyAll = () => {                                  // get Company All 
    return client1.get(company_ms);
}

export const getLocTypeAll = () => {                                  // get Location Type All 
    return client1.get(loc_type_ms);
}

export const getLocationAll = () => {                                  // get Location All 
    return client1.get(location_ms);
}

export const getMachineCatAll = () => {                                  // get Machine category All 
    return client1.get(machine_cat_ms);
}

export const getBankAll = () => {                                       // get Bank All 
    return client1.get(bank_ms);
}

export const getCustomerCSV = () => {                                       // get Customer CSV 
    return client1.get(customer_csv);
}

export const getSupplierCSV = () => {                                       // get Supplier CSV 
    return client1.get(supplier_csv);
}

// product family
export const getProductFamily = (payload) => {                            // get product family paginate
    return client1.post(product_family_lst, payload);
}

export const createProductFamily = (payload) => {                            // create product family
    return client1.post(product_family, payload);
}

export const updateProductFamily = (payload) => {                            // update product family
    return client1.patch(product_family, payload);
}

export const deleteProductFamily = (id) => {                            // delete product family
    return client1.delete(product_family + id + "/");
}
// product family

// product type
export const getProductType = (payload) => {                            // get product type
    return client2.get(product_type, { params: payload });
}

export const createProductType = (payload) => {                            // create product type
    return client2.post(product_type, payload);
}

export const updateProductType = (payload) => {                            // update product type
    return client2.patch(product_type, payload);
}

export const deleteProductType = (payload) => {                            // delete product type
    return client2.delete(product_type, { params: payload });
}
// product type


// Product => products
export const getProducts = (payload) => {                            // get products
    return client1.post(product_lst, payload);
}
// Product => products


// product Operation Category
export const getOperationCat = (payload) => {                               // create operation category
    return client2.get(operation_cat, { params: payload });
}

export const createOperationCat = (payload) => {                               // read operation category
    return client2.post(operation_cat, payload);
}

export const updateOperationCat = (payload) => {                               // read operation category
    return client2.patch(operation_cat, payload);
}

export const deleteOperationCat = (payload) => {                               // read operation category
    return client2.delete(operation_cat, { params: payload });
}

// product Operation Category

// product Operation Master
export const createOperation = (payload) => {                         // create operation master
    return client2.post(operation, payload);
}

export const getOperation = (payload) => {                            // read operation master
    return client2.get(operation, { params: payload });
}

export const updateOperation = (payload) => {                         // update operation master
    return client2.put(operation, payload);
}

export const deleteOperation = (payload) => {                         // delete operation master
    return client2.delete(operation, { params: payload });
}

export const operationRegister = (payload) => {                       // delete operation register
    return client1.post(operation_reg, payload);
}

export const getRegisterList = () => {                                // get register mapping list
    return client1.get(register_lst);
}
// product Operation Master


// product Location
// export const getLocation = () => {                                  // get all location
//     return client1.get(location_lst);
// }

// product Location

// product Machine Category
export const machineCategory = (payload) => {                          // get machine category paginate
    return client2.post(machine_cat, payload);
}

export const getMachineCategory = (payload) => {
    return client2.get(machine_cat, { params: payload });
}

export const saveMachineCategory = (payload) => {
    return client2.post(machine_cat, payload);
}

export const updateMachineCategory = (payload) => {
    return client2.patch(machine_cat, payload);
}

export const deleteMachineCategory = (payload) => {
    return client2.delete(machine_cat, { params: payload });
}

// product Machine Category


// product Machine Assets
export const machineAsset = (payload) => {                           // create, read, update, delete machine assets
    return client1.post(machine_asset, payload);
}
// product Machine Assets


// Customer
// export const getCustomer = (payload) => {                            // get customer with pagination
//     return client1.post(customer_lst, payload);
// }

export const getCustomerDetail = (paylaod) => {                      // get single customer details
    return client1.post(single_customer, paylaod);
}

export const createCustomer_Contact = (payload) => {                 // create customer and contact
    return client1.post(cust_contact_save, payload);
}

export const updateCustomer_Contact = (payload) => {                 // update customer and contact
    return client1.patch(cust_contact_update, payload);
}

export const removeCustomer_Contact = (payload) => {                 // remove contact details
    return client1.post(cust_contact_remove, payload);
}

export const createBilling_Shipping = (payload) => {                 // create customer billing & shipping address
    return client1.post(cust_bill_shipp_save, payload);
}

export const updateBilling_Shipping = (payload) => {                 // update customer billing & shipping address
    return client1.patch(cust_bill_shipp_update, payload);
}

export const removeBilling_Shipping = (payload) => {                 // remove customer billing & shipping address
    return client1.post(cust_bill_shipp_remove, payload);
}

export const createOther_Detail = (payload) => {                    // create customer billing details
    return client1.post(cust_billing_save, payload);
}

export const updateOther_Detail = (payload) => {                    // update customer billing details
    return client1.patch(cust_billing_update, payload);
}

export const createBank_Detail = (payload) => {                     // create customer bank details
    return client1.post(cust_bank_save, payload);
}

export const updateBank_Detail = (payload) => {                     // update customer bank details
    return client1.patch(cust_bank_update, payload);
}

export const removeBank_Detail = (payload) => {                     // remove customer bank details
    return client1.post(cust_bank_remove, payload);
}

// export const deleteCustomer = (paylaod) => {                             // delete customer 
//     return client1.post(cust_delete, paylaod);
// }

export const getContactByCustomer = (payload) => {
    return client1.post(get_ContactsByCustomer, payload);            // get contact details by customer (partyCode)
}

export const getProductByCustomer = (payload) => {
    return client1.post(get_ProductByCustomer, payload);            // get product details by customer (partyCode)
}

export const getLotsByCustomer = (payload) => {
    return client1.post(get_LotsByCustomer, payload);            // get lots details by customer (partyCode)
}

export const editCustomer_Detail = (payload) => {
    return client1.post(cust_edit, payload);                    // edit customer details for details (custId)
}

// Customer

// Supplier
// export const getSupplier = (payload) => {                           // create supplier and address
//     return client1.post(supplier_lst, payload);
// }

export const getSupplierDetail = (paylaod) => {                      // get single supplier details
    return client1.post(single_supplier, paylaod);
}

// export const deleteSupplier = (paylaod) => {                             // delete supplier 
//     return client1.post(supp_delete, paylaod);
// }

export const createSupplier_Address = (payload) => {                 // create supplier details and address details
    return client1.post(supp_addr_save, payload);
}

export const createSupplier_Contact = (payload) => {                 // create supplier contact details
    return client1.post(supp_contact_save, payload);
}
// Supplier





export const getNCList = (payload, signal) => {
    return client2.get(nc_api, { params: payload, ...signal });
}

export const getJobTagList = (payload, signal) => {
    return client2.get(nc_JobTag, { params: payload, ...signal });
}


export const getLotNumList = (payload) => {
    return client2.get(lotNum, { params: payload });
}

// product list
export const getProdList = (payload, signal) => {
    return client2.get(prodList, { params: payload, ...signal });
}

export const saveProduct = (payload) => {
    return client2.post(prodList, payload);
}

export const updateProduct = (payload) => {
    return client2.patch(prodList, payload);
}

export const deleteProduct = (payload) => {
    return client2.delete(prodList, { params: payload });
}

export const productExcel = (payload) => {
    return client2.get(product_excel, { params: payload });
}



export const oldgetProdList = (payload) => {
    return client1.post(oldprodList, payload);
}
// operation list
export const getOperationList = (payload) => {
    return client2.get(operationList, { params: payload });
}
export const getOperationListForNc = (params, signal) => {
    return client2.get(operationListFORNC, { params: params, ...signal });
}

// Machine list
export const getMachineList = (payload, signal) => {
    return client2.get(machineList, { params: payload, ...signal });
}

export const saveMachine = (payload) => {
    return client2.post(machineList, payload);
}

export const updateMachine = (payload) => {
    return client2.patch(machineList, payload);
}

export const deleteMachine = (payload) => {
    return client2.delete(machineList, { params: payload });
}
// ---------------------------------------------------------

// NC Reason list
export const getNCReasonList = (payload, signal) => {
    return client2.get(ncReasonList, signal, payload);
}
// disposal action list
export const getDisposalActionList = (payload, signal) => {
    return client2.get(disposalActionList, signal, payload);
}

// save nc
// export const saveNCAPI = (payload) => {
//     return client2.post(nc_api, payload);
// }

export const saveNCAPI = (payload) => {
    return client2.post(saveBulkNC, payload);
}

export const updateNCAPI = (payload) => {
    return client2.put(nc_api, payload);
}

export const deleteNCAPI = (payload) => {
    return client2.delete(nc_api, { params: payload });
}

export const getRawMaterialSpec = (payload) => {
    return client1.post(rawMaterialSpec, payload);
}
export const getToolList = (payload) => {
    return client1.post(toolList, payload);
}
export const getOperatorList = (params, signal) => {
    return client2.get(operatorList, { params: params, ...signal });
}
export const getOperatorList_availability = (params, signal) => {
    return client2.get(operatorList_availability, { params: params, ...signal });
}

export const getEmpAttendance = (params, signal) => {
    return client2.get(operatorAttendance, { params: params, ...signal });
}

export const createEmpApi = (payload) => {
    return client2.post(operatorList, payload);
}
export const updateEmpApi = (payload) => {
    return client2.put(operatorList, payload);
}

export const deleteEmpApi = (payload) => {
    return client2.delete(operatorList, { params: payload });
}



export const getGaugeList = (payload) => {
    return client1.post(gaugeList, payload);
}
export const getSFGList = (payload) => {
    return client1.post(SFGList, payload);
}
export const getSemiFinishGoodsList = (payload) => {
    return client1.post(SemiFinishProdList, payload);
}
export const getProdTypeList = (payload) => {
    return client1.post(productTypeList, payload);
}
export const getmaterialList = (payload, signal) => {
    return client1.get(materialList, payload, signal);
}

export const getProdCatList = (payload) => {
    return client1.get(productCatList, payload);
}
export const getCurrList = (payload) => {
    return client1.get(currList, payload);
}

export const getToolGradeList = (payload) => {
    return client1.post(toolGrade, payload);
}
export const getToolFamilyList = (payload) => {
    return client1.post(toolFamily, payload);
}
export const getMarketGrp = (payload) => {
    return client1.get(marketGrp, payload);
}
export const getProdFamily = (payload) => {
    return client1.get(productFamily, payload);
}
export const getPartyList = (payload) => {
    return client1.post(partyList, payload);
}

// Party Contacts
export const get_party_contact = (payload) => {
    return client2.get(party_contact, { params: payload });
}

export const delete_party_contact = (payload) => {
    return client2.delete(party_contact, { params: payload });
}

// Customer Master
export const getCustomer = (payload) => {
    return client2.get(customer, { params: payload });
}

export const saveCustomer = (payload) => {
    return client2.post(customer, payload);
}

export const updateCustomer = (payload) => {
    return client2.patch(customer, payload);
}

export const deleteCustomer = (payload) => {
    return client2.delete(customer, { params: payload });
}


// Supplier Customer Common
export const fetch_Supp_Customer = (payload) => {
    return client2.get(customer, { params: payload });
}

export const get_customer_rpt = (payload) => {
    return client2.get(customer_rpt, { params: payload });
}

export const get_supplier_rpt = (payload) => {
    return client2.get(supplier_rpt, { params: payload });
}

// ---------------------------------------------------------

// Customer type Master
export const getCustomerType = (payload) => {
    return client2.get(customerType, { params: payload });
}

export const saveCustomerType = (payload) => {
    return client2.post(customerType, payload);
}

export const updateCustomerType = (payload) => {
    return client2.patch(customerType, payload);
}

export const deleteCustomerType = (payload) => {
    return client2.delete(customerType, { params: payload });
}

// ---------------------------------------------------------

// Supplier Master
export const getSupplier = (payload) => {
    return client2.get(supplier, { params: payload });
}

export const saveSupplier = (payload) => {
    return client2.post(supplier, payload);
}

export const updateSupplier = (payload) => {
    return client2.patch(supplier, payload);
}

export const deleteSupplier = (payload) => {
    return client2.delete(supplier, { params: payload });
}

// ---------------------------------------------------------

// Supplier type Master
export const getSupplierType = (payload) => {
    return client2.get(supplierType, { params: payload });
}

export const saveSupplierType = (payload) => {
    return client2.post(supplierType, payload);
}

export const updateSupplierType = (payload) => {
    return client2.patch(supplierType, payload);
}

export const deleteSupplierType = (payload) => {
    return client2.delete(supplierType, { params: payload });
}

// ---------------------------------------------------------

// NC Reason Master
export const getNCReason = (payload) => {
    return client2.get(ncReason, { params: payload });
}

export const saveNCReason = (payload) => {
    return client2.post(ncReason, payload);
}

export const updateNCReason = (payload) => {
    return client2.patch(ncReason, payload);
}

export const deleteNCReason = (payload) => {
    return client2.delete(ncReason, { params: payload });
}

// ---------------------------------------------------------


// NC Status
export const getNCStatus = (payload) => {
    return client2.get(ncStatus, { params: payload });
}

export const saveNCStatus = (payload) => {
    return client2.post(ncStatus, payload);
}

export const updateNCStatus = (payload) => {
    return client2.patch(ncStatus, payload);
}

export const deleteNCStatus = (payload) => {
    return client2.delete(ncStatus, { params: payload });
}

// ---------------------------------------------------------

//----------------------------------------------------------
//Task Category

export const getTaskCategory = (payload) => {
    return client2.get(taskCategory, { params: payload });
}

export const saveTaskCategory = (payload) => {
    return client2.post(taskCategory, payload);
}

export const updateTaskCategory = (payload) => {
    return client2.patch(taskCategory, payload);
}

export const deletTaskCategory = (payload) => {
    return client2.delete(taskCategory, { params: payload });
}


// Task Master




//---------------------------------------------------------
// Location type
export const getLoctionType = (payload) => {
    return client2.get(locationType, { params: payload });
}

export const saveLocationType = (payload) => {
    return client2.post(locationType, payload);
}

export const updateLocationType = (payload) => {
    return client2.patch(locationType, payload);
}

export const deleteLocationType = (payload) => {
    return client2.delete(locationType, { params: payload });
}

// ---------------------------------------------------------

// Location Master
export const getLocation = (payload) => {
    return client2.get(location, { params: payload });
}

export const saveLocation = (payload) => {
    return client2.post(location, payload);
}

export const updateLocation = (payload) => {
    return client2.patch(location, payload);
}

export const deleteLocation = (payload) => {
    return client2.delete(location, { params: payload });
}

// ---------------------------------------------------------

// Location Mapping
export const getLocationMapping = (payload) => {
    return client1.get(locationMapping, { params: payload });
}

export const saveLocationMapping = (payload) => {
    return client1.post(locationMapping, payload);
}

export const updateLocationMapping = (payload) => {
    return client1.patch(locationMapping, payload);
}

export const deleteLocationMapping = (payload) => {
    return client1.delete(locationMapping, { params: payload });
}

// ---------------------------------------------------------

// User role
export const getUserRole = (payload) => {
    return client1.get(roles, { params: payload });
}

export const saveUserRole = (payload) => {
    return client1.post(roles, payload);
}

export const updateUserRole = (payload) => {
    return client1.patch(roles, payload);
}

export const deleteUpdateRole = (payload) => {
    return client1.delete(roles, { params: payload });
}

// ---------------------------------------------------------

// User application user
export const getAppUser = () => {
    return client1.get(app_user);
}

export const saveAppUser = (payload) => {
    return client1.post(app_user, payload);
}

export const updateAppUser = (payload) => {
    return client1.patch(app_user, payload);
}

export const deleteAppUser = (payload) => {
    return client1.delete(app_user, { params: payload });
}

// ---------------------------------------------------------

// product category
export const getProdCategory = (payload) => {                               // get product category
    return client2.get(product_cat, { params: payload });
}

export const createProdCategory = (payload) => {                            // create product category
    return client2.post(product_cat, payload);
}

export const updateProdCategory = (payload) => {                            // update product category
    return client2.patch(product_cat, payload);
}

export const deleteProdCategory = (payload) => {                            // delete product category
    return client2.delete(product_cat, { params: payload });
}
// product category

// Market group
export const getMarket_Group = (payload) => {                               // get market group
    return client2.get(market_group, { params: payload });
}

export const createMarketGroup = (payload) => {                            // create market group
    return client2.post(market_group, payload);
}

export const updateMarketGroup = (payload) => {                            // update market group
    return client2.patch(market_group, payload);
}

export const deleteMarketGroup = (payload) => {                            // delete market group
    return client2.delete(market_group, { params: payload });
}
// product group

// Product family
export const getProduct_Family = (payload) => {                               // get Product family
    return client2.get(prod_family, { params: payload });
}

export const createProduct_Family = (payload) => {                            // create Product family
    return client2.post(prod_family, payload);
}

export const updateProduct_Family = (payload) => {                            // update Product family
    return client2.patch(prod_family, payload);
}

export const deleteProduct_Family = (payload) => {                            // delete Product family
    return client2.delete(prod_family, { params: payload });
}
// Product family

// Product image
export const getProductImage = (payload) => {                               // get Product image
    return client2.get(product_image, { params: payload });
}

export const createProductImage = (payload) => {                            // create Product image
    return client2.post(product_image, payload);
}

export const updateProductImage = (payload) => {                            // update Product image
    return client2.patch(product_image, payload);
}

export const deleteProductImage = (payload) => {                            // delete Product image
    return client2.delete(product_image, { params: payload });
}
// Product image


// Product attribute
export const getProductAttribute = (payload) => {                               // get Product attribute
    return client2.get(product_attribute, { params: payload });
}

export const createProductAttribute = (payload) => {                            // create Product attribute
    return client2.post(product_attribute, payload);
}

export const updateProductAttribute = (payload) => {                            // update Product attribute
    return client2.patch(product_attribute, payload);
}

export const deleteProductAttribute = (payload) => {                            // delete Product attribute
    return client2.delete(product_attribute, { params: payload });
}
// Product attribute


// Attribute master
export const getAttribute = (payload) => {                               // get attribute master
    return client2.get(attribute, { params: payload });
}

export const createAttribute = (payload) => {                            // create attribute master
    return client2.post(attribute, payload);
}

export const updateAttribute = (payload) => {                            // update attribute master
    return client2.patch(attribute, payload);
}

export const deleteAttribute = (payload) => {                            // delete attribute master
    return client2.delete(attribute, { params: payload });
}
// Attribute master

// Unit of measurement
export const getUOM = (payload) => {                               // get unit of measurement
    return client2.get(unit_of_measurement, { params: payload });
}

export const createUOM = (payload) => {                            // create unit of measurement
    return client1.post(unit_of_measurement, payload);
}

export const updateUOM = (payload) => {                            // update unit of measurement
    return client1.patch(unit_of_measurement, payload);
}

export const deleteUOM = (payload) => {                            // delete unit of measurement
    return client1.delete(unit_of_measurement, { params: payload });
}
// Unit of measurement

export const createProductApi = (payload) => {
    return client1.post(createproduct, payload);
}
export const createPartyAgaintProdApi = (payload) => {
    return client1.post(partyAgainstProd, payload);
}

export const getNCImageApi = (payload) => {
    return client2.get(ncImage, { params: payload });
}

export const uploadNCImageApi = (payload) => {
    return client2.post(ncImage, payload);
}
export const deleteNCImageApi = (payload) => {
    return client2.delete(ncImage, { params: payload });
}
export const updateNCImageApi = (payload) => {
    return client2.patch(ncImage, payload);
}

export const getNCExcel = (payload) => {
    return client2.get(ncExcel, { params: payload });
}



// Module apis
export const getModuleList = (params) => {
    return client1.get(moduleList, { params: params });
}


// Role apis
export const getRoles = (params) => {
    return client1.get(roleList, { params: params });
}

// Role mapping
export const mapUserRole = (payload) => {
    return client1.post(roleMap, payload);
}


// Role creation
export const createRole = (payload) => {
    return client1.post(roleList, payload);
}

export const updateRoleApi = (payload, params) => {
    return client1.put(roleList, payload, { params: params });
}
export const deleteRoleApi = (params) => {
    return client1.delete(roleList, { params: params });
}

//cognitio role api's 


export const save_role_api = (payload) => {
    return client3.post(add_role, payload)
}

export const get_role_api = () => {
    return client3.get(get_role)
}

export const update_role_api = (id, payload) => {
    return client3.put(update_role, payload, { params: id })
}

export const delete_role_api = (payload) => {
    return client3.delete(delete_role, { params: payload })
}

//cognito user api 

// export const save_user_api = (payload) => {
//     return client3.post(add_user, payload)
// }

export const save_user_api = (payload) => {
    return client2.post(add_user, payload)
}

export const get_user_api = (payload) => {
    return client3.get(get_user, { params: payload })
}

// export const delete_user_api = (payload) => {
//     return client3.delete(delete_user, { params: payload })
// }

export const delete_user_api = (payload) => {
    return client2.delete(delete_user, { params: payload })
}

//cognito module 

export const get_modules_api = () => {
    return client3.get(get_modules)
}


// product configuration
export const getRoutesApi = (params) => {
    return client2.get(routesapi, { params: params });
}

export const createRoutesApi = (payload) => {
    return client2.post(routesapi, payload);
}

export const updateRoutesApi = (payload) => {
    return client2.patch(routesapi, payload);
}

export const delteRoutesApi = (params) => {
    return client2.delete(routesapi, { params: params });
}

export const getOperationApi = (params) => {
    return client2.get(opeartaionApi, { params: params });
}

export const createProdConfig = (payload) => {
    return client2.post(prodConfig, payload);
}
export const updateProdConfig = (payload) => {
    return client2.patch(prodConfig, payload);
}
export const uploadMultiImage = (payload) => {
    return client2.post(multiImage, payload);
}

export const getProdConfig = (params) => {
    return client2.get(prod_config, { params: params });
}

export const getProdMachines = (params) => {
    return client2.get(prod_machine, { params: params });
}
export const deleteProdMachine = (params) => {
    return client2.delete(prod_machine, { params: params });
}

export const createProdMachines = (payload) => {
    return client2.post(prod_machine, payload);
}

export const updateProdMachines = (payload) => {
    return client2.patch(prod_machine, payload);
}

export const getProdImages = (params) => {
    return client2.get(prod_images, { params: params });
}
export const delProdImages = (params) => {
    return client2.delete(prod_images, { params: params });
}
export const uploadFiles = (payload) => {
    return client2.post(prod_images, payload);
}

export const deleteProdOperation = (params) => {
    return client2.delete(prod_config, { params: params });
}


// bill of material
export const getBOM = (params) => {
    return client2.get(bom, { params: params });
}

export const getBOMListBYUUID = (params) => {
    return client2.get(bom_list_by_uuid, { params: params });
}

export const getBOMByProduct = (params) => {
    return client2.get(bom_list_by_product, { params: params });
}

export const createBOM = (payload) => {
    return client2.post(bom, payload);
}
export const updateBOM = (payload) => {
    return client2.put(bom, payload);
}
export const deleteBOM = (params) => {
    return client2.delete(bom, { params: params });
}
export const deleteBOMLineItem = (params) => {
    return client2.delete(bomLineItem, { params: params });
}

// Raw material product
export const getRawMaterialProduct = (payload) => {                               // get raw material product
    return client1.get(raw_material_api, { params: payload });
}

export const createRawMaterialProduct = (payload) => {                            // create raw material product
    return client1.post(raw_material_api, payload);
}

export const updateRawMaterialProduct = (payload) => {                            // update raw material product
    return client1.patch(raw_material_api, payload);
}

export const deleteRawMaterialProduct = (payload) => {                            // delete raw material product
    return client1.delete(raw_material_api, { params: payload });
}
// Raw material product

// Quotation 

export const getInternalCompany = (payload) => {                            // get internal company
    return client2.get(internal_company, { params: payload });
}

export const getQuotation = (payload) => {                               // get quotation
    return client2.get(quote_api, { params: payload });
}

export const deleteQuotation = (payload) => {                            // delete quotation
    return client2.delete(quotation, { params: payload });
}
// Quotation 

// Quotation product multi save
export const createQuotation = (payload) => {                            // create quotation product multisave
    return client2.post(quotation, payload);
}

export const updateQuotation = (payload) => {                            // update quotation product multisave
    return client2.put(quotation, payload);
}

export const deleteQuoteProduct = (payload) => {                            // delete quotation product
    return client2.delete(delete_quote_product, { params: payload });
}
// Quotation product multi save

// Quotation product fetch
export const getQuote_Products = (payload) => {                               // get quotation products
    return client1.get(quote_products_api, { params: payload });
}
// Quotation product fetch

// PO
export const getPOStatus = (payload) => {                            // get po status
    return client2.get(po_status, { params: payload });
}

export const createPO = (payload) => {                               // create PO
    return client2.post(po_api, payload);
}
export const getPO = (payload) => {                                 // get PO
    return client2.get(po_api_1, { params: payload });
}
export const deletePO = (payload) => {                               // delete PO
    return client2.delete(po_api, { params: payload });
}
export const updatePO = (payload) => {                               // update PO
    return client2.patch(po_api, payload);
}

export const deletePO_line_item = (payload) => {                     // delete PO
    return client2.delete(po_line_item, { params: payload });
}

export const get_po_template = () => {                               // download PO template
    return client2.get(getPOTemplate);
}


export const getGST = (payload) => {                                  // get gst details
    return client2.post(GST, payload);
}

export const getQuoteLineItems = (payload) => {                        // get quotation
    return client1.get(quote_line_items, { params: payload });
}

// Task master
export const getTask = (payload) => {
    // return client1.get(task_api, { params: payload });
    return client2.get(task_api, { params: payload });
}

export const saveTask = (payload) => {
    // return client1.post(task_api, payload);
    return client2.post(task_api, payload);
}

export const updateTask = (payload) => {
    // return client1.patch(task_api, payload);
    return client2.patch(task_api, payload);
}

export const deleteTask = (payload) => {
    // return client1.delete(task_api, { params: payload });
    return client2.delete(task_api, { params: payload });
}
// Task master

export const master_multi_file_upload = (payload) => {
    // return client1.post(multi_master_attachment, payload);
    return client2.post(multi_master_attachment, payload);
}

export const get_master_files = (payload) => {
    // return client1.get(get_master_attachment, { params: payload });
    return client2.get(get_master_attachment, { params: payload });
}

export const del_master_file = (payload) => {
    // return client1.delete(get_master_attachment, { params: payload });
    return client2.delete(get_master_attachment, { params: payload });
}

export const update_master_file = (payload) => {
    return client2.patch(get_master_attachment, payload);
}

export const import_po = (payload) => {
    return client1.post(importPO, payload);
}

// GST treatment master (GST Registration Type)
export const get_gst_treatment = (payload) => {
    return client2.get(gst_treatment, { params: payload });
}

export const save_gst_treatment = (payload) => {
    return client2.post(gst_treatment, payload);
}

export const update_gst_treatment = (payload) => {
    return client2.patch(gst_treatment, payload);
}

export const delete_gst_treatment = (payload) => {
    return client2.delete(gst_treatment, { params: payload });
}
// GST treatment master (GST Registration Type)


// GST slab master
export const get_gst_slab = (payload) => {
    return client2.get(gst_slab, { params: payload });
}

export const save_gst_slab = (payload) => {
    return client2.post(gst_slab, payload);
}

export const update_gst_slab = (payload) => {
    return client2.patch(gst_slab, payload);
}

export const delete_gst_slab = (payload) => {
    return client2.delete(gst_slab, { params: payload });
}
// GST slab master


// Organisation type master
export const get_org_type = (payload) => {
    return client2.get(org_type, { params: payload });
}

export const save_org_type = (payload) => {
    return client2.post(org_type, payload);
}

export const update_org_type = (payload) => {
    return client2.patch(org_type, payload);
}

export const delete_org_type = (payload) => {
    return client2.delete(org_type, { params: payload });
}
// Organisation type master


// Company type master
export const get_company_type = (payload) => {
    return client2.get(company_type, { params: payload });
}

export const save_company_type = (payload) => {
    return client2.post(company_type, payload);
}

export const update_company_type = (payload) => {
    return client2.patch(company_type, payload);
}

export const delete_company_type = (payload) => {
    return client2.delete(company_type, { params: payload });
}
// Company type master

//Internal Company 

export const save_internal_company = (paylaod) => {
    return client2.post(internal_company, paylaod);
}

export const update_internal_company = (paylaod) => {
    return client2.put(internal_company, paylaod);
}

export const delete_internal_company = (paylaod) => {
    return client2.delete(internal_company, { params: paylaod });
}

//Internal Company 

// Store type master
export const get_store_type = (payload) => {
    return client2.get(store_type, { params: payload });
}

export const save_store_type = (payload) => {
    return client2.post(store_type, payload);
}

export const update_store_type = (payload) => {
    return client2.patch(store_type, payload);
}

export const delete_store_type = (payload) => {
    return client2.delete(store_type, { params: payload });
}
// Store type master


// Store master
export const get_store = (payload) => {
    return client2.get(store_api, { params: payload });              // parent_id == null record in get list
}

export const get_store2 = (payload) => {
    return client2.get(store_api2, { params: payload });             // parent_id == null and also Shelf, Rack record in get list
}

export const save_store = (payload) => {
    return client2.post(store_api, payload);
}

export const update_store = (payload) => {
    return client2.patch(store_api, payload);
}

export const delete_store = (payload) => {
    return client2.delete(store_api, { params: payload });
}
// Store master

export const get_inventory_list = (payload) => {
    return client1.get(inventory_list_prod, { params: payload });
}

// Purchase inward master
export const get_purchaseInward = (payload) => {
    return client1.get(poInward, { params: payload });
}

export const get_purchaseInward_node = (payload) => {
    return client2.get(purchase_inward_node, { params: payload });
}

export const delete_purchaseInward = (payload) => {
    return client1.delete(poInward, { params: payload });
}
// Purchase inward master


// Purchase inward line items master
export const get_poInvItems = (payload) => {
    return client2.get(po_inv_items, { params: payload });
}

export const get_poInvItems_node = (payload) => {
    return client2.get(po_inv_items_node, { params: payload });
}

export const delete_poInvItems = (payload) => {
    return client1.delete(po_inv_items, { params: payload });
}

export const delete_pi_line_items = (payload) => {
    return client2.delete(pi_delete_items, { params: payload });
}
// Purchase inward line items master


// PO Inward with line items master
export const get_poWithLineItems = (payload) => {
    return client1.get(po_api_with_inv, { params: payload });
}

export const get_poWithLineItems_node = (payload) => {
    return client2.get(po_api_with_inv_node, { params: payload });
}
// PO Inward with line items master


// Purchase inward multisave master
export const save_purchase_inward = (payload) => {
    return client1.post(purchase_inward, payload);
}

export const save_purchase_inward_node = (payload) => {
    return client2.post(purchase_inward_node, payload);
}

export const update_purchase_inward = (payload) => {
    return client1.patch(purchase_inward, payload);
}

export const update_purchase_inward_node = (payload) => {
    return client2.put(purchase_inward_node, payload);
}

export const delete_purchase_inward = (payload) => {
    return client1.delete(purchase_inward, { params: payload });
}

export const delete_purchase_inward_node = (payload) => {
    return client2.delete(purchase_inward_node, { params: payload });
}

// Purchase inward multisave master


//Transaction Master 

export const getTransactionMaster = (payload) => {
    return client1.get(transactionmaster, { params: payload });
}

export const saveTransactionMaster = (payload) => {
    return client1.post(transactionmaster, payload);
}

export const updateTTransactionMaster = (payload) => {
    return client1.patch(transactionmaster, payload);
}

export const deletTransactionMaster = (payload) => {
    return client1.delete(transactionmaster, { params: payload });
}
//Transaction Master 

//Status Master 
export const getStatusMaster = (payload) => {
    return client2.get(status_api, { params: payload });
}

export const saveStatusMaster = (payload) => {
    return client2.post(status_api, payload);
}

export const updateStatusMaster = (payload) => {
    return client2.patch(status_api, payload);
}

export const deleteStatusMaster = (payload) => {
    return client2.delete(status_api, { params: payload });
}
//Status Master

// Sales order master
export const getSalesOrder = (payload) => {
    return client2.get(sales_order_api, { params: payload });
}

export const createSalesOrder = (payload) => {
    return client2.post(sales_order_api, payload);
}

export const updateSalesOrder = (payload) => {
    return client2.patch(sales_order_api, payload);
}

export const deleteSalesOrder = (payload) => {
    return client2.delete(sales_order_api, { params: payload });
}
// Sales order master

// Sales order product line items
export const deleteSOLineItem = (payload) => {
    return client2.delete(so_line_item, { params: payload });
}
// Sales order product line items

// Sales order status
export const getSOStatus = (payload) => {
    return client2.get(so_status, { params: payload });
}

export const createSOStatus = (payload) => {
    return client2.post(so_status, payload);
}

export const updateSOStatus = (payload) => {
    return client2.patch(so_status, payload);
}

export const deleteSOStatus = (payload) => {
    return client2.delete(so_status, { params: payload });
}
// Sales order status


// Material types for raw material
export const getMaterialType = (payload) => {
    return client2.get(material_type_api, { params: payload });
}

export const createMaterialType = (payload) => {
    return client2.post(material_type_api, payload);
}

export const updateMaterialType = (payload) => {
    return client2.patch(material_type_api, payload);
}

export const deleteMaterialType = (payload) => {
    return client2.delete(material_type_api, { params: payload });
}
// Material types for raw material

// Work order master
export const getWorkOrder = (payload) => {
    return client1.get(work_order, { params: payload });
}


export const createWorkOrder = (payload) => {
    return client1.post(work_order, payload);
}

export const updateWorkOrder = (payload) => {
    return client1.patch(work_order, payload);
}

export const deleteWorkOrder = (payload) => {
    return client1.delete(work_order, { params: payload });
}
// Work order master


//job card api's 

export const getJobCard = (payload) => {
    return client1.get(job_card, { params: payload });
}

export const createJobCard = (payload) => {
    return client1.post(job_card, payload);
}

export const updateJobCard = (payload) => {
    return client1.patch(job_card, payload);
}

export const deleteJobCard = (payload) => {
    return client1.delete(job_card, { params: payload });
}

//production data api's 

export const getProductionData = (payload) => {
    return client2.get(production_data_api, { params: payload });
}

export const getProductionData_Accumalation = (payload) => {
    return client2.get(production_data_api_accumulated_qty, { params: payload });
}

export const createProductionData = (payload) => {
    return client2.post(production_data_api, payload);
}

export const updateProductionData = (payload) => {
    return client2.put(production_data_api, payload);
}

export const deleteProductionData = (payload) => {
    return client2.delete(production_data_api, { params: payload });
}


// Transaction APIS

export const getBITypes = (payload, signal) => {
    return client2.get(bi_getTypes, { params: payload, ...signal, });
}

export const getBIReason = (payload, signal) => {
    return client2.get(bi_getReason, { params: payload, ...signal });
}

export const getBIProductEmp = (payload) => {
    return client2.get(bi_getProductEmp, { params: payload });
}

export const getBIProductEmpStack = (payload, signal) => {
    return client2.get(bi_getProductEmpStack, { params: payload, ...signal });
}

export const getBIProductOpr = (payload) => {
    return client2.get(bi_getProductOpr, { params: payload });
}

export const getBIProductOprStack = (payload, signal) => {
    return client2.get(bi_getProductOprStack, { params: payload, ...signal });
}

export const getBIProdMonthly = (payload, signal) => {
    return client2.get(bi_getProdMonthly, { params: payload, ...signal });
}

export const getBIReasonMonthly = (payload, signal) => {
    return client2.get(bi_getReasonMonthly, { params: payload, ...signal });
}

export const getBIProductReason = (payload) => {
    return client2.get(bi_getProductReason, { params: payload });
}
export const getBIProductReasonStack = (payload, signal) => {
    return client2.get(bi_getProductReasonStack, { params: payload, ...signal });
}

export const getBIEmployeeProd = (payload, signal) => {
    return client2.get(bi_getEmployeeProd, { params: payload, ...signal });
}

export const getBIEmployeeOpr = (payload, signal) => {
    return client2.get(bi_getEmployeeOpr, { params: payload, ...signal });
}

export const getBIEmpReason = (payload, signal) => {
    return client2.get(bi_getEmpReason, { params: payload, ...signal });
}

export const get_nc_age = (payload) => {
    return client2.get(bi_nc_age, { params: payload })
}



// doucment type
export const get_document_type = (payload) => {
    return client2.get(document_type_api, { params: payload })
}

export const update_document_type = (payload) => {
    return client2.patch(document_type_api, payload)
}

export const create_document_type = (payload) => {
    return client2.post(document_type_api, payload)
}

export const delete_document_type = (payload) => {
    return client2.delete(document_type_api, { params: payload })
}

// Designation master
export const getDesignationApi = (payload) => {
    return client2.get(designation_api, { params: payload })
}

export const updateDesignationApi = (payload) => {
    return client2.patch(designation_api, payload)
}

export const createDesignationApi = (payload) => {
    return client2.post(designation_api, payload)
}

export const deleteDesignationApi = (payload) => {
    return client2.delete(designation_api, { params: payload })
}
// Designation master

export const getDocTypes = (payload) => {
    return client1.get(emp_doc_type, { params: payload })
}

//Production Dashboard
export const getBILotWiseProdData = (payload, signal) => {
    return client2.get(bi_production_data_lot, { params: payload, ...signal, });
}

export const getBIProductWiseProdData = (payload, signal) => {
    return client2.get(bi_production_data_product, { params: payload, ...signal, });
}

export const getBILotProducedQty = (payload, signal) => {
    return client2.get(bi_lot_produced_qty, { params: payload, ...signal, });
}

export const get_client_campaign = (payload, signal) => {
    return client2.get(bi_client_campaign, { params: payload, ...signal, });
}

export const create_client_user_campaign = (payload) => {
    return client2.post(bi_create_client_campaign, payload);
}

export const update_client_user_campaign = (payload, uuid) => {
    return client2.put(bi_update_client_campaign, payload, { params: uuid });
}

export const delete_client_user_campaign = (payload) => {
    return client2.delete(bi_delete_client_campaign, { params: payload });
}

export const get_client_user_campaign = (payload) => {
    return client2.get(bi_get_client_campaign, { params: payload });
}


export const getRawMaterialType = () => {
    return client2.get(raw_material_type);
}

export const getInspectionStatus = () => {
    return client2.get(inspection_status);
}
// tool type family api's 

export const get_tool_type_family = (payload) => {
    return client2.get(tool_type_family, { params: payload });
}

export const create_tool_type_family = (payload) => {
    return client2.post(tool_type_family, payload);
}

export const update_tool_type_family = (payload) => {
    return client2.patch(tool_type_family, payload);
}

export const delete_tool_type_family = (id) => {
    return client2.delete(tool_type_family, { params: id });
}

// tool sub family api's 

export const get_tool_sub_family = (payload) => {
    return client2.get(tool_subfamily, { params: payload });
}

export const create_tool_sub_family = (payload) => {
    return client2.post(tool_subfamily, payload);
}

export const update_tool_sub_family = (payload) => {
    return client2.patch(tool_subfamily, payload);
}

export const delete_tool_sub_family = (id) => {
    return client2.delete(tool_subfamily, { params: id });
}

// tool grade api's 

export const get_tool_grade = (payload) => {
    return client2.get(tool_grade, { params: payload });
}

export const create_tool_grade = (payload) => {
    return client2.post(tool_grade, payload);
}

export const update_tool_grade = (payload) => {
    return client2.patch(tool_grade, payload);
}

export const delete_tool_grade = (id) => {
    return client2.delete(tool_grade, { params: id });
}

// Production data excel download
export const getProductionDataExcel = (payload) => {
    return client2.get(production_data_excel, { params: payload });
}

// RMI data 
export const get_rmi = (payload) => {
    return client2.get(rmi_api, { params: payload });
}
// tool product 
export const save_tool_fixture = (payload) => {
    return client1.post(tool_fixture_product, payload);
}

export const update_tool_fixture = (payload) => {
    return client1.patch(tool_fixture_product, payload);
}

//Tool & fixture opration linking 

export const get_tool_fixture_operation = (payload) => {
    return client2.get(tool_fixture_operation, { params: payload });
}

export const create_tool_fixture_operation = (payload) => {
    return client2.post(tool_fixture_operation, payload);
}

export const update_tool_fixture_operation = (payload) => {
    return client2.patch(tool_fixture_operation, payload);
}

export const delete_tool_fixture_operation = (id) => {
    return client2.delete(tool_fixture_operation, { params: id });
}

// Latest material code for rmi
export const get_material_code = () => {
    return client2.get(material_code);
}

// Bar Conversion sheet 
export const get_available_conversion_qty = (payload) => {
    return client2.get(available_conversion_qty, { params: payload });
}

export const get_conversion_sheet = (payload) => {
    return client2.get(conversion_sheet, { params: payload });
}

export const get_bar_length = (payload) => {
    return client2.get(bar_length_detail, { params: payload });
}

export const save_conversionSheet = (payload) => {
    return client2.post(save_conversion_sheet, payload);
}

export const update_conversionSheet = (payload) => {
    return client2.put(update_conversion_sheet, payload);
}

// Machine Model
export const get_machine_model = (payload) => {
    return client2.get(machine_model, { params: payload });
}


// product type
export const getCutlot = (payload) => {                            // get product type
    return client2.get(cutlot, { params: payload });
}

export const createCutlot = (payload) => {                            // create product type
    return client2.post(cutlot, payload);
}

export const updateCutlot = (payload) => {                            // update product type
    return client2.put(cutlot, payload);
}

export const deleteCutlot = (payload) => {                            // delete product type
    return client2.delete(cutlot, { params: payload });
}


// cutlot

export const RMI_list_for_Cutlot = (payload) => {
    return client2.get(rmiList_for_cutlot, { params: payload });
}

export const get_product_by_rmi = (payload) => {
    return client2.get(product_by_rmi, { params: payload });
}

export const get_cutlot_images = (payload) => {

    return client2.get(cutlot_images, { params: payload });
}

export const del_cutlot_images = (payload) => {
    return client2.delete(delete_cutlot_images, { params: payload });
}


// challan type
export const get_challan_type = (payload) => {                                // get challan type
    return client2.get(challan_type, { params: payload });
}

export const create_challan_type = (payload) => {                            // create challan type
    return client2.post(challan_type, payload);
}

export const update_challan_type = (payload) => {                            // update challan type
    return client2.patch(challan_type, payload);
}

export const delete_challan_type = (payload) => {                            // delete challan type
    return client2.delete(challan_type, { params: payload });
}


//Goods Challan 

export const get_challan_state = (payload) => {
    return client2.get(CHALLAN_STATE, { params: payload });
}


export const get_challan_transport = (payload) => {
    return client2.get(CHALLAN_TRANSPORT, { params: payload });
}
export const get_challan_internal_transport = (payload) => {
    return client2.get(CHALLAN_INTERNALTRANSPORT, { params: payload });
}

export const get_challan_goodsmovmet_challan_state = (payload) => {
    return client2.get(CHALLAN_GOODSMOVEMENTCHALLENSTATE, { params: payload });
}
export const get_lot_from_product_type = (payload) => {
    return client2.get(CHALLAN_GETLOTFROMPRODUCTTYPE, { params: payload });
}

export const get_product_from_lot = (payload) => {
    return client2.get(CHALLAN_GETPRODUCTFROMLOT, { params: payload });
}

export const get_current_stock_from_cut_lot = (payload) => {
    return client2.get(CHALLAN_GETCURRENTSTOCKFOR_CUTLOTCHALLAN, { params: payload });
}


export const get_challan = (payload) => {
    return client2.get(CHALLAN, { params: payload });
}
export const get_last_challan = (payload) => {
    return client2.get(LASTCHALLAN, { params: payload });
}

export const save_challan = (payload) => {
    return client2.post(CHALLAN_NEW, payload);
}

export const update_challan = (payload) => {
    return client2.put(CHALLAN_NEW, payload);
}

export const delete_challan = (payload) => {
    return client2.delete(CHALLAN_NEW, { params: payload });
}

export const Challan_Excel = (payload) => {
    return client2.get(CHALLAN_EXCEL, { params: payload });
}

export const Challan_itc_report = (payload) => {
    return client2.get(CHALLAN_ITC_REPORT, { params: payload });
}

//RMI Gallary to view images 

export const get_rmi_images = (payload) => {

    return client2.get(GET_RMI_IMAGES, { params: payload });
}

export const del_rmi_images = (payload) => {
    return client2.delete(DELETE_RMI_IMAGES, { params: payload });
}

export const upload_rmi_images = (payload) => {
    return client2.post(UPLOAD_RMI_IMAGES, payload);
}

// get stock availablity
export const getSTockAvilablity = (payload) => {
    return client2.get(stock_avail_api, { params: payload });
}

export const getSTockAvilablity_v3 = (payload) => {
    return client2.get(stock_avail_api_v3, { params: payload });
}

// New work order node api
export const get_WorkOrder = (payload) => {
    return client2.get(workOrder, { params: payload });
}

export const get_wo_count_by_status = (payload) => {
    return client2.get(workOrder_count_by_status, { params: payload });
}

export const create_WorkOrder = (payload) => {
    return client2.post(workOrder, payload);
}

export const update_WorkOrder = (payload) => {
    return client2.put(workOrder, payload);
}

export const delete_WorkOrder = (payload) => {
    return client2.delete(workOrder, { params: payload });
}

// work order report api 

// Production data excel download
export const getWorkOrderDataExcel = (payload) => {
    return client2.get(work_order_excel, { params: payload });
}

// Inspection type master
export const get_inspection_type = (payload) => {
    return client2.get(inspection_type, { params: payload });
}

export const create_inspection_type = (payload) => {
    return client2.post(inspection_type, payload);
}

export const update_inspection_type = (payload) => {
    return client2.patch(inspection_type, payload);
}

export const delete_inspection_type = (payload) => {
    return client2.delete(inspection_type, { params: payload });
}

// New job card node api
export const get_job_card = (payload) => {
    return client2.get(jobCard, { params: payload });
}

export const create_job_card = (payload) => {
    return client2.post(jobCard, payload);                      // Single job card creation api end point
}

export const create_job_card_bulk = (payload) => {
    return client2.post(jobCardBulk, payload);                  // Bulk job card creation on multiple operator api end point
}

export const update_job_card = (payload) => {
    return client2.put(jobCard, payload);
}

export const delete_job_card = (payload) => {
    return client2.delete(jobCard, { params: payload });
}

export const getjobcardDataExcel = (payload) => {
    return client2.get(job_card_excel, { params: payload });
}

export const get_job_log_summary = (payload) => {
    return client2.get(job_log_summary, { params: payload });
}

// Report node api
export const get_stock_by_product = (payload) => {
    return client2.get(stock_by_product, { params: payload });
}

export const get_stock_by_transaction = (payload) => {
    return client2.get(stock_by_transaction, { params: payload });
}

export const get_stock_availability_report = (payload) => {
    return client2.get(stock_availability_report, { params: payload });
}

export const get_stock_ledger_report = (payload) => {
    return client2.get(stock_ledger_report, { params: payload });
}

// dispatch api
export const get_dispatch = (payload) => {
    return client2.get(dispatch_api, { params: payload });
}

export const create_dispatch = (payload) => {
    return client2.post(dispatch_api, payload);
}

export const update_dispatch = (payload) => {
    return client2.put(dispatch_api, payload);
}

export const delete_dispatch = (payload) => {
    return client2.delete(dispatch_api, { params: payload });
}

export const get_dispatch_line_items = (payload) => {
    return client2.get(dispatch_line_items, { params: payload });
}

export const get_dispatch_pkg_store_qty = (payload) => {
    return client2.get(dispatch_pkg_store_qty, { params: payload });
}

export const get_dispatch_prev_rec = (payload) => {
    return client2.get(dispatch_prev_rec, { params: payload });
}

export const dispatchExcel = (payload) => {
    return client2.get(dispatch_excel, { params: payload });
}

// HSN Master                            
export const gethsn_master = (payload) => {
    return client2.get(HSN_master, { params: payload });
}

export const createhsn_master = (payload) => {
    return client2.post(HSN_master, payload);
}

export const updatehsn_master = (payload) => {
    return client2.patch(HSN_master, payload);
}

//Transporter Master
export const get_transporter = (payload) => {
    return client2.get(transporter_api, { params: payload });
}

export const create_transporter = (payload) => {
    return client2.post(transporter_api, payload);
}

export const update_transporter = (payload) => {
    return client2.patch(transporter_api, payload);
}

export const delete_transporter = (payload) => {
    return client2.delete(transporter_api, { params: payload });
}

// Fetch GST in details
export const get_GST_details = (payload) => {
    return client2.post(gst_details_api, payload);
}

// Corrective Action Master                            
export const get_corrective_action = (payload) => {
    return client2.get(corrective_api, { params: payload });
}

export const create_corrective_action = (payload) => {
    return client2.post(corrective_api, payload);
}

export const update_corrective_action = (payload) => {
    return client2.put(corrective_api, payload);
}

export const delete_corrective_action = (payload) => {
    return client2.delete(corrective_api, { params: payload });
}

// Preventive Action Master                            
export const get_preventive_action = (payload) => {
    return client2.get(preventive_api, { params: payload });
}

export const create_preventive_action = (payload) => {
    return client2.post(preventive_api, payload);
}

export const update_preventive_action = (payload) => {
    return client2.put(preventive_api, payload);
}

export const delete_preventive_action = (payload) => {
    return client2.delete(preventive_api, { params: payload });
}

// Quick View API
export const get_quick_view = (payload) => {
    return client2.get(quick_view_api, { params: payload });

}
export const bulk_delete_product_operation = (payload) => {
    return client2.post(product_operation_bulk_operation, payload);
}


export const get_workorder_product_routes = (payload) => {
    return client2.get(wo_product_routes, { params: payload });

}


export const download_prod_oper_template = (payload) => {
    return client2.get(product_operation_template, { params: payload });

}

export const bulk_upload_prod_oper = (payload) => {
    return client2.post(bulk_prod_operation_upload, payload);

}

//check production data flag
export const check_job_card = (payload) => {
    return client2.get(check_job_card_api, { params: payload });

}


export const fetch_prod_wise_bom = (payload) => {
    return client2.post(product_wise_bom_prod_plan, payload);
}

export const fetch_multi_prod_stock = (payload) => {
    return client2.post(stock_avail_multi_prod, payload);
    // operation download template and excel upload
}

export const get_operation_template = (payload) => {
    return client2.get(operation_template, { params: payload });

}

export const upload_operation = (payload) => {
    return client2.post(operation_template, payload);

}

export const fetch_production_plan = (payload) => {
    return client2.get(production_plan, { params: payload });
}

export const create_production_plan = (payload) => {
    return client2.post(production_plan, payload);
}
export const create_mr_wo_jc_pro_plan = (payload) => {
    // return client2.post(create_mr_wo_jc, payload);
    return client2.post(create_mr_wo_jcV3, payload);
}

export const update_production_plan = (payload) => {
    return client2.put(production_plan, payload);
}

export const delete_production_plan = (payload) => {
    return client2.delete(production_plan, { params: payload });
}
//Adjustment Reason

export const get_adjustment_reason = (payload) => {
    return client2.get(adjustment_reason, { params: payload });
}

// job card efficiency chart api
export const getJobCardEfficiency = (payload, signal) => {
    return client2.get(bi_jobcard_efficiency, { params: payload, ...signal });
}

// dispatch chart api
export const getDispatch = (payload, signal) => {
    return client2.get(bi_dispatch, { params: payload, ...signal });
}

//Material Request api 

export const createMaterialRequest = (payload) => {
    return client2.post(material_request, payload);
}
export const createMaterialRequestPP = (payload) => {
    return client2.post(material_request_ppV3, payload);
}
export const getMaterialRequest = (payload) => {
    return client2.get(material_request, { params: payload });
}
export const deleteMaterialRequest = (payload) => {
    return client2.delete(material_request, { params: payload });
}
export const updateMaterialRequest = (payload) => {
    return client2.put(material_request, payload);
}
export const updateMaterialRequestPP = (payload) => {
    return client2.put(material_request_ppV3, payload);
}

export const delete_material_item = (payload) => {
    return client2.delete(material_line_item, { params: payload });
}


// Quick view excel api
export const quickViewExcelDownload = (payload) => {
    return client2.get(quick_view_excel, { params: payload });
}

export const getBINCReason = (payload, signal) => {
    return client2.get(bi_nc_reason, { params: payload, ...signal });
}


// Global Routing api 
export const createGlobalRouting = (payload) => {
    return client2.post(global_routing, payload);
}
export const getGlobalRouting = (payload) => {
    return client2.get(global_routing, { params: payload });
}
export const updateGlobalRouting = (payload) => {
    return client2.put(global_routing, payload);
}
export const deleteGlobalRouting = (payload) => {
    return client2.delete(global_routing, { params: payload });
}

// disposal action chart api
export const getBIDisposalAction = (payload, signal) => {
    return client2.get(bi_disposal_action, { params: payload, ...signal });
}

// Employees nc count excel
export const getEmp_NC_count_excl = (payload, signal) => {
    return client2.get(emp_ncCount_excl, { params: payload, ...signal });
}

// Shift Master API
export const createShift = (payload) => {
    return client2.post(shift_master_api, payload);
}

export const getShift = (payload) => {
    return client2.get(shift_master_api, { params: payload });
}

export const updateShift = (payload) => {
    return client2.patch(shift_master_api, payload);
}

export const deleteShift = (payload) => {
    return client2.delete(shift_master_api, { params: payload });
}


export const fetch_privilages = (payload) => {
    return client2.get(privileges, { params: payload });
}

export const update_privilages = (payload) => {
    return client2.patch(privileges, payload);
}

export const create_privilages = (payload) => {
    return client2.post(privileges, payload);
}

// on boarding bulk upload api's 
export const get_masters_count = () => {
    return client2.get(masters_count);
}

export const download_customer_template = () => {
    return client2.get(onBoard_customer_mst);
}

export const bulk_upload_customer = (payload) => {
    return client2.post(onBoard_customer_mst, payload);
}

export const download_supplier_template = () => {
    return client2.get(onBoard_supplier_mst);
}

export const bulk_upload_supplier = (payload) => {
    return client2.post(onBoard_supplier_mst, payload);
}

export const download_operation_template = () => {
    return client2.get(onBoard_operation_mst);
}

export const bulk_upload_operation = (payload) => {
    return client2.post(onBoard_operation_mst, payload);
}

export const download_machine_template = () => {
    return client2.get(onBoard_machine_mst);
}

export const bulk_upload_machine = (payload) => {
    return client2.post(onBoard_machine_mst, payload);
}

export const download_product_template = (payload) => {
    return client2.get(onBoard_product_mst, { params: payload });
}

export const bulk_upload_product = (payload) => {
    return client2.post(onBoard_product_mst, payload);
}

export const download_location_template = (payload) => {
    return client2.get(onBoard_location_mst, { params: payload });
}

export const bulk_upload_location = (payload) => {
    return client2.post(onBoard_location_mst, payload);
}

export const download_store_template = (payload) => {
    return client2.get(onBoard_store_mst, { params: payload });
}

export const bulk_upload_store = (payload) => {
    return client2.post(onBoard_store_mst, payload);
}

// on boarding bulk upload api's 


// Industry Type
export const get_industry_type = (payload) => {
    return client2.get(industry_type, { params: payload });
}
// Vendor Type

export const get_vendor_type = (payload) => {
    return client2.get(vendor_type, { params: payload });
}

//Break Code 

export const get_break_code = (payload) => {
    return client2.get(break_code, { params: payload });
}

export const create_break_code = (payload) => {
    return client2.post(break_code, payload);
}

export const update_break_code = (payload) => {
    return client2.patch(break_code, payload);
}

export const delete_break_code = (payload) => {
    return client2.delete(break_code, { params: payload });
}

// Change password
export const reset_password = (payload) => {
    return client2.post(change_password, payload);
}

// PI without PO api
export const create_pi_wo_po = (payload) => {
    return client2.post(pi_wo_po, payload);
}

export const update_pi_wo_po = (payload) => {
    return client2.put(pi_wo_po, payload);
}

// Sales order api for so_id and uuid

export const get_so_id = () => {
    return client2.get(sales_order_with_soid);
}

// Sale order with measurements methods

export const get_attrib_api = () => {
    return client2.get(attrib_api);
}

export const get_party_attributes = (payload) => {
    return client2.get(party_attributes, { params: payload });
}

export const create_party_attributes = (payload) => {
    return client2.post(party_attributes, payload);
}

export const update_party_attributes = (payload) => {
    return client2.put(party_attributes, payload);
}

export const delete_party_attributes = (payload) => {
    return client2.delete(party_attributes, { params: payload });
}

//Get Product Light
 
export const get_product_light = (payload) => {
    return client2.get(product_light_weight, { params: payload });
}

export const get_contact_relation = (payload, signal) => {
    return client2.get(contact_relation, { params: payload, ...signal });
}

export const get_rmi_report = (payload) => {
    return client2.get(rmi_report, { params: payload});
}

//Performa Invoice 

export const get_proforma_invoice = (payload) => {
    return client2.get(proforma_invoice, { params: payload });
}

export const create_proforma_invoice = (payload) => {
    return client2.post(proforma_invoice, payload);
}

export const update_proforma_invoice = (payload) => {
    return client2.patch(proforma_invoice, payload);
}

export const delete_proforma_invoice = (payload) => {
    return client2.delete(proforma_invoice, { params: payload });
}

//Payment receipt 

export const get_payment_receipt = (payload) => {
    return client2.get(payment_receipt, { params: payload });
}

export const create_payment_receipt = (payload) => {
    return client2.post(payment_receipt, payload);
}

export const update_payment_receipt = (payload) => {
    return client2.patch(payment_receipt, payload);
}

export const delete_payment_receipt = (payload) => {
    return client2.delete(payment_receipt, { params: payload });
}

//Production Report Fashion 
export const get_prod_report_fashion = (payload) => {
    return client2.get(prod_report_fashion, { params: payload });
}

//Biometric Attendance
export const get_biometric_attendance = (payload) => {
    return client2.get(biometric_attendance, { params: payload });
}

export const get_biometric_report = (payload) => {
    return client2.get(biometric_attendance_report, { params: payload });
}

export const upload_biometric = (payload) => {
    return client2.post(biometric_attendance, payload);
}
