import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import { Typeahead } from "react-bootstrap-typeahead";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import {
  getProdCategory,
  getCustomer,
  getMarket_Group,
  getProduct_Family,
  getUOM,
  saveProduct,
  getBOM,
  gethsn_master,
  createMarketGroup,
  createProduct_Family,
  createProdCategory,
} from "../../../api";

import info from "../../../assets/images/info.png";
import { Tooltip } from "react-tooltip";
import {
  APPAREL,
  FASHION,
  MANUFACTURED_GOODS,
} from "../../../common/Constants/constants";
import AddProductCategory from "../AddMasters/AddProductCategory";
import AddProductFamily from "../AddMasters/AddProductFamily";
import AddTargetSegment from "../AddMasters/AddTargetSegment";

const schema = yup
  .object()
  .shape({
    // category: yup
    //   .array()
    //   .required("Product category is required !")
    //   .min(1, "Select atleast one type !"),
    marketGroup: yup
      .array()
      .required("Please select market group")
      .min(1, "Please select market group"),
    productFamily: yup
      .array()
      .required("Please select product family")
      .min(1, "Please select product family"),
    openingStock: yup
      .string()
      .transform((value, originalValue) =>
        originalValue.trim() === "" ? null : value
      )
      .matches(/^\d+(\.\d+)?$/, "Enter valid opening stock")
      .nullable(),
    OEMnumber: yup
      .string()
      .nullable()
      .transform((value, originalValue) =>
        originalValue.trim() === "" ? null : value
      ),
    productName: yup.string().required("Product name is required !"),
    // hsn: yup
    //   .string()
    //   .matches(/^[a-zA-Z0-9]*$/, "Enter valid hsn")
    //   .nullable(true),
    openingStock: yup
      .string()
      .matches(/^\d+$/, "Enter valid opening stock")
      .notRequired(),
    unit: yup
      .array()
      .required("Unit of measurement is required!")
      .min(1, "Please select unit of measurement!"),
    machineCost: yup
      .string()
      .nullable()
      .transform((value, originalValue) =>
        originalValue.trim() === "" ? null : value
      )
      .matches(/^\d+(\.\d+)?$/, "Enter valid cost of manufacturing"),
    // salesPrice: yup
    //   .string()
    //   .required("Selling price is required !")
    //   .matches(/^\d+(\.\d+)?$/, "Enter valid selling price"),
    // selling_price: yup
    //   .string()
    //   .required("Selling price is required !")
    //   .matches(/^\d+(\.\d+)?$/, "Enter valid selling price"),
    // purchase_price: yup
    //   .string()
    //   .required("Procurement price is required !")
    //   .matches(/^\d+(\.\d+)?$/, "Enter valid procurement price"),
    can_be_sold: yup.string().required("Required"),
    can_be_purchased: yup.string().required("Required"),
    // can_be_expensed: yup
    //   .string()
    //   .required("Required"),
    // has_bom: yup
    //   .string()
    //   .required("Required"),
    reorder_qty: yup
    .string()
    .transform((value, originalValue) => originalValue.trim() === '' ? null : value)
    .matches(/^\d+(\.\d+)?$/, 'Enter valid reorder qty')
    .nullable(),
  })
  .required();

function AddManufacturedGoods(props) {
  let navigate = useNavigate();
  const [prodCategory, setProdCategory] = useState({
    data: [],
    loading: false,
  });
  const [marketGroup, setMarketGroup] = useState({ data: [], loading: false });
  const [prodFamily, setProdFamily] = useState({ data: [], loading: false });
  const [uomList, setUOMList] = useState({ data: [], loading: false });
  const [hsnList, setHSNList] = useState({ data: [], loading: false });
  const [batch_track, setBatchTrack] = useState(false);

  const [filterConfig, setFilterConfig] = useState({ hsn_sac_code: "" });

  const {
    register,
    handleSubmit,
    formState,
    control,
    reset,
    watch,
    setError,
    setValue,
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook

  let { errors } = formState;
  let selUnit = watch("unit");
  let selCategory = watch("category");

  const localData = JSON.parse(localStorage.getItem("localData"));

  useEffect(() => {
    fetchProductCategory();
    fetchMarketGroup();
    fetchProductFamily();
    fetchUOM();
    // fetchHSN();
  }, []);

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      fetchHSN();
    }, 1000); // Adjust the delay as needed (e.g., 500 milliseconds)

    return () => {
      clearTimeout(debounceTimeout); // Clear the timeout if searchTerm changes before the timeout expires
    };
  }, [filterConfig]);

  function fetchHSN() {
    setHSNList({ ...hsnList, data: [], loading: true });
    let payload = {
      pageNo: 1,
      entriesPerPage: 1000,
    };
    if (
      filterConfig.hsn_sac_code !== "" &&
      filterConfig.hsn_sac_code.length >= 4
    ) {
      payload.hsn_sac_code = filterConfig.hsn_sac_code.trim(); // Search by hsn sac code
      gethsn_master(payload).then(
        (res) => {
          setHSNList({ ...hsnList, data: res.data.data, loading: false });
        },
        (err) => {
          setHSNList({ ...hsnList, data: [], loading: false });
        }
      );
    } else {
      // delete payload.hsn_sac_code;
      setHSNList({ ...hsnList, data: [], loading: false });
    }
  }

  function fetchUOM() {
    setUOMList({ ...uomList, data: [], loading: true });
    getUOM().then(
      (res) => {
        if (res && res.data.data?.length > 0) {
          setUOMList({ ...uomList, data: res.data.data, loading: false });
          // setValue("unit", [res.data.data?.[0]]);
        } else {
          setUOMList({ ...uomList, data: [], loading: false });
        }
      },
      (err) => {
        setUOMList({ ...uomList, data: [], loading: false });
      }
    );
  }

  function fetchProductCategory() {
    setProdCategory({ ...prodCategory, data: [], loading: true });
    getProdCategory().then(
      (res) => {
        if (res && res.data.data?.length > 0) {
          setProdCategory({
            ...prodCategory,
            data: res.data.data,
            loading: false,
          });
          // setValue("category", [res.data.data?.[0]]);
        } else {
          setProdCategory({ ...prodCategory, data: [], loading: false });
        }
      },
      (err) => {
        setProdCategory({ ...prodCategory, data: [], loading: false });
      }
    );
  }

  function fetchMarketGroup() {
    setMarketGroup({ ...marketGroup, data: [], loading: true });
    getMarket_Group().then(
      (res) => {
        if (res && res.data.data?.length > 0) {
          setMarketGroup({
            ...marketGroup,
            data: res.data.data,
            loading: false,
          });
          // setValue("marketGroup", [res.data.data?.[0]]);
        } else {
          setMarketGroup({ ...marketGroup, data: [], loading: false });
        }
      },
      (err) => {
        setMarketGroup({ ...marketGroup, data: [], loading: false });
      }
    );
  }

  function fetchProductFamily() {
    setProdFamily({ ...prodFamily, data: [], loading: true });
    getProduct_Family().then(
      (res) => {
        if (res && res.data.data?.length > 0) {
          setProdFamily({
            ...prodFamily,
            data: res.data.data,
            loading: false,
          });
          // setValue("productFamily", [res.data.data?.[0]]);
        } else {
          setProdFamily({ ...prodFamily, data: [], loading: false });
        }
      },
      (err) => {
        setProdFamily({ ...prodFamily, data: [], loading: false });
      }
    );
  }

  const onSubmit = (data) => {
    // Check if the industry type is FASHION or APPAREL
    if ([FASHION, APPAREL].includes(localData?.industry_type)) {
      // If the category is empty, set an error for the category field
      if (!data.category?.length) {
        setError("category", {
          type: "custom",
          message: "Please select the category!",
        });
        return; // Stop further execution if validation fails
      }
    }

    if (data.can_be_sold === "true") {
      if (data.salesPrice === "" || !/^\d+(\.\d+)?$/.test(data.salesPrice)) {
        setError("salesPrice", {
          type: "custom",
          message: data.salesPrice === ""
            ? "Please enter selling price!"
            : "Enter a valid value!",
        });
        return; // Stop further execution if validation fails
      }
    }

    if (data.can_be_purchased === "true") {
      if (data.purchase_price === "" || !/^\d+(\.\d+)?$/.test(data.purchase_price)) {
        setError("purchase_price", {
          type: "custom",
          message: data.purchase_price === ""
            ? "Please enter procurement price!"
            : "Enter a valid value!",
        });
        return; // Stop further execution if validation fails
      }
    }

    if (data !== "") {
      const reqPayload = {
        productType: props.product_type?.prodTypeId,
        productTypename: props.product_type?.productType,
        product_type_id: props.product_type?.uuid,
        category: data.category?.length > 0 ? data.category?.[0]?.categoryname : null,
        category_id: data.category?.length > 0 ? data.category?.[0]?.uuid : null,
        marketgrp: data.marketGroup?.[0]?.grpId,
        market_grp_id: data.marketGroup?.[0]?.uuid,
        prodfamily: data.productFamily?.[0]?.familyId,
        prod_family_id: data.productFamily?.[0]?.uuid,
        // subcategory: data.subCategory,
        productName: data.productName,
        openingstock: data.openingStock,
        hsn: data.hsn?.length > 0 ? data.hsn?.[0]?.hsn_sac_code : null,
        hsn_uuid: data.hsn?.length > 0 ? data.hsn?.[0]?.uuid : null,
        // partNo: data.partNo,
        machineCost: data.machineCost !== "" && data.machineCost !== null ? data.machineCost : "0.00",
        salesPrice: data.can_be_sold == "true" ? data.salesPrice : "0.00",
        can_be_sold: data.can_be_sold,
        can_be_purchased: data.can_be_purchased,
        OEMnumber: data.OEMnumber,
        partNo: data.OEMnumber,
        // can_be_expensed: data.can_be_expensed,
        // beacon_id: data.beacon_id,
        // qr_code_id: data.qr_code_id,
        // selling_price: data.selling_price,
        purchase_price: data.can_be_purchased == "true" ? data.purchase_price : "0.00",
        unit_id: data.unit ? data.unit[0]?.uuid : null,
        remark: data.remark,
        // party: [],
        batch_tracking: data.batch_tracking,
        reorder_qty: data.reorder_qty !== null ? data.reorder_qty : 0,
      };

      saveProduct(reqPayload).then(
        (res) => {
          if (res.status === 200 || res.status === 201) {
            if (props.product_type?.productType == MANUFACTURED_GOODS) {
              toast.success("Manufactured goods product saved successfully !", {
                autoClose: 2000,
              });
            } else {
              toast.success("Finished goods product saved successfully !", {
                autoClose: 2000,
              });
            }
            setTimeout(() => {
              props.setVisible(false);
              props.fetchProdList();
            }, 2200);
          }
        },
        (err) => {
          if (err.response.status === 400) {
            toast.error(err.response, {
              autoClose: 2000,
            });
            // reset();
          }
        }
      );
    } else {
      errors.showMessages();
    }
  };

  function handleCheckBoxChange(e, stringName) {
    const isChecked = e.target.checked;

    if (isChecked) {
      // Make the field required with regex validation
      setError(stringName, {
        type: "manual",
        message: `${
          stringName == "salesPrice" ? "Selling price" : "Procurement price"
        } is required.`,
      });
    } else {
      // Only regex validation
      setValue(stringName, "", { shouldValidate: true });
      clearErrors(stringName);
    }
  }

  const handleInputChange = (e, fieldName, regex) => {
    const value = e.target.value;

    if (regex.test(value)) {
      // Valid input
      clearErrors(fieldName);
    } else {
      // Invalid input
      setError(fieldName, {
        type: "manual",
        message: "Enter a valid value.",
      });
    }
  };

  function addProductCategory(data){
        if (data !== "") {
          const reqPayload = {
            product_type: Object.keys(props.product_type).length > 0 ? props.product_type?.uuid : null,
            categoryname: data,
          };
          createProdCategory(reqPayload).then(
            (res) => {
              // console.log("res", res);
              if (res.status === 200 || res.status === 201) {
                toast.success("Product category created successfully !", {
                  autoClose: 1000,
                });
                setTimeout(() => {
                  fetchProductCategory();
                }, 1300);
                
                setValue("category", [res.data], { shouldValidate: true });
              }
            },
            (err) => {
              if (err.response.status === 400) {
                toast.error(err.response.data.message, {
                  autoClose: 2000,
                });
                reset();
              }
            }
          );
        } else {
          errors.showMessages();
        }
  }

  function addProductFamily(data){
    if (data !== "") {
      const reqPayload = {
        product_type: Object.keys(props.product_type).length > 0 ? props.product_type?.uuid : null,
        product_category_id: selCategory?.length > 0 ? selCategory?.[0]?.uuid : null,
        familyname: data,
      };
      createProduct_Family(reqPayload).then(
        (res) => {
          // console.log("res", res);
          if (res.status === 200 || res.status === 201) {
            toast.success("Product family created successfully !", {
              autoClose: 1000,
            });
            setTimeout(() => {
              fetchProductFamily();
            }, 1300);
            
            setValue("familyname", [res.data], { shouldValidate: true });
          }
        },
        (err) => {
          if (err.response.status === 400) {
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
            reset();
          }
        }
      );
    } else {
      errors.showMessages();
    }
  }

  function addTargetSegment(data){
    if (data !== "") {
      const reqPayload = {
        groupname: data,
      };
      createMarketGroup(reqPayload).then(
        (res) => {
          // console.log("res", res);
          if (res.status === 200 || res.status === 201) {
            toast.success("Target segment created successfully !", {
              autoClose: 1000,
            });
            setTimeout(() => {
              fetchProductFamily();
            }, 1300);
            
            setValue("groupname", [res.data], { shouldValidate: true });
          }
        },
        (err) => {
          if (err.response.status === 400) {
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
            reset();
          }
        }
      );
    } else {
      errors.showMessages();
    }
  }

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="card border-0">
        <div className="card-body">
          <p className="pc_card_title roboto-bold">
            {props.constLabel?.lbl_product_summary
              ? props.constLabel.lbl_product_summary
              : "Product Summary"}
          </p>
          <div className="row">
            <div className="col-md-3">
              <div className="form-group typeahead-container mt-md-0 mt-3">
                <label className="lbl-style roboto-bold d-flex align-items-center">
                  {props.constLabel?.lbl_product_category
                    ? props.constLabel?.lbl_product_category
                    : "Product Category"}
                  {[FASHION, APPAREL].includes(localData?.industry_type) ? (
                    <span className="text-danger"> *</span>
                  ) : null}
                  <Tooltip
                    id={"open-tooltip1"}
                    place="top"
                    style={{ zIndex: 9999 }}
                  />
                  <img
                    src={info}
                    alt="info"
                    className="cursor_pointer"
                    data-tooltip-id={"open-tooltip1"}
                    data-tooltip-html="To classify products into distinct categories for easier<br />
                       management, searchability, and reporting within the system."
                    height={20}
                    width={20}
                  />
                  {/* <AddProductCategory
                    fetchProductCategory={fetchProductCategory}
                    type={props.product_type}
                  /> */}
                </label>
                <Controller
                  name="category"
                  control={control}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="categoryname"
                      className="mt-2"
                      multiple={false}
                      options={prodCategory.data}
                      isLoading={prodCategory.loading}
                      placeholder="Select product category"
                      // onChange={onChange}
                      onChange={(selected) => {
                        // If the new option is added
                        const isNewOption = selected && selected[0]?.customOption;
                        if (isNewOption) {
                          const newItem = selected[0]?.categoryname;
                          // Fire API to add the new item
                          addProductCategory(newItem);
                        }
                        onChange(selected);
                      }}
                      newSelectionPrefix="Add New: "
                      allowNew                // Enables adding new options
                      selected={value}
                      onBlur={onBlur}
                      // isInvalid={errors.category ? true : false}
                      isInvalid={
                        [FASHION, APPAREL].includes(localData?.industry_type)
                          ? !!errors.category
                          : null
                      }
                    />
                  )}
                />
                {!prodCategory.loading && !errors.category && (
                  <span className="arrow-icon"></span>
                )}
                {[FASHION, APPAREL].includes(localData?.industry_type) &&
                  errors.category && (
                    <span className="text-danger err-msg">
                      {errors.category.message}
                    </span>
                  )}
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group typeahead-container mt-md-0 mt-3">
                <label className="lbl-style roboto-bold d-flex align-items-center">
                  {props.constLabel?.lbl_target_segment
                    ? props.constLabel?.lbl_target_segment
                    : "Target Segment"}
                  <span className="text-danger"> *</span>
                  {/* <AddTargetSegment fetchMarketGroup={fetchMarketGroup} /> */}
                </label>
                <Controller
                  name="marketGroup"
                  control={control}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="groupname"
                      className="mt-2"
                      multiple={false}
                      options={marketGroup.data}
                      isLoading={marketGroup.loading}
                      placeholder="Select target segment"
                      // onChange={onChange}
                      onChange={(selected) => {
                        // If the new option is added
                        const isNewOption = selected && selected[0]?.customOption;
                        if (isNewOption) {
                          const newItem = selected[0]?.groupname;
                          // Fire API to add the new item
                          addTargetSegment(newItem);
                        }
                        onChange(selected);
                      }}
                      newSelectionPrefix="Add New: "
                      allowNew
                      selected={value}
                      onBlur={onBlur}
                      isInvalid={errors.marketGroup ? true : false}
                    />
                  )}
                />
                {!marketGroup.loading && !errors.marketGroup && (
                  <span className="arrow-icon"></span>
                )}
                {errors.marketGroup && (
                  <span className="text-danger err-msg">
                    {errors.marketGroup.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group typeahead-container mt-md-0 mt-3">
              <label className="lbl-style roboto-bold d-flex align-items-center">
                  {props.constLabel?.lbl_product_family
                    ? props.constLabel?.lbl_product_family
                    : "Product Family"}
                  <span className="text-danger"> *</span>
                  <Tooltip id={"open-tooltip2"} place="top" style={{ zIndex: 9999 }}/>
                  <img src={info} alt='info' className='cursor_pointer'
                    data-tooltip-id={"open-tooltip2"}
                    data-tooltip-html=" Groups products that share common characteristics, functionalities, or manufacturing processes.<br /> 
                       This field helps categorize products into broader families, such as similar types of finished goods,<br /> 
                       components, or raw materials, enabling more efficient inventory management, reporting, and product<br />
                       lifecycle tracking."
                    height={20} width={20} />
                    {/* <AddProductFamily fetchProductFamily={fetchProductFamily} type={props.product_type} category={selCategory} /> */}
                </label>
                <Controller
                  name="productFamily"
                  control={control}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="familyname"
                      className="mt-2"
                      multiple={false}
                      options={prodFamily.data}
                      isLoading={prodFamily.loading}
                      placeholder="Select product family"
                      // onChange={onChange}
                      onChange={(selected) => {
                        // If the new option is added
                        const isNewOption = selected && selected[0]?.customOption;
                        if (isNewOption) {
                          const newItem = selected[0]?.familyname;
                          // Fire API to add the new item
                          addProductFamily(newItem);
                        }
                        onChange(selected);
                      }}
                      newSelectionPrefix="Add New: "
                      allowNew
                      selected={value}
                      onBlur={onBlur}
                      isInvalid={errors.productFamily ? true : false}
                    />
                  )}
                />
                {!prodFamily.loading && !errors.productFamily && (
                  <span className="arrow-icon"></span>
                )}
                {errors.productFamily && (
                  <span className="text-danger err-msg">
                    {errors.productFamily.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {props.constLabel?.lbl_product_name
                    ? props.constLabel.lbl_product_name
                    : "Product Name"}
                  <span className="text-danger">*</span>
                </label>
                <input
                  className={
                    errors.productName
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Enter product name"
                  name="productName"
                  {...register("productName")}
                />
                {errors.productName && (
                  <span className="text-danger err-msg">
                    {errors.productName.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3 mt-3">
              <div className="form-group typeahead-container mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {props.constLabel?.lbl_unit_of_measurement
                    ? props.constLabel?.lbl_unit_of_measurement
                    : "Unit Of Measurement"}
                  <span className="text-danger"> *</span>
                </label>
                <Controller
                  name="unit"
                  control={control}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="uom"
                      className="mt-2"
                      multiple={false}
                      options={uomList.data}
                      isLoading={uomList.loading}
                      placeholder="Select unit of measurement"
                      onChange={onChange}
                      selected={value}
                      onBlur={onBlur}
                      isInvalid={errors.unit ? true : false}
                    />
                  )}
                />
                {!uomList.loading && !errors.unit && (
                  <span className="arrow-icon"></span>
                )}
                {errors.unit && (
                  <span className="text-danger err-msg">
                    {errors.unit.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {props.constLabel?.lbl_opening_stock
                    ? props.constLabel.lbl_opening_stock
                    : "Opening Stock"}{" / "}{selUnit && selUnit[0]?.uom}
                </label>
                <input
                  className="form-control mt-2"
                  type="text"
                  defaultValue={0.0}
                  placeholder="Enter opening stock"
                  name="openingStock"
                  {...register("openingStock")}
                />
                {errors.openingStock && (
                  <span className="text-danger err-msg">
                    {errors.openingStock.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {props.constLabel?.lbl_reorder_qty
                    ? props.constLabel?.lbl_reorder_qty
                    : "Reorder Qty"}
                </label>
                <input
                  className="form-control mt-2"
                  type="text"
                  defaultValue={0}
                  placeholder="Enter reorder qty"
                  name="reorder_qty"
                  {...register("reorder_qty")}
                />
                {errors.reorder_qty && (
                  <span className="text-danger err-msg">
                    {errors.reorder_qty.message}
                  </span>
                )}
              </div>
            </div>

            {/* <div className="col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {props.constLabel?.lbl_hsn ? props.constLabel.lbl_hsn : "HSN"}
                  </label>
                <input
                  className="form-control mt-2"
                  type="text"
                  placeholder="Enter hsn"
                  name="hsn"
                  {...register("hsn")}
                />
                {errors.hsn && (
                  <span className="text-danger err-msg">
                    {errors.hsn.message}
                  </span>
                )}
              </div>
            </div> */}

            {/* HSN select box */}
            <div className="col-md-3 mt-3">
              <div className="form-group typeahead-container mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {props.constLabel?.lbl_hsn
                    ? props.constLabel?.lbl_hsn
                    : "HSN"}
                  {/* <span className="text-danger"> *</span> */}
                  <Tooltip
                    id={"open-tooltip2"}
                    place="top"
                    style={{ zIndex: 9999 }}
                  />
                  <img
                    src={info}
                    alt="info"
                    className="cursor_pointer"
                    data-tooltip-id={"open-tooltip2"}
                    data-tooltip-html="Enter four digits to search hsn for selection."
                    height={20}
                    width={20}
                  />
                </label>
                <Controller
                  name="hsn"
                  control={control}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="hsn_sac_code"
                      className="mt-2"
                      multiple={false}
                      options={hsnList.data}
                      isLoading={hsnList.loading}
                      placeholder="Select hsn"
                      onChange={onChange}
                      selected={value}
                      onBlur={onBlur}
                      // isInvalid={errors.hsn ? true : false}
                      onInputChange={(text) =>
                        setFilterConfig({ ...filterConfig, hsn_sac_code: text })
                      }
                    />
                  )}
                />
                {!hsnList.loading && <span className="arrow-icon"></span>}
                {/* {errors.hsn && (
                  <span className="text-danger err-msg">
                    {errors.hsn.message}
                  </span>
                )} */}
              </div>
            </div>
            {/* HSN select box */}

            <div className="col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {props.constLabel?.lbl_oem_part_no
                    ? props.constLabel?.lbl_oem_part_no
                    : "OEM Part No"}
                    <Tooltip id={"open-tooltip2"} place="top" style={{ zIndex: 9999 }}/>
                    <img src={info} alt='info' className='cursor_pointer'
                      data-tooltip-id={"open-tooltip2"}
                      data-tooltip-html="This field referred to enter part no, product code, OEM part no."
                      height={20} width={20} />
                </label>
                <input
                  className={
                    errors.OEMnumber
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Enter part no / product code"
                  name="OEMnumber"
                  {...register("OEMnumber")}
                />
                {errors.OEMnumber && (
                  <span className="text-danger err-msg">
                    {errors.OEMnumber.message}
                  </span>
                )}
              </div>
            </div>
          </div>
          <hr className="my-2" />
          <p className="pc_card_title roboto-bold">
            {props.constLabel?.lbl_product_detail
              ? props.constLabel.lbl_product_detail
              : "Product Detail"}
          </p>
          <div className="row col-12">
            {/* <div className="col-md-3">
              <div className="form-group">
                <label className="lbl-style roboto-bold">{"Drawing No."}</label>
                <input
                  className="form-control mt-2"
                  type="text"
                  placeholder="Enter drawing no"
                  name="drawingNo"
                  {...register("drawingNo")}
                />
              </div>
            </div> */}

            <div className="col-md-3">
              <label className="lbl-style roboto-bold invisible">
                {props.constLabel?.lbl_drawing_no
                  ? props.constLabel.lbl_drawing_no
                  : "Drawing No"}
              </label>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultChecked={false}
                  name="can_be_sold"
                  // onChange={(e) => handleCheckBoxChange(e, "salesPrice")}
                  {...register("can_be_sold", {
                    onChange: (e) => handleCheckBoxChange(e, "salesPrice"),
                  })}
                />
                <label className="form-check-label lbl-style roboto-bold">
                  {props.constLabel?.lbl_can_be_sold
                    ? props.constLabel.lbl_can_be_sold
                    : "Can be Sold"}
                </label>
              </div>
            </div>

            <div className="col-md-3">
              <label className="lbl-style roboto-bold invisible">
                {"Drawing No."}
              </label>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultChecked={false}
                  name="can_be_purchased"
                  // {...register("can_be_purchased")}
                  {...register("can_be_purchased", {
                    onChange: (e) => handleCheckBoxChange(e, "purchase_price"),
                  })}
                />
                <label className="form-check-label lbl-style roboto-bold">
                  {props.constLabel?.lbl_can_be_purchase
                    ? props.constLabel.lbl_can_be_purchase
                    : "Can be Purchased"}
                </label>
              </div>
            </div>

            <div className="col-md-3">
              <label className="lbl-style roboto-bold invisible">
                {props.constLabel?.lbl_batch_tracking
                  ? props.constLabel?.lbl_batch_tracking
                  : "Batch Tracking"}
              </label>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="batch_tracking"
                  onChange={() => setBatchTrack(!batch_track)}
                  checked={batch_track}
                  {...register("batch_tracking")}
                />
                <label className="form-check-label lbl-style roboto-bold">
                  {props.constLabel?.lbl_batch_tracking
                    ? props.constLabel?.lbl_batch_tracking
                    : "Batch Tracking"}
                </label>
              </div>
            </div>

            {/* <div className="col-md-3">
              <label className="lbl-style roboto-bold invisible">
                {"Drawing No."}
              </label>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultChecked={false}
                  name="can_be_expensed"
                  {...register("can_be_expensed")}

                />
                <label className="form-check-label lbl-style roboto-bold">
                  {props.constLabel?.lbl_can_be_expense ? props.constLabel.lbl_can_be_expense : "Can be Expense"}
                </label>
              </div>
            </div> */}

            <div className="col-md-3"></div>

            {/* <div className="col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {props.constLabel?.lbl_beacon_id ? props.constLabel.lbl_beacon_id : "Beacon Id"}
                  </label>
                <input
                  className="form-control mt-2"
                  type="text"
                  placeholder="Enter BeaconId"
                  name="beacon_id"
                  {...register("beacon_id")}
                />
                {errors.beacon_id && (
                  <span className="text-danger err-msg">
                    {errors.beacon_id.message}
                  </span>
                )}
              </div>
            </div> */}

            {/* <div className="col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {props.constLabel?.lbl_qr_code_id ? props.constLabel.lbl_qr_code_id : "QR Code Id"}
                  </label>
                <input
                  className="form-control mt-2"
                  type="text"
                  placeholder="Enter QRCodeId"
                  name="qr_code_id"
                  {...register("qr_code_id")}
                />
                {errors.qr_code_id && (
                  <span className="text-danger err-msg">
                    {errors.qr_code_id.message}
                  </span>
                )}
              </div>
            </div> */}
          </div>

          <hr className="my-2" />
          <p className="pc_card_title roboto-bold">
            {props.constLabel?.lbl_product_price
              ? props.constLabel.lbl_product_price
              : "Product Price"}
          </p>
          <div className="row col-12">
            <div className="col-md-3">
              <div className="form-group">
                <label className="lbl-style roboto-bold">
                  {props.constLabel?.lbl_cost_of_mfg
                    ? props.constLabel.lbl_cost_of_mfg
                    : "Cost of Manufacturing"}
                  (<span>&#8377;</span>){" / "}
                  {selUnit && selUnit[0]?.uom}
                  {/* <span className="text-danger">*</span> */}
                </label>
                <input
                  className={
                    errors.machineCost
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Enter cost"
                  name="machineCost"
                  // defaultValue="0.00"
                  {...register("machineCost")}
                />
                {errors.machineCost && (
                  <span className="text-danger err-msg">
                    {errors.machineCost.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <label className="lbl-style roboto-bold">
                  {props.constLabel?.lbl_selling_price
                    ? props.constLabel.lbl_selling_price
                    : "Selling Price"}
                  {watch("can_be_sold") ? (
                    <span className="text-danger"> *</span>
                  ) : null}
                  (<span>&#8377;</span>){" / "}
                  {selUnit && selUnit[0]?.uom}
                </label>
                <input
                  className={
                    errors.salesPrice
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Enter selling price"
                  name="salesPrice"
                  // defaultValue="0.00"
                  {...register("salesPrice", {
                    required: watch("can_be_sold")
                      ? "Selling price is required."
                      : false,
                    pattern: {
                      value: /^\d+(\.\d+)?$/,
                      message: "Enter a valid selling price.",
                    },
                  })}
                  onChange={(e) => {
                    handleInputChange(e, "salesPrice", /^\d+(\.\d+)?$/);
                  }}
                />
                {errors.salesPrice && (
                  <span className="text-danger err-msg">
                    {errors.salesPrice.message}
                  </span>
                )}
              </div>
            </div>

            {/* <div className="col-md-3">
              <div className="form-group">
                <label className="lbl-style roboto-bold">
                  {props.constLabel?.lbl_selling_price ? props.constLabel.lbl_selling_price : "Selling Price"}
                  <span className="text-danger">*</span>
                </label>
                <input
                  className={
                    errors.selling_price
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Enter selling price"
                  name="selling_price"
                  defaultValue={0.0}
                  {...register("selling_price")}
                />
                {errors.selling_price && (
                  <span className="text-danger err-msg">
                    {errors.selling_price.message}
                  </span>
                )}
              </div>
            </div> */}

            <div className="col-md-3">
              <div className="form-group">
                <label className="lbl-style roboto-bold">
                  {props.constLabel?.lbl_procurement_price
                    ? props.constLabel.lbl_procurement_price
                    : "Procurement Price"}
                  {watch("can_be_purchased") ? (
                    <span className="text-danger"> *</span>
                  ) : null}
                  (<span>&#8377;</span>){" / "}
                  {selUnit && selUnit[0]?.uom}
                </label>
                <input
                  className={
                    errors.purchase_price
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Enter procurement price"
                  name="purchase_price"
                  // defaultValue="0.00"
                  {...register("purchase_price", {
                    required: watch("can_be_purchased")
                      ? "Procurement price is required."
                      : false,
                    pattern: {
                      value: /^\d+(\.\d+)?$/,
                      message: "Enter a valid procurement price.",
                    },
                  })}
                  onChange={(e) => {
                    handleInputChange(e, "purchase_price", /^\d+(\.\d+)?$/);
                  }}
                />
                {errors.purchase_price && (
                  <span className="text-danger err-msg">
                    {errors.purchase_price.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <label className="lbl-style roboto-bold">
                  {props.constLabel?.lbl_remark
                    ? props.constLabel.lbl_remark
                    : "Remark"}
                </label>
                <textarea
                  className="form-control mt-2"
                  id="remark"
                  type="text"
                  placeholder="Enter remark"
                  name="remark"
                  {...register("remark")}
                ></textarea>
              </div>
            </div>
          </div>
          <hr className="my-4" />
          <div className="d-flex justify-content-end">
            <button
              className="btn button-primary px-4 me-2"
              onClick={handleSubmit(onSubmit)}
              // onClick={() => props.setCurrentStep(props.currentStep + 1)}
            >
              {props.constLabel?.lbl_save ? props.constLabel.lbl_save : "Save"}
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default AddManufacturedGoods;
