import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faDownload } from "@fortawesome/free-solid-svg-icons";
import Roboto from "../../../assets/fonts/Roboto-Regular.ttf";
import Roboto_Bold from "../../../assets/fonts/Roboto-Bold.ttf";

import {
  PDFViewer,
  Document,
  Text,
  View,
  Image,
  Page,
  Link,
  StyleSheet,
  Font,
  pdf,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import { formatDate5, getShortReadableId } from "../../../common/Functions/CommonFunctions";

function WO_Status_PDF() {
  let navigate = useNavigate();
  let location = useLocation();
  const [party_details, setparty_details] = useState(null);
  const [gst_type, setgst_type] = useState(null);
  const [sub_total, setSub_total] = useState(null);
  const [tax_total, setTax_total] = useState(null);
  const [pdfBlobUrl, setPdfBlobUrl] = useState("");
  const [isPdfLoaded, setIsPdfLoaded] = useState(false);
  const downloadLinkRef = useRef(null);
  const data = location.state;

  console.log("data", data);

  Font.register({
    family: "roboto, roboto-light,roboto-bold",
    fonts: [
      {
        src: Roboto,
      },
      {
        src: Roboto_Bold,
        fontWeight: 600,
      },
    ],
  });

  const styles = StyleSheet.create({
    viewer: {
      width: "100%",
      height: window.innerHeight,
    },
    page: {
      paddingTop: 15,
      paddingBottom: 40,
      paddingHorizontal: 15,
      color: "black",
      fontFamily: "roboto, roboto-light,roboto-bold",
      border: "1px solid black",
    },
    pageNumber: {
      position: "absolute",
      fontSize: 12,
      bottom: 28,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "black",
    },
    footer: {
      position: "absolute",
      fontSize: 12,
      bottom: 0,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "black",
      paddingTop: "50px",
      paddingBottom: "10",
      paddingLeft: "15",
      paddingRight: "15",
      // borderTop:'1px solid black',
    },
    footer1: {
      position: "absolute",
      fontSize: 12,
      bottom: 50,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "black",
      paddingTop: "50px",
      paddingBottom: "10",
      paddingLeft: "15",
      paddingRight: "15",
      // borderTop:'1px solid black',
    },
    row1: {
      flexDirection: "row",
      border: "1px solid black",
    },
    row2: {
      flexDirection: "row",
      borderLeft: "1px solid black",
      borderRight: "1px solid black",
      borderBottom: "1px solid black",
    },
    row3: {
      flexDirection: "row",
      fontSize: "10px",
      width: "100%",
    },
    tablColumn1: {
      width: "6%",
      fontSize: "10px",
      border: "1px solid black",
      padding: "5px",
      fontWeight: 600,
    },
    tablColumn1Data: {
      width: "6%",
      borderLeft: "1px solid black",
      borderRight: "1px solid black",
      borderBottom: "1px solid black",
      padding: "5px",
    },
    tablColumn2: {
      width: "30%",
      border: "1px solid black",
      padding: "5px",
      fontWeight: 600,
    },
    tablColumn2Data: {
      width: "30%",
      borderLeft: "1px solid black",
      borderRight: "1px solid black",
      borderBottom: "1px solid black",
      padding: "5px",
    },
    tablColumn3: {
      width: "10%",
      border: "1px solid black",
      padding: "5px",
      fontWeight: 600,
    },
    tablColumn3Data: {
      width: "10%",
      borderLeft: "1px solid black",
      borderRight: "1px solid black",
      borderBottom: "1px solid black",
      padding: "5px",
    },
    tablColumn4: {
      width: "12%",
      border: "1px solid black",
      padding: "5px",
      fontWeight: 600,
    },
    tablColumn4Data: {
      width: "12%",
      borderLeft: "1px solid black",
      borderRight: "1px solid black",
      borderBottom: "1px solid black",
      padding: "5px",
    },
    tablColumn5: {
      width: "10%",
      border: "1px solid black",
      padding: "5px",
      fontWeight: 600,
    },
    tablColumn5Data: {
      width: "10%",
      borderLeft: "1px solid black",
      borderRight: "1px solid black",
      borderBottom: "1px solid black",
      padding: "5px",
    },
    tablColumn6: {
      width: "12%",
      border: "1px solid black",
      padding: "5px",
      fontWeight: 600,
    },
    tablColumn6Data: {
      width: "12%",
      borderLeft: "1px solid black",
      borderRight: "1px solid black",
      borderBottom: "1px solid black",
      padding: "5px",
    },
    tablColumn7: {
      width: "18%",
      border: "1px solid black",
      padding: "5px",
      fontWeight: 600,
    },
    tablColumn7Data: {
      width: "18%",
      borderLeft: "1px solid black",
      borderRight: "1px solid black",
      borderBottom: "1px solid black",
      padding: "5px",
    },
    tablColumn8: {
      width: "20%",
      border: "1px solid black",
      textAlign: "center",
      // padding: "5px",
      fontWeight: 600,
    },
    tablColumn8Data: {
      width: "20%",
      borderLeft: "1px solid black",
      borderRight: "1px solid black",
      borderBottom: "1px solid black",
      padding: "5px",
    },

    smallcolumn: {
      // border: "1px solid black",
      width: "50%",
    },
    subDivRow: {
      flexDirection: "row",
    },
    column: {
      width: "50%",
      // borderRight: '1px solid black',
      // backgroundColor: 'red',
      fontSize: "10px",
    },
    title: {
      textAlign: "center",
      fontWeight: 600,
    },
    companyName: {
      fontWeight: "600",
      fontSize: "12px",
    },
    signatureBlock: {
      width: "100%",
      marginLeft: "auto",
      marginTop: "100px",
      borderTop: "1px solid black",
      borderLeft: "1px solid black",
      borderRight: "1px solid black",
      borderBottom: "1px solid black",
      padding: "5px",
    },
    section: {
      marginBottom: 10,
    },
    label: {
      fontWeight: "bold",
      fontSize: "12px",
      marginLeft: "10px",
    },
    value: {
      flex: 1,
      fontSize: "12px",
    },
    row4: {
      flexDirection: "row",
      alignItems: "flex-start",
      marginBottom: 5,
    },
    label2: {
      fontWeight: "bold",
      fontSize: "12px",
      marginLeft: "10px",
    },
    value2: {
      flex: 1,
      fontSize: "12px",
    },
    row5: {
      flexDirection: "row",
      alignItems: "flex-start",
      marginBottom: 5,
    },
    bottomSection: {
      marginTop: 20,
      flexDirection: "column",
      alignItems: "flex-end",
    },
    text: {
      fontSize: 12,
      marginBottom: 10,
      fontWeight: "bold",
    },
  });


  useEffect(() => {
    const generatePdfBlob = async () => {
      const doc = (
        <WOStatusPDF data={data}/>
      );
      const asPdf = pdf([]);
      asPdf.updateContainer(doc);
      const blob = await asPdf.toBlob();
      const url = URL.createObjectURL(blob);
      setPdfBlobUrl(url);
      setIsPdfLoaded(true);
    };

    generatePdfBlob();
  }, [data]);

  const handleDownload = () => {
    const link = downloadLinkRef.current;
    link.href = pdfBlobUrl;
    link.download = `Work Order Status - ${getShortReadableId(data?.[0]?.work_order_no)}.pdf`;
    link.click();
  };

  const WOStatusPDF = ({ data }) => (
    <Document title={`Work Order Status - ${getShortReadableId(data?.[0]?.work_order_no)}`}>
      <Page style={styles.page}>
        <Text style={styles.title}>Work Order Status</Text>
        <View style={[styles.row1, { marginTop: "10px" }]}>
          <View style={[styles.column, { marginTop: "20px" }]}>
            <View style={styles.section}>
              <View style={styles.row4}>
                <Text style={styles.label}>Product Name : </Text>
                  <Text style={styles.value}>
                    {data?.[0]?.product_name
                      ? data?.[0]?.product_name
                      : "NA"}
                  </Text>
              </View>
              <View style={styles.row4}>
                <Text style={styles.label}>Work Order No : </Text>
                <Text style={styles.value}>
                    {data?.[0]?.work_order_no
                      ? getShortReadableId(data?.[0]?.work_order_no)
                      : "NA"}
                  </Text>
              </View>
              <View style={styles.row4}>
                <Text style={styles.label}>Work Order Qty : </Text>
                <Text style={styles.value}>
                    {data?.[0]?.work_order_qty
                      ? data?.[0]?.work_order_qty
                      : 0}
                  </Text>
              </View>
              <View style={styles.row4}>
                <Text style={styles.label}>Work Order Status : </Text>
                <Text style={styles.value}>
                    {data?.[0]?.work_order_status
                      ? data?.[0]?.work_order_status
                      : "NA"}
                  </Text>
              </View>
            </View>
          </View>
          <View style={[styles.column, { borderLeft: "1px solid black" }]}>
            <View
              style={[styles.subDivRow, { borderBottom: "1px solid black" }]}
            >
              <View style={[styles.column, { padding: "5px", padding: "5px" }]}>
                <Text>{"Raw Material Cost"}</Text>
              </View>
              <View
                style={[
                  styles.column,
                  { padding: "5px", borderLeft: "1px solid black" },
                ]}
              >
                <Text>{`₹ ${(data?.[0]?.raw_material_cost)?.toFixed(2) || 0}`}</Text>
              </View>
            </View>
            <View style={[styles.subDivRow]}>
              <View style={[styles.column, { padding: "5px" }]}>
                <Text>{"Consumables Cost"}</Text>
              </View>
              <View
                style={[
                  styles.column,
                  { padding: "5px", borderLeft: "1px solid black" },
                ]}
              >
                <Text>{`₹ ${(data?.[0]?.consumable_cost)?.toFixed(2) || 0}`}</Text>
              </View>
            </View>
            <View style={[styles.subDivRow, { borderTop: "1px solid black" }]}>
              <View style={[styles.column, { padding: "5px" }]}>
                <Text>{"Man Hour Cost"}</Text>
              </View>
              <View
                style={[
                  styles.column,
                  { padding: "5px", borderLeft: "1px solid black" },
                ]}
              >
                <Text>{`₹ ${(data?.[0]?.man_hour_cost)?.toFixed(2) || 0}`}</Text>
              </View>
            </View>
            <View style={[styles.subDivRow, { borderTop: "1px solid black" }]}>
              <View style={[styles.column, { padding: "5px" }]}>
              <Text>{"Machining Cost"}</Text>
              </View>
              <View
                style={[
                  styles.column,
                  { padding: "5px", borderLeft: "1px solid black" },
                ]}
              >
                <Text>{`₹ ${(data?.[0]?.machine_running_cost)?.toFixed(2) || 0}`}</Text>
              </View>
            </View>
            <View style={[styles.subDivRow, { borderTop: "1px solid black" }]}>
              <View style={[styles.column, { padding: "5px", padding: "5px" }]}>
                <Text>{"Indirect Cost"}</Text>
              </View>
              <View
                style={[
                  styles.column,
                  { padding: "5px", borderLeft: "1px solid black" },
                ]}
              >
                <Text>{`₹ ${(data?.[0]?.indirect_cost?.[0]?.Cost)?.toFixed(2) || 0}`}</Text>
              </View>
            </View>
          </View>
        </View>

        <View style={[styles.row1, { marginTop: "10px" }]}>
          <View style={[styles.column,]}>
            <View style={[{ marginTop: "10px" }]}>
                <View style={[styles.section, ]}>
                  <View style={styles.row5}>
                    <Text style={styles.label2}>Dispatch Qty : </Text>
                    <Text style={styles.value2}>
                        {data?.[0]?.dispatched_qty
                            ? data?.[0]?.dispatched_qty
                            : 0}
                    </Text>
                  </View>
                </View>
            </View>
          </View>
          <View style={[styles.column, { borderLeft: "1px solid black" },]}>
            <View style={[{ marginTop: "10px" }]}>
                <View style={[styles.section, ]}>
                  <View style={styles.row5}>
                    <Text style={styles.label2}>Work Order Qty : </Text>
                    <Text style={styles.value2}>
                        {data?.[0]?.work_order_qty
                            ? data?.[0]?.work_order_qty
                            : 0}
                    </Text>
                  </View>
                </View>
            </View>
          </View>
          <View style={[styles.column, { borderLeft: "1px solid black" },]}>
            <View style={[{ marginTop: "10px" }]}>
                <View style={[styles.section, ]}>
                  <View style={styles.row5}>
                    <Text style={styles.label2}>NC Qty : </Text>
                    <Text style={styles.value2}>
                        {data?.[0]?.nc_count
                            ? data?.[0]?.nc_count
                            : 0}
                    </Text>
                  </View>
                </View>
            </View>
          </View>
        </View>

        <View style={[styles.row3, { marginTop: "10px" }]}>
          <View style={styles.tablColumn1}>
            <Text>Sr No</Text>
          </View>
          <View style={styles.tablColumn2}>
            <Text>Operation</Text>
          </View>
          <View style={styles.tablColumn2}>
            <Text>Avg. Actual Cycle Time</Text>
          </View>

          <View style={styles.tablColumn2}>
            <Text>Total Produced Qty</Text>
          </View>
          <View style={styles.tablColumn6}>
            <Text>NC Count</Text>
          </View>
          <View style={styles.tablColumn7}>
            <Text>Scrap Weight (KG)</Text>
          </View>
        </View>

        {data?.[0]?.operation.map((val, i) => (
          <View key={i} style={[styles.row3]}>
            <View style={styles.tablColumn1Data}>
              <Text>{i + 1}</Text>
            </View>
            <View style={styles.tablColumn2Data}>
              <Text>{val.operationname !== "" && val.operationname !== null ? val.operationname : "NA"}</Text>
            </View>
            <View style={styles.tablColumn2Data}>
                <Text>{val.avg_actual_cycle_time !== "" && val.avg_actual_cycle_time !== null ? val.avg_actual_cycle_time : 0}{" "}mins</Text>
            </View>
            <View style={styles.tablColumn2Data}>
                <Text>{val.produced_quantity !== "" && val.produced_quantity !== null ? val.produced_quantity : 0}</Text>
            </View>
            <View style={styles.tablColumn6Data}>
                <Text>{val.nc_count !== "" && val.nc_count !== null ? val.nc_count : 0}</Text>
            </View>
            <View style={styles.tablColumn7Data}>
                <Text>{val.scrap_weight !== "" && val.scrap_weight !== null ? val.scrap_weight : 0}</Text>
            </View>
          </View>
        ))}

        <View style={styles.footer1}>
          <View
            style={{
              flexDirection: "row",
              fontSize: "12px",
              marginTop: "80px",
              marginLeft: "40px",
            }}
          >
            <View style={{ width: "30%" }}>
              <Text>Prepared By</Text>
            </View>
            <View style={{ width: "30%" }}>
              <Text>Verified By</Text>
            </View>
            <View style={{ width: "40%" }}>
              <Text>Authorised Signatory</Text>
            </View>
          </View>
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />

        <Text style={styles.footer} fixed>
          This is a Computer Generated Document
        </Text>
      </Page>
    </Document>
  );

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="manufac_goods_form mb-4">
        <div className="card">
          <div className="card-header bg-grey d-flex align-items-center justify-content-between">
            <div>
              <div className="p-2 d-flex align-items-center">
                <button
                  className="btn btn-yellow"
                  onClick={() => {
                    navigate("/pcms/work-order");
                  }}
                >
                  <FontAwesomeIcon icon={faArrowLeft} size="sm" />
                </button>

                <p className="mb-0 ms-3" style={{ fontSize: "24px" }}>
                  Work Order Status PDF
                </p>
              </div>
            </div>

            <div className=" p-2">
              <button
                className="btn btn-yellow"
                style={styles.downloadButton}
                onClick={handleDownload}
                disabled={!isPdfLoaded}
              >
                <FontAwesomeIcon icon={faDownload} size="sm" /> Download PDF
              </button>
            </div>
          </div>
          <div className="card-body">
            <div className="createNc container-fluid">
              <PDFViewer style={styles.viewer}>
                <WOStatusPDF data={data} />
              </PDFViewer>
              <a ref={downloadLinkRef} style={{ display: "none" }} />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default WO_Status_PDF;