import React, { useRef, useState, useEffect, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Spinner } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import DatePicker from "react-datepicker";

import {
  convertDateYYMMDD,
  formatDate5,
  getLabels,
} from "../../../common/Functions/CommonFunctions";
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import {
  create_proforma_invoice,
  get_GST_details,
  get_payment_receipt,
  getCustomer,
  gethsn_master,
  getInternalCompany,
} from "../../../api";
import { Search } from "react-feather";

function CreateProFormaInvoice() {
  let navigate = useNavigate();
  const location = useLocation();

  // console.log("location", location.state);

  const [payment_info, setPayment_Info] = useState({
    outstanding_amount: 0,
    advance_amount: 0,
    grand_amount: 0,
  });
  const schema = yup
    .object()
    .shape({
      order_date: yup
        .date()
        .required("Please select order date !")
        .typeError("Please select order date !"),
      organization: yup
        .array()
        .min(1, "Please select organization !")
        .required("Please select organization !"),
      customer: yup
        .array()
        .min(1, "Please select customer !")
        .required("Please select customer !"),
      dispatchTo: yup
        .array()
        .min(1, "Please select customer!")
        .required("Please select customer!"),
      order_due_date: yup
        .date()
        .required("Please select order due date!")
        .typeError("Please select order due date!")
        .min(
          yup.ref("order_date"),
          "Dispatch date cannot be before Order date!"
        ),
      gst_number: yup
        .string()
        .nullable()
        .test("gst-format", "Invalid GST number!", (value) => {
          if (!value || value.trim() === "") return true; // Allow empty string
          return /^([0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1})$/.test(value);
        }),
    })
    .required();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    watch,
    setValue,
    getValues,
    clearErrors,
    trigger,
    setError,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  // const hsnOptions = ["61171030", "611710", "61171010", "61171020"];
  const localData = JSON.parse(localStorage.getItem("localData"));
  const [buttonclick, setbuttonclick] = useState(false);
  const [constLabel, setConstLabel] = useState({});
  const [lineItems, setLineItems] = useState(location.state || []);
  const [loc_list, setLoc_List] = useState({ data: [], loading: false });
  const [customerList, setCustomerList] = useState({
    data: [],
    loading: false,
  });
  const [customer_details, setCustomer_details] = useState({
    data: {},
    gst_number: null,
    dataPresent: false,
    loading: false,
    invalid_data_present: false,
  });
  const [payment_receipts, setPayment_receipts] = useState({
    data: [],
    loading: false,
  });

  const [hsnList, setHSNList] = useState({}); // Store HSN data per row
  const [split_gst, set_split_gst] = useState({cgst: true, sgst: true, ugst: false, igst: false});

  const org = watch("organization");
  const gstRate = watch("gst_rate", 0);

  const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    setConstLabel(getLabels("Sales", "Performa Invoice"));

    const result = localData?.organizations?.filter(
      (item) => item.default_org == true
    );
    fetchpayments();
    // fetchInternal_company()
    let advance_amount_val = location.state
      .map((item) => item.advance_amount)
      .reduce((ac, dc) => {
        return ac + Number(dc);
      }, 0);

    let total_amount_val = location.state
      .map((item) => item.final_total)
      .reduce((ac, dc) => {
        return ac + Number(dc);
      }, 0);

    setTotalAmount(total_amount_val);

    setPayment_Info({
      outstanding_amount: total_amount_val - advance_amount_val,
      advance_amount: advance_amount_val,
      grand_amount: total_amount_val - advance_amount_val,
    });

    setValue("advance_amount", advance_amount_val);

    let arr = location.state.map((item) => {
      let obj = {
        organization: item.customer_name,
        party_id: item.customer_id,
      };
      return obj;
    });

    setCustomerList({ data: arr, loading: false });
    // console.log("result",result)
    setValue("organization", result);
    setValue("order_date", new Date());
    setValue("order_due_date", new Date());

    setValue("cgst", 0);
    setValue("sgst", 0);
    setValue("ugst", 0);
    setValue("igst", 0);

    window.history.pushState(null, "", window.location.href);
    window.onpopstate = function () {
      window.history.pushState(null, "", window.location.href);
    };
  }, []);

  useEffect(() => {
    const handler = setTimeout(() => {
      const adv_amt = getValues("advance_amount");
      if (gstRate !== "") {
        const gstAmount = (Number(totalAmount) * Number(gstRate)) / 100;
        if(split_gst.ugst){
          setValue("ugst", gstAmount, {shouldValidate: true});
        } else if(split_gst.igst){
          setValue("igst", gstAmount, {shouldValidate: true});
        } else {
          let split_amt = gstAmount/2;
          setValue("cgst", split_amt , {shouldValidate: true});
          setValue("sgst", split_amt, {shouldValidate: true} );
        }
        setPayment_Info({
          grand_amount: (Number(totalAmount) - Number(adv_amt)) + gstAmount,
          outstanding_amount: Number(totalAmount) - Number(adv_amt),
          advance_amount: adv_amt,
        });

      } else {
        if(split_gst.ugst){
          setValue("ugst", 0, {shouldValidate: true});
        } else if(split_gst.igst){
          setValue("igst", 0, {shouldValidate: true});
        } else {
          setValue("cgst", 0 , {shouldValidate: true});
          setValue("sgst", 0, {shouldValidate: true} );
        }
        setPayment_Info({
          grand_amount: Number(totalAmount) - Number(adv_amt),
          outstanding_amount: Number(totalAmount) - Number(adv_amt),
          advance_amount: adv_amt,
        });

      }
    }, 300); // 500ms delay before recalculating

    return () => clearTimeout(handler); // Cleanup previous timer
  }, [gstRate]);


  function validateGST(value) {
    const gstRegex =
      /^([0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1})$/;
    if (gstRegex.test(value)) {
      clearErrors("gst_number");
      return true;
    } else {
      return false;
    }
  }

  const useDebounce = (callback, delay) => {
    const timer = useRef(null);
  
    return useCallback(
      (...args) => {
        if (timer.current) clearTimeout(timer.current);
        timer.current = setTimeout(() => callback(...args), delay);
      },
      [callback, delay]
    );
  };

  function checkGST(gstNumber1, gstNumber2){
      const ugstTerritories = ["01", "04", "07", "26", "31", "34", "35", "38"];
     
      const stateCode1 = gstNumber1.substring(0, 2);
      const stateCode2 = gstNumber2.substring(0, 2);
     
      if (isNaN(stateCode1) || isNaN(stateCode2)) {
        toast.error("Invalid GST number format");
      }
     
      if (stateCode1 === stateCode2) {
        if (ugstTerritories.includes(stateCode1)) {
          set_split_gst({cgst: false, sgst: false, ugst: true, igst: false});
        } else {
          set_split_gst({cgst: true, sgst: true, ugst: false, igst: false});
        }
      } else {
        set_split_gst({cgst: false, sgst: false, ugst: false, igst: true});
      }
  }

  const fetchHSN = useDebounce(async (hsnCode, headIndex, rowIndex) => {
    if (hsnCode.length < 4) return; // ✅ Fetch only if 4+ characters
  
    setHSNList((prev) => ({
      ...prev,
      [headIndex]: {
        ...prev[headIndex],
        [rowIndex]: { data: [], loading: true },
      },
    }));
  
    try {
      const res = await gethsn_master({
        pageNo: 1,
        entriesPerPage: 22000,
        hsn_sac_code: hsnCode.trim(),
      });
  
      setHSNList((prev) => ({
        ...prev,
        [headIndex]: {
          ...prev[headIndex],
          [rowIndex]: { data: res.data.data, loading: false },
        },
      }));
    } catch (err) {
      setHSNList((prev) => ({
        ...prev,
        [headIndex]: {
          ...prev[headIndex],
          [rowIndex]: { data: [], loading: false },
        },
      }));
    }
  }, 300); // ✅ 300ms delay

  const handleHSNChange = (parentIndex, childIndex, hsnCode) => {
    const updatedItems = [...lineItems];
    updatedItems[parentIndex].so_order_lines[childIndex].hsn = hsnCode?.[0]?.hsn_sac_code;
    setLineItems(updatedItems);
  };

  async function Get_gst_details() {
    const gst = getValues("gst_number");

    if (!gst || gst.trim().length == 0) {
      setError("gst_number", {
        type: "manual",
        message: "GST number is required !",
      });
      return;
    }
    if (!validateGST(gst)) {
      setError("gst_number", {
        type: "manual",
        message: "Please enter a valid GST number",
      });
      return;
    }

    clearErrors("gst_number");
    // console.log("gst",gst)
    setCustomer_details({
      data: {},
      dataPresent: false,
      loading: true,
      gst_number: null,
      invalid_data_present: false,
    });

    await get_GST_details({ gstin: gst })
      .then((res) => {
        // console.log(res.data);

        // console.log(
        //   "res?.data?.data?.data.tradeNam",
        //   res?.data?.data?.data.tradeNam
        // );

        // call check gst function
        checkGST(org?.[0]?.comGSTnumber, res.data.data.data?.gstin)

        if (res.data.data.data.adadr.length > 0) {
          setValue(
            "billing_address",
            res.data.data.data.adadr[0].addr.bno +
              " " +
              res.data.data.data.adadr[0].addr.bnm +
              " " +
              res.data.data.data.adadr[0].addr.st +
              " " +
              res.data.data.data.adadr[0].addr.loc || "",
            { shouldValidate: true }
          );
        } else {
          setValue(
            "billing_address",
            res.data.data.data.pradr?.addr?.bno +
              " " +
              res.data.data.data.pradr?.addr?.bnm +
              " " +
              res.data.data.data.pradr?.addr?.st +
              " " +
              res.data.data.data.adadr?.addr?.loc || "",
            { shouldValidate: true }
          );
        }
        setCustomer_details({
          data: {},
          dataPresent: true,
          loading: false,
          gst_number: null,
          invalid_data_present: false,
        });
      })
      .catch((err) => {
        console.log("err", err);

        if (err.status == 400) {
          // alert("Please provide a valid GST number");
          let message = err?.response?.data?.message;

          if (
            message &&
            message.length > 0 &&
            message == "Invalid GSTIN pattern"
          ) {
            setError("gst_number", {
              type: "manual",
              message: "Invalid GSTIN pattern !",
            });

            setCustomer_details({
              data: {},
              dataPresent: false,
              loading: false,
              gst_number: null,
              invalid_data_present: false,
            });
          } else {
            setError("gst_number", {
              type: "manual",
              message: message
                ? message
                : "Something went wrong with GST service !",
            });

            setCustomer_details({
              data: {},
              dataPresent: true,
              loading: false,
              gst_number: null,
              invalid_data_present: true,
            });
          }
        } else {
          setError("gst_number", {
            type: "manual",
            message: "Something went wrong with GST service !",
          });
          setCustomer_details({
            data: {},
            dataPresent: true,
            loading: false,
            gst_number: null,
            invalid_data_present: true,
          });
        }
      });
  }

  const onSubmit = async (data) => {
    // console.log("data", data);

    setbuttonclick(true);

    let line_items_payload = [];
    for(let i=0; i<location.state.length; i++){
        let ele=location.state[i];
        // console.log("ele", ele);
      let arr = ele.so_order_lines.map((item)=>{
        let obj={
          sales_order_id:item.sales_order_id,
          sales_order_item_uuid:item.uuid,
          qty:item.quantity,
          amount:item.amount,
          hsn_no: item.hsn !== null && item.hsn !== "" ? Number(item.hsn) : null
        }
        return obj; 
      }); 
      line_items_payload.push(...arr)
    }
    let payload_obj = {
      invoice_date: convertDateYYMMDD(data.order_date, "-"),
      dispatch_date: convertDateYYMMDD(data.order_due_date, "-"),
      orgnization_id: data.organization[0].uuid,
      billing_address:data.billing_address?data.billing_address:null,
      party_id: data.customer[0].party_id,
      dispatch_to_id: data.dispatchTo[0].party_id,
      // sub_total:payment_info.grand_amount,
      sub_total: Number(totalAmount).toFixed(2),
      // gst_amount: data.gst_rate ? payment_info.grand_amount * (data.gst_rate/100) : (payment_info.grand_amount * 0.12),
      gst_amount: data.gst_rate ? Number(totalAmount * (data.gst_rate/100)).toFixed(2) : Number(totalAmount * 0.12).toFixed(2),
      gst_rate: data.gst_rate ? data.gst_rate : 12,
      gst_no: data.gst_number ? data.gst_number : null,
      outstanding_amount: payment_info.outstanding_amount,
      advance_amount:payment_info.advance_amount,
      invoice_performa_line_item_list:line_items_payload
    };

    // console.log("payoad", payload_obj); return;

    
    create_proforma_invoice(payload_obj).then(
      (res) => {
        if (res.status === 200 || res.status === 201) {
          toast.success("Proforma Invoice created successfully !", {
            autoClose: 2000,
          });
          setbuttonclick(false);

          setTimeout(() => {
            navigate("/pcms/proforma-invoice");
          }, 2200);
        }
      },
      (err) => {
        // console.log("err", err);
        setbuttonclick(false);
        // console.log("err.response.data.message",err.response.data.message)
        if (err.response.status === 400) {
          toast.error(err.response.data.message, {
            autoClose: 2000,
          });

          // reset();
        } else {
          if (err.response.status === 500) {
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
          } else {
            toast.error("Something went wrong ", {
              autoClose: 2000,
            });
          }
        }
      }
    );
  };

  function fetchInternal_company() {
    setLoc_List({ data: [], loading: true });

    getInternalCompany({ with_location: 1 })
      .then((res) => {
        // console.log("res", res);
        const ress = res.data.data.filter((item) => item.default_org == true);
        // console.log("ress", ress);
        if (ress.length > 0) {
          const loc = ress?.[0]?.location_list;
          setLoc_List({ data: loc, loading: false });
        } else {
          toast.success("Default organization not found !", {
            autoClose: 3000,
          });
        }
      })
      .catch((err) => {
        setLoc_List({ data: [], loading: false });
      });
  }

  function fetchpayments() {
    setPayment_receipts({ loading: true, data: [] });

    const payload = {
      sales_order_id_list: location.state.map((item) => item.uuid),
    };
    get_payment_receipt(payload).then(
      (res) => {
        // console.log("res.data.data", res.data.data);

        setPayment_receipts({
          loading: false,
          data: res.data.data,
        });
      },
      (err) => {
        console.log("err", err);
        setPayment_receipts({ loading: false, data: [] });
      }
    );
  }

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="manufac_goods_form mb-4">
        <div className="card">
          <div className="card-header bg-grey d-flex align-items-center">
            <button
              className="btn btn-yellow"
              onClick={() => {
                if (
                  window.confirm(
                    "Are you sure to go back, your present data will be lost !"
                  )
                ) {
                  window.onpopstate = undefined;
                  window.history.back();

                  setTimeout(() => {
                    navigate("/pcms/proforma-invoice");
                  }, [1000]);
                }
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} size="sm" />
            </button>

            <p className="mb-0 ms-3" style={{ fontSize: "24px" }}>
              {constLabel?.lbl_porforma_invoice_details
                ? constLabel.lbl_porforma_invoice_details
                : "Proforma Invoice Details"}
            </p>
          </div>
          <div className="card-body">
            <div className="createNc container-fluid">
              <p className="roboto-bold mb-0" style={{ fontSize: "20px" }}>
                {constLabel?.lbl_porforma_invoice_details
                  ? constLabel.lbl_porforma_invoice_details
                  : "Proforma Invoice Details"}
              </p>
              <hr className="my-2" />

              <div className="row mt-2 col-12">
                <div className="col-md-3 mt-3">
                  <div className="form-group typeahead-container mt-md-0 mt-3">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_organization
                        ? constLabel.lbl_organization
                        : "Organization"}
                      <span className="text-danger"> *</span>
                    </label>

                    <Controller
                      name={"organization"}
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          id="basic-typeahead-single"
                          labelKey="comName"
                          multiple={false}
                          options={localData?.organizations}
                          placeholder="Select organization"
                          onChange={(selected) => {
                            onChange(selected);
                          }}
                          onBlur={onBlur}
                          selected={value}
                          isInvalid={errors.organization ? true : false}
                        />
                      )}
                    />
                    {!errors.organization && (
                      <span className="arrow-icon"></span>
                    )}
                    {errors.organization && (
                      <span className="text-danger err-msg">
                        {errors.organization.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-3 mt-3">
                  <div className="form-group typeahead-container mt-md-0 mt-3">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_customer_name
                        ? constLabel.lbl_customer_name
                        : "Customer Name"}
                      <span className="ms-2 text-danger"> *</span>
                    </label>
                    <Controller
                      name={"customer"}
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          id="basic-typeahead-single"
                          labelKey="organization"
                          multiple={false}
                          options={customerList.data}
                          isLoading={customerList.loading}
                          placeholder="Select customer"
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          isInvalid={errors.customer ? true : false}
                        />
                      )}
                    />
                    {!customerList.loading && !errors.customer && (
                      <span className="arrow-icon"></span>
                    )}
                    {errors.customer && (
                      <span className="text-danger err-msg">
                        {errors.customer.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-3 mt-3">
                  <div className="form-group mt-md-0 mt-3">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_order_date
                        ? constLabel.lbl_order_date
                        : "Order Date"}
                      <span className="text-danger"> *</span>
                    </label>

                    <Controller
                      name="order_date"
                      control={control}
                      render={({ field }) => (
                        <DatePicker
                          className={
                            errors.order_date
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          {...field}
                          showYearDropdown
                          scrollableMonthYearDropdown
                          dateFormat="dd/MM/yyyy"
                          selected={field.value}
                          maxDate={new Date()}
                          placeholderText="Select a order date"
                          autoComplete="off"
                        />
                      )}
                    />
                    {errors.order_date && (
                      <span className="text-danger err-msg">
                        {errors.order_date.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-3 mt-3">
                  <div className="form-group typeahead-container mt-md-0 mt-3">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_dispatch_to
                        ? constLabel.lbl_dispatch_to
                        : "Dispatch To"}
                      <span className="ms-2 text-danger"> *</span>
                    </label>
                    <Controller
                      name={`dispatchTo`}
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          id="basic-typeahead-single"
                          labelKey="organization"
                          multiple={false}
                          options={customerList.data}
                          isLoading={customerList.loading}
                          placeholder="Select customer"
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          isInvalid={errors.dispatchTo ? true : false}
                        />
                      )}
                    />
                    {!customerList.loading && !errors.dispatchTo && (
                      <span className="arrow-icon"></span>
                    )}
                    {errors.dispatchTo && (
                      <span className="text-danger err-msg">
                        {errors.dispatchTo.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-3 mt-3">
                  <div className="form-group mt-md-0 mt-3">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_dispatch_date
                        ? constLabel.lbl_dispatch_date
                        : "Dispatch Date"}
                      <span className="text-danger"> *</span>
                    </label>

                    <Controller
                      name="order_due_date"
                      control={control}
                      render={({ field }) => (
                        <DatePicker
                          className={
                            errors.order_due_date
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          {...field}
                          showYearDropdown
                          scrollableMonthYearDropdown
                          dateFormat="dd/MM/yyyy"
                          selected={field.value}
                          // maxDate={new Date()}
                          placeholderText="Select a oroder due date"
                          autoComplete="off"
                        />
                      )}
                    />
                    {errors.order_due_date && (
                      <span className="text-danger err-msg">
                        {errors.order_due_date.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-3 mt-3">
                  <div className="form-group mt-md-0 mt-3">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_gst_no
                        ? constLabel.lbl_gst_no
                        : "GST No"}
                    </label>

                    <div className="input-group">
                      <input
                        className={`form-control ${
                          errors.gst_number ? "is-invalid" : ""
                        }`}
                        type="text"
                        name="gst_number"
                        placeholder="Enter GST No"
                        {...register("gst_number", {
                          onChange: (e) => {
                            if (e.target.value === "0") {
                              set_split_gst({ cgst: true, sgst: true, ugst: false, igst: false });
                              setValue("billing_address", "");
                              setValue("gst_rate", "");
                            }
                          },
                        })}
                      />
                      <button
                        className="btn btn-primary px-2"
                        onClick={() => Get_gst_details()}
                        disabled={customer_details.loading}
                        type="button"
                      >
                        {customer_details.loading ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          <Search size={16} />
                        )}
                      </button>
                    </div>

                    {errors.gst_number && (
                      <span
                        className="text-danger err-msg"
                        style={{ fontSize: "12px" }}
                      >
                        {errors.gst_number.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6 mt-3">
                  <div className="form-group mt-md-0 mt-3 ">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_billing_address
                        ? constLabel.lbl_billing_address
                        : "Billing Address"}
                    </label>

                    <textarea
                      className={"form-control form-control-sm"}
                      type="text"
                      placeholder="Enter billing address"
                      {...register("billing_address")}
                    ></textarea>
                  </div>
                </div>
                <div className="col-md-3 mt-3">
                  <div className="form-group  mt-0">
                    <label className="lbl-style roboto-bold">
                      GST Rate(%)
                    </label>

                    <input
                      name="gst_rate"
                      className={"form-control"}
                      type="number"
                      max={18}
                      placeholder="Enter gst rate"
                      {...register("gst_rate")}
                    />
                  </div>
                </div>
              </div>

              {/* <hr className="mt-4" /> */}
              <p className="roboto-bold mt-4 mb-0" style={{ fontSize: "20px" }}>
                {constLabel?.lbl_SO_details
                  ? constLabel.lbl_SO_details
                  : "Sales Order Details"}
              </p>
              <hr className="mt-4" />
              {location?.state?.length > 0 &&
                location?.state?.map((item, i) => {
                  return (
                    <div>
                      <div className="row col-12">
                        <div className="col-md-3 ">
                          <p className="lbl-style roboto-bold">
                            {constLabel?.lbl_sales_order_id
                              ? constLabel.lbl_sales_order_id
                              : "Sales Order ID"}
                            : {item.so_id}
                          </p>
                        </div>
                        <div className="col-md-3 ">
                          <p className="lbl-style roboto-bold">
                            {constLabel?.lbl_customer_name
                              ? constLabel.lbl_customer_name
                              : "Customer Name"}
                            : {item.customer_name}
                          </p>
                        </div>
                      </div>
                      <div className="row col-12 mt-0">
                        <div className="col-md-8">
                          <table className="table table-responsive table-bordered">
                            <thead className="thead-light text-center">
                              <tr>
                                <th>Sr. No.</th>
                                {/* <th>Product Type</th> */}
                                <th>Product Name</th>
                                <th>HSN Code</th>
                                <th>Quantity</th>
                                <th>Unit</th>
                                <th>Discount(%)</th>
                                <th>Amount ₹</th>
                              </tr>
                            </thead>
                            <tbody className="text-center">
                              {item?.so_order_lines?.length > 0 &&
                                item?.so_order_lines?.map((ele, ind) => {
                                  return (
                                    <tr key={ele.uuid}>
                                      <td>{ind + 1}</td>
                                      {/* <td>{ele.product_type}</td> */}
                                      <td>{ele.productname}</td>
                                      <td>
                                      <Controller
                                          name={`hsn-${i}-${ind}`}
                                          control={control}
                                          render={({ field: { onChange, onBlur, value, ref } }) => {
                                            return (
                                              <Typeahead
                                                id={`hsn-typeahead-${i}-${ind}`}
                                                multiple={false}
                                                labelKey="hsn_sac_code"
                                                options={hsnList[i]?.[ind]?.data || []}
                                                placeholder="Select HSN"
                                                onChange={(selected) => {
                                                  onChange(selected);
                                                  handleHSNChange(i, ind, selected);
                                                }}
                                                onInputChange={(text) => fetchHSN(text, i, ind)} // ✅ Fetch dynamically
                                                isLoading={hsnList[i]?.[ind]?.loading || false}
                                                selected={value}
                                                isInvalid={!!errors?.[`hsn-${i}-${ind}`]} // ✅ Ensure proper error handling
                                                inputRef={(r) => ref(r)} // ✅ Pass ref correctly
                                                clearButton
                                              />
                                            );
                                          }}
                                        />
                                      </td>
                                      <td>{ele.quantity}</td>
                                      <td>{ele.unit}</td>
                                      <td>{ele.discount}</td>
                                      <td>{ele.amount}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                        <div className="col-md-4 border">
                          <p className="lbl-style roboto-bold mt-2">Payment</p>
                          <div className="row col-12">
                            <div className="col-md-5 ml-2">
                              <div className="form-group  mt-0">
                                <label className="lbl-style roboto-bold">
                                  Advance Payment
                                </label>

                                <input
                                  name="advance_payment"
                                  className={"form-control"}
                                  type="text"
                                  disabled={true}
                                  value={
                                    item.advance_amount
                                      ? `₹${item.advance_amount}`
                                      : "₹0"
                                  }
                                  placeholder="Enter advance payment"
                                />
                              </div>
                            </div>
                            <div className="col-md-5 ml-3">
                              <div className="form-group  mt-0">
                                <label className="lbl-style roboto-bold">
                                  Outstanding Amount
                                </label>

                                <input
                                  name="outstanding_payment"
                                  className={"form-control"}
                                  type="text"
                                  disabled={true}
                                  value={
                                    item.outstanding_amount
                                      ? `₹ ${item.outstanding_amount}`
                                      : "₹ 0"
                                  }
                                  placeholder="Enter outstanding amount"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row col-12 mb-2">
                            <div className="col-md-5 ml-2"></div>
                            <div className="col-md-5 ml-3 ">
                              <div className="form-group  mt-2">
                                <label className="lbl-style roboto-bold">
                                  Total Amount
                                </label>

                                <input
                                  name="total_amount"
                                  className={"form-control"}
                                  type="text"
                                  disabled={true}
                                  value={
                                    item.final_total
                                      ? `₹ ${item.final_total}`
                                      : "₹ 0"
                                  }
                                  placeholder="Enter final total"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr className="mt-4" />
                    </div>
                  );
                })}
              <p className="roboto-bold mb-0" style={{ fontSize: "20px" }}>
                {constLabel?.lbl_payment_receipts
                  ? constLabel.lbl_payment_receipts
                  : "Payment Receipts"}
              </p>
              <div className="row col-12 mt-3">
                <div className="col-md-8">
                  <table className="table table-responsive table-bordered">
                    <thead className="thead-light text-center">
                      <tr>
                        <th>Sr. No.</th>
                        <th>SO ID</th>
                        <th>Proforma Invoice Id</th>
                        <th>Payment Date</th>
                        <th>Amount ₹</th>
                        <th>Discount(%)</th>
                        <th>Pending Amount</th>
                      </tr>
                    </thead>
                    <tbody className="text-center">
                      {payment_receipts.data.map((ele, ind) => {
                        return (
                          <tr key={ele.uuid}>
                            <td>{ind + 1}</td>
                            <td>
                              {ele?.sales_order_info?.so_id
                                ? ele.sales_order_info.so_id
                                : "NA"}
                            </td>
                            <td>
                              {ele?.invoice_performa_info?.invoice_id
                                ? ele.invoice_performa_info.invoice_id
                                : "NA"}
                            </td>
                            <td>
                              {ele?.payment_date
                                ? formatDate5(ele.payment_date)
                                : "NA"}
                            </td>
                            <td>{ele?.amount ? ele.amount : "0"}</td>
                            <td>
                              {ele?.discount_percentage
                                ? ele.discount_percentage
                                : "0"}
                            </td>
                            <td>
                              {ele?.pending_amount ? ele.pending_amount : "0"}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="col-md-4 border">
                  <p className="lbl-style roboto-bold mt-2">Total Payment</p>

                  <div className="row mt-2">
                    <div className="col-6 lbl-style roboto-bold mt-2">
                      Advance Payment:
                    </div>
                    <div className="col-6">
                      <div className="form-group mt-0">
                        <input
                          name="advance_amount"
                          className={`form-control form-control-sm ${
                            errors.advance_amount ? "is-invalid" : ""
                          }`}
                          disabled={true}
                          type="text"
                          value={Number(payment_info.advance_amount).toFixed(2)}
                          {...register("advance_amount")}
                        />
                        {errors.advance_amount && (
                          <span className="text-danger err-msg">
                            {errors.advance_amount.message}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <hr />

                {split_gst.cgst ? 
                  <div className="row mt-2">
                    <div className="col-6 lbl-style roboto-bold mt-2 ">
                      CGST Amount:
                    </div>
                    <div className="col-6">
                      <div className="form-group mt-0">
                        <input
                          name="cgst"
                          className="form-control form-control-sm"
                          type="text"
                          disabled={true}
                          {...register("cgst")}
                        />
                      </div>
                    </div>
                  </div>
                : null }

                {split_gst.sgst ?
                  <div className="row mt-2">
                    <div className="col-6 lbl-style roboto-bold mt-2 ">
                      SGST Amount:
                    </div>
                    <div className="col-6">
                      <div className="form-group mt-0">
                        <input
                          name="sgst"
                          className="form-control form-control-sm"
                          type="text"
                          disabled={true}
                          {...register("sgst")}
                        />
                      </div>
                    </div>
                  </div>
                : null }

                {split_gst.ugst ?
                  <div className="row mt-2">
                    <div className="col-6 lbl-style roboto-bold mt-2 ">
                      UGST Amount:
                    </div>
                    <div className="col-6">
                      <div className="form-group mt-0">
                        <input
                          name="ugst"
                          className="form-control form-control-sm"
                          type="text"
                          disabled={true}
                          {...register("ugst")}
                        />
                      </div>
                    </div>
                  </div>
                : null }

                {split_gst.igst ?
                  <div className="row mt-2">
                    <div className="col-6 lbl-style roboto-bold mt-2 ">
                      IGST Amount:
                    </div>
                    <div className="col-6">
                      <div className="form-group mt-0">
                        <input
                          name="igst"
                          className="form-control form-control-sm"
                          type="text"
                          disabled={true}
                          {...register("igst")}
                        />
                      </div>
                    </div>
                  </div>
                : null }

                  <div className="row mt-2">
                    <div className="col-6 lbl-style roboto-bold mt-2 ">
                      Outstanding Payment:
                    </div>
                    <div className="col-6">
                      <div className="form-group mt-0">
                        <input
                          name="outstanding_amount"
                          className={
                            errors.outstanding_amount
                              ? "form-control form-control-sm is-invalid"
                              : "form-control form-control-sm"
                          }
                          type="text"
                          disabled={true}
                          // {...register("outstanding_amount")}
                          value={Number(payment_info.outstanding_amount).toFixed(2)}
                          placeholder="Enter outstanding payment"
                        />
                        {/* {errors.outstanding_amount && (
                            <span className="text-danger err-msg">
                              {errors.outstanding_amount.message}
                            </span>
                          )} */}
                      </div>
                    </div>
                  </div>

                  <hr />
                  <div className="row">
                    <div className="col-6 fw-bold">Grand Total:</div>
                    <div className="col-6 mb-3">
                      <div className="form-group mt-0">
                        <input
                          name="grand_total"
                          className="form-control fw-bold"
                          type="text"
                          disabled={true}
                          value={Number(payment_info.grand_amount).toFixed(2)}
                          placeholder="Grand total"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card-footer py-3">
            <button
              className="btn btn-yellow px-4 float-end"
              disabled={buttonclick}
              onClick={handleSubmit(onSubmit)}
            >
              {constLabel?.lbl_save ? constLabel.lbl_save : "Save"}{" "}
              {buttonclick ? <Spinner animation="border" size="sm" /> : null}{" "}
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default CreateProFormaInvoice;
