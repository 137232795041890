const ver1 = "v1";
const ver2 = "v2";
const ver3 = "v3";

export const RefreshToken = "/api/token/refresh"

//PCMS Constant
export const PcmsSignIn = "api/aut/v2/login/";

// PCMS New Development Constants
export const country_ms = "api/allMaster/getCountries/";                       //get country All
export const state_ms = "api/allMaster/getStatesByCountry/";                //get state All by country id
export const city_ms = "api/allMaster/getCityListByState/";                   //get city All by state id
export const market_group_ms = "api/master/ProductGroupAll/";             //get market group All
export const prod_cat_ms = "api/master/ProductCategoryAll/";              //get product category All
export const prod_type_ms = "api/master/productTypeAll/";                 //get product type All
export const prod_family_ms = "api/master/family_productAll/";            //get product family All
// export const operation_cat_ms = "api/master/ProductOperationCategoryAll/";      //get operation category All
export const customer_type_ms = "api/party/getCustomerType/";                   //get customer type All
export const customer_ms = "api/party/CustomerAll/";                           // get customer All
export const supplier_type_ms = "api/allMaster/getSupplierType/";             //get supplier type All
export const company_ms = "api/payroll/CompanyAll/";                           // get company All
export const loc_type_ms = "api/master/LocationTypeAll/";                      // get location type All
export const location_ms = "api/master/LocationAll/";                          // get location All
export const machine_cat_ms = "api/master/MachineCategoryAll/";                // get machine category All
export const bank_ms = "api/allMaster/Banks/";                              // get bank All
export const product_family_lst = "api/master/getFamily_product/";        //get product family
export const product_family = "api/master/family_product/";               // CUD product family master

export const operation_cat = "api/product/v2/operation_category_api/";                  // Create, Read, Update Operation Category
export const operation_cat_csv = "api/product/getAllOperationCategoryforcsv/"   // Operation Category CSV 

export const operation = "api/product/v2/operation/";                          // Create, Read, Update Operation master
export const operation_reg = "api/MO/Operation_RegistersApi/"                // Operation register
export const register_lst = "api/MO/GetRegistersList/"                        // Register mapping list

export const location_lst = "api/allMaster/getLocationList/";                      //get location
export const product_lst = "api/master/getProductList/";                           //get products

// export const machine_cat = "api/machine/machineCategory/";                         // create, read, update delete machine category
export const machine_cat = "api/machine/v2/machine_category_api/";                    // v2 machine category
export const machine_cat_csv = "api/machine/getAllMachineCategoryforcvs/"             // machine category csv

export const machine_ast_lst = "api/master/getMachineList/";                          //get machine assets
export const machine_asset = "api/machine/machineApi/";                               //create, read, update machine assets
export const machine_asset_csv = "api/machine/getAllMachinesforcsv/"                  // machine asset csv

export const customer = "api/party/v2/party/"                                        // Customer Api
// export const supplier = "api/party/v2/supplier_api/"                              // get supplier
export const supplier = "api/party/v2/party/"                                        // Supplier Api
export const customer_rpt = "api/party/v2/customerreport/"
export const supplier_rpt = "api/party/v2/supplierreport/"

export const customer_lst = "api/party/getCustomerListForPagination/";            // get customer list with pagination
export const single_customer = "api/party/getCustomerDetails/"                    // get single customer data
export const cust_delete = "api/party/deleteParty/";                              // delete customer

export const cust_edit = "api/party/getCustomerDetailForEditbyCustomerId/";       // get customer detail for edit
export const customer_csv = "api/party/getCustomerDetailsforcsv/"

export const get_ContactsByCustomer = "api/party/getContactDetailsByCustomer/";    // get contacts by customer (partyCode)
export const get_ProductByCustomer = "api/product/getProductDetailsByCustomer/";   // get products by customer (partyCode)
export const get_LotsByCustomer = "api/MO/getMOByPartyid/";                        // get lots by customer (partyCode)                      

export const cust_contact_save = "api/party/createCustomerAndContact/";           // save customer details and contact details
export const cust_contact_update = "api/party/updateCustomerAndContact/";         // update customer details and contact details
export const cust_contact_remove = "api/party/removeCustomerContact/";            // remove contact details

export const cust_bill_shipp_save = "api/party/createCustomerBillingAndShipping/"   // save customer billing & shipping address
export const cust_bill_shipp_update = "api/party/updateCustomerBillingAndShipping/" // update customer billing & shipping address
export const cust_bill_shipp_remove = "api/party/removeCustomerShippingAddress/"    // remove customer billing & shipping address

export const cust_billing_save = "api/party/saveCustomerOtherDetails/";                 // save billing details
export const cust_billing_update = "api/party/updateCustomerOtherDetails/";             // update billing details

export const cust_bank_save = "api/party/saveCustomerBankDetails/";                     // save bank  details
export const cust_bank_update = "api/party/updateCustomerBankDetails/";                 // update bank  details
export const cust_bank_remove = "api/party/removeCustomerBankDetails/";                 // remove bank  details

export const supp_type_lst = "api/party/getPartySupplierTypeList/";               //get supplier type
export const supp_type = "api/party/PartySupplierType/";                          //CUD supplier category

export const supplier_lst = "api/party/getSupplierListForPagination/";                // get supplier list with pagination
export const single_supplier = "api/party/getSupplier/";                              // get single supplier (partyCode)
export const supp_delete = "api/party/deleteParty/";                                  // delete supplier

export const supp_addr_save = "api/party/saveSupplier/";                              // save supplier and address
export const supp_contact_save = "api/party/saveSupplierContact/";                    // save supplier contact
export const supplier_csv = "api/party/getSupplierDetailsforcsv/"

export const nc_api = "/api/NC/v2/ncMaster/";
export const nc_list_age = "/"
export const nc_JobTag = "api/NC/v2/ncMaster_all/";
export const lotNum = "/api/MO/LotMasterApi";
export const prodList = "/api/product/v2/product/";
export const product_excel = "api/product/v2/product/excel/"                       // Product excel report download
export const oldprodList = "/api/product/Product/";
export const operationList = "/api/product/v2/operation/";
export const operationListFORNC = "/api/product/v2/product_operation_nc_api/";


export const machineList = "/api/machine/v2/machine/";
export const disposalActionList = "/api/MO/v2/ncStatus/";
export const ncReasonList = "/api/MO/v2/ncReasonMaster/";
// export const saveBulkNC = "api/NC/v2/ncMaster_bulk_add/"                             // NC bulk add old 
export const saveBulkNC = "api/NC/v2/NCMaster_Bulk_Add_NewApi/"                             // NC reason add new 03-01-2024
export const rawMaterialSpec = "/api/Tools/RawMaterialSpecificationApi/";
export const toolList = "/api/product/ToolApi/";
export const operatorList = "/api/allMaster/v2/employee/";
export const operatorList_availability = "/api/allMaster/v2/employee_job_card_wise_availability/";
export const operatorAttendance = "/api/attendence"
export const gaugeList = "/api/product/GaugeApi/";
export const SFGList = "/api/product/SemiFinishedGoodsAPI/";
export const SemiFinishProdList = "/api/product/SemiFinishedProductApi/";
export const materialList = "/api/Tools/v2/toolInventaryTransaction/";
export const productTypeList = "/api/product/ProductType/";
export const productCatList = "/api/product/getProductCategory/";
export const currList = "/api/plan_module/GetCurrency/";
export const toolGrade = "/api/Tools/ToolGradeApi/";
export const toolFamily = "/api/Tools/ToolFamilyApi/";
export const marketGrp = "/api/product/getMarketGroup/";
export const productFamily = "/api/product/getProductFamily/";
export const partyList = "/api/party/getPartyList/";

export const customerType = "api/party/v2/customerType/";                // Customer type master
export const supplierType = "api/allMaster/v2/supplier_type_api/";       // Supplier type master
export const ncReason = "api/MO/v2/ncReasonMaster/"                      // NC reason master
export const ncStatus = "api/MO/v2/ncStatus/"                            // NC status master
export const taskCategory = "api/machine/v2/task_category_api/"          // Task Category 
export const locationType = "api/allMaster/v2/locationType/"             // Location Type Master
export const location = "api/allMaster/v2/location/"                     // Location Master
export const locationMapping = "api/allMaster/v2/wip/"                   // Location Mapping
export const createproduct = "/api/product/Product/";
export const partyAgainstProd = "/api/product/savePartyAgainstProduct/";
export const ncImage = "/api/NC/v2/ncImage/";
export const ncExcel = "api/NC/v2/NCCSV/";
export const userManagement = "api/aut/v2/user/";
export const roles = "api/aut/v2/role/";                                 // User Roles
export const roleMap = "api/aut/v2/user_role/";                          // User and role mapping
export const app_user = "api/aut/v2/user/";                              // Application user
export const roleList = "api/aut/v2/role/";                              // Application user
export const moduleList = "api/aut/v2/module/";                          // Application user
export const product_type = "api/product/v2/product_type/";               // Product type
export const cutlot = "api/MO/v2/cutlot/";               // Product type
export const product_cat = "api/product/v2/product_category/";           // Product category
export const market_group = "api/product/v2/product_group_api/"          // Market Group
export const prod_family = "api/product/v2/product_family/"              // Product Family
export const product_image = "/api/product/v2/product_image/"              // Product Image
export const routesapi = "/api/product/v2/routes_api/"              // Product Image
export const opeartaionApi = "/api/product/v2/operation/"              // Product Opertions
export const attribute = "api/product/v2/attribute_api/"                        // Attribute Master
export const unit_of_measurement = "api/PO/v2/UOM_api/"                         // UOM Master
export const product_attribute = "api/product/v2/product_attribute_api/"        // Product attribute
// export const prodConfig = "api/product/v2/product_operations_routing_mutisave/" // Product attribute
export const prodConfig = "api/product/v2/product_operations_routing_multisave/" // Product attribute
export const multiImage = "api/product/v2/product_operation_multi_images_api/" // Product attribute
export const prod_config = "api/product/v2/product_operations_routing/"         // Product Operations Rounting
export const prod_machine = "api/product/v2/operation_machine_api/"         // Product Operations Rounting
export const prod_images = "api/product/v2/product_operation_images_api/"         // Product Operations Rounting
export const bom = "api/product/v2/bom_api/"
export const bom_list_by_uuid = "api/product/v2/bom_list_by_uuid/"
export const bom_list_by_product = "api/product/v2/bom_list_by_product/"
export const bommulti = "api/product/v2/bom_multisave_api/"
export const bomLineItem = "api/product/v2/bom_line_item_api/"

export const country_api = "api/allMaster/v2/country/"                         // county api 
export const state_api = "api/allMaster/v2/state/"                             // state api  
export const city_api = "api/allMaster/v2/city/"                               // city api 

export const party_contact = "api/party/v2/partyContact/"                      // party contact

export const raw_material_api = "api/product/v2/raw_material_product_api/"     // raw material product 
export const semi_finish_goods = ""     // semi finish goods material product

export const quotation = "api/PO/v2/quote_multisave_api/"                      // quotation multisave
export const quote_api = "api/PO/v2/quote_api/"
export const quote_products_api = "api/PO/v2/quote_products_api/"
export const delete_quote_product = "api/PO/v2/quote_api/delete_product/"       // delete quotation product

export const po_status = "api/v2/po_status_api/"
export const internal_company = "api/allMaster/v2/internal_company_api/"
export const po_api = "api/PO/v2/po_multisave_api/"
export const po_line_item = "api/v2/po_line_item"
export const po_api_1 = "api/PO/v2/po_api/"
export const GST = "api/allMaster/v2/check_gst_state_details_api/"
export const quote_line_items = "api/PO/v2/quote_product_list_api/"

export const task_api = "api/machine/v2/task_api/"
export const multi_master_attachment = "api/allMaster/v2/attachment_master_multi_save_api/"
export const get_master_attachment = "api/allMaster/v2/attachment_master_api/"
export const getPOTemplate = "api/PO/v2/export_template_PO_details_api/"
export const importPO = "api/PO/v2/import_PO_details_api/"
export const gst_treatment = "api/allMaster/v2/gst_treatment_api/"
export const gst_slab = "api/allMaster/v2/gst_slabs_master_api/"
export const org_type = "api/allMaster/v2/organisation_type_api/"
export const company_type = "api/allMaster/v2/company_type_type_api/"
export const inventory_list_prod = "api/product/v2/inventory_list_product/"
export const store_type = "api/allMaster/v2/store_type_api/"
export const store_api = `api/allMaster/${ver2}/store_master_api/`
export const store_api2 = `api/allMaster/${ver3}/store_master_api/`
export const purchase_inward = "api/PO/v2/po_inward_multisave_api/"
export const purchase_inward_node = "pcms/api/v1/purchaseinward/"             //node api created for purchase inward with rmi
export const poInward = "api/PO/v2/po_inward_master_api/"
// export const po_inv_items = "api/PO/v2/po_inward_line_item_api/"
export const po_inv_items = "pcms/api/v1/purchaseinward/lineitems"
export const po_inv_items_node = "pcms/api/v1/purchaseinward/rmidetails"
export const pi_delete_items = "pcms/api/v1/purchaseinward/delete_item"


export const po_api_with_inv = "api/PO/v2/po_api_with_inward/"                //api created for get po line items for quantity validation
export const po_api_with_inv_node = "api/PO/v2/po_api_with_inward/"                //node api created for get po line items for quantity validation

export const transactionmaster = "api/PO/v2/transaction_master_api/"
export const status_api = "api/PO/v2/status_master_api/"

export const sales_order_api = "api/invoice/v2/sales_order/"                     // sales order api 08-11-23 Niranjan Jadhav
export const so_status = "api/invoice/v2/sales_order_status/"
// export const so_line_item = "api/invoice/v2/SalesOrderLineItem"                  // sales order line item 11-01-2024
export const so_line_item = "api/v2/sales_order_line"                  // sales order line item 11-01-2024

export const material_type_api = "api/product/v2/material_type_api/";            // material types api for raw material product

export const work_order = "api/MO/v2/work-order/";

// Transation api constants, NC Dashboard API's
export const bi_getTypes = "pcms/bi/v2/get_types"
export const bi_getReason = "pcms/bi/v2/get_reason/count"
export const bi_getProductEmp = "pcms/bi/v2/get_product/employee_count"
export const bi_getProductEmpStack = "pcms/bi/v2/get_product/nc_employee/stack_chart"
export const bi_getProductOpr = "pcms/bi/v2/get_product/operation_name"
export const bi_getProductOprStack = "pcms/bi/v2/get_product/nc_operation/stack_chart"
export const bi_getProdMonthly = "pcms/bi/v2/get_product/data_monthly"
export const bi_getReasonMonthly = "pcms/bi/v2/get_reason/data_monthly"
export const bi_getProductReason = "pcms/bi/v2/get_product/nc_reason"
export const bi_getProductReasonStack = "pcms/bi/v2/get_product/nc_reason/stack_chart"

export const bi_getEmployeeProd = "pcms/bi/v2/get_employee/product_name"
export const bi_getEmployeeOpr = "pcms/bi/v2/get_employee/operation_name"
export const bi_getEmpReason = "pcms/bi/v2/get_employee/nc_reason"
// export const bi_nc_age="pcms/bi/v2/nc_age"
export const bi_nc_age = "pcms/bi/v2/get/ncage"

//cognito role endpoint
export const add_role = "add-role"
export const get_role = "get-roles"
export const update_role = "update-role"
export const delete_role = "delete-role"

//cognito user endpoints
export const add_user = "/api/add-user"
export const get_user = "get-users"
export const update_user = "update-user"
// export const delete_user = "delete-user"
export const delete_user = "/api/delete-user"


//cognito module 
export const get_modules = "get-modules"

//job-card apim
export const job_card = "/api/MO/v2/JobcardApi/"
export const job_card_excel = "api/v2/job_card"

// Production data api
export const production_data_api = "v2/prod_data_api/"
export const production_data_api_accumulated_qty = "v2/prod_data_api/accumulated_qty"

export const document_type_api = "api/allMaster/v2/task_document_type_api/"


// Designation Master
export const designation_api = "api/payroll/v2/designation_api/"

export const emp_doc_type = "api/allMaster/getTaskDocumentType/"


// Production Dashboard api's
export const bi_production_data_lot = "pcms/bi/v2/prod/data/lot"
export const bi_production_data_product = "pcms/bi/v2/prod/data/product"
export const bi_lot_produced_qty = "pcms/bi/v2/prod/lotNo"

export const bi_client_campaign = "/pcms/get/client_campaign"
export const bi_create_client_campaign = "/pcms/create/campaign_list"
export const bi_update_client_campaign = "/pcms/update/campaign_list"
export const bi_delete_client_campaign = "/pcms/delete/campaign_list"
export const bi_get_client_campaign = "/pcms/get/campaign_list"

export const raw_material_type = "pcms/rmi/api/v1/raw_material_sub_type"                // RMI raw material type
export const inspection_status = "pcms/rmi/api/v1/inspection_status_list"               // inspection list status

//tool type family 
export const tool_type_family = "api/Tools/v2/tool_family_api/"
// tool subfamily 
export const tool_subfamily = "api/Tools/v2/tool_subfamily_api/"
// tool grade 
export const tool_grade = "api/Tools/v2/tool_grade_api/"

export const production_data_excel = "pcms/bi/v2/excel/prod_fy_data"                    //Production data excel download

export const rmi_api = "pcms/rmi/api/v1/rmi"                              // Rmi data
// tool & fixture product 
export const tool_fixture_product = "api/product/v2/tools_fixtures_product/"

// tool & fixture operation mapping 
export const tool_fixture_operation = "api/Tools/v2/tool_operations_api/"

export const material_code = "pcms/api/v1/purchaseinward/latest_material_code"


// Bar Canversion Sheet
export const available_conversion_qty = "pcms/rmi/api/v1/bar/available_conversion_qty"
export const conversion_sheet = "pcms/rmi/api/v1/bar/conversion_sheet"
export const bar_length_detail = "pcms/rmi/api/v1/bar/length_details"
export const save_conversion_sheet = "pcms/rmi/api/v1/bar/save_conversion_sheet"
export const update_conversion_sheet = "pcms/rmi/api/v1/bar/update_conversion_sheet"

// Machine Model
export const machine_model = "api/allMaster/v2/machinemodel"


export const rmiList_for_cutlot = "api/MO/v2/cutlot/Tools/ToolInventaryTransactionApi/"
export const product_by_rmi = "api/MO/v2/cutlot/Tools/rmiDetails/"
export const cutlot_images = "api/MO/v2/cutlot/getImages"
export const delete_cutlot_images = "api/MO/v2/cutlot/deleteCutLotFile"

export const challan_type = "api/PO/v2/challantype/"

//Good challan 
export const MATERIAL_TYPE = "/api/PO/v2/materialType"
export const CHALLAN = "/api/PO/v2/challanList"
export const CHALLAN_NEW = "/api/PO/v2/challanList_new"
export const LASTCHALLAN = "/api/PO/v2/lastchallan"
export const CHALLAN_STATE = "/api/PO/v2/challan_state"
export const CHALLAN_TRANSPORT = "/api/PO/v2/getTransport"
export const CHALLAN_INTERNALTRANSPORT = "/api/PO/v2/getInternalTransport"
export const CHALLAN_GOODSMOVEMENTCHALLENSTATE = "/api/PO/v2/getGoodsMovementChallanState"
export const CHALLAN_CHALLANTYPEDETAILS = "/api/PO/v2/ChallanTypeAndDetails"
export const CHALLAN_GETLOTFROMPRODUCTTYPE = "/api/PO/v2/getLotFromProductType"
export const CHALLAN_GETPRODUCTFROMLOT = "/api/PO/v2/getProductFromLot"
export const CHALLAN_GETCURRENTSTOCKFOR_CUTLOTCHALLAN = "/api/PO/v2/getCurrentStockFor_CutlotChallan"
export const CHALLAN_EXCEL = "api/v2/challen"
export const CHALLAN_ITC_REPORT = "api/getItcReport/"
//RMI Image gallary 

export const GET_RMI_IMAGES = "/pcms/rmi/api/v1/rmi-docs";
export const UPLOAD_RMI_IMAGES = "/pcms/rmi/api/v1/upload-docs";
export const DELETE_RMI_IMAGES = "/pcms/rmi/api/v1/delete-rmi-doc";


// stock availablity api
export const stock_avail_api = "api/stock_availability/v2";
// export const stock_avail_api_v3 = "api/stock_availability/v3";
export const stock_avail_api_v3 = "api/stock_availability/v4";

// new work order api
export const workOrder = "api/workorder/v2";
export const workOrder_count_by_status = "api/workorder/v2/count_by_status";

// work order excel api 

export const work_order_excel = "api/v2/workorder"                    //Production data excel download


// inspection type api
export const inspection_type = "api/inspection/inspection_type_master"

// new job card api
export const jobCard = "api/job_card/v2"                          // Single job card creation api end point
export const jobCardBulk = "api/job_card/v3"                      // Bulk job card creation on multiple operator api end point
export const job_log_summary = "/joblog/getjoblogsummary"

// Reports api
export const stock_by_product = "api/inventory/V2/getStockByProduct";
export const stock_by_transaction = "api/inventory/V2/getStockByTransactions";
export const stock_availability_report = "api/inventory/V2/stockAvailabilityReport";
export const stock_ledger_report = "api/inventory/V2/stockAvailabilityReportLedger";

// Dispatch Master
export const dispatch_api = "dispatch"
export const dispatch_line_items = "dispatch/line_items"
export const dispatch_pkg_store_qty = "api/stock_availability/v2/package_store_qty"
export const dispatch_prev_rec = "dispatch/prev_received_qty"
export const dispatch_excel = "api/v2/dispatch"

//HSN master
export const HSN_master = "hsnmaster/hsn"

// Transporter master
export const transporter_api = "api/transporter/v2"

// Fetch GST in details
export const gst_details_api = "/api/v2/gstindetails"

// Corrective action master
export const corrective_api = "api/corrective_action/V2"

// Preventive action master
export const preventive_api = "api/preventive_action/V2"

// quick view api
export const quick_view_api = "api/v2/workorder_quick_view"
export const product_operation_bulk_operation = "api/productOperation/bulkdelete/V2/"

export const wo_product_routes = "api/workorder/v2/product_routes"
export const product_operation_template = "api/product/v2/product_operation_excel"
export const bulk_prod_operation_upload = "api/product/v2/import_product_operation_excel"

export const check_job_card_api = "v2/checkjobcard"
export const product_wise_bom_prod_plan = "api/product/v2/bom_api/product_wise"

export const stock_avail_multi_prod = "api/stock_availability/v2/production_plan"

// operation download template and excel upload
export const operation_template = "api/v2/operationexcel";

export const production_plan = "api/v2/production_planning";
export const create_mr_wo_jc = "api/v2/create_mr_wo_jc_prod_plan";
export const create_mr_wo_jcV3 = "api/v3/create_mr_wo_jc_prod_plan";
// Adjustment Reason
export const adjustment_reason = "api/adjustment_reason"

export const bi_jobcard_efficiency = "api/job_card/get_efficiency"
// export const bi_dispatch = "api/v2/dispatch_chart"
export const bi_dispatch = "api/v2/dispatch_chart/v2"
export const bi_nc_reason = "api/stack_chart/nc_employee/nc_reason"

// Material request
export const material_request = "api/v2/material_request"
export const material_request_pp = "api/v2/material_request_pp"
export const material_request_ppV3 = "api/v3/material_request_pp"
export const material_line_item = "api/v2/material_request/delete_item"


// Quick view excel download
export const quick_view_excel = "api/v2/quick_view_excel"

// Global Routing Master
export const global_routing = "api/routing_master";

// 
export const bi_disposal_action = "api/pie-chart/disposal_action";

// 
export const emp_ncCount_excl = "api/get_excel/all_employee_with_ncreason";

// mobile otp
export const mobile_otp = "/otp/send-otp"
export const mobile_verify_otp = "/otp/onboarding-verify-otp"
export const onboard_client_reg = "/otp/onboarding-client-registration"
export const forgot_password = "/otp/forgot-password-otp"
export const forgot_verify_otp = "/otp/verify-forgot-otp"
export const reset_password ="/otp/reset-password"

// shift master
export const shift_master_api = "/api/shift"
export const privileges = "/api/privileges"

// On Boarding master setup
export const masters_count = "api/onboarding";
export const onBoard_customer_mst = "api/v2/customerexcel"
export const onBoard_supplier_mst = "api/v2/supplierexcel"
export const onBoard_operation_mst = "api/v2/operationexcel"
export const onBoard_machine_mst = "api/v2/machineexcel"
export const onBoard_product_mst = "api/v2/product_master/excel"
export const onBoard_location_mst = "api/allMaster/v2/location/excel"
export const onBoard_store_mst = "api/allMaster/v2/storeexcel"

// Industry type
export const industry_type = "api/v2/industry_type"

// Vendor type
export const vendor_type = "api/v2/vendor_type"

// Break Code 
export const break_code="api/break-code"

// Reset Password
export const change_password = "api/reset-password"

export const s3_image_upload = "imageupload"

// PI without PO api
export const pi_wo_po  = "pcms/api/v1/purchaseinward/wo_po" 

// Sales order api for so_id and uuid 
export const sales_order_with_soid  = "api/invoice/v2/sales_order_with_soid/" 

// Sale order with measurements
export const attrib_api = "api/product/v2/attribute_api"                       // get all measurements attributes
export const party_attributes = "api/party/v2/party_attributes"                // party measurements attribute

//Light weight api of product
export const product_light_weight = "api/product/v3/product/"

export const contact_relation = "api/contact_relation_master"

export const rmi_report = "api/rmi-report" // material_code = A-0726-001

export const proforma_invoice="api/invoice_performa"

export const payment_receipt="api/payment_receipt_transaction"

export const prod_report_fashion = "api/prod_report_fashion"             //producion report api for fashion industry


export const biometric_attendance = "api/employee_biometric_attendance"
export const biometric_attendance_report = "api/employee_biometric_attendance_report"