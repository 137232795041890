import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Modal } from "react-bootstrap";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import {
    create_job_card, create_job_card_bulk,
    update_job_card,
} from "../../../../api";
import {
    convertDateYYMMDD,
    create_job_obj, convert_date_and_time, newDateTimeForm, adjustDateTime, validateJobCardTiming
} from "../../../../common/Functions/CommonFunctions";
import { useNavigate, useLocation } from "react-router-dom";
import { Typeahead } from "react-bootstrap-typeahead";
import DatePicker from "react-datepicker";
import { useSelector, useDispatch } from 'react-redux';
import { save_job_card } from '../../../../slices/production_planning/production_plan_slice';
import { handle_job_card_in_opr } from "../../../../slices/production_planning/route_operation_slice";
import { APPAREL, FASHION, } from "../../../../common/Constants/constants";

const schema = yup
    .object()
    .shape({
        startDate: yup
            .date()
            .typeError("Please select start date!")
            .required("Please select start date!"),
        startTime: yup
            .date()
            .typeError("Please select start time!")
            .required("Please select start time!"),
        endDate: yup
            .date()
            .typeError("Please select end date!")
            .required("Please select end date!"),
        endTime: yup
            .date()
            .typeError("Please select end time!")
            .required("Please select end time!"),
        target_qty: yup
            .string()
            .matches(/^[1-9][0-9]*$/, "Enter valid target qty")
            .required("Enter target qty !"),
        operator: yup
            .array()
            .min(1, "Please select operator !")
            .required("Please select operator !"),
        machine: yup
            .array()
            .min(1, "Please select machine !")
            .required("Please select machine !")

    })
    .required().test(
        'endDateTime-after-startDateTime',
        'End date and time must be after start date and time',
        function (values) {
            const { startDate, startTime, endDate, endTime, } = values;
            // console.log("startDate", startDate);
            // console.log("startTime", startTime);
            // console.log("endDate", endDate);
            // console.log("endTime", endTime);

            // Check if all fields are present
            if (!startDate || !startTime || !endDate || !endTime) {
                return this.createError({
                    path: 'endTime',
                    message: 'Please ensure all date and time fields are selected',
                });
            }
            // console.log('setting 0 time');
            startDate.setHours(0, 0, 0, 0);
            endDate.setHours(0, 0, 0, 0);

            // console.log('before if');
            if (startDate > endDate) {
                return this.createError({
                    path: 'endDate',
                    message: 'Start date should be greater or equal to end date',
                });
            }
            // console.log('afterr if');
            const startDateTime = convert_date_and_time(startDate, startTime);
            const endDateTime = convert_date_and_time(endDate, endTime);

            // console.log("startDateTime", startDateTime);
            // console.log("endDateTime", endDateTime);

            if (endDateTime <= startDateTime) {
                return this.createError({
                    path: 'endTime',
                    message: 'End date and time must be after start date and time',
                });
            }

            return true;
        }
    );

function JobCardModalV3(props) {
    const { visible, setVisible, fetch_emp_job_cards, events } = props;
    const dispatch = useDispatch();
    const localData = JSON.parse(localStorage.getItem("localData"));

    const [constLabel, setConstLabel] = useState({});

    let navigate = useNavigate();
    let location = useLocation();

    const { emp_list, status: emp_list_status } = useSelector((state) => state.employees);
    const { base_data, selectedProduct, status } = useSelector((state) => state.products);
    const { data: operation_list, status: operation_list_status,
        selected_route, selected_opr } = useSelector((state) => state.operation_list);

    const loginData = useSelector((state) => state.auth);

    const [disDeleteBtn, setDisDeleteBtn] = useState(false);
    const { data: productionplanDetails, } = useSelector((state) => state.prod_plan_details);
    // setting this value in useEffect every time modal is opened setting latest value from localstorage


    const disableTargetQty = loginData.loginData?.data?.response.role_name !==
        "client_admin" &&
        loginData.loginData?.data?.response.role_name !==
        "admin" ? true : false;

    const [jobStatus, setJobStatus] = useState([
        { id: 1, statusName: "Open" },
        { id: 2, statusName: "In Progress" },
        { id: 3, statusName: "Hold" },
        { id: 4, statusName: "Closed" },
        { id: 5, statusName: "Cancelled" }
    ]);
    const cuur_date_plus_one_hr = new Date();
    cuur_date_plus_one_hr.setHours(cuur_date_plus_one_hr.getHours() + 1);

    const { register, handleSubmit, formState, control, reset, watch, setError, setValue, clearErrors } = useForm({
        resolver: yupResolver(schema),
        criteriaMode: 'all',
        mode: "onChange",
    }); // initialise the hook

    let { errors } = formState;


    const selProdOpr = watch("prod_operation");






    useEffect(() => {
        console.log("visible", visible);
        console.log("emp_list", emp_list);
        console.log("selected_opr", selected_opr);
        if (visible.state && visible.data) {
            const selected_product = JSON.parse(JSON.stringify(selectedProduct));
            delete selected_product.bom;
            delete selected_product.selected_bom;

            setValue("product", [selected_product]);
            setValue("partNo", selected_product.partNo);
            setValue("startDate", new Date(selected_opr.operation_details.startDate));
            setValue("startTime", new Date(selected_opr.operation_details.startDate));
            setValue("endDate", new Date(selected_opr.operation_details.endDate));
            setValue("endTime", new Date(selected_opr.operation_details.endDate));
            setValue("target_qty", 1);
            setValue("actual_qty", 0);
            console.log("selected_opr", selected_opr);
            setValue("prod_operation", [selected_opr]);

            let foundedEmp = emp_list.find((val) => val.uuid == visible.data.resourceId);
            foundedEmp = JSON.parse(JSON.stringify(foundedEmp));
            // temporary commenting below check if problem is coming 
            // delete foundedEmp.jobcard_list;
            setValue("operator", [foundedEmp]);

            // setValue("remarks", visible.data.remarks ?? "");
            console.log(selected_opr);
            const selected_operation = JSON.parse(JSON.stringify(selected_opr));
            console.log("selected_operation", selected_operation);

            let default_machine;
            if (selected_operation && selected_operation?.machine?.length == 1) {
                default_machine = selected_operation.machine;
            } else if (selected_operation?.machine?.length > 1) {
                //Block change by Niranjan 29-03-2025 11:34
                selected_operation.machine.forEach(element => {
                    if (element.name?.toLowerCase().includes("default") || element.name?.toLowerCase().includes("dummy machine")) {
                        default_machine = [element];
                    } else {
                        default_machine = [];
                    }
                });

            }

            console.log("default_machine", default_machine);
            //Block change by Niranjan 29-03-2025 11:34
            if(default_machine && default_machine?.length > 0){
                default_machine[0].machineNameSerialNo =
                default_machine[0].serialNo && default_machine[0].serialNo !== ""
                    ? default_machine[0].name + " - " + default_machine[0].serialNo
                    : default_machine[0].name;

            setValue("machine", default_machine);
            setValue("cycle_time", default_machine?.[0]?.cycle_time);
            } else {
                toast.error("Please add machine for the operation!", {
                    autoClose: 2000,
                });
            }

            


        } else if (visible.state && visible.displayData) {
            display_data();
        }
    }, [visible.state]);


    function display_data() {
        console.log(visible.displayData);
        if (visible.displayData.uuid) {
            delete visible.displayData.productDetail.bom;
            delete visible.displayData.productDetail.selected_bom;

            setValue("product", [visible.displayData.productDetail]);
            setValue("partNo", visible.displayData.productDetail.partNo);
            // setValue("startDate", new Date(visible.displayData.start_time.replace(".000Z", "")));
            setValue("startDate", new Date(visible.displayData.start_time));
            setValue("startTime", new Date(visible.displayData.start_time));
            // setValue("endDate", new Date(visible.displayData.end_time.replace(".000Z", "")));
            setValue("endDate", new Date(visible.displayData.end_time));
            setValue("endTime", new Date(visible.displayData.end_time));
            setValue("target_qty", visible.displayData.planned_qty);
            setValue("actual_qty", visible.displayData.actual_qty ?? 0);
            if (visible.displayData.workorderDetails.global_route_uuid) {

                console.log("visible.displayData?.operationDetails", visible.displayData?.operationDetails);

                setValue("prod_operation", [{
                    operationCategoryId: visible.displayData?.operationDetails?.operationCategoryId,
                    operation_category_id: visible.displayData?.operationDetails?.operation_category_id,
                    operation_id: visible.displayData?.operationDetails?.uuid,
                    operationname: visible.displayData?.operationDetails?.operationName,
                    oprationCategoryName: visible.displayData?.operationDetails?.oprationCategoryName,
                }])
            } else {
                setValue("prod_operation", [visible.displayData.productOperDetails]);
            }
            setValue("remarks", visible.displayData.remarks ?? "");
            // setValue("machine", [visible.displayData.machineDetails]);

            const operator_data =
                JSON.parse(JSON.stringify(visible.displayData.operatorDetails));
            delete operator_data.jobcard_list;

            if (operator_data) {
                operator_data.FullName = operator_data.MiddleName !== null && operator_data.MiddleName !== ""
                    ? operator_data.FirstName + " " + operator_data.MiddleName + " " + operator_data.LastName
                    : operator_data.FirstName + " " + operator_data.LastName;

                setValue("operator", [operator_data]);
            } else {
                setValue("operator", []);
            }

            const machine_data = JSON.parse(JSON.stringify(visible.displayData.machineDetails));
            if (machine_data) {
                machine_data.machineNameSerialNo =
                    machine_data.serialNo && machine_data.serialNo !== ""
                        ? machine_data.machineName + " - " + machine_data.serialNo
                        : machine_data.machineName;
                setValue("machine", [machine_data]);
            } else {
                setValue("machine", []);
            }
            setValue("cycle_time", visible.displayData.cycle_time);

        } else {
            setValue("product", [visible.displayData.productDetail]);
            setValue("partNo", visible.displayData.productDetail.partNo);
            setValue("startDate", new Date(visible.displayData.operationDetails.startDate));
            setValue("startTime", new Date(visible.displayData.operationDetails.startDate));
            setValue("endDate", new Date(visible.displayData.operationDetails.endDate));
            setValue("endTime", new Date(visible.displayData.operationDetails.endDate));
            setValue("target_qty", visible.displayData.planned_qty);
            setValue("actual_qty", visible.displayData.actual_qty);
            setValue("prod_operation", [visible.displayData.productOperDetails]);
            setValue("operator", [visible.displayData.operatorDetails]);

            console.log(visible.displayData.operationDetails);
            console.log(visible.displayData.operationDetails.machine_data[0].machine);

            if (visible.displayData.operationDetails &&
                visible.displayData.operationDetails.machine_data) {
                const machineData = { ...visible.displayData.operationDetails.machine_data[0].machine[0] };

                machineData.machineNameSerialNo =
                    machineData.serialNo && machineData.serialNo !== ""
                        ? machineData.machineName + " - " + machineData.serialNo
                        : machineData.machineName;

                setValue("machine", [machineData]);
                console.log("machineData", machineData);
            } else {
                console.log("asdasdsd");
                setValue("machine", []);
            }

            setValue("cycle_time", visible.displayData.cycle_time);
            setValue("remarks", visible.displayData.remarks ?? "");
        }
    }



    const onSubmit = (data) => {
        if (data !== "") {
            setDisDeleteBtn(true);

            console.log("data", data);
            console.log("productionplanDetails", productionplanDetails);
            console.log("selected_route", selected_route);
            console.log("visible", visible);

            const fromDate = newDateTimeForm(data.startDate, data.startTime);
            const toDate = newDateTimeForm(data.endDate, data.endTime);
            const timeDiff = Math.abs(new Date(fromDate) - new Date(toDate));


            if (!visible?.displayData && !visible?.displayData?.uuid &&
                productionplanDetails?.workorder?.length > 0) {

                const foundedWO = productionplanDetails?.workorder?.find((val) =>
                    val.product_id_id == data?.product?.[0]?.uuid);

                const payload = {
                    work_order_id: foundedWO?.uuid,
                    product_id: data?.product?.[0]?.uuid,
                    // product_operation_id: data.prod_operation?.[0]?.uuid,
                    cycle_time: data?.cycle_time,
                    // operator: data.operator?.[0]?.uuid,
                    operator: data.operator.map(val => val.uuid),
                    start_time: fromDate,
                    end_time: toDate,
                    total_time_hrs: Math.floor(timeDiff / 3600000).toString(),
                    total_time_mins: Math.floor(timeDiff / 60000).toString(),
                    total_time_sec: Math.floor(timeDiff / 1000).toString(),
                    planned_qty: parseInt(data.target_qty),
                    // actual_qty: data.actual_qty,
                    status: data?.status?.[0]?.statusName,
                    remarks: data.remarks !== "" ? data.remarks : null,
                    machine: data.machine?.[0]?.machine_id_id,
                };

                // selected_route?.[0]?.routes1 means its a product route
                if (selected_route?.routes1) {
                    payload.product_operation_id = data.prod_operation?.[0]?.uuid;
                    payload.operation_uuid = data.prod_operation?.[0]?.operation_id;
                } else if (selected_route?.uuid) {
                    // selected_route?.[0]?.uuid means its global route
                    payload.operation_uuid = data.prod_operation?.[0]?.operation_id;
                }

                console.log("create payload", payload);

                create_job_card_bulk(payload).then(
                    (res) => {
                        if (res.status === 200 || res.status === 201) {
                            toast.success("Job card created successfully !", {
                                autoClose: 2000,
                            });

                            setVisible({
                                state: false, data: null,
                                displayData: null
                            });

                            fetch_emp_job_cards();
                        }
                    },
                    (err) => {
                        if (err.response.status === 400) {
                            toast.error(err.response.data.message, {
                                autoClose: 2000,
                            });
                        }
                    }
                );
                return
            }

            if (visible.displayData !== null && visible.displayData.uuid !== null) {
                const payload = {
                    uuid: visible.displayData?.uuid,
                    work_order_id: visible.displayData.work_order_id,
                    product_id: data?.product?.[0]?.uuid,
                    // product_operation_id: data.prod_operation?.[0]?.uuid,
                    cycle_time: data.cycle_time,
                    operator: data.operator?.[0]?.uuid,
                    start_time: fromDate,
                    end_time: toDate,
                    total_time_hrs: Math.floor(timeDiff / 3600000).toString(),
                    total_time_mins: Math.floor(timeDiff / 60000).toString(),
                    total_time_sec: Math.floor(timeDiff / 1000).toString(),
                    planned_qty: parseInt(data.target_qty),
                    // actual_qty: data.actual_qty,
                    remarks: data.remarks !== "" ? data.remarks : null,
                    status: data.status?.[0]?.statusName,
                    machine: data.machine?.[0]?.uuid,
                };

                if (visible.displayData?.workorderDetails?.global_route_uuid !== null) {
                    payload.operation_uuid = data.prod_operation?.[0]?.operation_id;
                } else {
                    payload.product_operation_id = data.prod_operation?.[0]?.uuid;
                    payload.operation_uuid = data.prod_operation?.[0]?.operation_id;
                }

                console.log("update payload", payload);

                update_job_card(payload).then(
                    (res) => {
                        if (res.status === 200 || res.status === 201) {
                            toast.success("Job card updated successfully !", {
                                autoClose: 2000,
                            });

                            setVisible({
                                state: false, data: null,
                                displayData: null
                            });

                            fetch_emp_job_cards();
                        }
                    },
                    (err) => {
                        console.log("err", err);
                        if (err.response.status === 400) {
                            toast.error(err.response.data.message, {
                                autoClose: 2000,
                            });
                            setDisDeleteBtn(false);
                        }

                    }
                );
            } else {
                if (visible.data) {
                    data.resourceId = visible.data.resourceId;
                } else {
                    data.resourceId = visible.displayData.resourceId;
                }
                const startDateTime = convert_date_and_time(data.startDate, data.startTime);
                const endDateTime = convert_date_and_time(data.endDate, data.endTime);

                const timeDiff = Math.abs(new Date(startDateTime) - new Date(endDateTime));
                data.total_time_hrs = Math.floor(timeDiff / 3600000).toString();
                data.total_time_mins = Math.floor(timeDiff / 60000).toString();
                data.total_time_sec = Math.floor(timeDiff / 1000).toString();

                console.log("base_data", base_data);
                console.log("events", events);

                const { valid, message } = validateJobCardTiming(events, data, visible);
                if (valid) {
                    for (const operator of data.operator) {
                        console.log("operator", operator);

                        const job_card_payload = { ...data };
                        job_card_payload.operator = [{ ...operator }];
                        job_card_payload.resourceId = operator.uuid
                        console.log("job_card_payload", job_card_payload);

                        dispatch(handle_job_card_in_opr({ data: job_card_payload, action: "add" }));
                    }
                    setVisible({
                        state: false, data: null,
                        displayData: null
                    });
                } else {
                    toast.error(message, {
                        autoClose: 5000,
                    });
                    setDisDeleteBtn(false);
                }

            }

        } else {
            errors.showMessages();
        }
    };

    return (
        <React.Fragment>



            <Modal show={visible.state} onHide={() => {
                setVisible({ state: false, data: null, displayData: null });
            }} size={"xl"}>
                <Modal.Header
                    style={{ background: "#2A3643", color: "white" }}
                    closeButton
                >
                    <Modal.Title>
                        {visible.displayData !== null ?
                            constLabel?.lbl_view_job_card
                                ? constLabel.lbl_view_job_card
                                : `Job Card Details - ${visible.displayData.job_card_no}`
                            :
                            constLabel?.lbl_create_job_card
                                ? constLabel.lbl_create_job_card
                                : "Create Job Card"
                        }


                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row pb-3">

                        <div className="col-12 col-md-3">
                            <div className="form-group mt-md-0 mt-3">
                                <label
                                    style={{ fontSize: "14px" }}
                                    className="col-form-label roboto-bold"
                                >
                                    {constLabel?.lbl_product_name
                                        ? constLabel.lbl_product_name
                                        : "Product Name"}
                                </label>

                                <Controller
                                    name="product"
                                    control={control}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <Typeahead
                                            id="basic-typeahead-single"
                                            labelKey="productName"
                                            multiple={false}
                                            placeholder="Select product"
                                            options={[]}
                                            disabled
                                            onBlur={onBlur}
                                            selected={value}
                                            isInvalid={errors.product ? true : false}
                                        />
                                    )}
                                />
                                <span className="text-danger err-msg">
                                    {errors.product?.message}
                                </span>
                            </div>
                        </div>

                        <div className="col-12 col-md-3">
                            <div className="form-group mt-md-0 mt-3">
                                <label
                                    style={{ fontSize: "14px" }}
                                    className="col-form-label roboto-bold"
                                >
                                    {constLabel?.lbl_part_no
                                        ? constLabel.lbl_part_no
                                        : "Part No"}
                                </label>

                                <input
                                    type="text"
                                    className="form-control "
                                    id="partNo"
                                    name="partNo"
                                    placeholder="Enter part no"
                                    disabled
                                    {...register("partNo")}
                                />

                            </div>
                        </div>

                        <div className="col-12 col-md-3">
                            <div className="form-group mt-md-0 mt-3">
                                <label
                                    style={{ fontSize: "14px" }}
                                    className="col-form-label roboto-bold"
                                >
                                    {constLabel?.lbl_status ? constLabel.lbl_status : "Status"}
                                </label>

                                <Controller
                                    name="status"
                                    control={control}
                                    defaultValue={[{ id: 1, statusName: "Open" }]}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <Typeahead
                                            id="basic-typeahead-single"
                                            labelKey="statusName"
                                            multiple={false}
                                            defaultSelected={[{ id: 1, statusName: "Open" }]}
                                            // size="sm"
                                            options={jobStatus}
                                            placeholder="Select status"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            selected={value}
                                            disabled
                                        />
                                    )}
                                />

                            </div>
                        </div>

                        <div className="col-12 col-md-3">
                            <div className="form-group mt-md-0 mt-3">
                                <label
                                    style={{ fontSize: "14px" }}
                                    className="col-form-label roboto-bold"
                                >
                                    {constLabel?.lbl_start_date
                                        ? constLabel.lbl_start_date
                                        : "Start Date"}
                                    <span className="text-danger"> *</span>
                                </label>

                                <Controller
                                    control={control}
                                    name="startDate"
                                    render={({ field }) => (
                                        <DatePicker
                                            className={
                                                errors.startDate
                                                    ? "form-control  is-invalid"
                                                    : "form-control  "
                                            }
                                            placeholderText="Select date"
                                            selected={field.value}
                                            onChange={(date) => {
                                                field.onChange(date);
                                            }}
                                            dateFormat="dd/MM/yyyy"
                                            autoComplete="false"
                                            // disable condition is when job card is not present in db the fields should be disabled
                                            // if job cards present in db are displaying here fields should be available to edit and update
                                            disabled={!visible.displayData || !visible.displayData?.uuid}

                                        />
                                    )}
                                />

                                {errors.startDate && (
                                    <span className="text-danger err-msg">
                                        {errors.startDate.message}
                                    </span>
                                )}
                            </div>
                        </div>

                        <div className="col-12 col-md-3 mt-2">
                            <div className="form-group mt-md-0 mt-3">
                                <label
                                    style={{ fontSize: "14px" }}
                                    className="col-form-label roboto-bold"
                                >
                                    {constLabel?.lbl_start_time
                                        ? constLabel?.lbl_start_time
                                        : "Start Time"}
                                    <span className="text-danger"> *</span>
                                </label>


                                <Controller
                                    control={control}
                                    name="startTime"
                                    render={({ field }) => (
                                        <DatePicker
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={30}
                                            timeCaption="Time"
                                            className={
                                                errors.startTime
                                                    ? "form-control  is-invalid"
                                                    : "form-control  "
                                            }
                                            placeholderText="Select start time"
                                            selected={field.value}
                                            onChange={(date) => {
                                                field.onChange(date);
                                            }}
                                            dateFormat="h:mm aa"
                                            autoComplete="false"
                                            // disable condition is when job card is not present in db the fields should be disabled
                                            // if job cards present in db are displaying here fields should be available to edit and update
                                            disabled={!visible.displayData || !visible.displayData?.uuid}
                                        // minDate={
                                        //     new Date(new Date().getFullYear(), new Date().getMonth() - 3, new Date().getDate()).getTime()
                                        // }
                                        />
                                    )}
                                />


                                {errors.startTime && (
                                    <span className="text-danger err-msg">
                                        {errors.startTime.message}
                                    </span>
                                )}
                            </div>
                        </div>

                        <div className="col-12 col-md-3 mt-2">
                            <div className="form-group mt-md-0 mt-3">
                                <label
                                    style={{ fontSize: "14px" }}
                                    className="col-form-label roboto-bold"
                                >
                                    {constLabel?.lbl_end_date
                                        ? constLabel.lbl_end_date
                                        : "End Date"}
                                    <span className="text-danger"> *</span>
                                </label>

                                <Controller
                                    control={control}
                                    name="endDate"
                                    render={({ field }) => (
                                        <DatePicker
                                            className={
                                                errors.endDate
                                                    ? "form-control  is-invalid"
                                                    : "form-control  "
                                            }
                                            placeholderText="Select date"
                                            selected={field.value}
                                            onChange={(date) => {
                                                field.onChange(date);
                                            }}
                                            dateFormat="dd/MM/yyyy"
                                            autoComplete="false"
                                            // disable condition is when job card is not present in db the fields should be disabled
                                            // if job cards present in db are displaying here fields should be available to edit and update
                                            disabled={!visible.displayData || !visible.displayData?.uuid}
                                        />
                                    )}
                                />

                                {errors.endDate && (
                                    <span className="text-danger err-msg">
                                        {errors.endDate.message}
                                    </span>
                                )}
                            </div>
                        </div>

                        <div className="col-12 col-md-3 mt-2">
                            <div className="form-group mt-md-0 mt-3">
                                <label
                                    style={{ fontSize: "14px" }}
                                    className="col-form-label roboto-bold"
                                >
                                    {constLabel?.lbl_end_time
                                        ? constLabel?.lbl_end_time
                                        : "End Time"}
                                    <span className="text-danger"> *</span>
                                </label>



                                <Controller
                                    control={control}
                                    name="endTime"
                                    render={({ field }) => (
                                        <DatePicker
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={30}
                                            timeCaption="Time"
                                            className={
                                                errors.endTime
                                                    ? "form-control  is-invalid"
                                                    : "form-control  "
                                            }
                                            placeholderText="Select start time"
                                            selected={field.value}
                                            onChange={(date) => {
                                                field.onChange(date);
                                            }}
                                            dateFormat="h:mm aa"
                                            autoComplete="false"
                                            // disable condition is when job card is not present in db the fields should be disabled
                                            // if job cards present in db are displaying here fields should be available to edit and update
                                            disabled={!visible.displayData || !visible.displayData?.uuid}
                                        // minDate={
                                        //     new Date(new Date().getFullYear(), new Date().getMonth() - 3, new Date().getDate()).getTime()
                                        // }
                                        />
                                    )}
                                />


                                {errors.endTime && (
                                    <span className="text-danger err-msg">
                                        {errors.endTime.message}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>

                    <hr className="my-2" />
                    <p className="roboto-bold mb-0" style={{ fontSize: "20px" }}>
                        {constLabel?.lbl_job_card_detail
                            ? constLabel.lbl_job_card_detail
                            : "Job Card Detail"}
                    </p>
                    <hr className="my-2" />
                    <div className="row pb-3">
                        <div className="col-12 col-md-3">
                            <div className="form-group mt-md-0 mt-3">
                                <label
                                    style={{ fontSize: "14px" }}
                                    className="col-form-label roboto-bold"
                                >
                                    {constLabel?.lbl_product_operation
                                        ? constLabel.lbl_product_operation
                                        : "Product Operation"}
                                    <span className="text-danger"> *</span>
                                </label>

                                <Controller
                                    name="prod_operation"
                                    control={control}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <Typeahead
                                            id="basic-typeahead-single"
                                            labelKey="operationname"
                                            multiple={false}
                                            options={[]}
                                            placeholder="Select product operation"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            selected={value}
                                            isInvalid={errors.prod_operation ? true : false}
                                            disabled
                                        />
                                    )}
                                />
                                <span className="text-danger err-msg">
                                    {errors.prod_operation?.message}
                                </span>
                            </div>
                        </div>

                        <div className="col-12 col-md-3">
                            <div className="form-group mt-md-0 mt-3">
                                <label
                                    style={{ fontSize: "14px" }}
                                    className="col-form-label roboto-bold"
                                >
                                    {constLabel?.lbl_machine
                                        ? constLabel.lbl_machine
                                        : "Machine"}
                                    <span className="text-danger"> *</span>
                                </label>

                                <Controller
                                    name={`machine`}
                                    control={control}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <Typeahead
                                            id="basic-typeahead-single"
                                            labelKey="machineNameSerialNo"
                                            multiple={false}
                                            // options={machineList.data}
                                            // isLoading={machineList.loading}
                                            placeholder="Select machine"
                                            onChange={(selected) => {
                                                onChange(selected);
                                                if (selected.length > 0) {
                                                    setValue("cycle_time", selected?.[0]?.cycle_time);
                                                    setValue(
                                                        "setting_time",
                                                        selected?.[0]?.settings_time
                                                    );
                                                } else {
                                                    setValue("cycle_time", "");
                                                    setValue("setting_time", "");
                                                }
                                            }}
                                            onBlur={onBlur}
                                            selected={value}
                                            isInvalid={errors.machine ? true : false}
                                            disabled
                                        />
                                    )}
                                />
                                {errors.machine && (
                                    <span className="text-danger err-msg">
                                        {errors.machine.message}
                                    </span>
                                )}
                            </div>
                        </div>

                        <div className="col-12 col-md-3">
                            <div className="form-group mt-md-0 mt-3">
                                <label
                                    style={{ fontSize: "14px" }}
                                    className="col-form-label roboto-bold"
                                >
                                    {constLabel?.lbl_cycle_time
                                        ? constLabel.lbl_cycle_time
                                        : "Cycle Time"}

                                </label>
                                <input
                                    type="text"
                                    className="form-control "
                                    id="cycle_time"
                                    name="cycle_time"
                                    placeholder="Enter cycle time"
                                    disabled
                                    {...register("cycle_time")}
                                />

                            </div>
                        </div>

                        <div className="col-12 col-md-3">
                            <div className="form-group mt-md-0 mt-3">
                                <label
                                    style={{ fontSize: "14px" }}
                                    className="col-form-label roboto-bold"
                                >
                                    {constLabel?.lbl_target_qty
                                        ? constLabel.lbl_target_qty
                                        : "Target Qty"}
                                    <span className="text-danger"> *</span>{" "}

                                </label>
                                <input
                                    type="text"
                                    className={
                                        errors.target_qty
                                            ? "form-control  is-invalid"
                                            : "form-control  "
                                    }
                                    id="target_qty"
                                    name="target_qty"
                                    placeholder="Enter target qty"
                                    {...register(`target_qty`, {
                                        onChange: (e) => {
                                            setValue("target_qty", e.target.value, { shouldValidate: true });
                                        },
                                    })}
                                // disabled={disableTargetQty}
                                // disabled={true}
                                />
                                <span className="text-danger err-msg">
                                    {errors.target_qty?.message}
                                </span>
                            </div>
                        </div>

                        <div className="col-12 col-md-3 mt-2">
                            <div className="form-group mt-md-0 mt-3">
                                <label
                                    style={{ fontSize: "14px" }}
                                    className="col-form-label roboto-bold"
                                >
                                    {constLabel?.lbl_actual_qty
                                        ? constLabel.lbl_actual_qty
                                        : "Actual Qty"}
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="actual_qty"
                                    defaultValue={0}
                                    name="actual_qty"
                                    placeholder="Enter actual qty"
                                    {...register("actual_qty")}
                                    disabled
                                />
                            </div>
                        </div>


                        <div className="col-12 col-md-3 mt-2">
                            <div className="form-group mt-md-0 mt-3">
                                <label
                                    style={{ fontSize: "14px" }}
                                    className="col-form-label roboto-bold"
                                >
                                    {constLabel?.lbl_operator
                                        ? constLabel.lbl_operator
                                        : "Operator"}
                                    <span className="text-danger"> *</span>
                                </label>

                                <Controller
                                    name="operator"
                                    control={control}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <Typeahead
                                            id="basic-typeahead-single"
                                            labelKey="FullName"
                                            multiple={
                                                // [FASHION, APPAREL].includes(localData?.industry_type) &&
                                                selected_opr?.operation_details?.group_jobCards &&
                                                !visible.displayData
                                            }
                                            options={emp_list || []}
                                            disabled={visible.displayData}
                                            placeholder="Select operator"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            selected={value}
                                            isInvalid={errors.operator ? true : false}
                                        />
                                    )}
                                />
                                <span className="text-danger err-msg">
                                    {errors.operator?.message}
                                </span>
                            </div>
                        </div>


                        <div className="col-12 col-md-3 mt-2">
                            <div className="form-group mt-md-0 mt-3">
                                <label
                                    style={{ fontSize: "14px" }}
                                    className="col-form-label roboto-bold"
                                >
                                    {constLabel?.lbl_remarks
                                        ? constLabel.lbl_remarks
                                        : "Remarks"}
                                </label>
                                <textarea
                                    type="text"
                                    className="form-control"
                                    id="remarks"
                                    name="remarks"
                                    placeholder="Enter remarks"
                                    {...register("remarks")}
                                >
                                </textarea>

                            </div>
                        </div>

                        <div className="col-12 col-md-3 mt-2">
                            <label className="lbl-style roboto-bold invisible">
                                {"Unit"}
                            </label>
                            <div className="d-flex align-items-center mt-3">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        defaultChecked={selProdOpr?.[0]?.serial_number_tracking}
                                        disabled
                                        checked={selProdOpr?.[0]?.serial_number_tracking}
                                    />
                                    <label className="form-check-label lbl-style roboto-bold">
                                        {constLabel?.lbl_serial_number_tracking
                                            ? constLabel?.lbl_serial_number_tracking
                                            : "Serial number tracking"}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>

                    <div className="d-md-flex justify-content-end">
                        {visible.displayData && visible.displayData?.uuid == null &&
                            <button
                                onClick={() => {
                                    setVisible({
                                        state: false, data: null,
                                        displayData: null
                                    });
                                    dispatch(handle_job_card_in_opr({
                                        data: visible.displayData,
                                        operation_list: operation_list,
                                        action: "delete"
                                    }));
                                }}
                                className="f-16 btn button-danger me-2"
                                disabled={disDeleteBtn}
                            >
                                {constLabel?.lbl_delete ? constLabel.lbl_delete : "Delete Job Card"}
                            </button>
                        }
                        <button
                            onClick={handleSubmit(onSubmit)}
                            className="f-16 btn btn-yellow "
                            disabled={disDeleteBtn}
                        >
                            {constLabel?.lbl_save ? constLabel.lbl_save : "Save"}
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default JobCardModalV3