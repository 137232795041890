import React, { useEffect, useState, useRef } from "react";
import {
  getInternalCompany,
  createEmpApi,
  get_country,
  get_state,
  get_city,
  getDesignationApi,
  getLocation,
  updateEmpApi,
  getDocTypes,
  master_multi_file_upload,
  getOperationCat,
  getProduct_Family,
} from "../../../api/index";
import { ToastContainer, toast } from "react-toastify";
import {
  calMaxPage,
  getLabels,
  setAccessPrivilage,
  capitalizeFirstLetter,
  convertBase64,
} from "../../../common/Functions/CommonFunctions";
import { imagefileTypes } from "../../../common/Common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Nav, Tab, Tabs } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import DatePicker from "react-datepicker";
import { useNavigate, useLocation } from "react-router-dom";
import EmployeeGallery from "./EmployeeGallery";
import {
  APPAREL,
  FASHION,
  JUNIOR_OPERATOR,
  MENS_WEAR,
  OPERATOR,
  PER_DAY_WAGE,
  PER_HOUR_WAGE,
  PER_MONTH_WAGE,
  SENIOR_OPERATOR,
  UPDATE,
  WOMENS_WEAR,
} from "../../../common/Constants/constants";



const schema = yup
  .object()
  .shape({
    branch: yup
      .array()
      .required("Please select organization")
      .min(1, "Please select organization"),
    EmployeeCardId: yup
      .string()
      .required("Employee card id is required")
      .max(500, "Reached character limit"),
    FirstName: yup
      .string()
      .required("First name is required")
      .max(500, "Reached character limit"),
    MiddleName: yup
      .string()
      .matches(/^[a-zA-Z ]*$/, "Enter valid middle name")
      .max(500, "Reached character limit")
      .nullable() // Allows null as a valid value
      .notRequired(), // Allows empty string as a valid value
    LastName: yup
      .string()
      .required("Last name is required")
      .max(500, "Reached character limit"),
    state: yup
      .array()
      .min(1, "Please select state")
      .required("Please select state"),
    country: yup
      .array()
      .min(1, "Please select country")
      .required("Please select country"),
    city: yup
      .array()
      .min(1, "Please select city")
      .required("Please select city"),
    Designation: yup
      .array()
      .min(1, "Please select designation")
      .required("Please select designation"),
    gender: yup.string().required("Please select gender"),
    address: yup
      .string()
      .required("Address is required")
      .max(500, "Reached character limit"),
    ContactNo: yup
      .string()
      .matches(/^[6-9]\d{9}$/, "Enter valid contact number")
      .required("Address is required"),
    // Location: yup.string().required("Location is required"),
    Location: yup
      .array()
      .min(1, "Please select location")
      .required("Please select location"),
    email: yup.string().email("Enter valid email id").notRequired(),
    aadhar_no: yup
      .string()
      .transform((value, originalValue) => (originalValue == "" ? null : value))
      .matches(
        /^[2-9][0-9]{3}\s[0-9]{4}\s[0-9]{4}$/,
        "Enter valid aadhar number"
      )
      .nullable(),
    joining_date: yup
      .date()
      .required("Joining date is required")
      .typeError("Joining date is required"),
    emp_type: yup
      .array()
      .min(1, "Please select employee type")
      .required("Please select employee type"),
    job_type: yup
      .array()
      .min(1, "Please select job type")
      .required("Please select job type"),

    per_hour_wage: yup
      .string()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === ""
          ? null
          : typeof originalValue === "number"
          ? String(originalValue)
          : originalValue
      )
      .matches(/^\d+(\.\d+)?$/, "Enter valid per hour wage")
      .when("Designation", {
        is: (designationArray) =>
          designationArray?.length > 0 &&
          [OPERATOR, JUNIOR_OPERATOR, SENIOR_OPERATOR].includes(
            designationArray[0]?.desi_name
          ),
        then: (schema) => schema.required("Per hour wage is required"),
        otherwise: (schema) => schema.notRequired(),
      }),

    per_day_wage: yup
      .string()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === ""
          ? null
          : typeof originalValue === "number"
          ? String(originalValue)
          : originalValue
      )
      .matches(/^\d+(\.\d+)?$/, "Enter valid per day wage")
      .when("Designation", {
        is: (designationArray) =>
          designationArray?.length > 0 &&
          [OPERATOR, JUNIOR_OPERATOR, SENIOR_OPERATOR].includes(
            designationArray[0]?.desi_name
          ),
        then: (schema) => schema.required("Per day wage is required"),
        otherwise: (schema) => schema.notRequired(),
      }),
    per_month_wage: yup
      .string()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === ""
          ? null
          : typeof originalValue === "number"
          ? String(originalValue)
          : originalValue
      )
      .matches(/^\d+(\.\d+)?$/, "Enter valid per month wage")
      .when("Designation", {
        is: (designationArray) =>
          designationArray?.length > 0 &&
          [OPERATOR, JUNIOR_OPERATOR, SENIOR_OPERATOR].includes(
            designationArray[0]?.desi_name
          ),
        then: (schema) => schema.required("Per month wage is required"),
        otherwise: (schema) => schema.notRequired(),
      }),
  })
  .required();

function CreateEmp() {
  let navigate = useNavigate();
  let location = useLocation();
  const [countryList, setCountryList] = useState({ data: [], loading: false });
  const [stateList, setStateList] = useState({ data: [], loading: false });
  const [cityList, setCityList] = useState({ data: [], loading: false });
  const [orgList, setOrgList] = useState({ data: [], loading: false });
  const [desigList, setDesignList] = useState({ data: [], loading: false });
  const [operationList, setOperationList] = useState({
    data: [],
    loading: false,
  });
  const [constLabel, setConstLabel] = useState({});
  const [buttonClick, setButtonClick] = useState(false);
  const [fileList, setFileList] = useState([]);
  let countref = useRef(0);
  const [locList, setLocList] = useState({ data: [], loading: false });
  const [prodFamily, setProdFamily] = useState({ data: [], loading: false });
  const [key, setkey] = useState("General");

  const [special_list, set_special_list] = useState({
      data: [{id: 1, special_name: MENS_WEAR}, {id: 2, special_name: WOMENS_WEAR}],
      loading: false
  });

  const localData = JSON.parse(localStorage.getItem("localData"));
  const config = JSON.parse(localStorage.getItem("configuration"));

  const { register, handleSubmit, formState, control, reset, watch, setValue, setError, getValues } =
    useForm({
      resolver: yupResolver(schema),
      mode: "onChange",
    }); // initialise the hook
  let { errors } = formState;
  const selectedCountry = watch("country");
  const selectedState = watch("state");
  const selectedBranch = watch("branch");
  const selDesignation = watch("Designation");

  function setDefaultValues() {
    // console.log("location",location)
    reset({
      FirstName: location.state.data.FirstName,
      MiddleName: location.state.data.MiddleName,
      LastName: location.state.data.LastName,
      gender:
        location.state.data.gender !== "" && location.state.data.gender !== null
          ? capitalizeFirstLetter(location.state.data.gender)
          : "",
      email:
        location.state.data.Email !== "" && location.state.data.Email !== null
          ? location.state.data.Email
          : "",
      ContactNo:
        location.state.data.ContactNo !== "" &&
        location.state.data.ContactNo !== null
          ? location.state.data.ContactNo
          : "",
      aadhar_no:
        location.state.data.aadhar_no !== "" &&
        location.state.data.aadhar_no !== null
          ? location.state.data.aadhar_no
          : "",
      joining_date:
        location.state.data.joining_date !== null
          ? new Date(location.state.data.joining_date)
          : null,
      // branch:
      //   location.state.data.organisation_id !== null &&
      //   location.state.data.organisation_name !== null
      //     ? [
      //         {
      //           uuid: location.state.data.organisation_id,
      //           comName: location.state.data.organisation_name,
      //         },
      //       ]
      //     : [],
      EmployeeCardId:
        location.state.data.card_id !== "" &&
        location.state.data.card_id !== null
          ? location.state.data.card_id
          : "",
      // Location: location.state.data.Location !== "" && location.state.data.Location !== null ? location.state.data.Location : "",
      Location:
        location.state.data.branch_location_id !== null &&
        location.state.data.Location !== null
          ? [
              {
                uuid: location.state.data.branch_location_id,
                locationname: location.state.data.Location,
              },
            ]
          : [],
      address:
        location.state.data.address !== "" &&
        location.state.data.address !== null
          ? location.state.data.address
          : "",
      Designation:
        location.state.data.Designation !== null
          ? [
              {
                desi_name: location.state.data.Designation,
                desi_id: location.state.data.Designation_id,
              },
            ]
          : [],
      emp_type:
        location.state.data.emp_type !== "" &&
        location.state.data.emp_type !== null
          ? [{ name: location.state.data.emp_type }]
          : [],
      job_type:
        location.state.data.job_type !== "" &&
        location.state.data.job_type !== null
          ? [{ name: location.state.data.job_type }]
          : [],
      country:
        location.state.data.country_id !== "" &&
        location.state.data.country_id !== null
          ? [{ countryName: location.state.data.country_id }]
          : [],
      state:
        location.state.data.state_id !== "" &&
        location.state.data.state_id !== null
          ? [{ stateName: location.state.data.state_id }]
          : [],
      city:
        location.state.data.city_id !== "" &&
        location.state.data.city_id !== null
          ? [{ cityName: location.state.data.city_id }]
          : [],
      operationCategory: location.state.data.operationCategoryDetails
        ? [location.state.data.operationCategoryDetails]
        : [],
      // productFamily: location.state.data.prodfamily
      //   ? [location.state.data.prodfamily]
      //   : [],
      per_hour_wage:
        location.state.data.per_hour_wage !== "" &&
        location.state.data.per_hour_wage !== null
          ? location.state.data.per_hour_wage
          : "",
      per_day_wage:
        location.state.data.per_day_wage !== "" &&
        location.state.data.per_day_wage !== null
          ? location.state.data.per_day_wage
          : "",
      per_month_wage:
        location.state.data.monthly_wage !== "" &&
        location.state.data.monthly_wage !== null
          ? location.state.data.monthly_wage
          : "",
      emp_special:
        location.state.data?.specialisation !== "" &&
        location.state.data?.specialisation !== null
          ? [{special_name: location.state.data?.specialisation}]
          : [],
    });
  }

  useEffect(() => {
    setConstLabel(getLabels("Master", "Employee Master"));

    if (location.state?.action == "update") {
      setDefaultValues();
    } else {
      setValue("gender", "Male");
    }

    fetchOrg();
    fetchDesignationList();
    fetchOperationCategoryList();
    fetchProductFamily();
    setCountryList({ ...countryList, state: true, data: [] });

    get_country().then(
      (res) => {
        setCountryList({
          ...countryList,
          state: false,
          data: res.data.data,
        });
      },
      (err) => {
        setCountryList({ ...countryList, state: false, data: [] });
        toast.error(err.response.data.message, {
          autoClose: 3000,
        });
      }
    );
  }, []);

  useEffect(() => {
    // fetching states for selected country through api
    if (selectedCountry !== undefined && selectedCountry.length !== 0) {
      setStateList({ ...stateList, data: [], loading: true });

      const payload = {
        country_id: selectedCountry[0]?.uuid,
      };
      get_state(payload).then(
        (res) => {
          setStateList({
            ...stateList,
            data: res.data.data,
            loading: false,
          });
        },
        (err) => {
          setStateList({ ...stateList, data: [], loading: false });
          // toast.error("Something went wrong in fetching states !", { autoClose: 5000 })
        }
      );
    }
    if (selectedState !== undefined && selectedState.length > 0) {
      setCityList({ ...cityList, data: [], loading: true });

      const payload = {
        state_id: selectedState[0]?.uuid,
      };

      get_city(payload).then(
        (res) => {
          setCityList({ ...cityList, data: res.data.data, loading: false });
        },
        (err) => {
          setCityList({ ...cityList, data: [], loading: false });
          // toast.error("Something went wrong in fetching cities !", { autoClose: 5000 })
        }
      );
    }
  }, [selectedCountry, selectedState]);

  useEffect(() => {
    // console.log("selectedBranch", selectedBranch);
    if (countref.current === 1) {
      setValue("Location", []);
    }

    if (selectedBranch !== undefined && selectedBranch.length > 0) {
      setLocList({ ...locList, data: [], loading: true });
      getLocation({ company_id: selectedBranch[0].uuid }).then(
        (res) => {
          // console.log("res", res);
          if (res.data.data.length > 0) {
            countref.current = 1;
            setLocList({ ...locList, data: res.data.data, loading: false });

            // setValue("Location", res.data.data[0].locationname, {
            //   shouldValidate: true,
            // });

            // setValue("branch_location_id", res.data.data[0].uuid, {
            //   shouldValidate: true,
            // });
          } else {
            toast.error("Location not found for the selected branch !", {
              autoClose: 3000,
            });
          }
        },
        (err) => {
          setLocList({ ...locList, data: [], loading: false });
          toast.error(err.response.data.message, {
            autoClose: 3000,
          });
        }
      );

      // Wage recalculations when branch changes
      const perDayHours = selectedBranch[0]?.org_configuration?.per_day_hours || config?.per_day_hours || 8;
      const perMonthDays = selectedBranch[0]?.org_configuration?.per_month_days || config?.per_month_days || 30;

      const perHourWage = getValues("per_hour_wage");
      const perDayWage = getValues("per_day_wage");
      const perMonthWage = getValues("per_month_wage");

      if (perHourWage) {
          setValue("per_day_wage", perHourWage * perDayHours, { shouldValidate: true });
          setValue("per_month_wage", perHourWage * perDayHours * perMonthDays, { shouldValidate: true });
      } else if (perDayWage) {
          setValue("per_hour_wage", parseFloat((perDayWage / perDayHours).toFixed(2)), { shouldValidate: true });
          setValue("per_month_wage", perDayWage * perMonthDays, { shouldValidate: true });
      } else if (perMonthWage) {
          setValue("per_day_wage", parseFloat((perMonthWage / perMonthDays).toFixed(2)), { shouldValidate: true });
          setValue("per_hour_wage", parseFloat(((perMonthWage / perMonthDays) / perDayHours).toFixed(2)), { shouldValidate: true });
      }
    }
  }, [selectedBranch]);

  function fetchDesignationList() {
    setDesignList({ ...desigList, data: [], loading: true });

    getDesignationApi().then(
      (res) => {
        setDesignList({ ...desigList, data: res.data.data, loading: false });
      },
      (err) => {
        setDesignList({ ...desigList, data: [], loading: false });
      }
    );
  }

  function fetchOperationCategoryList() {
    setOperationList({ ...operationList, data: [], loading: true });

    getOperationCat().then(
      (res) => {
        setOperationList({
          ...operationList,
          data: res.data.data,
          loading: false,
        });
      },
      (err) => {
        setOperationList({ ...operationList, data: [], loading: false });
      }
    );
  }

  function fetchOrg() {
    setOrgList({ ...orgList, loading: true, data: [] });

    getInternalCompany().then(
      (res) => {
        if (location.state?.action !== UPDATE.toLowerCase()) {
          const result = localData?.organizations?.filter(
            (item) => item.default_org == true
          );
          setValue("branch", result);
        }

        if (location.state?.action === UPDATE.toLowerCase()) {
          const result2 = res.data.data?.filter(
            (item) => item.uuid == location.state?.data?.organisation_id
          );
          setValue("branch", result2);
        }

        setOrgList({ ...orgList, loading: false, data: res.data.data });
      },
      (err) => {
        setOrgList({ ...orgList, loading: false, data: [] });
      }
    );
  }

  function fetchProductFamily() {
    setProdFamily({ ...prodFamily, data: [], loading: true });
    getProduct_Family().then(
      (res) => {
        setProdFamily({
          ...prodFamily,
          data: res.data.data,
          loading: false,
        });

        if (location.state?.action == "update") {
          let matchedObjects = location.state?.data?.emp_family.map((emp) =>
            res.data.data.find((res) => res.uuid === emp.prod_family_id)
          );
          console.log("matchedObjects", matchedObjects);
          setValue("productFamily", matchedObjects);
        } else {
          setValue("productFamily", []);
        }
      },
      (err) => {
        setProdFamily({ ...prodFamily, data: [], loading: false });

      }
    );
  }

  const handleWageInput = (e, type) => {
    const value = parseFloat(e.target.value);
    // const perDayHours = selectedBranch[0]?.org_configuration !== null ? selectedBranch[0]?.org_configuration?.per_day_hours : config?.per_day_hours; // hours per day, e.g., 8 ( config?.per_day_hours || 8 )
    // const perMonthDays = selectedBranch[0]?.org_configuration !== null ? selectedBranch[0]?.org_configuration?.per_month_days : config?.per_month_days; // per month days, e.g., 30 ( config?.per_day_hours || 30 )

    // Default values in case selectedBranch is empty
    const perDayHours = selectedBranch.length > 0 && selectedBranch[0]?.org_configuration !== null
        ? selectedBranch[0]?.org_configuration?.per_day_hours
        : config?.per_day_hours || 8; // Default to 8 hours per day

    const perMonthDays = selectedBranch.length > 0 && selectedBranch[0]?.org_configuration !== null
        ? selectedBranch[0]?.org_configuration?.per_month_days
        : config?.per_month_days || 30; // Default to 30 days per month

    // Check if input is empty, non-numeric, or less than 0
    if (e.target.value === "" || isNaN(value) || value < 0) {
      if (type === PER_HOUR_WAGE) {
        setValue("per_day_wage", "");
        setValue("per_month_wage", "");
      } else if (type === PER_DAY_WAGE) {
        setValue("per_hour_wage", "");
        setValue("per_month_wage", "");
      } else if (type === PER_MONTH_WAGE) {
        setValue("per_day_wage", "");
        setValue("per_hour_wage", "");
      }
      return;
    }

    // Handle case when the value is 0
    if (value === 0) {
      setValue("per_hour_wage", 0, { shouldValidate: true });
      setValue("per_day_wage", 0, { shouldValidate: true });
      setValue("per_month_wage", 0, { shouldValidate: true });
      return;
    }

    // Compute and set values based on input type
    if (type === PER_HOUR_WAGE) {
      const perDayWage = value * perDayHours;
      const perMonthWage = perDayWage * perMonthDays;

      setValue("per_hour_wage", value, { shouldValidate: true });
      setValue("per_day_wage", perDayWage, { shouldValidate: true });
      setValue("per_month_wage", perMonthWage, { shouldValidate: true });
    } else if (type === PER_DAY_WAGE) {
      const perHourWage = parseFloat((value / perDayHours).toFixed(2));
      const perMonthWage = value * perMonthDays;

      setValue("per_day_wage", value, { shouldValidate: true });
      setValue("per_hour_wage", perHourWage, { shouldValidate: true });
      setValue("per_month_wage", perMonthWage, { shouldValidate: true });
    } else if (type === PER_MONTH_WAGE) {
      const  perDayWage=parseFloat((value / perMonthDays).toFixed(2));
      const perHourWage=parseFloat((perDayWage / perDayHours).toFixed(2));
      setValue("per_month_wage", value, { shouldValidate: true });
      setValue("per_day_wage", perDayWage, { shouldValidate: true });
      setValue("per_hour_wage", perHourWage, { shouldValidate: true });
    }
  };

  const onSubmit = (data) => {
    data.joining_date.setHours(new Date().getHours());
    data.joining_date.setMinutes(new Date().getMinutes());
    data.joining_date.setSeconds(new Date().getSeconds());

    setButtonClick(true);

    // console.log("data", data);

    if (data !== "") {
      const payload = {
        // branch_location_id: data.branch_location_id,
        branch_location_id: data.Location?.[0]?.uuid,
        organisation_id: data.branch[0]?.uuid,
        organisation_name: data.branch[0]?.comName,
        card_id: data.EmployeeCardId,
        FirstName: data.FirstName,
        MiddleName: data.MiddleName,
        LastName: data.LastName,
        // Location: data.Location,
        Location: data.Location?.[0]?.locationname,
        Email: data.email,
        Designation: data.Designation[0]?.desi_name,
        Designation_id: data.Designation[0]?.desi_id,
        ContactNo: data.ContactNo,
        country_id: data.country[0].countryName,
        state_id: data.state[0]?.stateName,
        city_id: data.city[0]?.cityName,
        address: data.address,
        gender: data.gender,
        aadhar_no: data.aadhar_no,
        joining_date: new Date(data.joining_date),
        emp_type: data.emp_type[0]?.name,
        job_type: data.job_type[0]?.name,
        operation_category:
          data.operationCategory && data.operationCategory?.length > 0
            ? data.operationCategory[0]?.uuid
            : null,
        // product_family_id:
        //   data.productFamily && data.productFamily?.length > 0
        //     ? data.productFamily[0]?.uuid
        //     : null,
        //
        per_hour_wage:
          data.per_hour_wage !== "" && data.per_hour_wage !== null
            ? data.per_hour_wage
            : null,
        per_day_wage:
          data.per_day_wage !== "" && data.per_day_wage !== null
            ? data.per_day_wage
            : null,
        monthly_wage:
          data.per_month_wage !== "" && data.per_month_wage !== null
            ? data.per_month_wage
            : null,
        specialisation: data.emp_special?.length > 0 ? data.emp_special[0]?.special_name : null
      };

      if (data.productFamily?.length > 0) {
        let arr = data.productFamily.map((item) => {
          return `${item.uuid}`;
        });
        payload.product_family_id = arr;
      }

      if (location.state?.action == "update") {
        payload.uuid = location.state?.data.uuid;

        updateEmpApi(payload).then(
          (res) => {
            toast.success("Employee updated successfully", {
              autoClose: 2000,
            });

            setTimeout(() => {
              setButtonClick(false);
              navigate("/pcms/employee_list");
            }, [2200]);
          },
          (err) => {
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
            setButtonClick(false);
          }
        );
      } else {
        createEmpApi(payload).then(
          async (res) => {
            toast.success("Employee created successfully", {
              autoClose: 2000,
            });

            if (fileList.length > 0) {
              const imgArr = [];

              for (let a = 0; a < fileList.length; a++) {
                const getExt = fileList[a].file.name?.split(".").pop();

                console.log("get", getExt);
                const dataimage = await convertBase64(fileList[a].file);
                const newImage = dataimage.replace(
                  /data:image\/[a-zA-Z]+;/,
                  "data:image/" + getExt + ";"
                );
                imgArr.push({
                  document: newImage,
                  document_type: imagefileTypes.includes(fileList[a].image.type)
                    ? "image"
                    : "document",
                  document_name: fileList[a].filename + `.${getExt}`,
                });
              }

              const payload = {
                model_name: "Employee",
                ref_model_id: res.data?.empid,
                ref_model_uuid: res.data.uuid,
                files: imgArr,
              };

              master_multi_file_upload(payload).then(
                (res) => {
                  toast.success("Employee documents uploaded successfully", {
                    autoClose: 2000,
                  });
                  setTimeout(() => {
                    setButtonClick(false);
                    navigate("/pcms/employee_list");
                  }, [2000]);
                },
                (err) => {
                  setButtonClick(false);
                  navigate("/pcms/employee_list");
                  if (err.response == undefined) {
                    toast.error("Something went wrong uploading images", {
                      autoClose: 2000,
                    });
                  } else {
                    toast.error(err?.response?.data?.message, {
                      autoClose: 2000,
                    });
                  }
                }
              );
            } else {
              setTimeout(() => {
                setButtonClick(false);
                navigate("/pcms/employee_list");
              }, [2200]);
            }
          },
          (err) => {
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
            setButtonClick(false);
          }
        );
      }
    } else {
      errors.showMessages();
    }
  };

  return (
    <React.Fragment>
      <div className="bom-creation">
        <ToastContainer />

        <div className="row mb-3">
          <div className="col-12">
            <div className="card border-0">
              <div className="card-header bg-grey d-flex align-items-center">
                <button
                  className="btn btn-yellow"
                  onClick={() => {
                    if (location.state?.action == "update") {
                      navigate("/pcms/employee_list");
                    } else {
                      if (
                        window.confirm(
                          "Are you sure to go back, your present data will be lost !"
                        )
                      ) {
                        window.onpopstate = undefined;
                        window.history.back();

                        setTimeout(() => {
                          navigate("/pcms/employee_list");
                        }, [1000]);
                      }
                    }
                  }}
                >
                  <FontAwesomeIcon icon={faArrowLeft} size="sm" />
                </button>

                <p className="mb-0 ms-3" style={{ fontSize: "24px" }}>
                  {location.state?.action == "update" ? (
                    <>
                      {constLabel?.lbl_update
                        ? constLabel.lbl_update
                        : "Update Employee ~"}
                    </>
                  ) : (
                    <>
                      {constLabel?.lbl_create
                        ? constLabel.lbl_create
                        : "Create Employee ~"}
                    </>
                  )}
                </p>
              </div>

              <div className="card-body p-4 ">
                <div id="emp_tab_master">
                  <Tabs
                    variant="pills"
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => {
                      setkey(k);
                    }}
                    // className="mb-3"
                  >
                    <Tab
                      eventKey="General"
                      title={
                        <span className="mx-2 roboto-bold">{"General"}</span>
                      }
                    >
                      <div className="row p-4">
                        <div className="col-12 col-md-3">
                          <div className="form-group row">
                            <label
                              style={{ fontSize: "14px" }}
                              className="col-form-label col-md-12 roboto-bold"
                            >
                              {constLabel?.lbl_firstname
                                ? constLabel.lbl_firstname
                                : "First Name ~"}
                              <span className="text-danger">*</span>
                            </label>

                            <div className="col-md-12">
                              <input
                                type="text"
                                className={`form-control ${
                                  errors.FirstName ? "is-invalid" : null
                                }`}
                                placeholder="Enter first name"
                                {...register("FirstName")}
                              />

                              <span className="text-danger err-msg">
                                {errors.FirstName?.message}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="col-12 col-md-3">
                          <div className="form-group row">
                            <label
                              style={{ fontSize: "14px" }}
                              className="col-form-label col-md-12 roboto-bold"
                            >
                              {constLabel?.lbl_middlename
                                ? constLabel.lbl_middlename
                                : "Middle Name ~"}
                              {/* <span className="text-danger">*</span> */}
                            </label>

                            <div className="col-md-12">
                              <input
                                type="text"
                                className={`form-control ${
                                  errors.MiddleName ? "is-invalid" : null
                                }`}
                                placeholder="Enter middle name"
                                {...register("MiddleName")}
                              />

                              <span className="text-danger err-msg">
                                {errors.MiddleName?.message}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="col-12 col-md-3">
                          <div className="form-group row">
                            <label
                              style={{ fontSize: "14px" }}
                              className="col-form-label col-md-12 roboto-bold"
                            >
                              {constLabel?.lbl_lastname
                                ? constLabel.lbl_lastname
                                : "Last Name ~"}
                              <span className="text-danger"> *</span>
                            </label>

                            <div className="col-md-12">
                              <input
                                type="text"
                                className={`form-control ${
                                  errors.LastName ? "is-invalid" : null
                                }`}
                                placeholder="Enter last name"
                                {...register("LastName")}
                              />

                              <span className="text-danger err-msg">
                                {errors.LastName?.message}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="col-12 col-md-3 mt-2">
                          <div className="form-group row">
                            <label
                              style={{ fontSize: "14px" }}
                              className="col-form-label col-md-12 roboto-bold"
                            >
                              {constLabel?.lbl_gender
                                ? constLabel.lbl_gender
                                : "Gender ~"}
                              <span className="text-danger"> *</span>
                            </label>

                            <div className="col-md-12">
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="inlineRadioOptions"
                                  id="maleRadio"
                                  {...register("gender")}
                                  // defaultChecked
                                  value="Male"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="maleRadio"
                                >
                                  Male
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="inlineRadioOptions"
                                  id="femaleRadio"
                                  {...register("gender")}
                                  value="Female"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="femaleRadio"
                                >
                                  Female
                                </label>
                              </div>
                              <span className="text-danger err-msg">
                                {errors.gender?.message}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="col-12 col-md-3 mt-2">
                          <div className="form-group row">
                            <label
                              style={{ fontSize: "14px" }}
                              className="col-form-label col-md-12 roboto-bold"
                            >
                              {constLabel?.lbl_email
                                ? constLabel.lbl_email
                                : "Email ~"}
                            </label>

                            <div className="col-md-12">
                              <input
                                type="text"
                                className={`form-control ${
                                  errors.email ? "is-invalid" : null
                                }`}
                                placeholder="Enter email"
                                {...register("email")}
                              />

                              <span className="text-danger err-msg">
                                {errors.email?.message}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="col-12 col-md-3 mt-2">
                          <div className="form-group typeahead-container">
                            <label
                              style={{ fontSize: "14px" }}
                              className="col-form-label roboto-bold"
                            >
                              {constLabel?.lbl_Designation
                                ? constLabel.lbl_Designation
                                : "Designation ~"}
                              <span className="text-danger"> *</span>
                            </label>

                            <Controller
                              name="Designation"
                              control={control}
                              render={({
                                field: { onChange, onBlur, value, ref },
                              }) => (
                                <Typeahead
                                  id="basic-typeahead-single"
                                  labelKey="desi_name"
                                  multiple={false}
                                  options={desigList.data}
                                  isLoading={desigList.loading}
                                  placeholder="Select designation"
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  selected={value}
                                  isInvalid={errors.Designation ? true : false}
                                />
                              )}
                            />
                            {!desigList.loading && !errors.Designation && (
                              <span className="arrow-icon"></span>
                            )}
                            <span className="text-danger err-msg">
                              {errors.Designation?.message}
                            </span>
                          </div>
                        </div>

                        <div className="col-12 col-md-3 mt-2">
                          <div className="form-group row">
                            <label
                              style={{ fontSize: "14px" }}
                              className="col-form-label col-md-12 roboto-bold"
                            >
                              {constLabel?.lbl_contactno
                                ? constLabel.lbl_contactno
                                : "Contact No ~"}
                              <span className="text-danger"> *</span>
                            </label>

                            <div className="col-md-12">
                              <input
                                type="text"
                                className={`form-control ${
                                  errors.ContactNo ? "is-invalid" : null
                                }`}
                                placeholder="Enter contact no"
                                maxLength={10}
                                {...register("ContactNo")}
                                rows={"1"}
                              />

                              <span className="text-danger err-msg">
                                {errors.ContactNo?.message}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="col-12 col-md-3 mt-2">
                          <div className="form-group row">
                            <label
                              style={{ fontSize: "14px" }}
                              className="col-form-label col-md-12 roboto-bold"
                            >
                              {constLabel?.lbl_aadharno
                                ? constLabel.lbl_aadharno
                                : "Aadhar number ~"}
                            </label>

                            <div className="col-md-12">
                              <input
                                type="text"
                                className={`form-control ${
                                  errors.aadhar_no ? "is-invalid" : null
                                }`}
                                placeholder="Enter aadhar number"
                                {...register("aadhar_no")}
                              />

                              <span className="text-danger err-msg">
                                {errors.aadhar_no?.message}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="col-12 col-md-3 mt-2">
                          <div className="form-group row">
                            <label
                              style={{ fontSize: "14px" }}
                              className="col-form-label col-md-12 roboto-bold"
                            >
                              {constLabel?.lbl_joining_date
                                ? constLabel.lbl_joining_date
                                : "Joining Date ~"}
                              <span className="text-danger"> *</span>
                            </label>

                            <div className="col-md-12">
                              <Controller
                                name={`joining_date`}
                                control={control}
                                render={({ field }) => (
                                  <DatePicker
                                    className={
                                      errors.joining_date
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    {...field}
                                    showYearDropdown
                                    scrollableMonthYearDropdown
                                    dateFormat="dd/MM/yyyy"
                                    selected={field.value}
                                    maxDate={new Date()}
                                    placeholderText="Select a joining date"
                                    autoComplete="off"
                                  />
                                )}
                              />

                              <span className="text-danger err-msg">
                                {errors.joining_date?.message}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="col-12 col-md-3 mt-2">
                          <div className="form-group typeahead-container">
                            <label
                              style={{ fontSize: "14px" }}
                              className="col-form-label roboto-bold"
                            >
                              {constLabel?.lbl_organization
                                ? constLabel.lbl_organization
                                : "Select Organization ~"}
                              <span className="text-danger"> *</span>
                            </label>

                            <Controller
                              name="branch"
                              control={control}
                              render={({
                                field: { onChange, onBlur, value, ref },
                              }) => (
                                <Typeahead
                                  id="basic-typeahead-single"
                                  labelKey="comName"
                                  multiple={false}
                                  options={orgList.data}
                                  isLoading={orgList.loading}
                                  placeholder="Select organization"
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  selected={value}
                                  isInvalid={errors.branch ? true : false}
                                />
                              )}
                            />
                            {!orgList.loading && !errors.branch && (
                              <span className="arrow-icon"></span>
                            )}
                            <span className="text-danger err-msg">
                              {errors.branch?.message}
                            </span>
                          </div>
                        </div>

                        <div className="col-12 col-md-3 mt-2">
                          <div className="form-group typeahead-container">
                            <label
                              style={{ fontSize: "14px" }}
                              className="col-form-label roboto-bold"
                            >
                              {constLabel?.lbl_location
                                ? constLabel.lbl_location
                                : "Location ~"}
                              <span className="text-danger"> *</span>
                            </label>

                            <Controller
                              name="Location"
                              control={control}
                              render={({
                                field: { onChange, onBlur, value, ref },
                              }) => (
                                <Typeahead
                                  id="basic-typeahead-single"
                                  labelKey="locationname"
                                  multiple={false}
                                  options={locList.data}
                                  isLoading={locList.loading}
                                  placeholder="Select location"
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  selected={value}
                                  isInvalid={errors.Location ? true : false}
                                />
                              )}
                            />
                            {!locList.loading && !errors.Location && (
                              <span className="arrow-icon"></span>
                            )}
                            <span className="text-danger err-msg">
                              {errors.Location?.message}
                            </span>
                          </div>
                        </div>

                        <div className="col-12 col-md-3 mt-2">
                          <div className="form-group row">
                            <label
                              style={{ fontSize: "14px" }}
                              className="col-form-label col-md-12 roboto-bold"
                            >
                              {constLabel?.lbl_emp_card_id
                                ? constLabel.lbl_emp_card_id
                                : "Employee Card Id"}
                              <span className="text-danger"> *</span>
                            </label>

                            <div className="col-md-12">
                              <input
                                type="text"
                                className={`form-control ${
                                  errors.EmployeeCardId ? "is-invalid" : null
                                }`}
                                placeholder="Enter employee card id"
                                {...register("EmployeeCardId")}
                              />

                              <span className="text-danger err-msg">
                                {errors.EmployeeCardId?.message}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="col-12 col-md-3 mt-2">
                          <div className="form-group typeahead-container">
                            <label
                              style={{ fontSize: "14px" }}
                              className="col-form-label col-md-12 roboto-bold"
                            >
                              {constLabel?.employee_type
                                ? constLabel.employee_type
                                : "Employee Type ~"}
                              <span className="text-danger"> *</span>
                            </label>
                            <div className="col-md-12">
                              <Controller
                                name="emp_type"
                                control={control}
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                }) => (
                                  <Typeahead
                                    id="basic-typeahead-single"
                                    labelKey="name"
                                    multiple={false}
                                    options={[
                                      { name: "Office Employee" },
                                      { name: "Worker" },
                                      { name: "Contractor" },
                                    ]}
                                    isLoading={false}
                                    placeholder="Select employee type"
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    selected={value}
                                    isInvalid={errors.emp_type ? true : false}
                                  />
                                )}
                              />
                              {!errors.emp_type && (
                                <span className="arrow-icon"></span>
                              )}
                              <span className="text-danger err-msg">
                                {errors.emp_type?.message}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="col-12 col-md-3 mt-2">
                          <div className="form-group typeahead-container">
                            <label
                              style={{ fontSize: "14px" }}
                              className="col-form-label roboto-bold"
                            >
                              {constLabel?.lbl_job_type
                                ? constLabel.lbl_job_type
                                : "Job Type ~"}
                              <span className="text-danger"> *</span>
                            </label>

                            <Controller
                              name="job_type"
                              control={control}
                              render={({
                                field: { onChange, onBlur, value, ref },
                              }) => (
                                <Typeahead
                                  id="basic-typeahead-single"
                                  labelKey="name"
                                  multiple={false}
                                  options={[
                                    { name: "Probation" },
                                    { name: "Permanent" },
                                    { name: "Contract" },
                                  ]}
                                  isLoading={false}
                                  placeholder="Select job type"
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  selected={value}
                                  isInvalid={errors.job_type ? true : false}
                                />
                              )}
                            />
                            {!errors.job_type && (
                              <span className="arrow-icon"></span>
                            )}
                            <span className="text-danger err-msg">
                              {errors.job_type?.message}
                            </span>
                          </div>
                        </div>

                        <div className="col-12 col-md-3 mt-2">
                          <div className="form-group typeahead-container">
                            <label
                              style={{ fontSize: "14px" }}
                              className="col-form-label roboto-bold"
                            >
                              {constLabel?.lbl_country
                                ? constLabel.lbl_country
                                : "Country ~"}
                              <span className="text-danger"> *</span>
                            </label>

                            <Controller
                              name="country"
                              control={control}
                              render={({
                                field: { onChange, onBlur, value, ref },
                              }) => (
                                <Typeahead
                                  id="basic-typeahead-single"
                                  labelKey="countryName"
                                  multiple={false}
                                  options={countryList.data}
                                  isLoading={countryList.loading}
                                  placeholder="Select country"
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  selected={value}
                                  isInvalid={errors.country ? true : false}
                                />
                              )}
                            />
                            {!countryList.loading && !errors.country && (
                              <span className="arrow-icon"></span>
                            )}
                            <span className="text-danger err-msg">
                              {errors.country?.message}
                            </span>
                          </div>
                        </div>

                        <div className="col-12 col-md-3 mt-2">
                          <div className="form-group typeahead-container">
                            <label
                              style={{ fontSize: "14px" }}
                              className="col-form-label roboto-bold"
                            >
                              {constLabel?.lbl_state
                                ? constLabel.lbl_state
                                : "State ~"}
                              <span className="text-danger"> *</span>
                            </label>

                            <Controller
                              name="state"
                              control={control}
                              render={({
                                field: { onChange, onBlur, value, ref },
                              }) => (
                                <Typeahead
                                  id="basic-typeahead-single"
                                  labelKey="stateName"
                                  multiple={false}
                                  options={stateList.data}
                                  isLoading={stateList.loading}
                                  placeholder="Select state"
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  selected={value}
                                  isInvalid={errors.state ? true : false}
                                />
                              )}
                            />
                            {!stateList.loading && !errors.state && (
                              <span className="arrow-icon"></span>
                            )}
                            <span className="text-danger err-msg">
                              {errors.state?.message}
                            </span>
                          </div>
                        </div>

                        <div className="col-12 col-md-3 mt-2">
                          <div className="form-group typeahead-container">
                            <label
                              style={{ fontSize: "14px" }}
                              className="col-form-label roboto-bold"
                            >
                              {constLabel?.lbl_city
                                ? constLabel.lbl_city
                                : "City ~"}
                              <span className="text-danger"> *</span>
                            </label>

                            <Controller
                              name="city"
                              control={control}
                              render={({
                                field: { onChange, onBlur, value, ref },
                              }) => (
                                <Typeahead
                                  id="basic-typeahead-single"
                                  labelKey="cityName"
                                  multiple={false}
                                  options={cityList.data}
                                  isLoading={cityList.loading}
                                  placeholder="Select city"
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  selected={value}
                                  isInvalid={errors.city ? true : false}
                                />
                              )}
                            />
                            {!cityList.loading && !errors.city && (
                              <span className="arrow-icon"></span>
                            )}
                            <span className="text-danger err-msg">
                              {errors.city?.message}
                            </span>
                          </div>
                        </div>

                        <div className="col-12 col-md-3 mt-2">
                          <div className="form-group typeahead-container">
                            <label
                              style={{ fontSize: "14px" }}
                              className="col-form-label roboto-bold"
                            >
                              {constLabel?.lbl_operation_category
                                ? constLabel.lbl_operation_category
                                : "Operation Category"}
                              {/* <span className="text-danger">*</span> */}
                            </label>

                            <Controller
                              name="operationCategory"
                              control={control}
                              render={({
                                field: { onChange, onBlur, value, ref },
                              }) => (
                                <Typeahead
                                  id="basic-typeahead-single"
                                  labelKey="oprationCategoryName"
                                  multiple={false}
                                  options={operationList.data}
                                  isLoading={operationList.loading}
                                  placeholder="Select operation category"
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  selected={value}
                                />
                              )}
                            />
                            {!operationList.loading && (
                              <span className="arrow-icon"></span>
                            )}
                          </div>
                        </div>

                        <div className="col-12 col-md-3 mt-2">
                          <div className="form-group row">
                            <label
                              style={{ fontSize: "14px" }}
                              className="col-form-label col-md-12 roboto-bold"
                            >
                              {constLabel?.lbl_address
                                ? constLabel.lbl_address
                                : "Address ~"}
                              <span className="text-danger"> *</span>
                            </label>

                            <div className="col-md-12">
                              <textarea
                                type="text"
                                className={`form-control ${
                                  errors.address ? "is-invalid" : null
                                }`}
                                placeholder="Enter address"
                                {...register("address")}
                                rows={"1"}
                              ></textarea>

                              <span className="text-danger err-msg">
                                {errors.address?.message}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="col-12 col-md-3 mt-2">
                          <div className="form-group typeahead-container">
                            <label
                              style={{ fontSize: "14px" }}
                              className="col-form-label roboto-bold"
                            >
                              {constLabel?.lbl_product_family
                                ? constLabel.lbl_product_family
                                : "Product Family ~"}
                            </label>

                            <Controller
                              name="productFamily"
                              control={control}
                              render={({
                                field: { onChange, onBlur, value, ref },
                              }) => (
                                <Typeahead
                                  id="basic-typeahead-single"
                                  labelKey="familyname"
                                  multiple={true}
                                  options={prodFamily.data}
                                  isLoading={prodFamily.loading}
                                  placeholder="Select product family"
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  selected={value}
                                  isInvalid={
                                    errors.productFamily ? true : false
                                  }
                                />
                              )}
                            />
                            {!prodFamily.loading && (
                              <span className="arrow-icon"></span>
                            )}
                          </div>
                        </div>

                        <div className="col-12 col-md-3 mt-2">
                          <div className="form-group row">
                            <label
                              style={{ fontSize: "14px" }}
                              className="col-form-label col-md-12 roboto-bold"
                            >
                              {constLabel?.lbl_per_hour_wage
                                ? constLabel.lbl_per_hour_wage
                                : "Per Hour Wage"}
                              {selDesignation &&
                              selDesignation?.length > 0 &&
                              [
                                OPERATOR,
                                JUNIOR_OPERATOR,
                                SENIOR_OPERATOR,
                              ].includes(selDesignation?.[0]?.desi_name) ? (
                                <span className="text-danger"> *</span>
                              ) : null}
                            </label>

                            <div className="col-md-12">
                              <input
                                type="text"
                                id="per_hour_wage"
                                name="per_hour_wage"
                                className={
                                  errors.per_hour_wage &&
                                  selDesignation &&
                                  selDesignation?.length > 0 &&
                                  [
                                    OPERATOR,
                                    JUNIOR_OPERATOR,
                                    SENIOR_OPERATOR,
                                  ].includes(selDesignation?.[0]?.desi_name)
                                    ? "form-control is-invalid"
                                    : "form-control"
                                }
                                {...register("per_hour_wage")}
                                onChange={(e) =>
                                  handleWageInput(e, PER_HOUR_WAGE)
                                }
                                placeholder="Enter per hour wage"
                              />

                              {errors.per_hour_wage &&
                                selDesignation &&
                                selDesignation?.length > 0 &&
                                [
                                  OPERATOR,
                                  JUNIOR_OPERATOR,
                                  SENIOR_OPERATOR,
                                ].includes(selDesignation?.[0]?.desi_name) && (
                                  <span className="text-danger err-msg">
                                    {errors.per_hour_wage?.message}
                                  </span>
                                )}
                            </div>
                          </div>
                        </div>

                        <div className="col-12 col-md-3 mt-2">
                          <div className="form-group row">
                            <label
                              style={{ fontSize: "14px" }}
                              className="col-form-label col-md-12 roboto-bold"
                            >
                              {constLabel?.lbl_per_day_wage
                                ? constLabel.lbl_per_day_wage
                                : "Per Day Wage"}
                              {selDesignation &&
                              selDesignation?.length > 0 &&
                              [
                                OPERATOR,
                                JUNIOR_OPERATOR,
                                SENIOR_OPERATOR,
                              ].includes(selDesignation?.[0]?.desi_name) ? (
                                <span className="text-danger"> *</span>
                              ) : null}
                            </label>

                            <div className="col-md-12">
                              <input
                                type="text"
                                id="per_day_wage"
                                name="per_day_wage"
                                className={
                                  errors.per_day_wage &&
                                  selDesignation &&
                                  selDesignation?.length > 0 &&
                                  [
                                    OPERATOR,
                                    JUNIOR_OPERATOR,
                                    SENIOR_OPERATOR,
                                  ].includes(selDesignation?.[0]?.desi_name)
                                    ? "form-control is-invalid"
                                    : "form-control"
                                }
                                {...register("per_day_wage")}
                                onChange={(e) =>
                                  handleWageInput(e, PER_DAY_WAGE)
                                }
                                placeholder="Enter per day wage"
                              />

                              {errors.per_day_wage &&
                                selDesignation &&
                                selDesignation?.length > 0 &&
                                [
                                  OPERATOR,
                                  JUNIOR_OPERATOR,
                                  SENIOR_OPERATOR,
                                ].includes(selDesignation?.[0]?.desi_name) && (
                                  <span className="text-danger err-msg">
                                    {errors.per_day_wage?.message}
                                  </span>
                                )}
                            </div>
                          </div>
                        </div>

                        <div className="col-12 col-md-3 mt-2">
                          <div className="form-group row">
                            <label
                              style={{ fontSize: "14px" }}
                              className="col-form-label col-md-12 roboto-bold"
                            >
                              {constLabel?.lbl_per_monthly_wage
                                ? constLabel.lbl_per_monthly_wage
                                : "Monthly Wage"}
                              {selDesignation &&
                              selDesignation?.length > 0 &&
                              [
                                OPERATOR,
                                JUNIOR_OPERATOR,
                                SENIOR_OPERATOR,
                              ].includes(selDesignation?.[0]?.desi_name) ? (
                                <span className="text-danger"> *</span>
                              ) : null}
                            </label>

                            <div className="col-md-12">
                              <input
                                type="text"
                                id="per_month_wage"
                                name="per_month_wage"
                                className={
                                  errors.per_month_wage &&
                                  selDesignation &&
                                  selDesignation?.length > 0 &&
                                  [
                                    OPERATOR,
                                    JUNIOR_OPERATOR,
                                    SENIOR_OPERATOR,
                                  ].includes(selDesignation?.[0]?.desi_name)
                                    ? "form-control is-invalid"
                                    : "form-control"
                                }
                                {...register("per_month_wage")}
                                onChange={(e) =>
                                  handleWageInput(e, PER_MONTH_WAGE)
                                }
                                placeholder="Enter per month wage"
                              />

                              {errors.per_month_wage &&
                                selDesignation &&
                                selDesignation?.length > 0 &&
                                [
                                  OPERATOR,
                                  JUNIOR_OPERATOR,
                                  SENIOR_OPERATOR,
                                ].includes(selDesignation?.[0]?.desi_name) && (
                                  <span className="text-danger err-msg">
                                    {errors.per_month_wage?.message}
                                  </span>
                                )}
                            </div>
                          </div>
                        </div>
                      
                      {localData.industry_type == FASHION || localData.industry_type == APPAREL ?
                        <div className="col-12 col-md-3 mt-2">
                          <div className="form-group typeahead-container">
                            <label
                              style={{ fontSize: "14px" }}
                              className="col-form-label roboto-bold"
                            >
                              {constLabel?.lbl_specialization
                                ? constLabel.lbl_specialization
                                : "Specialization"}
                            </label>

                            <Controller
                              name="emp_special"
                              control={control}
                              render={({
                                field: { onChange, onBlur, value, ref },
                              }) => (
                                <Typeahead
                                  id="basic-typeahead-single"
                                  labelKey="special_name"
                                  multiple={false}
                                  options={special_list.data}
                                  isLoading={special_list.loading}
                                  placeholder="Select specialization"
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  selected={value}
                                  filterBy={() => true}
                                  // isInvalid = {[FASHION, APPAREL].includes(localData?.industry_type) ? !!errors.emp_special : null}
                                />
                              )}
                            />
                            {!special_list.loading && (<span className="arrow-icon"></span>)}
                            {/* {[FASHION, APPAREL].includes(localData?.industry_type) && errors.emp_special && (
                              <span className="text-danger err-msg">
                                {errors.emp_special.message}
                              </span>
                            )} */}
                          </div>
                        </div>
                      : null }

                        <div className="col-12 mt-4">
                          <button
                            className="btn btn-yellow px-3 float-end"
                            disabled={buttonClick}
                            onClick={handleSubmit(onSubmit)}
                          >
                            {location.state?.action == "update"
                              ? constLabel?.lbl_save_changes
                                ? constLabel.lbl_save_changes
                                : "Save Changes"
                              : constLabel?.lbl_save
                              ? constLabel.lbl_save
                              : "Save"}
                          </button>
                        </div>
                      </div>
                    </Tab>
                    <Tab
                      eventKey="Upload Document"
                      title={
                        <span className="mx-2 roboto-bold">
                          {"Upload Document"}
                        </span>
                      }
                    >
                      <EmployeeGallery
                        constLabel={constLabel}
                        setFileList={setFileList}
                        fileList={fileList}
                      />
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default React.memo(CreateEmp);
