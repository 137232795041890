import React, { useState, useEffect } from "react";
import { getPO, getSupplier } from "../../../api/index";
import SupplierPOList from "./SupplierPOList";
import SupplierQuoteList from "./SupplierQuoteList";
import CreatePO from "./Forms/CreatePO";
import CreateQuote from "./Forms/CreateQuote";
import {
  convertDateYYMMDD,
  getLabels,
  setAccessPrivilage,
} from "../../../common/Functions/CommonFunctions";
import { Nav, Tab, Tabs } from "react-bootstrap";
import { faCircleInfo, faList } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ContactDetailSUPP from "./ContactDetailSUPP";
import SupplierOverview from "./SupplierOverview";
import Address from "./Address";
import { useSelector } from "react-redux";
import Slide from "../../../assets/images/icons/project_menu/collapse.png";
import { Tooltip } from "react-tooltip";

function Suppliers360() {
  function getPrvMonthDateFromToday() {
    const today = new Date();
    const endDate = new Date(today);
    endDate.setMonth(today.getMonth() - 6);
    return endDate;
  }
  const [view, setViews] = useState("overview");
  const [suppList, setSuppList] = useState({ data: [], loading: false });
  const [selectedSupp, setSelectedSupp] = useState({});
  const [supplierName, setSupplierName] = useState("");

  const [constLabel, setConstLabel] = useState({});
  const [supplierpresent, setsupplierpresent] = useState(false);
  const [key, setkey] = useState("supplierOverview");
  const [startDate, setStartDate] = useState(getPrvMonthDateFromToday);
  const [endDate, setEndDate] = useState(new Date());

  const [dateFilterTab, setDateFilterTab] = useState("Today");
  const [statusTab, setStatusTab] = useState("Pending");
  const [invoiceStatus, setInvoiceStatus] = useState("Pending");
  const [paymentStatus, setPaymentStatus] = useState("Status1");
  const [placeholderStatus, setPlaceholderStatus] = useState("Status1");

  const [po_value, setPO_Value] = useState(0);

  const loginData = useSelector(
    (state) => state.auth?.loginData?.data?.response
  );
  const [privilage, setPrivilage] = useState({
    read: true,
    write: true,
    view: true,
    delete: true,
    report: true,
    no_access: false,
  });

  useEffect(() => {
    setPrivilage(setAccessPrivilage(loginData, "Supplier360"));
    openNav();
    // fetchSupp();
    setConstLabel(getLabels("Master", "Supplier360"));
  }, []);

  useEffect(() => {
    const getData = setTimeout(() => {
      fetchSupp();
    }, 1000);
    return () => clearTimeout(getData);
  }, [supplierName]);

  // Call the fetch function inside useEffect
  useEffect(() => {
    fetchPurchaseOrderValue();
  }, [startDate, endDate, selectedSupp]); // Re-run when these dependencies change

  function fetchSupp(para1, para2) {
    let payload = {
      searchVal: supplierName.length > 0 ? supplierName : null,
      partyType: "Supplier",
    };
    setSuppList({ ...suppList, loading: true });

    getSupplier(payload).then(
      (res) => {
        setSuppList({ ...suppList, data: res.data.data, loading: false });
        if (res.data.data.length > 0) {
          setSelectedSupp(res.data.data[0]);
          setsupplierpresent(false);
        } else {
          setsupplierpresent(true);
        }
      },
      (err) => {
        setSuppList({ ...suppList, data: [], loading: false });
      }
    );
  }

  function openNav() {
    document.getElementById("mySidebar").style.width = "320px";
    document.getElementById("root_div_main").style.marginLeft = "320px";
    setTimeout(() => {
      document.getElementById("mySidebar").style.overflowX = "visible";
    }, [200]);
  }

  function closeNav() {
    document.getElementById("mySidebar").style.width = "0";
    document.getElementById("root_div_main").style.marginLeft = "0";

    document.getElementById("mySidebar").style.overflowX = "hidden";
  }

  function handleFilterTab(tab) {
    setDateFilterTab(tab);
  }

  function handleOrderStatus(ord_st) {
    setStatusTab(ord_st);
  }

  function handleInvoiceStatus(inv_st) {
    setInvoiceStatus(inv_st);
  }

  function handlePaymentStatus(pay_st) {
    setPaymentStatus(pay_st);
  }

  function handlePlaceholderStatus(plc_st) {
    setPlaceholderStatus(plc_st);
  }

  function toggleNav() {
    const sidebar = document.getElementById("mySidebar");
    const mainDiv = document.getElementById("root_div_main");

    if (sidebar.style.width === "320px") {
      // Sidebar is open, so close it
      sidebar.style.width = "0";
      mainDiv.style.marginLeft = "0";
      sidebar.style.overflowX = "hidden";
    } else {
      // Sidebar is closed, so open it
      sidebar.style.width = "320px";
      mainDiv.style.marginLeft = "320px";
      setTimeout(() => {
        sidebar.style.overflowX = "visible";
      }, 200);
    }
  }

  // Separate function to fetch and calculate total amount
  async function fetchPurchaseOrderValue() {
    try {
      const payload = {
        startDate: convertDateYYMMDD(startDate, "-"),
        endDate: convertDateYYMMDD(endDate, "-"),
        suppliername: `["${selectedSupp.name}"]`,
      };

      const res = await getPO(payload);
      const amount = res.data.data?.reduce((orderAcc, order) => {
        console.log("werewirweio", orderAcc + Number(order.net_amount ?? 0));
        return orderAcc + Number(order.net_amount ?? 0); // Use final_total if it exists
      }, 0);

      setPO_Value(amount.toFixed(2)); // Update state with total amount
    } catch (error) {
      // console.error("Error fetching sales order:", error);
      setPO_Value(0);
    }
  }

  return (
    <React.Fragment>
      <div className="min-vh-100" id="root_div_main">
        <div id="mySidebar" className="supplier_customsidebar ">
          {privilage.read && (
            <>
              <div className="d-flex justify-content-between m-2">
                <p className="roboto-bold pt-2" style={{ fontSize: "24px" }}>
                  {constLabel?.lbl_suppliers
                    ? constLabel.lbl_suppliers
                    : "Suppliers"}
                </p>
                <div>
                  <a className="custom-close-btn" onClick={() => closeNav()}>
                    ×
                  </a>
                </div>
              </div>

              <div className="col-12 pb-2 px-2">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  placeholder="Search by name"
                  onChange={(val) => {
                    const inputValue = val.target.value;
                    if (!inputValue.trim()) {
                      if (supplierName.length == 1) {
                        setSupplierName("");
                      }
                    } else {
                      setSupplierName(inputValue);
                    }
                  }}
                />
              </div>

              <div className="content" style={{ marginTop: "0px" }}>
                {suppList.loading ? (
                  <div className="d-flex justify-content-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  suppList.data.map((val, ind) => (
                    <div className="supplier_box" key={ind}>
                      <div className="form-check">
                        <input
                          onChange={() => {
                            setSelectedSupp(val);
                          }}
                          checked={selectedSupp.uuid === val.uuid}
                          className="form-check-input cursor_pointer"
                          type="checkbox"
                          value=""
                          id={"flexCheckDefault" + ind}
                        />
                        <label
                          className="form-check-label cursor_pointer"
                          htmlFor={"flexCheckDefault" + ind}
                        >
                          {val.name}
                        </label>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </>
          )}
        </div>

        <div className="supplier_360_view">
          {supplierpresent == false ? (
            // <div className="row">
            //   <div className="col-12">
            //     <div className="card border-0">
            //       <div className="card-body p-0">
            //         <div className="p-4 d-flex justify-content-between">
            //           <p className="roboto-bold m-0" style={{ fontSize: "24px" }}>
            //             {selectedSupp.name}
            //           </p>
            //           <button
            //             onClick={() => openNav()}
            //             className="btn btn-sm btn-grey ms-4 px-3"
            //           >
            //             {constLabel?.lbl_view_suppliers ? constLabel.lbl_view_suppliers : "View Suppliers"}
            //           </button>
            //           <div className="col-12 col-md-6">
            //             <div className="d-flex justify-content-end">
            //             <CreatePO selectedSupp={[selectedSupp]} supplierList={suppList.data} constLabel={constLabel} />
            //             <CreateQuote selectedSupp={[selectedSupp]} supplierList={suppList.data} constLabel={constLabel} />
            //             </div>
            //           </div>
            //         </div>

            //         <div className="px-4">
            //           <Nav variant="underline">
            //             <Nav.Item className="pr-2">
            //               <Nav.Link
            //                 active={view == "overview"}
            //                 onClick={() => setViews("overview")}
            //               >
            //                 {constLabel?.lbl_overview ? constLabel.lbl_overview : "Overview"}
            //               </Nav.Link>
            //             </Nav.Item>
            //             <Nav.Item className="px-2">
            //               <Nav.Link
            //                 active={view == "quotation"}
            //                 onClick={() => setViews("quotation")}
            //               >
            //                 {constLabel?.lbl_quotation ? constLabel.lbl_quotation : "Quotation"}
            //               </Nav.Link>
            //             </Nav.Item>
            //             <Nav.Item className="px-2">
            //               <Nav.Link
            //                 active={view == "purchase order"}
            //                 onClick={() => setViews("purchase order")}
            //               >
            //                 {constLabel?.lbl_purchase_order ? constLabel.lbl_purchase_order : "Purchase Order"}
            //               </Nav.Link>
            //             </Nav.Item>
            //           </Nav>
            //         </div>

            //         <div
            //           style={{
            //             borderTop: "2px solid #c6c6c6",
            //             marginTop: "-1px",
            //             background: "white",
            //           }}
            //         >
            //           {view == "overview" ? (
            //             <Overview selectedSupp={selectedSupp} constLabel={constLabel} />
            //           ) : view == "quotation" ? (
            //             <SupplierQuoteList selectedSupp={selectedSupp} constLabel={constLabel} />
            //           ) : view == "purchase order" ? (
            //             <SupplierPOList selectedSupp={selectedSupp} constLabel={constLabel} />
            //           ) : null}
            //         </div>
            //       </div>
            //     </div>
            //   </div>
            // </div>

            privilage.read && (
              <div className="row">
                <div className="col-12">
                  <div className="py-2 d-md-flex justify-content-start align-items-center">
                    {/* <button
                    onClick={() => openNav()}
                    className="btn btn-sm btn-grey px-2 "
                  >
                    <FontAwesomeIcon
                      style={{
                        color: "white",                        
                      }}
                      icon={faList}
                      size="sm"
                    />
                  </button> */}
                    <Tooltip id={"open-tooltip"} place="left" />
                    <img
                      src={Slide}
                      height="32"
                      width="32"
                      className="cursor_pointer my-1"
                      onClick={() => toggleNav()}
                      data-tooltip-id={"open-tooltip"}
                      data-tooltip-content={"Filters"}
                    />

                    <p
                      className="roboto-bold m-0 ms-3"
                      style={{ fontSize: "24px" }}
                    >
                      {selectedSupp.name}
                    </p>
                  </div>
                  <div className="card border-0">
                    <div className="card-body p-0">
                      <div className="col-12 col-md-12 p-4">
                        <div className="row">
                          <div className="col-12 col-md-6">
                            <div className="row">
                              <div className="col-12 col-md-3">
                                <p className="mb-0 form-check-label">
                                  {constLabel?.lbl_from_date
                                    ? constLabel.lbl_from_date
                                    : "From Date"}
                                </p>
                              </div>
                              <div className="col-12 col-md-3">
                                <div>
                                  <DatePicker
                                    id="fromDate"
                                    className="form-control form-control-sm"
                                    selected={startDate}
                                    onChange={(e) => setStartDate(e)}
                                    showYearDropdown
                                    scrollableMonthYearDropdown
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="From Date"
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-3">
                                <p className="mb-0">
                                  {constLabel?.lbl_to_date
                                    ? constLabel.lbl_to_date
                                    : "To Date"}
                                </p>
                              </div>
                              <div className="col-12 col-md-3">
                                <p className="mb-0">
                                  <div>
                                    <DatePicker
                                      id="fromDate"
                                      className="form-control form-control-sm"
                                      selected={endDate}
                                      onChange={(e) => setEndDate(e)}
                                      showYearDropdown
                                      scrollableMonthYearDropdown
                                      dateFormat="dd/MM/yyyy"
                                      placeholderText="From Date"
                                    />
                                  </div>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-md-6 text-end">
                            <span
                              className={`roboto badge rounded-pill text-dark badge-outline cursor_pointer me-2 text-center ${
                                dateFilterTab === "Today"
                                  ? "button-primary text-white"
                                  : ""
                              }`}
                              onClick={() => handleFilterTab("Today")}
                            >
                              {constLabel?.lbl_today
                                ? constLabel.lbl_today
                                : "Today"}
                            </span>
                            <span
                              className={`roboto badge rounded-pill text-dark badge-outline cursor_pointer me-2 text-center ${
                                dateFilterTab === "This Week"
                                  ? "button-primary text-white"
                                  : ""
                              }`}
                              onClick={() => handleFilterTab("This Week")}
                            >
                              {constLabel?.lbl_this_week
                                ? constLabel.lbl_this_week
                                : "This Week"}
                            </span>
                            <span
                              className={`roboto badge rounded-pill text-dark badge-outline cursor_pointer text-center ${
                                dateFilterTab === "This Month"
                                  ? "button-primary text-white"
                                  : ""
                              }`}
                              onClick={() => handleFilterTab("This Month")}
                            >
                              {constLabel?.lbl_this_year
                                ? constLabel.lbl_this_year
                                : "This Year"}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-3 mt-4">
                      <div className="card border-0">
                        <div className="card-body p-2">
                          <div className="d-flex justify-content-between align-items-center">
                            <p className="roboto-bold">
                              {constLabel?.lbl_purchase_order
                                ? constLabel.lbl_purchase_order
                                : "Purchase Order"}{" "}
                              <span>(&#8377;)</span>
                            </p>
                            {/* <FontAwesomeIcon
                            className="pt-1"
                            icon={faCircleInfo}
                            size="sm"
                          /> */}
                          </div>

                          {/* <div className="col-12 col-md-12">
                          <span className={`roboto badge rounded-pill text-dark badge-outline cursor_pointer me-2 text-center ${statusTab === 'Approved' ? 'button-primary text-white' : ''}`} onClick={() => handleOrderStatus('Approved')}>
                            {constLabel?.lbl_approved ? constLabel.lbl_approved : "Approved"}
                          </span>
                          <span className={`roboto badge rounded-pill text-dark badge-outline cursor_pointer me-2 text-center ${statusTab === 'Cancelled' ? 'button-primary text-white' : ''}`} onClick={() => handleOrderStatus('Cancelled')}>
                            {constLabel?.lbl_cancelled ? constLabel.lbl_cancelled : "Cancelled"}
                          </span>
                          <span className={`roboto badge rounded-pill text-dark badge-outline cursor_pointer text-center ${statusTab === 'Pending' ? 'button-primary text-white' : ''}`} onClick={() => handleOrderStatus('Pending')}>
                            {constLabel?.lbl_pending ? constLabel.lbl_pending : "Pending"}
                          </span>
                        </div> */}

                          <div className="roboto-bold text-center m-3">
                            <h1 className="bold">{po_value}</h1>
                            {/* <p>Orders Pending</p>
                          <span className={`roboto badge rounded-pill text-dark badge-outline cursor_pointer text-center`}>
                            View All Purchase Order
                          </span> */}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12 col-md-3 mt-4">
                      <div className="card border-0">
                        <div className="card-body p-2">
                          <div className="d-flex justify-content-between align-items-center">
                            <p className="roboto-bold">
                              {constLabel?.lbl_purchase_invoice
                                ? constLabel.lbl_purchase_invoice
                                : "Purchase Invoice"}{" "}
                              <span>(&#8377;)</span>
                            </p>
                            {/* <FontAwesomeIcon
                            className="pt-1"
                            icon={faCircleInfo}
                            size="sm"
                          /> */}
                          </div>
                          {/* <div className="col-12 col-md-12">
                          <span className={`roboto badge rounded-pill text-dark badge-outline cursor_pointer me-2 text-center ${invoiceStatus === 'Approved' ? 'button-primary text-white' : ''}`} onClick={() => handleInvoiceStatus('Approved')}>
                            {constLabel?.lbl_approved ? constLabel.lbl_approved : "Approved"}
                          </span>
                          <span className={`roboto badge rounded-pill text-dark badge-outline cursor_pointer me-2 text-center ${invoiceStatus === 'Cancelled' ? 'button-primary text-white' : ''}`} onClick={() => handleInvoiceStatus('Cancelled')}>
                            {constLabel?.lbl_cancelled ? constLabel.lbl_cancelled : "Cancelled"}
                          </span>
                          <span className={`roboto badge rounded-pill text-dark badge-outline cursor_pointer text-center ${invoiceStatus === 'Pending' ? 'button-primary text-white' : ''}`} onClick={() => handleInvoiceStatus('Pending')}>
                            {constLabel?.lbl_pending ? constLabel.lbl_pending : "Pending"}
                          </span>
                        </div> */}

                          <div className="roboto-bold text-center m-3">
                            <h1 className="bold">0</h1>
                            {/* <p>Purchase invoice pending</p>
                          <span className={`roboto badge rounded-pill text-dark badge-outline cursor_pointer text-center`}>
                            View All Purchase Invoices
                          </span> */}
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className="col-12 col-md-3 mt-4">
                    <div className="card border-0">
                      <div className="card-body p-2">
                        <div className="d-flex justify-content-between align-items-center">
                          <p className="roboto-bold">{constLabel?.lbl_total_payment_receive ? constLabel.lbl_total_payment_receive : "Total Payment Received"}</p>
                          <FontAwesomeIcon
                            className="pt-1"
                            icon={faCircleInfo}
                            size="sm"
                          />
                        </div>
                        <div className="col-12 col-md-12">
                          <span className={`roboto badge rounded-pill text-dark badge-outline cursor_pointer me-2 text-center ${paymentStatus === 'Status1' ? 'button-primary text-white' : ''}`} onClick={() => handlePaymentStatus('Status1')}>
                            Status1
                          </span>
                          <span className={`roboto badge rounded-pill text-dark badge-outline cursor_pointer me-2 text-center ${paymentStatus === 'Status2' ? 'button-primary text-white' : ''}`} onClick={() => handlePaymentStatus('Status2')}>
                            Status2
                          </span>
                          <span className={`roboto badge rounded-pill text-dark badge-outline cursor_pointer text-center ${paymentStatus === 'Status3' ? 'button-primary text-white' : ''}`} onClick={() => handlePaymentStatus('Status3')}>
                            Status3
                          </span>
                        </div>

                        <div className="roboto-bold text-center m-3">
                          <h1>$ 10,000</h1>
                          <p className="invisible">payment</p>
                          <span className={`roboto badge rounded-pill text-dark badge-outline cursor_pointer text-center`}>
                            View All Transactions
                          </span>

                        </div>

                      </div>
                    </div>
                  </div> */}

                    {/* <div className="col-12 col-md-3 mt-4">
                    <div className="card border-0">
                      <div className="card-body p-2">
                        <div className="d-flex justify-content-between align-items-center">
                          <p className="roboto-bold">{constLabel?.lbl_placeholder_widget ? constLabel.lbl_placeholder_widget : "Placeholder Widget"}</p>
                          <FontAwesomeIcon
                            className="pt-1"
                            icon={faCircleInfo}
                            size="sm"
                          />
                        </div>
                        <div className="col-12 col-md-12">
                          <span className={`roboto badge rounded-pill text-dark badge-outline cursor_pointer me-2 text-center ${placeholderStatus === 'Status1' ? 'button-primary text-white' : ''}`} onClick={() => handlePlaceholderStatus('Status1')}>
                            Status1
                          </span>
                          <span className={`roboto badge rounded-pill text-dark badge-outline cursor_pointer me-2 text-center ${placeholderStatus === 'Status2' ? 'button-primary text-white' : ''}`} onClick={() => handlePlaceholderStatus('Status2')}>
                            Status2
                          </span>
                          <span className={`roboto badge rounded-pill text-dark badge-outline cursor_pointer text-center ${placeholderStatus === 'Status3' ? 'button-primary text-white' : ''}`} onClick={() => handlePlaceholderStatus('Status3')}>
                            Status3
                          </span>
                        </div>

                        <div className="roboto-bold text-center m-3">
                          <h1>5</h1>
                          <p>Label</p>
                          <span className={`roboto badge rounded-pill text-dark badge-outline cursor_pointer text-center`}>
                            Placeholder CTA
                          </span>

                        </div>

                      </div>
                    </div>
                  </div> */}
                  </div>

                  <div className="row mt-4 p-2" id="cust_tab_master">
                    <Tabs
                      variant="pills"
                      id="controlled-tab-example"
                      activeKey={key}
                      onSelect={(k) => {
                        setkey(k);
                      }}
                      className="mb-3"
                      // fill
                    >
                      <Tab
                        eventKey="supplierOverview"
                        title={
                          <span className="f-14 f-w-600">
                            {"Supplier Overview"}
                          </span>
                        }
                      >
                        <SupplierOverview
                          data={selectedSupp}
                          constLabel={constLabel}
                        />
                      </Tab>
                      <Tab
                        eventKey="contactDetail"
                        title={
                          <span className="f-14 f-w-600">
                            {"Contact Detail"}
                          </span>
                        }
                      >
                        <ContactDetailSUPP
                          setkey={setkey}
                          data={selectedSupp}
                        />
                      </Tab>

                      <Tab
                        eventKey="address"
                        title={
                          <span className="f-14 f-w-600">{"Address"}</span>
                        }
                      >
                        <Address data={selectedSupp} />
                      </Tab>

                      <Tab
                        eventKey="purchaseOrder"
                        title={
                          <span className="f-14 f-w-600">
                            {"Purchase Order"}
                          </span>
                        }
                      >
                        <SupplierPOList
                          selectedSupp={selectedSupp}
                          constLabel={constLabel}
                          startDate={startDate}
                          endDate={endDate}
                        />
                      </Tab>

                      <Tab
                        eventKey="quotation"
                        title={
                          <span className="f-14 f-w-600">{"Quotation"}</span>
                        }
                      >
                        <SupplierQuoteList
                          selectedSupp={selectedSupp}
                          constLabel={constLabel}
                          startDate={startDate}
                          endDate={endDate}
                        />
                      </Tab>

                      <Tab
                        eventKey="transaction"
                        title={
                          <span className="f-14 f-w-600">{"Transaction"}</span>
                        }
                      >
                        <div
                          className="alert alert-sm alert-danger mb-0 text-center my-1"
                          role="alert"
                          setkey={setkey}
                        >
                          {"Transaction list is empty !"}
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
            )
          ) : (
            <div
              className="alert alert-sm alert-danger mb-0 text-center my-1"
              role="alert"
            >
              {"Supplier not found !"}
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default Suppliers360;
