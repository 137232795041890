import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import DatePicker from "react-datepicker";
import { getLabels } from "../../../common/Functions/CommonFunctions";

const months = [
  "January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];


export const IndirectCosting = ({
  visible,
  setVisible,
  cost_json,
  setCost_Json,
}) => {
  
  const [constLabel, setConstLabel] = useState({});

  const { register, handleSubmit, formState, control, reset, watch, setValue, setError } = useForm({
    mode: "onChange",
  }); // initialise the hook

  let { errors } = formState;

  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [costConfig, setCostConfig] = useState(cost_json);

  useEffect(() => {
    setConstLabel(getLabels("Master", "Organization"));
  }, [])
  

  const handleYearChange = (date) => {
    if (!date) return;  // Prevents errors when date is null
    const year = date.getFullYear();
    setSelectedYear(year);
  };

  const handleCostChange = (month, value) => {
    let sanitizedValue = value.replace(/[^0-9. ]/g, ""); // Allow only digits, dot, and space
    
    // Ensure there's only one dot in the number
    const dotCount = (sanitizedValue.match(/\./g) || []).length;
    if (dotCount > 1) {
      return; // Ignore invalid input
    }
  
    setCostConfig(prevConfig => {
      let updatedCostConfig = [...prevConfig];
      const monthIndex = updatedCostConfig.findIndex(item => item.Year === selectedYear && item.Month === month);
      let costValue = parseFloat(sanitizedValue);
  
      if (!isNaN(costValue)) {
        costValue = Number(costValue.toFixed(2)); // Ensure it's a number, not a string
      } else {
        costValue = 0;
      }
  
      if (costValue > 0) {
        if (monthIndex === -1) {
          updatedCostConfig.push({ Year: selectedYear, Month: month, Cost: costValue });
        } else {
          updatedCostConfig[monthIndex].Cost = costValue;
        }
      } else {
        updatedCostConfig = updatedCostConfig.filter(item => !(item.Year === selectedYear && item.Month === month));
      }
  
      setCost_Json(updatedCostConfig);
      return updatedCostConfig;
    });
  };
  

  return (
    <div>
      <ToastContainer />
      <Modal show={visible} onHide={() => setVisible(false)} size={"lg"}>
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>{constLabel?.lbl_add_indirect_costing ? constLabel?.lbl_add_indirect_costing : "Add Indirect Costing"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action="">
            <div className="col-12 col-sm-2">
                <div className="form-group">
                <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_select_year
                    ? constLabel.lbl_select_year
                    : "Select Year"}
                </label>
                <Controller
                    name="costing_year"
                    control={control}
                    render={({ field }) => (
                    <DatePicker
                        className={"form-control mt-2"}
                        {...field}
                        showYearPicker
                        dateFormat="yyyy"
                        selected={new Date(selectedYear, 0)}
                        onChange={handleYearChange}
                        placeholderText="Select year"
                        autoComplete="off"
                    />
                    )}
                />
                </div>
            </div>
            <hr className="my-2"/>
            {/* Month Cards */}
           
            <div className="row">
              {months.map((month, ind) => (
                <div key={month} className={`col-12 col-sm-3 border p-2 ${costConfig?.some(item => item.Year === selectedYear && item.Month === month && item.Cost) ? "bg-green-light" : "bg-red-light"}`}>
                  <h4 className="text-muted bold">{month}</h4>
                  <div className="form-group my-3 d-flex align-items-center">
                    <label className="lbl-style me-2">Cost</label>
                    <input
                      type="number"
                      className="form-control form-control-sm text-center roboto-bold"
                      value={costConfig.find(item => item.Year === selectedYear && item.Month === month)?.Cost || ""}
                      onChange={(e) => handleCostChange(month, e.target.value)}
                      style={{ appearance: "textfield" }} // For extra safety
                  
                    />
                    <label className="lbl-style ms-2">INR</label>
                  </div>
                </div>
              ))}
            </div>
          </form>
          {/* <pre className="mt-4">{JSON.stringify(costConfig, null, 2)}</pre> */}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default IndirectCosting;