import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import SweetAlert from "sweetalert2";
import { useForm, Controller } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import {
  getLocation,
  getMachineCategory,
  machineCategory,
  saveMachine,
  updateMachine,
  get_machine_model
} from "../../../../api";
import DatePicker from "react-datepicker";
import { useNavigate, useLocation } from "react-router-dom";
import { convertDateYYMMDDHHMM } from "../../../../common/Functions/CommonFunctions";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import UpdateMachineGallery from "../MachineGallery/UpdateMachineGallery";
const ipv4Regex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
const schema = yup
  .object()
  .shape({
    category: yup
      .array()
      .min(1, "Please select category")
      .required("Please select category!"),
    machineModel: yup
      .array()
      .min(1, "Please select machine model !")
      .required("Please select machine model !"),
    location: yup
      .array()
      .min(1, "Please select location")
      .required("Please select location !"),
    machineName: yup.string().required("Machine name is required !"),
    serialNo: yup
      .string()
      .required("Serial number is required !")
      .matches(/^[a-zA-Z0-9 -]*$/, "Enter valid serial number"),
    cost: yup
      .string()
      .required("Machine cost is required !")
      .matches(/^\d+(\.\d+)?$/, "Enter valid machine cost"),
    machine_operating_cost: yup
          .string()
          .test(
            "is-valid-number",
            "Enter a valid machine hour cost",
            (value) => {
              // If the field is empty, it's valid (optional field)
              if (!value) return true;
              // Otherwise, validate the number format
              return /^[0-9]+(\.[0-9]+)?$/.test(value);
            }
          )
          .notRequired(),  
    lastServiceDate: yup.date().typeError("Please select last service date !"),
    service_schedule: yup
      .number()
      .integer("Enter valid service schedule")
      .typeError("Service Schedule is required !"),
    nextServiceDate: yup
      .date()
      .when("lastServiceDate", (lastServiceDate, schema) => {
        // Check if lastServiceDate is undefined or not a valid date
        if (!lastServiceDate || isNaN(new Date(lastServiceDate))) {
          return schema.notRequired();
        }
        return schema.min(
          lastServiceDate,
          "Next service date should be greater than last service date"
        );
      })
      .typeError("Please select next service date !"),
    // machine_ip_address: yup
    //   .string()
    //   .matches(ipv4Regex, "Enter valid ip address")
    //   .nullable(),
    // remark: yup
    //   .string()
    //   .max(500, "500 characters allowed")
    //   .required("Remarks are required"),
    // expDate: yup
    //   .date()
    //   .when("lastServiceDate", (lastServiceDate, schema) => {
    //     // Check if lastServiceDate is undefined or not a valid date
    //     if (!lastServiceDate || isNaN(new Date(lastServiceDate))) {
    //       return schema.notRequired();
    //     }
    //     return schema.min(lastServiceDate, "Expiry date must be future date");
    //   })
    //   .required("Expiry date is required"),
  })
  .required();

function EditMachine(props) {
  let navigate = useNavigate();
  let location = useLocation();
  const [machine_cat, setMachine_Cat] = useState({ data: [], loading: false });
  const [locData, setLocData] = useState({ data: [], loading: false });
  const [macModel, setMacModel] = useState({ data: [], loading: false });
  const [galVisible, setGalVisible] = useState(false);
  const [drawingFiles, setDrawingFiles] = useState([]);
  const [totalDays, setTotalDays] = useState("");

  const [iot_flag, setIOT_Flag] = useState(props.selMachine?.iot_enabled);

  const { register, handleSubmit, formState, control, reset, watch, setValue } =
    useForm({
      resolver: yupResolver(schema),
      mode: "onChange",
    }); // initialise the hook

  let { errors } = formState;

  const lastSerDate = watch("lastServiceDate");
  const type = watch("service_schedule_type");
  const serSchedule = watch("service_schedule");

  useEffect(() => {
    fetchLocation();
    fetchMachineCategory();
    fetchMachineModel();
  }, []);

  useEffect(() => {
    
    if (type && lastSerDate && serSchedule) {
      const inputDate = new Date(lastSerDate);
      inputDate.setDate(inputDate.getDate() + 1);
      

      const newValue = calculateNewDate(type, inputDate, serSchedule);

      
      setValue("nextServiceDate", new Date(newValue));

      var timeDifference = Math.abs(new Date(newValue) - new Date(inputDate));
      var daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
      setTotalDays(daysDifference);
      
    }
  }, [type, lastSerDate, serSchedule]);

  const calculateNewDate = (type, lastSerDate, value) => {

    const date = new Date(lastSerDate);


    if (type === 'days') {
      date.setDate(date.getDate() + parseInt(value));
    } else if (type === 'months') {
      date.setMonth(date.getMonth() + parseInt(value));
    } else if (type === 'years') {
      date.setFullYear(date.getFullYear() + parseInt(value));
    }


    return date.toISOString().split('T')[0];
  };

  function fetchMachineModel() {
    setMacModel({loading: true, data: [] });
    console.log("props.selMachine", props.selMachine);
    get_machine_model().then(
      (res) => {
        
        setMacModel({
          loading: false,
          data: res.data.data,
        });
        const mac = res.data.data.find(
          (item) => item.uuid === props.selMachine?.machine_model_id
        );
        if (setValue && mac) {
          setValue("machineModel", [mac]);
        }
      },
      (err) => {
        //
        setMacModel({loading: false, data: [] });
        toast.error(err.response.data.message, {
          autoClose: 3000,
        });
      }
    );
  }

  function fetchLocation() {
    setLocData({ loading: true, data: [] });

    getLocation().then(
      (res) => {
        const result = res.data.data.filter((item) => item.location_type_id?.typename?.toLowerCase() == "organization");
        setLocData({
          loading: false,
          data: result,
        });
        const loc = res.data.data.find(
          (item) => item.uuid === props.selMachine?.location_id
        );
        if (setValue && loc) {
          
          setValue("location", [loc]);
        }
      },
      (err) => {
        
        setLocData({ loading: false, data: [] });
        toast.error(err.response.data.message, {
          autoClose: 3000,
        });
      }
    );
  }

  function fetchMachineCategory() {
    setMachine_Cat({ data: [], state: true });

    getMachineCategory().then(
      (res) => {
        
        setMachine_Cat({
          state: false,
          data: res.data.data,
        });
        const cat = res.data.data.find(
          (item) => item.uuid === props.selMachine?.machine_cat_id
        );
        if (setValue && cat) {
          
          setValue("category", [cat]);
        }
      },
      (err) => {
        
        setMachine_Cat({ state: false, data: [] });
        toast.error(err.response.data.message, {
          autoClose: 3000,
        });
      }
    );
  }

  const onSubmit = (data) => {
    
// console.log("data", data);
    if (data !== "") {
      const reqPayload = {
        uuid: props.selMachine?.uuid,
        model: data.machineModel?.[0]?.model_name,
        machineName: data.machineName,
        mantainanceFrequency: data.mantainanceFrequency,
        cost: data.cost,
        vendorName: data.vendorName,
        machineCatId1: data.category[0]?.machineCatId,
        machine_cat_id: data.category[0]?.uuid,
        serialNo: data.serialNo,
        remark: data.remark,
        service_schedule: parseInt(totalDays),
        service_schedule_type: data.service_schedule_type,
        service_schedule_duration: parseInt(data.service_schedule),
        machineModel: data.machineModel?.[0]?.model_name,
        expDate: data.expDate ? convertDateYYMMDDHHMM(data.expDate) : null,
        lastServiceDate: convertDateYYMMDDHHMM(data.lastServiceDate),
        nextServiceDate: convertDateYYMMDDHHMM(data.nextServiceDate),
        locationId: data.location[0]?.locationId,
        location_id: data.location[0]?.uuid,
        locationName: data.location[0]?.locationname,
        machine_model_id: data.machineModel?.[0]?.uuid,
        beaconId: data.beaconId,
        iot_enabled: iot_flag,
        machine_ip_address: data.machine_ip_address,
        hourly_running_cost:data.machine_operating_cost?data.machine_operating_cost:null

      };

      // console.log("reqPayload", reqPayload); return;
      

      // let imgArr = {};

      // for (let a = 0; a < drawingFiles.length; a++) {
      //   imgArr["attachment" + (a + 1)] = drawingFiles[a].file;
      //   imgArr["obj" + (a + 1)] = JSON.stringify(drawingFiles[a]);
      //   imgArr["img_count"] = drawingFiles.length;

      //   let formDataToSend = new FormData();

      //   for (const [key, value] of Object.entries(imgArr)) {
      //     formDataToSend.append(key, value);
      //   }
      // }

      

      updateMachine(reqPayload).then(
        (res) => {
          
          if (res.status === 200 || res.status === 201) {
            toast.success("Machine details updated Successfully !", {
              autoClose: 3000,
            });
            props.setSelStatus({ view: true, add: false, edit: false });
            props.fetchMachine();
            reset();
          }
        },
        (err) => {
          
          if (err.response.status === 400) {
            
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
            reset();
          }
        }
      );
    } else {
      errors.showMessages();
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <UpdateMachineGallery
        selMachine={props.selMachine}
        galVisible={galVisible}
        setGalVisible={setGalVisible}
        machineImgs={props.machineImgs}
        fetchImage={props.fetchImage}
        constLabel={props.constLabel}
      />

      <div className="manufac_goods_form mb-4">
        <div className="card border-0">
          <div className="d-flex justify-content-between align-items-center px-4 mt-3">
            <p className="roboto-bold m-0" style={{ fontSize: "24px" }}>
              {props.constLabel?.lbl_update_machine ? props.constLabel?.lbl_update_machine : "Update Machine" }
            </p>
            <a
              className="custom-close-btn"
              onClick={() => {
                    props.setSelStatus({
                      view: true,
                      add: false,
                      edit: false,
                    });
              }}
            >
              ×
            </a>
          </div>
          <div className="card-body">
            <div className="createNc container-fluid">
              <div className="row">
              <div className="col-12 col-sm-4">
                  <div className="form-group typeahead-container">
                    <label className="lbl-style roboto-bold">
                    {props.constLabel?.lbl_category_name ? props.constLabel?.lbl_category_name : "Category Name"}
                      <span className="text-danger">*</span>
                    </label>
                    <Controller
                      name="category"
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          className={"mt-2"}
                          id="basic-typeahead-single"
                          labelKey="machineCatName"
                          multiple={false}
                          options={
                            machine_cat.data !== undefined
                              ? machine_cat.data
                              : []
                          }
                          isLoading={machine_cat.state}
                          placeholder="Select category "
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          isInvalid={errors.category ? true : false}
                        />
                      )}
                    />
                    {!machine_cat.state && !errors.category && <span className="arrow-icon"></span>}
                    <span className="text-danger err-msg">
                      {errors.category?.message}
                    </span>
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div className="form-group">
                    <label className="lbl-style roboto-bold">
                    {props.constLabel?.lbl_machine_name ? props.constLabel?.lbl_machine_name : "Machine Name"}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      className={
                        errors.machineName
                          ? "form-control mt-2 is-invalid"
                          : "form-control mt-2"
                      }
                      type="text"
                      id="machineName"
                      name="machineName"
                      placeholder="Enter machine name"
                      defaultValue={props.selMachine?.machineName}
                      {...register("machineName")}
                    />
                    <span className="text-danger err-msg">
                      {errors.machineName?.message}
                    </span>
                  </div>
                </div>
                
                <div className="col-12 col-sm-4">
                  <div className="form-group typeahead-container">
                    <label className="lbl-style roboto-bold">
                    {props.constLabel?.lbl_location_name ? props.constLabel?.lbl_location_name : "Location Name"}
                      <span className="text-danger">*</span>
                    </label>
                    <Controller
                      name="location"
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          className={"mt-2"}
                          id="basic-typeahead-single"
                          labelKey="locationname"
                          multiple={false}
                          options={
                            locData.data !== undefined ? locData.data : []
                          }
                          isLoading={locData.loading}
                          placeholder="Select location "
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          isInvalid={errors.location ? true : false}
                        />
                      )}
                    />
                    {!locData.loading && !errors.location && <span className="arrow-icon"></span>}
                    <span className="text-danger err-msg">
                      {errors.location?.message}
                    </span>
                  </div>
                </div>

                <div className="col-12 col-sm-4">
                  <div className="form-group mt-3">
                    <label className="lbl-style roboto-bold">
                     {props.constLabel?.lbl_vendor_name ? props.constLabel?.lbl_vendor_name : "Vendor Name"}
                    </label>
                    <input
                      className={"form-control mt-2"}
                      type="text"
                      id="vendorName"
                      name="vendorName"
                      placeholder="Enter vendor name"
                      defaultValue={props.selMachine?.vendorName}
                      {...register("vendorName")}
                    />
                  </div>
                </div>

                <div className="col-12 col-sm-4">
                  <div className="form-group mt-3 typeahead-container">
                    <label className="lbl-style roboto-bold">
                      {props.constLabel?.lbl_model ? props.constLabel?.lbl_model : "Model"}
                      <span className="text-danger"> *</span>
                    </label>
                    <Controller
                      name="machineModel"
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          className={"mt-2"}
                          id="basic-typeahead-single"
                          labelKey="model_name"
                          multiple={false}
                          options={
                            macModel.data !== undefined ? macModel.data : []
                          }
                          isLoading={macModel.loading}
                          placeholder="Select machine model"
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          isInvalid={errors.machineModel ? true : false}
                        />
                      )}
                    />
                    {!macModel.loading && !errors.machineModel && <span className="arrow-icon"></span>}
                    <span className="text-danger err-msg">
                      {errors.machineModel?.message}
                    </span>
                  </div>
                </div>

                {/* <div className="col-12 col-sm-4">
                  <div className="form-group mt-3">
                    <label className="lbl-style roboto-bold">
                    {props.constLabel?.lbl_model ? props.constLabel?.lbl_model : "Model"}
                      </label>
                    <input
                      className={
                        errors.machineModel
                          ? "form-control mt-2 is-invalid"
                          : "form-control mt-2"
                      }
                      type="text"
                      id="machineModel"
                      name="machineModel"
                      placeholder="Enter machine model"
                      defaultValue={props.selMachine?.machineModel}
                      {...register("machineModel")}
                    />
                  </div>
                </div> */}


                <div className="col-12 col-sm-4">
                  <div className="form-group mt-3">
                    <label className="lbl-style roboto-bold">
                    {props.constLabel?.lbl_serial_number ? props.constLabel?.lbl_serial_number : "Serial Number"}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      className={
                        errors.serialNo
                          ? "form-control mt-2 is-invalid"
                          : "form-control mt-2"
                      }
                      type="text"
                      id="serialNo"
                      name="serialNo"
                      placeholder="Enter serial number"
                      defaultValue={props.selMachine?.serialNo}
                      {...register("serialNo")}
                    />
                    <span className="text-danger err-msg">
                      {errors.serialNo?.message}
                    </span>
                  </div>
                </div>

                <div className="col-12 col-sm-4">
                  <div className="form-group mt-3">
                    <label className="lbl-style roboto-bold">
                    {props.constLabel?.lbl_maintenance_frequnecy ? props.constLabel?.lbl_maintenance_frequnecy : "Maintenance Frequency"}
                    </label>
                    <input
                      className="form-control mt-2"
                      type="text"
                      id="mantainanceFrequency"
                      name="mantainanceFrequency"
                      placeholder="Enter maintainance frequency"
                      defaultValue={props.selMachine?.mantainanceFrequency}
                      {...register("mantainanceFrequency")}
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div className="form-group mt-3">
                    <label className="lbl-style roboto-bold">
                    {props.constLabel?.lbl_machine_cost ? props.constLabel?.lbl_machine_cost : "Machine Cost"}
                      </label>
                    <input
                      className={
                        errors.cost
                          ? "form-control mt-2 is-invalid"
                          : "form-control mt-2"
                      }
                      type="text"
                      id="cost"
                      name="cost"
                      placeholder="Enter machine cost"
                      defaultValue={props.selMachine?.cost}
                      {...register("cost")}
                    />
                    <span className="text-danger err-msg">
                      {errors.cost?.message}
                    </span>
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div className="form-group mt-3">
                    <label className="lbl-style roboto-bold">
                      {props.constLabel?.lbl_machine_operating_cost
                        ? props.constLabel?.lbl_machine_operating_cost
                        : "Machine Operating Cost(₹)/Hour"}
                    </label>
                    <input
                      className={
                        errors.machine_operating_cost
                          ? "form-control mt-2 is-invalid"
                          : "form-control mt-2"
                      }
                      type="text"
                      id="machine_operating_cost"
                      name="machine_operating_cost"
                      defaultValue={props.selMachine?.hourly_running_cost
                      }
                      placeholder="Enter machine hour cost"
                      {...register("machine_operating_cost")}
                    />
                    <span className="text-danger err-msg">
                      {errors.machine_operating_cost?.message}
                    </span>
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div className="form-group mt-3">
                    <label className="lbl-style roboto-bold">
                    {props.constLabel?.lbl_service_schedule ? props.constLabel?.lbl_service_schedule : "Service Schedule"}
                      <span className="text-danger">*</span>
                    </label>
                    <div className="input-group mt-2">
                      <input
                        className={
                          errors.service_schedule
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        type="number"
                        id="service_schedule"
                        min={0}
                        name="service_schedule"
                        placeholder="Enter service schedule"
                        defaultValue={
                          props.selMachine?.service_schedule_type == "months" ||
                            props.selMachine?.service_schedule_type == "years"
                            ? props.selMachine?.service_schedule_duration
                            : props.selMachine?.service_schedule
                        }
                        {...register("service_schedule")}
                      />
                      <div className="input-group-text p-0 px-2">
                        <select
                          {...register("service_schedule_type")}
                          defaultValue={props.selMachine?.service_schedule_type}
                          className="form-select form-select-sm"
                          aria-label="Small select example"
                        >
                          <option value="days">Days</option>
                          <option value="years">Years</option>
                          <option value="months">Months</option>
                        </select>
                      </div>
                    </div>
                    <span className="text-danger err-msg">
                      {errors.service_schedule?.message}
                    </span>
                  </div>
                </div>

                <div className="col-12 col-sm-4">
                  <div className="form-group mt-3">
                    <label className="lbl-style roboto-bold">
                    {props.constLabel?.lbl_lastService_date ? props.constLabel?.lbl_lastService_date : "Last Service Date"}
                      <span className="text-danger">*</span>
                    </label>
                    <Controller
                      name="lastServiceDate"
                      control={control}
                      defaultValue={new Date(props.selMachine?.lastServiceDate)}
                      render={({ field }) => (
                        <DatePicker
                          className={
                            errors.lastServiceDate
                              ? "form-control mt-2 is-invalid"
                              : "form-control mt-2"
                          }
                          {...field}
                          defaultValue={
                            new Date(props.selMachine?.lastServiceDate)
                          }
                          showYearDropdown
                          scrollableMonthYearDropdown
                          dateFormat="dd/MM/yyyy"
                          placeholderText="Select last service date"
                          selected={field.value || null}
                          maxDate={new Date()}
                          autoComplete="off"
                        />
                      )}
                    />
                    {errors.lastServiceDate && (
                      <span className="text-danger err-msg">
                        {errors.lastServiceDate.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div className="form-group mt-3">
                    <label className="lbl-style roboto-bold">
                        {props.constLabel?.lbl_nextService_date ? props.constLabel?.lbl_nextService_date : "Next Service Date"}
                      <span className="text-danger">*</span>
                    </label>
                    <Controller
                      name="nextServiceDate"
                      control={control}
                      defaultValue={new Date(props.selMachine?.nextServiceDate)}
                      render={({ field }) => (
                        <DatePicker
                          className={
                            errors.nextServiceDate
                              ? "form-control mt-2 is-invalid"
                              : "form-control mt-2"
                          }
                          {...field}
                          defaultValue={
                            new Date(props.selMachine?.nextServiceDate)
                          }
                          showYearDropdown
                          scrollableMonthYearDropdown
                          dateFormat="dd/MM/yyyy"
                          placeholderText="Select next service date"
                          selected={field.value || null}
                          maxDate={new Date()}
                          autoComplete="off"
                          disabled
                        />
                      )}
                    />
                    {errors.nextServiceDate && (
                      <span className="text-danger err-msg">
                        {errors.nextServiceDate.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div className="form-group mt-3">
                    <label className="lbl-style roboto-bold">
                      {props.constLabel?.lbl_commission_date ? props.constLabel?.lbl_commission_date : "Commission Date"}
                    </label>
                    <Controller
                      name="expDate"
                      control={control}
                      defaultValue={
                        props.selMachine?.expDate
                          ? new Date(props.selMachine.expDate)
                          : null
                      }
                      render={({ field }) => (
                        <DatePicker
                          className={
                            errors.expDate
                              ? "form-control mt-2 is-invalid"
                              : "form-control mt-2"
                          }
                          {...field}
                          defaultValue={
                            props.selMachine?.expDate
                              ? new Date(props.selMachine.expDate)
                              : null
                          }
                          showYearDropdown
                          scrollableMonthYearDropdown
                          dateFormat="dd/MM/yyyy"
                          placeholderText="Select commission date"
                          selected={field.value || null}
                          // maxDate={new Date()}
                          autoComplete="off"
                        />
                      )}
                    />
                    {errors.expDate && (
                      <span className="text-danger err-msg">
                        {errors.expDate.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-sm-4">
                  <div className="form-group mt-3">
                    <label className="lbl-style roboto-bold">
                    {props.constLabel?.lbl_beacon_id ? props.constLabel?.lbl_beacon_id : "BeaconId"}
                      </label>
                    <input
                      className={
                        errors.beaconId
                          ? "form-control mt-2 is-invalid"
                          : "form-control mt-2"
                      }
                      type="text"
                      id="beaconId"
                      name="beaconId"
                      placeholder="Enter beacon id"
                      defaultValue={props.selMachine?.beaconId}
                      {...register("beaconId")}
                    />
                    {/* <span className="text-danger err-msg">
                      {errors.beacon_id?.message}
                    </span> */}
                  </div>
                </div>

                <div className="col-12 col-sm-4">
                  <div className="form-group mt-3">
                    <label className="lbl-style roboto-bold invisible">
                    {"IOT Enabled"}
                    </label>
                    <div className="form-check mt-sm-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        style={{ width: "20px", height: "20px" }}
                        onChange={() => setIOT_Flag(!iot_flag)}
                        checked={iot_flag}
                      />{" "}
                      <label className="lbl-style roboto-bold ms-sm-2">
                      {props.constLabel?.lbl_iot_enabled ? props.constLabel?.lbl_iot_enabled : "IOT Enabled"}
                    </label>
                    </div>
                    {/* <span className="text-danger err-msg">
                      {errors.beacon_id?.message}
                    </span> */}
                  </div>
                </div>

                <div className="col-12 col-sm-4">
                  <div className="form-group mt-3">
                    <label className="lbl-style roboto-bold">
                      {props.constLabel?.lbl_machine_ip_address ? props.constLabel?.lbl_machine_ip_address : "Machine IP Address"}
                      </label>
                    <input
                      className={
                        errors.machine_ip_address
                          ? "form-control mt-2 is-invalid"
                          : "form-control mt-2"
                      }
                      type="text"
                      id="machine_ip_address"
                      name="machine_ip_address"
                      placeholder="Enter machine ip address"
                      defaultValue={props.selMachine?.machine_ip_address}
                      {...register("machine_ip_address")}
                    />
                    {/* <span className="text-danger err-msg">
                      {errors.machine_ip_address?.message}
                    </span> */}
                  </div>
                </div>

                <div className="col-12 col-sm-4">
                  <div className="form-group mt-3">
                    <label className="lbl-style roboto-bold">
                      {props.constLabel?.lbl_remark ? props.constLabel?.lbl_remark : "Remark"}
                    </label>
                    <textarea
                      className="form-control mt-2"
                      type="text"
                      placeholder="Enter remark"
                      {...register("remark")}
                      defaultValue={props.selMachine?.remark}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end mt-4">
              <div>
                <button
                  onClick={() => setGalVisible(true)}
                  className="btn btn-yellow mb-3 px-3"
                >
                  Image Gallery
                  <FontAwesomeIcon
                    style={{
                      color: "#000",
                      fontSize: "16px",
                      marginLeft: "5px",
                    }}
                    icon={faImage}
                  />
                </button>
              </div>
              <div>
                <button
                  className="btn btn-grey px-3 mx-3"
                  onClick={handleSubmit(onSubmit)}
                >
                  {props.constLabel?.lbl_save ? props.constLabel?.lbl_save : "Save"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default EditMachine;
