import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import { faClose, faEye, faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation, useNavigate } from "react-router-dom";
import { Pagination } from "react-bootstrap";

import {
  calMaxPage,
  convertUrl,
  formatDateString2,
  getLabels,
} from "../../../common/Functions/CommonFunctions";
import { getProductImage, get_tool_fixture_operation } from "../../../api";

function ViewToolsFixture() {
  const location = useLocation();
  const main_obj = { prod_overview: location.state };
  const [page, setPage] = useState(1);
  const [srNo, setSrNo] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [flag, setflag] = useState(false);
  const [isGauge, setIsGauge]=useState(false);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [prod_img, setProd_Img] = useState({ data: [], loading: true });
  const [toolsfixturesoperations, settoolfixturesoperations] = useState({
    data: [],
    loading: false,
    totalRecords: "",
  });

  const [constLabel, setConstLabel] = useState({});

  const {
    register,
    handleSubmit,
    formState,
    control,
    reset,
    setValue,
    getValues,
  } = useForm({
    // resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook
  let { errors } = formState;
  let navigate = useNavigate();

  useEffect(() => {
    //

    console.log("main",main_obj)
    setConstLabel(getLabels("Master", "Product"));
    fetchProductImage();
    fetchtools_fixture_operation(1, 10);
    if (main_obj?.prod_overview?.product_type_id?.productType == "Consumable Items") {
      setflag(true);
    }

    
    if(main_obj?.prod_overview?.product_type_id?.productType == "Gauges"){
      setIsGauge(true)
    }
  }, []);

  function fetchProductImage() {
    setProd_Img({ ...prod_img, data: [], loading: true });
    const payload = {
      product_uuid: main_obj && main_obj?.prod_overview?.uuid,
    };
    getProductImage(payload).then(
      (res) => {
        setProd_Img({ ...prod_img, data: res.data.data, loading: false });
      },
      (err) => {
        setProd_Img({ ...prod_img, data: [], loading: false });
      }
    );
  }

  function fetchtools_fixture_operation(pg, epp) {
    settoolfixturesoperations({
      ...toolsfixturesoperations,
      data: [],
      loading: true,
    });
    const payload = {
      entriesPerPage: epp,
      pageNo: pg,
      tool_id: main_obj && main_obj?.prod_overview?.uuid,
    };
    get_tool_fixture_operation(payload).then(
      (res) => {
        settoolfixturesoperations({
          ...toolsfixturesoperations,
          data: res.data.data,
          loading: false,
          totalRecords: res.data.total,
        });
        setMaxPage(calMaxPage(res.data.total));
      },
      (err) => {
        settoolfixturesoperations({
          ...toolsfixturesoperations,
          data: [],
          loading: false,
        });
      }
    );
  }



  return (
    <React.Fragment>
      <div className="mb-4">
        <div className="card">
          <div className="card-header bg-grey d-flex justify-content-between align-items-center">
            <h1 className="mb-0">
            {flag ? "Consumable Item Preview" : isGauge==false? "Fixture Preview":"Gauge Preview"}
            </h1>
            <div className="cursor_pointer" onClick={() => navigate("/pcms/product")}>
              <FontAwesomeIcon
                style={{ color: "#fff" }}
                icon={faClose}
                size="xl"
              />
            </div>
          </div>
          <div className="card-body">
            <div className="pc">
              <div className="d-flex justify-content-between align-items-center">
                <p className="roboto-bold mb-0" style={{ fontSize: "20px" }}>
                {flag ? "Consumable Item Information" : isGauge==false? "Fixture Information":"Gauge Information"}
                </p>
                {/* <div>
                  <button className="btn btn-sm btn-grey" onClick={() => { previousStep(1) }}>
                    {constLabel?.lbl_edit ? constLabel?.lbl_edit : "Edit"}
                  </button>
                </div> */}
              </div>
              <hr className="mt-2" />

              <div className="">
                <div
                  className="row"
                  style={{ paddingTop: "10px", paddingBottom: "25px" }}
                >
                  <div className="col-12">
                    <div className="row">
                      <div className="col-12 col-md-12">
                        <div className="card shadow-sm rounded-0 p-2">
                          <div className="card-body">
                            <p className="pc_card_title roboto-bold">
                            {flag
                                ? constLabel?.lbl_tool_summary
                                  ? constLabel?.lbl_tool_summary
                                  : "Consumable Item Summary"
                                :  isGauge==false? constLabel?.lbl_fixture_summary
                                ? constLabel?.lbl_fixture_summary
                                : "Fixture Summary":constLabel?.lbl_gauges_summary
                                ? constLabel?.lbl_gauges_summary
                                : "Gauges Summary"}
                            </p>
                            <div className="row">
                              <div className="col-md-3 form-group mt-3">
                                <label className="lbl-style roboto-bold">
                                  {constLabel?.lbl_product_type
                                    ? constLabel?.lbl_product_type
                                    : "Product Type"}
                                </label>
                                <input
                                  className="form-control mt-2 "
                                  disabled
                                  type="text"
                                  defaultValue={
                                    main_obj?.prod_overview?.productTypename !==
                                    ""
                                      ? main_obj?.prod_overview?.productTypename
                                      : "NA"
                                  }
                                />
                              </div>
                              {/*                            
                            <div className="col-md-3 form-group mt-3">
                              <label className="lbl-style roboto-bold">
                                {constLabel?.lbl_product_name
                                  ? constLabel?.lbl_product_name
                                  : "Product Name"}
                              </label>
                              <input
                                className="form-control mt-2"
                                disabled
                                type="text"
                                defaultValue={
                                  main_obj?.prod_overview?.productName !== ""
                                    ? main_obj?.prod_overview?.productName
                                    : "NA"
                                }
                              />
                            </div> */}
                              <div className="col-md-3 form-group mt-3">
                                <label className="lbl-style roboto-bold">
                                {flag
                                    ? constLabel?.lbl_tool_name
                                      ? constLabel?.lbl_tool_name
                                      : "Consumable Item Name"
                                    :isGauge==false? constLabel?.lbl_equipment_name
                                    ? constLabel?.lbl_equipment_name
                                    : "Equipment Name":constLabel?.lbl_gauge_name
                                    ? constLabel?.lbl_gauge_name
                                    : "Gauge Name"}
                                </label>
                                <input
                                  className="form-control mt-2"
                                  disabled
                                  type="text"
                                  defaultValue={
                                    main_obj?.prod_overview?.productName !== ""
                                      ? main_obj?.prod_overview?.productName
                                      : "NA"
                                  }
                                />
                              </div>
                              <div className="col-md-3 form-group mt-3">
                                <label className="lbl-style roboto-bold">
                                {flag
                                    ? constLabel?.lbl_tool_number
                                      ? constLabel?.lbl_tool_number
                                      : "Consumable Item Number"
                                    :isGauge==false? constLabel?.lbl_equipment_number
                                    ? constLabel?.lbl_equipment_number
                                    : "Equipment Number":constLabel?.lbl_gauge_number
                                    ? constLabel?.lbl_gauge_number
                                    : "Gauge Number"}
                                </label>
                                <input
                                  className="form-control mt-2"
                                  disabled
                                  type="text"
                                  defaultValue={
                                    main_obj?.prod_overview?.toolnumber !== ""
                                      ? main_obj?.prod_overview?.toolnumber
                                      : "NA"
                                  }
                                />
                              </div>

                              {isGauge&&
                              <div className="col-md-3 form-group mt-3">
                              <label className="lbl-style roboto-bold">
                               { constLabel?.lbl_lower_size
                                    ? constLabel?.lbl_lower_size
                                    : "Lower Size"
                                 }
                              </label>
                              <input
                                className="form-control mt-2"
                                disabled
                                type="text"
                                defaultValue={
                                  main_obj?.prod_overview?.lowersize !== ""
                                    ? main_obj?.prod_overview?.lowersize
                                    : "NA"
                                }
                              />
                            </div>
                              }

                            {isGauge&&
                              <div className="col-md-3 form-group mt-3">
                              <label className="lbl-style roboto-bold">
                               { constLabel?.lbl_upper_size
                                    ? constLabel?.lbl_upper_size
                                    : "Upper Size"
                                 }
                              </label>
                              <input
                                className="form-control mt-2"
                                disabled
                                type="text"
                                defaultValue={
                                  main_obj?.prod_overview?.uppersize !== ""
                                    ? main_obj?.prod_overview?.uppersize
                                    : "NA"
                                }
                              />
                            </div>
                              }
                              <div className="col-md-3 form-group mt-3">
                                <label className="lbl-style roboto-bold">
                                {flag
                                    ? constLabel?.lbl_tool_type
                                      ? constLabel?.lbl_tool_type
                                      : "Consumable Item Type"
                                    :isGauge==false? constLabel?.lbl_equipment_type
                                    ? constLabel?.lbl_equipment_type
                                    : "Equipment Type":constLabel?.lbl_gauge_type
                                    ? constLabel?.lbl_gauge_type
                                    : "Guage Type"}
                                </label>
                                <input
                                  className="form-control mt-2"
                                  disabled
                                  type="text"
                                  defaultValue={
                                    main_obj?.prod_overview?.tool_family_id
                                      .name !== ""
                                      ? main_obj?.prod_overview?.tool_family_id
                                          .name
                                      : "NA"
                                  }
                                />
                              </div>
                              <div className="col-md-3 form-group mt-3">
                                <label className="lbl-style roboto-bold">
                                {flag
                                    ? constLabel?.lbl_tool_type_code
                                      ? constLabel?.lbl_tool_type_code
                                      : "Consumable Item Type Code"
                                    :isGauge==false? constLabel?.lbl_equipment_type_code
                                    ? constLabel?.lbl_equipment_type_code
                                    : "Equipment Type Code":constLabel?.lbl_gauge_type_code
                                    ? constLabel?.lbl_gauge_type_code
                                    : "Gauge Type Code"}
                                </label>
                                <input
                                  className="form-control mt-2"
                                  disabled
                                  type="text"
                                  defaultValue={
                                    main_obj?.prod_overview?.tool_family_id
                                      ?.tool_family_code !== ""
                                      ? main_obj?.prod_overview?.tool_family_id
                                          ?.tool_family_code
                                      : "NA"
                                  }
                                />
                              </div>
                              <div className="col-md-3 form-group mt-3">
                                <label className="lbl-style roboto-bold">
                                {flag
                                    ? constLabel?.lbl_tool_sub_type
                                      ? constLabel?.lbl_tool_sub_type
                                      : "Consumable Item Sub-Type"
                                    :isGauge==false? constLabel?.lbl_equipment_sub_type
                                    ? constLabel?.lbl_equipment_sub_type
                                    : "Equipment Sub Type":constLabel?.lbl_gauge_sub_type
                                    ? constLabel?.lbl_gauge_sub_type
                                    : "Gauge Sub Type"}
                                </label>
                                <input
                                  className="form-control mt-2"
                                  disabled
                                  type="text"
                                  defaultValue={
                                    main_obj?.prod_overview?.tool_sub_family_id
                                      ?.name !== ""
                                      ? main_obj?.prod_overview
                                          ?.tool_sub_family_id?.name
                                      : "NA"
                                  }
                                />
                              </div>
                              <div className="col-md-3 form-group mt-3">
                                <label className="lbl-style roboto-bold">
                                {flag
                                    ? constLabel?.lbl_tool_sub_type_code
                                      ? constLabel?.lbl_tool_sub_type_code
                                      : "Consumable Item Sub Type Code"
                                    :isGauge==false? constLabel?.lbl_tool_sub_type_code
                                    ? constLabel?.lbl_tool_sub_type_code
                                    : "Equipment Sub Type Code":constLabel?.lbl_gauge_sub_type_code
                                    ? constLabel?.lbl_gauge_sub_type_code
                                    : "Gauge Sub Type Code"}
                                </label>
                                <input
                                  className="form-control mt-2"
                                  disabled
                                  type="text"
                                  defaultValue={
                                    main_obj?.prod_overview?.tool_sub_family_id
                                      ?.tool_sub_family_code !== ""
                                      ? main_obj?.prod_overview
                                          ?.tool_sub_family_id
                                          ?.tool_sub_family_code
                                      : "NA"
                                  }
                                />
                              </div>
                              {flag && (
                                <div className="col-md-3 form-group mt-3">
                                  <label className="lbl-style roboto-bold">
                                    {constLabel?.lbl_tool_grade
                                      ? constLabel?.lbl_tool_grade
                                      : "Consumable Item Grade"}
                                  </label>
                                  <input
                                    className="form-control mt-2"
                                    disabled
                                    type="text"
                                    defaultValue={
                                      main_obj?.prod_overview?.tool_grade_id
                                        ?.gradename !== ""
                                        ? main_obj?.prod_overview?.tool_grade_id
                                            ?.gradename
                                        : "NA"
                                    }
                                  />
                                </div>
                              )}
                              <div className="col-md-3 form-group mt-3">
                                <label className="lbl-style roboto-bold">
                                {flag
                                    ? constLabel?.lbl_calibration_frequency
                                      ? constLabel?.lbl_calibration_frequency
                                      : "Calibration Frequency"
                                    :isGauge==false? constLabel?.lbl_verification_frequency
                                    ? constLabel?.lbl_verification_frequency
                                    : "Verification Frequency":constLabel?.lbl_calibration_frequency
                                    ? constLabel?.lbl_calibration_frequency
                                    : "Calibration Frequency"}
                                </label>
                                <input
                                  className="form-control mt-2"
                                  disabled
                                  type="text"
                                  defaultValue={
                                    main_obj?.prod_overview
                                      ?.calibration_frequency !== ""
                                      ? main_obj?.prod_overview
                                          ?.calibration_frequency
                                      : "NA"
                                  }
                                />
                              </div>
                              <div className="col-md-3 form-group mt-3">
                                <label className="lbl-style roboto-bold">
                                {flag
                                    ? constLabel?.lbl_calibration_validity
                                      ? constLabel?.lbl_calibration_validity
                                      : "Calibration Validity"
                                    :isGauge==false? constLabel?.lbl_verification_frequency
                                    ? constLabel?.lbl_verification_frequency
                                    : "Verification Validity":constLabel?.lbl_calibration_validity
                                    ? constLabel?.lbl_calibration_validity
                                    : "Calibration Validity"}
                                </label>
                                <input
                                  className="form-control mt-2"
                                  disabled
                                  type="text"
                                  defaultValue={
                                    main_obj?.prod_overview
                                      ?.calibration_validity !== ""
                                      ? main_obj?.prod_overview
                                          ?.calibration_validity
                                      : "NA"
                                  }
                                />
                              </div>
                              <div className="col-md-3 form-group mt-3">
                                <label className="lbl-style roboto-bold">
                                {flag
                                    ? constLabel?.lbl_calibration_date
                                      ? constLabel?.lbl_calibration_date
                                      : "Calibration Date"
                                    :isGauge==false? constLabel?.lbl_verification_date
                                    ? constLabel?.lbl_verification_date
                                    : "Verification Date":constLabel?.lbl_calibration_date
                                    ? constLabel?.lbl_calibration_date
                                    : "Calibration Date"}
                                </label>
                                <input
                                  className="form-control mt-2"
                                  disabled
                                  type="text"
                                  defaultValue={
                                    main_obj?.prod_overview
                                      ?.calibration_date !== ""
                                      ? formatDateString2(
                                          main_obj?.prod_overview
                                            ?.calibration_date
                                        )
                                      : "NA"
                                  }
                                />
                              </div>
                              <div className="col-md-3 form-group mt-3">
                                <label className="lbl-style roboto-bold">
                                {flag
                                    ? constLabel?.lbl_calibration_due_date
                                      ? constLabel?.lbl_calibration_due_date
                                      : "Calibration Due Date"
                                    :isGauge==false? constLabel?.lbl_verification_due_date
                                    ? constLabel?.lbl_verification_due_date
                                    : "Verification Due Date":constLabel?.lbl_calibration_due_date
                                    ? constLabel?.lbl_calibration_due_date
                                    : "Calibration Due Date"}
                                </label>
                                <input
                                  className="form-control mt-2"
                                  disabled
                                  type="text"
                                  defaultValue={
                                    main_obj?.prod_overview
                                      ?.calibration_duedate !== ""
                                      ? formatDateString2(
                                          main_obj?.prod_overview
                                            ?.calibration_duedate
                                        )
                                      : "NA"
                                  }
                                />
                              </div>

                              <div className="col-md-3 form-group mt-3">
                                <label className="lbl-style roboto-bold">
                                {constLabel?.lbl_product_category
                                      ? constLabel?.lbl_product_category
                                      : "Product Category"}
                                </label>
                                <input
                                  className="form-control mt-2"
                                  disabled
                                  type="text"
                                  defaultValue={
                                    main_obj?.prod_overview
                                      ?.category_id?.categoryname !== ""
                                      ? 
                                          main_obj?.prod_overview
                                            ?.category_id?.categoryname
                                      : "NA"
                                  }
                                />
                              </div>

                              <div className="col-md-3 form-group mt-3">
                                <label className="lbl-style roboto-bold">
                                {constLabel?.lbl_market_group
                                      ? constLabel?.lbl_market_group
                                      : "Market group"}
                                </label>
                                <input
                                  className="form-control mt-2"
                                  disabled
                                  type="text"
                                  defaultValue={
                                    main_obj?.prod_overview
                                      ?.market_grp_id?.groupname !== ""
                                      ? 
                                          main_obj?.prod_overview
                                            ?.market_grp_id?.groupname
                                      : "NA"
                                  }
                                />
                              </div>

                              <div className="col-md-3 form-group mt-3">
                                <label className="lbl-style roboto-bold">
                                {constLabel?.lbl_product_family
                                      ? constLabel?.lbl_product_family
                                      : "Product Family"}
                                </label>
                                <input
                                  className="form-control mt-2"
                                  disabled
                                  type="text"
                                  defaultValue={
                                    main_obj?.prod_overview
                                      ?.prod_family_id?.familyname !== ""
                                      ? 
                                          main_obj?.prod_overview
                                            ?.prod_family_id?.familyname
                                      : "NA"
                                  }
                                />
                              </div>

                              <div className="col-md-3 form-group mt-3">
                                <label className="lbl-style roboto-bold">
                                {constLabel?.lbl_opening_stock
                                      ? constLabel?.lbl_opening_stock
                                      : "Opening Stock"}
                                </label>
                                <input
                                  className="form-control mt-2"
                                  disabled
                                  type="text"
                                  defaultValue={
                                    main_obj?.prod_overview
                                      ?.openingstock !== ""
                                      ? 
                                          main_obj?.prod_overview
                                            ?.openingstock
                                      : "NA"
                                  }
                                />
                              </div>

                              <div className="col-md-3 form-group mt-3">
                                <label className="lbl-style roboto-bold">
                                {constLabel?.lbl_hsn
                                      ? constLabel?.lbl_hsn
                                      : "HSN"}
                                </label>
                                <input
                                  className="form-control mt-2"
                                  disabled
                                  type="text"
                                  defaultValue={
                                    main_obj?.prod_overview
                                      ?.hsn_uuid?.hsn_sac_code !== ""
                                      ? 
                                          main_obj?.prod_overview
                                            ?.hsn_uuid?.hsn_sac_code
                                      : "NA"
                                  }
                                />
                              </div>

                              <div className="col-md-3 form-group mt-3">
                                <label className="lbl-style roboto-bold">
                                {constLabel?.lbl_unit
                                      ? constLabel?.lbl_unit
                                      : "Unit"}
                                </label>
                                <input
                                  className="form-control mt-2"
                                  disabled
                                  type="text"
                                  defaultValue={
                                    main_obj?.prod_overview
                                      ?.unit_id?.uom !== ""
                                      ? 
                                          main_obj?.prod_overview
                                            ?.unit_id?.uom
                                      : "NA"
                                  }
                                />
                              </div>

                              <div className="col-md-3 form-group mt-3">
                                <label className="lbl-style roboto-bold">
                                {constLabel?.lbl_oem_part_no
                                      ? constLabel?.lbl_oem_part_no
                                      : "OEM Part No"}
                                </label>
                                <input
                                  className="form-control mt-2"
                                  disabled
                                  type="text"
                                  defaultValue={
                                    main_obj?.prod_overview
                                      ?.OEMnumber !== ""
                                      ? 
                                          main_obj?.prod_overview
                                            ?.OEMnumber
                                      : "NA"
                                  }
                                />
                              </div>

                            </div>
                            <hr />
                            <p className="pc_card_title roboto-bold">
                            {constLabel?.lbl_product_detail
                                  ? constLabel?.lbl_product_detail
                                  : "Product Detail"}
                            </p>
                            <div className="row">
                              <div className="col-md-3 form-group mt-3">
                                <label className="lbl-style roboto-bold invisible">
                                  {constLabel?.lbl_can_be_sold
                                    ? constLabel?.lbl_can_be_sold
                                    : "Can be Sold"}
                                </label>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    defaultChecked={
                                      main_obj?.prod_overview?.can_be_sold 
                                        ? main_obj?.prod_overview?.can_be_sold
                                        : false
                                    }
                                    disabled
                                  />
                                  <label className="form-check-label lbl-style roboto-bold">
                                    {constLabel?.lbl_can_be_sold ? constLabel?.lbl_can_be_sold : "Can be Sold"}
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-3 form-group mt-3">
                                <label className="lbl-style roboto-bold invisible">
                                  {constLabel?.lbl_can_be_pruchase
                                    ? constLabel?.lbl_can_be_pruchase
                                    : "Can be Purchased"}
                                </label>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    defaultChecked={
                                      main_obj?.prod_overview?.can_be_purchased 
                                        ? main_obj?.prod_overview?.can_be_purchased
                                        : false
                                    }
                                    disabled
                                  />
                                  <label className="form-check-label lbl-style roboto-bold">
                                    {constLabel?.lbl_can_be_pruchase ? constLabel?.lbl_can_be_pruchase : "Can be Purchased"}
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-3 form-group mt-3">
                                <label className="lbl-style roboto-bold invisible">
                                  {constLabel?.lbl_batch_tracking
                                    ? constLabel?.lbl_batch_tracking
                                    : "Batch Tracking"}
                                </label>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    defaultChecked={
                                      main_obj?.prod_overview?.batch_tracking 
                                        ? main_obj?.prod_overview?.batch_tracking
                                        : false
                                    }
                                    disabled
                                  />
                                  <label className="form-check-label lbl-style roboto-bold">
                                    {constLabel?.lbl_batch_tracking ? constLabel?.lbl_batch_tracking : "Batch Tracking"}
                                  </label>
                                </div>
                              </div>
                            </div>
                            <hr />
                            <p className="pc_card_title roboto-bold">
                            {constLabel?.lbl_product_price
                                  ? constLabel?.lbl_product_price
                                  : "Product Price"}
                            </p>
                            <div className="row">
                              <div className="col-md-3 form-group mt-3">
                                <label className="lbl-style roboto-bold">
                                {constLabel?.lbl_cost_of_mfg ? constLabel?.lbl_cost_of_mfg : "Cost of Manufacturing"}
                                </label>
                                <input
                                  className="form-control mt-2 "
                                  disabled
                                  type="text"
                                  defaultValue={
                                    main_obj?.prod_overview?.machineCost !==
                                    ""
                                      ? main_obj?.prod_overview?.machineCost
                                      : "NA"
                                  }
                                />
                              </div>
                              <div className="col-md-3 form-group mt-3">
                                <label className="lbl-style roboto-bold">
                                {constLabel?.lbl_selling_price
                                  ? constLabel?.lbl_selling_price
                                  : "Selling Price "}
                                </label>
                                <input
                                  className="form-control mt-2 "
                                  disabled
                                  type="text"
                                  defaultValue={
                                    main_obj?.prod_overview?.salesPrice !==
                                    ""
                                      ? main_obj?.prod_overview?.salesPrice
                                      : "NA"
                                  }
                                />
                              </div>
                              <div className="col-md-3 form-group mt-3">
                                <label className="lbl-style roboto-bold">
                                {constLabel?.lbl_procurement_price
                                  ? constLabel?.lbl_procurement_price
                                  : "Procurement Price "}
                                </label>
                                <input
                                  className="form-control mt-2 "
                                  disabled
                                  type="text"
                                  defaultValue={
                                    main_obj?.prod_overview?.purchase_price !==
                                    ""
                                      ? main_obj?.prod_overview?.purchase_price
                                      : "NA"
                                  }
                                />
                              </div>
                              <div className="col-md-3 form-group mt-3">
                                <label className="lbl-style roboto-bold">
                                  {constLabel?.lbl_remark
                                    ? constLabel?.lbl_remark
                                    : "Remark"}
                                </label>
                                <textarea
                                  className="form-control mt-2 "
                                  disabled
                                  type="text"
                                  defaultValue={
                                    main_obj?.prod_overview?.remark !==
                                    ""
                                      ? main_obj?.prod_overview?.remark
                                      : "NA"
                                  }
                                ></textarea>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <hr className="mb-2" />
                <div className="d-flex justify-content-between align-items-center">
                  <p className="roboto-bold mb-0" style={{ fontSize: "20px" }}>
                    {flag ? "Consumable Item Images" :isGauge==false? "Fixtures Images":"Gauges Images"}
                  </p>
                  {/* <div>
                    <button className="btn btn-sm btn-grey" onClick={() => { previousStep(2) }}>
                      {constLabel?.lbl_edit ? constLabel?.lbl_edit : "Edit"}
                    </button>
                  </div> */}
                </div>
                <hr className="mt-2" />

                <div
                  className="row image_gallery2 "
                  style={{ paddingTop: "10px", paddingBottom: "25px" }}
                >
                  <div className="col-12">
                    <div className="card shadow-sm rounded-0">
                      <div className="card-body mx-4">
                        {/* <Slider {...settings}> */}
                        <div className="row">
                          {prod_img.data !== undefined &&
                          prod_img.data.length > 0 ? (
                            prod_img.data.map((imgSrc, index) => (
                              <div className="col-12 col-md-3" key={index}>
                                <div className="image-container">
                                  <img
                                    src={
                                      process.env.REACT_APP_IMAGE_URL +
                                      imgSrc.attachment
                                    }
                                    className="img-fluid"
                                    alt="Image 1"
                                  />

                                  <div className="image-overlay">
                                    <FontAwesomeIcon
                                      onClick={() =>
                                        window.open(
                                          process.env.REACT_APP_IMAGE_URL +
                                            imgSrc.attachment,
                                          "_blank"
                                        )
                                      }
                                      className="icon"
                                      style={{ color: "#fff" }}
                                      icon={faEye}
                                      size="xl"
                                    />

                                    {/* <FontAwesomeIcon
                                className="icon-close"
                                style={{ color: "#fff" }}
                                icon={faClose}
                                size="lg"
                                /> */}

                                    <span className="image-name cursor_pointer">
                                      {imgSrc.name == null ? "NA" : imgSrc.name}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ))
                          ) : (
                            <div
                              className="alert alert-danger mb-0 text-center my-4"
                              role="alert"
                            >
                                {flag
                                ? "No consumable item images found !"
                                :isGauge==false? "No fixture images found": "No gauges images found"}
                            </div>
                          )}
                        </div>

                        {/* </Slider> */}
                      </div>
                    </div>
                  </div>
                </div>

                <hr className="mt-2" />

                <div className="d-flex justify-content-between align-items-center">
                  <p className="roboto-bold mb-0" style={{ fontSize: "20px" }}>
                  {flag ? "Consumable Item Operation" :isGauge==false? "Fixture Operation":"Gauges Operation"}
                  </p>
                  {/* <div>
                    <button className="btn btn-sm btn-grey" onClick={() => { previousStep(3) }}>
                      {constLabel?.lbl_edit ? constLabel?.lbl_edit : "Edit"}
                    </button>
                  </div> */}
                </div>
                <hr className="mt-2" />
                {toolsfixturesoperations.loading ? (
                  <div className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : toolsfixturesoperations.data?.length > 0 ? (
                  <div
                    className="row"
                    style={{ paddingTop: "10px", paddingBottom: "25px" }}
                  >
                    <div className="col-12">
                      <div className="table-responsive pc">
                        <table className="table my-3 table-bordered">
                          <thead className="table-grey roboto">
                            <tr>
                              <th>{"Sr.No"}</th>
                              <th>
                                {constLabel?.lbl_sequence_no
                                  ? constLabel?.lbl_sequence_no
                                  : "Sequence No"}
                              </th>
                              <th>
                                {constLabel?.lbl_operation_name
                                  ? constLabel?.lbl_operation_name
                                  : "Operation Name"}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {toolsfixturesoperations.data.map((val, ind) => (
                              <React.Fragment key={ind}>
                                <tr>
                                  <td>{parseInt(ind) + 1 + parseInt(srNo)}</td>
                                  <td>{ind + 1}</td>
                                  <td>{val.operation_name}</td>
                                </tr>
                              </React.Fragment>
                            ))}
                          </tbody>
                        </table>

                        {/* {toolsfixturesoperations.loading ? null : toolsfixturesoperations.data?.length > 0 ? ( */}
                        <div className="mt-4 d-flex justify-content-between">
                          <p className="mb-0">{`Showing ${
                            parseInt(srNo) + 1
                          } to ${
                            toolsfixturesoperations.data.length +
                            entriesPerPage * page -
                            entriesPerPage
                          } of ${
                            toolsfixturesoperations.totalRecords
                          } entries`}</p>
                          <Pagination className="my-2">
                            <Pagination.Prev
                              disabled={page === 1 ? true : false}
                              onClick={() => {
                                fetchtools_fixture_operation(
                                  page - 1,
                                  entriesPerPage,
                                  parseInt(srNo) - parseInt(entriesPerPage)
                                );
                                setSrNo((prevC) =>
                                  page - 1 == 1
                                    ? 0
                                    : prevC - parseInt(entriesPerPage)
                                );
                                setPage(page - 1);
                              }}
                            >
                              {"Prev"}
                            </Pagination.Prev>

                            <Pagination.Item active>{page}</Pagination.Item>

                            <Pagination.Next
                              disabled={
                                page === maxPage ||
                                maxPage === 0 ||
                                entriesPerPage >
                                  toolsfixturesoperations.data.length
                                  ? true
                                  : false
                              }
                              onClick={() => {
                                fetchtools_fixture_operation(
                                  page + 1,
                                  entriesPerPage,
                                  parseInt(srNo) + parseInt(entriesPerPage)
                                );
                                setSrNo(
                                  (prevC) => prevC + parseInt(entriesPerPage)
                                );
                                setPage(page + 1);
                              }}
                            >
                              {"Next"}
                            </Pagination.Next>
                          </Pagination>
                        </div>
                        {/* ) : null} */}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className="alert alert-danger mb-0 text-center my-4"
                    role="alert"
                  >
                  {flag
                      ? "No consumable item operation found !"
                      : isGauge==false? "No fixture opration found !":"No gauges opration found !"}
                  </div>
                )}
              </div>

              {/* <hr className="my-2" />
              <div className="d-flex justify-content-end">
                <button
                    className="btn btn-grey px-4 me-2"
                    onClick={() => previousStep()}
                >
                    {constLabel?.lbl_previous ? constLabel?.lbl_previous : "Previous"}
                </button>
                <button
                  className="btn btn-lightblue px-4 me-2"
                  onClick={() => navigate("/pcms/product")}
                >
                  {constLabel?.lbl_close ? constLabel?.lbl_close : "Close"}
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ViewToolsFixture;
