import React, { useState, useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  del_master_file,
  get_city,
  get_country,
  get_state,
  getCustomer,
  getCustomerType,
  saveCustomer,
  updateCustomer,
} from "../../../api";
import { ToastContainer, toast } from "react-toastify";
import DatePicker from "react-datepicker";
import { convertDateYYMMDD } from "../../../common/Functions/CommonFunctions";
import axios from "axios";
import { s3_image_upload } from "../../../constant";
import m_front from "../../../assets/images/fashion/male_front.jpg";
import m_back from "../../../assets/images/fashion/male_back.jpg";
import m_side from "../../../assets/images/fashion/male_side.jpg";
import w_front from "../../../assets/images/fashion/lady_front_view.png";
import w_back from "../../../assets/images/fashion/lady_back_view.png";
import w_side from "../../../assets/images/fashion/lady_side_view.png";

function Add_Profile(props) {
  const { visible, setVisible, selectedCustomer, fetchImage, attach_files } = props;
  const [constLabel, setConstLabel] = useState({});
  const [imgArr, setImgArr] = useState([]);
  const [lockBtn, setLockBtn] = useState(false);

  const localData = JSON.parse(localStorage.getItem("localData"));

  const { register, handleSubmit, formState, reset, watch, control, setValue } =
    useForm({
      mode: "onChange",
    });

  let { errors } = formState;

  function image_upload(e, viewType) {
    const file = e.target.files[0];

    if (file) {
      setImgArr((prevImgArr) => {
        const updatedImgArr = prevImgArr.filter(
          (img) => img.viewType !== viewType
        );
        return [...updatedImgArr, { file, viewType }];
      });

      // Clear file input field
      e.target.value = "";
    }
  }

  const frontImage = imgArr.find((itm) => itm.viewType === "front");
  const backImage = imgArr.find((itm) => itm.viewType === "back");
  const sideImage = imgArr.find((itm) => itm.viewType === "side");

  const getImageElement = (image, defaultSrc) => {
    if (image) {
      const fileURL = URL.createObjectURL(image.file);
      return (
        <div className="image-container text-center">
          <a href={fileURL} target="_blank" rel="noopener noreferrer">
            <img
              src={fileURL}
              className="img-fluid"
              style={{ height: "200px" }}
              alt={image.file?.name || "Image"}
            />
          </a>
        </div>
      );
    }
    
    return (
      <img
        src={defaultSrc}
        className="img-fluid"
        style={{ height: "200px" }}
        alt="Default Image"
      />
    );
  };

  const onSubmit = (data) => {
    setLockBtn(true);
    let file_arr = [];
    const orderedViewTypes = ["front", "back", "side"];
    orderedViewTypes.forEach((viewType) => {
      const img = imgArr.find((itm) => itm.viewType === viewType);
      if (img) {
        const sanitizedFileName = img?.file?.name?.replace(
          /[^a-zA-Z0-9.]+/g,
          ""
        );

        file_arr.push({
          fileName: `${viewType}${sanitizedFileName}`,
          contentType: img?.file?.type,
          model: {
            ref_model_uuid: selectedCustomer.uuid,
            model_name: "Customer",
            name: `${viewType}${sanitizedFileName}`,
            document_type: img?.file?.type.startsWith("image/")
              ? "image"
              : "image",
            created_by_user: localData?.user_id,
            client_id: localData?.client_id,
          },
        });
      }
    });

    const payload = {
      files: file_arr,
    };

    if (imgArr.length > 0) {
      const url = process.env.REACT_APP_COGNITO_URL + s3_image_upload;
      const idToken = localStorage.getItem("id");

      // Code START => delete existing image before upload new
      try {
        if (attach_files.data?.length > 0 && imgArr?.length > 0) {
          imgArr.forEach(({ viewType }) => {
            if (viewType) {
              const existingImage = attach_files.data.find((file) =>
                file.name.startsWith(viewType)
              );
      
              if (existingImage) {
                // Call delete API for the old image after successful upload
                del_master_file({ uuid: existingImage.uuid });
              }
            }
          });
        }
      } catch (error) {
        console.error("Error while processing file deletion:", error);
      }
      // Code END => delete existing image before upload new

      axios
        .post(url, payload, {
          headers: {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
          },
        })
        .then(
          (res) => {
            // console.log("res", res);
            for (let x = 0; x < res.data.data?.body?.length; x++) {
              const url2 = res.data.data?.body?.[x]?.url;

              axios
                .put(url2, imgArr[x]?.file, {
                  headers: {
                    "Content-Type": imgArr[x]?.file?.type,
                  },
                })
                .then(
                  (ress) => {

                    toast.success("Images uploaded successfully !", {
                      autoClose: 3000,
                    });

                    setTimeout(() => {
                      setVisible(false);
                      setImgArr([]);
                      fetchImage(selectedCustomer.uuid);
                    }, 3500);
                    setLockBtn(false);
                  },
                  (errr) => {
                    console.log("errr", errr);
                    if (errr.response.status == 413) {
                      toast.error("Image upload failed !", {
                        autoClose: 3000,
                      });
                    }
                    setLockBtn(false);
                  }
                );
            }
          },
          (err) => {
            console.log("err", err);
          }
        );
    } else {
      setLockBtn(false);
    }
  };

  return (
    <React.Fragment>
      <Modal
        show={visible}
        onHide={() => {
          setVisible(false);
        }}
        size="lg"
        backdrop="static"
      >
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>
            {constLabel?.lbl_add_profile
              ? constLabel.lbl_add_profile
              : "Add Profile"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action="">
            <div className="row mt-2">
              <div className="col-12 col-md-4">
                <label
                  style={{ fontSize: "14px" }}
                  className="col-form-label roboto-bold"
                >
                  {constLabel?.lbl_front ? constLabel.lbl_front : "Front"}
                </label>
                <input
                  className="form-control form-control-sm"
                  type="file"
                  {...register("imageFront")}
                  onChange={(e) => image_upload(e, "front")}
                  accept="image/*"
                />
              </div>
              <div className="col-12 col-md-4">
                <label
                  style={{ fontSize: "14px" }}
                  className="col-form-label roboto-bold"
                >
                  {constLabel?.lbl_back ? constLabel.lbl_back : "Back"}
                </label>
                <input
                  className="form-control form-control-sm"
                  type="file"
                  {...register("imageBack")}
                  onChange={(e) => image_upload(e, "back")}
                  accept="image/*"
                />
              </div>
              <div className="col-12 col-md-4">
                <label
                  style={{ fontSize: "14px" }}
                  className="col-form-label roboto-bold"
                >
                  {constLabel?.lbl_side ? constLabel.lbl_side : "Side"}
                </label>
                <input
                  className={"form-control form-control-sm"}
                  type="file"
                  {...register("imageSide")}
                  onChange={(e) => image_upload(e, "side")}
                  accept="image/*"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-4">
                <div className="d-flex justify-content-center mt-3">
                  {getImageElement(
                    frontImage,
                    selectedCustomer && selectedCustomer.gender == "Male"
                      ? m_front
                      : w_front
                  )}
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="d-flex justify-content-center mt-3">
                  {getImageElement(
                    backImage,
                    selectedCustomer && selectedCustomer.gender == "Male"
                      ? m_back
                      : w_back
                  )}
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="d-flex justify-content-center mt-3">
                  {getImageElement(
                    sideImage,
                    selectedCustomer && selectedCustomer.gender == "Male"
                      ? m_side
                      : w_side
                  )}
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-grey px-4"
            onClick={handleSubmit(onSubmit)}
            disabled={lockBtn}
          >
            {constLabel?.lbl_save ? constLabel.lbl_save : "Save"}{" "}
            {lockBtn ? <Spinner animation="border" size="sm" /> : null}{" "}
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default Add_Profile;
