// features/employeeSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import client2 from "../../constant/NodeAPI_AxiosInstance";
import { operatorList_availability } from "../../constant/index"
import axios from "axios"

// Fetch employees with job cards
export const fetchEmployees = createAsyncThunk("employees/availability", async (params) => {
    try {
        const res = await client2.get(operatorList_availability, { params: params });
        res.data.data.map((val, ind) => {
            val.FullName = val.FirstName + ' ' + val.LastName;

            if (ind == 0) {
                val.selected = true;
            }
            
            val.selected_emp = [];
            val.selected_startdate = new Date().setHours(9, 0, 0, 0); // null

            val.selected_enddate = new Date().setHours(18, 0, 0, 0);   // null
        })
        return res.data.data;

    } catch (error) {
        return []
    }
});

// Manage job cards for an employee
export const createJobCard = createAsyncThunk(
    "employees/createJobCard",
    async (jobCardData) => {
        const response = await axios.post("/api/job-cards", jobCardData);
        return response.data; // Assume response includes updated employee job card data
    }
);

export const deleteJobCard = createAsyncThunk("employees/deleteJobCard", async (jobCardId) => {
    await axios.delete(`/api/job-cards/${jobCardId}`);
    return jobCardId; // Return deleted job card ID
});

const employeeSlice = createSlice({
    name: "employees",
    initialState: {
        emp_list: [],
        status: "idle",
        error: null,
    },
    reducers: {
        reset_state(state, action) {
            state.emp_list = [];
            state.status = "idle";
            state.error = null;
        },
        manageSelectedEmp(state, action) {
            state.status = "loading";
            console.log("action.payload", action.payload);
            const selectedEmpList = action.payload.map((val) => val.uuid);
            console.log("selectedEmpList", selectedEmpList);

            state.emp_list.forEach((val) => {
                if (selectedEmpList.includes(val.uuid)) {
                    val.selected = true;
                } else {
                    val.selected = false;
                }
            });
            state.status = "idle";
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchEmployees.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchEmployees.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.emp_list = action.payload;
            })
            .addCase(fetchEmployees.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            })
            .addCase(createJobCard.fulfilled, (state, action) => {
                const updatedEmployee = action.payload;
                const index = state.emp_list.findIndex((emp) => emp.id === updatedEmployee.id);
                if (index >= 0) state.emp_list[index] = updatedEmployee;
            })
            .addCase(deleteJobCard.fulfilled, (state, action) => {
                const jobCardId = action.payload;
                state.emp_list.forEach((employee) => {
                    employee.jobCards = employee.jobCards.filter((jobCard) => jobCard.id !== jobCardId);
                });
            });
    },
});
export const { reset_state, manageSelectedEmp } = employeeSlice.actions;
export default employeeSlice.reducer;
