import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faImage,
  faAdd,
  faCircle,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import SweetAlert from "sweetalert2";
import { Trash2 } from "react-feather";
import { Typeahead } from "react-bootstrap-typeahead";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import DatePicker from "react-datepicker";
import {
  getUOM,
  getProdList,
  getProductType,
  getInternalCompany,
  getGST,
  getQuotation,
  master_multi_file_upload,
  getCustomer,
  getSOStatus,
  createSalesOrder,
} from "../../api/index";
import {
  convertBase64,
  convertDateYYMMDD,
  getLabels,
} from "../../common/Functions/CommonFunctions";
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AddProduct from "../Masters/AddProducts/AddProduct";
import SOImageGallary from "./SOImageGallary";
import { Tooltip } from "react-tooltip";
import AddCustomer from "../Masters/AddCustomer/AddCustomer";
import axios from 'axios';
import { s3_image_upload } from "../../constant";
import { Spinner } from "react-bootstrap";
import { screenList } from "../../common/Labels/screenList";

const schema = yup
  .object()
  .shape({
    organization: yup
      .array()
      .min(1, "Please select organization !")
      .required("Please select organization !"),
    customer: yup
      .array()
      .min(1, "Please select customer !")
      .required("Please select customer !"),
    invoiceTo: yup
      .array()
      .min(1, "Please select company !")
      .required("Please select company !"),
    dispatchTo: yup
      .array()
      .min(1, "Please select company !")
      .required("Please select company !"),
    // party_po_ref: yup.string().required("Customer po reference is required !"),
    party_po_ref: yup.string().default("0").when(["customer"], (customer, schema) => {
      if (customer !== undefined && customer.length > 0) {
        const cust_type = customer[0].customerType !== "Retail";
        if (cust_type) {
          return schema.test(
            "is-required",
            "Customer po reference is required!",
            (value) => value !== "" && value !== null // Validation logic
          );
        }
      }
      return schema;
    }),
    status: yup
      .array()
      .min(1, "Please select status !")
      .required("Please select status !"),
    // remarks: yup
    //   .string()
    //   .required("Please enter remarks !"),
    internalRemarks: yup.string().required("Internal remark is required"),
    order_date: yup
      .date()
      .required("Please select order date!")
      .typeError("Please select order date!")
      .test(
          "Order_date-not-greater-than-order_due_date",
          "Order date cannot be after order due date!",
          function (value) {
              const { order_due_date } = this.parent;
              // Skip validation if delivery_date is not selected
              if (!order_due_date || !value) return true;
              return value <= order_due_date;
          }),
    order_due_date: yup
      .date()
      .required("Please select order due date!")
      .typeError("Please select order due date!")
      .test(
          "order_due_date-not-before-order_date",
          "Order due date should be greater than order date",
          function (value) {
              const { order_date } = this.parent;
              // Skip validation if plan_date is not selected
              if (!order_date || !value) return true;
              return value >= order_date;
          }),
    items: yup.array().of(
      yup.object().shape({
        prod_type: yup
          .array()
          .min(1, "Please select product type !")
          .required("Please select product type !"),
        product: yup
          .array()
          .min(1, "Please select product !")
          .required("Please select product !"),
        quantity: yup
          .string()
          .required("Quantity is required")
          .matches(/^[0-9]+(\.[0-9]+)?$/, "Enter valid quantity")
          .typeError("Enter valid quantity"),
        unit: yup
          .array()
          .min(1, "Please select unit !")
          .required("Please select unit !"),
        price: yup
          .string()
          .required("Price is required")
          .matches(/^[0-9]+(\.[0-9]+)?$/, "Enter valid price")
          .typeError("Enter valid price"),
        gst_rate: yup
          .string()
          .required("gst rate  is required !")
          .matches(/^[0-9]+(\.[0-9]+)?$/, "Enter gst rate !")
          .typeError("Enter gst rate !"),
        gst_amount: yup
          .string()
          .notRequired()
          .matches(/^[0-9]+(\.[0-9]+)?$/, "Enter gst amount !")
          .typeError("gst amount is invalid !"),
        discount: yup
          .string()
          .required("Discount is required !")
          .matches(/^[0-9]+(\.[0-9]+)?$/, "Enter discount !")
          .typeError("Enter discount !"),
        hsn_code: yup.string().notRequired(),
        amount: yup
          .string()
          .required("Amount is required !")
          .matches(/^[0-9]+(\.[0-9]+)?$/, "Enter valid amount !"),
      })
    ),
    // discount: yup
    //   .string()
    //   .matches(/^\d+(\.\d+)?$/, "Enter valid discount")
    //   .required("Discount is required"),
  })
  .required();

function CreateSalesOrder() {
  let navigate = useNavigate();
  let location = useLocation();
  let urlParams = useParams();

  const [customerList, setCustomerList] = useState({
    data: [],
    loading: false,
  });
  const [orgList, setOrgList] = useState({ data: [], loading: false });
  const [uomList, setUOMList] = useState({ data: [], loading: false });
  const [prod_List, setProd_List] = useState({ data: [], loading: false });
  const [prodType, setProdType] = useState({ data: [], loading: false });
  const [soStatusList, setSOStatusList] = useState({
    data: [],
    loading: false,
  });

  const [quotationList, setQuotationList] = useState({
    data: [],
    loading: false,
  });
  const [taxDetails, setTaxDetails] = useState({});
  const [usingQuoteProd, setUsingQuoteProd] = useState(false);
  // const [cameFromQuoteTable] = useState(atob(urlParams.quoteId) !== "null" && atob(urlParams.supplierId) !== "null");
  const [gstErr, setGstErr] = useState(false);
  const [lockBtn, setLockBtn] = useState(false);

  const [galVisible, setGalVisible] = useState(false);
  const [drawingFiles, setDrawingFiles] = useState([]);
  const [disableLineItems, setDisableLineItems] = useState(true);
  const [constLabel, setConstLabel] = useState({});

  const localData = JSON.parse(localStorage.getItem("localData"));

  const {
    register,
    handleSubmit,
    formState,
    control,
    watch,
    getValues,
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      CGST_amount: 0,
      SGST_amount: 0,
      IGST_amount: 0,
      UGST_amount: 0,
      discount: 0,
      items: [
        {
          prod_type: [],
          product: [],
          quantity: 0,
          unit: [],
          price: 0,
          gst_rate: 0,
          gst_amount: 0,
          amount: 0,
          discount: 0,
        },
      ],
    },
  });
  let { errors } = formState;

  const { fields, append, remove } = useFieldArray({ control, name: "items" });

  const lineItems = watch("items");
  const org = watch("organization");
  const invTo = watch("invoiceTo");
  const cust = watch("customer");
  const qRef = watch("quoteRef");
  const tempQI = [];

  const [subTotalForRef, setSubtotalForRef] = useState(0);
  let subTotalForRef2 = "";

  useEffect(() => {
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = function () {
      window.history.pushState(null, "", window.location.href);
    };
    // console.log("localData", localData);

    setConstLabel(getLabels("Sales", "Sales Order"));
    fetchUOM();
    fetchCustomers();
    fetchProductType();
    fetchSOStatus();
    fetchQuotation();
    fetchOrganizations();
  }, []);

  useEffect(() => {
    calSubAmt();
    cal_final_total_with_tax_details();
  }, [lineItems]);

  useEffect(() => {
    if (Object.keys(taxDetails).length > 0) {
      cal_final_total_with_tax_details();
    }
  }, [taxDetails]);

  useEffect(() => {
    if (cust !== undefined && cust.length > 0) {
      setValue("invoiceTo", cust);
      setValue("dispatchTo", cust);
      setValue("party_po_ref", cust?.length > 0 && cust?.[0]?.customerType !== "Retail" ? 0 : "");
    }
  }, [cust]);

  useEffect(() => {
    if (invTo !== undefined && org !== undefined) {
      if (invTo.length > 0 && org.length > 0) {
        fetchGST({
          suplier_gst_number: org[0].comGSTnumber, //cust[0]?.gst,
          invoice_gst_number: invTo[0]?.gst,
        });

        setDisableLineItems(false);
      } else {
        setDisableLineItems(true);
      }
    }
  }, [invTo, org]); //cust

  useEffect(() => {
    setValue("items", [{}]);
    // setUsingQuoteProd(false);
    setValue("subTotal", 0.0);
    setValue("taxAmt", 0.0);
    setValue("discount", 0);
    setValue("CGST", 0);
    setValue("CGST_amount", 0);
    setValue("SGST", 0);
    setValue("SGST_amount", 0);
    setValue("IGST", 0);
    setValue("IGST_amount", 0);
    setValue("UGST", 0);
    setValue("UGST_amount", 0);
    setTaxDetails({});
  }, [customerList.data]);

  function fetchQuotation() {
    setQuotationList({ ...quotationList, data: [], loading: true });

    getQuotation().then(
      (res) => {
        // if (atob(urlParams.quoteId) !== "null" && atob(urlParams.supplierId) !== "null") {
        //   const result = res.data.data.filter((val) => {
        //     return val.uuid == atob(urlParams.quoteId)
        //   })

        //   setValue('quoteRef', result);

        //   setValue('invoiceTo', [result[0]?.quotation_for_id]);

        // }

        setQuotationList({
          ...quotationList,
          data: res.data.data,
          loading: false,
        });
      },
      (err) => {
        setQuotationList({ ...quotationList, data: [], loading: false });
      }
    );
  }

  async function fetchGST(payload) {
    await getGST(payload).then(
      (res) => {
        setTaxDetails(res.data);

        const subTot = parseFloat(getValues("subTotal"));

        // setValue("SGST_amount", ((subTot * res.data.sgst) / 100).toFixed(2));
        // setValue("CGST_amount", ((subTot * res.data.cgst) / 100).toFixed(2));
        // setValue("IGST_amount", ((subTot * res.data.igst) / 100).toFixed(2));
        // setValue("UGST_amount", ((subTot * res.data.ugst) / 100).toFixed(2));

        // setValue("CGST", res.data.cgst);
        // setValue("SGST", res.data.sgst);
        // setValue("IGST", res.data.igst);
        // setValue("UGST", res.data.ugst);

        setGstErr(false);
      },
      (err) => {
        toast.error(err.response.data.message, {
          autoClose: 3000,
        });
        setGstErr(true);
        setValue("CGST", 0);
        setValue("SGST", 0);
        setValue("IGST", 0);
        setValue("UGST", 0);
        setTaxDetails({});
      }
    );
  }

  function calSubAmt() {
    const totalAmount = lineItems.reduce(
      (accumulator, item) => parseFloat(accumulator) + parseFloat(item.amount),
      0
    );

    setValue("subTotal", parseFloat(totalAmount).toFixed(2));
    setSubtotalForRef(parseFloat(totalAmount).toFixed(2));
    subTotalForRef2 = parseFloat(totalAmount);
  }

  function fetchUOM() {
    setUOMList({ ...uomList, data: [], loading: true });
    getUOM().then(
      (res) => {
        setUOMList({ ...uomList, data: res.data.data, loading: false });
      },
      (err) => {
        setUOMList({ ...uomList, data: [], loading: false });
      }
    );
  }

  function fetchProdList(selVal, text) {
    if (selVal.length > 0  && text.length >= 4) {
      setProd_List({ ...prod_List, loading: true, data: [] });

      getProdList({ searchVal: text, product_type_id: selVal[0].uuid }).then(
        (res) => {
          let arr = res.data.data.filter(
            (item) => item.productName !== null || item.productName !== undefined
          );
          setProd_List({ ...prod_List, data: arr, loading: false });
        },
        (err) => {
          setProd_List({ ...prod_List, loading: false, data: [] });
        }
      );
    } else {
      setProd_List({ ...prod_List, loading: false, data: [] });
    }
  }

  // function handleProdType(selVal, index) {
  //   setValue(`items[${index}].prod_type`, selVal, { shouldValidate: true });
  //   setValue(`items[${index}].product`, []);
  //   if (selVal.length > 0) {
  //     fetchProdList(selVal);
  //   } else {
  //     setProd_List({ ...prod_List, loading: false, data: [] });
  //   }
  // }

  // Handle product type selection
  function handleProdType(selVal, index) {
    setValue(`items[${index}].prod_type`, selVal, { shouldValidate: true });
    setValue(`items[${index}].product`, []); // Reset product selection

    // Clear the product list, but do not fetch yet
    setProd_List({ loading: false, data: [] });
  }

  

  function fetchProductType() {
    setProdType({ ...prodType, data: [], loading: true });
    getProductType().then(
      (res) => {
        setProdType({ ...prodType, data: res.data.data, loading: false });
      },
      (err) => {
        setProdType({ ...prodType, data: [], loading: false });
      }
    );
  }

  function fetchCustomers() {
    setCustomerList({ ...customerList, loading: true, data: [] });
    getCustomer({ partyType: "Customer" }).then(
      (res) => {
        const result = res.data.data.filter(
          (item) => item.organization !== null
        );
        setCustomerList({
          ...customerList,
          loading: false,
          data: result,
        });
      },
      (err) => {
        //
        setCustomerList({ ...customerList, loading: false, data: [] });
        // toast.error("Something went wrong in suppliers!", {
        //   autoClose: 3000,
        // });
      }
    );
  }

  function fetchSOStatus() {
    setSOStatusList({ ...soStatusList, data: [], loading: true });
    getSOStatus().then(
      (res) => {
        setSOStatusList({
          ...soStatusList,
          data: res.data.data,
          loading: false,
        });
        const result = res.data.data?.filter((item) => item.status_name == "Open");

        setValue("status", result);
      },
      (err) => {
        setSOStatusList({ ...soStatusList, data: [], loading: false });
      }
    );
  }

  function fetchOrganizations() {
    setOrgList({ ...orgList, data: [], loading: true });

    getInternalCompany().then(
      (res) => {
        const result = localData?.organizations?.filter((item) => item.default_org == true);
        setValue("organization", result);
        if(res && res.data?.data?.length > 0){
          setOrgList({ ...orgList, data: res.data.data, loading: false });
        } else {
          setOrgList({ ...orgList, data: [], loading: false });
        }
        
      },
      (err) => {
        setOrgList({ ...orgList, data: [], loading: false });
      }
    );
  }

  const onSubmit = (data) => {
    setLockBtn(true);
    let reqPayload = [];

    for (let i = 0; i < data.items.length; i++) {
      const lineItems = {
        product_type: data.items[i].prod_type[0].productType,
        // product_code: "",
        productname: data.items[i].product[0].productName,
        partnumber: data.items[i].product[0].partNo,
        quantity: parseFloat(data.items[i].quantity),
        rate: data.items[i].price,
        unit: data.items[i].unit[0].uom,
        amount: (data.items[i].amount).toString(),
        product_type_id: data.items[i].prod_type[0].uuid,
        product_id: data.items[i].product[0].uuid,
        unit_id: data.items[i].unit[0].uuid,
        org_id: data.organization[0].id,
        gst_rate: parseFloat(data.items[i]?.gst_rate),
        gst_amount: parseFloat(data.items[i]?.gst_amount),
        discount: parseFloat(data.items[i]?.discount),
      };

      reqPayload.push(lineItems);
    }

    const payload = {
      org_id: data.organization[0].uuid,
      customer_id: data.customer[0].uuid,
      // customer_name: data.customer[0].name,
      customer_name: data.customer[0].organization, //as per discussion with tester change
      party_address: data.customer[0].address,
      party_po_ref: data.party_po_ref,
      quote_ref: data.quote_ref,
      order_date: convertDateYYMMDD(data.order_date, "-"),
      order_due_date: convertDateYYMMDD(data.order_due_date, "-"),
      invoice_to: data.invoiceTo[0].uuid,
      invoice_to_address: data.invoiceTo[0].address,
      dispatch_to: data.dispatchTo[0].uuid,
      dispatch_to_address: data.dispatchTo[0].address,
      // discount: parseFloat(data.discount),
      sub_total: parseFloat(data.subTotal),
      final_total: parseFloat(data.totalAmount),
      status: data.status[0].id,
      // so_status: data.status[0].status_name,
      // so_status_id: data.status[0].uuid,
      // remark: data.remarks,
      internal_remark: data.internalRemarks,
      sales_order_name: data.sales_order_name,
    };

    if (taxDetails.type == "sgst") {
      payload.cgst = 0;
      payload.cgst_cost = parseFloat(data.CGST_amount);
      payload.sgst = 0;
      payload.sgst_cost = parseFloat(data.SGST_amount);
    } else if (taxDetails.type == "igst") {
      payload.igst = 0;
      payload.igst_cost = parseFloat(data.IGST_amount);
    } else if (taxDetails.type == "ugst") {
      payload.cgst = 0;
      payload.cgst_cost = parseFloat(data.CGST_amount);
      payload.ugst = 0;
      payload.ugst_cost = parseFloat(data.UGST_amount);
    } 

    const payload1 = {
      so_order: payload,
      so_order_lines: reqPayload,
    };

    // console.log("payload1", payload1);
    // return;
    createSalesOrder(payload1).then(
      async (res) => {
        if (res.status === 200 || res.status === 201) {
          const imgArr = [];
          for (let a = 0; a < drawingFiles.length; a++) {
            imgArr.push({
              fileName: (drawingFiles[a]?.file?.name)?.replace(/[^a-zA-Z0-9.]+/g, ''),
              contentType: drawingFiles[a]?.file?.type,
              model: {
                ref_model_uuid: res.data?.so_order?.uuid,
                model_name: screenList.sales_order_screen.replace(/\s+/g, ""),
                name: (drawingFiles[a]?.file?.name)?.replace(/[^a-zA-Z0-9.]+/g, ''),
                document_type: drawingFiles[a]?.file?.type.startsWith("image/") ? "image" : 
                drawingFiles[a]?.file?.type.startsWith("audio/") ? "audio" : "video",
                created_by_user: localData?.user_id,
                client_id: localData?.client_id
              }
            });
          }

          const payload = {
            files: imgArr,
          };

          if (drawingFiles.length > 0) {
            const url = process.env.REACT_APP_COGNITO_URL + s3_image_upload;
            const idToken = localStorage.getItem("id");
            
            axios.post(url, payload, {
              headers: {
                Authorization: `Bearer ${idToken}`,
                'Content-Type': 'application/json'
              }
            }).then(
              (res) => {
                // console.log("res", res);
                for(let x = 0; x < res.data.data?.body?.length; x++){
                  let bytes = [];
                  const url2 = res.data.data?.body?.[x]?.url;

                  axios.put(url2, drawingFiles[x]?.file, {
                    headers: {
                      'Content-Type': drawingFiles[x]?.file?.type
                    }
                  }).then(
                    (ress) => {
                      toast.success("Sales order created successfully !", {
                        autoClose: 2000,
                      });
                      setTimeout(() => {
                        navigate("/pcms/sales-order");
                        reset();
                      }, [2000]);
                      setLockBtn(false);
                    },
                    (errr) => {
                      console.log("errr", errr);
                      if (errr.response.status == 413) {
                        toast.error("Image upload failed !", {
                          autoClose: 3000,
                        });
                      }
                    }
                  );
                }
              },
              (err) => {
                console.log("err", err);
              }
            );
          } else {
            toast.success("Sales order created successfully !", {
              autoClose: 2000,
            });
            setTimeout(() => {
              navigate("/pcms/sales-order");
              reset();
            }, [2200]);
            setLockBtn(false);
          }
        }
      },
      (err) => {
        toast.error(err.response.data.message, {
          autoClose: 3000,
        });
        setLockBtn(false);
      }
    );
  };

  async function calRate(para1, para2, index) {
    let rate;
    let gst_rate;
    let gst_amount;
    let discount;
    let discounted_amt;
    let amount;
    let quantity;
    let price;
    para1 = Number(para1);

    if (para1.length == 0) {
      para1 = parseFloat(0).toFixed(2);
    }

    switch (para2) {
      case "quantity":
        // items[${index}].price
        const p_price = parseFloat(getValues(`items[${index}].price`)).toFixed(
          2
        );

        gst_rate = Number(getValues(`items[${index}].gst_rate`));
        discount = parseFloat(getValues(`items[${index}].discount`));

        rate = parseFloat((para1 * Number(p_price)).toFixed(2));

        discounted_amt = parseFloat((rate * discount) / 100).toFixed(2);

        gst_amount = parseFloat(
          (((rate - discounted_amt) * gst_rate) / 100).toFixed(2)
        );

        amount = rate - discounted_amt;

        setValue(`items[${index}].amount`, amount);
        setValue(`items[${index}].gst_amount`, gst_amount);

        await calSubAmt();
        cal_final_total_with_tax_details();

        break;

      case "price":
        quantity = Number(getValues(`items[${index}].quantity`));

        rate = parseFloat((para1 * quantity).toFixed(2));

        gst_rate = Number(getValues(`items[${index}].gst_rate`));
        discount = parseFloat(getValues(`items[${index}].discount`));

        discounted_amt = parseFloat((rate * discount) / 100).toFixed(2);

        gst_amount = parseFloat(
          (((Number(rate) - discounted_amt) * Number(gst_rate)) / 100).toFixed(
            2
          )
        );

        amount = rate - discounted_amt;

        setValue(`items[${index}].amount`, amount);
        setValue(`items[${index}].gst_amount`, gst_amount);

        await calSubAmt();
        cal_final_total_with_tax_details();
        break;

      case "gst_rate":
        quantity = Number(getValues(`items[${index}].quantity`));
        price = Number(getValues(`items[${index}].price`));
        discount = parseFloat(getValues(`items[${index}].discount`));

        discounted_amt = parseFloat(
          ((quantity * price * discount) / 100).toFixed(2)
        );
        const result = parseFloat(
          (
            ((Number(quantity) * Number(price) - discounted_amt) * para1) /
            100
          ).toFixed(2)
        );

        // console.log("result", result);
        setValue(`items[${index}].gst_amount`, result);

        amount = quantity * price - discounted_amt;

        setValue(`items[${index}].amount`, amount);

        await calSubAmt();
        cal_final_total_with_tax_details();
        break;

      case "discount":
        quantity = Number(getValues(`items[${index}].quantity`));
        price = Number(getValues(`items[${index}].price`));
        gst_rate = Number(getValues(`items[${index}].gst_rate`));
        discounted_amt = parseFloat(
          ((quantity * price * para1) / 100).toFixed(2)
        );

        const result2 = parseFloat(
          (
            ((Number(quantity) * Number(price) - discounted_amt) * gst_rate) /
            100
          ).toFixed(2)
        );
        setValue(`items[${index}].gst_amount`, result2);

        amount = quantity * price - discounted_amt;

        setValue(`items[${index}].amount`, amount);

        await calSubAmt();
        cal_final_total_with_tax_details();
        break;
    }
  }


  async function cal_final_total_with_tax_details() {
    await calSubAmt();

    // let SGST = parseFloat(getValues("SGST"));
    // let CGST = parseFloat(getValues("CGST"));

    // Discount Calcultaion logic
    // console.log("taxDetails.type", taxDetails.type);
    const line_items = getValues("items");

    // console.log("line_items", line_items);

    if (!taxDetails.type || line_items?.length == 0) {
      setValue("totalAmount", "0.00");
    }

    let total_of_gst_amount = 0;
    let total_of_amt_after_discount = 0;

    if (line_items.length > 0) {
      line_items.forEach((val) => {
        total_of_gst_amount += val.gst_amount;
        total_of_amt_after_discount += val.amount;
      });
    }

    // console.log("total_of_gst_amount", total_of_gst_amount);

    if (taxDetails.type && line_items.length > 0) {
      setValue(
        "totalAmount",
        Number(total_of_gst_amount + total_of_amt_after_discount).toFixed(2)
      );
    }

    if (taxDetails.type == "sgst") {
      setValue("SGST_amount", Number(total_of_gst_amount / 2).toFixed(2));
      setValue("CGST_amount", Number(total_of_gst_amount / 2).toFixed(2));
    } else if (taxDetails.type == "igst") {
      setValue("IGST_amount", Number(total_of_gst_amount).toFixed(2));
    } else if (taxDetails.type == "ugst") {
      setValue("CGST_amount", Number(total_of_gst_amount / 2).toFixed(2));
      setValue("UGST_amount", Number(total_of_gst_amount / 2).toFixed(2));
      // setValue("UGST_amount", Number(total_of_gst_amount).toFixed(2));
    }
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <SOImageGallary
        setGalVisible={setGalVisible}
        galVisible={galVisible}
        setDrawingFiles={setDrawingFiles}
        drawingFiles={drawingFiles}
      />

      <div className="purchase_order mb-4">
        <div className="card">
          <div className="card-header bg-grey d-flex align-items-center">
            <button
              className="btn btn-yellow"
              onClick={() => {
                SweetAlert.fire({
                  title: "Are you sure?",
                  text: "Your present data will be lost!",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes, go back!",
                }).then((result) => {
                  if (result.isConfirmed) {
                    window.onpopstate = undefined;

                    navigate("/pcms/sales-order");
                  }
                });
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} size="sm" />
            </button>

            <p className="mb-0 ms-3" style={{ fontSize: "24px" }}>
              {constLabel?.lbl_create_sales_order
                ? constLabel.lbl_create_sales_order
                : "Create Sales Order"}
            </p>
          </div>
          <div className="card-body">
            <div className="createPO container-fluid">
              <p className="roboto-bold mb-0" style={{ fontSize: "20px" }}>
                {constLabel?.lbl_create_sales_order
                  ? constLabel.lbl_create_sales_order
                  : "Sales Order Detail"}
              </p>
              <hr className="my-2" />

              <div className="row my-4">
                <div className="col-12 col-md-4">
                  <div className="form-group typeahead-container">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_organization_name
                      ? constLabel.lbl_organization_name
                      : "Organization Name"}
                    <span className="text-danger"> *</span>
                  </label>
                    <Controller
                      name={`organization`}
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          className="mt-2"
                          id="basic-typeahead-single"
                          labelKey="comName"
                          multiple={false}
                          options={orgList.data}
                          isLoading={orgList.loading}
                          placeholder="Select organization"
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          isInvalid={errors.organization ? true : false}
                        />
                      )}
                    />
                    {!orgList.loading && !errors.organization && <span className="arrow-icon"></span>}
                    {errors.organization && (
                      <span className="text-danger err-msg">
                        {errors.organization.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-4">
                  <div className="form-group typeahead-container">
                  <label className="lbl-style col-12 col-md-12 roboto-bold d-flex align-items-center" style={{marginTop:"3px"}}>
                    {constLabel?.lbl_customer_name ? constLabel.lbl_customer_name : "Customer Name"}
                    <span className="ms-2 text-danger"> *</span>
                    <span className="ms-2">
                      <AddCustomer fetchCustomers={fetchCustomers} />
                    </span>
                  </label>
                    <Controller
                      name={`customer`}
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          className="mt-2"
                          id="basic-typeahead-single"
                          labelKey="organization"
                          multiple={false}
                          options={customerList.data}
                          isLoading={customerList.loading}
                          placeholder="Select customer"
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          // disabled={cameFromQuoteTable}
                          isInvalid={errors.customer ? true : false}
                        />
                      )}
                    />
                    {!customerList.loading && !errors.customer && <span className="arrow-icon"></span>}
                    {errors.customer && (
                      <span className="text-danger err-msg">
                        {errors.customer.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-4">
                  <div className="form-group">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_order_date
                      ? constLabel.lbl_order_date
                      : "Order Date"}
                    {""}
                    <span className="text-danger"> *</span>
                  </label>
                    <Controller
                      control={control}
                      name="order_date"
                      defaultValue={new Date()}
                      render={({ field }) => (
                        <DatePicker
                          className={
                            errors.order_date
                              ? "form-control mt-2 is-invalid"
                              : "form-control mt-2"
                          }
                          placeholderText="Select date"
                          onChange={(date) => field.onChange(date)}
                          selected={field.value}
                          defaultValue={new Date()}
                          maxDate={new Date()}
                          dateFormat="dd/MM/yyyy"
                        />
                      )}
                    />

                    {errors.order_date && (
                      <span className="text-danger err-msg">
                        {errors.order_date.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-4 mt-3">
                  <div className="form-group">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_order_due_date
                      ? constLabel.lbl_order_due_date
                      : "Order Due Date"}
                    {""}
                    <span className="text-danger"> *</span>
                  </label>
                    <Controller
                      control={control}
                      name="order_due_date"
                      defaultValue={new Date()}
                      render={({ field }) => (
                        <DatePicker
                          className={
                            errors.order_due_date
                              ? "form-control mt-2 is-invalid"
                              : "form-control mt-2"
                          }
                          placeholderText="Select date"
                          onChange={(date) => field.onChange(date)}
                          selected={field.value}
                          defaultValue={new Date()}
                          minDate={new Date()}
                          dateFormat="dd/MM/yyyy"
                        />
                      )}
                    />

                    {errors.order_due_date && (
                      <span className="text-danger err-msg">
                        {errors.order_due_date.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-4 mt-3">
                  <div className="form-group typeahead-container">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_invoice_to
                      ? constLabel.lbl_invoice_to
                      : "Invoice To"}
                    <span className="text-danger"> *</span>
                  </label>
                    <Controller
                      name={`invoiceTo`}
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          className="mt-2"
                          id="basic-typeahead-single"
                          labelKey="organization"
                          multiple={false}
                          options={customerList.data}
                          isLoading={customerList.loading}
                          placeholder="Select company"
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          // disabled={cameFromQuoteTable == true}
                          isInvalid={errors.invoiceTo ? true : false}
                        />
                      )}
                    />
                    {!customerList.loading && !errors.invoiceTo && <span className="arrow-icon"></span>}
                    {errors.invoiceTo && (
                      <span className="text-danger err-msg">
                        {errors.invoiceTo.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-4 mt-3">
                  <div className="form-group typeahead-container">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_dispatch_to
                      ? constLabel.lbl_dispatch_to
                      : "Dispatch To"}
                    <span className="text-danger"> *</span>
                  </label>
                    <Controller
                      name={`dispatchTo`}
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          className="mt-2"
                          id="basic-typeahead-single"
                          labelKey="organization"
                          multiple={false}
                          options={customerList.data}
                          isLoading={customerList.loading}
                          placeholder="Select company"
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          isInvalid={errors.dispatchTo ? true : false}
                        />
                      )}
                    />
                    {!customerList.loading && !errors.dispatchTo && <span className="arrow-icon"></span>}
                    {errors.dispatchTo && (
                      <span className="text-danger err-msg">
                        {errors.dispatchTo.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-4 mt-3">
                  <div className="form-group">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_party_po_ref
                      ? constLabel.lbl_party_po_ref
                      : "Customer PO Ref"}
                    {cust && cust?.length > 0 && cust?.[0]?.customerType !== "Retail" ? 
                      <span className="text-danger"> *</span>
                    : null }
                  </label>
                    <input
                      className={
                        errors.party_po_ref && (cust && cust?.length > 0 && cust?.[0]?.customerType !== "Retail")
                          ? "form-control mt-2 is-invalid"
                          : "form-control mt-2"
                      }
                      id="party_po_ref"
                      name="party_po_ref"
                      type="text"
                      placeholder="Enter customer po reference"
                      {...register("party_po_ref")}
                    />
                    {errors.party_po_ref && (cust && cust?.length > 0 && cust?.[0]?.customerType !== "Retail") && (
                      <span className="text-danger err-msg">
                        {errors.party_po_ref.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-4 mt-3">
                  <div className="form-group">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_quote_ref
                      ? constLabel.lbl_quote_ref
                      : "Quote Ref"}
                  </label>
                    <input
                      className={
                        errors.quote_ref
                          ? "form-control mt-2 is-invalid"
                          : "form-control mt-2"
                      }
                      id="quote_ref"
                      name="quote_ref"
                      type="text"
                      placeholder="Enter quote reference"
                      {...register("quote_ref")}
                    />
                  </div>
                </div>

                <div className="col-12 col-md-4 mt-3">
                  <div className="form-group typeahead-container">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_status ? constLabel.lbl_status : "Status"}
                    <span className="text-danger"> *</span>
                  </label>
                    <Controller
                      name={`status`}
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          className="mt-2"
                          id="basic-typeahead-single"
                          labelKey="status_name"
                          multiple={false}
                          options={soStatusList.data}
                          isLoading={soStatusList.loading}
                          placeholder="Select status"
                          disabled
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          isInvalid={errors.status ? true : false}
                          filterBy={() => true}
                        />
                      )}
                    />
                    {!soStatusList.loading && !errors.status && <span className="arrow-icon"></span>}
                    {errors.status && (
                      <span className="text-danger err-msg">
                        {errors.status.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-4 mt-3">
                  <div className="form-group">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_internal_remark
                      ? constLabel.lbl_internal_remark
                      : "Internal Remark"}
                    <span className="text-danger"> *</span>
                  </label>
                    <textarea
                      className={
                        errors.internalRemarks
                          ? "form-control mt-2 is-invalid"
                          : "form-control mt-2"
                      }
                      id="internalRemarks"
                      name="internalRemarks"
                      placeholder="Enter Internal Remarks"
                      {...register("internalRemarks")}
                    ></textarea>
                    {errors.internalRemarks && (
                      <span className="text-danger err-msg">
                        {errors.internalRemarks.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-4 mt-3">
                  <div className="form-group">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_sales_order_name
                      ? constLabel.lbl_sales_order_name
                      : "Sales Order Name"}
                  </label>
                    <input
                      className="form-control mt-2"
                      id="sales_order_name"
                      name="sales_order_name"
                      type="text"
                      placeholder="Enter sales order name"
                      {...register("sales_order_name")}
                    />
                  </div>
                </div>
              </div>

              <p className="mt-2 mb-4">
                <i>
                  {" "}
                  <span className="text-danger roboto-bold">Note: </span>
                  <FontAwesomeIcon
                    style={{
                      color: "#c7c7c7",
                    }}
                    icon={faCircle}
                    size="2xs"
                    className="mx-2"
                  />
                  Internal remarks will not be displayed in report
                </i>
                <i className=" d-block">
                  {" "}
                  <span className="text-danger roboto-bold invisible">
                    Note:{" "}
                  </span>
                  <FontAwesomeIcon
                    style={{
                      color: "#c7c7c7",
                    }}
                    icon={faCircle}
                    size="2xs"
                    className="mx-2"
                  />
                  Product type must be selected first then product
                </i>
              </p>

              <p className="roboto-bold mb-0" style={{ fontSize: "20px" }}>
                {constLabel?.lbl_product_detail
                  ? constLabel.lbl_product_detail
                  : "Product Detail"}
              </p>
              <hr className="my-2" />

              <div
                className="my-3"
                // style={{ maxHeight: "80vh" }}
              >
                <table className="table table-responsive table-bordered">
                  <thead className="thead-light text-center">
                    <tr>
                      <th scope="col">
                        {constLabel?.lbl_product_type
                          ? constLabel.lbl_product_type
                          : "Product Type"}
                      </th>
                      <th scope="col">
                        {constLabel?.lbl_product_name
                          ? constLabel.lbl_product_name
                          : "Product Name"}
                      </th>
                      <th scope="col">
                        {constLabel?.lbl_quantity
                          ? constLabel.lbl_quantity
                          : "Quantity"}
                      </th>
                      <th scope="col">
                        {constLabel?.lbl_unit_price
                          ? constLabel.lbl_unit_price
                          : "Unit Price"}
                        <span>&#8377;</span>
                      </th>
                      <th scope="col">
                        {constLabel?.gst_rate ? constLabel.gst_rate : "GST %"}
                      </th>
                      <th scope="col">
                        {constLabel?.hsn_code
                          ? constLabel.hsn_code
                          : "HSN Code"}
                      </th>
                      <th scope="col">
                        {constLabel?.discount
                          ? constLabel.discount
                          : "Discount "}
                        <span>%</span>
                      </th>
                      <th scope="col">
                        {constLabel?.lbl_unit ? constLabel.lbl_unit : "Unit"}
                      </th>
                      <th scope="col">
                        {constLabel?.lbl_amount
                          ? constLabel.lbl_amount
                          : "Amount "}{" "}
                        <span>&#8377;</span>
                      </th>
                      <th scope="col">{"Action"}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {fields.map(({ id }, index) => (
                      <tr key={id} className="f-18">
                        <td style={{ verticalAlign: "top", width: '15%' }}>
                          <Controller
                            name={`items[${index}].prod_type`}
                            control={control}
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <Typeahead
                                id="basic-typeahead-single"
                                labelKey="productType"
                                size="sm"
                                multiple={false}
                                options={prodType.data}
                                isLoading={prodType.loading}
                                placeholder="Select product type"
                                // onChange={onChange}
                                onChange={(selected) => {
                                  handleProdType(selected, index);
                                }}
                                onBlur={onBlur}
                                selected={value}
                                disabled={disableLineItems}
                                isInvalid={
                                  errors.items?.[index]?.prod_type
                                    ? true
                                    : false
                                }
                              />
                            )}
                          />

                          {errors.items?.[index]?.prod_type && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.prod_type.message}
                            </span>
                          )}

                          {index + 1 == fields.length ? (
                            <div>
                              <button
                                style={{ fontSize: "16px" }}
                                className="btn btn-sm button-primary px-2 mt-2"
                                onClick={() => {
                                  append({
                                    prod_type: [],
                                    product: [],
                                    quantity: 0,
                                    unit: [],
                                    price: 0,
                                    amount: 0,
                                    gst_rate: 0,
                                    gst_amount: 0,
                                    discount: 0,
                                  });
                                }}
                              >
                                <FontAwesomeIcon
                                  style={{
                                    color: "#ffffff",
                                    marginRight: "5px",
                                  }}
                                  icon={faAdd}
                                  size="sm"
                                />{" "}
                                {constLabel?.lbl_add
                                  ? constLabel.lbl_add
                                  : "Add"}
                              </button>
                            </div>
                          ) : null}
                        </td>

                        <td style={{ verticalAlign: "top", width: "22%" }}>
                          <Controller
                            name={`items[${index}].product`}
                            control={control}
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <Typeahead
                                id="basic-typeahead-single"
                                labelKey="productName"
                                size="sm"
                                multiple={false}
                                options={prod_List.data}
                                isLoading={prod_List.loading}
                                placeholder="Search product for selection"
                                // onChange={onChange}
                                // onChange={(selected) => {
                                //   handleProduct(index);
                                //   onChange(selected);
                                // }}
                                onChange={(selected) => {
                                  // console.log("selected", selected);
                                  if (selected.length > 0) {
                                    setValue(
                                      `items[${index}].product`,
                                      selected,
                                      { shouldValidate: true }
                                    );
                                    setValue(
                                      `items[${index}].gst_rate`,
                                      selected[0].hsn_uuid?.gst_rate ?? 0,
                                      { shouldValidate: true }
                                    );
                                    setValue(
                                      `items[${index}].hsn_code`,
                                      selected[0].hsn_uuid?.hsn_sac_code ?? "",
                                      { shouldValidate: true }
                                    );
                                    setValue(
                                      `items[${index}].unit`,
                                      [selected[0].unit_id]?? [],
                                      { shouldValidate: true }
                                    );

                                    calRate(
                                      selected[0].hsn_uuid?.gst_rate ?? 0,
                                      "gst_rate",
                                      index
                                    );
                                  } else {
                                    setValue(`items[${index}].product`, [], {
                                      shouldValidate: true,
                                    });
                                    setValue(`items[${index}].gst_rate`, [], {
                                      shouldValidate: true,
                                    });
                                    setValue(
                                      `items[${index}].unit`, [],
                                      { shouldValidate: true }
                                    );
                                  }
                                }}
                                onBlur={onBlur}
                                selected={value}
                                // disabled={usingQuoteProd}
                                isInvalid={
                                  errors.items?.[index]?.product ? true : false
                                }
                                disabled={disableLineItems}
                                onInputChange={(text) => {
                                  if (text.length >= 4) {
                                    fetchProdList(getValues(`items[${index}].prod_type`), text);
                                  }
                                }}
                              />
                            )}
                          />

                          {errors.items?.[index]?.product && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.product.message}
                            </span>
                          )}

                          {index + 1 == fields.length ? (
                            <AddProduct fetchProdList={fetchProdList} />
                          ) : null}
                        </td>

                        <td style={{ verticalAlign: "top", width: "9%" }}>
                          <input
                            className={
                              errors.items?.[index]?.quantity
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            type="text"
                            defaultValue={0}
                            name={`items[${index}].quantity`}
                            placeholder="Enter quantity"
                            {...register(`items[${index}].quantity`, {
                              onChange: (e) =>
                                calRate(e.target.value, "quantity", index),
                            })}
                            disabled={disableLineItems}
                          />

                          {errors.items?.[index]?.quantity && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.quantity.message}
                            </span>
                          )}
                        </td>

                        <td style={{ verticalAlign: "top", width: "14%" }}>
                          <input
                            className={
                              errors.items?.[index]?.price
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            type="text"
                            // defaultValue={0}
                            name={`items[${index}].price`}
                            {...register(`items[${index}].price`, {
                              onChange: (e) =>
                                calRate(e.target.value, "price", index),
                            })}
                            disabled={disableLineItems}
                          />

                          {errors.items?.[index]?.price && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.price.message}
                            </span>
                          )}
                        </td>

                        <td style={{ verticalAlign: "top", width: "6%" }}>
                          <input
                            className={
                              errors.items?.[index]?.gst_rate
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            type="text"
                            // defaultValue={0}
                            name={`items[${index}].gst_rate`}
                            {...register(`items[${index}].gst_rate`, {
                              onChange: (e) => {
                                // console.log("e", e);
                                calRate(e.target.value, "gst_rate", index);
                              },
                            })}
                            disabled={disableLineItems}
                          />

                          {errors.items?.[index]?.gst_rate && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.gst_rate.message}
                            </span>
                          )}
                        </td>

                        <td style={{ verticalAlign: "top", width: "9%" }}>
                          <input
                            className={
                              errors.items?.[index]?.hsn_code
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            type="text"
                            // defaultValue={0}
                            name={`items[${index}].hsn_code`}
                            {...register(`items[${index}].hsn_code`)}
                            disabled
                          />

                          {errors.items?.[index]?.hsn_code && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.hsn_code.message}
                            </span>
                          )}
                        </td>

                        <td style={{ verticalAlign: "top", width: '6%' }}>
                          <input
                            className={
                              errors.items?.[index]?.discount
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            type="text"
                            // defaultValue={0}
                            name={`items[${index}].discount`}
                            {...register(`items[${index}].discount`, {
                              onChange: (e) =>
                                calRate(e.target.value, "discount", index),
                            })}
                            disabled={disableLineItems}
                          />

                          {errors.items?.[index]?.discount && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.discount.message}
                            </span>
                          )}
                        </td>

                        <td style={{ verticalAlign: "top", width: '10%'  }}>
                          <Controller
                            name={`items[${index}].unit`}
                            control={control}
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => {
                              const product = watch(`items[${index}].product`);
                              // Disable unit field if product.material_type_id is null
                              const isUnitDisabled = product?.[0]?.material_type_id == null || disableLineItems;
                              return (
                              <Typeahead
                                id="basic-typeahead-single"
                                labelKey="uom"
                                size="sm"
                                multiple={false}
                                options={uomList.data}
                                isLoading={uomList.loading}
                                placeholder="Select unit"
                                onChange={onChange}
                                onBlur={onBlur}
                                selected={value}
                                isInvalid={
                                  errors.items?.[index]?.unit ? true : false
                                }
                                disabled={isUnitDisabled}
                              />
                            )}}
                          />

                          {errors.items?.[index]?.unit && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.unit.message}
                            </span>
                          )}
                        </td>

                        <td
                          style={{
                            textAlign: "center",
                            verticalAlign: "top",
                            width: "10%",
                          }}
                        >
                          <input
                            className={
                              errors.items?.[index]?.amount
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            type="text"
                            disabled
                            name={`items[${index}].amount`}
                            {...register(`items[${index}].amount`)}
                          />
                        </td>

                        <td
                          style={{
                            textAlign: "center",
                            verticalAlign: "top",
                            width: "10%",
                          }}
                        >
                          <Tooltip id={"delete-tooltip" + index} place="top" />
                          {
                            <div
                              data-tooltip-id={"delete-tooltip" + index}
                              data-tooltip-content={
                                constLabel?.lbl_delete_product
                                  ? constLabel.lbl_delete_product
                                  : "Delete Product Item"
                              }
                              className="deleteIcon cursor_pointer"
                              onClick={
                                index > 0
                                  ? () => {
                                      remove(index);
                                    }
                                  : null
                              }
                            >
                              <Trash2 color="#FFFFFF" size={18} />
                            </div>
                          }
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div
                className="p-3 ms-auto col-12 col-md-6"
                style={{ border: "1px solid #c6c6c6", borderRadius: "2px" }}
              >
                <div className="row mb-2">
                  <div className="col-12 row ms-auto">
                    <label
                      style={{ fontSize: "14px" }}
                      className="col-form-label roboto-bold col-12 col-md-3"
                    >
                      {constLabel?.lbl_sub_total
                        ? constLabel.lbl_sub_total
                        : "Sub Total"}{" "}
                      <span>&#8377;</span>
                    </label>

                    <div className="col-12 col-md-4 ">
                      <input
                        className="form-control form-control-sm"
                        type="number"
                        value={subTotalForRef}
                        {...register("subTotal")}
                        disabled
                      />
                    </div>
                  </div>
                </div>

                <hr />

                {taxDetails.type == "sgst" ? (
                  <div className="row mb-2">
                    <div className="col-12 row ms-auto">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label roboto-bold col-12 col-md-3"
                      >
                        {constLabel?.lbl_cgst ? constLabel.lbl_cgst : "CGST"}{" "}
                        (%)
                      </label>

                      {/* <div className="col-12 col-md-4">
                        <input
                          className="form-control form-control-sm"
                          type="number"
                          {...register("CGST", {
                            onChange: (e) =>
                              CaltotalAmount(e.target.value, "CGST"),
                          })}
                          defaultValue={0}
                          placeholder="Enter CGST"
                          disabled
                        />
                        {errors.CGST && (
                          <span className="text-danger err-msg">
                            {errors.CGST.message}
                          </span>
                        )}
                      </div> */}
                      <div className="col-12 col-md-4  d-flex align-items-center">
                        <span>&#8377;</span>
                        <input
                          className="form-control form-control-sm ms-2"
                          type="number"
                          disabled
                          // defaultValue={0}
                          {...register("CGST_amount")}
                          placeholder="CGST Amount"
                        />
                      </div>
                    </div>
                  </div>
                ) : null}

                {taxDetails.type == "sgst" ? (
                  <div className="row mb-2">
                    <div className="col-12 row ms-auto">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label roboto-bold col-12 col-md-3"
                      >
                        {constLabel?.lbl_sgst ? constLabel.lbl_sgst : "SGST"}{" "}
                        (%)
                      </label>

                      {/* <div className="col-12 col-md-4">
                        <input
                          className="form-control form-control-sm"
                          type="number"
                          defaultValue={0}
                          {...register("SGST", {
                            onChange: (e) =>
                              CaltotalAmount(e.target.value, "SGST"),
                          })}
                          placeholder="Enter SGST"
                          disabled
                        />
                        {errors.SGST && (
                          <span className="text-danger err-msg">
                            {errors.SGST.message}
                          </span>
                        )}
                      </div> */}
                      <div className="col-12 col-md-4 d-flex align-items-center">
                        <span>&#8377;</span>
                        <input
                          className="form-control form-control-sm ms-2"
                          type="number"
                          // defaultValue={0}
                          {...register("SGST_amount")}
                          disabled
                          placeholder="SGST Amount"
                        />
                      </div>
                    </div>
                  </div>
                ) : null}

                {taxDetails.type == "igst" ? (
                  <div className="row mb-2">
                    <div className="col-12 row ms-auto">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label roboto-bold col-12 col-md-3"
                      >
                        {constLabel?.lbl_igst ? constLabel.lbl_igst : "IGST"}{" "}
                        (%)
                      </label>

                      {/* <div className="col-12 col-md-4">
                        <input
                          className="form-control form-control-sm"
                          type="text"
                          value={taxDetails.igst}
                          {...register("IGST")}
                          {...register("IGST", {
                              onChange: (e) => CaltotalAmount(e.target.value, "IGST")
                          })}
                          placeholder="Enter IGST"
                          disabled
                        />
                        {errors.IGST && (
                          <span className="text-danger err-msg">
                            {errors.IGST.message}
                          </span>
                        )}
                      </div> */}
                      <div className="col-12 col-md-4 d-flex align-items-center">
                        <span>&#8377;</span>
                        <input
                          className="form-control form-control-sm ms-2"
                          type="number"
                          // defaultValue={0}
                          {...register("IGST_amount")}
                          disabled
                          placeholder="IGST Amount"
                        />
                      </div>
                    </div>
                  </div>
                ) : null}

                {taxDetails.type == "ugst" ? (
                  <div className="row mb-2">
                    <div className="col-12 row ms-auto">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label roboto-bold col-12 col-md-3"
                      >
                        {constLabel?.lbl_ugst ? constLabel.lbl_ugst : "UGST"}{" "}
                        (%)
                        <span>
                          <i style={{ color: "red" }}>*</i>
                        </span>
                      </label>

                      {/* <div className="col-12 col-md-4">
                        <input
                          className="form-control form-control-sm"
                          type="number"
                          placeholder="Enter ugst"
                          {...register("UGST")}
                          value={taxDetails.ugst}
                          disabled
                        />
                        {errors.ugst && (
                          <span className="text-danger err-msg">
                            {errors.ugst.message}
                          </span>
                        )}
                      </div> */}
                      <div className="col-12 col-md-4 d-flex align-items-center">
                        <span>&#8377;</span>
                        <input
                          className="form-control form-control-sm ms-2"
                          type="number"
                          disabled
                          {...register("UGST_amount")}
                          placeholder="ugst cost"
                        />
                      </div>
                    </div>
                  </div>
                ) : null}

                {taxDetails.type == "ugst" ? (
                  <div className="row mb-2">
                    <div className="col-12 row ms-auto">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label col-12 col-md-3"
                      >
                        {constLabel?.lbl_cgst ? constLabel.lbl_cgst : "CGST"}{" "}
                        (%)
                        <span>
                          <i style={{ color: "red" }}>*</i>
                        </span>
                      </label>

                      <div className="col-12 col-md-4 d-flex align-items-center">
                        <span>&#8377; </span>
                        <input
                          className="form-control form-control-sm ms-2"
                          type="number"
                          disabled
                          {...register("CGST_amount")}
                          placeholder="CGST amount"
                        />
                      </div>
                    </div>
                  </div>
                ) : null}

                <div className="row mb-2">
                  <div className="col-12 row ms-auto">
                    <label
                      style={{ fontSize: "14px" }}
                      className="col-form-label roboto-bold col-12 col-md-3"
                    >
                      {constLabel?.lbl_total_amount
                        ? constLabel.lbl_total_amount
                        : "Total Amount"}{" "}
                      &#8377;
                    </label>

                    <div className="col-12 col-md-4">
                      <input
                        className="form-control form-control-sm"
                        type="text"
                        {...register("totalAmount")}
                        // defaultValue={0}
                        placeholder="Total Amount"
                        disabled
                      />
                      {errors.totalAmount && (
                        <span className="text-danger err-msg">
                          {errors.totalAmount.message}
                        </span>
                      )}
                    </div>
                  </div>

                  {gstErr ? (
                    <div className="col-12 mt-3">
                      <div
                        className="alert alert-danger mb-0 text-center my-4"
                        role="alert"
                      >
                        GST number is not valid of Organization or Invoice To
                        company, sales order will be saved without GST.
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div className="card-footer d-flex justify-content-end py-3">
            <button
              onClick={() => setGalVisible(true)}
              className="btn btn-yellow px-3"
            >
              {constLabel?.image_gallary
                ? constLabel.image_gallary
                : "Image Gallery"}
              <FontAwesomeIcon
                style={{
                  color: "#000",
                  fontSize: "16px",
                  marginLeft: "5px",
                }}
                icon={faImage}
              />
            </button>

            <button
              className="btn btn-grey px-4 ms-0 ms-md-2"
              onClick={handleSubmit(onSubmit)}
              disabled={lockBtn}
            >
              {constLabel?.lbl_save ? constLabel.lbl_save : "Save"}{" "}
              {lockBtn ? <Spinner animation="border" size="sm" /> : null}{" "}
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default CreateSalesOrder;
