import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import * as yup from "yup";
import { Typeahead } from "react-bootstrap-typeahead";

const schema = yup
  .object()
  .shape({
    value: yup.string().required("Key name is required !"),
    type: yup
      .array()
      .required("Please select key type !")
      .min(1, "Please select key type !"),
    keyvalue: yup.string().when("type", (type, schema) => {
    console.log("type", type);
    if (type?.length > 0 && type[0]?.name === "Text") {
        return schema.required("Enter key value is required!");
    }
    return schema;
    }),
  })
  .required();

export const AddConfiguration = ({
  visible,
  setVisible,
  table_label,
  setTable_lables,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const [iskeytype, setIsKeyType] = useState("Text");

  const onSubmit = (data) => {
    // console.log("submitted Data", data);
    //  console.log("table",table_label);

    let check = table_label.filter((item) => item["value"] == data.value);
    console.log("che", check);
    if (check.length == 0) {
      let obj = {};
      obj.key = "label";
      obj.value = data.value;
      obj.flag = data.type[0].name == "Text" ? null : isChecked;
      obj.text_value = data.type[0].name == "Text" ? data.keyvalue : null;
      const arr = [...table_label, obj];
      console.log("arr",arr)

      setVisible(false);
      setTable_lables(arr);
      setIsChecked(false);
      reset();
    } else {
      toast.error("The module is already present !", {
        autoClose: 2000,
      });
    }
  };

  const { register, handleSubmit, formState, control, reset, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook

  let { errors } = formState;

  return (
    <div>
      <ToastContainer />
      <Modal show={visible} onHide={() => setVisible(false)} size={"md"}>
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>{"Add Configuration"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action="">
            <div className="form-group row mt-2">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label col-md-4"
              >
                {"Key name"}
                <span className="text-danger"> *</span>
              </label>
              <div className="col-md-8">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="value"
                  name="value"
                  placeholder="Enter key name "
                  {...register("value")}
                />
                <span className="text-danger err-msg">{errors.value?.message}</span>
              </div>
            </div>
            <div className="form-group row mt-2">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label col-md-4"
              >
                {"Key Type"}
                <span className="text-danger"> *</span>
              </label>
              <div className="col-md-8">
                <Controller
                  name="type"
                  control={control}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead"
                      labelKey="name"
                      size="sm"
                      multiple={false}
                      options={[{ name: "Boolean" }, { name: "Text" }]}
                      placeholder="Please select key type"
                      onChange={(selected) => {
                        onChange(selected);
                        //  console.log("selected",selected)
                        if (
                          selected &&
                          selected.length > 0 &&
                          selected[0].name == "Boolean"
                        ) {
                          // console.log("Boolean")

                          setIsKeyType("Boolean");
                        } else {
                          // console.log("Text")

                          setIsKeyType("Text");
                        }
                      }}
                      onBlur={onBlur}
                      selected={value}
                      isInvalid={errors.type ? true : false}
                    />
                  )}
                />
                <span className="text-danger err-msg">{errors.type?.message}</span>
              </div>
            </div>
            <div className="form-group row mt-2">
              <label className="form-check-label lbl-style inter col-4">
                {"Key Value"}
              </label>
              {iskeytype == "Boolean" ? (
                <div className="form-check form-switch col-8 ">
                  <input
                    className="form-check-input "
                    style={{ marginLeft: "-30px" }}
                    type="checkbox"
                    id="flexSwitchCheckChecked"
                    checked={isChecked}
                    onChange={() => setIsChecked(!isChecked)}
                  />
                </div>
              ) : (
                <div className="col-md-8">
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    id="keyvalue"
                    name="keyvalue"
                    placeholder="Enter key value "
                    {...register("keyvalue")}
                  />
                  <span className="text-danger err-msg">
                    {errors.keyvalue?.message}
                  </span>
                </div>
              )}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleSubmit(onSubmit)}
            className="f-16 btn btn-yellow inter-bold"
          >
            {"Save"}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddConfiguration;
