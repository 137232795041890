import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faAdd,
  faEye,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import { Modal, Spinner, Tab, Tabs } from "react-bootstrap";
import { Check, Trash2 } from "react-feather";
import { Typeahead } from "react-bootstrap-typeahead";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import DatePicker from "react-datepicker";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import {
  getOperationList,
  getLocation,
  getUOM,
  get_challan_transport,
  get_challan_internal_transport,
  get_store,
  get_challan_type,
  get_challan,
  save_challan,
  get_last_challan,
  getProdList,
  get_purchaseInward_node,
  get_job_card,
  get_WorkOrder,
  getProdConfig,
  getGST,
  get_dispatch_pkg_store_qty,
  get_adjustment_reason,
  get_stock_by_product,
  get_dispatch,
  get_product_light,
} from "../../api/index";
import {
  convertDateYYMMDD,
  convertDate,
  getLabels,
} from "../../common/Functions/CommonFunctions";
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";

function CreateGoodsChallan() {
  let navigate = useNavigate();
  const location = useLocation();
  // console.log("locatin",location.state)
  const [locList, setLocList] = useState({ data: [], loading: false });
  const [fromlocdata, setfromlocdata] = useState({ data: [], loading: false });
  const [tolocdata, settolocdata] = useState({ data: [], loading: false });
  const [opList, setOpList] = useState({ data: [], loading: false });
  const [challantypeList, setchallantypeList] = useState({
    data: [],
    loading: false,
  });

  const [products, setproducts] = useState({
    data: [],
    loading: false,
  });
  const [storeList, setstoreList] = useState({ data: [], loading: false });
  const [uomList, setUOMList] = useState({ data: [], loading: false });
  const [fromStorelist, setfromStorelist] = useState({
    data: [],
    loading: false,
  });
  const [toStorelist, settoStorelist] = useState({ data: [], loading: false });
  const [trasnportList, settransportList] = useState({
    data: [],
    loading: false,
  });
  const [internaltrasnportList, setinternaltransportList] = useState({
    data: [],
    loading: false,
  });
  const [workorderList, setworkorderList] = useState({
    data: [],
    loading: true,
  });

  const [jobcardList, setjobcardList] = useState({
    data: [],
    loading: false,
  });

  const [challanList, setchallanList] = useState({
    data: [],
    loading: true,
  });
  const [challantype, setchallantype] = useState([]);
  const [transporttype, settranporttype] = useState(null);
  const [constLabel, setConstLabel] = useState({});
  const [qty_data, setqty_data] = useState(null);
  const [show_work_quantity, setshow_work_quantity] = useState([
    { index: 0, show: false, value: 0 },
  ]);
  const [available_product_qty, setavailable_product_qty] = useState([]);

  const [piList, setpiList] = useState({ data: [], loading: true });
  const [buttonclick, setbuttonclick] = useState(false);
  const [dispatch, setdispatch] = useState(false);
  const [adjustmentlist, setadjustmentlist] = useState({
    data: [],
    loading: true,
  });
  const adjustment_types = [{ name: "Issue" }, { name: "Receipt" }];
  const [adj_type, setadj_type] = useState(undefined);

  const [dispatchList, setDispatchList] = useState({
    data: [],
    loading: false,
  });

  const [prod, setProd] = useState("");

  const schema = yup
    .object()
    .shape({
      date: yup.date().required("Please select date !").typeError("Please select date !"),
      challanType: yup
        .array()
        .min(1, "Please select challan type !")
        .required("Please select challan type !"),
      outwardChallanRef: yup
        .array()
        .when(["challanType"], (challanType, schema) => {
          if (
            challanType !== undefined &&
            challanType.length > 0 &&
            challanType[0].typename.endsWith("Inward") &&
            challanType[0].typename !== "Customer Return Material Inward"&&
            challanType[0].typename !== "General Inward"
          ) {
            return schema
              .min(1, "Please select inward challan !")
              .required("Please select inward challan !");
          } else {
            return schema;
          }
        }),
      fromLocation: yup
        .array()
        .min(1, "Please select from location !")
        .required("Please select from location !"),
      fromstore: yup
        .array()
        .min(1, "Please select from store !")
        .required("Please select from store !"),
      toLocation: yup
        .array()
        .min(1, "Please select to location !")
        .required("Please select to location !"),
      tostore: yup
        .array()
        .min(1, "Please select to store !")
        .required("Please select to store !"),
      transportType: yup
        .array()
        .min(1, "Please select to transport type !")
        .required("Please select to transport type !"),
      driverName: yup.string().required("Driver name is required !"),
      internaltransport: yup
        .array()
        .when(["transportType"], (transportType, schema) => {
          if (
            transportType !== undefined &&
            transportType.length > 0 &&
            transportType[0].name == "Internal"
          ) {
            return schema
              .min(1, "Please select internal transport !")
              .required("Please select internal transport !");
          } else {
            return schema;
          }
        }),
      extTransport: yup
        .string()
        .when(["transportType"], (transportType, schema) => {
          if (
            transportType !== undefined &&
            transportType.length > 0 &&
            transportType[0].name == "External"
          ) {
            return schema.required("Vehical number is required !");
          } else {
            return schema;
          }
        }),
      airwayTransport: yup
        .string()
        .when(["transportType"], (transportType, schema) => {
          if (
            transportType !== undefined &&
            transportType.length > 0 &&
            transportType[0].name == "Airway"
          ) {
            return schema.required("Flight number is required !");
          } else {
            return schema;
          }
        }),
      items: yup.array().when("challanType", {
        is: (challanType) =>
          challanType &&
          challanType.length > 0 &&
          // challanType[0].typename === "Job Work Outward" ||
          (challanType[0].typename === "Job Work Inward" ||
            challanType[0].typename === "Packing"),
        then: yup.array().of(
          yup.object().shape({
            particulars: yup.string().required("Particulars is required!"),
            uom: yup
              .array()
              .min(1, "Please select unit!")
              .required("Please select unit!"),
            qty: yup
              .string()
              .required("Quantity is required!")
              .matches(/^[0-9]+(\.[0-9]+)?$/, "Enter valid quantity")
              .typeError("Enter valid quantity")
              .test("max-qty", function (value, context) {
                const index = context.path.match(/\d+/);
                // console.log("index",index)
                console.log("value", value);
                // console.log("challantype",challantype);
                // console.log("seloutward",seloutwardchallan)
                if (index && available_product_qty[index[0]] !== undefined) {
                  const maxQty = available_product_qty[index[0]];
                  console.log("maxQty", maxQty);
                  return (
                    value <= maxQty.qty ||
                    new yup.ValidationError(
                      `Quantity cannot exceed ${maxQty.qty}`,
                      null,
                      context.path
                    )
                  );
                }
                return true;
              }),
            operation: yup
              .array()
              .min(1, "Please select operation!")
              .required("Please select operation!"),
            job_card: yup
              .array()
              .min(1, "Please select job card!")
              .required("Please select job card!"),
            work_order: yup
              .array()
              .min(1, "Please select work order!")
              .required("Please select work order!"),
          })
        ),
        otherwise: yup.array().of(
          yup.object().shape({
            particulars: yup.string().required("Particulars is required!"),
            uom: yup
              .array()
              .min(1, "Please select unit!")
              .required("Please select unit!"),
            qty: yup
              .string()
              .required("Quantity is required!")
              .matches(/^[0-9]+(\.[0-9]+)?$/, "Enter valid quantity")
              .typeError("Enter valid quantity")
              .test("max-qty", function (value, context) {
                const index = context.path.match(/\d+/);
                // console.log("index",index)
                // console.log("value",value)
                if (index && available_product_qty[index[0]] !== undefined) {
                  const maxQty = available_product_qty[index[0]];
                  // console.log("maxQty",maxQty)
                  return (
                    value <= maxQty.qty ||
                    new yup.ValidationError(
                      `Quantity cannot exceed ${maxQty.qty}`,
                      null,
                      context.path
                    )
                  );
                }
                return true;
              }),
          })
        ),
      }),
      purchase_inward: yup
        .array()
        .when(["challanType"], (challanType, schema) => {
          if (
            challanType !== undefined &&
            challanType.length > 0 &&
            challanType[0].typename == "Goods Return"
          ) {
            return schema
              .min(1, "Please select purchase inward !")
              .required("Please select purchase inward !");
          } else {
            return schema;
          }
        }),
      adjustment_reason: yup
        .array()
        .when(["challanType"], (challanType, schema) => {
          if (
            challanType !== undefined &&
            challanType.length > 0 &&
            challanType[0].typename == "Adjustment Challan"
          ) {
            return schema
              .min(1, "Please select adjustment reason !")
              .required("Please select adjustment reason !");
          } else {
            return schema;
          }
        }),
      adjustment_type: yup
        .array()
        .when(["challanType"], (challanType, schema) => {
          if (
            challanType !== undefined &&
            challanType.length > 0 &&
            challanType[0].typename == "Adjustment Challan"
          ) {
            return schema
              .min(1, "Please select adjustment type !")
              .required("Please select adjustment type !");
          } else {
            return schema;
          }
        }),
      organization: yup
        .array()
        .min(1, "Please select organization !")
        .required("Please select organization !"),
    })
    .required();
  const {
    register,
    handleSubmit,
    formState,
    control,
    reset,
    watch,
    setValue,
    getValues,
    setError,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      items: [
        {
          product: [],
          particulars: "",
          qty: "",
          uom: [],
          operation: [],
          job_card: [],
          work_order: [],
        },
      ],
    },
  });

  const localData = JSON.parse(localStorage.getItem("localData"));

  const selLocation = watch("fromLocation");
  const selStore = watch("fromstore");
  const seloutwardchallan = watch("outwardChallanRef");
  // const adj_type=watch("adjustment_type")

  const { fields, append, remove } = useFieldArray({ control, name: "items" });

  let { errors } = formState;

  useEffect(() => {
    setConstLabel(getLabels("Inventory", "Goods Challan"));

    const result = localData?.organizations?.filter(
      (item) => item.default_org == true
    );
    // console.log("result",result)
    setValue("organization", result);
    if (location.state) {
      setdispatch(true);
      // console.log("locatino",location.state);
      setValue("challanType", [{ typename: location.state.challantype }]);
      setchallantype([{ typename: location.state.challantype }]);

      if (
        location.state.Productdetails &&
        location.state.Productdetails.length > 0
      ) {
        let arr2 = [];
        let arr = location.state.Productdetails.map((item, ind) => {
          let obj = {
            product: [
              {
                productName: item.product_name,
                uuid: item.product_id,
                productId: item.productId,
                partNo: item.partNo,
                hsn_model: item.hsn_model,
                salesPrice: item.salesPrice,
                product_type_id: item.product_type_id,
                productTypename: item.productTypename,
                category_id: item.category_id,
              },
            ],
            particulars: item.product_name,
            uom: [{ uom: item.uom }],
            work_order:
              item.work_order_id && item.work_order_no
                ? [
                    {
                      uuid: item.work_order_id,
                      work_order_no: item.work_order_no,
                      work_order_status: item.work_order_status,
                      quantity: item.work_order_quantity,
                    },
                  ]
                : [],
            qty: item.qty,
          };

          let obj2 = {
            show: true,
            value: item.work_order_quantity ? item.work_order_quantity : 0,
            index: ind,
          };
          arr2.push(obj2);
          return obj;
        });
        // console.log("arr2", arr2);
        setshow_work_quantity(arr2);
        // console.log("arr",arr)
        setValue("items", arr);
      }
      setValue("date", new Date(location?.state?.challandate));
      setValue("status", "Transfer");
      if (
        location.state.to_locationdata &&
        location.state.to_locationdata.length > 0
      ) {
        // console.log("asldjk inside the location",location.state.to_locationdata)
        setValue("toLocation", location.state.to_locationdata);
      } else {
        setValue("toLocation", []);
      }

      if (
        location.state.to_store_data &&
        location.state.to_store_data.length > 0
      ) {
        setValue("tostore", location.state.to_store_data);
      } else {
        setValue("tostore", []);
      }

      if (
        location.state.from_locationdata &&
        location.state.from_locationdata.length > 0
      ) {
        // console.log("asldjk inside the location",location.state.from_locationdata)
        setValue("fromLocation", location.state.from_locationdata);
      } else {
        setValue("fromLocation", []);
      }

      if (
        location.state.from_store_data &&
        location.state.from_store_data.length > 0
      ) {
        setValue("fromstore", location.state.from_store_data);
      } else {
        setValue("fromstore", []);
      }
    }
    fetchLocationList();
    fetchOperationList();

    fetchUOM();
    fetchtransport();
    fetchinternaltransport();
    fetchStoreList();
    fetchChallantype();
    fetchWorkOrders();
    // fetchJobCardList();
    fetchAdjustmentReasonList();
    fetchPOInvList();
    // fetchproducts();
    fetchDispatchData();
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = function () {
      window.history.pushState(null, "", window.location.href);
    };
  }, []);

  useEffect(() => {
      const debounceTimeout = setTimeout(() => {
        if (prod.trim().length >= 4) {
          fetchproducts(prod);
        } 
      }, 1000); // Adjust the delay as needed (e.g., 500 milliseconds)
  
      return () => {
        clearTimeout(debounceTimeout); // Clear the timeout if searchTerm changes before the timeout expires
      };
    }, [prod]);

  function fetchUOM() {
    setUOMList({ ...uomList, data: [], loading: true });
    getUOM().then(
      (res) => {
        setUOMList({ ...uomList, data: res.data.data, loading: false });
      },
      (err) => {
        setUOMList({ ...uomList, data: [], loading: false });
      }
    );
  }

  function fetchChallantype() {
    setchallantypeList({ data: [], loading: true });
    get_challan_type().then(
      (res) => {
        // console.log("arr",arr)
        setchallantypeList({ data: res.data.data, loading: false });
      },
      (err) => {
        setchallantypeList({ data: [], loading: false });
      }
    );
  }

  function fetchStoreList() {
    setstoreList({ loading: true, data: [] });

    get_store().then(
      (res) => {
        setstoreList({
          loading: false,
          data: res.data.data.filter(
            (item) =>
              item.name !== "" && item.name !== undefined && item.name !== null
          ),
        });
      },
      (err) => {
        setstoreList({ loading: false, data: [] });
      }
    );
  }

  function fetchtransport() {
    settransportList({ data: [], loading: true });
    get_challan_transport().then(
      (res) => {
        settransportList({ data: res.data.transportlist, loading: false });
      },
      (err) => {
        settransportList({ data: [], loading: false });
      }
    );
  }
  
  function fetchinternaltransport() {
    setinternaltransportList({ data: [], loading: true });
    get_challan_internal_transport().then(
      (res) => {
        setinternaltransportList({
          data: res.data.InternalTransportlist,
          loading: false,
        });
      },
      (err) => {
        setinternaltransportList({ data: [], loading: false });
      }
    );
  }

  function fetchLocationList() {
    setLocList({ data: [], loading: true });

    getLocation().then(
      (res) => {
        // let arr1=res.data.data.filter((item)=>item.internal_company_id==null);
        // let arr2=res.data.data.filter((item)=>item.internal_company_id !== null)
        settolocdata({ data: res.data.data, loading: false });
        setfromlocdata({ data: res.data.data, loading: false });
        setLocList({ data: res.data.data, loading: false });
      },
      (err) => {
        setLocList({ data: [], loading: false });
      }
    );
  }

  function fetchOperationList() {
    setOpList({ ...opList, data: [], loading: true });
    getOperationList().then(
      (res) => {
        setOpList({ ...opList, data: res.data.data, loading: false });
      },
      (err) => {
        setOpList({ ...opList, data: [], loading: false });
      }
    );
  }

  function filter_store(uuid, type, location) {
    // console.log("uuid", uuid);
    let arr = storeList.data.filter((item) => item.location_id == uuid);
    // console.log("type", type);
    let find_adjustment_store = storeList.data.filter(
      (item) => item.name == "Adjustment Store"
    );
    // console.log("arr", arr);
    // console.log("find_adjustment_store",find_adjustment_store)

    if (
      challantype &&
      challantype.length > 0 &&
      challantype[0].typename == "Adjustment Challan"
    ) {
      if (type == "from") {
        setValue("toLocation", location);
        setValue("tostore", find_adjustment_store);
        setValue("status", "Issue");
      } else {
        setValue("fromLocation", location);
        setValue("fromstore", find_adjustment_store);
        setValue("status", "Receive");
      }
    }
    if (challantype && challantype.length > 0 && !challantype[0].sameLocation) {
      let to_location_array = tolocdata.data.filter(
        (item) => item.uuid !== uuid
      );
      // console.log("to_location_array",to_location_array.length)
      // console.log("tolocdata",tolocdata.data.length)
      if (type == "to") {
        setfromlocdata({ data: to_location_array, loading: false });
      } else {
        settolocdata({ data: to_location_array, loading: false });
      }
    }
    if (type == "to") {
      settoStorelist({ data: arr, loading: false });
    } else {
      setfromStorelist({ data: arr, loading: false });
    }
  }

  function fetchWorkOrders() {
    setworkorderList({ data: [], loading: true });

    get_WorkOrder({ work_order_status: ["Released", "Completed"] }).then(
      (res) => {
        setworkorderList({
          data: res.data.data,
          loading: false,
        });
      },
      (err) => {
        setworkorderList({ data: [], loading: false });
      }
    );
  }

  function fetchJobCardList(uuid,obj) {
    setjobcardList({ data: [], loading: true });

    let payload = {
      work_order_id: uuid,
    };
    get_job_card(payload).then(
      (res) => {
        let arr = res.data.data;
        if (
          challantype &&
          challantype.length > 0 &&
          challantype[0].typename == "Packing"
        ) {
          // console.log("job card list",res.data.data);
           
          if(obj?.global_route_uuid!==undefined && obj?.global_route_uuid !==null){
            let operation_arr=obj?.globalrouteData?.routeData;
             if(operation_arr.length>0){
              let max = -Infinity;
              let find_max = [];
              for (let i = 0; i < operation_arr.length; i++) {
                let item = operation_arr[i];
                if (Number(item.sequence_no) > max) {
                  max = Number(item.sequence_no);
                  find_max[0] = item;
                }
              };
              console.log("find_max",find_max);

              let max_arr = res.data.data.filter(
                (item) =>
                  item.operation_uuid ==
                  find_max[0].operation_id
              );
              console.log("max_arr",max_arr)
              
                arr = max_arr;
             
             }else{
              arr=[]
             }
          }else{
            let max = -Infinity;
            let find_max = [];
            for (let i = 0; i < res.data.data.length; i++) {
              let item = res.data.data[i];
              if (Number(item.productOperDetails.sequenceNo) > max) {
                max = Number(item.productOperDetails.sequenceNo);
                find_max[0] = item;
              }
            }
           
            let max_arr = res.data.data.filter(
              (item) =>
                item.productOperDetails.sequenceNo ==
                find_max[0].productOperDetails.sequenceNo
            );
            // console.log("max_arr",max_arr)
            if (max_arr.length > 1) {
              arr = max_arr;
            } else {
              arr = find_max;
            }
          }
        

          
        }
        setjobcardList({
          data: arr,
          loading: false,
        });
      },
      (err) => {
        setjobcardList({ data: [], loading: false });
      }
    );
  }

  function fetch_outward_challan() {
    setchallanList({ loading: true, data: [] });

    const payload = {
      pageNo: 1,
      entriesPerPage: 100,
      outward: true,
    };

    get_challan(payload).then(
      (res) => {
        setchallanList({
          loading: false,
          data: res.data.data,
        });
      },
      (err) => {
        setchallanList({ loading: false, data: [] });
      }
    );
  }

  function fetchproducts(prod) {
    setproducts({ loading: true, data: [] });

    get_product_light({searchVal: prod}).then(
      (res) => {
        let arr = res.data.data.filter(
          (item) => item.productName !== null || item.productName !== undefined
        );
        // console.log("arr",arr)
        setproducts({
          loading: false,
          data: arr,
        });
      },
      (err) => {
        setproducts({ loading: false, data: [] });
      }
    );
  }

  function find_ops_from_product(product_id, route_id) {
    const payload = {
      product_id: product_id,
      routes_uuid: route_id,
    };
    getProdConfig(payload)
      .then((res) => {
        console.log("res", res.data);
        if (res?.data?.data?.length > 0) {
          let arr = res.data.data.map((item) => {
            let obj = {
              operationName: item.operation_details?.operationName
                ? item.operation_details.operationName
                : null,
              uuid: item.operation_details?.uuid
                ? item.operation_details.uuid
                : null,
            };
            return obj;
          });
          console.log("arr", arr);
          setOpList({ data: arr, loading: false });
        } else {
          setOpList({ data: [], loading: false });
        }
      })
      .catch((err) => {
        console.log("err", err);
        setOpList({ data: [], loading: false });
      });
  }

  const onSubmit = async (data) => {
    console.log("data", data);

    setbuttonclick(true);

    let from_gst;
    let to_gst;
    let gst_info;

    if (
      data.challanType[0].typename.endsWith("Outward") ||
      data.challanType[0].typename == "Dispatch Challan" ||
      data.challanType[0].typename == "Packing" ||
      data.challanType[0].typename == "Customer Return Material Inward" ||
      data.challanType[0].typename == "Adjustment Challan"
    ) {
      from_gst = data.fromLocation[0].company?.comGSTnumber;
      to_gst = data.toLocation[0].locationList?.gst;

      //  console.log("from_gst",from_gst);
      //  console.log("to_gst",to_gst);

      if (data.challanType[0].typename == "Customer Return Material Inward" && data.outwardChallanRef==undefined) {
        to_gst = data.toLocation[0].company?.comGSTnumber;
        from_gst = data.fromLocation[0].locationList?.gst;
      }

      console.log("from_gst", from_gst);
      console.log("to_gst", to_gst);
      try {
        let gst = await getGST({
          suplier_gst_number: to_gst,
          invoice_gst_number: from_gst,
        });

        gst_info = gst.data;
      } catch (error) {
        // toast.error(error.response.data.message, {
        //   autoClose: 3000,
        // });
        // setbuttonclick(false)
        //     return
      }
    }

    //  console.log("gst_info",gst_info)

    // setbuttonclick(true)
    let products_array = data.items.map((item) => {
      let obj = {
        qty: item.qty,
        uom: item.uom[0].uom,
        product_id: item.product[0].uuid,
        productId: item.product[0].productId,
        product_name:
          item.product[0].productName !== "Other"
            ? item.product[0].productName
            : item.particulars,
        partNo: item.product[0].partNo ? item.product[0].partNo : null,
        product_type_id: item?.product?.[0]?.product_type_id
          ? item.product[0].product_type_id
          : null,
        product_type_name: item?.product?.[0]?.productTypename
          ? item?.product?.[0]?.productTypename
          : null,
        product_category_id: item?.product?.[0]?.category_id
          ? item?.product?.[0]?.category_id
          : null,
        operation_id:
          item.operation && item.operation.length > 0
            ? item.operation[0].uuid
              ? item.operation[0].uuid
              : null
            : null,
        operation_name:
          item.operation && item.operation.length > 0
            ? item.operation[0].operationName
              ? item.operation[0].operationName
              : null
            : null,
        job_card_id:
          item.job_card && item.job_card.length > 0
            ? item.job_card[0].uuid
              ? item.job_card[0].uuid
              : null
            : null,
        job_card_no:
          item.job_card && item.job_card.length > 0
            ? item.job_card[0].uuid
              ? item.job_card[0].job_card_no
              : null
            : null,
        work_order_id:
          item.work_order && item.work_order.length > 0
            ? item.work_order[0].uuid
              ? item.work_order[0].uuid
              : null
            : null,
        work_order_no:
          item.work_order && item.work_order.length > 0
            ? item.work_order[0].work_order_no
              ? item.work_order[0].work_order_no
              : null
            : null,
        work_order_status:
          item.work_order && item.work_order.length > 0
            ? item.work_order[0].work_order_status
              ? item.work_order[0].work_order_status
              : null
            : null,
        work_order_quantity:
          item.work_order && item.work_order.length > 0
            ? item.work_order[0].quantity
              ? `${item.work_order[0].quantity}`
              : null
            : null,
      };

      if (obj.operation_id == null) {
        delete obj.operation_id;
        delete obj.operation_name;
      }
      if (obj.job_card_id == null) {
        delete obj.job_card_id;
        delete obj.job_card_no;
      }
      if (obj.work_order_id == null) {
        delete obj.work_order_id;
        delete obj.work_order_no;
        delete obj.work_order_quantity;
        delete obj.work_order_status;
      }
      if (obj.partNo == null) {
        delete obj.partNo;
      }
      if (obj.product_category_id == null) {
        delete obj.product_category_id;
      }
      if (obj.product_type_id == null) {
        delete obj.product_type_id;
      }
      if (obj.product_type_name == null) {
        delete obj.product_type_name;
      }

      if (
        data.challanType[0].typename == "General Outward" ||
        data.challanType[0].typename == "Adjustment Challan" ||
        data.challanType[0].typename == "Inter Store Transfer" ||
        data.challanType[0].typename == "Customer Return Material Inward" ||
        data.challanType[0].typename == "Goods Return" ||
        data.outwardChallanRef ==undefined
      ) {
        obj.product_type_id = obj?.product_type_id?.uuid;
        obj.product_category_id = obj?.product_category_id?.uuid;
      }

      // if(data.challanType[0].typename == "Job Work Outward" &&!obj.job_card_id){
      //   console.log("inside")
      //   obj.product_type_id = obj?.product_type_id?.uuid;
      //   obj.product_category_id = obj?.product_category_id?.uuid;
      // }

      if (
        obj.work_order_quantity &&
        obj.work_order_quantity == null &&
        item?.work_order?.[0]?.quantity == undefined
      ) {
        // console.log("work_order_quantity",item.work_order_quantity)
        obj.work_order_quantity = item.work_order[0].work_order_quantity
          ? `${item.work_order_quantity}`
          : 0;
      }
      if (
        data.challanType[0].typename.endsWith("Outward") ||
        data.challanType[0].typename == "Dispatch Challan" ||
        data.challanType[0].typename == "Packing" ||
        data.challanType[0].typename == "Customer Return Material Inward" ||
        data.challanType[0].typename == "Adjustment Challan" ||
        data.outwardChallanRef==undefined
      ) {
        let gst_rate = item.product[0]?.hsn_model?.gst_rate
          ? item.product[0]?.hsn_model?.gst_rate / 100
          : 0;
        let hsn = item.product[0]?.hsn_model?.hsn_sac_code
          ? item.product[0]?.hsn_model?.hsn_sac_code
          : 0;
        let amount = item.product[0].salesPrice
          ? Number(item.product[0].salesPrice)
          : 0;
        let gst_amount = (obj.qty * amount * gst_rate).toFixed(2);
        // console.log("gst_rate",gst_rate);
        // console.log("amount",amount);
        // console.log("gst_amount",gst_amount);
        // console.log("hsn",hsn);
        obj.hsn = Number(hsn);
        obj.amount = amount;
        if (gst_info && gst_info.type == "igst") {
          obj.igst_rate = gst_rate;
          obj.igst_amount = Number(gst_amount);
          obj.cgst_rate = 0;
          obj.cgst_amount = 0;
          obj.sgst_rate = 0;
          obj.sgst_amount = 0;
          obj.ugst_rate = 0;
          obj.ugst_amount = 0;
        } else if (gst_info && gst_info.type == "ugst") {
          let split_gst = gst_rate / 2;
          let split_amount = gst_amount / 2;
          // console.log("split_gst",split_gst)
          // console.log("split_amount",split_amount)
          obj.igst_rate = 0;
          obj.igst_amount = 0;
          obj.cgst_rate = split_gst;
          obj.cgst_amount = split_amount;
          obj.sgst_rate = 0;
          obj.sgst_amount = 0;
          obj.ugst_rate = split_gst;
          obj.ugst_amount = split_amount;
        } else if (gst_info && gst_info.type == "sgst") {
          let split_gst = gst_rate / 2;
          let split_amount = gst_amount / 2;
          // console.log("split_gst",split_gst)
          // console.log("split_amount",split_amount)
          obj.igst_rate = 0;
          obj.igst_amount = 0;
          obj.cgst_rate = split_gst;
          obj.cgst_amount = split_amount;
          obj.sgst_rate = split_gst;
          obj.sgst_amount = split_amount;
          obj.ugst_rate = 0;
          obj.ugst_amount = 0;
        } else {
          obj.igst_rate = 0;
          obj.igst_amount = 0;
          obj.cgst_rate = 0;
          obj.cgst_amount = 0;
          obj.sgst_rate = 0;
          obj.sgst_amount = 0;
          obj.ugst_rate = 0;
          obj.ugst_amount = 0;
        }
      }
      if (
        data.challanType[0].typename.endsWith("Inward") &&
        data.challanType[0].typename !== "Customer Return Material Inward" &&data.outwardChallanRef
      ) {
        obj.hsn = Number(item.hsn);
        obj.amount = item.amount;
        if (item.igst_rate !== 0) {
          obj.igst_rate = item.igst_rate;
          obj.igst_amount = Number(item.amount * item.igst_rate * item.qty);
          obj.cgst_rate = 0;
          obj.cgst_amount = 0;
          obj.sgst_rate = 0;
          obj.sgst_amount = 0;
          obj.ugst_rate = 0;
          obj.ugst_amount = 0;
        } else if (item.ugst_rate !== 0) {
          obj.igst_rate = 0;
          obj.igst_amount = 0;
          obj.cgst_rate = item.cgst_rate;
          obj.cgst_amount = item.cgst_rate * item.amount * item.qty;
          obj.sgst_rate = 0;
          obj.sgst_amount = 0;
          obj.ugst_rate = item.ugst_rate;
          obj.ugst_amount = item.ugst_rate * item.amount * item.qty;
        } else if (item.sgst_rate !== 0) {
          obj.igst_rate = 0;
          obj.igst_amount = 0;
          obj.cgst_rate = item.cgst_rate;
          obj.cgst_amount = item.cgst_rate * item.amount * item.qty;
          obj.sgst_rate = item.sgst_rate;
          obj.sgst_amount = item.sgst_rate * item.amount * item.qty;
          obj.ugst_rate = 0;
          obj.ugst_amount = 0;
        } else {
          obj.igst_rate = 0;
          obj.igst_amount = 0;
          obj.cgst_rate = 0;
          obj.cgst_amount = 0;
          obj.sgst_rate = 0;
          obj.sgst_amount = 0;
          obj.ugst_rate = 0;
          obj.ugst_amount = 0;
        }
      }
      return obj;
    });

    let payload_obj = {
      challantype: data.challanType[0].typename,
      vehicalnumber:
        data.transportType[0].id == 1
          ? data.internaltransport[0].name
          : data.transportType[0].id == 2
          ? data.extTransport
          : data.airwayTransport,
      transporttype: `${data.transportType[0].id}`,
      challanstate: data.status,
      drivername: data.driverName,
      remark: data.remark,
      challandate: convertDateYYMMDD(data.date, "-"),
      org_id: data.organization[0].uuid,
      from_store_id: data.fromstore[0].uuid,
      from_store_name: data.fromstore[0].name,
      from_location_id: data.fromLocation[0].uuid,
      to_store_id: data.tostore[0].uuid,
      to_store_name: data.tostore[0].name,
      to_location_id: data.toLocation[0].uuid,
      Productdetails: products_array,
      challanno: data.challanNo,
      challanRef: data.challanRefNo,
    };

    if (data.challanType[0].typename == "Goods Return") {
      payload_obj.pi_id = data.purchase_inward[0].uuid;
    }

    if (
      data.challanType[0].typename.endsWith("Inward") &&
      data.challanType[0].typename  !== "Customer Return Material Inward" 
    ) {
     if(data.outwardChallanRef){ payload_obj.outwardchallanid = [
        {
          challanid: qty_data.outwardchallan_id,
        },
      ];}else{
        payload_obj.outwardchallanid=[]
      }
      payload_obj.party_id = data.fromLocation[0].party_id
        ? data.fromLocation[0].party_id
        : null;
    } else {
      payload_obj.outwardchallanid = [];
      payload_obj.party_id = data.toLocation[0].party_id
        ? data.toLocation[0].party_id
        : null;
    }

    if (data.challanType[0].typename == "Dispatch Challan") {
      payload_obj.dispatch_id = location.state.dispatch_id;
    }

    if (data.challanType[0].typename == "Customer Return Material Inward") {
      payload_obj.dispatch_id = data?.dispatch?.[0]?.uuid
        ? data.dispatch[0].uuid
        : null;
        payload_obj.party_id = data.fromLocation[0].party_id
        ? data.fromLocation[0].party_id
        : null;
    }

    if (data.lrn) {
      payload_obj.lrn = data.lrn;
    }

    if (data.challanType[0].typename == "Adjustment Challan") {
      payload_obj.adj_reason_id = data.adjustment_reason[0].uuid;
      payload_obj.remark = data.adjustment_reason[0].name;
    }

    console.log("payoad", payload_obj);

    save_challan(payload_obj).then(
      (res) => {
        if (res.status === 200 || res.status === 201) {
          toast.success("Challan created successfully !", {
            autoClose: 2000,
          });
          setbuttonclick(false);

          setTimeout(() => {
            navigate("/pcms/goodsMovementChallan");
          }, 2200);
        }
      },
      (err) => {
        // console.log("err", err);
        setbuttonclick(false);
        // console.log("err.response.data.message",err.response.data.message)
        if (err.response.status === 400) {
          toast.error(err.response.data.message, {
            autoClose: 2000,
          });

          // reset();
        } else {
          if (err.response.status === 500) {
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
          } else {
            toast.error("Something went wrong ", {
              autoClose: 2000,
            });
          }
        }
      }
    );
  };

  function set_data_inward(val) {
    // console.log("val", val);

    setValue("fromLocation", [{ ...val.toDetails }], { shouldValidate: true });
    setValue("fromstore", [{ ...val.tostoreDetails }], {
      shouldValidate: true,
    });
    setValue("toLocation", [{ ...val.fromDetails }], { shouldValidate: true });
    setValue("tostore", [{ ...val.fromstoreDetails }], {
      shouldValidate: true,
    });

    // console.log("valdfgs",val.challan_line_items);

    let arr2 = [];
    let arr = val.challan_line_items.map((item, ind) => {
      // let job_card_find=jobcardList.data.filter((ele)=>ele.uuid==item.job_card_id);
      // let work_order_find=workorderList.data.filter((ele)=>ele.uuid==item.work_order_id);

      // console.log("job_card_find",job_card_find)

      let obj = {
        product: [
          {
            productName: item.product_name,
            uuid: item.product_id,
            productId: item.productId,
            partNo: item.partNo,
            product_type_id: item.product_type_id,
            category_id: item.product_category_id,
            productTypename: item.product_type_name,
          },
        ],
        particulars: item.product_name,
        operation:
          item.operation_name && item.operation_id
            ? [{ operationName: item.operation_name, uuid: item.operation_id }]
            : [],
        uom: [{ uom: item.uom }],
        work_order:
          item.work_order_id && item.work_order_no
            ? [
                {
                  uuid: item.work_order_id,
                  work_order_no: item.work_order_no,
                  work_order_status: item.work_order_status,
                  quantity: item.work_order_quantity,
                },
              ]
            : [],
        job_card:
          item.job_card_id && item.job_card_no
            ? [{ uuid: item.job_card_id, job_card_no: item.job_card_no }]
            : [],
      };

      if (
        challantype &&
        challantype.length > 0 &&
        challantype[0].typename == "Job Work Inward"
      ) {
        obj.hsn = item.hsn;
        obj.amount = item.amount;
        obj.igst_rate = item.igst_rate;
        obj.igst_amount = item.igst_amount;
        obj.cgst_rate = item.cgst_rate;
        obj.cgst_amount = item.cgst_amount;
        obj.sgst_rate = item.sgst_rate;
        obj.sgst_amount = item.sgst_amount;
        obj.ugst_rate = item.ugst_rate;
        obj.ugst_amount = item.ugst_amount;
      }
      let obj2 = {
        show: true,
        value: item.work_order_quantity ? item.work_order_quantity : 0,
        index: ind,
      };
      arr2.push(obj2);
      return obj;
    });
    // console.log("arr", arr);

    if (challantype && challantype.length > 0 && !challantype[0].sameLocation) {
      let to_location_array = locList.data.filter(
        (item) => item.uuid !== val.toDetails.uuid
      );
      // console.log("to_location_array", to_location_array.length);
      // console.log("val.toDetails.uuid", val.toDetails.uuid);
      // console.log("tolocdata", tolocdata.data.length);

      //  console.log("to_location_array",to_location_array)

      settolocdata({ data: to_location_array, loading: false });
    }
    setshow_work_quantity(arr2);
    // console.log("arr",arr)
    setValue("items", arr, { shouldValidate: true });

    get_available_quatity(val.gmchallanid);
  }

  function get_available_quatity(outchallanid) {
    let payload = {
      outwardchallan_id: outchallanid,
    };
    get_last_challan(payload).then(
      (res) => {
        // console.log("res", res.data);
        setqty_data(res.data);
      },
      (err) => {
        setqty_data(null);
      }
    );
  }

  function fetchPOInvList() {
    setpiList({ data: [], loading: true });
    get_purchaseInward_node().then(
      (res) => {
        // let arr = res.data.data.map((item) => {
        //   let obj = {
        //     ...item,
        //     poid: item?.po_details?.poid,
        //   };
        //   return obj;
        // });

        let arr = res.data.data
          .filter((itm) =>itm?.po_details?.poid !== "" && itm?.po_details?.poid !== null && itm?.po_details?.poid !== undefined) // Filter items with valid poid
          .map((item) => ({
            ...item,
            poid: item.po_details.poid,
          }));

        setpiList({ data: arr, loading: false });
      },
      (err) => {
        setpiList({ data: [], loading: false });
      }
    );
  }

  function fetchAdjustmentReasonList() {
    setadjustmentlist({ data: [], loading: true });
    get_adjustment_reason().then(
      (res) => {
        setadjustmentlist({ data: res.data.data, loading: false });
      },
      (err) => {
        setadjustmentlist({ data: [], loading: false });
      }
    );
  }

  function fetchStoreQty(wo_data, ind) {
    // console.log("selLocation",selLocation)
    if (
      challantype.length > 0 &&
      (challantype[0].typename.endsWith("Outward") ||
        challantype[0].typename == "Packing") &&
      selLocation &&
      selLocation.length > 0
    ) {
      get_dispatch_pkg_store_qty({
        work_order_uuid: wo_data.uuid,
        location_uuid: selLocation?.[0]?.uuid,
        wip: true,
      }).then(
        (res) => {
          // console.log("res", res);

          let obj = {};
          obj.index = ind;
          obj.productName = wo_data.product_info.productName;
          obj.previous_issued = res.data.data.storeDetails.issue;
          obj.qty = res.data.data.storeDetails.availqty;

          let arr = [...available_product_qty, obj];

          // console.log("arr",arr)
          const uniqueProducts = arr.filter(
            ((product) => {
              const seen = new Set();
              return (obj) => {
                const isUnique = !seen.has(obj.productName);
                seen.add(obj.productName);
                return isUnique;
              };
            })()
          );
          // console.log("uniqueProducts",uniqueProducts)

          setavailable_product_qty(uniqueProducts);
        },
        (err) => {
          console.log("err", err);
        }
      );
    } else {
      toast.error(
        "Please select from location & challan type for available quantity !",
        {
          autoClose: 2000,
        }
      );
      setValue("items", [
        {
          product: [],
          particulars: "",
          qty: "",
          uom: [],
          operation: [],
          work_order: [],
          job_card: [],
        },
      ]);
      setshow_work_quantity([{ index: 0, show: false, value: 0 }]);
      setavailable_product_qty([]);
    }
  }

  function fetchproductqty(product_id, product_name, ind) {
    // console.log("getFinancialYearStartDate",getFinancialYearStartDate())
    // console.log("convertDateYYMMDD(new Date(getFinancialYearStartDate), "-")",convertDateYYMMDD(new Date(getFinancialYearStartDate()), "-"))
    // console.log("product_id", product_id);
    // console.log("seloutward",seloutwardchallan); 
    // console.log('challantype',challantype)

   
    if (
      challantype.length > 0 &&
      // challantype[0].typename == "Inter Store Transfer" &&
      selLocation &&
      selLocation.length > 0 &&
      selStore &&
      selStore.length > 0
    ) {

      if((seloutwardchallan==undefined && challantype[0].typename=="General Inward") || challantype[0].typename=="Adjustment Challan"){
        // console.log("in")
        return 
      }
      const payload = {
        entriesPerPage: 10,
        pageNo: 1,
        startDate: convertDateYYMMDD(
          new Date(getFinancialYearStartDate()),
          "-"
        ),
        endDate: convertDateYYMMDD(new Date(), "-"),
        productid_id: product_id,
        location_id: selLocation?.[0]?.uuid,
        store_id: selStore?.[0]?.uuid,
        showZero: false,
      };

      get_stock_by_product(payload).then(
        (res) => {
          console.log("res---fetch-product", res.data);

          let obj = {};
          obj.index = ind;
          obj.productName = product_name;
          obj.previous_issued = 0;
          obj.qty = res.data.data[0].quantity;

          let arr = [...available_product_qty, obj];

          // console.log("arr",arr)
          const uniqueProducts = arr.filter(
            ((product) => {
              const seen = new Set();
              return (obj) => {
                const isUnique = !seen.has(obj.productName);
                seen.add(obj.productName);
                return isUnique;
              };
            })()
          );
          console.log("uniqueProducts",uniqueProducts)
          
          setavailable_product_qty(uniqueProducts);
        },
        (err) => {
          console.log("err--->", err);
          if(err?.response?.data?.message=="No records found"){
            let obj = {};
            obj.index = ind;
            obj.productName = product_name;
            obj.previous_issued = 0;
            obj.qty = 0;
  
            let arr = [...available_product_qty, obj];
  
            // console.log("arr",arr)
            const uniqueProducts = arr.filter(
              ((product) => {
                const seen = new Set();
                return (obj) => {
                  const isUnique = !seen.has(obj.productName);
                  seen.add(obj.productName);
                  return isUnique;
                };
              })()
            );
            console.log("uniqueProducts",uniqueProducts)
            
            setavailable_product_qty(uniqueProducts);
          }
        }
      );
    } else {
      toast.error(
        "Please select from location & from store & challan type for available product quantity !",
        {
          autoClose: 2000,
        }
      );
      setValue("items", [
        {
          product: [],
          particulars: "",
          qty: "",
          uom: [],
          operation: [],
          work_order: [],
          job_card: [],
        },
      ]);
      setshow_work_quantity([{ index: 0, show: false, value: 0 }]);
      setavailable_product_qty([]);
    }
  }

  function getFinancialYearStartDate() {
    const today = new Date();
    const year = today.getMonth() >= 3 ? today.getFullYear() - 1 : today.getFullYear() - 2;
    return new Date(year, 3, 1);
  }

  function fetchDispatchData() {
    setDispatchList({ ...dispatchList, loading: true, data: [] });
    const payload = {
      entriesPerPage: 100,
      pageNo: 1,
    };
    get_dispatch(payload).then(
      (res) => {
        // console.log("res", res);
        setDispatchList({
          loading: false,
          data: res.data.data,
        });
      },
      (err) => {
        setDispatchList({ loading: false, data: [] });
      }
    );
  }

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="manufac_goods_form mb-4">
        <div className="card">
          <div className="card-header bg-grey d-flex align-items-center">
            <button
              className="btn btn-yellow"
              onClick={() => {
                if (
                  window.confirm(
                    "Are you sure to go back, your present data will be lost !"
                  )
                ) {
                  window.onpopstate = undefined;
                  window.history.back();

                  setTimeout(() => {
                    navigate("/pcms/goodsMovementChallan");
                  }, [1000]);
                }
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} size="sm" />
            </button>

            <p className="mb-0 ms-3" style={{ fontSize: "24px" }}>
              {constLabel?.lbl_add_goods_challan
                ? constLabel.lbl_add_goods_challan
                : "Create Challan"}
            </p>
          </div>
          <div className="card-body">
            <div className="createNc container-fluid">
              <p className="roboto-bold mb-0" style={{ fontSize: "20px" }}>
                {constLabel?.lbl_challan_details
                  ? constLabel.lbl_challan_details
                  : "Challan Details"}
              </p>
              <hr className="my-2" />

              <div className="row mt-2 col-12">
                <div className="col-md-3 mt-3">
                  <div className="form-group typeahead-container mt-md-0 mt-3 ">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_challan_type
                        ? constLabel.lbl_challan_type
                        : "Challan Type"}
                      <span className="text-danger"> *</span>
                    </label>

                    <Controller
                      name={`challanType`}
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          id="basic-typeahead-single"
                          labelKey="typename"
                          multiple={false}
                          options={challantypeList.data}
                          isLoading={challantypeList.loading}
                          placeholder="Select challan type"
                          disabled={dispatch}
                          onChange={(selected) => {
                            onChange(selected);
                            //  console.log("selected", selected);
                            if (selected && selected.length > 0) {
                              setchallantype(selected);

                              if (
                                selected[0].typename == "Packing" ||
                                selected[0].typename ==
                                  "Inter Store Transfer" ||
                                selected[0].typename == "Adjustment Challan"
                              ) {
                                setValue("transportType", [
                                  { id: 1, name: "Internal" },
                                ]);
                                settranporttype([{ id: 1, name: "Internal" }]);
                                setValue("internaltransport", [{ name: "NA" }]);
                                setValue("driverName", "NA");
                                // setValue("status","Transfer")
                              } else {
                                setadj_type(undefined);
                                setValue("transportType", []);
                                settranporttype(null);
                                setValue("internaltransport", []);
                                setValue("driverName", "");
                              }

                              if (selected[0].typename.endsWith("Inward")) {
                                setValue("status", "Received");

                                fetch_outward_challan();
                              } else {
                                setValue("status", "Issued");
                              }
                            } else {
                              setchallantype([]);
                              setValue("items", [
                                {
                                  product: [],
                                  particulars: "",
                                  qty: "",
                                  uom: [],
                                  operation: [],
                                  work_order: [],
                                  job_card: [],
                                },
                              ]);
                              setshow_work_quantity([
                                { index: 0, show: false, value: 0 },
                              ]);
                              setavailable_product_qty([]);
                            }
                          }}
                          onBlur={onBlur}
                          selected={value}
                          isInvalid={errors.challanType ? true : false}
                        />
                      )}
                    />
                    {!challantypeList.loading && !errors.challanType && (
                      <span className="arrow-icon"></span>
                    )}
                    {errors.challanType && (
                      <span className="text-danger err-msg">
                        {errors.challanType.message}
                      </span>
                    )}
                  </div>
                </div>
                {/* {console.log("adj",adj_type)} */}
                {challantype &&
                  challantype.length > 0 &&
                  challantype[0].typename == "Adjustment Challan" && (
                    <div className="col-md-3 mt-3">
                      <div className="form-group typeahead-container mt-md-0 mt-3 ">
                        <label className="lbl-style roboto-bold">
                          Adjustment Type
                          <span className="text-danger"> *</span>
                        </label>

                        <Controller
                          name={`adjustment_type`}
                          control={control}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <Typeahead
                              id="basic-typeahead-single"
                              labelKey="name"
                              multiple={false}
                              options={adjustment_types}
                              // loading={adjustmentlist.loading}
                              placeholder="Select adjustment type "
                              onChange={(selected) => {
                                onChange(selected);
                                if (selected && selected.length > 0) {
                                  setadj_type(selected);
                                } else {
                                  setadj_type(undefined);
                                  setValue("fromLocation", []);
                                  setValue("fromstore", []);
                                  setValue("toLocation", []);
                                  setValue("tostore", []);
                                }
                              }}
                              onBlur={onBlur}
                              selected={value}
                              isInvalid={errors.adjustment_type ? true : false}
                            />
                          )}
                        />
                        {!adjustment_types && !errors.adjustment_type && (
                          <span className="arrow-icon"></span>
                        )}
                        {errors.adjustment_type && (
                          <span className="text-danger err-msg">
                            {errors.adjustment_type.message}
                          </span>
                        )}
                      </div>
                    </div>
                  )}

                {challantype &&
                  challantype.length > 0 &&
                  challantype[0].typename.endsWith("Inward") &&
                  challantype[0].typename !==
                    "Customer Return Material Inward" && (
                    <div className="col-md-3 mt-3">
                      <div className="form-group typeahead-container mt-md-0 mt-3 ">
                        <label className="lbl-style roboto-bold">
                          Outward challan ref.
                          {/* <span className="text-danger"> *</span> */}
                        </label>

                        <Controller
                          name={`outwardChallanRef`}
                          control={control}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <Typeahead
                              id="basic-typeahead-single"
                              labelKey="gmchallanid"
                              multiple={false}
                              options={challanList.data}
                              loading={challanList.loading}
                              placeholder="Select outward ref challan "
                              onChange={(selected) => {
                                onChange(selected);
                                if (selected && selected.length > 0) {
                                  set_data_inward(selected[0]);
                                }
                              }}
                              onBlur={onBlur}
                              selected={value}
                              isInvalid={
                                errors.outwardChallanRef ? true : false
                              }
                            />
                          )}
                        />
                        {!challanList?.loading && !errors.outwardChallanRef && (
                          <span className="arrow-icon"></span>
                        )}
                        {/* {errors.outwardChallanRef && (
                          <span className="text-danger err-msg">
                            {errors.outwardChallanRef.message}
                          </span>
                        )} */}
                      </div>
                    </div>
                  )}

                <div className="col-md-3 mt-3">
                  <div className="form-group mt-md-0 mt-3">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_date ? constLabel.lbl_date : "Date"}
                      <span className="text-danger"> *</span>
                    </label>

                    <Controller
                      name="date"
                      control={control}
                      render={({ field }) => (
                        <DatePicker
                          className={
                            errors.date
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          {...field}
                          showYearDropdown
                          scrollableMonthYearDropdown
                          dateFormat="dd/MM/yyyy"
                          selected={field.value}
                          maxDate={new Date()}
                          placeholderText="Select a date"
                          autoComplete="off"
                        />
                      )}
                    />
                    {errors.date && (
                      <span className="text-danger err-msg">
                        {errors.date.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-md-3 mt-3">
                  <div className="form-group typeahead-container mt-md-0 mt-3">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_organization
                        ? constLabel.lbl_organization
                        : "Organization"}
                      <span className="text-danger"> *</span>
                    </label>

                    <Controller
                      name={`organization`}
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          id="basic-typeahead-single"
                          labelKey="comName"
                          multiple={false}
                          options={localData?.organizations}
                          placeholder="Select organization"
                          onChange={(selected) => {
                            onChange(selected);
                          }}
                          onBlur={onBlur}
                          selected={value}
                          isInvalid={errors.organization ? true : false}
                        />
                      )}
                    />
                    {!errors.organization && (
                      <span className="arrow-icon"></span>
                    )}
                    {errors.organization && (
                      <span className="text-danger err-msg">
                        {errors.organization.message}
                      </span>
                    )}
                  </div>
                </div>

                {(adj_type == undefined ||
                  (adj_type &&
                    adj_type.length > 0 &&
                    adj_type[0].name == "Issue")) && (
                  <div className="col-md-3 mt-3">
                    <div className="form-group typeahead-container mt-md-0 mt-3">
                      <label className="lbl-style roboto-bold">
                        {challantype &&
                        challantype.length > 0 &&
                        challantype[0].typename == "Adjustment Challan"
                          ? "Location"
                          : constLabel?.lbl_from_location
                          ? constLabel.lbl_from_location
                          : "From Location"}

                        <span className="text-danger"> *</span>
                      </label>

                      <Controller
                        name={`fromLocation`}
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Typeahead
                            id="basic-typeahead-single"
                            labelKey="locationname"
                            multiple={false}
                            options={fromlocdata.data}
                            isLoading={fromlocdata.loading}
                            placeholder="Select from location"
                            disabled={
                              qty_data !== null || dispatch ? true : false
                            }
                            onChange={(selected) => {
                              onChange(selected);
                              console.log("selected from location", selected);
                              if (selected && selected.length > 0) {
                                filter_store(
                                  selected[0].uuid,
                                  "from",
                                  selected
                                );
                                setValue("fromstore", []);
                              } else {
                                setValue("fromstore", []);
                                setfromStorelist({ data: [], loading: false });
                                setValue("items", [
                                  {
                                    product: [],
                                    particulars: "",
                                    qty: "",
                                    uom: [],
                                    operation: [],
                                    work_order: [],
                                    job_card: [],
                                  },
                                ]);
                                setshow_work_quantity([
                                  { index: 0, show: false, value: 0 },
                                ]);
                                setavailable_product_qty([]);
                              }
                            }}
                            onBlur={onBlur}
                            selected={value}
                            isInvalid={errors.fromLocation ? true : false}
                          />
                        )}
                      />
                      {!fromlocdata.loading && !errors.fromLocation && (
                        <span className="arrow-icon"></span>
                      )}
                      {errors.fromLocation && (
                        <span className="text-danger err-msg">
                          {errors.fromLocation.message}
                        </span>
                      )}
                    </div>
                  </div>
                )}
                {(adj_type == undefined ||
                  (adj_type &&
                    adj_type.length > 0 &&
                    adj_type[0].name == "Issue")) && (
                  <div className="col-md-3 mt-3">
                    <div className="form-group typeahead-container mt-md-0 mt-3">
                      <label className="lbl-style roboto-bold">
                        {challantype &&
                        challantype.length > 0 &&
                        challantype[0].typename == "Adjustment Challan"
                          ? "Store"
                          : constLabel?.lbl_from_store
                          ? constLabel.lbl_from_store
                          : "	From Store"}
                        <span className="text-danger"> *</span>
                      </label>

                      <Controller
                        name={`fromstore`}
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Typeahead
                            id="basic-typeahead-single"
                            labelKey="name"
                            multiple={false}
                            options={fromStorelist.data}
                            isLoading={fromStorelist.loading}
                            placeholder="Select from store"
                            onChange={onChange}
                            onBlur={onBlur}
                            selected={value}
                            disabled={
                              qty_data !== null || dispatch ? true : false
                            }
                            isInvalid={errors.fromstore ? true : false}
                          />
                        )}
                      />
                      {!fromStorelist.loading && !errors.fromstore && (
                        <span className="arrow-icon"></span>
                      )}
                      {errors.fromstore && (
                        <span className="text-danger err-msg">
                          {errors.fromstore.message}
                        </span>
                      )}
                    </div>
                  </div>
                )}

                {(adj_type == undefined ||
                  (adj_type &&
                    adj_type.length > 0 &&
                    adj_type[0].name == "Receipt")) && (
                  <div className="col-md-3 mt-3">
                    <div className="form-group typeahead-container mt-md-0 mt-3">
                      <label className="lbl-style roboto-bold">
                        {challantype &&
                        challantype.length > 0 &&
                        challantype[0].typename == "Adjustment Challan"
                          ? "Location"
                          : constLabel?.lbl_to_location
                          ? constLabel.lbl_to_location
                          : "To Location"}
                        <span className="text-danger"> *</span>
                      </label>

                      <Controller
                        name={`toLocation`}
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Typeahead
                            id="basic-typeahead-single"
                            labelKey="locationname"
                            multiple={false}
                            options={tolocdata.data}
                            isLoading={tolocdata.loading}
                            placeholder="Select to location"
                            disabled={dispatch}
                            onChange={(selected) => {
                              console.log("selected to location", selected);
                              onChange(selected);
                              if (selected && selected.length > 0) {
                                filter_store(selected[0].uuid, "to", selected);
                                setValue("tostore", []);
                              } else {
                                setValue("tostore", []);
                                settoStorelist({ data: [], loading: false });
                              }
                            }}
                            onBlur={onBlur}
                            selected={value}
                            isInvalid={errors.toLocation ? true : false}
                          />
                        )}
                      />
                      {!tolocdata.loading && !errors.toLocation && (
                        <span className="arrow-icon"></span>
                      )}
                      {errors.toLocation && (
                        <span className="text-danger err-msg">
                          {errors.toLocation.message}
                        </span>
                      )}
                    </div>
                  </div>
                )}

                {(adj_type == undefined ||
                  (adj_type &&
                    adj_type.length > 0 &&
                    adj_type[0].name == "Receipt")) && (
                  <div className="col-md-3 mt-3">
                    <div className="form-group typeahead-container mt-md-0 mt-3">
                      <label className="lbl-style roboto-bold">
                        {challantype &&
                        challantype.length > 0 &&
                        challantype[0].typename == "Adjustment Challan"
                          ? "Store"
                          : constLabel?.lbl_to_store
                          ? constLabel.lbl_to_store
                          : "To Store"}
                        <span>
                          <i style={{ color: "red" }}> *</i>
                        </span>
                      </label>

                      <Controller
                        name={`tostore`}
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Typeahead
                            id="basic-typeahead-single"
                            labelKey="name"
                            multiple={false}
                            options={toStorelist.data}
                            isLoading={toStorelist.loading}
                            placeholder="Select to store"
                            disabled={dispatch}
                            onChange={onChange}
                            onBlur={onBlur}
                            selected={value}
                            isInvalid={errors.tostore ? true : false}
                          />
                        )}
                      />
                      {!toStorelist.loading && !errors.tostore && (
                        <span className="arrow-icon"></span>
                      )}
                      {errors.tostore && (
                        <span className="text-danger err-msg">
                          {errors.tostore.message}
                        </span>
                      )}
                    </div>
                  </div>
                )}

                <div className="col-md-3 mt-3">
                  <div className="form-group mt-md-0 mt-3">
                    <label className="lbl-style roboto-bold">
                      Challan Ref No.
                    </label>

                    <input
                      name="challanRefNo"
                      className={
                        errors.challanRefNo
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      type="text"
                      placeholder="Enter challan ref no"
                      {...register("challanRefNo")}
                    />
                    {errors.challanRefNo && (
                      <span className="text-danger err-msg">
                        {errors.challanRefNo.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-md-3 mt-3">
                  <div className="form-group mt-md-0 mt-3">
                    <label className="lbl-style roboto-bold">
                      Challan Number
                    </label>

                    <input
                      name="challanNo"
                      className={
                        errors.challanNo
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      type="text"
                      placeholder="Enter challan number"
                      {...register("challanNo")}
                    />
                    {errors.challanNo && (
                      <span className="text-danger err-msg">
                        {errors.challanNo.message}
                      </span>
                    )}
                  </div>
                </div>
                {challantype &&
                  challantype.length > 0 &&
                  challantype[0].typename == "Goods Return" && (
                    <div className="col-md-3 mt-3">
                      <div className="form-group typeahead-container mt-md-0 mt-3 ">
                        <label className="lbl-style roboto-bold">
                          {constLabel?.lbl_purchase_inward
                            ? constLabel.lbl_purchase_inward
                            : "Purchase Inward"}
                          <span className="text-danger"> *</span>
                        </label>

                        <Controller
                          name={`purchase_inward`}
                          control={control}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <Typeahead
                              id="basic-typeahead-single"
                              labelKey="poid"
                              multiple={false}
                              options={piList.data}
                              isLoading={piList.loading}
                              placeholder="Select purchase inward"
                              onChange={onChange}
                              onBlur={onBlur}
                              selected={value}
                              isInvalid={errors.purchase_inward ? true : false}
                            />
                          )}
                        />
                        {!piList.loading && !errors.purchase_inward && (
                          <span className="arrow-icon"></span>
                        )}
                        {errors.purchase_inward && (
                          <span className="text-danger err-msg">
                            {errors.purchase_inward.message}
                          </span>
                        )}
                      </div>
                    </div>
                  )}

                {challantype &&
                  challantype.length > 0 &&
                  challantype[0].typename ==
                    "Customer Return Material Inward" && (
                    <div className="col-md-3 mt-3">
                      <div className="form-group typeahead-container mt-md-0 mt-3 ">
                        <label className="lbl-style roboto-bold">
                          {constLabel?.lbl_dispatch
                            ? constLabel.lbl_dispatch
                            : "Dispatch"}
                        </label>

                        <Controller
                          name={`dispatch`}
                          control={control}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <Typeahead
                              id="basic-typeahead-single"
                              labelKey="dispatchId"
                              multiple={false}
                              options={dispatchList.data}
                              isLoading={dispatchList.loading}
                              placeholder="Select dispatch"
                              onChange={onChange}
                              onBlur={onBlur}
                              selected={value}
                            />
                          )}
                        />
                      </div>
                    </div>
                  )}

                {challantype &&
                  challantype.length > 0 &&
                  challantype[0].typename == "Adjustment Challan" && (
                    <div className="col-md-3 mt-3">
                      <div className="form-group typeahead-container mt-md-0 mt-3 ">
                        <label className="lbl-style roboto-bold">
                          Adjustment Reason
                          <span className="text-danger"> *</span>
                        </label>

                        <Controller
                          name={`adjustment_reason`}
                          control={control}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <Typeahead
                              id="basic-typeahead-single"
                              labelKey="name"
                              multiple={false}
                              options={adjustmentlist.data}
                              loading={adjustmentlist.loading}
                              placeholder="Select adjustment reason "
                              onChange={(selected) => {
                                onChange(selected);
                              }}
                              onBlur={onBlur}
                              selected={value}
                              isInvalid={
                                errors.adjustment_reason ? true : false
                              }
                            />
                          )}
                        />
                        {!adjustmentlist.loading &&
                          !errors.adjustment_reason && (
                            <span className="arrow-icon"></span>
                          )}
                        {errors.adjustment_reason && (
                          <span className="text-danger err-msg">
                            {errors.adjustment_reason.message}
                          </span>
                        )}
                      </div>
                    </div>
                  )}
              </div>

              {challantype &&
                challantype.length > 0 &&
                qty_data &&
                challantype[0].typename.endsWith("Inward") && (
                  <>
                    <hr className="mt-4" />
                    <p
                      className="roboto-bold mb-0"
                      style={{ fontSize: "20px" }}
                    >
                      {constLabel?.lbl_outward_challan_ref_details
                        ? constLabel.lbl_outward_challan_ref_details
                        : "	Outward Challan Ref Details"}
                    </p>
                    <hr className="mt-4" />
                    <div className="my-3" style={{ maxHeight: "80vh" }}>
                      <table className="table table-responsive table-bordered">
                        <thead className="thead-light text-center">
                          <tr>
                            <th scope="col">
                              {constLabel?.lbl_outward_challan_id
                                ? constLabel.lbl_outward_challan_id
                                : "Outward Challan Id"}
                            </th>
                            <th scope="col">
                              {constLabel?.lbl_productname
                                ? constLabel.lbl_productname
                                : "Product Name"}
                            </th>
                            <th scope="col">
                              {constLabel?.lbl_available_qty
                                ? constLabel.lbl_available_qty
                                : "Available Qty"}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {qty_data.product_arr_value.map((item) => {
                            return (
                              <tr className="f-18">
                                <td
                                  style={{
                                    textAlign: "center",
                                    verticalAlign: "top",
                                    width: "10%",
                                  }}
                                >
                                  {qty_data && qty_data.outwardchallan_id}
                                </td>
                                <td
                                  style={{
                                    textAlign: "center",
                                    verticalAlign: "top",
                                    width: "10%",
                                  }}
                                >
                                  {item.productName}
                                </td>
                                <td
                                  style={{
                                    textAlign: "center",
                                    verticalAlign: "top",
                                    width: "10%",
                                  }}
                                >
                                  {item.availQty}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </>
                )}

              {challantype &&
                challantype.length > 0 &&
                available_product_qty.length > 0 &&
                (challantype[0].typename.endsWith("Outward") ||
                  challantype[0].typename == "Packing" ||
                  challantype[0].typename ==
                    "Customer Return Material Inward" ||
                  challantype[0].typename == "Inter Store Transfer") && (
                  <>
                    <hr className="mt-4" />
                    <p
                      className="roboto-bold mb-0"
                      style={{ fontSize: "20px" }}
                    >
                      {constLabel?.lbl_available_product_qty
                        ? constLabel.lbl_available_product_qty
                        : "Available Product Quantity"}
                    </p>
                    <hr className="mt-4" />
                    <div className="my-3" style={{ maxHeight: "80vh" }}>
                      <table className="table table-responsive table-bordered">
                        <thead className="thead-light text-center">
                          <tr>
                            <th scope="col">
                              {constLabel?.lbl_productname
                                ? constLabel.lbl_productname
                                : "Product Name"}
                            </th>
                            <th scope="col">
                              {constLabel?.lbl_previous_issued
                                ? constLabel.lbl_previous_issued
                                : "Previous Issued"}
                            </th>
                            <th scope="col">
                              {constLabel?.lbl_available_qty
                                ? constLabel.lbl_available_qty
                                : "Available Qty"}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {available_product_qty.map((item) => {
                            return (
                              <tr className="f-18">
                                <td
                                  style={{
                                    textAlign: "center",
                                    verticalAlign: "top",
                                    width: "10%",
                                  }}
                                >
                                  {item.productName}
                                </td>
                                <td
                                  style={{
                                    textAlign: "center",
                                    verticalAlign: "top",
                                    width: "10%",
                                  }}
                                >
                                  {item.previous_issued}
                                </td>
                                <td
                                  style={{
                                    textAlign: "center",
                                    verticalAlign: "top",
                                    width: "10%",
                                  }}
                                >
                                  {item.qty}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </>
                )}

              <hr className="mt-4" />
              <p className="roboto-bold mb-0" style={{ fontSize: "20px" }}>
                {constLabel?.lbl_product_details
                  ? constLabel.lbl_product_details
                  : "	Product Details"}
              </p>
              <hr className="my-4" />

              <div className="my-3" style={{ maxHeight: "80vh" }}>
                <table className="table table-responsive table-bordered">
                  <thead className="thead-light text-center">
                    <tr>
                      <th scope="col">
                        {constLabel?.lbl_work_order
                          ? constLabel.lbl_work_order
                          : "Work Order"}
                      </th>
                      {show_work_quantity[0].show && (
                        <th scope="col">
                          {constLabel?.lbl_work_order_quantity
                            ? constLabel.lbl_work_order_quantity
                            : "Work Order Quanity"}
                        </th>
                      )}
                      {dispatch !== true && (
                        <th scope="col">
                          {constLabel?.lbl_job_card
                            ? constLabel.lbl_job_card
                            : "Job card"}
                        </th>
                      )}
                      {dispatch !== true && (
                        <th scope="col">
                          {constLabel?.lbl_operation
                            ? constLabel.lbl_operation
                            : "Operation"}
                        </th>
                      )}
                      <th scope="col">
                        {constLabel?.lbl_productname
                          ? constLabel.lbl_productname
                          : "Product"}
                      </th>
                      <th scope="col">
                        {constLabel?.lbl_particulars
                          ? constLabel.lbl_particulars
                          : "Particulars"}
                      </th>

                      <th scope="col">
                        {constLabel?.lbl_quantity
                          ? constLabel.lbl_quantity
                          : "Quantity"}
                      </th>
                      <th scope="col">
                        {constLabel?.lbl_uom ? constLabel.lbl_uom : "Unit"}
                      </th>

                      <th scope="col">{"Action"}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {fields.map(({ id }, index) => (
                      <tr key={id} className="f-18">
                        <td
                          style={{
                            // textAlign: "center",
                            justifyContent: "start",
                            verticalAlign: "top",
                            width: "10%",
                          }}
                        >
                          <Controller
                            name={`items[${index}].work_order`}
                            control={control}
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <Typeahead
                                id="basic-typeahead-single"
                                labelKey="work_order_no"
                                size="sm"
                                multiple={false}
                                options={workorderList.data}
                                isLoading={workorderList.loading}
                                placeholder="Select work order"
                                onChange={(selected) => {
                                  onChange(selected);
                                  if (selected && selected.length > 0) {
                                    console.log("selected", selected);
                                    // filter_job_card(selected[0].uuid);
                                    fetchJobCardList(selected[0].uuid,selected[0]);
                                    let arr = [...show_work_quantity];
                                    arr[index].show = true;
                                    arr[index].value = selected[0].quantity;
                                    setshow_work_quantity(arr);
                                    fetchStoreQty(selected[0], index);

                                    if (
                                      challantype &&
                                      challantype.length > 0 &&
                                      challantype[0].typename ==
                                        "Job Work Outward"
                                    ) {
                                      setValue(`items[${index}].product`, [
                                        selected[0].product_info,
                                      ]);
                                      setValue(
                                        `items[${index}].particulars`,
                                        selected[0].product_info.productName
                                      );
                                      setValue(`items[${index}].uom`, [
                                        selected[0].product_info?.uomproduct,
                                      ]);
                                      find_ops_from_product(
                                        selected[0].product_id,
                                        selected[0].route_uuid
                                      );
                                    }
                                    // find_product_from_wo(selected[0].uuid, index);
                                  } else {
                                    if (index == 0) {
                                      setavailable_product_qty([]);
                                    } else {
                                      const newArray =
                                        available_product_qty.filter(
                                          (_, ind) => ind !== index
                                        );
                                      setavailable_product_qty(newArray);
                                    }

                                    setjobcardList({
                                      data: [],
                                      loading: false,
                                    });
                                    setOpList({ data: [], loading: false });
                                    setValue(`items[${index}].product`, []);
                                    setValue(`items[${index}].job_card`, []);
                                    setValue(`items[${index}].operation`, []);
                                    setValue(`items[${index}].particulars`, "");
                                    let arr = [...show_work_quantity];
                                    arr[index].show = true;
                                    arr[index].value = 0;
                                    setshow_work_quantity(arr);
                                  }
                                }}
                                onBlur={onBlur}
                                selected={value}
                                disabled={
                                  qty_data !== null || dispatch ? true : false
                                }
                                isInvalid={
                                  errors.items?.[index]?.work_order
                                    ? true
                                    : false
                                }
                              />
                            )}
                          />
                          {errors.items?.[index]?.work_order && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.work_order.message}
                            </span>
                          )}
                          {index == fields.length - 1 &&
                            (qty_data == null && !dispatch ? true : false) && (
                              <></>
                              // <div>
                              //   <button
                              //     style={{ fontSize: "16px" }}
                              //     className="btn btn-sm button-primary px-2 mt-2"
                              //     onClick={() => {
                              //       append({
                              //         product: [],
                              //         particulars: "",
                              //         qty: "",
                              //         uom: [],
                              //         operation: [],
                              //         work_order: [],
                              //         job_card: [],
                              //       });
                              //       if (
                              //         challantype &&
                              //         challantype.length > 0 &&
                              //         challantype[0].typename !==
                              //           "Job Work Outward"
                              //       ) {
                              //         let arr = [
                              //           ...show_work_quantity,
                              //           {
                              //             index: index + 1,
                              //             show: false,
                              //             value: 0,
                              //           },
                              //         ];
                              //         setshow_work_quantity(arr);
                              //       } else {
                              //         let arr = [
                              //           ...show_work_quantity,
                              //           {
                              //             index: index + 1,
                              //             show: true,
                              //             value: 0,
                              //           },
                              //         ];
                              //         setshow_work_quantity(arr);
                              //       }
                              //     }}
                              //   >
                              //     <FontAwesomeIcon
                              //       style={{
                              //         color: "#ffffff",
                              //         marginRight: "5px",
                              //       }}
                              //       icon={faAdd}
                              //       size="sm"
                              //     />{" "}
                              //     {constLabel?.lbl_add
                              //       ? constLabel.lbl_add
                              //       : "Add"}
                              //   </button>
                              // </div>
                            )}
                        </td>
                        {show_work_quantity[index].show && (
                          <td
                            style={{
                              textAlign: "center",
                              verticalAlign: "top",
                              width: "10%",
                            }}
                          >
                            <input
                              className={"form-control form-control-sm"}
                              type="text"
                              value={`${show_work_quantity[index].value}`}
                              disabled={true}
                              // placeholder="Enter quantity"
                            />
                          </td>
                        )}
                        {dispatch !== true && (
                          <td
                            style={{
                              textAlign: "center",
                              verticalAlign: "top",
                              width: "10%",
                            }}
                          >
                            <Controller
                              name={`items[${index}].job_card`}
                              control={control}
                              render={({
                                field: { onChange, onBlur, value, ref },
                              }) => (
                                <Typeahead
                                  id="basic-typeahead-single"
                                  labelKey="job_card_no"
                                  size="sm"
                                  multiple={false}
                                  options={jobcardList.data}
                                  isLoading={jobcardList.loading}
                                  placeholder="Select job card"
                                  onChange={(selected) => {
                                    onChange(selected);
                                    // console.log("selected", selected);
                                    if (selected && selected.length > 0) {
                                      console.log("selected", selected);

                                       if(selected[0].workorderDetails.global_route_uuid !==null &&selected[0].workorderDetails.global_route_uuid !==undefined){
                                        setValue(`items[${index}].operation`, [
                                          {
                                            operationName: selected[0].operationDetails.operationName,
                                            uuid: selected[0].operationDetails.uuid,
                                          },
                                        ]);
                                       }else{
                                        let { operationname, operation_id } =
                                        selected[0].productOperDetails;
                                      setValue(`items[${index}].operation`, [
                                        {
                                          operationName: operationname,
                                          uuid: operation_id,
                                        },
                                      ]);
                                       }
                                      
                                      setValue(`items[${index}].product`, [
                                        selected[0].productDetail,
                                      ]);
                                      setValue(
                                        `items[${index}].particulars`,
                                        selected[0].productDetail.productName
                                      );
                                      setValue(`items[${index}].uom`, [
                                        selected[0].productDetail?.uomproduct,
                                      ]);
                                    } else {
                                      console.log("selected else", selected)
                                      setValue(`items[${index}].operation`, []);
                                      setValue(`items[${index}].product`, []);
                                      setValue(
                                        `items[${index}].particulars`,
                                        ""
                                      );
                                      setValue(`items[${index}].uom`, []);
                                    }
                                  }}
                                  onBlur={onBlur}
                                  selected={value}
                                  disabled={
                                    qty_data !== null || dispatch ? true : false
                                  }
                                  isInvalid={
                                    errors.items?.[index]?.job_card
                                      ? true
                                      : false
                                  }
                                />
                              )}
                            />
                            {errors.items?.[index]?.job_card && (
                              <span className="text-danger err-msg">
                                {errors.items?.[index]?.job_card.message}
                              </span>
                            )}
                          </td>
                        )}
                        {dispatch !== true && (
                          <td
                            style={{
                              textAlign: "center",
                              verticalAlign: "top",
                              width: "10%",
                            }}
                          >
                            <Controller
                              name={`items[${index}].operation`}
                              control={control}
                              render={({
                                field: { onChange, onBlur, value, ref },
                              }) => (
                                <Typeahead
                                  id="basic-typeahead-single"
                                  labelKey="operationName"
                                  size="sm"
                                  multiple={false}
                                  options={opList.data}
                                  isLoading={opList.loading}
                                  placeholder="Select operation"
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  selected={value}
                                  disabled={
                                    qty_data !== null || dispatch ? true : false
                                  }
                                  isInvalid={
                                    errors.items?.[index]?.operation
                                      ? true
                                      : false
                                  }
                                />
                              )}
                            />
                            {errors.items?.[index]?.operation && (
                              <span className="text-danger err-msg">
                                {errors.items?.[index]?.operation.message}
                              </span>
                            )}
                          </td>
                        )}
                        <td
                          style={{
                            // textAlign: "center",
                            justifyContent: "start",
                            verticalAlign: "top",
                            width: "20%",
                          }}
                        >
                          <Controller
                            name={`items[${index}].product`}
                            control={control}
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <Typeahead
                                id="basic-typeahead-single"
                                labelKey="productName"
                                size="sm"
                                multiple={false}
                                options={products.data}
                                isLoading={products.loading}
                                placeholder="Search product for selection"
                                onChange={(selected) => {
                                  onChange(selected);
                                  // console.log("selected",selected)
                                  // if (
                                  //   selected&&selected.length > 0 &&
                                  //   selected[0].productName !== "Other"
                                  // ) {
                                  //   setValue(
                                  //     `items[${index}].particulars`,
                                  //     selected[0].productName,
                                  //     { shouldValidate: true }
                                  //   );
                                  //   setValue(`items[${index}].uom`, [
                                  //     selected[0].unit_id,
                                  //   ]);
                                  // } else {
                                  //   setValue(`items[${index}].particulars`, "");
                                  //   setValue(`items[${index}].uom`, []);
                                  // };

                                  if (selected && selected.length > 0) {
                                    console.log("selected", selected);
                                  
                                    // if(challantype&&challantype.name)
                                      fetchproductqty(
                                        selected[0].uuid,
                                        selected[0].productName,
                                        index
                                      );
                                     
                                    

                                    setValue(
                                      `items[${index}].particulars`,
                                      selected[0].productName, {
                                        shouldValidate:true
                                      }
                                    );
                                    setValue(`items[${index}].uom`, [
                                      selected[0].unit_id,
                                    ],{
                                      shouldValidate:true
                                    });
                                  }else{

                                    setavailable_product_qty([])
                                    setValue(
                                      `items[${index}].particulars`,
                                      ""
                                    );
                                    setValue(`items[${index}].uom`, []); 
                                  }
                                }}
                                onBlur={onBlur}
                                selected={value}
                                disabled={
                                  qty_data !== null || dispatch ? true : false
                                }
                                isInvalid={errors.product ? true : false}
                                onInputChange={(text) => setProd(text)}
                              />
                            )}
                          />
                          {errors.items?.[index]?.product && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.product.message}
                            </span>
                          )}
                        </td>
                        <td
                          style={{
                            // textAlign: "center",
                            verticalAlign: "top",
                            width: "10%",
                          }}
                        >
                          <input
                            className={
                              errors.items?.[index]?.particulars
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            type="text"
                            name={`items[${index}].particulars`}
                            disabled={
                              qty_data !== null || dispatch ? true : false
                            }
                            placeholder="Enter particular"
                            {...register(`items[${index}].particulars`)}
                          />
                          {errors.items?.[index]?.particulars && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.particulars.message}
                            </span>
                          )}
                        </td>

                        <td
                          style={{
                            textAlign: "center",
                            verticalAlign: "top",
                            width: "10%",
                          }}
                        >
                          <input
                            className={
                              errors.items?.[index]?.qty
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            type="text"
                            name={`items[${index}].qty`}
                            placeholder="Enter quantity"
                            disabled={dispatch}
                            {...register(`items[${index}].qty`)}
                          />

                          {errors.items?.[index]?.qty && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.qty.message}
                            </span>
                          )}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            verticalAlign: "top",
                            width: "10%",
                          }}
                        >
                          <Controller
                            name={`items[${index}].uom`}
                            control={control}
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => {
                              const product = watch(`items[${index}].product`);
                              // Disable unit field if product.material_type_id is null
                              const isUnitDisabled =
                                product?.[0]?.material_type_id == null;
                              return (
                                <Typeahead
                                  id="basic-typeahead-single"
                                  labelKey="uom"
                                  size="sm"
                                  multiple={false}
                                  options={uomList.data}
                                  isLoading={uomList.loading}
                                  placeholder="Select unit"
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  selected={value}
                                  disabled={
                                    isUnitDisabled ||
                                    qty_data !== null ||
                                    dispatch
                                      ? true
                                      : false
                                  }
                                  isInvalid={
                                    errors.items?.[index]?.uom ? true : false
                                  }
                                />
                              );
                            }}
                          />
                          {errors.items?.[index]?.uom && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.uom.message}
                            </span>
                          )}
                        </td>

                        <td
                          style={{
                            textAlign: "center",
                            verticalAlign: "top",
                            width: "10%",
                          }}
                        >
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip id="tooltip">
                                {"Delete Particulars"}
                              </Tooltip>
                            }
                          >
                            {
                              <div
                                className="deleteIcon cursor_pointer"
                                onClick={
                                  index > 0
                                    ? () => {
                                        remove(index);
                                        let arr = [...show_work_quantity];
                                        arr.pop();
                                        setshow_work_quantity(arr);
                                        if (
                                          available_product_qty.length > index
                                        ) {
                                          const newArray =
                                            available_product_qty.filter(
                                              (_, ind) => ind !== index
                                            );
                                          setavailable_product_qty(newArray);
                                        }
                                      }
                                    : null
                                }
                              >
                                <Trash2 color="#FFFFFF" size={18} />
                              </div>
                            }
                          </OverlayTrigger>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <hr className="mt-4" />
              {challantype &&
                challantype.length > 0 &&
                challantype[0].typename !== "Packing" &&
                challantype[0].typename !== "Inter Store Transfer" &&
                challantype[0].typename !== "Adjustment Challan" && (
                  <>
                    <p
                      className="roboto-bold mb-0"
                      style={{ fontSize: "20px" }}
                    >
                      {constLabel?.lbl_tranport_details
                        ? constLabel.lbl_tranport_details
                        : "Transport Details"}
                    </p>
                    <hr className="my-4" />

                    <div className="row mt-2 col-12">
                      <div className="col-md-3 mt-3">
                        <div className="form-group mt-md-0 mt-3 ">
                          <label className="lbl-style roboto-bold">
                            {constLabel?.lbl_transport_type
                              ? constLabel.lbl_transport_type
                              : "Transport Type"}
                            <span className="text-danger"> *</span>
                          </label>

                          <Controller
                            name={`transportType`}
                            control={control}
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <Typeahead
                                id="basic-typeahead-single"
                                labelKey="name"
                                size="sm"
                                multiple={false}
                                options={trasnportList.data}
                                isLoading={trasnportList.loading}
                                placeholder="Select transport type"
                                onChange={(selected) => {
                                  onChange(selected);
                                  if (selected && selected.length > 0) {
                                    settranporttype(selected);
                                  }
                                }}
                                onBlur={onBlur}
                                selected={value}
                                isInvalid={errors.transportType ? true : false}
                              />
                            )}
                          />
                          {errors.transportType && (
                            <span className="text-danger err-msg">
                              {errors.transportType.message}
                            </span>
                          )}
                        </div>
                      </div>

                      {transporttype && transporttype[0].name == "Internal" && (
                        <div className="col-md-3 mt-3">
                          <div className="form-group mt-md-0 mt-3 ">
                            <label className="lbl-style roboto-bold">
                              {constLabel?.lbl_internal_transport
                                ? constLabel.lbl_internal_transport
                                : "Int. Transport"}
                              <span className="text-danger"> *</span>
                            </label>

                            <Controller
                              name={`internaltransport`}
                              control={control}
                              render={({
                                field: { onChange, onBlur, value, ref },
                              }) => (
                                <Typeahead
                                  id="basic-typeahead-single"
                                  labelKey="name"
                                  size="sm"
                                  multiple={false}
                                  options={internaltrasnportList.data}
                                  isLoading={internaltrasnportList.loading}
                                  placeholder="Select internal transport"
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  selected={value}
                                  isInvalid={
                                    errors.internaltransport ? true : false
                                  }
                                />
                              )}
                            />
                            {errors.internaltransport && (
                              <span className="text-danger err-msg">
                                {errors.internaltransport.message}
                              </span>
                            )}
                          </div>
                        </div>
                      )}

                      {transporttype && transporttype[0].name == "External" && (
                        <div className="col-md-3 mt-3">
                          <div className="form-group mt-md-0 mt-3 ">
                            <label className="lbl-style roboto-bold">
                              {constLabel?.lbl_ext_transport
                                ? constLabel.lbl_ext_transport
                                : "External Transport"}
                              <span className="text-danger"> *</span>
                            </label>

                            <input
                              name="extTransport"
                              className={
                                errors.extTransport
                                  ? "form-control form-control-sm is-invalid"
                                  : "form-control form-control-sm"
                              }
                              type="text"
                              placeholder="Enter vehical number"
                              {...register("extTransport")}
                            />
                            {errors.extTransport && (
                              <span className="text-danger err-msg">
                                {errors.extTransport.message}
                              </span>
                            )}
                          </div>
                        </div>
                      )}

                      {transporttype && transporttype[0].name == "Airway" && (
                        <div className="col-md-3 mt-3">
                          <div className="form-group mt-md-0 mt-3 ">
                            <label className="lbl-style roboto-bold">
                              {constLabel?.lbl_airway_transport
                                ? constLabel.lbl_airway_transport
                                : "Airway Transport"}
                              <span className="text-danger"> *</span>
                            </label>

                            <input
                              name="airwayTransport"
                              className={
                                errors.airwayTransport
                                  ? "form-control form-control-sm is-invalid"
                                  : "form-control form-control-sm"
                              }
                              type="text"
                              placeholder="Enter flight number"
                              {...register("airwayTransport")}
                            />
                            {errors.airwayTransport && (
                              <span className="text-danger err-msg">
                                {errors.airwayTransport.message}
                              </span>
                            )}
                          </div>
                        </div>
                      )}

                      <div className="col-md-3 mt-3">
                        <div className="form-group mt-md-0 mt-3 ">
                          <label className="lbl-style roboto-bold">
                            {constLabel?.lbl_driver_name
                              ? constLabel.lbl_driver_name
                              : "Driver Name"}
                            <span className="text-danger"> *</span>
                          </label>

                          <input
                            name="driverName"
                            className={
                              errors.driverName
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            type="text"
                            placeholder="Enter driver name "
                            {...register("driverName")}
                          />
                          {errors.driverName && (
                            <span className="text-danger err-msg">
                              {errors.driverName.message}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="col-md-3 mt-3">
                        <div className="form-group mt-md-0 mt-3 ">
                          <label className="lbl-style roboto-bold">
                            {constLabel?.lbl_status
                              ? constLabel.lbl_status
                              : "Status"}
                          </label>

                          <input
                            name="status"
                            className={
                              errors.status
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            type="text"
                            placeholder="Enter status"
                            disabled={true}
                            {...register("status")}
                          />
                          {errors.status && (
                            <span className="text-danger err-msg">
                              {errors.status.message}
                            </span>
                          )}
                        </div>
                      </div>
                      {challantype &&
                        challantype.length > 0 &&
                        challantype[0].typename == "Dispatch Challan" && (
                          <div className="col-md-3 mt-3">
                            <div className="form-group mt-md-0 mt-3 ">
                              <label className="lbl-style roboto-bold">
                                {constLabel?.lrn
                                  ? constLabel.lrn
                                  : "Lorry Receipt Number"}
                              </label>

                              <input
                                name="lrn"
                                className={
                                  errors.lrn
                                    ? "form-control form-control-sm is-invalid"
                                    : "form-control form-control-sm"
                                }
                                type="text"
                                placeholder="Enter lorry receipt number"
                                {...register("lrn")}
                              />
                              {/* {errors.lrn && (
                      <span className="text-danger err-msg">
                        {errors.lrn.message}
                      </span>
                    )} */}
                            </div>
                          </div>
                        )}

                      <div className="col-md-3 mt-3">
                        <div className="form-group mt-md-0 mt-3 ">
                          <label className="lbl-style roboto-bold">
                            {constLabel?.lbl_remark
                              ? constLabel.lbl_remark
                              : "Remark"}
                          </label>

                          <textarea
                            className={
                              errors.remark
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            type="text"
                            placeholder="Enter remark"
                            {...register("remark")}
                          ></textarea>
                          {errors.remark && (
                            <span className="text-danger err-msg">
                              {errors.remark.message}
                            </span>
                          )}
                        </div>
                      </div>
                      {/* 
                <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label col-12 col-md-3"
                  >
                    NC Details
                  </label>

                  <div className="col-12 col-md-9">
                    <div className="form-control form-control-sm">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="ncDetail"
                        name="ncDetail"
                        {...register("ncDetail")}
                      />
                      <label className="form-check-label form_label ms-2">
                        {" "}
                        {" Has NC ?"}
                      </label>
                    </div>
                  </div>
                </div> */}
                    </div>

                    <hr className="my-4" />
                  </>
                )}
            </div>
          </div>

          <div className="card-footer py-3">
            <button
              className="btn btn-yellow px-4 float-end"
              disabled={buttonclick}
              onClick={handleSubmit(onSubmit)}
            >
              {constLabel?.lbl_save ? constLabel.lbl_save : "Save"}{" "}
              {buttonclick ? <Spinner animation="border" size="sm" /> : null}{" "}
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default CreateGoodsChallan;
