import React, { useState, useEffect } from "react";
import { get_poInvItems } from "../../../api/index";
import {
  calMaxPage,
  convertDate,
  getLabels,
} from "../../../common/Functions/CommonFunctions";
import { Modal, Pagination } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowLeftCircle, Eye } from "react-feather";
import { intervalToDuration } from "date-fns/esm";
import Back from "../../../assets/images/icons/project_menu/back-button.png";
import { Tooltip } from "react-tooltip";

function POInwardItems() {
  let navigate = useNavigate();
  let location = useLocation();
  const [srNo, setSrNo] = useState(0);
  const [dataList, setDataList] = useState({ data: [], loading: false });
  const [constLabel, setConstLabel] = useState({});
  const [visible, setVisible] = useState(false);
  const [barList, setBarList] = useState([]);

  useEffect(() => {
    setConstLabel(getLabels("Purchase", "Purchase Inward"));
    fetchLineItems();
  }, []);

  function fetchLineItems() {
    const payload = {
      uuid: location.state?.uuid,
    };
    setDataList({ ...dataList, data: [], loading: true });

    get_poInvItems(payload).then(
      (res) => {
        setDataList({ ...dataList, data: res.data.data, loading: false });
      },
      (err) => {
        setDataList({ ...dataList, data: [], loading: false });
      }
    );
  }

  function toggleDiv(ind) {
    const selRow = document.getElementById("collapse_row" + ind);

    selRow.classList.toggle("expanded");
  }

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="min-vh-100" id="root_div_main">
        <div className="content-wrapper">
          <div className="card border-0">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="d-md-flex justify-content-start col-12 col-md-6">
                  <Tooltip id={"back-tooltip"} place="top" />
                  <img
                    src={Back}
                    height="32"
                    width="32"
                    className="cursor_pointer my-1 me-2"
                    onClick={() => navigate(-1)}
                    data-tooltip-id={"back-tooltip"}
                    data-tooltip-content={"Back"}
                  />
                  <h1 className="bold">
                    {constLabel?.lbl_purchase_inv_line_items
                      ? constLabel.lbl_purchase_inv_line_items
                      : "Purchase Inward Line Items"}
                  </h1>
                </div>
              </div>
              <div className="mt-3">
                {dataList.loading ? (
                  <div className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : dataList.data.length > 0 ? (
                  <div className="table-responsive pc">
                    <table className="table table-bordered">
                      <thead className="table-grey roboto-bold">
                        <tr className="">
                          <th scope="col">{"Sr.No"}</th>
                          <th scope="col">{"Product Name"}</th>
                          <th scope="col">{"Invoice Date"}</th>
                          <th scope="col">{"Invoice No"}</th>
                          <th scope="col">{"Action"}</th>
                        </tr>
                      </thead>
                      <tbody className=" roboto">
                        {dataList.data.map((val, ind) => (
                          <React.Fragment key={ind}>
                            <tr>
                              <td>{parseInt(ind) + 1 + parseInt(srNo)}</td>
                              <td>
                                {val.productname ? val.productname : "NA"}
                              </td>
                              <td>
                                {val.invoice_date
                                  ? convertDate(val.invoice_date, "-")
                                  : "NA"}
                              </td>
                              <td>{val.invoice_no ? val.invoice_no : "NA"}</td>
                              <td>
                                <Eye
                                  onClick={() => {
                                    toggleDiv(ind, val);
                                  }}
                                  className="menu_icon_grey cursor_pointer"
                                  size={20}
                                />
                              </td>
                            </tr>
                            <tr
                              className={"collapsible-row"}
                              id={"collapse_row" + ind}
                            >
                              <td colSpan="12">
                                <div className="collapse-content">
                                  <div className="row mb-3">
                                    <div className="col-12 col-md-1">
                                      <label className="roboto-bold">
                                        {"Sr.No"}
                                      </label>
                                    </div>
                                    <div className="col-12 col-md-2">
                                      <label className="roboto-bold">
                                        {constLabel?.lbl_po_no
                                          ? constLabel.lbl_po_no
                                          : "PO No"}
                                      </label>
                                    </div>
                                    <div className="col-12 col-md-2">
                                      <label className="roboto-bold">
                                        {constLabel?.lbl_rmi_id
                                          ? constLabel.lbl_rmi_id
                                          : "RMI Id"}
                                      </label>
                                    </div>
                                    <div className="col-12 col-md-2">
                                      <label className="roboto-bold">
                                        {constLabel?.lbl_material_code
                                          ? constLabel.lbl_material_code
                                          : "Material Code"}
                                      </label>
                                    </div>
                                    <div className="col-12 col-md-2">
                                      <label className="roboto-bold">
                                        {constLabel?.lbl_qty
                                          ? constLabel.lbl_qty
                                          : "Qty"}
                                      </label>
                                    </div>
                                    <div className="col-12 col-md-2">
                                      <label className="roboto-bold">
                                        {constLabel?.lbl_accepted_qty
                                          ? constLabel.lbl_accepted_qty
                                          : "Accepted Qty"}
                                      </label>
                                    </div>
                                    <div className="col-12 col-md-1">
                                      <label className="roboto-bold">
                                        {"Action"}
                                      </label>
                                    </div>
                                  </div>
                                  {val.Tool_inv_tran &&
                                    val.Tool_inv_tran.length > 0 &&
                                    val.Tool_inv_tran.map((vv, index) => (
                                      <div className="row " key={index}>
                                        <div className="col-12 col-md-1">
                                          <label className="roboto">
                                            {index + 1}
                                          </label>
                                        </div>

                                        <div className="col-12 col-md-2">
                                          <label className="roboto">
                                            {vv.ponum ? vv.ponum : "NA"}
                                          </label>
                                        </div>
                                        <div className="col-12 col-md-2">
                                          <label className="roboto">
                                            {vv.toolinvenId
                                              ? vv.toolinvenId
                                              : "NA"}
                                          </label>
                                        </div>

                                        <div className="col-12 col-md-2">
                                          <label className="roboto">
                                            {vv.material_code
                                              ? vv.material_code
                                              : "NA"}
                                          </label>
                                        </div>

                                        <div className="col-12 col-md-2">
                                          <label className="roboto">
                                            {vv.qty ? vv.qty : "NA"}
                                          </label>
                                        </div>

                                        <div className="col-12 col-md-2">
                                          <label className="roboto">
                                            {vv.acceptyqty
                                              ? vv.acceptyqty
                                              : "NA"}
                                          </label>
                                        </div>

                                        <div className="col-12 col-md-1 cursor_pointer">
                                          <a
                                            href="#"
                                            className="roboto-bold"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              setVisible(true);
                                              setBarList(vv.barLengthDetails);
                                            }}
                                          >
                                            {"Bar Detail"}
                                          </a>
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              </td>
                            </tr>
                          </React.Fragment>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div
                    className="alert alert-danger mb-0 text-center my-4"
                    role="alert"
                  >
                    {"No Purchase Inward Line Items Found !"}{" "}
                    <a
                      href="#"
                      onClick={() => navigate("/pcms/purchaseInward")}
                    >
                      {" "}
                      Back{" "}
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={visible} onHide={() => setVisible(false)} size={"lg"}>
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>{"Bar Details"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            className="table-responsive"
            style={{ overflow: "scroll", maxHeight: "60vh" }}
          >
            <table className="table table-sm table-bordered">
              <thead className="table-grey roboto-bold">
                <tr>
                  <th scope="col">{"Sr.No"}</th>
                  <th scope="col">{"Bar No"}</th>
                  <th scope="col">{"Cut Length"}</th>
                </tr>
              </thead>
              <tbody>
                {barList.map((val, ind) => (
                  <tr key={ind}>
                    <td>{parseInt(ind) + 1 + parseInt(srNo)}</td>
                    <td>{val.bar_no ? val.bar_no : "NA"}</td>
                    <td>{val.cutlength ? val.cutlength : "NA"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={() => setVisible(false)}
            className="f-16 btn btn-yellow "
          >
            {constLabel?.lbl_close ? constLabel?.lbl_close : "Close"}
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default POInwardItems;
