import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faDownload } from "@fortawesome/free-solid-svg-icons";
import Roboto from "../../../assets/fonts/Roboto-Regular.ttf";
import Roboto_Bold from "../../../assets/fonts/Roboto-Bold.ttf";
import ns_logo from "../../../assets/images/ns_logo.png"

import {
  PDFViewer,
  Document,
  Text,
  View,
  Image,
  Page,
  Link,
  StyleSheet,
  Font,
  pdf,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import { formatDate5, getShortReadableId } from "../../../common/Functions/CommonFunctions";
import { toWords } from "number-to-words";

function Proforma_Invoice_pdf() {
  let navigate = useNavigate();
  let location = useLocation();
  const [party_details, setparty_details] = useState(null);
  const [gst_type, setgst_type] = useState(null);
  const [sub_total, setSub_total] = useState(null);
  const [tax_total, setTax_total] = useState(null);
  const [pdfBlobUrl, setPdfBlobUrl] = useState("");
  const [isPdfLoaded, setIsPdfLoaded] = useState(false);
  const downloadLinkRef = useRef(null);
  const data = location.state;

  console.log("data", data);

  Font.register({
    family: "roboto, roboto-light,roboto-bold",
    fonts: [
      {
        src: Roboto,
      },
      {
        src: Roboto_Bold,
        fontWeight: 600,
      },
    ],
  });

  const styles = StyleSheet.create({
    viewer: {
      width: "100%",
      height: window.innerHeight,
    },
    page: {
      paddingTop: 15,
      paddingBottom: 40,
      paddingHorizontal: 15,
      color: "black",
      fontFamily: "roboto, roboto-light,roboto-bold",
      border: "1px solid black",
    },
    pageNumber: {
      position: "absolute",
      fontSize: 12,
      bottom: 28,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "black",
    },
    footer: {
      position: "absolute",
      fontSize: 12,
      bottom: 0,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "black",
      paddingTop: "50px",
      paddingBottom: "10",
      paddingLeft: "15",
      paddingRight: "15",
      // borderTop:'1px solid black',
    },
    footer1: {
      position: "absolute",
      fontSize: 12,
      bottom: 50,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "black",
      paddingTop: "50px",
      paddingBottom: "10",
      paddingLeft: "15",
      paddingRight: "15",
      // borderTop:'1px solid black',
    },
    row1: {
      flexDirection: "row",
      border: "1px solid black",
    },
    row2: {
      flexDirection: "row",
      borderLeft: "1px solid black",
      borderRight: "1px solid black",
      borderBottom: "1px solid black",
    },
    row3: {
      flexDirection: "row",
      fontSize: "10px",
      width: "100%",
    },
    tablColumn1: {
      width: "5%",
      fontSize: "10px",
      border: "1px solid black",
      padding: "5px",
      fontWeight: 600,
    },
    tablColumn1Data: {
      width: "5%",
      borderLeft: "1px solid black",
      borderRight: "1px solid black",
    //   borderBottom: "1px solid black",
      padding: "5px",
    },
    tablColumn2: {
      width: "50%",
      border: "1px solid black",
      padding: "5px",
      fontWeight: 600,
    },
    tablColumn2Data: {
      width: "50%",
      borderLeft: "1px solid black",
      borderRight: "1px solid black",
    //   borderBottom: "1px solid black",
      padding: "5px",
    },
    tablColumn3: {
      width: "11%",
      border: "1px solid black",
      padding: "5px",
      fontWeight: 600,
    },
    tablColumn3Data: {
      width: "11%",
      borderLeft: "1px solid black",
      borderRight: "1px solid black",
    //   borderBottom: "1px solid black",
      padding: "5px",
    },
    tablColumn4: {
        width: "10%",
        border: "1px solid black",
        padding: "5px",
        fontWeight: 600,
      },
      tablColumn4Data: {
        width: "10%",
        borderLeft: "1px solid black",
        borderRight: "1px solid black",
        // borderBottom: "1px solid black",
        padding: "5px",
      },
      tablColumn5: {
        width: "12%",
        border: "1px solid black",
        padding: "5px",
        fontWeight: 600,
      },
      tablColumn5Data: {
        width: "12%",
        borderLeft: "1px solid black",
        borderRight: "1px solid black",
        // borderBottom: "1px solid black",
        padding: "5px",
      },
    smallcolumn: {
      // border: "1px solid black",
      width: "50%",
    },
    subDivRow: {
      flexDirection: "row",
    },
    column: {
      width: "50%",
      // borderRight: '1px solid black',
      // backgroundColor: 'red',
      fontSize: "10px",
    },
    title: {
      textAlign: "center",
      fontWeight: 600,
    },
    companyName: {
      fontWeight: "600",
      fontSize: "12px",
    },
    signatureBlock: {
      width: "100%",
      marginLeft: "auto",
      marginTop: "100px",
      borderTop: "1px solid black",
      borderLeft: "1px solid black",
      borderRight: "1px solid black",
      borderBottom: "1px solid black",
      padding: "5px",
    },
    section: {
      marginBottom: 10,
    },
    label: {
      fontWeight: "bold",
      fontSize: "12px",
      marginLeft: "10px",
    },
    value: {
      flex: 1,
      fontSize: "12px",
    },
    row4: {
      flexDirection: "row",
      alignItems: "flex-start",
      marginBottom: 5,
    },
    
    row5: {
      flexDirection: "row",
      alignItems: "flex-start",
      marginBottom: 5,
    },

    label2: {
        marginLeft: "5px",
        marginBottom: "2px",
        fontSize: "10px",
      },

  });


  useEffect(() => {
    const generatePdfBlob = async () => {
      const doc = (
        <WOStatusPDF data={data}/>
      );
      const asPdf = pdf([]);
      asPdf.updateContainer(doc);
      const blob = await asPdf.toBlob();
      const url = URL.createObjectURL(blob);
      setPdfBlobUrl(url);
      setIsPdfLoaded(true);
    };

    generatePdfBlob();
  }, [data]);

  const handleDownload = () => {
    const link = downloadLinkRef.current;
    link.href = pdfBlobUrl;
    link.download = `Proforma Invoice.pdf`;
    link.click();
  };

  const convertToRupees = (amount) => {
    const rupees = Math.floor(amount);
    const paise = Math.round((amount - rupees) * 100);
  
    let words = toWords(rupees)
      .split(" ")
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  
    if (paise > 0) {
      words += ` And ${toWords(paise)} Paise`;
    }
  
    return `${words} Only`;
  };

  const WOStatusPDF = ({ data }) => (
    <Document title={`Proforma Invoice`}>
      <Page style={styles.page}>
        <View style={[styles.row1]}>
          <View style={{ width: "20%" }}>
            <Image style={{ width: "80%" }}  src={ns_logo !== null ? ns_logo : null} />     
          </View>
          <View 
            style={{ 
                width: "60%",
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
          >
             <Text style={{ fontSize: '10px', marginTop: '5px' }}>
                {'PROFORMA INVOICE'}
            </Text>
            <Text style={{ fontSize: '20px', fontWeight: '600', marginTop: '5px' }}>
                {data?.orgnization_details?.comName !== null && data?.orgnization_details?.comName !== "" ? data?.orgnization_details?.comName : 'NA'}
            </Text>
            <Text style={{ fontSize: '10px', fontWeight: '600', marginTop: '5px' }}>
                {data?.orgnization_details?.comaddress !== null && data?.orgnization_details?.comaddress !== "" ? data?.orgnization_details?.comaddress : 'NA'}
            </Text>
            <Text style={{ fontSize: '10px', fontWeight: '600', marginTop: '5px', marginBottom: '5px' }}>
            {'Mumbai - 400049 Ph: +91 9819480480'}
            </Text>
          </View>
          <View style={{ width: "20%" }}></View>
        </View>

        <View style={[styles.row1]}>
          <View style={[{width: "55%"}]}>
            <Text style={[styles.label2, {fontWeight: "bold", marginTop: "2px"}]}>To, </Text>
            <Text style={[styles.label2, {fontWeight: "bold"}]}>{data?.party_details?.name !== null && data?.party_details?.name !== "" ? data?.party_details?.name : "NA"}</Text>
            <Text style={[styles.label2]}>{data?.billing_address !== null && data?.billing_address !== "" ? data?.billing_address : "NA"}</Text>
            <View style={[styles.label2, {flexDirection: "row", fontWeight: "bold"}]}>
                <Text style={{marginTop: "2px"}}>GSTIN NO : </Text>
                <Text style={{marginTop: "2px"}}>
                    {data?.gst_no !== null && data?.gst_no !== "" ? data?.gst_no : "NA"}
                </Text>
            </View>
          </View>
          <View style={[{ borderLeft: "1px solid black", width: "45%"}]}>
            <View style={[styles.label2, {flexDirection: "row"}]}>
                <Text style={{marginTop: "2px"}}>EST NO : </Text>
                <Text style={{marginTop: "2px", fontWeight: "bold"}}>
                    {data?.invoice_id ? data?.invoice_id : "N/A"}
                </Text>
            </View>
            <View style={[styles.label2, {flexDirection: "row"}]}>
                <Text>DATE : </Text>
                <Text style={{fontWeight: "bold"}}>
                    {data?.invoice_date !== null && data?.invoice_date !== "" ? formatDate5(data?.invoice_date) : "NA"}
                </Text>
            </View>
          </View>
        </View>

        <View style={[styles.row3]}>
          <View style={styles.tablColumn1}>
            <Text>Sr No</Text>
          </View>
          <View style={styles.tablColumn2}>
            <Text>Particular</Text>
          </View>
          <View style={styles.tablColumn3}>
            <Text>HSN Code</Text>
          </View>
          <View style={styles.tablColumn4}>
            <Text>No. Of Sets</Text>
          </View>
          <View style={styles.tablColumn5}>
            <Text>Rate (₹)</Text>
          </View>
          <View style={styles.tablColumn5}>
            <Text>Amount (₹)</Text>
          </View>
        </View>

        {data?.performa_invoice?.map((val, i) => (
          <View key={i} style={[styles.row3]}>
            <View style={styles.tablColumn1Data}>
              <Text>{i + 1}</Text>
            </View>
            <View style={styles.tablColumn2Data}>
              <Text>{val.sales_order_line_item_details?.productname !== null && val.sales_order_line_item_details?.productname !== "" ? val.sales_order_line_item_details?.productname : "NA"}</Text>
            </View>
            <View style={styles.tablColumn3Data}>
                <Text>{val?.hsn_no !== null && val?.hsn_no !== "" ? val?.hsn_no : "NA"}</Text>
            </View>
            <View style={styles.tablColumn4Data}>
                <Text style={{ textAlign: "center" }}>{val.sales_order_line_item_details?.quantity !== null && val.sales_order_line_item_details?.quantity !== "" ? val.sales_order_line_item_details?.quantity : "NA"}</Text>
            </View>
            <View style={styles.tablColumn5Data}>
                <Text style={{ textAlign: "right" }}>{val.sales_order_line_item_details?.rate !== null && val.sales_order_line_item_details?.rate !== "" ? Number(val.sales_order_line_item_details?.rate).toFixed(2) : "0.00"}</Text>
            </View>
            <View style={styles.tablColumn5Data}>
                <Text style={{ textAlign: "right" }}>{val.sales_order_line_item_details?.amount !== null && val.sales_order_line_item_details?.amount !== "" ? Number(val.sales_order_line_item_details?.amount).toFixed(2) : "0.00"}</Text>
            </View>
          </View>
        ))}

        <View style={[styles.row1]}>
          <View style={{width: "42%"}}>
            <View style={[{marginLeft: "5px", fontSize: "10px"}]}>
                <View style={{flexDirection: "row"}}>
                    <Text>BANK NAME : </Text>
                    <Text>
                        {/* {data?.[0]?.work_order_qty
                            ? data?.[0]?.work_order_qty
                            : 0} */}
                    </Text>
                </View>
                <View style={{flexDirection: "row"}}>
                    <Text>BRANCH : </Text>
                    <Text>
                        {/* {data?.[0]?.work_order_qty
                            ? data?.[0]?.work_order_qty
                            : 0} */}
                    </Text>
                </View>
                <View style={{flexDirection: "row"}}>
                    <Text>A/C NO : </Text>
                    <Text>
                        {/* {data?.[0]?.work_order_qty
                            ? data?.[0]?.work_order_qty
                            : 0} */}
                    </Text>
                </View>
                <View style={{flexDirection: "row"}}>
                    <Text>IFSC CODE : </Text>
                    <Text>
                        {/* {data?.[0]?.work_order_qty
                            ? data?.[0]?.work_order_qty
                            : 0} */}
                    </Text>
                </View>
            </View>
          </View>
          <View style={{ borderLeft: "1px solid black",  width: "34%" }}>
          <View style={[{ marginLeft: "5px", fontSize: "10px"}]}>
                <View style={{flexDirection: "row"}}>
                    <Text>GSTIN NO : </Text>
                    <Text>
                        {/* {data?.[0]?.work_order_qty
                            ? data?.[0]?.work_order_qty
                            : 0} */}
                    </Text>
                </View>
                <View style={{flexDirection: "row"}}>
                    <Text>PAN NO : </Text>
                    <Text>
                        {/* {data?.[0]?.work_order_qty
                            ? data?.[0]?.work_order_qty
                            : 0} */}
                    </Text>
                </View>
            </View>
          </View>
          <View style={[{ borderLeft: "1px solid black", width: "24%" }]}>
            <View
              style={[styles.subDivRow, { borderBottom: "1px solid black" }]}
            >
              <View style={[styles.column, { padding: "5px", padding: "5px" }]}>
                <Text style={{fontWeight: "bold"}}>{"Sub Total"}</Text>
              </View>
              <View
                style={[
                  styles.column,
                  { padding: "5px", borderLeft: "1px solid black" },
                ]}
              >
                <Text style={{ textAlign: "right" }}>{`${Number(data?.sub_total)?.toFixed(2) || 0}`}</Text>
              </View>
            </View>
            <View style={[styles.subDivRow]}>
              <View style={[styles.column, { padding: "5px" }]}>
                <Text style={{fontWeight: "bold"}}>{"CGST"}{" "}{"6%"}</Text>
              </View>
              <View
                style={[
                  styles.column,
                  { padding: "5px", borderLeft: "1px solid black" },
                ]}
              >
                <Text style={{ textAlign: "right" }}>{`${0}`}</Text>
              </View>
            </View>
          </View>
        </View>

        <View style={[styles.row1]}>
          <View style={[{width: "76%" }]}>
              <Text style={[styles.label2, {marginTop: "5px"}]}>"I/We hereby certify that my/our Registration Certificate under the Maharashtra GST Tax Act, 
                2017 is in force on the date on which the sale of goods specified in this Tax Invoice is made by 
                me/us and that the transaction of sale covered by this Tax Invoice has been effected by me/us and 
                it shall be accounted for in the turnover of sales while filing of return and the due tax if any, 
                payable on the sale has been paid or shall be paid."</Text>
          </View>
          <View style={[{ borderLeft: "1px solid black", width: "24%" }]}>
            <View
              style={[styles.subDivRow, { borderBottom: "1px solid black" }]}
            >
              <View style={[styles.column, { padding: "5px", padding: "5px" }]}>
                <Text style={{fontWeight: "bold"}}>{"SGST"}{" "}{"6%"}</Text>
              </View>
              <View
                style={[
                  styles.column,
                  { padding: "5px", borderLeft: "1px solid black" },
                ]}
              >
                <Text style={{ textAlign: "right" }}>{`${0}`}</Text>
              </View>
            </View>
            <View style={[styles.subDivRow]}>
              <View style={[styles.column, { padding: "5px" }]}>
                <Text style={{fontWeight: "bold"}}>{"IGST"}{" "}{"12%"}</Text>
              </View>
              <View
                style={[
                  styles.column,
                  { padding: "5px", borderLeft: "1px solid black" },
                ]}
              >
                <Text style={{ textAlign: "right" }}>{`${Number(data?.gst_amount)?.toFixed(2) || 0}`}</Text>
              </View>
            </View>
            <View style={[styles.subDivRow, { borderTop: "1px solid black" }]}>
              <View style={[styles.column, { padding: "5px" }]}>
                <Text style={{fontWeight: "bold"}}>{"Grand Total "}</Text>
              </View>
              <View
                style={[
                  styles.column,
                  { padding: "5px", borderLeft: "1px solid black" },
                ]}
              >
                <Text style={{ textAlign: "right", fontWeight: "bold" }}>{`${Number(Number(data?.sub_total) + Number(data?.gst_amount))?.toFixed(2) || 0}`}</Text>
              </View>
            </View>
          </View>
        </View>

       
        <View style={styles.row1}>
            <Text style={[styles.label, { borderBottom: '1px solid black', marginTop: "2px", marginBottom: "2px" }]}>Rupees in words : </Text>
            <Text style={[styles.label, { borderBottom: '1px solid black', marginTop: "2px", marginBottom: "2px", fontSize: "10px" }]}>
            {/* {numWords(Number(Number(data?.sub_total) + Number(data?.gst_amount)))} */}
            {convertToRupees(Number(Number(data?.sub_total) + Number(data?.gst_amount)))}
            </Text>
        </View>
        

        <View style={[styles.row1]}>
          <View style={[{width: "76%" }]}>
                {/* <Text style={[styles.label, { borderBottom: '1px solid black', marginTop: "2px", marginBottom: "2px" }]}> Terms & Conditions </Text> */}
                <Text style={[styles.label, { textDecoration: "underline", marginTop: "5px", marginBottom: "5px"  }]}>Terms & Conditions</Text>
                <Text style={styles.label2}> 1. Goods once sold will not be taken back or exchanged</Text>
                <Text style={styles.label2}> 2. All taxes as applicable will be extra</Text>
                <Text style={styles.label2}> 3. Disputed if any is subject to Pune Jurisdiction.</Text>
                <Text style={styles.label2}> 4. Please pay by Cheque/D.D. only in favour of <Text style={{ fontWeight: "bold" }}>NIVEDITA SABOO COUTURE LLP</Text>.</Text>
          </View>
          <View style={[{ borderLeft: "1px solid black", width: "24%" }]}>
            <Text style={[styles.label, { fontSize: "8px", marginTop: "50%", marginBottom: "5px"  }]}>For Nivedita Saboo Couture LLP</Text>
          </View>
        </View>

        <View style={styles.footer1}>
          <View
            style={{
              flexDirection: "row",
              fontSize: "12px",
              marginTop: "80px",
              marginLeft: "40px",
            }}
          >
          </View>
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );

 

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="manufac_goods_form mb-4">
        <div className="card">
          <div className="card-header bg-grey d-flex align-items-center justify-content-between">
            <div>
              <div className="p-2 d-flex align-items-center">
                <button
                  className="btn btn-yellow"
                  onClick={() => {
                    navigate("/pcms/proforma-invoice");
                  }}
                >
                  <FontAwesomeIcon icon={faArrowLeft} size="sm" />
                </button>

                <p className="mb-0 ms-3" style={{ fontSize: "24px" }}>
                  Proforma Invoice
                </p>
              </div>
            </div>

            <div className=" p-2">
              <button
                className="btn btn-yellow"
                style={styles.downloadButton}
                onClick={handleDownload}
                disabled={!isPdfLoaded}
              >
                <FontAwesomeIcon icon={faDownload} size="sm" /> Download PDF
              </button>
            </div>
          </div>
          <div className="card-body">
            <div className="createNc container-fluid">
              <PDFViewer style={styles.viewer}>
                <WOStatusPDF data={data} />
              </PDFViewer>
              <a ref={downloadLinkRef} style={{ display: "none" }} />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Proforma_Invoice_pdf;