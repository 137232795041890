import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faFileAlt, faMusic } from "@fortawesome/free-solid-svg-icons";
import { Modal, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import audio from "../../../assets/images/audio.png"
import { getLabels, truncateText } from "../../../common/Functions/CommonFunctions";

const imageFileTypes = ["image/jpeg", "image/jpg", "image/png"];
const audioFileTypes = ["audio/mpeg", "audio/wav", "audio/mp3"];

const schema = yup.object().shape({
  image: yup
    .mixed()
    .test(
      "fileSize",
      "File can be uploaded up to 10 MB",
      (value) => 
        !value || Array.from(value).every((file) => file.size <= 10 * 1024 * 1024)
    )
    .test(
      "fileType",
      "Only image or audio files are allowed",
      (value) =>
        !value || Array.from(value).every((file) => 
          imageFileTypes.includes(file.type) || audioFileTypes.includes(file.type)
        )
    )
    .required("File is required"),
});

function DesignSheetUpload(props) {
  const { imageFiles, setImageFiles, visible, setVisible } = props;

  const [new_files, set_new_files] = useState([]);
  const [lockBtn, setLockBtn] = useState(false);

  const [constLabel, setConstLabel] = useState({});

  const {
    register,
    handleSubmit,
    formState,
    setValue,
    reset,
    setError,
    clearErrors
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  let { errors } = formState;

  useEffect(() => {
    if(visible){
        setConstLabel(getLabels("Sales", "Sales Order"));
    }
  }, [visible]);


  const [dragActive, setDragActive] = useState(false);
  const fileInputRef = useRef(null);

  // const handleFiles = (files) => {
  //   console.log("files", files)
  //     set_new_files((prevFiles) => [
  //       ...prevFiles,
  //       ...Array.from(files).map((file) => ({ file })) // Convert each file to an object
  //     ]);
  // };

  const handleFiles = (files) => {
    const newFilesArray = Array.from(files).map((file) => ({ file }));

    set_new_files((prevFiles) => {
        // Extract existing file names to avoid duplication
        const existingFileNames = new Set(prevFiles.map(f => f.file.name));

        // Filter out duplicates before adding
        const filteredFiles = newFilesArray.filter(f => !existingFileNames.has(f.file.name));

        // If no new files, return previous state
        if (filteredFiles.length === 0) return prevFiles;

        const updatedFiles = [...prevFiles, ...filteredFiles];
        // console.log("Updated state files:", updatedFiles);
        return updatedFiles;
    });
};

  const removeFile = (index) => {
    set_new_files((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleDrag = (event, isActive) => {
    event.preventDefault();
    setDragActive(isActive);
  };

  // const handleFileInputChange = (e) => {
  //   // console.log("e.target.files", e.target.files)
  //   handleFiles(e.target.files);
  //   e.target.value = null; // Clear the input value
  // };

  const handleFileInputChange = (e) => {
    const files = e.target.files;
    if (files.length === 0) return; // Prevent empty selections
  
    // Check if all selected files are image or audio
    const isValid = Array.from(files).every(file => 
      file.type.startsWith("image/") || file.type.startsWith("audio/")
    );
  
    if (!isValid) {
      setError("image", {
        type: "manual",
        message: "Only image or audio files are allowed!",
      });
      e.target.value = null; // Reset file input
      return;
    }
  
    // If valid, update the field and trigger validation
    clearErrors("image"); // Clear previous errors
    handleFiles(files);
    setValue("image", files, { shouldValidate: true });
  
    e.target.value = null; // Clear input value after processing
  };

  const onSubmit = () => {
    let arr = new_files;
    setImageFiles(arr);
    setTimeout(() => {
      setVisible(false);
    }, 50);
  };
  
  return (
    <React.Fragment>
      <ToastContainer />

      <Modal
        show={visible}
        onHide={() => {
          setVisible(false);
          set_new_files([]);
        }}
        size="lg"
        backdrop="static"
      >
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>
            {constLabel?.lbl_design_sheet
              ? constLabel.lbl_design_sheet
              : "Design Sheet"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
            <div className="row mb-4">
              <div className="col-12">
                <div
                    className={`file-upload-container ${dragActive ? "drag-active" : ""} d-flex flex-column align-items-center justify-content-center p-5 text-center border border-secondary border-dashed`}
                    onDragOver={(e) => handleDrag(e, true)}
                    onDragLeave={(e) => handleDrag(e, false)}
                    onDrop={(e) => { handleDrag(e, false); handleFiles(e.dataTransfer.files); }}
                    onClick={() => fileInputRef?.current.click()}
                >
                    <input
                    className={`form-control form-control-sm ${errors?.image ? "is-invalid" : ""}`}
                    type="file"
                    multiple
                    name="image"
                    accept="image/*, audio/*"
                    onChange={handleFileInputChange}
                    ref={fileInputRef}
                    hidden
                    />
                    <span className="fw-bold">
                    Drag and drop or <span className="file-link text-primary">Choose your files</span>
                    </span>
                </div>
                {errors?.image && <span className="text-danger err-msg">{errors.image.message}</span>}
              </div>
            </div>

            {new_files?.loading ? (
                <div className="text-center mt-4">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            ) : new_files?.length > 0 ? (
                <div className="row drawing_gallery row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4">
                {new_files.map((val, i) => {
                    const fileType = val.file?.type || ""; // Get the file type
                    const fileURL = URL.createObjectURL(val?.file); // Create object URL for the file
                    return (
                        <div className="col text-center" key={i}>
                        <div className="image-container">
                            {fileType.includes("image") ? (
                                <a href={fileURL} target="_blank" rel="noopener noreferrer">
                            <img
                                src={fileURL}
                                className="img-fluid"
                                alt={val.file?.name || "Image"}
                            />
                            </a>
                            ) : fileType.includes("audio") ? (
                            <a href={fileURL} target="_blank" rel="noopener noreferrer">
                                <img 
                                src={audio} 
                                alt="Audio File" 
                                className="audio-icon" 
                                style={{ width: "100px", height: "50px" }} 
                                />
                            </a>
                            ) : (
                            <div
                                onClick={() => window.open(fileURL, "_blank")}
                                className="other_doc d-flex justify-content-center align-items-center cursor-pointer"
                            >
                                <FontAwesomeIcon
                                className="icon-file-default"
                                style={{ color: "#fff" }}
                                icon={faFileAlt} // Icon for default file type
                                size="3x"
                                />
                            </div>
                            )}

                            <FontAwesomeIcon
                            onClick={() => removeFile(i)}
                            className="icon-close"
                            style={{ color: "#000" }}
                            icon={faClose}
                            size="lg"
                            />
                        </div>

                        <span className="file-name cursor-pointer">
                            {truncateText((val.file?.name), 15)}
                        </span>
                        </div>
                    );
                })}
                </div>
            ) : (
                <div
                  className="alert alert-danger mb-0 text-center my-4"
                  role="alert"
                >
                 {"Please select and upload files!"}
                </div>
            )}
        </Modal.Body>
        <Modal.Footer>
            <button
                className="btn btn-sm btn-yellow px-3"
                onClick={() => onSubmit()}
                // onClick={() => submitFiles()}
                disabled={lockBtn || new_files.length === 0} // Disable if no files
                >
                {constLabel?.lbl_submit ? constLabel.lbl_submit : "Submit"}{" "}
                {lockBtn ? <Spinner animation="border" size="sm" /> : null}
            </button>
            <button
              className="btn btn-sm btn-grey px-4"
              onClick={() => {
                setVisible(false);
                set_new_files([]);
              }}
            >
              {constLabel?.lbl_close ? constLabel.lbl_close : "Close"}
            </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default DesignSheetUpload;