import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleDown, faArrowLeft, faPlus } from "@fortawesome/free-solid-svg-icons";
import SweetAlert from "sweetalert2";
import { useForm, Controller } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import {
  getInternalCompany,
  get_company_type,
  get_gst_treatment,
  get_industry_type,
  get_master_files,
  get_org_type,
  master_multi_file_upload,
  save_internal_company,
  update_internal_company,
} from "../../../api";
import Gallary from "./Gallary";
import {
  convertBase64,
  convertUrl,
  getLabels,
} from "../../../common/Functions/CommonFunctions";
import Gallary2 from "./Gallary2";
import AddConfiguration from "./AddConfiguration";
import { Edit, Trash2 } from "react-feather";
import { Spinner } from "react-bootstrap";
import IndirectCosting from "./IndirectCosting";

const schema = yup
  .object()
  .shape({
    orgnisation_type: yup
      .array()
      .min(1, "Please select orgnisation type !")
      .required("Please select orgnisation type !"),
    company_type: yup
      .array()
      .min(1, "Please select company type !")
      .required("Please select company type !"),
    gst_type: yup
      .array()
      .min(1, "Please select gst registration type !")
      .required("Please select gst registration type !"),
    // parent_id: yup
    //     .array()
    //     .min(1, "Please select parent company !")
    //     .required("Please select parent company !"),
    comName: yup.string().required("Please enter company name !"),
    comaddress: yup.string().required("Please enter company address !"),
    comGSTnumber: yup.string().when(["gst_type"], (gst_type, schema) => {
      if (gst_type !== undefined && gst_type.length > 0) {
        const gst_typeSelected = gst_type[0].name == "Register";

        // If 'gst_tye' is in the category, make 'GST number' mandatory
        return gst_typeSelected
          ? schema
              .required("Please enter GST number !")
              .max(15, "GST number must be 15 characters only !")
              .matches(/^[A-Z0-9]*$/, "Enter valid GST number !")
          : schema;
      } else {
        return schema;
      }
    }),
    // comGSTnumber: yup
    //     .string()
    //     .required("Please enter GST number !")
    //     .max(15, 'GST number must be 15 characters only !')
    //     .matches(/^[A-Z0-9]*$/, 'Enter valid GST number !'),
    first_name: yup
      .string()
      .matches(/^[a-zA-Z ]*$/, "Enter valud first name!")
      .notRequired(),
    middle_name: yup
      .string()
      .matches(/^[a-zA-Z ]*$/, "Enter valud middle name!")
      .notRequired(),
    last_name: yup
      .string()
      .matches(/^[a-zA-Z ]*$/, "Enter valud last name!")
      .notRequired(),
    mail: yup
      .string()
      .email("Invalid email address !")
      .required("Please enter mail !"),
    phone: yup
      .string()
      .required("Please enter phone number !")
      .matches(/^[0-9]+(\.[0-9]+)?$/, "Enter valid phone number !"),
    landline: yup
      .string()
      .max(10, "Landline number must be 10 characters only")
      .matches(/^[0-9]*$/, "Please enter a valid landline number")
      .notRequired(),
    uan_no: yup
      .string()
      .max(12, "Udyog Aadhaar number must be 12 characters only !")
      .matches(/^[A-Z0-9]*$/, "Please enter valid Udyog Aadhaar number !")
      .notRequired(),
    cin_no: yup
      .string()
      .max(21, "CIN number must be 21 characters only")
      .matches(/^[A-Z0-9]*$/, "Please enter a valid CIN number")
      .notRequired(),
  })
  .required();

function UpdateOrgnization() {
  let navigate = useNavigate();
  let location = useLocation();
  const {
    uuid,
    comId,
    comName,
    comaddress,
    first_name,
    last_name,
    industry,
    linkedin,
    middle_name,
    mail,
    phone,
    comGSTnumber,
    company_type,
    orgnisation_type,
    gst_type,
    parent_id,
    parent_name,
    location_name,
    website,
    landline,
    uan_no,
    cin_no,
    org_configuration
  } = location.state;

  const [constLabel, setConstLabel] = useState({});
  const [lockBtn, setLockBtn] = useState(false);
  const { register, handleSubmit, formState, control, reset, watch, setValue } =
    useForm({
      resolver: yupResolver(schema),
      mode: "onChange",
    }); // initialise the hook

  let { errors } = formState;

  const [visible, setVisible] = useState(false);
  const [name_gal, setName_gal] = useState({ name: "", type: "" });
  const [files, setFiles] = useState({
    gst_certificate: {
      name: "",
      file: {},
    },
    uydog_certificate: {
      name: "",
      file: {},
    },
    cin_certificate: {
      name: "",
      file: {},
    },
  });

  const [files_arr, setFiles_arr] = useState([]);
  const [parentlist, setParentlist] = useState({
    data: [],
    loading: false,
  });
  const [companytype, setCompanytype] = useState({
    data: [],
    loading: false,
  });
  const [orgtype, setOrgtype] = useState({
    data: [],
    loading: false,
  });
  const [gstType, setGstType] = useState({
    data: [],
    loading: false,
  });

  const [industryType, setIndustryType] = useState({
    data: [],
    loading: false,
  });

  const [visible2, setVisible2] = useState(false);
  const [table_label, setTable_lables] = useState([]);
  const [editedKey2, setEditedKey2] = useState("");
  const [editedValue2, setEditedValue2] = useState({});

  const [visible3, setVisible3] = useState(false);
  const [cost_json, setCost_Json] = useState(org_configuration && Object.keys(org_configuration).length > 0 ? (Array.isArray(org_configuration?.factory_running_cost) ? org_configuration?.factory_running_cost : []) : []);

  const localData = JSON.parse(localStorage.getItem("localData"));

  useEffect(() => {
    setConstLabel(getLabels("Master", "Organization"));
    fetchInternal_company();
    fetchCompanyType();
    fetchOrgType();
    fetchGstType();
    fetchIndustryType();

    if(org_configuration && Object.keys(org_configuration).length > 0){
      // Transform configuration into the desired format
    const transformedData = Object.entries(org_configuration).filter(([key]) => key !== "factory_running_cost").map(([key, value]) => {
      const isBoolType = typeof value == "boolean";
      const isArrayType = Array.isArray(value);
      return {
        key: "label",
        value: key,
        flag: isBoolType ? value == true ? true : false : null, // Replace `false` with any `isChecked` logic if needed
        text_value: isBoolType 
          ? null 
          : isArrayType 
            ? JSON.stringify(value) // Convert array to a JSON string
            : value, // Keep normal values as they are
      };
    });
      setTable_lables(transformedData);

    // } else if(localData?.configuration && Object.keys(localData?.configuration).length > 0){
    //   // Transform configuration into the desired format
    // const transformedData = Object.entries(localData?.configuration).filter(([key]) => key !== "factory_running_cost").map(([key, value]) => {
    //   const isBoolType = typeof value == "boolean";
    //   const isArrayType = Array.isArray(value);
    //   return {
    //     key: "label",
    //     value: key,
    //     flag: isBoolType ? value == true ? true : false : null, // Replace `false` with any `isChecked` logic if needed
    //     text_value: isBoolType 
    //       ? null 
    //       : isArrayType 
    //         ? JSON.stringify(value) // Convert array to a JSON string
    //         : value, // Keep normal values as they are
    //       };
    // });
    //   setTable_lables(transformedData);

    } else {
      setTable_lables([]);
    }

  }, []);

  function pullConfiguration(){
    if(localData?.configuration && Object.keys(localData?.configuration).length > 0){
      // Transform configuration into the desired format
    const transformedData = Object.entries(localData?.configuration).filter(([key]) => key !== "factory_running_cost").map(([key, value]) => {
      const isBoolType = typeof value == "boolean";
      return {
        key: "label",
        value: key,
        flag: isBoolType ? value == true ? true : false : null, // Replace `false` with any `isChecked` logic if needed
        text_value: isBoolType ? null : value,
      };
    });
      setTable_lables(transformedData);
    } else {
      setTable_lables([]);
    }
  }

  useEffect(() => {
    fetchImage();
  }, [visible]);

  const fetchInternal_company = () => {
    setParentlist({ data: [], loading: true });

    getInternalCompany({})
      .then((res) => {
        setParentlist({data: res.data.data, loading: false });
      })
      .catch((err) => {
        setParentlist({data: [], loading: false });
      });
  };

  function fetchCompanyType() {
    setCompanytype({ loading: true, data: [] });

    get_company_type().then(
      (res) => {
        setCompanytype({
          loading: false,
          data: res.data.data,
        });
      },
      (err) => {
        //
        setCompanytype({ loading: false, data: [] });
      }
    );
  }

  function fetchOrgType() {
    setOrgtype({ loading: true, data: [] });

    get_org_type().then(
      (res) => {
        setOrgtype({
          loading: false,
          data: res.data.data,
        });
      },
      (err) => {
        //
        setOrgtype({ loading: false, data: [] });
      }
    );
  }

  function fetchGstType() {
    setGstType({ loading: true, data: [] });

    get_gst_treatment().then(
      (res) => {
        setGstType({
          loading: false,
          data: res.data.data,
        });
      },
      (err) => {
        //
        setGstType({ loading: false, data: [] });
      }
    );
  }

  function fetchImage() {
    get_master_files({
      ref_model_uuid: location.state?.uuid,
    }).then(
      (res) => {
        let arr = res.data.data.map((item) => {
          item.attachment = convertUrl(item.attachment);
          return item;
        });
        setFiles_arr(arr);
      },
      (err) => {}
    );
  }

  function fetchIndustryType() {
    setIndustryType({ loading: true, data: [] });

    get_industry_type().then(
      (res) => {
        setIndustryType({
          loading: false,
          data: res.data.data,
        });
      },
      (err) => {
        //
        setIndustryType({ loading: false, data: [] });
      }
    );
  }

  const onSubmit = (data) => {
    setLockBtn(true);
    if (
      files_arr.length > 0 &&
      !files_arr.some((obj) => obj["description"] === "gst_certificate") &&
      data.comGSTnumber.length > 0 &&
      files.gst_certificate.name.length == 0
    ) {
      toast.error("Please Upload GST Certificate !", {
        autoClose: 2000,
      });
      return;
    }

    if (
      files_arr.length > 0 &&
      !files_arr.some((obj) => obj["description"] === "uydog_certificate") &&
      data.uan_no.length > 0 &&
      files.uydog_certificate.name.length == 0
    ) {
      toast.error("Please Upload Udyog Aadhar Certificate !", {
        autoClose: 2000,
      });
      return;
    }

    if (
      files_arr.length > 0 &&
      !files_arr.some((obj) => obj["description"] === "cin_certificate") &&
      data.cin_no.length > 0 &&
      files.cin_certificate.name.length == 0
    ) {
      toast.error(" Upload CIN Certificate !", {
        autoClose: 2000,
      });
      return;
    }

    
    let config_obj = {}
    table_label.forEach((item)=>{
      let ob = {[`${item.value}`]: item.flag==null?item.text_value:item.flag};
      config_obj = {...config_obj, ...ob}   
    });

    if(cost_json?.length > 0 ){
      config_obj = { ...config_obj, factory_running_cost: cost_json };
    }
    
    const payload = {
      ...data,
      uuid: uuid,
      parent_id:
        data.parent_id !== undefined && data.parent_id.length !== 0
          ? data.parent_id[0].uuid
          : null,
      //   parent_name:
      //     data.parent_id !== undefined && data.parent_id.length !== 0
      //       ? data.parent_id[0].comName
      //       : null,
      company_type: data.company_type[0].name,
      orgnisation_type: data.orgnisation_type[0].name,
      gst_type: data.gst_type[0].name,
      industry: data.industry?.[0]?.type_name ?? "",
      org_configuration: config_obj && Object.keys(config_obj)?.length > 0 ? config_obj : null
    };

    // console.log("payload", payload); return;

    let arr = [];

    for (const x in files) {
      if (files[x].name.length > 0) {
        arr.push(files[x]);
      }
    }

    update_internal_company(payload)
      .then(async (res) => {
        let barr = [];

        for (let i = 0; i < arr.length; i++) {
          let document_type = null;
          if (arr[i].file.type === "image/png") {
            document_type = "image";
          }
          if (arr[i].file.type === "image/jpeg") {
            document_type = "image";
          }
          if (arr[i].file.type === "image/jpg") {
            document_type = "image";
          }
          if (arr[i].file.type === "application/pdf") {
            document_type = "document";
          }

          const getExt = arr[i].file.name?.split(".").pop();

          console.log("get", getExt);
          const dataimage = await convertBase64(arr[i].file);
          const newImage = dataimage.replace(
            /data:image\/[a-zA-Z]+;/,
            "data:image/" + getExt + ";"
          );
          barr.push({
            document: newImage,
            document_type: document_type,
            document_name: arr[i].name + `.${getExt}`,
            description: arr[i].type,
          });
        }

        const payload = {
          model_name: "Organization",
          ref_model_id: comId,
          ref_model_uuid: uuid,
          files: barr,
        };

        if (barr.length > 0) {
          master_multi_file_upload(payload).then(
            (res) => {
              toast.success("Organization updated successfully !", {
                autoClose: 2000,
              });
              setTimeout(() => {
                navigate(`/pcms/orgnization`);
              }, 2200);
              setLockBtn(false);
            },
            (err) => {
              toast.error("Image upload failed !", {
                autoClose: 3000,
              });
            }
          );
        } else {
          toast.success("Organization updated successfully !", {
            autoClose: 2000,
          });
          setTimeout(() => {
            navigate(`/pcms/orgnization`);
          }, 2200);
          setLockBtn(false);
        }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          toast.error(err.response.data.message, {
            autoClose: 2000,
          });

          // setTimeout(() => {
          //   navigate(`/pcms/orgnization`);
          // }, 2200);
        }
        setLockBtn(false);
      });
  };

  const handleUpdate2 = (ind) => {
    let arr = [...table_label];
    arr[ind] = editedValue2;
 
    setTable_lables(arr);
    setEditedKey2("");
    setEditedValue2([]);
  };

  const handleEdit2 = (ind) => {
    console.log("ind", ind);
    setEditedKey2(ind);
    setEditedValue2(table_label[ind]);
  };

  const handleDelete2 = (ind) => {
    table_label.splice(ind, 1);
    setTable_lables([...table_label]);
  };

  const handleInputChange2 = (event) => {
    console.log("key", event.target.checked);
    if (event.target.type == "text") {
      let obj = { ...editedValue2, text_value: event.target.value };
      setEditedValue2(obj);
    } else {
      let obj = { ...editedValue2, flag: event.target.checked };
      setEditedValue2(obj);
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <Gallary2
        visible={visible}
        setVisible={setVisible}
        files={files}
        setFiles={setFiles}
        name_gal={name_gal}
        files_arr={files_arr}
      />

      <AddConfiguration
        visible={visible2}
        setVisible={setVisible2}
        table_label={table_label}
        setTable_lables={setTable_lables}
      />

      <IndirectCosting
        visible={visible3}
        setVisible={setVisible3}
        cost_json={cost_json}
        setCost_Json={setCost_Json}
      />

      <div className="purchase_order mb-4">
        <div className="card">
          <div className="card-header bg-grey d-flex align-items-center">
            <button
              className="btn btn-yellow"
              onClick={() => {
                window.onpopstate = undefined;
                navigate(-1);
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} size="sm" />
            </button>

            <p className="mb-0 ms-3" style={{ fontSize: "24px" }}>
              {constLabel?.lbl_update_organization
                ? constLabel?.lbl_update_organization
                : "Update Organization"}
            </p>
          </div>
          <div className="card-body">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12 col-sm-4">
                  <div className="form-group typeahead-container">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_parent
                        ? constLabel?.lbl_parent
                        : "Parent"}
                      {/* <span className="text-danger">*</span> */}
                    </label>
                    <Controller
                      name="parent_id"
                      control={control}
                      defaultValue={
                        parent_id == null
                          ? []
                          : [{ comName: parent_name, uuid: parent_id }]
                      }
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          className={"mt-2"}
                          id="basic-typeahead-single"
                          labelKey="comName"
                          multiple={false}
                          options={parentlist.data}
                          isLoading={parentlist.loading}
                          placeholder="Select Parent "
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          defaultSelected={
                            parent_id == null
                              ? []
                              : [{ comName: parent_name, uuid: parent_id }]
                          }
                          isInvalid={errors.parent_id ? true : false}
                        />
                      )}
                    />
                    {!parentlist.loading && (
                      <span className="arrow-icon"></span>
                    )}
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div className="form-group">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_company_name
                        ? constLabel?.lbl_company_name
                        : "Company Name"}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      className={
                        errors.comName
                          ? "form-control mt-2 is-invalid"
                          : "form-control mt-2"
                      }
                      type="text"
                      id="comName"
                      name="comName"
                      placeholder="Enter company name"
                      defaultValue={comName}
                      {...register("comName")}
                    />
                    <span className="text-danger err-msg">
                      {errors.comName?.message}
                    </span>
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div className="form-group typeahead-container">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_organization_type
                        ? constLabel?.lbl_organization_type
                        : "Organization Type"}
                      <span className="text-danger">*</span>
                    </label>
                    <Controller
                      name={`orgnisation_type`}
                      control={control}
                      defaultValue={
                        orgnisation_type == null
                          ? []
                          : [{ name: orgnisation_type }]
                      }
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          className={"mt-2"}
                          id="basic-typeahead-single"
                          labelKey="name"
                          multiple={false}
                          options={orgtype.data}
                          isLoading={orgtype.loading}
                          placeholder="Select organization type"
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          defaultSelected={
                            orgnisation_type == null
                              ? []
                              : [{ name: orgnisation_type }]
                          }
                          isInvalid={errors.orgnisation_type ? true : false}
                        />
                      )}
                    />
                    {!orgtype.loading && !errors.orgnisation_type && (
                      <span className="arrow-icon"></span>
                    )}
                    {errors.orgnisation_type && (
                      <span className="text-danger err-msg">
                        {errors.orgnisation_type.message}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-12 col-sm-4">
                  <div className="form-group typeahead-container">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_company_type
                        ? constLabel?.lbl_company_type
                        : "Company Type"}
                      <span>
                        <i style={{ color: "red" }}>*</i>
                      </span>
                    </label>
                    <Controller
                      name="company_type"
                      control={control}
                      defaultValue={
                        company_type == null ? [] : [{ name: company_type }]
                      }
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          className={"mt-2"}
                          id="basic-typeahead-single"
                          labelKey="name"
                          multiple={false}
                          options={companytype.data}
                          isLoading={companytype.loading}
                          placeholder="Select company type "
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          defaultSelected={
                            company_type == null ? [] : [{ name: company_type }]
                          }
                          isInvalid={errors.company_type ? true : false}
                        />
                      )}
                    />
                    {!companytype.loading && !errors.company_type && (
                      <span className="arrow-icon"></span>
                    )}
                    <span className="text-danger err-msg">
                      {errors.company_type?.message}
                    </span>
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div className="form-group typeahead-container">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_gst_type
                        ? constLabel?.lbl_gst_type
                        : "GST Registration Type"}
                      <span className="text-danger">*</span>
                    </label>
                    <Controller
                      name={`gst_type`}
                      control={control}
                      defaultValue={
                        gst_type == null ? [] : [{ name: gst_type }]
                      }
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          className={"mt-2"}
                          id="basic-typeahead-single"
                          labelKey="name"
                          multiple={false}
                          options={gstType.data}
                          isLoading={gstType.loading}
                          placeholder="Select gst registration type"
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          defaultSelected={
                            gst_type == null ? [] : [{ name: gst_type }]
                          }
                          isInvalid={errors.gst_type ? true : false}
                        />
                      )}
                    />
                    {!gstType.loading && !errors.gst_type && (
                      <span className="arrow-icon"></span>
                    )}
                    {errors.gst_type && (
                      <span className="text-danger err-msg">
                        {errors.gst_type.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div className="form-group">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_company_address
                        ? constLabel?.lbl_company_address
                        : "Company Address"}
                      <span>
                        <i style={{ color: "red" }}>*</i>
                      </span>
                    </label>

                    <input
                      className={
                        errors.comaddress
                          ? "form-control mt-2 is-invalid"
                          : "form-control mt-2"
                      }
                      type="text"
                      id="comaddress"
                      name="comaddress"
                      placeholder="Enter company address"
                      defaultValue={comaddress}
                      {...register("comaddress")}
                    />
                    <span className="text-danger err-msg">
                      {errors.comaddress?.message}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-12 col-sm-4">
                  <div className="form-group">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_phone_number
                        ? constLabel?.lbl_phone_number
                        : "Phone Number"}
                      <span>
                        <i style={{ color: "red" }}>*</i>
                      </span>
                    </label>

                    <input
                      className={
                        errors.phone
                          ? "form-control mt-2 is-invalid"
                          : "form-control mt-2"
                      }
                      type="text"
                      id="phone"
                      name="phone"
                      maxLength={10}
                      placeholder="Enter phone number"
                      defaultValue={phone}
                      {...register("phone")}
                    />
                    <span className="text-danger err-msg">
                      {errors.phone?.message}
                    </span>
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div className="form-group">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_mail ? constLabel?.lbl_mail : "Mail"}
                      <span>
                        <i style={{ color: "red" }}>*</i>
                      </span>
                    </label>

                    <input
                      className={
                        errors.mail
                          ? "form-control mt-2 is-invalid"
                          : "form-control mt-2"
                      }
                      type="text"
                      id="mail"
                      name="mail"
                      placeholder="Enter mail"
                      defaultValue={mail}
                      {...register("mail")}
                    />
                    <span className="text-danger err-msg">
                      {errors.mail?.message}
                    </span>
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div className="form-group">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_location_name
                        ? constLabel?.lbl_location_name
                        : "Location Name"}
                    </label>

                    <input
                      className={"form-control mt-2"}
                      type="text"
                      id="location_name"
                      name="location_name"
                      placeholder="Enter location"
                      defaultValue={location_name}
                      {...register("location_name")}
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-12 col-sm-4">
                  <div className="form-group typeahead-container">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_industry_type
                        ? constLabel?.lbl_industry_type
                        : "Industry Type"}
                    </label>
                    <Controller
                      name="industry"
                      control={control}
                      defaultValue={
                        industry !== "" && industry !== null
                          ? [{ type_name: industry }]
                          : []
                      }
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          className={"mt-2"}
                          id="basic-typeahead-single"
                          labelKey="type_name"
                          multiple={false}
                          options={industryType.data}
                          isLoading={industryType.loading}
                          placeholder="Select industry type "
                          defaultSelected={
                            industry !== "" && industry !== null
                              ? [{ type_name: industry }]
                              : []
                          }
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          // isInvalid={errors.industry ? true : false}
                        />
                      )}
                    />
                    {!industryType.loading && (
                      <span className="arrow-icon"></span>
                    )}
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div className="form-group">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_first_name
                        ? constLabel?.lbl_first_name
                        : "First Name"}
                      {/* <span>
                        <i style={{ color: "red" }}>*</i>
                      </span> */}
                    </label>

                    <input
                      className={
                        errors.first_name
                          ? "form-control mt-2 is-invalid"
                          : "form-control mt-2"
                      }
                      type="text"
                      id="first_name"
                      name="first_name"
                      placeholder="Enter first name"
                      defaultValue={first_name}
                      {...register("first_name")}
                    />
                    <span className="text-danger err-msg">
                      {errors.first_name?.message}
                    </span>
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div className="form-group">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_middle_name
                        ? constLabel?.lbl_middle_name
                        : "Middle Name"}
                      {/* <span>
                        <i style={{ color: "red" }}>*</i>
                      </span> */}
                    </label>

                    <input
                      className={
                        errors.middle_name
                          ? "form-control mt-2 is-invalid"
                          : "form-control mt-2"
                      }
                      type="text"
                      id="middle_name"
                      name="middle_name"
                      placeholder="Enter middle name"
                      defaultValue={middle_name}
                      {...register("middle_name")}
                    />
                    <span className="text-danger err-msg">
                      {errors.middle_name?.message}
                    </span>
                  </div>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-12 col-sm-4">
                  <div className="form-group">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_last_name
                        ? constLabel?.lbl_last_name
                        : "Last Name"}
                      {/* <span>
                        <i style={{ color: "red" }}>*</i>
                      </span> */}
                    </label>

                    <input
                      className={
                        errors.last_name
                          ? "form-control mt-2 is-invalid"
                          : "form-control mt-2"
                      }
                      type="text"
                      id="last_name"
                      name="last_name"
                      placeholder="Enter last name"
                      defaultValue={last_name}
                      {...register("last_name")}
                    />
                    <span className="text-danger err-msg">
                      {errors.last_name?.message}
                    </span>
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div className="form-group">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_landline_number
                        ? constLabel?.lbl_landline_number
                        : "Landline Number"}
                      {/* <span>
                                                <i style={{ color: "red" }}>*</i>
                                            </span> */}
                    </label>

                    <input
                      className={"form-control mt-2"}
                      type="text"
                      id="landline"
                      name="landline"
                      maxLength={10}
                      placeholder="Enter landline number"
                      defaultValue={landline}
                      {...register("landline")}
                    />
                    <span className="text-danger err-msg">
                      {errors.landline?.message}
                    </span>
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div className="form-group">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_website
                        ? constLabel?.lbl_website
                        : "Website"}
                      {/* <span>
                                                <i style={{ color: "red" }}>*</i>
                                            </span> */}
                    </label>

                    <input
                      className={"form-control mt-2"}
                      type="text"
                      id="website"
                      name="website"
                      placeholder="Enter website"
                      defaultValue={website}
                      {...register("website")}
                    />
                    {/* <span className="text-danger err-msg">
                                            {errors.website?.message}
                                        </span> */}
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-12 col-sm-4">
                  <div className="form-group">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_linkedIn
                        ? constLabel?.lbl_linkedIn
                        : "Linkedln"}
                      {/* <span>
                                                <i style={{ color: "red" }}>*</i>
                                            </span> */}
                    </label>

                    <input
                      className={"form-control mt-2"}
                      type="text"
                      id="linkedin"
                      name="linkedin"
                      placeholder="Enter linkedln url "
                      defaultValue={linkedin}
                      {...register("linkedin")}
                    />
                    {/* <span className="text-danger err-msg">
                                            {errors.website?.message}
                                        </span> */}
                  </div>
                </div>
              </div>
            </div>
            <hr className="m-3" />
            <div className="d-flex justify-content-between align-items-center px-2 mt-4">
              <p className="roboto-bold m-0" style={{ fontSize: "24px" }}>
                {constLabel?.lbl_taxAndCertificationInfo
                  ? constLabel.lbl_taxAndCertificationInfo
                  : "Tax and Certification Info"}
              </p>
            </div>
            <div className="container-fluid">
              <div className="row mt-3">
                <div className="col-12 col-sm-4">
                  <div className="form-group">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_gst_number
                        ? constLabel.lbl_gst_number
                        : "GST Number"}
                      {/* <span>
                                                <i style={{ color: "red" }}>*</i>
                                            </span> */}
                    </label>

                    <input
                      className={
                        errors.comGSTnumber
                          ? "form-control mt-2 is-invalid"
                          : "form-control mt-2"
                      }
                      type="text"
                      id="comGSTnumber"
                      name="comGSTnumber"
                      maxLength={15}
                      placeholder="Enter GST number"
                      defaultValue={comGSTnumber}
                      {...register("comGSTnumber")}
                    />
                    <span className="text-danger err-msg">
                      {errors.comGSTnumber?.message}
                    </span>
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div className="form-group">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_udyog_aadhar
                        ? constLabel.lbl_udyog_aadhar
                        : "Udyog Aadhar"}
                      {/* <span>
                                                <i style={{ color: "red" }}>*</i>
                                            </span> */}
                    </label>

                    <input
                      className={"form-control mt-2"}
                      type="text"
                      id="uan_no"
                      name="uan_no"
                      maxLength={12}
                      placeholder="Enter udyog aadhar number"
                      defaultValue={uan_no}
                      {...register("uan_no")}
                    />
                    <span className="text-danger err-msg">
                      {errors.uan_no?.message}
                    </span>
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div className="form-group">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_cin_no
                        ? constLabel.lbl_cin_no
                        : "CIN No"}
                      {/* <span>
                                                <i style={{ color: "red" }}>*</i>
                                            </span> */}
                    </label>{" "}
                    <em className="body3">{"( Ex. L12345AB6789XYZ012345 )"}</em>
                    <input
                      className={"form-control mt-2"}
                      type="text"
                      id="cin_no"
                      name="cin_no"
                      maxLength={21}
                      placeholder="Enter CIN number"
                      defaultValue={cin_no}
                      {...register("cin_no")}
                    />
                    <span className="text-danger err-msg">
                      {errors.cin_no?.message}
                    </span>
                  </div>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-12 col-sm-4">
                  <div
                    className="form-group"
                    onClick={() => {
                      setName_gal({
                        name: "Upload GST Certificate",
                        type: "gst_certificate",
                      });
                      setVisible(true);
                    }}
                  >
                    <label style={{ color: "#4E7DAE", fontSize: "15px" }}>
                      {constLabel?.lbl_uploadGSTCertificate
                        ? constLabel.lbl_uploadGSTCertificate
                        : "Upload GST Certificate"}
                    </label>
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div
                    className="form-group"
                    onClick={() => {
                      setName_gal({
                        name: "Upload Udyog Aadhar Certificate",
                        type: "uydog_certificate",
                      });
                      setVisible(true);
                    }}
                  >
                    <label style={{ color: "#4E7DAE", fontSize: "15px" }}>
                      {constLabel?.lbl_uploadUdyogAadharCertificate
                        ? constLabel.lbl_uploadUdyogAadharCertificate
                        : "Upload Udyog Aadhar Certificate"}
                    </label>
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div
                    className="form-group"
                    onClick={() => {
                      setName_gal({
                        name: "Upload CIN Certificate",
                        type: "cin_certificate",
                      });
                      setVisible(true);
                    }}
                  >
                    <label style={{ color: "#4E7DAE", fontSize: "15px" }}>
                      {constLabel?.lbl_uploadCINCertificate
                        ? constLabel.lbl_uploadCINCertificate
                        : "Upload CIN Certificate"}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <hr className="m-3" />
            <div className="d-flex justify-content-between align-items-center px-2 mt-4">
              <p className="roboto-bold m-0" style={{ fontSize: "24px" }}>
                {constLabel?.lbl_configuration
                  ? constLabel.lbl_configuration
                  : "Configuration"}
              </p>
            {table_label?.length > 0 ? (
              <div>
                <button 
                  className="btn button-primary me-0 me-md-2 mt-2 mt-md-0"
                  onClick={() => setVisible2(true)}
                
                >
                  <FontAwesomeIcon
                    style={{ color: "#fff" }}
                    icon={faPlus}
                    size="sm"
                  />{" "}
                  {constLabel?.lbl_add_configuration
                    ? constLabel.lbl_add_configuration
                    : "Add Configuration"}
                </button>
                <button 
                  className="btn btn-outline-secondary me-0 me-md-2 mt-2 mt-md-0"
                  onClick={() => setVisible3(true)}
                
                >
                  {constLabel?.lbl_add_costing
                    ? constLabel.lbl_add_costing
                    : "Add Costing"}
                </button>
              </div>
            ) : (
              <div>
                <button 
                  className="btn button-primary me-0 me-md-2 mt-2 mt-md-0"
                  onClick={() => pullConfiguration()}
                
                >
                  <FontAwesomeIcon
                    style={{ color: "#fff" }}
                    icon={faAngleDoubleDown}
                    size="sm"
                  />{" "}
                  {constLabel?.lbl_pull_configuration
                    ? constLabel.lbl_pull_configuration
                    : "Pull Configuration"}
                </button>
              </div>
            )}
            </div>
            {table_label?.length > 0 ? (
              <div className="table-responsive mt-3">
                <table className="table table-bordered">
                  <thead className="table-grey roboto-bold">
                    <tr>
                      <th>{"Name"}</th>
                      <th>{"Value"}</th>
                      <th>{"Action"}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {table_label.map((item, ind) => {
                      return (
                        <tr>
                          <td>{item.value}</td>
                          {item.flag !== null && (
                            <td>
                              {editedKey2 === ind ? (
                                <div className="form-check form-switch ">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={editedValue2.flag}
                                    onChange={(e) => handleInputChange2(e)}
                                  />
                                </div>
                              ) : item.flag ? (
                                "True"
                              ) : (
                                "False"
                              )}
                            </td>
                          )}
                          {item.flag == null && (
                            <td>
                              {editedKey2 === ind ? (
                                <input
                                  value={editedValue2.text_value}
                                  onChange={(e) => handleInputChange2(e)}
                                />
                              ) : (
                                item.text_value
                              )}
                            </td>
                          )}
                          <td>
                            {editedKey2 === ind ? (
                              <button
                                className="btn btn-yellow px-3 btn-sm"
                                onClick={() => handleUpdate2(ind)}
                              >
                                Update
                              </button>
                            ) : (
                              <React.Fragment>
                                <Edit
                                  className="menu_icon_grey cursor_pointer me-2"
                                  size={20}
                                  onClick={() => handleEdit2(ind)}
                                />

                                <Trash2
                                  className="menu_icon_red cursor_pointer"
                                  size={20}
                                  onClick={() => handleDelete2(ind)}
                                />
                              </React.Fragment>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
             ) : (
              <div className="alert alert-danger mb-0 text-center my-4" role="alert">
                {"No Configuration Found!"}
              </div>
            )}
          </div>
          <div className="card-footer d-flex justify-content-end py-3">
            <button
              className="btn btn-grey px-4 ms-0 ms-md-2"
              onClick={handleSubmit(onSubmit)}
              disabled={lockBtn}
            >
              {constLabel?.lbl_save ? constLabel.lbl_save : "Save"}{" "}
              {lockBtn ? <Spinner animation="border" size="sm" /> : null}
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default UpdateOrgnization;
