import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt, faClose } from "@fortawesome/free-solid-svg-icons";
import { Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  convertDateYYMMDD,
  formatDate5,
  getLabels,
} from "../../../common/Functions/CommonFunctions";
import {
  create_payment_receipt,
  get_payment_receipt,
  getInternalCompany,
} from "../../../api";
import DatePicker from "react-datepicker";
import { Typeahead } from "react-bootstrap-typeahead";
import { toast } from "react-toastify";

function PaymentReceipt(props) {
  const localData = JSON.parse(localStorage.getItem("localData"));

  const [constLabel, setConstLabel] = useState({});
  const [loc_list, setLoc_List] = useState({ data: [], loading: false });
  const [OUTSTANDING_AMOUNT, setOUTSTANDING_AMOUNT] = useState(0);
  const [payment_receipts, setPayment_receipts] = useState({
    data: [],
    loading: false,
  });
  const [discountType, setDiscountType] = useState("amount");
  const schema = yup
    .object()
    .shape({
      invoice_date: yup
        .date()
        .required("Please select invoice date !")
        .typeError("Please select invoice date !"),
      location: yup
        .array()
        .min(1, "Please select location !")
        .required("Please select location !"),
      mode_of_payment: yup
        .array()
        .min(1, "Please select mode of payment !")
        .required("Please select mode of payment !"),
      discount_amount: yup
        .number()
        .typeError("Enter a valid discount amount!")
        .min(0, "Discount cannot be negative!")
        .max(OUTSTANDING_AMOUNT, "Discount cannot exceed outstanding amount!")
        .when("discountType", {
          is: "amount",
          then: (schema) => schema.required("Discount amount is required!"),
          otherwise: (schema) => schema.notRequired(),
        }),
      discount_percentage: yup
        .number()
        .typeError("Enter a valid discount percentage!")
        .min(0, "Discount percentage cannot be negative!")
        .max(100, "Discount percentage cannot exceed 100%!")
        .when("discountType", {
          is: "percentage",
          then: (schema) => schema.required("Discount percentage is required!"),
          otherwise: (schema) => schema.notRequired(),
        }),
      amount: yup
        .number()
        .typeError("Enter a valid amount!")
        .required("Amount is required!")
        .positive("Amount must be greater than zero!")
        .test(
          "maxAmount",
          "Amount cannot exceed available outstanding amount!",
          function (value) {
            const discount = this.parent.discount_amount || 0;
            const availableAmount = OUTSTANDING_AMOUNT - discount;
            return value <= availableAmount;
          }
        ),
    })
    .required();
  const {
    register,
    handleSubmit,
    formState,
    control,
    reset,
    watch,
    setValue,
    setError,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      amount: "",
      discount_amount: "",
      discount_percentage: "",
      pending_amount: "",
    },
  });

  let { errors } = formState;

  useEffect(() => {
    setConstLabel(getLabels("Sales", "Proforma Invoice"));
    if (props.visible) {
      reset();
      fetchpayments();
      fetchInternal_company();
    }
  }, [props.visible]);

  const discountAmount = watch("discount_amount") || 0;
  const discountPercentage = watch("discount_percentage") || 0;
  const amount = watch("amount") || 0;

  useEffect(() => {
    if (discountType === "amount" && discountAmount > 0) {
      const calculatedPercentage = (discountAmount / OUTSTANDING_AMOUNT) * 100;
      setValue("discount_percentage", calculatedPercentage.toFixed(2));
    }
  }, [discountAmount, discountType, setValue]);

  useEffect(() => {
    if (discountType === "percentage" && discountPercentage > 0) {
      const calculatedAmount = (discountPercentage / 100) * OUTSTANDING_AMOUNT;
      setValue("discount_amount", calculatedAmount.toFixed(2));
    }
  }, [discountPercentage, discountType, setValue]);

  useEffect(() => {
    const discountedAmount = Math.max(OUTSTANDING_AMOUNT - discountAmount, 0);
    const calculatedPendingAmount = Math.max(discountedAmount - amount, 0);
    setValue("pending_amount", calculatedPendingAmount);
  }, [amount, discountAmount, setValue]);

  function fetchpayments() {
    setPayment_receipts({ loading: true, data: [] });

    const payload = {
      performa_invoice_id: props.data.uuid,
    };
    get_payment_receipt(payload).then(
      (res) => {
        console.log("res.data.data", res.data.data);

        setPayment_receipts({
          loading: false,
          data: res.data.data,
        });

        let value = res.data.data[0].pending_amount;
        setOUTSTANDING_AMOUNT(value);
      },
      (err) => {
        console.log("err", err);
        setPayment_receipts({ loading: false, data: [] });
        
      }
    );
  }

  function fetchInternal_company() {
    setLoc_List({ data: [], loading: true });

    getInternalCompany({ with_location: 1 })
      .then((res) => {
        // console.log("res", res);
        const ress = res.data.data.filter((item) => item.default_org == true);
        // console.log("ress", ress);
        if (ress.length > 0) {
          const loc = ress?.[0]?.location_list;
          setLoc_List({ data: loc, loading: false });
        } else {
          toast.success("Default organization not found !", {
            autoClose: 3000,
          });
        }
      })
      .catch((err) => {
        setLoc_List({ data: [], loading: false });
      });
  }

  const onsubmit = (data) => {
    console.log("data", data);
    console.log("props.data", props.data);

    const payload = {
      performa_invoice_id: props.data.uuid,
      location_id: data.location[0].uuid,
      party_id: props.data.party_id,
      amount: data.amount,
      discount_amount: data.discount_amount,
      discount_percentage: data.discount_percentage,
      payment_date: convertDateYYMMDD(data.invoice_date, "-"),
      pending_amount: data.pending_amount,
      mode_of_payment: data.mode_of_payment[0].name,
    };
    console.log("payload", payload);

    create_payment_receipt(payload)
      .then((res) => {
        console.log(res.data);
        props.setVisible(false)
         reset()
        toast.success("Payment receipt created successfully !", {
          autoClose: 2000,
        });
        
      })
      .catch((err) => {
        console.log("err", err);

        toast.success("Something went wrong !", {
          autoClose: 2000,
        });
      });
  };

  return (
    <React.Fragment>
      <Modal
        show={props.visible}
        onHide={() => props.setVisible(false)}
        size="lg"
        backdrop="static"
      >
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>
            {constLabel?.lbl_payment_receipt
              ? constLabel.lbl_payment_receipt
              : "Payment Receipt"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="roboto-bold mb-0" style={{ fontSize: "20px" }}>
            {constLabel?.lbl_payment_receipts
              ? constLabel.lbl_payment_receipts
              : "Previous Payment Receipts"}
          </p>
          <div className="row col-12 mt-3">
            <table className="table table-responsive table-bordered">
              <thead className="thead-light text-center">
                <tr>
                  <th>Sr. No.</th>
                  <th>SO ID</th>
                  <th>Proforma Invoice Id</th>
                  <th>Payment Date</th>
                  <th>Mode of Payment</th>
                  <th>Location</th>
                  <th>Amount ₹</th>
                  <th>Discount(%)</th>
                  <th>Pending Amount</th>
                </tr>
              </thead>
              <tbody className="text-center">
                {payment_receipts.data.map((ele, ind) => {
                  return (
                    <tr key={ele.uuid}>
                      <td>{ind + 1}</td>
                      <td>
                        {ele?.sales_order_info?.so_id
                          ? ele.sales_order_info.so_id
                          : "NA"}
                      </td>
                      <td>
                        {ele?.invoice_performa_info?.invoice_id
                          ? ele.invoice_performa_info.invoice_id
                          : "NA"}
                      </td>
                      <td>
                        {ele?.payment_date
                          ? formatDate5(ele.payment_date)
                          : "NA"}
                      </td>
                      <td>
                        {ele?.mode_of_payment ? ele.mode_of_payment : "NA"}
                      </td>
                      <td>
                        {ele?.payment_receipt_location?.locationname
                          ? ele.payment_receipt_location?.locationname
                          : "NA"}
                      </td>
                      <td>{ele?.amount ? ele.amount : "0"}</td>
                      <td>
                        {ele?.discount_percentage
                          ? ele.discount_percentage
                          : "0"}
                      </td>
                      <td>{ele?.pending_amount ? ele.pending_amount : "0"}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="row mt-2 col-12">
            <div className="col-md-6 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_invoice_date
                    ? constLabel.lbl_invoice_date
                    : "Invoice Date"}
                  <span className="text-danger"> *</span>
                </label>

                <Controller
                  name="invoice_date"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      className={
                        errors.invoice_date
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      {...field}
                      showYearDropdown
                      scrollableMonthYearDropdown
                      dateFormat="dd/MM/yyyy"
                      selected={field.value}
                      maxDate={new Date()}
                      placeholderText="Select a invoice date"
                      autoComplete="off"
                    />
                  )}
                />
                {errors.invoice_date && (
                  <span className="text-danger err-msg">
                    {errors.invoice_date.message}
                  </span>
                )}
              </div>
            </div>
            <div className=" col-md-6 mt-3">
              <div className="form-group typeahead-container">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_location
                    ? constLabel.lbl_location
                    : "Location"}
                  {""}
                  <span className="text-danger"> *</span>
                </label>
                <Controller
                  name={`location`}
                  control={control}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="locationname"
                      multiple={false}
                      options={loc_list.data}
                      isLoading={loc_list.loading}
                      placeholder="Select location"
                      onChange={onChange}
                      onBlur={onBlur}
                      selected={value}
                      isInvalid={errors.location ? true : false}
                    />
                  )}
                />

                {errors.location && (
                  <span className="text-danger err-msg">
                    {errors.location.message}
                  </span>
                )}
              </div>
            </div>
            <div className=" col-md-6 mt-3">
              <div className="form-group typeahead-container">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_mode_of_payment
                    ? constLabel.lbl_mode_of_payment
                    : "Mode of Payment"}
                  {""}
                  <span className="text-danger"> *</span>
                </label>
                <Controller
                  name={`mode_of_payment`}
                  control={control}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="name"
                      multiple={false}
                      options={[
                        { name: "Cash" },
                        { name: "Online" },
                        { name: "Cheque" },
                      ]}
                      placeholder="Select mode of payment"
                      onChange={onChange}
                      onBlur={onBlur}
                      selected={value}
                      isInvalid={errors.mode_of_payment ? true : false}
                    />
                  )}
                />

                {errors.mode_of_payment && (
                  <span className="text-danger err-msg">
                    {errors.mode_of_payment.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-6 mt-3">
              <div className="form-group typeahead-container">
                <label className="lbl-style roboto-bold">
                  Discounted Outstanding Amount
                </label>
                <input
                  type="number"
                  className="form-control"
                  value={OUTSTANDING_AMOUNT - discountAmount}
                  disabled={true}
                />
              </div>
            </div>
            <div className="col-md-6 mt-3">
              <label className="lbl-style roboto-bold">Discount By:</label>
              <div>
                <input
                  type="radio"
                  id="discountAmount"
                  name="discountType"
                  value="amount"
                  checked={discountType === "amount"}
                  onChange={() => setDiscountType("amount")}
                />
                <label htmlFor="discountAmount" className="ml-2">
                  Amount
                </label>
              </div>
              <div>
                <input
                  type="radio"
                  id="discountPercentage"
                  name="discountType"
                  value="percentage"
                  checked={discountType === "percentage"}
                  onChange={() => setDiscountType("percentage")}
                />
                <label htmlFor="discountPercentage" className="ml-2">
                  Percentage
                </label>
              </div>
            </div>

            {discountType === "amount" && (
              <div className="col-md-6 mt-3">
                <label className="lbl-style roboto-bold">Discount Amount</label>
                <input
                  type="number"
                  className="form-control"
                  {...register("discount_amount")}
                />
                {errors.discount_amount && (
                  <span className="text-danger">
                    {errors.discount_amount.message}
                  </span>
                )}
              </div>
            )}

            {discountType === "percentage" && (
              <div className="col-md-6 mt-3">
                <label className="lbl-style roboto-bold">
                  Discount Percentage
                </label>
                <input
                  type="number"
                  className="form-control"
                  {...register("discount_percentage")}
                />
                {errors.discount_percentage && (
                  <span className="text-danger">
                    {errors.discount_percentage.message}
                  </span>
                )}
              </div>
            )}

            <div className="col-md-6 mt-3">
              <div className="form-group typeahead-container">
                <label className="lbl-style roboto-bold">Amount</label>
                <span className="text-danger"> *</span>
                <input
                  type="number"
                  name="amount"
                  className="form-control"
                  placeholder="Enter amount"
                  {...register("amount")}
                />
                {errors.amount && (
                  <span className="text-danger err-msg">
                    {errors.amount.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-6 mt-3">
              <div className="form-group typeahead-container">
                <label className="lbl-style roboto-bold">Pending Amount</label>
                <input
                  type="number"
                  name="pending_amount"
                  className="form-control"
                  placeholder="Pending amount"
                  disabled={true}
                  {...register("pending_amount")}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-grey px-4"
            onClick={handleSubmit(onsubmit)}
          >
            {constLabel?.lbl_save ? constLabel.lbl_save : "Save"}
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default PaymentReceipt;
