import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faSearch,
  faFilter,
  faBan,
  faPlus,
  faCog,
  faBars,
} from "@fortawesome/free-solid-svg-icons";
// import { getNCList, getOperatorList } from "../../../src/api/index";
import { Pagination } from "react-bootstrap";
import { Edit2, Eye, Trash2, Image, Edit } from "react-feather";
import { getBOM, deleteBOM, get_so_id } from "../../../api";
import SweetAlert from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import {
  calMaxPage,
  getLabels,
  setAccessPrivilage,
} from "../../../common/Functions/CommonFunctions";
import { BOM_Col_Name } from "../../../common/Labels/labelConstant";
import { useSelector } from "react-redux";
import List from "../../../assets/images/icons/project_menu/checklist.png";
import AccessDenied from "../../Common/AccessDenied";
import Slide from "../../../assets/images/icons/project_menu/collapse.png";
import { Typeahead } from "react-bootstrap-typeahead";
import * as name_const from "../../../common/Constants/constants"

function Bom() {
  let navigate = useNavigate();

  const [showLabel, setShowLabel] = useState(BOM_Col_Name);
  const [page, setPage] = useState(1);
  const [srNo, setSrNo] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [bomList, setBomList] = useState({
    data: [],
    loading: true,
    total: "",
  });

  const [filterConfig, setFilterConfig] = useState({
    searchVal: "",
    bom_name: "",
    sales_order: []
  });
  const [sea_val, setSea_val] = useState(false);
  const [constLabel, setConstLabel] = useState({});
  const [soId_List, setSoId_List] = useState({data: [], loading: true});
  const loginData = useSelector(
    (state) => state.auth?.loginData?.data?.response
  );
  const [privilage, setPrivilage] = useState({
    read: true,
    write: true,
    view: true,
    delete: true,
    report: true,
   no_access: false
  });

  const localData = JSON.parse(localStorage.getItem("localData"));

  useEffect(() => {
    setConstLabel(getLabels("Master", "Bill of Material"));

    setPrivilage(setAccessPrivilage(loginData, "Bill of Material"));
  }, []);

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      setPage(1);
      fetchBoms(page, entriesPerPage);
    }, 1000);

    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [filterConfig]);

  function fetchSoIdList() {
      setSoId_List({ ...soId_List, data: [], loading: true });
      get_so_id().then(
        (res) => {
          setSoId_List({ ...soId_List, data: res.data.data, loading: false });
        },
        (err) => {
          setSoId_List({ ...soId_List, data: [], loading: false });
        }
      );
    }

    function fetchBoms(pg, epp) {
      const payload = {
        entriesPerPage: epp,
        pageNo: pg,
      };
    
      setBomList({ data: [], loading: true });
    
      const { searchVal, bom_name, sales_order } = filterConfig || {};
    
      if (searchVal?.trim()) payload.searchVal = searchVal.trim();
      if (bom_name?.trim()) payload.bom_name = bom_name.trim();
      if (sales_order?.[0]?.so_id) payload.sales_order = sales_order[0].so_id;
    
      // Reset page and serial number if filters change and page is not 1
      if (pg !== 1 && (searchVal || bom_name || sales_order?.length > 0)) {
        payload.pageNo = 1;
        payload.entriesPerPage = entriesPerPage;
        setPage(1);
        setSrNo(0);
      }
    
      getBOM(payload)
        .then((res) => {
          const { data, total } = res.data;
    
          setBomList({ data, loading: false, total });
          setMaxPage(calMaxPage(total));
    
          setSea_val(!!(searchVal || bom_name || sales_order?.length > 0));
    
          if (pg === 1) {
            setSrNo(0);
            setPage(1);
          }
        })
        .catch(() => {
          setBomList({ data: [], loading: false });
        });
    }
    

  function delBom(val) {
    SweetAlert.fire({
      title: "Are you sure to delete \n" + val.productname + " ?",
      // text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteBOM({ uuid: val.uuid }).then(
          (res) => {
            toast.success("BOM deleted successfully", {
              autoClose: 2000,
            });
            if (bomList.data.length == 1 && page > 1) {
              fetchBoms(page - 1, entriesPerPage);
              setSrNo(parseInt(srNo - entriesPerPage));
              setPage(page - 1);
            } else {
              fetchBoms(page, entriesPerPage);
            }
          },
          (err) => {
            toast.error("Something went wrong !", {
              autoClose: 2000,
            });
          }
        );
      }
    });
  }

  function handleClick(ind) {
    setShowLabel((showLabel) => {
      const updatedCardShow = showLabel.map((val, i) => {
        if (ind === i) {
          // Toggle the flag for the clicked checkbox
          return { ...val, flag: !val.flag };
        }
        return val;
      });

      return updatedCardShow;
    });
  }

  function openNav() {
    document.getElementById("mySidebar").style.width = "320px";
    document.getElementById("root_div_main").style.marginLeft = "320px";
    setTimeout(() => {
      document.getElementById("mySidebar").style.overflowX = "visible";
    }, [200]);
  }

  function closeNav() {
    document.getElementById("mySidebar").style.width = "0";
    document.getElementById("root_div_main").style.marginLeft = "0";

    document.getElementById("mySidebar").style.overflowX = "hidden";
  }

  function clearFilter() {
    setFilterConfig({
      ...filterConfig,
      searchVal: "",
      bom_name: "",
      sales_order: []
    });
    setSrNo(0);
    setPage(1);
  }

  function toggleDiv(ind) {
    const selRow = document.getElementById("collapse_row" + ind);

    selRow.classList.toggle("expanded");
  }

  function toggleNav() {
    const sidebar = document.getElementById("mySidebar");
    const mainDiv = document.getElementById("root_div_main");

    if (sidebar.style.width === "320px") {
      // Sidebar is open, so close it
      sidebar.style.width = "0";
      mainDiv.style.marginLeft = "0";
      sidebar.style.overflowX = "hidden";
    } else {
      // Sidebar is closed, so open it
      sidebar.style.width = "320px";
      mainDiv.style.marginLeft = "320px";
      setTimeout(() => {
        sidebar.style.overflowX = "visible";
      }, 200);
      fetchSoIdList();
    }
  }

  return (
    <React.Fragment>
      <ToastContainer />


      {!privilage.read ?
        <AccessDenied />
        :
        <>
          <div id="mySidebar" className="customsidebar">
            <a className="closebtn" onClick={() => toggleNav()}>
              ×
            </a>

            <div className=" content">
              <div>
                <label
                  className="filterLabel mb-1 roboto-bold"
                  style={{ fontSize: "14px" }}
                >
                  {constLabel?.lbl_product_name
                    ? constLabel?.lbl_product_name
                    : "Product Name"}{" "}
                </label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Search by product name "
                  value={filterConfig.searchVal}
                  onChange={(val) => {
                    const inputValue = val.target.value;
                    if (!inputValue.trim()) {
                      // Input contains only whitespace, don't trigger the search.
                      if (filterConfig.searchVal.length == 1) {
                        setFilterConfig({ ...filterConfig, searchVal: "" });
                      }
                    } else {
                      setFilterConfig({
                        ...filterConfig,
                        searchVal: inputValue,
                      });
                    }
                  }}
                />
              </div>

              <div className="mt-2">
              <label
                className="filterLabel mb-1 roboto-bold"
                style={{ fontSize: "14px" }}
              >
                {constLabel?.lbl_BOM_name ? constLabel?.lbl_BOM_name : "BOM Name"}
              </label>
              <input
                className="form-control"
                type="text"
                placeholder="Search by bom name"
                value={filterConfig.bom_name}
                onChange={(val) => {
                  const inputValue = val.target.value;
                  if (!inputValue.trim()) {
                    // Input contains only whitespace, don't trigger the search.
                    if (filterConfig.bom_name.length == 1) {
                      setFilterConfig({ ...filterConfig, bom_name: "" });
                    }
                  } else {
                    setFilterConfig({
                      ...filterConfig,
                      bom_name: inputValue,
                    });
                  }
                }}
              />
              </div>
              
            {(localData?.industry_type).toUpperCase() === (name_const.FASHION).toUpperCase() ? 
              <div className="mt-2">
              <label
                className="filterLabel my-2 roboto-bold"
                style={{ fontSize: "14px" }}
              >
                {constLabel?.lbl_sales_order
                  ? constLabel.lbl_sales_order
                  : "Sales Order"}
              </label>

              <Typeahead
                id="basic-typeahead-single"
                labelKey="so_id"
                name="so_id"
                multiple={false}
                options={soId_List.data}
                isLoading={soId_List.loading}
                selected={filterConfig.sales_order}
                onChange={(val) => {
                  if (val.length > 0) {
                    setFilterConfig({ ...filterConfig, sales_order: val });
                  } else {
                    setFilterConfig({ ...filterConfig, sales_order: [] });
                  }
                }}
                placeholder="Search by so id"
              />
              </div>
            : null }

              <div className="my-3 d-flex justify-content-end">
                <button
                  onClick={() => clearFilter()}
                  className="btn btn-sm btn-yellow "
                >
                  <FontAwesomeIcon
                    style={{ color: "#344454" }}
                    icon={faBan}
                    size="sm"
                  />{" "}
                  {constLabel?.lbl_clear_filter
                    ? constLabel?.lbl_clear_filter
                    : "Clear Filter"}
                </button>
              </div>
            </div>
          </div>
          <div className="min-vh-100" id="root_div_main">
            <div className="content-wrapper">
              <div className="card border-0">
                <div className="card-body ">
                  <div className="row align-items-center">
                    <div className="d-md-flex justify-content-start col-12 col-md-6">
        
                      <Tooltip id={"open-tooltip"} place="top" />
                        <img
                          src={Slide}
                          height="32"
                          width="32"
                          className="cursor_pointer my-1 me-2"
                          onClick={() => toggleNav()}
                          data-tooltip-id={"open-tooltip"}
                          data-tooltip-content={"Filters"}
                        />
                      <h1 className="bold">
                        {constLabel?.lbl_bill_of_material
                          ? constLabel.lbl_bill_of_material
                          : "Bill of Material"}
                      </h1>
                    </div>

                    <div className="d-md-flex justify-content-end align-items-center col-12 col-md-6">
                      {privilage.write && (
                        <button
                          onClick={() =>
                            navigate("/pcms/create/bill-of-material", {
                              state: constLabel,
                            })
                          }
                          className="btn button-primary me-0 me-md-2 mt-2 mt-md-0"
                        >
                          <FontAwesomeIcon
                            style={{ color: "#fff" }}
                            icon={faPlus}
                            size="sm"
                          />{" "}
                          {constLabel?.lbl_create_bom
                            ? constLabel.lbl_create_bom
                            : "Create BOM"}
                        </button>
                      )}
                      <Tooltip id={"show-tooltip"} place="top" />
                      <div
                        className="dropdown"
                        data-tooltip-id={"show-tooltip"}
                        data-tooltip-content={"Show / Hide Columns"}
                      >
                        <div
                          className="d-flex justify-content-center align-items-center"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {/* <List size={24} className="menu_icon_grey cursor_pointer"/> */}
                          <img src={List} height='32' width='32' className="cursor_pointer" />
                        </div>

                        <ul className="dropdown-menu px-2">
                          {showLabel !== undefined && showLabel.length > 0
                            ? showLabel.map((val, ind, arr) => (
                              <li key={ind}>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    style={{ width: "20px", height: "20px" }}
                                    onChange={() => handleClick(ind)}
                                    value=""
                                    checked={val.flag}
                                  />
                                  <label
                                    className=""
                                    style={{ fontSize: "13px" }}
                                    htmlFor="flexCheckDefault"
                                  >
                                    {val.label}
                                  </label>
                                </div>
                              </li>
                            ))
                            : null}
                        </ul>
                      </div>
                    </div>
                  </div>

                  {bomList.loading ? (
                    <div className="text-center">
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ) : privilage.read && bomList.data?.length > 0 ? (
                    <>
                      <div className="table-responsive pc">
                        <table className="table mt-2 table-bordered">
                          <thead className="table-grey  roboto-bold">
                            <tr className="">
                              <th scope="col">{"SrNo"}</th>
                              {showLabel.map((v, i) =>
                                v.label === "BOM Name" && v.flag ? (
                                  <th key={i} scope="col">
                                    {constLabel?.lbl_bom_name
                                      ? constLabel.lbl_bom_name
                                      : "BOM Name"}
                                  </th>
                                ) : v.label === "BOM Id" && v.flag ? (
                                  <th key={i} scope="col">
                                    {constLabel?.lbl_bom_id
                                      ? constLabel.lbl_bom_id
                                      : "BOM Id"}
                                  </th>
                                ) : v.label === "Product Name" && v.flag ? (
                                  <th key={i} scope="col">
                                    {constLabel?.lbl_product_name
                                      ? constLabel.lbl_product_name
                                      : "Product Name"}
                                  </th>
                                ) : v.label === "Product Type" && v.flag ? (
                                  <th key={i} scope="col">
                                    {constLabel?.lbl_product_type
                                      ? constLabel.lbl_product_type
                                      : "Product Type"}
                                  </th>
                                ) : v.label === "Product Family" && v.flag ? (
                                  <th key={i} scope="col">
                                    {constLabel?.lbl_product_family
                                      ? constLabel.lbl_product_family
                                      : "Product Family"}
                                  </th>
                                ) : v.label === "BOM Type" && v.flag ? (
                                  <th key={i} scope="col">
                                    {constLabel?.lbl_bom_type
                                      ? constLabel.lbl_bom_type
                                      : "BOM Type"}
                                  </th>
                                ) : v.label === "Quantity" && v.flag ? (
                                  <th key={i} scope="col">
                                    {constLabel?.lbl_quantity
                                      ? constLabel.lbl_quantity
                                      : "Quantity"}
                                  </th>
                                ) : v.label === "Remark" && v.flag ? (
                                  <th key={i} scope="col">
                                    {constLabel?.lbl_remark
                                      ? constLabel.lbl_remark
                                      : "Remark"}
                                  </th>
                                ) : null
                              )}
                              <th scope="col" width="15%">
                                {"Action"}
                              </th>
                            </tr>
                          </thead>
                          <tbody className=" roboto">
                            {bomList.data.map((val, i) => (
                              <React.Fragment key={i}>
                                <tr>
                                  <td style={{ verticalAlign: "middle" }}>
                                    {parseInt(i) + 1 + parseInt(srNo)}
                                  </td>
                                  {showLabel.map((item, idx) =>
                                    item.label === "BOM Name" && item.flag ? (
                                      <td key={idx}>
                                        {val.bom_name !== null ? val.bom_name : "NA"}
                                      </td>
                                    ) : item.label === "BOM Id" && item.flag ? (
                                      <td key={idx}>
                                        {val.bomId !== null ? val.bomId : "NA"}
                                      </td>
                                    ) : item.label === "Product Name" &&
                                      item.flag ? (
                                      <td key={idx}>
                                        {val.productname !== null
                                          ? val.productname
                                          : "NA"}
                                      </td>
                                    ) : item.label === "Product Type" &&
                                      item.flag ? (
                                      <td key={idx}>
                                        {val.productType
                                          ? val.productType
                                          : "NA"}
                                      </td>
                                    ) : item.label === "Product Family" &&
                                      item.flag ? (
                                      <td key={idx}>
                                        {val.product_family_id?.familyname
                                          ? val.product_family_id?.familyname
                                          : "NA"}
                                      </td>
                                    ) : item.label === "BOM Type" &&
                                      item.flag ? (
                                      <td key={idx}>
                                        {val.bom_type ? val.bom_type : "NA"}
                                      </td>
                                    ) : item.label === "Quantity" &&
                                      item.flag ? (
                                      <td key={idx}>
                                        {val.qty ? val.qty : "NA"}
                                      </td>
                                    ) : item.label === "Remark" && item.flag ? (
                                      <td key={idx}>
                                        {val.remarks ? val.remarks : "NA"}
                                      </td>
                                    ) : null
                                  )}
                                  <td className="">
                                    {privilage.write && (
                                      <>
                                        <Tooltip id={"edit-tooltip" + i} place="top" />
                                        <Edit
                                          data-tooltip-id={"edit-tooltip" + i}
                                          data-tooltip-content={
                                            constLabel?.lbl_edit_bom
                                              ? constLabel.lbl_edit_bom
                                              : "Edit BOM"
                                          }
                                          className="menu_icon_grey cursor_pointer me-2"
                                          size={20}
                                          onClick={() => {
                                            navigate(
                                              "/pcms/update/bill-of-material",
                                              {
                                                state: {
                                                  locVal: val,
                                                  constLabel: constLabel,
                                                },
                                              }
                                            );
                                          }}
                                        />
                                      </>
                                    )}

                                    {privilage.read && (
                                      <>
                                        <Tooltip id={"view-tooltip" + i} place="top" />
                                        <Eye
                                          data-tooltip-id={"view-tooltip" + i}
                                          data-tooltip-content={
                                            constLabel?.lbl_view_item
                                              ? constLabel.lbl_view_item
                                              : "View Item"
                                          }
                                          className="menu_icon_grey cursor_pointer me-2"
                                          size={20}
                                          onClick={() => {
                                            toggleDiv(i, val);
                                          }}
                                        />
                                      </>
                                    )}

                                    {privilage.delete && (
                                      <>
                                        <Tooltip id={"delete-tooltip" + i} place="top" />
                                        <Trash2
                                          data-tooltip-id={"delete-tooltip" + i}
                                          data-tooltip-content={
                                            constLabel?.lbl_delete_bom
                                              ? constLabel.lbl_delete_bom
                                              : "Delete BOM"
                                          }
                                          className="menu_icon_red cursor_pointer me-2"
                                          size={20}
                                          onClick={() => delBom(val)}
                                        />
                                      </>
                                    )}
                                  </td>
                                </tr>
                                <tr
                                  className={"collapsible-row"}
                                  id={"collapse_row" + i}
                                >
                                  <td colSpan="12">
                                    <div className="collapse-content">
                                      <div className="row mb-3">
                                        <div className="col-12 col-md-1">
                                          <label className="roboto-bold">
                                            {"Sr.No"}
                                          </label>
                                        </div>
                                        <div className="col-12 col-md-5">
                                          <label className="roboto-bold">
                                            {constLabel?.lbl_product_name
                                              ? constLabel.lbl_product_name
                                              : "Product Name"}
                                          </label>
                                        </div>
                                        <div className="col-12 col-md-3">
                                          <label className="roboto-bold">
                                            {constLabel?.lbl_quantity
                                              ? constLabel.lbl_quantity
                                              : "Quantity"}
                                          </label>
                                        </div>
                                        <div className="col-12 col-md-3">
                                          <label className="roboto-bold">
                                            {constLabel?.lbl_unit
                                              ? constLabel.lbl_unit
                                              : "Unit"}
                                          </label>
                                        </div>
                                      </div>
                                      {val.bom_line_item &&
                                        val.bom_line_item.length > 0 &&
                                        val.bom_line_item.map((vv, index) => (
                                          <div className="row " key={index}>
                                            <div className="col-12 col-md-1">
                                              <label className="roboto">
                                                {index + 1}
                                              </label>
                                            </div>

                                            <div className="col-12 col-md-5">
                                              <label className="roboto">
                                                {vv.productname
                                                  ? vv.productname
                                                  : "NA"}
                                              </label>
                                            </div>
                                            <div className="col-12 col-md-3">
                                              <label className="roboto">
                                                {vv.qty ? vv.qty : "NA"}
                                              </label>
                                            </div>

                                            <div className="col-12 col-md-3">
                                              <label className="roboto">
                                                {vv.unitname
                                                  ? vv.unitname
                                                  : "NA"}
                                              </label>
                                            </div>
                                          </div>
                                        ))}
                                    </div>
                                  </td>
                                </tr>
                              </React.Fragment>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <div className="d-flex justify-content-between align-items-center mt-2">
                        <p className="mb-0">
                          Show
                          <select
                            className="mx-1"
                            defaultValue={entriesPerPage}
                            onChange={(e) => {
                              setEntriesPerPage(e.target.value);
                              fetchBoms(1, e.target.value);
                            }}
                          >
                            <option value={"10"}>10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                          Entries
                        </p>
                        <p className="mb-0">{`Showing ${parseInt(srNo) + 1
                          } to ${Math.min(
                            parseInt(entriesPerPage) + parseInt(srNo),
                            bomList.total
                          )} of ${bomList.total} entries`}</p>
                        <Pagination className="my-2">
                          <Pagination.Prev
                            disabled={page === 1 ? true : false}
                            onClick={() => {
                              fetchBoms(
                                page - 1,
                                entriesPerPage,
                                parseInt(srNo) - parseInt(entriesPerPage)
                              );
                              setSrNo((prevC) =>
                                page - 1 == 1
                                  ? 0
                                  : prevC - parseInt(entriesPerPage)
                              );
                              setPage(page - 1);
                            }}
                          >
                            {"Prev"}
                          </Pagination.Prev>

                          <Pagination.Item active>{page}</Pagination.Item>

                          <Pagination.Next
                            disabled={
                              page === maxPage ||
                                maxPage === 0 ||
                                entriesPerPage > bomList.data.length
                                ? true
                                : false
                            }
                            onClick={() => {
                              console.log("page", page)
                              console.log("srNo", srNo)
                              console.log("entriesPerPage", entriesPerPage)
                              fetchBoms(
                                page + 1,
                                entriesPerPage,
                                parseInt(srNo) + parseInt(entriesPerPage)
                              );
                              setSrNo(
                                (prevC) => prevC + parseInt(entriesPerPage)
                              );
                              setPage(page + 1);
                            }}
                          >
                            {"Next"}
                          </Pagination.Next>
                        </Pagination>
                      </div>
                    </>
                  ) : (
                    <div
                      className="alert alert-danger mb-0 text-center my-4"
                      role="alert"
                    >
                      {"BOM not found!"}
                    </div>
                  )}

                </div>
              </div>
            </div>
          </div>
        </>
      }
    </React.Fragment>
  );
}

export default Bom;
