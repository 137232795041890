import React, { useState, useEffect, useCallback } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import DatePicker from "react-datepicker";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Typeahead } from "react-bootstrap-typeahead";
import { faArrowLeft, faClose, faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import SweetAlert from "sweetalert2";
import Add_Measurements from "./Add_Measurements";
import {
  createSalesOrder,
  del_master_file,
  get_attrib_api,
  get_master_files,
  get_party_attributes,
  getCustomer,
  getLocation,
  getSOStatus,
  updateProduct,
} from "../../../api";
import Add_Profile from "./Add_Profile";
import { Tooltip } from "react-tooltip";
import { Edit } from "react-feather";
import { Spinner } from "react-bootstrap";
import axios from "axios";
import { s3_image_upload } from "../../../constant";
import { calculateAge, convertDateYYMMDD, truncateText } from "../../../common/Functions/CommonFunctions";
import DesignSheetUpload from "./DesignSheetUpload";
import audio from "../../../assets/images/audio.png"
import { screenList } from "../../../common/Labels/screenList";
import * as name_const from "../../../common/Constants/constants";

const normalizeDate = (date) => {
  if (!date) return null;
  const d = new Date(date);
  if (isNaN(d)) return null; // Handle invalid dates
  d.setHours(0, 0, 0, 0); // Normalize to midnight
  return d;
};

const schema = yup.object().shape({
      // sales_order_name: yup.string().required("Sales order name is required"),
      location: yup
        .array()
        .min(1, "Please select location!")
        .required("Please select location!"),
      discount: yup
        .string()
        .nullable()
        .transform((value, originalValue) =>
          originalValue === "" ? null : value
        )
        .matches(/^\d+(\.\d+)?$/, "Enter valid price"),
      final_total: yup
        .string()
        .required("Enter total amount")
        .matches(/^\d+(\.\d+)?$/, "Enter valid amount"),
      advance_amount: yup
        .string()
        .nullable()
        .transform((value, originalValue) =>
          originalValue === "" ? null : value
        )
        .matches(/^\d+(\.\d+)?$/, "Enter valid price"),
      order_date: yup
              .date()
              .required("Please select order date!")
              .typeError("Please select order date!"),
      
      approval_date: yup
        .date()
        .nullable()
        .typeError("Please select approval date!")
        .test(
          "approval-date-validation",
          "Approval date must be same as or after Order date!",
          function (value) {
            const { order_date } = this.parent;
            const approvalDate = normalizeDate(value);
            const orderDate = normalizeDate(order_date);
    
            if (!approvalDate) return true; // Approval date is optional
            if (!orderDate) return false; // Order date must exist if approval date is selected
    
            return approvalDate >= orderDate;
          }
        ),
          
      trail_date: yup
        .date()
        .required("Please select trial date!") // ❌ Cannot be empty
        .typeError("Please select trial date!")
        .test(
          "trial-date-validation",
          "Trial date must be same as or after Approval date (if selected), otherwise Order date!",
          function (value) {
            const { order_date, approval_date } = this.parent;
            const minDate = normalizeDate(approval_date) || normalizeDate(order_date);
            const trialDate = normalizeDate(value);
    
            return minDate && trialDate && trialDate >= minDate;
          }
        ),
          
      delivery_date: yup
        .date()
        .required("Please select delivery date!") // ❌ Cannot be empty
        .typeError("Please select delivery date!")
        .test(
          "delivery-date-validation",
          "Delivery date must be same as or after Trial date!",
          function (value) {
            const { trail_date } = this.parent;
            const trialDate = normalizeDate(trail_date);
            const deliveryDate = normalizeDate(value);
    
            return trialDate && deliveryDate && deliveryDate >= trialDate;
          }
        ),
        
      final_date: yup
        .date()
        .required("Please select final date!") // ❌ Cannot be empty
        .typeError("Please select final date!")
        .test(
          "final-date-validation",
          "Final date must be same as or after Delivery date!",
          function (value) {
            const { delivery_date } = this.parent;
            const deliveryDate = normalizeDate(delivery_date);
            const finalDate = normalizeDate(value);
    
            return deliveryDate && finalDate && finalDate >= deliveryDate;
          }
        ),
      
      internal_remark: yup
        .string()
        .required("Special instructions required!"),

      items: yup.array().of(
        yup.object().shape({
          product: yup
            .array()
            .min(1, "Please select product !")
            .required("Please select product !"),
          price: yup
            .string()
            .required("Price is required")
            .matches(/^[0-9]+(\.[0-9]+)?$/, "Enter valid price"),
        })
      ),
});

function Alter_Sales_Order() {
  let location = useLocation();
  let navigate = useNavigate();

  const [visible, setVisible] = useState(false); // Modal open for measurements
  const [visible2, setVisible2] = useState(false); // Modal open for profile images
  const [visible3, setVisible3] = useState(false); // Modal open for design sheets upload
  const [constLabel, setConstLabel] = useState({});
  const [lockBtn, setLockBtn] = useState(false);

  const [cust_data, setCust_Data] = useState([]);
  const [selectedType, setSelectedType] = useState("Alteration"); // Default selection

  const [attribList, setAttribList] = useState([]); // get all measurements
  const [single_measured, set_single_mesured] = useState([]); // sigle mesurement create / edit
  const [selectedCustomer, setSelectedCustomer] = useState([]);
  const [attach_files, setAttach_Files] = useState({
    data: [],
    loading: false,
  });

  const [discountType, setDiscountType] = useState("disPerc"); // Default selection

  const [loc_list, setLoc_List] = useState({ data: [], loading: false });
  const [prod_List, setProd_List] = useState({ data: [], loading: false });
  const [imageFiles, setImageFiles] = useState([]);
  const [approved_status, set_approved_status] = useState({data: []});

  const localData = JSON.parse(localStorage.getItem("localData"));
  const def_org = localData?.organizations?.filter(
    (item) => item.default_org == true
  );

  const [debounceTimer, setDebounceTimer] = useState(null);

  const {
    register,
    handleSubmit,
    formState,
    trigger,
    reset,
    watch,
    control,
    setValue,
    getValues,
    setError,
    clearErrors
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  let { errors } = formState;

  const { fields } = useFieldArray({ control, name: "items" });

  useEffect(() => {
    const fetchData = async () => {
      console.log("location.state", location.state);
      const firstItem = location.state;
  
      // Fetch location
      let locObj = [];
      try {
        const locRes = await getLocation({ uuid: firstItem?.location_id });
        locObj = locRes?.data?.data || [];
      } catch (error) {
        console.error("Error fetching location:", error);
      }
  
      // Fetch attributes
      get_attrib_api()
        .then((res) => {
          setAttribList(res?.data?.data?.length > 0 ? res.data.data : []);
        })
        .catch(() => setAttribList([]));
  
      // Fetch customer data
      getCustomer({ uuid: location.state?.customer_id })
        .then((res) => {
          if (res?.data?.data?.length > 0) {
            setCust_Data(res.data.data);
            setSelectedCustomer(res.data.data[0]);
            party_mesurements(res.data.data);
            fetchImage(res.data.data?.[0]?.uuid);
          } else {
            setCust_Data([]);
            setSelectedCustomer([]);
          }
        })
        .catch(() => {
          setCust_Data([]);
          setSelectedCustomer([]);
        });
  
      // Set form values
      setValue("sales_order_no", firstItem.so_id);
      setValue("sales_order_name", firstItem.sales_order_name);
      setValue("location", locObj);
      setValue("order_date", firstItem.order_date ? new Date(firstItem.order_date) : null);
      setValue("approval_date", null);
      setValue("trail_date", null);
      setValue("delivery_date", null);
      setValue("final_date", null);
      setValue("discount", 0);
      setValue("sub_total", 0);
      setValue("final_total", 0);
      setValue("advance_amount", 0);
      setValue("outstanding_amount", 0);
      setValue("internal_remark", firstItem.internal_remark);
  
      // Process order lines
      let arr = firstItem.so_order_lines
        ?.sort((a, b) => a.id - b.id)
        .map((item) => ({
          product: [
            {
              ...item.product_id,
              productWithPrice:
                item.product_id?.productName &&
                item.product_id?.productName !== ""
                  ? `${item.product_id.productName} - ${item.product_id.salesPrice} ₹`
                  : "",
            },
          ],
          price: 0,
          itemChecked: false,
        }));
  
      setValue("items", arr); // Default value if no order lines exist
    };
  
    if (location.state) {
      fetchData();
    }
    fetchSOStatus();
  }, [location.state]);

  useEffect(() => {
    if(!visible){
      party_mesurements([selectedCustomer]);
    }
  }, [visible])

  function fetchSOStatus() {
        getSOStatus().then(
          (res) => {
            if(res && res.data.data){
              const result = res.data.data?.filter((item) => item.status_name == name_const.APPROVED);
              set_approved_status({data: result});
            } else {
              set_approved_status({ data:[]});
            }
          },
          (err) => {
            set_approved_status({ data:[]});
          }
        );
      }

  function party_mesurements(party) {
    if (party.length > 0) {
      get_party_attributes({ party_uuid: party?.[0]?.uuid }).then(
        (res) => {
          // console.log("res", res);
          if (res && res.data.data?.length > 0) {
            const result = res.data.data?.filter((item) => item.uuid === location.state?.party_attribute_id);
            set_single_mesured(result);
          } else {
            set_single_mesured([]);
          }
        },
        (err) => {
          set_single_mesured([]);
        }
      );
    }
  }

  // async function fetchImage(customer_uuid) {
  //   setAttach_Files((prev) => ({ ...prev, loading: true, data: [] }));
  //   await get_master_files({
  //     ref_model_uuid: customer_uuid,
  //   }).then(
  //     (res) => {
  //       setAttach_Files((prev) => ({
  //         ...prev,
  //         loading: false,
  //         data: res.data.data,
  //       }));
  //     },
  //     (err) => {
  //       setAttach_Files((prev) => ({ ...prev, loading: true, data: [] }));
  //     }
  //   );
  // }

  const fetchImage = useCallback(async (customer_uuid) => {
    setAttach_Files((prev) => ({ ...prev, loading: true, data: [] }));
    try {
      const res = await get_master_files({ ref_model_uuid: customer_uuid });
      setAttach_Files((prev) => ({
        ...prev,
        loading: false,
        data: res.data.data,
      }));
    } catch (error) {
      setAttach_Files((prev) => ({ ...prev, loading: false, data: [] }));
    }
  }, []);

  const calculateTotalAmount = async (isFinalTotalChanged = false) => {
    const customerItems = getValues(`items`) || [];
    let totalAmount = customerItems.reduce(
      (sum, item) => sum + (Number(item.price) || 0),
      0
    );
  
    let discountValue = Number(getValues(`discount`)) || 0;
    let finalAmount = totalAmount;
  
    if (isFinalTotalChanged) {
      // When user manually changes final_total, adjust discount automatically
      let newFinalAmount = Number(getValues(`final_total`));
  
      if (newFinalAmount > totalAmount) {
        setError(`final_total`, {
          type: "manual",
          message: "Final amount cannot be greater than the sub total.",
        });
        return;
      } else {
        clearErrors(`final_total`);
      }
  
      discountValue = totalAmount - newFinalAmount;
  
      setDiscountType(`disAmt`);
      setValue(`discount`, discountValue.toFixed(2), {
        shouldValidate: true,
      });
  
      finalAmount = newFinalAmount;
    } else {
      if (discountType === "disPerc") {
        if (discountValue > 100) {
          setError(`discount`, {
            type: "manual",
            message: "Percentage cannot exceed 100%",
          });
          return;
        } else {
          clearErrors(`discount`);
        }
        finalAmount = totalAmount - totalAmount * (discountValue / 100);
      } else if (discountType === "disAmt") {
        if (discountValue > totalAmount) {
          setError(`discount`, {
            type: "manual",
            message: "Discount amount cannot exceed sub total",
          });
          return;
        } else {
          clearErrors(`discount`);
        }
        finalAmount = totalAmount - discountValue;
      }
    }
  
    finalAmount = Number(finalAmount.toFixed(2));
  
    let advanceAmount = Number(getValues(`advance_amount`)) || 0;
    advanceAmount = Math.min(advanceAmount, finalAmount);
    let outstandingAmount = Math.max(finalAmount - advanceAmount, 0);
  
    setValue(`sub_total`, totalAmount.toFixed(2));
  
    if (!isFinalTotalChanged) {
      setValue(`final_total`, finalAmount.toFixed(2), {
        shouldValidate: true,
      });
    }
  
    setValue(`outstanding_amount`, outstandingAmount.toFixed(2));
  
    // Ensure validation is triggered after value updates
    trigger(`discount`);
  };

  const getViewType = (name) => {
    const match = name.match(/^(front|back|side)/);
    return match ? match[0] + "-" + "image" : "";
  };

  const handleCheckboxChange = (index, isChecked) => {
    setValue(`items[${index}].itemChecked`, isChecked, { shouldValidate: true });
  };

  function onSubmit(data) {
    // console.log("data", data); return;
    setLockBtn(true);
    let discount_percentage = 0;
    if (!data.items.some(item => item.itemChecked)) {
      toast.error("Please select a product for alteration", {autoClose: 1200});
      setLockBtn(false);
      return;
    }

    if(discountType === "disAmt"){
      let difference = Number(data?.sub_total) - Number(data?.final_total);
      discount_percentage = (difference / Number(data?.sub_total)) * 100;
    } else {
      discount_percentage = Number(data?.discount);
    }

    const reqPayload = [];
    for (let i = 0; i < data.items?.length; i++) {
      const item = data.items[i];
    
      if (item.itemChecked) {
        const lineItems = {
          product_type: item.product?.[0]?.product_type_id?.productType,
          productname: item.product?.[0]?.productName,
          partnumber: item.product?.[0]?.OEMnumber,
          quantity: 1,
          rate: Number(item.price).toFixed(2),
          unit: item.product?.[0]?.unit_id?.uom,
          amount: Number(item.price).toFixed(2),
          product_type_id: item.product?.[0].product_type_id?.uuid,
          product_id: item.product?.[0]?.uuid,
          unit_id: item.product?.[0]?.unit_id?.uuid,
          org_id: def_org?.[0]?.id,
          gst_rate: 0,
          gst_amount: 0,
          discount: discount_percentage,
          approval_date:
            data?.approval_date !== null &&
            data?.approval_date !== undefined
              ? convertDateYYMMDD(data?.approval_date, "-")
              : null,
          trail_date: convertDateYYMMDD(data?.trail_date, "-"),
          delivery_date: convertDateYYMMDD(data?.delivery_date, "-"),
          final_date: convertDateYYMMDD(data?.final_date, "-"),
          measurement: location.state?.party_attribute_id,
          grouping_sequence: 1.0,
          party_id: location.state?.customer_id,
        };
    
        reqPayload.push(lineItems);
    
        // if (item.price !== "0") {
        //   updateProduct({
        //     uuid: item.product?.[0]?.uuid,
        //     can_be_sold: "true",
        //     salesPrice: item.price,
        //   });
        // }
      }
    }

    const payload = {
      sales_order_id: location.state?.uuid,
      org_id: def_org?.[0]?.uuid,
      customer_id: location.state?.customer_id,
      customer_name: location.state?.customer_name, //as per discussion with tester change
      party_address: location.state?.party_address,
      party_po_ref: null,
      quote_ref: null,
      order_date: convertDateYYMMDD(data?.order_date, "-"),
      order_due_date: convertDateYYMMDD(
        data?.delivery_date,
        "-"
      ),
      approval_date:
        data?.approval_date !== null &&
        data?.approval_date !== undefined
          ? convertDateYYMMDD(data?.approval_date, "-")
          : null,
      trail_date: convertDateYYMMDD(data?.trail_date, "-"),
      delivery_date: convertDateYYMMDD(
        data?.delivery_date,
        "-"
      ),
      final_date: convertDateYYMMDD(data?.final_date, "-"),
      invoice_to: location.state?.invoice_to?.uuid,
      invoice_to_address: location.state?.invoice_to?.address,
      dispatch_to: location.state?.dispatch_to?.uuid,
      dispatch_to_address: location.state?.dispatch_to?.address,
      sub_total: Number(data?.sub_total).toFixed(2),
      final_total: Number(data?.final_total).toFixed(2),
      status: approved_status.data?.[0]?.id, // Approved status id
      internal_remark: data?.internal_remark,
      sales_order_name: data?.sales_order_name,
      igst: 0,
      igst_cost: 0,
      delivery_address: location.state?.delivery_address,
      sales_person: localData?.employee_id?.uuid,
      delivery_preferance: "",
      design_sheet: null,
      party_attribute_id: location.state?.party_attribute_id,
      employee_name:
        localData?.employee_id?.FirstName +
        " " +
        localData?.employee_id?.LastName,
      location_name: data?.location?.[0]?.locationname,
      location_id: data?.location?.[0]?.uuid,
      sales_order_type: selectedType,
      age:
        selectedCustomer?.dob !== null
          ? calculateAge(selectedCustomer?.dob)
          : null,
      gender: selectedCustomer?.gender,
      kids: selectedCustomer?.kids_wear,
      advance_amount: Number(data?.advance_amount).toFixed(2),
      outstanding_amount: Number(
        data?.outstanding_amount
      ).toFixed(2),
    };

    const createPayload = {
      so_order: payload,
      so_order_lines: reqPayload,
    };

    // console.log("createPayload", createPayload);

    createSalesOrder(createPayload).then(
      async (res) => {
        if (res.status === 200 || res.status === 201) {
          const imgArr = [];
          for (let a = 0; a < imageFiles?.length; a++) {
            const imgObj = imageFiles[a];
            imgArr.push({
              fileName: (imgObj?.file?.name)?.replace(/[^a-zA-Z0-9.]+/g, ''),
              contentType: imgObj?.file?.type,
              model: {
                ref_model_uuid: res.data?.so_order?.uuid,
                model_name: screenList.sales_order_screen.replace(/\s+/g, ""),
                name: (imgObj?.file?.name)?.replace(/[^a-zA-Z0-9.]+/g, ''),
                document_type: imgObj?.file?.type.startsWith("image/") ? "image" : 
                imgObj?.file?.type.startsWith("audio/") ? "audio" : "video",
                created_by_user: localData?.user_id,
                client_id: localData?.client_id
              }
            });
          }

          const payload = {
            files: imgArr,
          };

          if (imageFiles?.length > 0) {
            const url = process.env.REACT_APP_COGNITO_URL + s3_image_upload;
            const idToken = localStorage.getItem("id");
            
            axios.post(url, payload, {
              headers: {
                Authorization: `Bearer ${idToken}`,
                'Content-Type': 'application/json'
              }
            }).then(
              (res) => {
                // console.log("res", res);
                for(let x = 0; x < res.data.data?.body?.length; x++){
                  const url2 = res.data.data?.body?.[x]?.url;

                  axios.put(url2, imageFiles[x]?.file, {
                    headers: {
                      'Content-Type': imageFiles[x]?.file?.type
                    }
                  }).then(
                    (ress) => {
                      toast.success("Alteration sales order created successfully !", {
                        autoClose: 2000,
                      });
                      setTimeout(() => {
                        navigate("/pcms/sales-order");
                        reset();
                      }, [2000]);
                      setLockBtn(false);
                    },
                    (errr) => {
                      console.log("errr", errr);
                      if (errr.response.status == 413) {
                        toast.error("Image upload failed !", {
                          autoClose: 3000,
                        });
                      }
                    }
                  );
                }
              },
              (err) => {
                console.log("err", err);
              }
            );
          } else {
            toast.success("Sales order created successfully !", {
              autoClose: 2000,
            });
            setTimeout(() => {
              navigate("/pcms/sales-order");
              reset();
            }, [2200]);
            setLockBtn(false);
          }
        }
      },
      (err) => {
        toast.error(err.response.data.message, {
          autoClose: 3000,
        });
        setLockBtn(false);
      }
    );

  }



  return (
    <React.Fragment>
      <ToastContainer />

      <Add_Measurements
        visible={visible}
        setVisible={setVisible}
        cust_data={cust_data}
        setCust_Data={setCust_Data}
        attribList={attribList}
        single_measured={single_measured}
        set_single_mesured={set_single_mesured}
        party_mesurements={party_mesurements}
        selectedCustomer={selectedCustomer}
      />

      <Add_Profile
        visible={visible2}
        setVisible={setVisible2}
        fetchImage={fetchImage}
        selectedCustomer={selectedCustomer}
        attach_files={attach_files}
      />

      <div className="purchase_order mb-4">
        <div className="card">
          <div className="card-header bg-grey d-flex align-items-center">
            <button
              className="btn btn-yellow"
              onClick={() => {
                SweetAlert.fire({
                  title: "Are you sure?",
                  text: "Your present data will be lost!",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes, go back!",
                }).then((result) => {
                  if (result.isConfirmed) {
                    window.onpopstate = undefined;
                    navigate("/pcms/sales-order");
                  }
                });
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} size="sm" />
            </button>

            <p className="mb-0 ms-3" style={{ fontSize: "24px" }}>
              {constLabel?.lbl_alter_sales_order
                ? constLabel.lbl_alter_sales_order
                : "Alter Sales Order"}{" "}{`(${location.state?.customer_name})`}
            </p>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-12 col-md-3">
                <div className="form-group">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_sales_order_no
                      ? constLabel.lbl_sales_order_no
                      : "Sales Order No"}
                  </label>
                  <input
                    className="form-control mt-2"
                    id="sales_order_no"
                    name="sales_order_no"
                    type="text"
                    placeholder="Enter sales order no"
                    {...register(`sales_order_no`)}
                    disabled
                  />
                  {/* {errors.sales_order_name && (
                        <span className="text-danger err-msg">
                          {errors.sales_order_name.message}
                        </span>
                      )} */}
                </div>
              </div>

              <div className="col-12 col-md-3">
                <div className="form-group">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_sales_order_name
                      ? constLabel.lbl_sales_order_name
                      : "Sales Order Name"}
                  </label>
                  <input
                    className="form-control mt-2"
                    id="sales_order_name"
                    name="sales_order_name"
                    type="text"
                    placeholder="Enter sales order name"
                    {...register(`sales_order_name`)}
                  />
                  {/* {errors.sales_order_name && (
                        <span className="text-danger err-msg">
                          {errors.sales_order_name.message}
                        </span>
                      )} */}
                </div>
              </div>

              <div className="col-12 col-md-3">
                <div className="form-group typeahead-container">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_location
                      ? constLabel.lbl_location
                      : "Location"}
                    {""}
                    <span className="text-danger"> *</span>
                  </label>
                  <Controller
                    name={`location`}
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        className="mt-2"
                        id="basic-typeahead-single"
                        labelKey="locationname"
                        multiple={false}
                        options={loc_list.data}
                        isLoading={loc_list.loading}
                        placeholder="Select location"
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        isInvalid={errors.location ? true : false}
                        disabled
                      />
                    )}
                  />
                  {!loc_list.loading && !errors.location && (
                    <span className="arrow-icon"></span>
                  )}
                  {errors.location && (
                    <span className="text-danger err-msg">
                      {errors.location.message}
                    </span>
                  )}
                </div>
              </div>

              <div className="col-12 col-md-3">
                <div className="form-group">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_order_date
                      ? constLabel.lbl_order_date
                      : "Order Date"}
                    {""}
                    <span className="text-danger"> *</span>
                  </label>
                  <Controller
                    control={control}
                    name={`order_date`}
                    defaultValue={new Date()}
                    render={({ field }) => (
                      <DatePicker
                        className={
                          errors.order_date
                            ? "form-control mt-2 is-invalid"
                            : "form-control mt-2"
                        }
                        placeholderText="Select date"
                        onChange={(date) => field.onChange(date)}
                        selected={field.value}
                        defaultValue={new Date()}
                        // maxDate={new Date()}
                        dateFormat="dd/MM/yyyy"
                        disabled
                      />
                    )}
                  />

                  {errors.order_date && (
                    <span className="text-danger err-msg">
                      {errors.order_date.message}
                    </span>
                  )}
                </div>
              </div>

              <div className="col-12 col-md-3 mt-3">
                <div className="form-group">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_approval_date
                      ? constLabel.lbl_approval_date
                      : "Approval Date"}
                    {""}
                    {/* <span className="text-danger"> *</span> */}
                  </label>
                  <Controller
                    control={control}
                    name={`approval_date`}
                    defaultValue={new Date()}
                    render={({ field }) => (
                      <DatePicker
                        className={
                          errors.approval_date
                            ? "form-control mt-2 is-invalid"
                            : "form-control mt-2"
                        }
                        placeholderText="Select approval date"
                        onChange={(date) => field.onChange(date)}
                        selected={field.value}
                        defaultValue={new Date()}
                        // maxDate={new Date()}
                        dateFormat="dd/MM/yyyy"
                      />
                    )}
                  />

                  {errors.approval_date && (
                    <span className="text-danger err-msg">
                      {errors.approval_date.message}
                    </span>
                  )}
                </div>
              </div>

              <div className="col-12 col-md-3 mt-3">
                <div className="form-group">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_trial_date
                      ? constLabel.lbl_trial_date
                      : "Trial Date"}
                    {""}
                    <span className="text-danger"> *</span>
                  </label>
                  <Controller
                    control={control}
                    name={`trail_date`}
                    defaultValue={new Date()}
                    render={({ field }) => (
                      <DatePicker
                        className={
                          errors.trail_date
                            ? "form-control mt-2 is-invalid"
                            : "form-control mt-2"
                        }
                        placeholderText="Select trial date"
                        onChange={(date) => field.onChange(date)}
                        selected={field.value}
                        defaultValue={new Date()}
                        // maxDate={new Date()}
                        dateFormat="dd/MM/yyyy"
                      />
                    )}
                  />

                  {errors.trail_date && (
                    <span className="text-danger err-msg">
                      {errors.trail_date.message}
                    </span>
                  )}
                </div>
              </div>

              <div className="col-12 col-md-3 mt-3">
                <div className="form-group">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_delivery_date
                      ? constLabel.lbl_delivery_date
                      : "Delivery Date"}
                    {""}
                    <span className="text-danger"> *</span>
                  </label>
                  <Controller
                    control={control}
                    name={`delivery_date`}
                    defaultValue={new Date()}
                    render={({ field }) => (
                      <DatePicker
                        className={
                          errors.delivery_date
                            ? "form-control mt-2 is-invalid"
                            : "form-control mt-2"
                        }
                        placeholderText="Select delivery date"
                        onChange={(date) => field.onChange(date)}
                        selected={field.value}
                        defaultValue={new Date()}
                        // maxDate={new Date()}
                        dateFormat="dd/MM/yyyy"
                      />
                    )}
                  />

                  {errors.delivery_date && (
                    <span className="text-danger err-msg">
                      {errors.delivery_date.message}
                    </span>
                  )}
                </div>
              </div>

              <div className="col-12 col-md-3 mt-3">
                <div className="form-group">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_final_date
                      ? constLabel.lbl_final_date
                      : "Final Date"}
                    {""}
                    <span className="text-danger"> *</span>
                  </label>
                  <Controller
                    control={control}
                    name={`final_date`}
                    defaultValue={new Date()}
                    render={({ field }) => (
                      <DatePicker
                        className={
                          errors.final_date
                            ? "form-control mt-2 is-invalid"
                            : "form-control mt-2"
                        }
                        placeholderText="Select final date"
                        onChange={(date) => field.onChange(date)}
                        selected={field.value}
                        defaultValue={new Date()}
                        // maxDate={new Date()}
                        dateFormat="dd/MM/yyyy"
                      />
                    )}
                  />

                  {errors.final_date && (
                    <span className="text-danger err-msg">
                      {errors.final_date.message}
                    </span>
                  )}
                </div>
              </div>

              <div className="col-12 col-md-3 mt-3">
                <div className="form-group">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_special_instruction
                      ? constLabel.lbl_special_instruction
                      : "Special Instruction"}{" "}
                    <span className="text-danger"> *</span>
                  </label>
                  <textarea
                    className="form-control mt-2"
                    id="internal_remark"
                    name="internal_remark"
                    type="text"
                    placeholder="Enter special instructions"
                    {...register(`internal_remark`)}
                  ></textarea>
                  {errors.internal_remark && (
                    <span className="text-danger err-msg">
                      {errors.internal_remark.message}
                    </span>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-6 mt-3">
                  <div className="form-group row">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_order_type
                        ? constLabel.lbl_order_type
                        : "Order Type"}
                    </label>

                    <div className="col-md-12 mt-2">
                      {["Alteration"].map((option) => (
                        <div
                          className="form-check form-check-inline"
                          key={option}
                        >
                          <input
                            className="form-check-input mt-2"
                            type="checkbox"
                            id={option}
                            value={option}
                            checked={selectedType === option} // Only one selection allowed
                            disabled
                          />
                          <label
                            className="form-check-label mt-1"
                            htmlFor={option}
                          >
                            {option}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-6 mt-3 text-end">
                  <button
                    type="button"
                    className="btn btn-warning mt-3"
                    onClick={() => setVisible3(true)} // Open modal for this customer
                  >
                    {constLabel?.lbl_design_sheet
                      ? constLabel?.lbl_design_sheet
                      : "Design Sheet"}
                  </button>
                </div>
              </div>

                {/* Show modal only for the selected customer */}
                <DesignSheetUpload
                  imageFiles={imageFiles}
                  setImageFiles={setImageFiles}
                  visible={visible3}
                  setVisible={setVisible3} // Close modal
                />

              <hr className="my-2" />
              <div className="col-12 col-md-6 p-2 border">
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <p className="roboto-bold mb-0" style={{ fontSize: "20px" }}>
                    {constLabel?.lbl_measurements
                      ? constLabel.lbl_measurements
                      : "Measurements"}
                  </p>
                </div>
                {single_measured.length > 0 ? (
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead className="table-grey roboto-bold">
                        <tr>
                          <th width="10%">Sr.</th>
                          <th>Measurements</th>
                          <th width="15%">Action</th>
                        </tr>
                      </thead>
                      <tbody className="roboto-bold">
                        {single_measured?.map((val, i) => (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{val.name}</td>
                            <td>
                              <Tooltip id={"edit-tooltip" + i} place="top" />
                              <Edit
                                data-tooltip-id={"edit-tooltip" + i}
                                data-tooltip-content={
                                  constLabel?.lbl_edit_measurements
                                    ? constLabel.lbl_edit_measurements
                                    : "Edit Mesurements"
                                }
                                className="menu_icon_grey cursor_pointer me-2"
                                size={20}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  set_single_mesured([val]);
                                  setTimeout(() => setVisible(true), 50); // Slight delay
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div
                    className="alert alert-danger mb-0 text-center my-4"
                    role="alert"
                  >
                    {"Measurements not found!"}
                  </div>
                )}
              </div>

              <div className="col-12 col-md-6 p-2 border">
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <p className="roboto-bold mb-0" style={{ fontSize: "20px" }}>
                    {constLabel?.lbl_profile_image
                      ? constLabel.lbl_profile_image
                      : "Profile Image"}
                  </p>
                  <button
                    className="btn btn-sm btn-outline-dark px-4 ms-0 ms-md-2"
                    onClick={(e) => {
                      e.stopPropagation();
                      setTimeout(() => setVisible2(true), 50); // Slight delay
                    }}
                  >
                    {constLabel?.lbl_add_profile
                      ? constLabel.lbl_add_profile
                      : "Add Profile"}
                  </button>
                </div>
                <div>
                  {attach_files?.loading ? (
                    <Spinner size="sm" />
                  ) : attach_files?.data?.length > 0 ? (
                    <div className="row">
                      {attach_files?.data.map((val, i) =>
                        val.attachment !== null ? (
                          <React.Fragment key={val.uuid}>
                            <div className="col-12 col-md-4 text-center">
                              <div className="d-flex justify-content-center mt-3">
                                <div className="image-container text-center">
                                  <a
                                    href={`${process.env.REACT_APP_IMAGE_URL}${val.attachment}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <img
                                      src={`${process.env.REACT_APP_IMAGE_URL}${val.attachment}`}
                                      className="img-fluid"
                                      style={{ height: "100px" }}
                                      alt={"Image"}
                                    />
                                  </a>
                                </div>
                              </div>
                              <span className="file-name cursor-pointer">
                                {getViewType(val?.name)}
                              </span>
                            </div>
                          </React.Fragment>
                        ) : null
                      )}
                    </div>
                  ) : (
                    <div
                      className="alert alert-danger mb-0 text-center my-4"
                      role="alert"
                    >
                      {"No files found!"}
                    </div>
                  )}
                </div>
              </div>

              <hr className="my-2" />

              <div className="col-12 col-md-6 p-2 border">
                <p className="fw-bold mt-2">Order Items</p>
                <hr />
                <div className="">
                  <table className="table table-responsive table-bordered">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">
                          {constLabel?.lbl_product_name
                            ? constLabel.lbl_product_name
                            : "Product Name"}
                        </th>
                        <th scope="col">
                          {constLabel?.lbl_unit_price
                            ? constLabel.lbl_unit_price
                            : "Unit Price"}{" "}
                          <span>&#8377;</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {fields.map((item, index) => (
                        <tr key={item.id}>
                          <td>
                            <input
                              className="form-check-input cursor_pointer"
                              type="checkbox"
                              checked={!!watch(`items[${index}].itemChecked`)}
                              onChange={(e) => handleCheckboxChange(index, e.target.checked)}
                            />
                          </td>
                          <td style={{ verticalAlign: "top" }}>
                            <Controller
                              name={`items[${index}].product`}
                              control={control}
                              render={({
                                field: { onChange, onBlur, value, ref },
                              }) => (
                                <Typeahead
                                  id="basic-typeahead-single"
                                  labelKey="productWithPrice"
                                  size="sm"
                                  multiple={false}
                                  options={prod_List.data}
                                  isLoading={prod_List.loading}
                                  placeholder="Select product"
                                  onChange={(selected) => {
                                    if (selected.length > 0) {
                                      setValue(
                                        `items[${index}].product`,
                                        selected,
                                        { shouldValidate: true }
                                      );
                                      setValue(
                                        `items[${index}].price`,
                                        selected[0].salesPrice ?? 0,
                                        { shouldValidate: true }
                                      );
                                      calculateTotalAmount();
                                    } else {
                                      setValue(`items[${index}].product`, [], {
                                        shouldValidate: true,
                                      });
                                      setValue(`items[${index}].price`, "", {
                                        shouldValidate: true,
                                      });
                                      calculateTotalAmount();
                                    }

                                    const isNewOption =
                                      selected && selected[0]?.customOption;
                                    if (isNewOption) {
                                      const newItem =
                                        selected[0]?.productWithPrice;
                                    }
                                    onChange(selected);
                                  }}
                                  newSelectionPrefix="Add New: "
                                  allowNew
                                  onBlur={onBlur}
                                  selected={value}
                                  isInvalid={
                                    errors?.items?.[index]?.product
                                      ? true
                                      : false
                                  }
                                  disabled
                                />
                              )}
                            />
                            {errors?.items?.[index]?.product && (
                              <span className="text-danger err-msg">
                                {errors?.items?.[index]?.product.message}
                              </span>
                            )}
                          </td>

                          <td style={{ verticalAlign: "top", width: "20%" }}>
                            <input
                              className={
                                errors?.items?.[index]?.price
                                  ? "form-control form-control-sm is-invalid"
                                  : "form-control form-control-sm"
                              }
                              type="text"
                              // defaultValue={0}
                              name={`items[${index}].price`}
                              {...register(`items[${index}].price`, {
                                onChange: () => calculateTotalAmount(),
                              })}
                            />

                            {errors?.items?.[index]?.price && (
                              <span className="text-danger err-msg">
                                {errors?.items?.[index]?.price.message}
                              </span>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="col-12 col-md-6 p-2 border">
                <p className="fw-bold mt-2">Payment Details</p>
                <hr />
                <div className="row">
                  <div className="col-12 col-md-4">
                    <div className="form-group">
                      <label className="lbl-style roboto-bold">
                        {constLabel?.lbl_amount
                          ? constLabel.lbl_amount
                          : "Amount"}
                      </label>
                      <input
                        className="form-control mt-2"
                        id="sub_total"
                        name="sub_total"
                        type="text"
                        {...register(`sub_total`)}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group row">
                      <label className="lbl-style roboto-bold">
                        {constLabel?.lbl_discount_type
                          ? constLabel.lbl_discount_type
                          : "Discount Type"}
                      </label>

                      <div className="col-md-12">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input mt-2"
                            type="radio"
                            name="inlineRadioOptions"
                            id="disPerc"
                            value="disPerc"
                            checked={discountType === "disPerc"}
                            {...register("discountType", {
                              onChange: (e) => {
                                setDiscountType(e.target.value);
                                calculateTotalAmount();
                              },
                            })}
                          />
                          <label
                            className="form-check-label body2"
                            htmlFor="disPerc"
                          >
                            Percentage (%)
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input mt-2"
                            type="radio"
                            name="inlineRadioOptions"
                            id="disAmt"
                            value="disAmt"
                            checked={discountType === "disAmt"}
                            {...register("discountType", {
                              onChange: (e) => {
                                setDiscountType(e.target.value);
                                calculateTotalAmount();
                              },
                            })}
                          />
                          <label
                            className="form-check-label body2"
                            htmlFor="disAmt"
                          >
                            Amount (₹)
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group">
                      <label className="lbl-style roboto-bold">
                        {constLabel?.lbl_discount
                          ? constLabel.lbl_discount
                          : "Discount"}{" "}
                        {discountType === "disPerc" ? "(%)" : "(₹)"}
                      </label>
                      <input
                        className="form-control mt-2"
                        id="discount"
                        name="discount"
                        type="text"
                        {...register(`discount`, {
                          onChange: (e) => {
                            clearTimeout(debounceTimer);
                            const newTimer = setTimeout(() => {
                              calculateTotalAmount();
                            }, 500); // 500ms debounce
                            setDebounceTimer(newTimer);
                          },
                        })}
                      />
                      {errors.discount && (
                        <small className="text-danger err-msg">
                          {errors.discount.message}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-4 mt-3">
                    <div className="form-group">
                      <label className="lbl-style roboto-bold">
                        {constLabel?.lbl_total_amount
                          ? constLabel.lbl_total_amount
                          : "Total Amount"}
                      </label>
                      <input
                        className="form-control mt-2"
                        id="final_total"
                        name="final_total"
                        type="text"
                        {...register(`final_total`, {
                          onChange: (e) => {
                            clearTimeout(debounceTimer);
                            const newTimer = setTimeout(() => {
                              calculateTotalAmount(true);
                            }, 500); // 500ms debounce
                            setDebounceTimer(newTimer);
                          },
                        })}
                      />
                      {errors.final_total && (
                        <small className="text-danger err-msg">
                          {errors.final_total.message}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-4 mt-3">
                    <div className="form-group">
                      <label className="lbl-style roboto-bold">
                        {constLabel?.lbl_advance_amount
                          ? constLabel.lbl_advance_amount
                          : "Advance Amount"}
                      </label>
                      <input
                        className="form-control mt-2"
                        id="advance_amount"
                        name="advance_amount"
                        type="text"
                        {...register(`advance_amount`, {
                          onChange: () => calculateTotalAmount(),
                        })}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-4 mt-3">
                    <div className="form-group">
                      <label className="lbl-style roboto-bold">
                        {constLabel?.lbl_outstanding_amount
                          ? constLabel.lbl_outstanding_amount
                          : "Outstanding Amount"}
                      </label>
                      <input
                        className="form-control mt-2"
                        id="outstanding_amount"
                        name="outstanding_amount"
                        type="text"
                        {...register(`outstanding_amount`)}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-footer text-end">
            <button
              type="button"
              className="btn button-primary"
              onClick={handleSubmit(onSubmit)}
              disabled={lockBtn}

            >
              {constLabel?.lbl_save ? constLabel?.lbl_save : "Save"}
              {lockBtn ? (
                <Spinner animation="border" size="sm" className="ms-2" />
              ) : null}
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Alter_Sales_Order;
