import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import { Typeahead } from "react-bootstrap-typeahead";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getProdCategory,
  getCustomer,
  getProduct_Family,
  createRawMaterialProduct,
  getUOM,
  getProdList,
  getMaterialType,
  updateRawMaterialProduct,
  updateProduct,
  saveProduct,
  gethsn_master,
  get_store,
  getLocation,
  createMarketGroup,
  createProduct_Family,
  createProdCategory,
} from "../../../../../api";
import { getLabels } from "../../../../../common/Functions/CommonFunctions";
import info from "../../../../../assets/images/info.png";
import { Tooltip } from "react-tooltip";
import { Trash2 } from "react-feather";
import AddProductCategory from "../../../AddMasters/AddProductCategory";
import AddProductFamily from "../../../AddMasters/AddProductFamily";
import { APPAREL, BAR, CASTING, FASHION, MANUFACTURING, PURCHASE } from "../../../../../common/Constants/constants";
import AddStore from "../../../AddMasters/AddStore";

const schema = yup
  .object()
  .shape({
    unit: yup
      .array()
      .min(1, "Please select unit of measurement!")
      .required("Unit of measurement is required!"),
    // hsn: yup
    //   .string()
    //   .matches(/^[a-zA-Z0-9]*$/, "Enter valid HSN")
    //   .nullable(true),
    openingStock: yup
      .string()
      .transform((value, originalValue) =>
        originalValue.trim() === "" ? null : value
      )
      .matches(/^\d+(\.\d+)?$/, "Enter valid opening stock")
      .nullable(),
    // materialType: yup
    //   .array()
    //   .min(1, "Please select material type")
    //   .required("Material type is required"),
    store: yup
      .array()
      .min(1, "Please select default store")
      .required("Default store is required"),
    // diameter: yup
    //   .string()
    //   .matches(/^\d+(\.\d+)?$/, "Enter valid diameter")
    //   .nullable(true),
    // diameter: yup
    //   .string()
    //   .transform((value, originalValue) =>
    //     originalValue.trim() === "" ? null : value
    //   )
    //   .matches(/^\d+(\.\d+)?$/, "Enter valid diameter")
    //   .nullable()
    //   .when(["materialType"], (materialType, schema) => {
    //     if (materialType !== undefined && materialType.length > 0) {
    //       const machineTypeSelected =
    //         materialType[0].material_type.includes("BAR");

    //       return machineTypeSelected
    //         ? schema
    //             .matches(/^\d+(\.\d+)?$/, "Enter valid diameter")
    //             .required("Diameter is required !")
    //         : schema;
    //     } else {
    //       return schema;
    //     }
    //   }),
    // cutLength: yup
    //   .string()
    //   .transform((value, originalValue) => originalValue.trim() === '' ? null : value)
    //   .matches(/^\d+(\.\d+)?$/, "Enter valid cut length")
    //   .nullable(),
    // cutLength: yup
    //   .string()
    //   .transform((value, originalValue) =>
    //     originalValue.trim() === "" ? null : value
    //   )
    //   .matches(/^\d+(\.\d+)?$/, "Enter valid length")
    //   .nullable()
    //   .when(["materialType"], (materialType, schema) => {
    //     if (materialType !== undefined && materialType.length > 0) {
    //       const machineTypeSelected =
    //         materialType[0].material_type.includes("BAR");

    //       return machineTypeSelected
    //         ? schema
    //             .matches(/^\d+(\.\d+)?$/, "Enter valid length")
    //             .required("Length is required !")
    //         : schema;
    //     } else {
    //       return schema;
    //     }
    //   }),
    // cutWeight: yup
    //   .string()
    //   .transform((value, originalValue) => originalValue.trim() === '' ? null : value)
    //   .matches(/^\d+(\.\d+)?$/, "Enter valid cut weight")
    //   .nullable(),
    // cutWeight: yup
    //   .string()
    //   .transform((value, originalValue) =>
    //     originalValue.trim() === "" ? null : value
    //   )
    //   .matches(/^\d+(\.\d+)?$/, "Enter cut weight")
    //   .nullable()
    //   .when(["materialType"], (materialType, schema) => {
    //     if (materialType !== undefined && materialType.length > 0) {
    //       const machineTypeSelected =
    //         materialType[0].material_type.includes("BAR");

    //       return machineTypeSelected
    //         ? schema
    //             .matches(/^\d+(\.\d+)?$/, "Enter valid cut weight")
    //             .required("Cut weight is required !")
    //         : schema;
    //     } else {
    //       return schema;
    //     }
    //   }),
    productName: yup.string().required("Product name is required !"),
    machineCost: yup
      .string()
      .nullable()
      .transform((value, originalValue) =>
        originalValue.trim() === "" ? null : value
      )
      .matches(/^\d+(\.\d+)?$/, "Enter valid cost of manufacturing"),
    // selling_price: yup
    //   .string()
    //   .required("Selling price is required !")
    //   .matches(/^\d+(\.\d+)?$/, "Enter valid selling price"),
    // purchase_price: yup
    //   .string()
    //   .required("Procurement price is required !")
    //   .matches(/^\d+(\.\d+)?$/, "Enter valid procurement price"),
    can_be_sold: yup.string().required("Required"),
    can_be_purchased: yup.string().required("Required"),
    // can_be_expensed: yup
    //   .string()
    //   .required("Required"),
    OEMnumber: yup
      .string()
      .nullable()
      .transform((value, originalValue) =>
        originalValue.trim() === "" ? null : value
      ),
    // .matches(/^[a-zA-Z0-9\s]*$/, 'Enter a valid OEM part no'),
    productFamily: yup
      .array()
      .required("Please select product family")
      .min(1, "Please select product family"),
    reorder_qty: yup
      .string()
      .transform((value, originalValue) => originalValue.trim() === '' ? null : value)
      .matches(/^\d+(\.\d+)?$/, 'Enter valid reorder qty')
      .nullable(),
  })
  .required();

function RawMaterial(props) {
  let navigate = useNavigate();
  let location = useLocation();

  const [prodCategory, setProdCategory] = useState({
    data: [],
    loading: false,
  });
  const [prodFamily, setProdFamily] = useState({ data: [], loading: false });
  const [uomList, setUOMList] = useState({ data: [], loading: false });
  const [hsnList, setHSNList] = useState({ data: [], loading: false });
  const [createBom, setCreateBom] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);

  const [matType, setMatType] = useState({ data: [], loading: false });
  const [filterConfig, setFilterConfig] = useState({ hsn_sac_code: "" });

  const [constLabel, setConstLabel] = useState({});
  const [storeList, setStoreList] = useState({ data: [], loading: false });

  const rmi_config = JSON.parse(localStorage.getItem("configuration"));

  const { register, handleSubmit, formState, control, reset, watch, setValue, setError, clearErrors } =
    useForm({
      resolver: yupResolver(schema),
      mode: "onChange",
    }); // initialise the hook

  let { errors } = formState;
  let selUnit = watch("unit");
  let selCategory = watch("category");
  let selMaterialType = watch("materialType");

  const localData = JSON.parse(localStorage.getItem("localData"));

  useEffect(() => {
    setConstLabel(getLabels("Master", "Product"));
    fetchProductCategory();
    fetchProductFamily();
    fetchMaterialType();
    fetchUOM();
    // fetchStore(null);
    // fetchHSN();
    if(localData && localData?.organizations?.length > 0){
      const def_org = localData?.organizations?.filter((item) =>  item.default_org == true);
        getLocation({company_id: def_org?.[0]?.uuid}).then(
            (res) => {
              // console.log("loc res", res);
              const def_loc = res.data.data.filter(
                (item) => item.default_location == true
              );
              fetchStore(def_loc?.[0]?.uuid);
            },
            (err) => {
              // console.log("err", err);
              fetchStore(null);
            }
          );
    } else {
      fetchStore(null);
    }
  }, []);

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      fetchHSN();
    }, 1000); // Adjust the delay as needed (e.g., 500 milliseconds)

    return () => {
      clearTimeout(debounceTimeout); // Clear the timeout if searchTerm changes before the timeout expires
    };
  }, [filterConfig]);


  function fetchProdList(product_uuid) {
    getProdList({ uuid: product_uuid }).then(
      (res) => {
        props.setMain_obj({
          ...props.main_obj,
          prod_overview: res.data.data[0],
        });
        props.nextStep();
      },
      (err) => {}
    );
  }

  function fetchMaterialType() {
    setMatType({ ...matType, data: [], loading: true });
    getMaterialType().then(
      (res) => {
        if(res && res.data.data?.length > 0){
          setMatType({ ...matType, data: res.data.data, loading: false });
          // if(location && location.pathname !== "/pcms/editProductConfigurator"){
          //   setValue("materialType", [res.data.data?.[0]]);
          // }
        } else {
          setMatType({ ...matType, data: [], loading: false });
        }  
      },
      (err) => {
        setMatType({ ...matType, data: [], loading: false });
      }
    );
  }

  function fetchHSN() {
    setHSNList({ ...hsnList, data: [], loading: true });
    let payload = {
      pageNo: 1,
      entriesPerPage: 1000,
    };
    if (
      filterConfig.hsn_sac_code !== "" &&
      filterConfig.hsn_sac_code.length >= 4
    ) {
      payload.hsn_sac_code = filterConfig.hsn_sac_code.trim(); // Search by hsn sac code
      gethsn_master(payload).then(
        (res) => {
          setHSNList({ ...hsnList, data: res.data.data, loading: false });
        },
        (err) => {
          setHSNList({ ...hsnList, data: [], loading: false });
        }
      );
    } else {
      // delete payload.hsn_sac_code;
      setHSNList({ ...hsnList, data: [], loading: false });
    }
  }

  function fetchUOM() {
    setUOMList({ ...uomList, data: [], loading: true });
    getUOM({ pageNo: 1, entriesPerPage: 100 }).then(
      (res) => {
        if(res && res.data.data?.length > 0){
          setUOMList({ ...uomList, data: res.data.data, loading: false });
          
          // if(location && location.pathname !== "/pcms/editProductConfigurator"){
          //   setValue("unit", [res.data.data?.[0]]);
          // }
        } else {
          setUOMList({ ...uomList, data: [], loading: false });
        } 
      },
      (err) => {
        setUOMList({ ...uomList, data: [], loading: false });
      }
    );
  }

  function fetchProductCategory() {
    setProdCategory({ ...prodCategory, data: [], loading: true });
    getProdCategory().then(
      (res) => {
        if(res && res.data.data?.length > 0){
          setProdCategory({
            ...prodCategory,
            data: res.data.data,
            loading: false,
          });

          // if(location && location.pathname !== "/pcms/editProductConfigurator"){
          //   setValue("category", [res.data.data?.[0]]);
          // }
        } else {
          setProdCategory({ ...prodCategory, data: [], loading: false });
        }
      },
      (err) => {
        setProdCategory({ ...prodCategory, data: [], loading: false });
      }
    );
  }

  function fetchProductFamily() {
    setProdFamily({ ...prodFamily, data: [], loading: true });
    getProduct_Family().then(
      (res) => {
        if(res && res.data.data?.length > 0){
          setProdFamily({
            ...prodFamily,
            data: res.data.data,
            loading: false,
          });

          // if(location && location.pathname !== "/pcms/editProductConfigurator"){
          //   setValue("productFamily", [res.data.data?.[0]]);
          // }

        } else {
          setProdFamily({ ...prodFamily, data: [], loading: false });
        }
      },
      (err) => {
        setProdFamily({ ...prodFamily, data: [], loading: false });
      }
    );
  }

  function fetchStore(loc_uuid, isNew = false) {
    setStoreList({ ...storeList, data: [], loading: true });

    get_store().then(
      (res) => {
        // if (res && res.data.data.length > 0) {
        //   setStoreList({ ...storeList, data: res.data.data, loading: false });
        // }

        if (res && res.data.data.length > 0) {
          // const result = res.data.data.map((item) => ({
          //   ...item,
          //   storeAndLocation: item.location_id_id?.locationname
          //     ? `${item.name} - ${item.location_id_id.locationname}`
          //     : item.name,
          // }));
          const result = res.data.data.map((item) => ({
            ...item,
            storeAndLocation: [
              item.parent_id_id !== null ? item.parent_id_id?.name : null, // Include parent name if it exists
              item.name, // Always include item name
            ]
              .filter(Boolean) // Removes null, undefined, or empty values
              .join('/') // Join parent name and item name with " / "
              + (item.location_id_id?.locationname ? ` - (${item.location_id_id.locationname})` : ''), // Add location name if it exists with " - "
          }));

          // if(location && location.pathname !== "/pcms/editProductConfigurator"){
          //   if(loc_uuid){
          //     const loc_stores = result?.filter((item) => item.location_id == loc_uuid);
          //     const purchaseStore = loc_stores?.filter((item) => item.name == PURCHASE);
          //     if (purchaseStore) {
          //       setValue("store", purchaseStore);
          //     }
          //   }
          // }

          if (
            props.main_obj.prod_overview?.store_id !== undefined &&
            props.main_obj.prod_overview?.store_id !== null
          ) {
            const setRes = result.filter(
              (item) =>
                item.uuid == props.main_obj.prod_overview?.store_id?.uuid
            );
            setValue("store", setRes);
          }

          if(isNew){
            setValue("store", [result[0]]);
          }

          setStoreList({ ...storeList, data: result, loading: false });

        } else {
          setStoreList({ ...storeList, data: [], loading: false });
        }
      },
      (err) => {
        setStoreList({ ...storeList, data: [], loading: false });
      }
    );
  }

  // const onSubmit = (data) => {
  //   props.setCurrentStep(props.currentStep + 1);
  // };

  const onSubmit = (data) => {
    // Check if the industry type is FASHION or APPAREL
    if ([FASHION, APPAREL].includes(localData?.industry_type)) {
      // If the category is empty, set an error for the category field
      if (!data.category?.length) {
        setError("category", {
          type: "custom",
          message: "Please select the category!",
        });
        return; // Stop further execution if validation fails
      }
    }

    if ([MANUFACTURING].includes(localData?.industry_type)) {
      // If the category is empty, set an error for the category field
      if (!data.materialType?.length) {
        setError("materialType", {
          type: "custom",
          message: "Please select the material type!",
        });
        return; // Stop further execution if validation fails
      }
    }

    if (data.can_be_sold === "true") {
      if (data.selling_price === "" || !/^\d+(\.\d+)?$/.test(data.selling_price)) {
        setError("selling_price", {
          type: "custom",
          message: data.selling_price === ""
            ? "Please enter selling price!"
            : "Enter a valid value!",
        });
        return; // Stop further execution if validation fails
      }
    }

    if (data.can_be_purchased === "true") {
      if (data.purchase_price === "" || !/^\d+(\.\d+)?$/.test(data.purchase_price)) {
        setError("purchase_price", {
          type: "custom",
          message: data.purchase_price === ""
            ? "Please enter procurement price!"
            : "Enter a valid value!",
        });
        return; // Stop further execution if validation fails
      }
    }

    setBtnDisable(true);

    if (data !== "") {
      const reqPayload = {
        productType: props.product_type?.prodTypeId,
        productTypename: props.product_type?.productType,
        product_type_id: props.product_type?.uuid,
        category:
          data.category?.length > 0 ? data.category?.[0]?.categoryname : null,
        category_id: data.category?.length > 0 ? data.category?.[0]?.uuid : null,
        prodfamily:
          data.productFamily?.length > 0
            ? data.productFamily?.[0]?.familyId
            : null,
        prod_family_id:
          data.productFamily?.length > 0 ? data.productFamily?.[0]?.uuid : null,
        productName: data.productName,
        openingstock: data.openingStock,
        hsn: data.hsn?.length > 0 ? data.hsn?.[0]?.hsn_sac_code : null,
        hsn_uuid: data.hsn?.length > 0 ? data.hsn?.[0]?.uuid : null,
        OEMnumber: data.OEMnumber,
        material_type_id: data.materialType?.length > 0 ? data.materialType?.[0]?.uuid : null,
        // material: data.material,
        diameter: data.diameter,
        cutlength: data.cutLength,
        cutweight: data.cutWeight,
        store_id: data.store ? data.store?.[0]?.uuid : null,
        partNo: data.OEMnumber,
        machineCost: data.machineCost !== null ? data.machineCost : "0.00",
        can_be_sold: data.can_be_sold,
        can_be_purchased: data.can_be_purchased,
        // can_be_expensed: data.can_be_expensed,
        // beacon_id: data.beacon_id,
        // qr_code_id: data.qr_code_id,
        selling_price: data.can_be_sold == "true" ? data.selling_price : "0.00",
        purchase_price: data.can_be_purchased ==  "true" ? data.purchase_price : "0.00",
        unit_id: data.unit ? data.unit?.[0]?.uuid : null,
        remark: data.remark,
        // party: [],
        batch_tracking: data.batch_tracking,
        reorder_qty: data.reorder_qty !== null ? data.reorder_qty : 0,
      };

      if (props.main_obj.prod_overview?.uuid) {
        reqPayload.uuid = props.main_obj.prod_overview.uuid;

        // updateRawMaterialProduct(reqPayload).then(
        updateProduct(reqPayload).then(
          (res) => {
            setBtnDisable(false);
            if (res.status === 200 || res.status === 201) {
              toast.success("Raw material product updated successfully !", {
                autoClose: 2000,
              });

              setTimeout(() => {
                // fetchProdList(res.data.data?.[0]?.product_serializer?.uuid);
                fetchProdList(res.data.updatedRows?.[0]?.uuid);
              }, [2300]);
            }
          },
          (err) => {
            setBtnDisable(false);
            if (err.response.status === 400) {
              toast.error(err.response.data.message, {
                autoClose: 2000,
              });
            }
          }
        );
      } else {
        // createRawMaterialProduct(reqPayload).then(
        saveProduct(reqPayload).then(
          (res) => {
            setBtnDisable(false);

            if (res.status === 200 || res.status === 201) {
              toast.success("Raw material product saved successfully !", {
                autoClose: 2000,
              });

              props.setCreatedProd(res.data.result);

              setTimeout(() => {
                // fetchProdList(res.data.uuid);
                fetchProdList(res.data.result.uuid);
              }, [2300]);
            }
          },
          (err) => {
            setBtnDisable(false);

            if (err.response.status === 400) {
              toast.error(err.response.data.message, {
                autoClose: 2000,
              });
            }
          }
        );
      }
    } else {
      errors.showMessages();
    }
  };

  function handleCheckBoxChange(e, stringName){

    const isChecked = e.target.checked;

    if (isChecked) {
      // Make the field required with regex validation
      setError(stringName, {
        type: "manual",
        message: `${stringName == "selling_price" ? "Selling price" : "Procurement price"} is required.`,
      });
    } else {
      // Only regex validation
      setValue(stringName, "", {shouldValidate: true});
      clearErrors(stringName);
    }
  }

  const handleInputChange = (e, fieldName, regex) => {
    const value = e.target.value;
  
    if (regex.test(value)) {
      // Valid input
      clearErrors(fieldName);
    } else {
      // Invalid input
      setError(fieldName, {
        type: "manual",
        message: "Enter a valid value.",
      });
    }
  };

  function addProductCategory(data){
      if (data !== "") {
        const reqPayload = {
          product_type: Object.keys(props.product_type).length > 0 ? props.product_type?.uuid : null,
          categoryname: data,
        };
        createProdCategory(reqPayload).then(
          (res) => {
            // console.log("res", res);
            if (res.status === 200 || res.status === 201) {
              toast.success("Product category created successfully !", {
                autoClose: 1000,
              });
              setTimeout(() => {
                fetchProductCategory();
              }, 1300);
              
              setValue("category", [res.data], { shouldValidate: true });
            }
          },
          (err) => {
            if (err.response.status === 400) {
              toast.error(err.response.data.message, {
                autoClose: 2000,
              });
              reset();
            }
          }
        );
      } else {
        errors.showMessages();
      }
  }
  
  function addProductFamily(data){
    if (data !== "") {
      const reqPayload = {
        product_type: Object.keys(props.product_type).length > 0 ? props.product_type?.uuid : null,
        product_category_id: selCategory?.length > 0 ? selCategory?.[0]?.uuid : null,
        familyname: data,
      };
      createProduct_Family(reqPayload).then(
        (res) => {
          // console.log("res", res);
          if (res.status === 200 || res.status === 201) {
            toast.success("Product family created successfully !", {
              autoClose: 1000,
            });
            setTimeout(() => {
              fetchProductFamily();
            }, 1300);
            
            setValue("productFamily", [res.data], { shouldValidate: true });
          }
        },
        (err) => {
          if (err.response.status === 400) {
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
            reset();
          }
        }
      );
    } else {
      errors.showMessages();
    }
  }

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="card shadow-sm rounded-0 p-2">
        <div className="card-body">
          <p className="pc_card_title roboto-bold">
            {constLabel?.lbl_product_summary
              ? constLabel?.lbl_product_summary
              : "Product Summary"}
          </p>
          <div className="row">
            <div className="col-md-3">
              <div className="form-group typeahead-container mt-md-0 mt-3">
                <label className="lbl-style roboto-bold d-flex align-items-center">
                  {constLabel?.lbl_product_category
                    ? constLabel?.lbl_product_category
                    : "Product Category"}
                    {[FASHION, APPAREL].includes(localData?.industry_type) ? <span className="text-danger"> *</span> : null }
                  <Tooltip
                    id={"open-tooltip1"}
                    place="top"
                    style={{ zIndex: 9999 }}
                  />
                  <img
                    src={info}
                    alt="info"
                    className="cursor_pointer"
                    data-tooltip-id={"open-tooltip1"}
                    data-tooltip-html="To classify products into distinct categories for easier<br />
                        management, searchability, and reporting within the system."
                    height={20}
                    width={20}
                  />
                  {/* <AddProductCategory fetchProductCategory={fetchProductCategory} type={props.product_type}/> */}
                </label>
                <Controller
                  name="category"
                  control={control}
                  defaultValue={
                    props.main_obj?.prod_overview?.category_id !== undefined &&
                    props.main_obj?.prod_overview?.category_id !== null &&
                    props.main_obj.prod_overview.category_id !== null
                      ? [props.main_obj.prod_overview?.category_id]
                      : []
                  }
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="categoryname"
                      className="mt-2"
                      multiple={false}
                      options={prodCategory.data}
                      isLoading={prodCategory.loading}
                      defaultSelected={
                        props.main_obj?.prod_overview?.category_id !==
                          undefined &&
                        props.main_obj?.prod_overview?.category_id !== null &&
                        props.main_obj.prod_overview.category_id !== null
                          ? [props.main_obj.prod_overview?.category_id]
                          : []
                      }
                      placeholder="Select product category"
                      // onChange={onChange}
                      onChange={(selected) => {
                        // If the new option is added
                        const isNewOption = selected && selected[0]?.customOption;
                        if (isNewOption) {
                          const newItem = selected[0]?.categoryname;
                          // Fire API to add the new item
                          addProductCategory(newItem);
                        }
                        onChange(selected);
                      }}
                      newSelectionPrefix="Add New: "
                      allowNew  
                      selected={value}
                      onBlur={onBlur}
                      isInvalid = {[FASHION, APPAREL].includes(localData?.industry_type) ? !!errors.category : null}
                    />
                  )}
                />
                {!prodCategory.loading && !errors.category && <span className="arrow-icon"></span>}
                {[FASHION, APPAREL].includes(localData?.industry_type) && errors.category && (
                  <span className="text-danger err-msg">
                    {errors.category.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group typeahead-container mt-md-0 mt-3">
                <label className="lbl-style roboto-bold d-flex align-items-center">
                  {constLabel?.lbl_product_family
                    ? constLabel?.lbl_product_family
                    : "Product Family"}
                  <span className="text-danger"> *</span>
                  <Tooltip
                    id={"open-tooltip2"}
                    place="top"
                    style={{ zIndex: 9999 }}
                  />
                  <img
                    src={info}
                    alt="info"
                    className="cursor_pointer"
                    data-tooltip-id={"open-tooltip2"}
                    data-tooltip-html=" Groups products that share common characteristics, functionalities, or manufacturing processes.<br /> 
                       This field helps categorize products into broader families, such as similar types of finished goods,<br /> 
                       components, or raw materials, enabling more efficient inventory management, reporting, and product<br />
                       lifecycle tracking."
                    height={20}
                    width={20}
                  />
                  {/* <AddProductFamily fetchProductFamily={fetchProductFamily} type={props.product_type} category={selCategory} /> */}
                </label>
                <Controller
                  name="productFamily"
                  control={control}
                  defaultValue={
                    props.main_obj.prod_overview.prod_family_id !== undefined &&
                    props.main_obj.prod_overview.prod_family_id !== null
                      ? [props.main_obj.prod_overview?.prod_family_id]
                      : []
                  }
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="familyname"
                      className="mt-2"
                      multiple={false}
                      options={prodFamily.data}
                      isLoading={prodFamily.loading}
                      defaultSelected={
                        props.main_obj.prod_overview.prod_family_id !==
                          undefined &&
                        props.main_obj.prod_overview.prod_family_id !== null
                          ? [props.main_obj.prod_overview?.prod_family_id]
                          : []
                      }
                      placeholder="Select product family"
                      // onChange={onChange}
                      onChange={(selected) => {
                        // If the new option is added
                        const isNewOption = selected && selected[0]?.customOption;
                        if (isNewOption) {
                          const newItem = selected[0]?.familyname;
                          // Fire API to add the new item
                          addProductFamily(newItem);
                        }
                        onChange(selected);
                      }}
                      newSelectionPrefix="Add New: "
                      allowNew 
                      selected={value}
                      onBlur={onBlur}
                      isInvalid={errors.productFamily ? true : false}
                    />
                  )}
                />
                {!prodFamily.loading && !errors.productFamily && <span className="arrow-icon"></span>}
                {errors.productFamily && (
                  <span className="text-danger err-msg">
                    {errors.productFamily.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_product_name
                    ? constLabel?.lbl_product_name
                    : "Product Name"}
                  <span className="text-danger">*</span>
                </label>
                <input
                  className={
                    errors.productName
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Enter product name"
                  name="productName"
                  defaultValue={
                    props.main_obj.prod_overview.productName !== null &&
                    props.main_obj.prod_overview.productName !== ""
                      ? props.main_obj.prod_overview.productName
                      : ""
                  }
                  {...register("productName")}
                />
                {errors.productName && (
                  <span className="text-danger err-msg">
                    {errors.productName.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group typeahead-container mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                {constLabel?.lbl_unit_of_measurement ? constLabel?.lbl_unit_of_measurement : "Unit Of Measurement"}
                  <span className="text-danger"> *</span>
                </label>
                <Controller
                  name="unit"
                  control={control}
                  defaultValue={
                    props.main_obj.prod_overview.unit_id !== undefined &&
                    props.main_obj.prod_overview.unit_id !== null
                      ? [props.main_obj.prod_overview?.unit_id]
                      : []
                  }
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      className="mt-2"
                      labelKey="uom"
                      multiple={false}
                      options={uomList.data}
                      isLoading={uomList.loading}
                      placeholder="Select unit of measurement"
                      defaultSelected={
                        props.main_obj.prod_overview.unit_id !== undefined &&
                        props.main_obj.prod_overview.unit_id !== null
                          ? [props.main_obj.prod_overview?.unit_id]
                          : []
                      }
                      onChange={onChange}
                      selected={value}
                      onBlur={onBlur}
                      isInvalid={errors.unit ? true : false}
                    />
                  )}
                />
                {!uomList.loading && !errors.unit && <span className="arrow-icon"></span>}
                {errors.unit && (
                  <span className="text-danger err-msg">
                    {errors.unit.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_opening_stock
                    ? constLabel?.lbl_opening_stock
                    : "Opening Stock"}{" / "}{selUnit && selUnit[0]?.uom}
                </label>
                <input
                  className="form-control mt-2"
                  type="text"
                  placeholder="Enter opening stock"
                  defaultValue={
                    props.main_obj.prod_overview.openingstock !== null &&
                    props.main_obj.prod_overview.openingstock !== ""
                      ? props.main_obj.prod_overview.openingstock
                      : 0
                  }
                  name="openingStock"
                  {...register("openingStock")}
                />
                {errors.openingStock && (
                  <span className="text-danger err-msg">
                    {errors.openingStock.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_reorder_qty
                    ? constLabel?.lbl_reorder_qty
                    : "Reorder Qty"}
                </label>
                <input
                  className="form-control mt-2"
                  type="text"
                  defaultValue={
                    props.main_obj.prod_overview.reorder_qty !== null &&
                      props.main_obj.prod_overview.reorder_qty !== ""
                      ? props.main_obj.prod_overview.reorder_qty
                      : 0
                  }
                  placeholder="Enter reorder qty"
                  name="reorder_qty"
                  {...register("reorder_qty")}
                />
                {errors.reorder_qty && (
                  <span className="text-danger err-msg">
                    {errors.reorder_qty.message}
                  </span>
                )}
              </div>
            </div>

            {/* <div className="col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">{constLabel?.lbl_hsn ? constLabel?.lbl_hsn : "HSN"}</label>
                <input
                  className="form-control mt-2"
                  type="text"
                  placeholder="Enter hsn"
                  name="hsn"
                  defaultValue={(props.main_obj.prod_overview.hsn !== null && props.main_obj.prod_overview.hsn !== "") ? props.main_obj.prod_overview.hsn : ""}
                  {...register("hsn")}
                />
                {errors.hsn && (
                  <span className="text-danger err-msg">
                    {errors.hsn.message}
                  </span>
                )}
              </div>
            </div> */}

            {/* HSN select box */}
            <div className="col-md-3 mt-3">
              <div className="form-group typeahead-container mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_hsn ? constLabel?.lbl_hsn : "HSN"}
                  {/* <span className="text-danger"> *</span> */}
                  <Tooltip id={"open-tooltip2"} place="top" style={{ zIndex: 9999 }}/>
                  <img src={info} alt='info' className='cursor_pointer'
                    data-tooltip-id={"open-tooltip2"}
                    data-tooltip-html="Enter four digits to search hsn for selection."
                    height={20} width={20} />
                </label>
                <Controller
                  name="hsn"
                  control={control}
                  defaultValue={
                    props.main_obj.prod_overview.hsn_uuid !== undefined &&
                    props.main_obj.prod_overview.hsn_uuid !== null
                      ? [props.main_obj.prod_overview?.hsn_uuid]
                      : []
                  }
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="hsn_sac_code"
                      className="mt-2"
                      multiple={false}
                      options={hsnList.data}
                      isLoading={hsnList.loading}
                      placeholder="Select hsn"
                      onChange={onChange}
                      defaultSelected={
                        props.main_obj.prod_overview.hsn_uuid !== undefined &&
                        props.main_obj.prod_overview.hsn_uuid !== null
                          ? [props.main_obj.prod_overview?.hsn_uuid]
                          : []
                      }
                      selected={value}
                      onBlur={onBlur}
                      // isInvalid={errors.hsn ? true : false}
                      onInputChange={(text) =>
                        setFilterConfig({ ...filterConfig, hsn_sac_code: text })
                      }
                    />
                  )}
                />
                {!hsnList.loading && !errors.hsn && <span className="arrow-icon"></span>}
                {/* {errors.hsn && (
                  <span className="text-danger err-msg">
                    {errors.hsn.message}
                  </span>
                )} */}
              </div>
            </div>
            {/* HSN select box */}

            <div className="col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_oem_part_no
                    ? constLabel?.lbl_oem_part_no
                    : "OEM Part No"}
                    <Tooltip id={"open-tooltip2"} place="top" style={{ zIndex: 9999 }}/>
                    <img src={info} alt='info' className='cursor_pointer'
                      data-tooltip-id={"open-tooltip2"}
                      data-tooltip-html="This field referred to enter part no, product code, OEM part no."
                      height={20} width={20} />
                </label>
                <input
                  className={
                    errors.OEMnumber
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Enter part no / product code"
                  name="OEMnumber"
                  defaultValue={
                    props.main_obj.prod_overview.OEMnumber !== null &&
                    props.main_obj.prod_overview.OEMnumber !== ""
                      ? props.main_obj.prod_overview.OEMnumber
                      : ""
                  }
                  {...register("OEMnumber")}
                />
                {errors.OEMnumber && (
                  <span className="text-danger err-msg">
                    {errors.OEMnumber.message}
                  </span>
                )}
              </div>
            </div>

            {/* <div className="col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {"Part No"}
                <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control mt-2"
                  type="text"
                  name="partNo"
                  placeholder="Enter part no"
                  {...register("partNo")}
                />

                {errors.customerPartNo && (
                  <span className="text-danger err-msg">
                    {errors.customerPartNo.message}
                  </span>
                )}
              </div>
            </div> */}
          </div>

          <hr className="my-4" />
          <p className="pc_card_title roboto-bold">
          {constLabel?.lbl_product_information
              ? constLabel?.lbl_product_information
              : "Product Information"}
          </p>
          <div className="row col-12">
          {rmi_config.isMaterialType ?
            <div className="col-md-3">
              <div className="form-group typeahead-container mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_material_type
                    ? constLabel?.lbl_material_type
                    : "Material Type"}
                  <span className="text-danger"> *</span>
                </label>
                <Controller
                  name="materialType"
                  control={control}
                  defaultValue={
                    props.main_obj.prod_overview.material_type_id !==
                      undefined &&
                    props.main_obj.prod_overview.material_type_id !== null
                      ? [props.main_obj.prod_overview?.material_type_id]
                      : []
                  }
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="material_type"
                      className="mt-2"
                      multiple={false}
                      options={matType.data}
                      isLoading={matType.loading}
                      placeholder="Select material type"
                      onChange={onChange}
                      selected={value}
                      defaultSelected={
                        props.main_obj.prod_overview.material_type_id !==
                          undefined &&
                        props.main_obj.prod_overview.material_type_id !== null
                          ? [props.main_obj.prod_overview?.material_type_id]
                          : []
                      }
                      onBlur={onBlur}
                      isInvalid={errors.materialType ? true : false}
                    />
                  )}
                />
                {!matType.loading && !errors.materialType && <span className="arrow-icon"></span>}
                {errors.materialType && (
                  <span className="text-danger err-msg">
                    {errors.materialType.message}
                  </span>
                )}
              </div>
            </div>
          : null }

            {/* <div className="col-md-3">
              <div className="form-group">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_material_name
                    ? constLabel?.lbl_material_name
                    : "Material Name"}
                </label>
                <input
                  className="form-control mt-2"
                  type="text"
                  placeholder="Enter material name"
                  name="material"
                  defaultValue={
                    props.main_obj.prod_overview.material !== null &&
                    props.main_obj.prod_overview.material !== ""
                      ? props.main_obj.prod_overview.material
                      : ""
                  }
                  {...register("material")}
                />
              </div>
            </div> */}

          {[MANUFACTURING].includes(localData.industry_type) ? 
            <div className="col-md-3">
              <div className="form-group">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_diameter
                    ? constLabel?.lbl_diameter
                    : "Diameter"}
                </label>
                {selMaterialType && selMaterialType?.[0]?.material_type == BAR ? <span className="text-danger"> *</span> : null}
                <input
                  className={
                    errors.diameter
                      ? "form-control  is-invalid mt-2"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Enter diameter"
                  name="diameter"
                  defaultValue={
                    props.main_obj.prod_overview.diameter !== null &&
                    props.main_obj.prod_overview.diameter !== ""
                      ? props.main_obj.prod_overview.diameter
                      : 0
                  }
                  {...register("diameter")}
                  onChange={(e) => {
                    handleInputChange(e, "diameter", /^\d+(\.\d+)?$/);
                  }}
                />
                {errors.diameter && (
                  <span className="text-danger err-msg">
                    {errors.diameter.message}
                  </span>
                )}
              </div>
            </div>
          : null }

            <div className="col-md-3">
              <div className="form-group">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_length
                    ? constLabel?.lbl_length
                    : "Length"}
                </label>
                {selMaterialType && selMaterialType?.[0]?.material_type == BAR ? <span className="text-danger"> *</span> : null}
                <input
                  className={
                    errors.cutLength
                      ? "form-control  is-invalid mt-2"
                      : "form-control mt-2 "
                  }
                  type="text"
                  placeholder="Enter length / size"
                  defaultValue={
                    props.main_obj.prod_overview.cutlength !== null &&
                    props.main_obj.prod_overview.cutlength !== ""
                      ? props.main_obj.prod_overview.cutlength
                      : 0
                  }
                  name="cutLength"
                  {...register("cutLength")}
                  onChange={(e) => {
                    handleInputChange(e, "cutLength", /^\d+(\.\d+)?$/);
                  }}
                />
                {errors.cutLength && (
                  <span className="text-danger err-msg">
                    {errors.cutLength.message}
                  </span>
                )}
              </div>
            </div>
          
          {[MANUFACTURING].includes(localData.industry_type) ? 
            <div className="col-md-3">
              <div className="form-group">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_cut_weight
                    ? constLabel?.lbl_cut_weight
                    : "Cut Weight"}
                </label>
                {selMaterialType && selMaterialType?.[0]?.material_type == BAR ? <span className="text-danger"> *</span> : null}
                <input
                  className={
                    errors.cutWeight
                      ? "form-control is-invalid mt-2"
                      : "form-control mt-2 "
                  }
                  type="text"
                  placeholder="Enter cut weight"
                  defaultValue={
                    props.main_obj.prod_overview.cutweight !== null &&
                    props.main_obj.prod_overview.cutweight !== ""
                      ? props.main_obj.prod_overview.cutweight
                      : 0
                  }
                  name="cutWeight"
                  {...register("cutWeight")}
                  onChange={(e) => {
                    handleInputChange(e, "cutWeight", /^\d+(\.\d+)?$/);
                  }}
                />
                {errors.cutWeight && (
                  <span className="text-danger err-msg">
                    {errors.cutWeight.message}
                  </span>
                )}
              </div>
            </div>
          : null }

            <div className={`col-md-3 ${rmi_config.isMaterialType ? "mt-3" : ""}`}>
              <div className="form-group typeahead-container mt-md-0 mt-3">
                <label className="lbl-style roboto-bold d-flex align-items-center">
                  {constLabel?.lbl_store ? constLabel?.lbl_store : "Store"}
                  <span className="text-danger"> *</span>{" "}
                  <AddStore fetchStore={fetchStore} isNew={true} />
                </label>
                <Controller
                  name="store"
                  control={control}
                  // defaultValue={(props.main_obj.prod_overview.store_id !== undefined && props.main_obj.prod_overview.store_id !== null) ? [props.main_obj.prod_overview?.store_id] : []}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="storeAndLocation"
                      className="mt-2"
                      multiple={false}
                      options={storeList.data}
                      isLoading={storeList.loading}
                      placeholder="Select store"
                      onChange={onChange}
                      selected={value}
                      // defaultSelected={(props.main_obj.prod_overview.store_id !== undefined && props.main_obj.prod_overview.store_id !== null) ? [props.main_obj.prod_overview?.store_id] : []}
                      onBlur={onBlur}
                      isInvalid={errors.store ? true : false}
                    />
                  )}
                />
                {!storeList.loading && !errors.store && <span className="arrow-icon"></span>}
                {errors.store && (
                  <span className="text-danger err-msg">
                    {errors.store.message}
                  </span>
                )}
              </div>
            </div>

            {/* <div className="col-md-3 mt-3">
              <div className="form-group">
                <label className="lbl-style roboto-bold">{constLabel?.lbl_beacon_id
                  ? constLabel?.lbl_beacon_id
                  : "Beacon Id"}</label>
                <input
                  className="form-control mt-2"
                  type="text"
                  placeholder="Enter beacon id"
                  name="beacon_id"
                  defaultValue={(props.main_obj.prod_overview.beacon_id !== null && props.main_obj.prod_overview.beacon_id !== "") ? props.main_obj.prod_overview.beacon_id : ""}
                  {...register("beacon_id")}
                />
              </div>
            </div> */}

            {/* <div className="col-md-3 mt-3">
              <div className="form-group">
                <label className="lbl-style roboto-bold">{constLabel?.lbl_qr_code_id
                  ? constLabel?.lbl_qr_code_id
                  : "QR Code Id"}</label>
                <input
                  className="form-control mt-2"
                  type="text"
                  placeholder="Enter qr code id"
                  name="qr_code_id"
                  defaultValue={(props.main_obj.prod_overview.qr_code_id !== null && props.main_obj.prod_overview.qr_code_id !== "") ? props.main_obj.prod_overview.qr_code_id : ""}
                  {...register("qr_code_id")}
                />
              </div>
            </div> */}
          </div>
          <div className="row">
            <div className="col-md-3 mt-3">
              <label className="lbl-style roboto-bold invisible">
                {constLabel?.lbl_can_be_sold
                  ? constLabel?.lbl_can_be_sold
                  : "Can be Sold"}
              </label>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultChecked={
                    props.main_obj.prod_overview.can_be_sold
                      ? props.main_obj.prod_overview.can_be_sold
                      : false
                  }
                  name="can_be_sold"
                  // onChange={(e) => handleCheckBoxChange(e, "selling_price")}
                  {...register("can_be_sold", {
                    onChange: (e) => handleCheckBoxChange(e, "selling_price"),
                  })}
                />
                <label className="form-check-label lbl-style roboto-bold">
                  {constLabel?.lbl_can_be_sold
                    ? constLabel?.lbl_can_be_sold
                    : "Can be Sold"}
                </label>
              </div>
            </div>

            <div className="col-md-3 mt-3">
              <label className="lbl-style roboto-bold invisible">
                {constLabel?.lbl_can_be_pruchase
                  ? constLabel?.lbl_can_be_pruchase
                  : "Can be Purchase"}
              </label>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="can_be_purchased"
                  defaultChecked={
                    props.main_obj.prod_overview.can_be_purchased
                      ? props.main_obj.prod_overview.can_be_purchased
                      : false
                  }
                  // {...register("can_be_purchased")}
                  {...register("can_be_purchased", {
                    onChange: (e) => handleCheckBoxChange(e, "purchase_price")
                  })}
                />
                <label className="form-check-label lbl-style roboto-bold">
                  {constLabel?.lbl_can_be_pruchase
                    ? constLabel?.lbl_can_be_pruchase
                    : "Can be Purchase"}
                </label>
              </div>
            </div>

            <div className="col-md-3 mt-3">
              <label className="lbl-style roboto-bold invisible">
                {constLabel?.lbl_batch_tracking
                  ? constLabel?.lbl_batch_tracking
                  : "Batch Tracking"}
              </label>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultChecked={
                    props.main_obj.prod_overview.batch_tracking
                      ? props.main_obj.prod_overview.batch_tracking
                      : false
                  }
                  name="batch_tracking"
                  {...register("batch_tracking")}
                />
                <label className="form-check-label lbl-style roboto-bold">
                  {constLabel?.lbl_batch_tracking
                    ? constLabel?.lbl_batch_tracking
                    : "Batch Tracking"}
                </label>
              </div>
            </div>

            {/* <div className="col-md-3 mt-3">
              <label className="lbl-style roboto-bold invisible">
                {constLabel?.lbl_can_be_expense
                  ? constLabel?.lbl_can_be_expense
                  : "Can be Expense"}
              </label>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="can_be_expensed"
                  defaultChecked={props.main_obj.prod_overview.can_be_purchased ? props.main_obj.prod_overview.can_be_purchased : false}
                  {...register("can_be_expensed")}
                />
                <label className="form-check-label lbl-style roboto-bold">
                  {constLabel?.lbl_can_be_expense
                    ? constLabel?.lbl_can_be_expense
                    : "Can be Expense"}
                </label>
              </div>
            </div> */}
          </div>

          <hr className="my-4" />
          <p className="pc_card_title roboto-bold">
          {constLabel?.lbl_pricing_details
              ? constLabel?.lbl_pricing_details
              : "Pricing Details"}
          </p>
          <div className="row col-12">
            <div className="col-md-3">
              <div className="form-group">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_cost_of_mfg
                    ? constLabel?.lbl_cost_of_mfg
                    : "Cost of Manufacturing"}
                  (<span>&#8377;</span>){" / "}{selUnit && selUnit[0]?.uom}
                </label>
                <input
                  className={
                    errors.machineCost
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Enter cost of manufacturing"
                  defaultValue={
                    props.main_obj.prod_overview.machineCost !== null &&
                    props.main_obj.prod_overview.machineCost !== ""
                      ? props.main_obj.prod_overview.machineCost
                      : "0.00"
                  }
                  name="machineCost"
                  {...register("machineCost")}
                />
                {errors.machineCost && (
                  <span className="text-danger err-msg">
                    {errors.machineCost.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_selling_price
                    ? constLabel?.lbl_selling_price
                    : "Selling Price"}
                  {watch("can_be_sold") ? <span className="text-danger"> *</span> : null }
                  (<span>&#8377;</span>){" / "}{selUnit && selUnit[0]?.uom}
                </label>
                <input
                  className={
                    errors.selling_price
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Enter selling price"
                  name="selling_price"
                  defaultValue={
                    props.main_obj.prod_overview.selling_price !== null &&
                    props.main_obj.prod_overview.selling_price !== ""
                      ? props.main_obj.prod_overview.selling_price
                      : "0.00"
                  }
                  {...register("selling_price", {
                    required: watch("can_be_sold") ? "Selling price is required." : false,
                    pattern: {
                      value: /^\d+(\.\d+)?$/,
                      message: "Enter a valid selling price.",
                    },
                  })}
                  onChange={(e) => {
                    handleInputChange(e, "selling_price", /^\d+(\.\d+)?$/);
                  }}
                />
                {errors.selling_price && (
                  <span className="text-danger err-msg">
                    {errors.selling_price.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_procurement_price
                    ? constLabel?.lbl_procurement_price
                    : "Procurement Price "}
                  {watch("can_be_purchased") ? <span className="text-danger"> *</span> : null }
                  (<span>&#8377;</span>){" / "}{selUnit && selUnit[0]?.uom}
                </label>
                <input
                  className={
                    errors.purchase_price
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Enter procurement price"
                  name="purchase_price"
                  defaultValue={
                    props.main_obj.prod_overview.purchase_price !== null &&
                    props.main_obj.prod_overview.purchase_price !== ""
                      ? props.main_obj.prod_overview.purchase_price
                      : "0.00"
                  }
                  {...register("purchase_price", {
                    required: watch("can_be_purchased") ? "Procurement price is required." : false,
                    pattern: {
                      value: /^\d+(\.\d+)?$/,
                      message: "Enter a valid procurement price.",
                    },
                  })}
                  onChange={(e) => {
                    handleInputChange(e, "purchase_price", /^\d+(\.\d+)?$/);
                  }}
                />
                {errors.purchase_price && (
                  <span className="text-danger err-msg">
                    {errors.purchase_price.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_remark ? constLabel?.lbl_remark : "Remark"}
                </label>
                <textarea
                  className="form-control mt-2"
                  type="text"
                  placeholder="Enter remark"
                  name="remark"
                  defaultValue={
                    props.main_obj.prod_overview.remark !== null &&
                    props.main_obj.prod_overview.remark !== ""
                      ? props.main_obj.prod_overview.remark
                      : ""
                  }
                  {...register("remark")}
                ></textarea>
              </div>
            </div>
          </div>
          <hr className="my-4" />
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-grey px-4 me-2"
              onClick={() => navigate("/pcms/product")}
            >
              {constLabel?.lbl_cancel ? constLabel?.lbl_cancel : "Cancel"}
            </button>
            <button
              className="btn button-primary px-4 me-2"
              onClick={handleSubmit(onSubmit)}
              disabled={btnDisable}
              // onClick={() => props.setCurrentStep(props.currentStep + 1)}
            >
              {constLabel?.lbl_save_continue
                ? constLabel?.lbl_save_continue
                : "Save & Continue"}
            </button>
            <button
              className="btn btn-grey px-4 me-2"
              disabled={
                Object.keys(props.main_obj.prod_overview).length > 0
                  ? false
                  : true
              }
              onClick={() => props.nextStep()}
            >
              {constLabel?.lbl_skip ? constLabel?.lbl_skip : "Skip"}
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default RawMaterial;
