import React, { useState, useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { create_party_attributes, getOperatorList, update_party_attributes } from "../../../api";
import { ToastContainer, toast } from "react-toastify";
import DatePicker from "react-datepicker";
import { convertDateYYMMDD, formatUTCDate } from "../../../common/Functions/CommonFunctions";
import Leg_img from "../../../assets/images/leg.png"

const schema = yup
  .object()
  .shape({
    name: yup
      .string()
      .required("Measurement name is required !"),
  })
  .required();

function Add_Measurements(props) {
  const {
    visible,
    setVisible,
    cust_data,
    setCust_Data,
    attribList,
    single_measured,
    set_single_mesured,
    party_mesurements,
    selectedCustomer
  } = props;

  const [measured, setMeasured] = useState({ data: [], loading: false });
  const [noted, setNoted] = useState({ data: [], loading: false });

  const [constLabel, setConstLabel] = useState({});
  const [lockBtn, setLockBtn] = useState(false);

  const [filteredAndSortedAttribList, setFilteredAndSortedAttribList] = useState([]);


  const [up_body, setUp_Body] = useState([]);
  const [low_body, setLow_Body] = useState([]);
  const [checkboxes, setCheckBoxes] = useState([]);
  const [up_lines, setUp_Lines] = useState([]);
  const [low_lines, setLow_Lines] = useState([]);
  const [sleeves, setSleeves] = useState([]);
  const [legs, setLegs] = useState([]);

  const localData = JSON.parse(localStorage.getItem("localData"));

  const inputPositions = [
    { top: "8%", left: "31%", inputTop: "6%", inputLeft: "44%" },
    { top: "18%", left: "31%", inputTop: "16%", inputLeft: "44%" },
    { top: "28%", left: "31%", inputTop: "26%", inputLeft: "44%" },
    { top: "38%", left: "31%", inputTop: "36%", inputLeft: "44%" },
    { top: "56%", left: "31%", inputTop: "54%", inputLeft: "44%" },
    { top: "76%", left: "31%", inputTop: "74%", inputLeft: "44%" },
  ];

  const sleeveTypes = ["SHORT", "HALF", "3/4TH", "FULL"];


  const { register, handleSubmit, formState, reset, watch, control, setValue } =
    useForm({
      resolver: yupResolver(schema),
      mode: "onChange",
    });

  let { errors } = formState;

  useEffect(() => {
    
    setMeasured({ ...measured, loading: true, data: [] });
    setNoted({ ...noted, loading: true, data: [] });
    const payload = {
      designation: "sales",
      location: localData.employee_id?.branch_location_id,
    };

    getOperatorList(payload).then(
      (res) => {
        if (res && res.data.data?.length > 0) {
          const result = res.data.data.map((item) => ({
            ...item,
            fullName: item.FirstName + " " + item.LastName,
          }));
          setMeasured({ ...measured, loading: false, data: result });
          setNoted({ ...noted, loading: false, data: result });
        } else {
          setMeasured({ ...measured, loading: false, data: [] });
          setNoted({ ...noted, loading: false, data: [] });
        }
      },
      (err) => {
        setMeasured({ ...measured, loading: false, data: [] });
        setNoted({ ...noted, loading: false, data: [] });
        toast.error(err.response.data.message, {
          autoClose: 2000,
        });
      }
    );
    
  }, []);

  useEffect(() => {
    if (single_measured.length > 0) {
      setValue("name", single_measured[0]?.name !== "" ?
          single_measured[0]?.name : ""
      );
      setValue("measured_by", single_measured[0]?.measured_by_uuid !== null ? [{
                fullName: single_measured[0]?.measured_by_name,
                uuid: single_measured[0]?.measured_by_uuid,
              }]: []
      );
      setValue("noted_by", single_measured[0]?.noted_by_uuid !== null ? [{
          fullName: single_measured[0]?.noted_by_name,
          uuid: single_measured[0]?.noted_by_uuid,
        }]: []
      );
      setValue("measured_date", single_measured[0]?.measured_date !== null ?
          new Date(single_measured[0]?.measured_date) : null
      );
    } else {
      setValue(
        "name",
        (selectedCustomer?.name && selectedCustomer?.name !== "" ? selectedCustomer.name : "") +
        " - " +
        formatUTCDate(new Date())
      );
      setValue("measured_by", []);
      setValue("noted_by",[]);
      setValue("measured_date", new Date());
    }
  }, [single_measured]);
  

  useEffect(() => {
    const gender = cust_data.length > 0 ? cust_data?.[0]?.gender : selectedCustomer?.gender;
    const filteredList = attribList
      .filter(attr => attr.attribute_type === gender)
      .sort((a, b) => a.sequence_no - b.sequence_no)
      .map(attr => ({ ...attr, value: null }));
  
    if (single_measured.length > 0) {
      const updatedAttribList = filteredList.map(attr => {
        const matchingAttribute = single_measured?.[0]?.attributes.find(a => a.uuid === attr.uuid);
        return matchingAttribute ? { ...attr, value: matchingAttribute.value } : attr;
      });
      setFilteredAndSortedAttribList(updatedAttribList);
    } else {
      setFilteredAndSortedAttribList(filteredList);
    }

  }, [single_measured, attribList, cust_data, selectedCustomer]);

  useEffect(() => {
    if (filteredAndSortedAttribList.length > 0) {
      setUp_Body(filteredAndSortedAttribList.filter(attr => attr.field_type === "text" && attr.sub_attribute_type === "upperbody" && attr.sequence_no != null));
      setLow_Body(filteredAndSortedAttribList.filter(attr => attr.field_type === "text" && attr.sub_attribute_type === "lowerbody" && attr.sequence_no != null));
      setCheckBoxes(filteredAndSortedAttribList.filter(attr => attr.field_type === "checkbox"));
      setUp_Lines(filteredAndSortedAttribList.filter(attr => attr.field_type === "line" && attr.sub_attribute_type === "upperbody"));
      setLow_Lines(filteredAndSortedAttribList.filter(attr => attr.field_type === "line" && attr.sub_attribute_type === "lowerbody"));
      setSleeves(
        filteredAndSortedAttribList.filter(
          attr => attr.field_type === "text" && /\bSLEEVES\b/.test(attr.name.toUpperCase())
        )
      );
      setLegs(
        filteredAndSortedAttribList
          .filter(attr => attr.field_type === "leg")
          .map(attr => ({
            ...attr,
            value: attr.value ? JSON.parse(attr.value) : Array(6).fill(""), // Convert value to array or initialize
          }))
      );
    }
    // console.log("filteredAndSortedAttribList", filteredAndSortedAttribList)
  }, [filteredAndSortedAttribList]);

  const handleChange = (value, name, body) => {
    setFilteredAndSortedAttribList(prevList =>
      prevList.map(attr =>
        attr.name === name && attr.sub_attribute_type === body ? { ...attr, value } : attr
      )
    );
  };

  const handleChange2 = (value, name, index) => {
    setFilteredAndSortedAttribList((prevList) =>
      prevList.map((attr) => {
        if (attr.name === name && attr.field_type === "leg") {
          // Ensure `attr.value` is an array, even if it was previously null or a string
          const updatedValues = attr.value ? JSON.parse(attr.value) : Array(6).fill("");
  
          // Update only the specific index
          updatedValues[index] = value;
  
          return { ...attr, value: JSON.stringify(updatedValues) }; // Store as JSON string
        }
        return attr;
      })
    );
  };

  const onSubmit = (data) => {
    // console.log("data", data);
    setLockBtn(true);

    // Filter attributes to keep only those with a non-null and non-empty value
    const updatedAttributes = filteredAndSortedAttribList.filter(attr => attr.value !== null && attr.value !== "");
    
    // Ensure at least one attribute has a value
    if (updatedAttributes.length === 0) {
        toast.error("Please fill at least one attribute value.", {autoClose: 1000});
        setLockBtn(false);
        return;
    }

    const payload = {
      name: data.name,
      party_uuid: cust_data?.[0]?.uuid || selectedCustomer?.uuid,
      attributes: updatedAttributes,
      client_id: localData.client_id,
      measured_by_uuid: data.measured_by.length > 0 ? data.measured_by?.[0]?.uuid : null,
      measured_by_name: data.measured_by.length > 0 ? data.measured_by?.[0]?.fullName : null ,
      noted_by_uuid: data.noted_by?.length > 0 ? data.noted_by?.[0]?.uuid : null,
      noted_by_name: data.noted_by?.length > 0 ? data.noted_by?.[0]?.fullName : null,
      measured_date: data.measured_date ? convertDateYYMMDD(data.measured_date, "-") : null,
    };

    if(single_measured.length > 0){
      payload.uuid = single_measured?.[0]?.uuid;
    }

    const party_payload = cust_data.length > 0 ? cust_data : [selectedCustomer]

    if (single_measured.length > 0) {
      update_party_attributes(payload).then(
        async (res) => {
          if (res.status === 200 || res.status === 201) {
            toast.success("Measurements updated successfully !", {
              autoClose: 1500,
            });

            party_mesurements(party_payload, "only_active");

            setTimeout(() => {
              setLockBtn(false);
              setVisible(false);
              setCust_Data([]);
            }, 1800);
            
          }
        },
        (err) => {
          if (err.response.status === 400) {
            toast.error(err.response.data.message, { autoClose: 3000 });
          }
          setLockBtn(false);
        }
      );
    } else {
      create_party_attributes(payload).then(
        async (res) => {
          if (res.status === 200 || res.status === 201) {
            toast.success("Measurements created successfully !", {
              autoClose: 1500,
            });
            party_mesurements(party_payload, "only_active");
            
            setTimeout(() => {
              setLockBtn(false);
              setVisible(false);
              setCust_Data([]);
            }, 1800);
          }
        },
        (err) => {
          if (err.response.status === 400) {
            toast.error(err.response.data.message, {
              autoClose: 3000,
            });
            setLockBtn(false);
          }
        }
      );
    }
  };

  // Create a lookup object for quick access
  const lookup = {};
  sleeves.forEach(item => {
      lookup[item.name] = item.value;
  });

  return (
    <React.Fragment>
      <Modal
        show={visible}
        onHide={() => {
          setVisible(false);
          setCust_Data([]);
          set_single_mesured([]);
          setFilteredAndSortedAttribList([]);
        }}
        size="fullscreen"
        backdrop="static"
      >
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>
            {constLabel?.lbl_customer_measurements
              ? constLabel.lbl_customer_measurements
              : "Customer Measurements"}{" "}{`(${cust_data.length > 0 ? cust_data?.[0]?.organization : selectedCustomer?.organization} - 
                 ${cust_data.length > 0 ? cust_data?.[0]?.gender : selectedCustomer?.gender})`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action="">
            <div className="row">
              <div className="col-12 col-md-3">
                <div className="form-group typeahead-container">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label roboto-bold"
                  >
                    {constLabel?.lbl_name
                      ? constLabel.lbl_name
                      : "Name"}{" "}
                    <span className="text-danger"> *</span>
                  </label>
                  <input
                      className={
                        errors.name
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      id="name"
                      name="name"
                      type="text"
                      defaultValue={
                        (cust_data.length > 0 ? cust_data?.[0]?.organization : selectedCustomer?.organization) +
                        " - " +
                        formatUTCDate(new Date())
                      }
                      placeholder="Enter name"
                      {...register("name")}
                    />
                    <span className="text-danger err-msg">
                      {errors.name?.message}
                    </span>
                </div>
              </div>

              <div className="col-12 col-md-3">
                <div className="form-group typeahead-container">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label roboto-bold"
                  >
                    {constLabel?.lbl_measured_by
                      ? constLabel.lbl_measured_by
                      : "Measured By"}{" "}
                    {/* <span className="text-danger"> *</span> */}
                  </label>
                  <Controller
                    name="measured_by"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        className={""}
                        id="basic-typeahead-single"
                        labelKey="fullName"
                        multiple={false}
                        options={
                          measured.data !== undefined ? measured.data : []
                        }
                        isLoading={measured.loading}
                        placeholder="Select measured by"
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        // isInvalid={errors.measured_by ? true : false}
                      />
                    )}
                  />
                  {!measured.loading && <span className="arrow-icon"></span>}
                  {/* <span className="text-danger err-msg">
                    {errors.measured_by?.message}
                  </span> */}
                </div>
              </div>

              <div className="col-12 col-md-3">
                <div className="form-group typeahead-container">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label roboto-bold"
                  >
                    {constLabel?.lbl_noted_by
                      ? constLabel.lbl_noted_by
                      : "Noted By"}{" "}
                    {/* <span className="text-danger"> *</span> */}
                  </label>
                  <Controller
                    name="noted_by"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        className={""}
                        id="basic-typeahead-single"
                        labelKey="fullName"
                        multiple={false}
                        options={noted.data !== undefined ? noted.data : []}
                        isLoading={noted.loading}
                        placeholder="Select noted by"
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        // isInvalid={errors.noted_by ? true : false}
                      />
                    )}
                  />
                  {!noted.loading && <span className="arrow-icon"></span>}
                  {/* <span className="text-danger err-msg">
                    {errors.noted_by?.message}
                  </span> */}
                </div>
              </div>

              <div className="col-12 col-sm-3">
                <div className="form-group">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label roboto-bold"
                  >
                    {constLabel?.lbl_measured_date
                      ? constLabel.lbl_measured_date
                      : "Measured Date"}
                  </label>
                  <Controller
                    name="measured_date"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        className="form-control"
                        {...field}
                        dateFormat="dd/MM/yyyy"
                        selected={field.value || null}
                        placeholderText="Select measured date"
                        autoComplete="off"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <hr />
            <div className="row p-2">
              <div className="col-12 border col-md-3 p-2">
                {up_body.map((attr, i) => (
                  <div key={i} className="row mb-3">
                    <div className="col-md-7">
                      <label
                        className="form-label roboto-bold"
                        style={{ fontSize: "12px" }}
                      >
                        {attr.name}
                      </label>
                    </div>
                    <div className="col-md-5">
                      <input
                        className="form-control form-control-sm"
                        type="text"
                        id={attr.name}
                        name={attr.name}
                        value={attr.value || ""}
                        onChange={(e) => {
                          const t_values = e.target.value;
                          if (t_values !== "" || e.nativeEvent.inputType === "deleteContentBackward") {
                            handleChange(t_values, attr.name, attr.sub_attribute_type);
                          }
                        }}
                      />
                    </div>
                  </div>
                ))}
                {low_body.map((attr, i) => (
                  <div key={i} className="row mb-3">
                    <div className="col-md-7">
                      <label
                        className="form-label roboto-bold"
                        style={{ fontSize: "12px" }}
                      >
                        {attr.name}
                      </label>
                    </div>
                    <div className="col-md-5">
                      <input
                        className="form-control form-control-sm"
                        type="text"
                        id={attr.name}
                        name={attr.name}
                        value={attr.value || ""}
                        onChange={(e) => {
                          const t_values = e.target.value;
                          if (t_values !== "" || e.nativeEvent.inputType === "deleteContentBackward") {
                            handleChange(t_values, attr.name, attr.sub_attribute_type);
                          }
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
              <div className="col-12 col-md-7">
                <div className="row">
                    <div className="col-8 border p-3">
                    <table className="table table-sm table-bordered">
                      <thead className="roboto-bold">
                          <tr>
                              <th>SLEEVES</th>
                              {sleeveTypes.map((type, index) => (
                                  <th key={index}>{type}</th>
                              ))}
                          </tr>
                      </thead>
                      <tbody>
                          {[1, 2].map((num) => (
                              <tr key={num}>
                                  <td></td>
                                  {sleeveTypes.map((type) => (
                                      <td key={type}>
                                          <input
                                              type="text"
                                              className="form-control form-control-sm"
                                              value={lookup[`SLEEVES ${type} ${num}`] || ""}
                                              onChange={(e) => {
                                                const t_values = e.target.value;
                                                if (t_values !== "" || e.nativeEvent.inputType === "deleteContentBackward") {
                                                  handleChange(t_values, `SLEEVES ${type} ${num}`, "upperbody");
                                                }
                                              }}
                                          />
                                      </td>
                                  ))}
                              </tr>
                          ))}
                      </tbody>
                  </table>

                    </div>
                    <div className="col-4 border p-3">
                    <div className="position-relative">
                      {/* Vertical line with horizontal lines at top and bottom */}
                      <div
                        className="position-absolute border-dark"
                        style={{
                          left: "50%",
                          top: 0,
                          bottom: 0,
                          width: "2px",
                          backgroundColor: "black",
                        }}
                      >
                        <div
                          className="position-absolute start-50 translate-middle-x border-top border-dark"
                          style={{ width: "10px", top: "0px" }}
                        ></div>
                        <div
                          className="position-absolute start-50 translate-middle-x border-bottom border-dark"
                          style={{ width: "10px", bottom: "0px" }}
                        ></div>
                      </div>
                    
                        {up_lines.map((attr, i) => (
                          <div key={i} className="row mb-3 align-items-center">
                            <div className="col-md-8">
                              <label
                                className="form-label roboto-bold"
                                style={{ fontSize: "12px" }}
                              >
                                {attr.name}
                              </label>
                            </div>
                            <div className="col-md-4">
                              <input
                                className="form-control form-control-sm"
                                type="text"
                                id={attr.name}
                                name={attr.name}
                                value={attr.value || ""}
                                onChange={(e) => {
                                  const t_values = e.target.value;
                                  if (t_values !== "" || e.nativeEvent.inputType === "deleteContentBackward") {
                                    handleChange(t_values, attr.name, attr.sub_attribute_type);
                                  }
                                }}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                </div>
                <div className="row">
                  <div className="col-8 border p-3 position-relative">
                      {/* Leg Image */}
                      <img 
                        src={Leg_img} 
                        className="ms-5"
                        alt="Leg Image"
                        height="550"
                        width="120"
                      />

                      {/* Dynamically generated arrows and input boxes */}
                      {inputPositions.map((pos, index) => (
                        <React.Fragment key={index}>
                          {/* Arrow pointing to input */}
                          <div
                            className="position-absolute"
                            style={{
                              top: pos.top,
                              left: pos.left,
                              width: "10%",
                              height: "2px",
                              backgroundColor: "black",
                            }}
                          >
                            {/* Triangle arrowhead */}
                            <div
                              className="position-absolute"
                              style={{
                                top: "-3px",
                                right: "-10px",
                                width: "0",
                                height: "0",
                                borderLeft: "10px solid black",
                                borderTop: "5px solid transparent",
                                borderBottom: "5px solid transparent",
                              }}
                            ></div>
                          </div>

                          {/* Input box next to arrow */}
                          <input
                            type="text"
                            name="Leg"
                            className="position-absolute form-control form-control-sm"
                            style={{
                              top: pos.inputTop,
                              left: pos.inputLeft,
                              width: "100px",
                            }}
                            value={legs?.[0]?.value?.[index] || ""}
                            onChange={(e) => handleChange2(e.target.value, "Leg", index)}
                          />
                        </React.Fragment>
                      ))}
                    </div>
                    <div className="col-4 border p-3">
                      <div className="position-relative">
                        {/* Vertical line with horizontal lines at top and bottom */}
                        <div
                          className="position-absolute border-dark"
                          style={{
                            left: "50%",
                            top: 0,
                            bottom: 0,
                            width: "2px",
                            backgroundColor: "black",
                          }}
                        >
                          <div
                            className="position-absolute start-50 translate-middle-x border-top border-dark"
                            style={{ width: "10px", top: "0px" }}
                          ></div>
                          <div
                            className="position-absolute start-50 translate-middle-x border-bottom border-dark"
                            style={{ width: "10px", bottom: "0px" }}
                          ></div>
                        </div>

                        {/* Loop through form rows */}
                        {low_lines.map((attr, i) => (
                          <div key={i} className="row mb-3 align-items-center">
                            <div className="col-md-8">
                              <label className="form-label roboto-bold" style={{ fontSize: "12px" }}>
                                {attr.name}
                              </label>
                            </div>
                            <div className="col-md-4">
                              <input
                                className="form-control form-control-sm"
                                type="text"
                                id={attr.name}
                                name={attr.name}
                                value={attr.value || ""}
                                onChange={(e) => {
                                  const t_values = e.target.value;
                                  if (t_values !== "" || e.nativeEvent.inputType === "deleteContentBackward") {
                                    handleChange(t_values, attr.name , attr.sub_attribute_type);
                                  }
                                }}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                </div>
              </div>
              <div className="col-12 col-md-2 border p-2">
                {checkboxes.map((attr, i) => (
                  <div key={i} className="row mb-3">
                    <div className="col-md-8">
                      <label
                        className="form-label roboto-bold"
                        style={{ fontSize: "12px" }}
                      >
                        {attr.name}
                      </label>
                    </div>
                    <div className="col-md-4">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id={attr.name}
                        name={attr.name}
                        defaultChecked={attr.value == 'true' ? true : false}
                        onChange={(e) => {
                          const t_values = e.target.checked == true ? 'true' : 'false';
                            handleChange(t_values, attr.name, attr.sub_attribute_type);
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-grey px-4"
            onClick={handleSubmit(onSubmit)}
            disabled={lockBtn}
          >
            {constLabel?.lbl_save ? constLabel.lbl_save : "Save"}{" "}
            {lockBtn ? <Spinner animation="border" size="sm" /> : null}{" "}
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default Add_Measurements;
