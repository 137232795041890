import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import { createRoutesApi, get_store2, get_store_type, getCustomer, getInternalCompany, getLocation, save_store } from "../../../api/index";
import { useLocation, useNavigate } from "react-router-dom";
import { getLabels } from "../../../common/Functions/CommonFunctions";
import { Modal, Spinner } from "react-bootstrap";
import { Tooltip } from "react-tooltip";

const schema = yup
  .object()
  .shape({
    name: yup.string().required("Store name is required!"),
    store_type: yup
      .array()
      .min(1, "Please select store type !")
      .required("Please select store type !"),
    location: yup
      .array()
      .min(1, "Please select location !")
      .required("Please select location !"),
    root_store: yup
      .array()
      .notRequired()
  })
  .required()

function AddStore(props) {
  let location = useLocation();
  let navigate = useNavigate();
  
  const [visible, setVisible] = useState(false);
  const [constLabel, setConstLabel] = useState({});
  const [storeTypes, setStoreTypes] = useState({ data: [], loading: false });
    const [locList, setLocList] = useState({ data: [], loading: false });
    const [partyList, setPartyList] = useState({ data: [], loading: false });
    const [intCompList, setIntCompList] = useState({ data: [], loading: false });
    const [storeList, setStoreList] = useState({ data: [], loading: false });
    const [parentstoreList, setParentstoreList] = useState({
        data: [],
        loading: false,
    });
    const [disableBtn, setDisableBtn] = useState(false);
  
  const { register, handleSubmit, formState, reset, setValue, control, watch, setError } =
    useForm({
      resolver: yupResolver(schema),
      mode: "onChange",
    }); // initialise the hook

  let { errors } = formState;

  const selected_store_type = watch('store_type');
  const selected_location = watch('location');

  useEffect(() => {
    setConstLabel(getLabels("Master", "Store"));
    if(visible){
      fetchStoreTypes();
      fetchInternalCompany();
      fetchParties();
      fetchStores();
    }
  }, [visible]);

  function fetchStoreTypes() {
      setStoreTypes({ ...storeTypes, data: [], loading: true });
  
      get_store_type().then(
        (res) => {
          setStoreTypes({ ...storeTypes, data: res.data.data, loading: false });
        },
        (err) => {
          setStoreTypes({ ...storeTypes, data: [], loading: false });
        }
      );
    }
  
    function fetchLocations(org, strName) {
      setLocList({ ...locList, data: [], loading: true });
  
      const payload = { company_id: strName == "party" ? org?.[0]?.org_id : org?.[0]?.uuid };
  
      getLocation(payload).then(
        (res) => {
          const result = res.data.data.filter(
            (item) => item.locationname !== null
          );
  
          setLocList({ ...locList, data: result, loading: false });
        },
        (err) => {
          setLocList({ ...locList, data: [], loading: false });
        }
      );
    }
  
    function fetchInternalCompany() {
      setIntCompList({ ...intCompList, data: [], loading: true });
  
      getInternalCompany().then(
        (res) => {
          setIntCompList({ ...intCompList, data: res.data.data, loading: false });
        },
        (err) => {
          setIntCompList({ ...intCompList, data: [], loading: false });
        }
      );
    }
  
    function fetchParties() {
      setPartyList({ ...partyList, data: [], loading: true });
  
      getCustomer().then(
        (res) => {
          const result = res.data.data.filter(
            (item) => item.organization !== "" && item.organization !== null
          );
          setPartyList({ ...partyList, data: result, loading: false });
        },
        (err) => {
          setPartyList({ ...partyList, data: [], loading: false });
        }
      );
    }
  
    function fetchStores(location_id) {
      setStoreList({ ...storeList, data: [], loading: true });
      setParentstoreList({ ...parentstoreList, data: [], loading: true });
  
      get_store2({ parent_list: true, location_id: location_id }).then(
        (res) => {
          setStoreList({ ...storeList, data: res.data.data, loading: false });
  
          const storesWithNoRootStore = res.data.data.filter((val) => val.root_store_id == null);
          setParentstoreList({ ...parentstoreList, data: storesWithNoRootStore, loading: false });
  
        },
        (err) => {
          setStoreList({ ...storeList, data: [], loading: false });
          setParentstoreList({ ...parentstoreList, data: [], loading: false });
        }
      );
    }

  const onSubmit = (data) => {
      setDisableBtn(true);
      if (data !== "") {
  
        if ((data.store_type[0].name == "Rack" || data.store_type[0].name == "Shelf")
          && (!data.root_store || data.root_store?.length == 0)) {
          setError(`root_store`, {
            type: "custom",
            message: "Please select root store",
          });
          return
        }
  
  
        const reqPayload = {
          name: data.name.trim(),
          store_types: data.store_type[0]?.name,
          location_id: data.location[0]?.uuid,
          parent_id_id: data.parent_store?.[0]?.uuid,
        };
  
        if (data.root_store && data.root_store.length > 0) {
          reqPayload.root_store_id = data.root_store?.[0]?.uuid;
        } else {
          reqPayload.root_store_id = null;
        }
  
  
        if (data.party !== undefined && data.party?.length > 0) {
          reqPayload.party_id = data.party?.[0]?.uuid;
          reqPayload.party_name = data.party?.[0]?.organization;
        } else {
          reqPayload.internal_company_id = data.internal_company?.[0]?.uuid;
          reqPayload.internal_company_name = data.internal_company?.[0]?.comName;
        }
  
        // console.log("reqPayload", reqPayload);
  
        save_store(reqPayload).then(
          (res) => {
            if (res.status === 200 || res.status === 201) {
              toast.success("Store created successfully !", {
                autoClose: 2000,
              });

              setTimeout(() => {
                props.fetchStore(null, props.isNew === true ? true : false);
                setVisible(false);
              }, 2200);
            }
          },
          (err) => {
            if (err.response.status === 400) {
              toast.error(err.response.data.message, {
                autoClose: 2000,
              });
              setDisableBtn(false);
            }
          }
        );
      } else {
        errors.showMessages();
      }
    };

  return (
   <React.Fragment>
    <Tooltip id={"add-tooltip"} place="top" />
      <div
        data-tooltip-id={"add-tooltip"}
        data-tooltip-content={
            constLabel?.lbl_add_store
            ? constLabel.lbl_add_store
            : "Add Store"}
        className="cursor_pointer me-2"
       
    ><a
        className="px-2 mt-2 cursor_pointer"
        onClick={(e) => {
            // setUpdateForm(val)
            e.preventDefault();
            setVisible(true);
            reset();
        }}
    >
        {constLabel?.lbl_add_store ? constLabel.lbl_add_store : "Add Store"}
    </a>
    </div>
    <Modal show={visible} onHide={() => setVisible(false)} size={"lg"}>
    <Modal.Header
      style={{ background: "#2A3643", color: "white" }}
      closeButton
    >
      <Modal.Title>{constLabel?.lbl_add_store ? constLabel.lbl_add_store : "Add Store"}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <form action="">
            <div className="row">
            {/* Orgnazation */}
            <div className="col-12 col-md-6">
                <div className="form-group typeahead-container">
                <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label roboto-bold"
                >
                    {constLabel?.lbl_internal_company
                    ? constLabel.lbl_internal_company
                    : "Internal Company"}
                </label>
                <Controller
                    name="internal_company"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                        className={""}
                        id="basic-typeahead-single"
                        labelKey="comName"
                        multiple={false}
                        options={
                        intCompList.data !== undefined ? intCompList.data : []
                        }
                        isLoading={intCompList.state}
                        placeholder="Select internal company"
                        onChange={(selected) => {
                        onChange(selected);
                        if (selected && selected.length > 0) {
                            setValue("party", []);
                            setValue("location", []);
                            console.log("selected", selected);
                            if (selected.length > 0) {
                            fetchLocations(selected, "organization");
                            }
                        } else {
                            setLocList({ ...locList, data: [], loading: false });
                        }
                        }}
                        onBlur={onBlur}
                        selected={value}
                        isInvalid={errors.internal_company ? true : false}
                    />
                    )}
                />
                {!intCompList.loading && !errors.internal_company && <span className="arrow-icon"></span>}
                <span className="text-danger err-msg">
                    {errors.internal_company?.message}
                </span>
                </div>
            </div>

            {/* Party */}
            <div className="col-12 col-md-6">
                <div className="form-group typeahead-container">
                <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label roboto-bold"
                >
                    {constLabel?.lbl_party ? constLabel?.lbl_party : "Party"}
                    {/* <span className="text-danger"> *</span> */}
                </label>
                <Controller
                    name="party"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                        id="basic-typeahead-single"
                        labelKey="organization"
                        multiple={false}
                        // size="sm"
                        options={
                        partyList.data !== undefined ? partyList.data : []
                        }
                        isLoading={partyList.loading}
                        placeholder="Select party"
                        onChange={(selected) => {
                        onChange(selected);
                        if (selected && selected.length > 0) {
                            setValue("internal_company", []);
                            setValue("location", []);
                            console.log("selected", selected);
                            if (selected.length > 0) {
                            fetchLocations(selected, "party");
                            }
                        } else {
                            setLocList({ ...locList, data: [], loading: false });
                        }
                        }}
                        onBlur={onBlur}
                        selected={value}
                        isInvalid={errors.party ? true : false}
                        // disabled={!selected_internal_company}
                    />
                    )}
                />
                {!partyList.loading && !errors.party && <span className="arrow-icon"></span>}
                <span className="text-danger err-msg">
                    {errors.party?.message}
                </span>
                </div>
            </div>

            {/* Location */}
            <div className="col-12 col-md-6">
                <div className="form-group mt-2 typeahead-container">
                <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label roboto-bold"
                >
                    {constLabel?.lbl_location_name
                    ? constLabel.lbl_location_name
                    : "Location Name"}
                    <span className="text-danger"> *</span>
                </label>
                <Controller
                    name="location"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                        id="basic-typeahead-single"
                        labelKey="locationname"
                        multiple={false}
                        options={locList.data !== undefined ? locList.data : []}
                        // options={[{name : null}, {name: "null"}]}
                        isLoading={locList.loading}
                        placeholder="Select location"
                        onChange={(selected) => {
                        onChange(selected);
                        if (selected && selected.length > 0) {
                            // let arr = storeList.data.filter(
                            //   (item) => item.location_id == selected[0].uuid
                            // );
                            // setParentstoreList({ data: arr, loading: false });
                            fetchStores(selected[0].uuid);
                            setValue("parent_store", []);
                        } else {
                            // setParentstoreList({ data: [], loading: false });
                            setValue("parent_store", []);
                        }
                        }}
                        onBlur={onBlur}
                        selected={value}
                        isInvalid={errors.location ? true : false}
                    />
                    )}
                />
                {!locList.loading && !errors.location && <span className="arrow-icon"></span>}
                <span className="text-danger err-msg">
                    {errors.location?.message}
                </span>
                </div>
            </div>

            {/* Parent Store */}
            <div className="col-12 col-md-6">
                <div className="form-group mt-2 typeahead-container">
                <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label roboto-bold"
                >
                    {constLabel?.lbl_parent_store
                    ? constLabel.lbl_parent_store
                    : "Parent Store"}
                </label>
                <Controller
                    name="parent_store"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                        id="basic-typeahead-single"
                        labelKey="name"
                        multiple={false}
                        options={
                        storeList.data !== undefined
                            ? storeList.data
                            : []
                        }
                        // options={[{name : null}, {name: "null"}]}
                        isLoading={storeList.loading}
                        placeholder="Select parent store"
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        disabled={selected_location == null || selected_location == undefined}
                    // isInvalid={errors.parent_store ? true : false}
                    />
                    )}
                />
                {!storeList.loading && <span className="arrow-icon"></span>}
                {/* <span className="text-danger err-msg">
                    {errors.parent_store?.message}
                </span> */}
                </div>
            </div>
            

            {/* Store Type */}
            <div className="col-12 col-md-6">
                <div className="form-group mt-2 typeahead-container">
                <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label roboto-bold"
                >
                    {constLabel?.lbl_store_type
                    ? constLabel.lbl_store_type
                    : "Store Type"}
                    <span className="text-danger"> *</span>
                </label>
                <Controller
                    name="store_type"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                        id="basic-typeahead-single"
                        labelKey="name"
                        multiple={false}
                        options={
                        storeTypes.data !== undefined ? storeTypes.data : []
                        }
                        // options={[{name : null}, {name: "null"}]}
                        isLoading={storeTypes.loading}
                        placeholder="Select store type"
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        isInvalid={errors.store_type ? true : false}
                    />
                    )}
                />
                {!storeTypes.loading && !errors.store_type && <span className="arrow-icon"></span>}
                <span className="text-danger err-msg">
                    {errors.store_type?.message}
                </span>
                </div>
            </div>
            
            <div className="col-12 col-md-6">
                <div className="form-group mt-2 typeahead-container">
                <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label roboto-bold"
                >
                    {constLabel?.lbl_root_store
                    ? constLabel.lbl_root_store
                    : "Root Store"}
                    <span className="text-danger"> *</span>
                </label>
                <Controller
                    name="root_store"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                        id="basic-typeahead-single"
                        labelKey="name"
                        multiple={false}
                        options={
                        parentstoreList.data !== undefined
                            ? parentstoreList.data
                            : []
                        }
                        // options={[{name : null}, {name: "null"}]}
                        isLoading={parentstoreList.loading}
                        placeholder="Select root store"
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        isInvalid={errors.root_store ? true : false}
                        disabled={!selected_store_type ||
                        (selected_store_type && selected_store_type[0]?.name !== "Rack" &&
                            selected_store_type[0]?.name !== "Shelf")}
                    />
                    )}
                />
                {!parentstoreList.loading && !errors.root_store &&
                    <span className="arrow-icon"></span>}

                <span className="text-danger err-msg">
                    {errors.root_store?.message}
                </span>
                </div>
            </div>

            {/* Store name */}
            <div className="col-12 col-md-6">
                <div className="form-group mt-2">
                <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label roboto-bold"
                >
                    {constLabel?.lbl_store_name
                    ? constLabel.lbl_store_name
                    : "Store Name"}
                    <span className="text-danger"> *</span>
                </label>
                <input
                    type="text"
                    className={
                    errors.name ? "form-control  is-invalid" : "form-control "
                    }
                    id="name"
                    name="name"
                    placeholder="Enter store name"
                    {...register("name")}
                />
                <span className="text-danger err-msg">
                    {errors.name?.message}
                </span>
                </div>
            </div>

            </div>
        </form>
    </Modal.Body>
    <Modal.Footer>
        <button
        disable={disableBtn}
        onClick={handleSubmit(onSubmit)}
        className="f-16 btn btn-yellow "
        >
        {constLabel?.lbl_save ? constLabel.lbl_save : "Save"}
        </button>
    </Modal.Footer>
  </Modal>
  </React.Fragment>
  );
}

export default AddStore;