import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faBan,
  faFilter,
  faCog,
  faPlus,
  faUpload,
  faFileExcel,
} from "@fortawesome/free-solid-svg-icons";
import { Typeahead } from "react-bootstrap-typeahead";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Pagination, Spinner } from "react-bootstrap";
import { Edit, Edit2, Eye, Trash2 } from "react-feather";
import SweetAlert from "sweetalert2";
import { Supplier_Col_Name } from "../../../../common/Labels/labelConstant";
import CreateSupplier from "../../Supplier/Supplier/CreateSupplier";
import EditSupplier from "./EditSupplier";
import { getSupplier, deleteSupplier, download_supplier_template, bulk_upload_supplier, get_supplier_rpt } from "../../../../api/index";
import {
  calMaxPage_new,
  getLabels,
  setAccessPrivilage,
} from "../../../../common/Functions/CommonFunctions";
import { Tooltip } from "react-tooltip";
import { useSelector } from "react-redux";
import List from "../../../../assets/images/icons/project_menu/checklist.png";
import Slide from "../../../../assets/images/icons/project_menu/collapse.png";
import AccessDenied from "../../../Common/AccessDenied";
import { isExcelFile } from "../../../../common/Functions/CommonFunctions";
import * as name_const from "../../../../common/Constants/constants"

function Supplier() {
  let navigate = useNavigate();
  const [dataList, setDataList] = useState({
    data: [],
    loading: true,
    totalRecords: "",
  });

  const [suppList, setSuppList] = useState({ data: [], loading: false });

  const [srNo, setSrNo] = useState(0);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [sea_val, setSea_val] = useState(false);
  const [showLabel, setShowLabel] = useState(Supplier_Col_Name);
  const [constLabel, setConstLabel] = useState({});

  const [filterConfig, setFilterConfig] = useState({
    searchVal: "",
    GSTNO: "",
  });

  const [importData, setImportData] = useState(false);
  const [downloadTemplate, setDownloadTemplate] = useState(false);
  const [downloadReport, setDownloadReport] = useState(false);

  const loginData = useSelector(
    (state) => state.auth?.loginData?.data?.response
  );

  const [privilage, setPrivilage] = useState(
    setAccessPrivilage(loginData, "Supplier")
  );

  useEffect(() => {
    setConstLabel(getLabels("Party Master", "Supplier"));
    fetchSupplier();
  }, []);

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      setPage(1);
      fetchSuppliers(1, entriesPerPage);
    }, 1000); // Adjust the delay as needed (e.g., 500 milliseconds)

    return () => {
      clearTimeout(debounceTimeout); // Clear the timeout if searchTerm changes before the timeout expires
    };
  }, [filterConfig]);

  function fetchSupplier() {
    setSuppList({ ...suppList, data: [], loading: true });

    getSupplier({ partyType: "Supplier" }).then(
      (res) => {
        setSuppList({ ...suppList, data: res.data.data, loading: false });
      },
      (err) => {
        setSuppList({ ...suppList, data: [], loading: false });
      }
    );
  }

  function fetchSuppliers(pg, epp) {
    setDataList({ ...dataList, loading: true, data: [] });

    let payload = {
      pageNo: pg,
      entriesPerPage: epp,
      partyType: "Supplier",
    };

    if (filterConfig.searchVal !== "" || filterConfig.GSTNO !== "") {
      payload.searchVal = filterConfig.searchVal;
      payload.GSTNO = filterConfig.GSTNO;
      if (
        (filterConfig.searchVal?.length > 0 ||
          filterConfig.GSTNO?.length > 0) &&
        page !== 1 &&
        sea_val == false
      ) {
        payload.pageNo = 1;
        payload.entriesPerPage = entriesPerPage;
        setPage(1);
        setSrNo(0);
      }
    } else {
      delete payload.searchVal;
      delete payload.GSTNO;
    }

    getSupplier(payload).then(
      (res) => {
        setDataList({
          ...dataList,
          loading: false,
          data: res.data.data,
          totalRecords: res.data.total,
        });

        if (
          (filterConfig.searchVal != "" &&
            filterConfig.searchVal?.length > 0) ||
          (filterConfig.GSTNO != "" && filterConfig.GSTNO?.length > 0)
        ) {
          setSea_val(true);
        } else {
          setSea_val(false);
        }

        setMaxPage(calMaxPage_new(res.data.total));
        if (pg == 1) {
          setSrNo(0);
          setPage(1);
        }
      },
      (err) => {
        setDataList({
          ...dataList,
          loading: false,
          data: [],
          totalRecords: "",
        });
        if (err.response.status === 500) {
          toast.error("Something went wrong !", { autoClose: 5000 });
        }
      }
    );
  }

  function clearFilter() {
    setFilterConfig({ ...filterConfig, searchVal: "", GSTNO: "" });
    setSrNo(0);
    setPage(1);
  }

  // Deleting supplier
  const delSupplier = (val) => {
    SweetAlert.fire({
      title: "Are you sure ?",
      text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        const payload = {
          uuid: val.uuid,
          partyType: "Supplier",
        };
        deleteSupplier(payload).then(
          (res) => {
            toast.success("Supplier deleted successfully !", {
              autoClose: 2000,
            });

            if (dataList.data.length == 1 && page > 1) {
              fetchSuppliers(page - 1, entriesPerPage);
              setSrNo(parseInt(srNo - entriesPerPage));
              setPage(page - 1);
            } else {
              fetchSuppliers(page, entriesPerPage);
            }
          },
          (err) => {
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
          }
        );
      }
    });
  };

  function openNav() {
    document.getElementById("mySidebar").style.width = "320px";
    document.getElementById("root_div_main").style.marginLeft = "320px";
    setTimeout(() => {
      document.getElementById("mySidebar").style.overflowX = "visible";
    }, [200]);
  }

  function closeNav() {
    document.getElementById("mySidebar").style.width = "0";
    document.getElementById("root_div_main").style.marginLeft = "0";

    document.getElementById("mySidebar").style.overflowX = "hidden";
  }

  function handleClick(ind) {
    setShowLabel((showLabel) => {
      const updatedCardShow = showLabel.map((val, i) => {
        if (ind === i) {
          // Toggle the flag for the clicked checkbox
          return { ...val, flag: !val.flag };
        }
        return val;
      });

      return updatedCardShow;
    });
  }

  function toggleNav() {
    const sidebar = document.getElementById("mySidebar");
    const mainDiv = document.getElementById("root_div_main");

    if (sidebar.style.width === "320px") {
      // Sidebar is open, so close it
      sidebar.style.width = "0";
      mainDiv.style.marginLeft = "0";
      sidebar.style.overflowX = "hidden";
    } else {
      // Sidebar is closed, so open it
      sidebar.style.width = "320px";
      mainDiv.style.marginLeft = "320px";
      setTimeout(() => {
        sidebar.style.overflowX = "visible";
      }, 200);
    }
  }

  const handleFileChange = async (event) => {
    const file = event.target.files[0]; // Get the selected file
  
    if (!file || !isExcelFile(file.name)) {
      toast.error("Please upload a valid Excel file!", { autoClose: 2000 });
      return;
    }
  
    try {
      const reader = new FileReader();
  
      reader.onload = async () => {
        const base64 = reader.result.split(",")[1];

        // console.log("base64", base64); return;
  
        if (!base64) {
          toast.error("Failed to generate base64 string. Please try again!", { autoClose: 2000 });
          return;
        }
  
        setImportData(true);
  
        try {
          const res = await bulk_upload_supplier({ file_base_64: base64 });
  
          if (res.status === 200 || res.status === 201) {
            toast.success("Supplier imported successfully!", { autoClose: 2000 });
            fetchSuppliers(1, entriesPerPage);
          }
        } catch (err) {
          const errorMessage =
            err?.response?.status === 400 && err?.response?.data?.message
              ? err.response.data.message
              : "Something went wrong. Please try again later!";
          toast.error(errorMessage, { autoClose: 2000 });
        } finally {
          setImportData(false);
        }
      };
  
      reader.onerror = () => {
        toast.error("Error reading the file. Please try again!", { autoClose: 2000 });
      };
  
      reader.readAsDataURL(file);
    } catch (err) {
      toast.error("An unexpected error occurred. Please try again!", { autoClose: 2000 });
    }
  };
  

  function base64ToExcel(response, str_type) {
    const { statusCode, headers, body, isBase64Encoded } = response;
  
    // Assume base64String contains the base64 representation of the Excel file
    const base64String = body; // Replace this with your base64 string
  
    // Convert the base64 string into a Uint8Array
    const bytes = Uint8Array.from(atob(base64String), (c) => c.charCodeAt(0));
  
    // Create a Blob object from the Uint8Array
    const blob = new Blob([bytes], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
  
    // Create a URL for the Blob object
    const url = URL.createObjectURL(blob);
  
    // Create a link element with download attribute
    const link = document.createElement("a");
    link.href = url;
    const currentDate = new Date().toDateString().split(" ").join("_");

    if(str_type === name_const.TEMPLATE){
      link.download = `Supplier_Template_${currentDate}.xlsx`;
    } else {
      link.download = `Supplier_Report_${currentDate}.xlsx`;
    }
  
    // Programmatically click on the link to trigger the download
    if(str_type === name_const.TEMPLATE){
      setDownloadTemplate(false);
    } else {
      setDownloadReport(false);
    }
    document.body.appendChild(link);
    link.click();
  
    // Cleanup: remove the link and revoke the URL
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }

  async function goToDownload() {
    setDownloadReport(true);
    // console.log("inside download excel", filterConfig);
    let payload = {}

    if (filterConfig.searchVal.length > 0) {
      payload.searchVal = filterConfig.searchVal;
    } else {
      delete payload.searchVal;
    }
    if (filterConfig.GSTNO.length > 0) {
      payload.GSTNO = filterConfig.GSTNO;
    } else {
      delete payload.GSTNO;
    }

    await get_supplier_rpt(payload).then(
      (res) => {
        if (res) {
          base64ToExcel(res.data, name_const.REPORT);
        }
      },
      (err) => {
        console.log("err", err);
        toast.error(err.message, {
          autoClose: 2000,
        });
        setDownloadReport(false);
      }
    );
  }

  return (
    <React.Fragment>
      <ToastContainer />
      {!privilage.read ?
        <AccessDenied />
        :

        <div className="min-vh-100" id="root_div_main">
          <div id="mySidebar" className="customsidebar ">
            <a className="closebtn" onClick={() => toggleNav()}>
              ×
            </a>

            <div className=" content">
              <div>
                <label
                  className="filterLabel mb-1 roboto-bold"
                  style={{ fontSize: "14px" }}
                >
                  {constLabel?.lbl_supplier_name
                    ? constLabel.lbl_supplier_name
                    : "Supplier Name"}
                </label>

                <input
                  className="form-control"
                  type="text"
                  placeholder="Search by supplier name"
                  value={filterConfig.searchVal}
                  onChange={(val) => {
                    const inputValue = val.target.value;
                    if (!inputValue.trim()) {
                      if (filterConfig.searchVal.length == 1) {
                        setFilterConfig({ ...filterConfig, searchVal: "" });
                      }

                      if (inputValue.length == 0) {
                        setFilterConfig({ ...filterConfig, searchVal: "" });
                      }
                    } else {
                      setFilterConfig({ ...filterConfig, searchVal: inputValue });
                    }
                  }}
                />
              </div>

              <div className="mt-2">
                <label
                  className="filterLabel mb-1 roboto-bold"
                  style={{ fontSize: "14px" }}
                >
                  {constLabel?.lbl_gst_no ? constLabel?.lbl_gst_no : "GST No"}
                </label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Search by gst no"
                  value={filterConfig.GSTNO}
                  onChange={(val) => {
                    const inputValue = val.target.value;
                    if (!inputValue.trim()) {
                      // Input contains only whitespace, don't trigger the search.
                      if (filterConfig.GSTNO.length == 1) {
                        setFilterConfig({ ...filterConfig, GSTNO: "" });
                      }

                      if (inputValue.length == 0) {
                        setFilterConfig({ ...filterConfig, GSTNO: "" });
                      }
                    } else {
                      setFilterConfig({
                        ...filterConfig,
                        GSTNO: inputValue,
                      });
                    }
                  }}
                />
              </div>

              <div className="my-3 d-flex justify-content-end">
                <button
                  className="btn btn-sm btn-yellow"
                  onClick={() => clearFilter()}
                >
                  <FontAwesomeIcon
                    style={{ color: "#344454" }}
                    icon={faBan}
                    size="sm"
                  />{" "}
                  {constLabel?.lbl_clear_filter
                    ? constLabel.lbl_clear_filter
                    : "Clear Filter"}
                </button>
              </div>
            </div>
          </div>
          <div className="content-wrapper">
            <div className="card border-0">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="d-md-flex justify-content-start col-12 col-md-6">
                    {/* <FontAwesomeIcon
                    onClick={() => toggleNav()}
                    className="cursor_pointer mb-2"
                    style={{
                      color: "Black",
                      marginRight: "10px",
                    }}
                    icon={faBars}
                    size="lg"
                  /> */}
                    <Tooltip id={"open-tooltip"} place="top" />
                    <img
                      src={Slide}
                      height="32"
                      width="32"
                      className="cursor_pointer my-1 me-2"
                      onClick={() => toggleNav()}
                      data-tooltip-id={"open-tooltip"}
                      data-tooltip-content={"Filters"}
                    />
                    <h1 className="bold">
                      {constLabel?.lbl_supplier
                        ? constLabel.lbl_supplier
                        : "Supplier"}
                    </h1>
                  </div>

                  <div className="d-md-flex justify-content-end align-items-center col-12 col-md-6">
                    
                    {privilage.write && (
                      <button
                        className="btn button-primary me-0 me-md-2 mt-2 mt-md-0"
                        onClick={() => {
                          navigate("/pcms/addSupplier");
                        }}
                      >
                        <FontAwesomeIcon
                          style={{ color: "#fff" }}
                          icon={faPlus}
                          size="sm"
                        />{" "}
                        {constLabel?.lbl_add_supplier
                          ? constLabel.lbl_add_supplier
                          : "Add Supplier"}
                      </button>
                    )}

                  {privilage.report &&
                    <>
                      <Tooltip id={"download-tooltip"} place="top" />
                      <button
                        className="btn btn-outline-secondary me-0 me-md-2 mt-2 mt-md-0"
                        data-tooltip-id={"download-tooltip"}
                        data-tooltip-content={
                          constLabel?.lbl_supplier_report
                            ? constLabel.lbl_supplier_report
                            : "Supplier Report"}
                          onClick={() => { goToDownload() }}
                        disabled={downloadReport}
                      >
                        <FontAwesomeIcon
                          style={{ color: "#000000" }}
                          icon={faFileExcel}
                          size="lg"
                        />{" "}{downloadReport && <Spinner animation="border" size="sm" />}
                      </button>

                      <Tooltip id={"download-tooltip"} place="top" />
                      <button
                        className="btn btn-light-grey me-0 me-md-2 mt-2 mt-md-0"
                        data-tooltip-id={"download-tooltip"}
                        data-tooltip-content={
                          constLabel?.lbl_download_template
                            ? constLabel.lbl_download_template
                            : "Download Template"}
                          onClick={() => {
                            setDownloadTemplate(true);
                            download_supplier_template().then((res) => {
                              // console.log("res", res);
                              base64ToExcel(res.data);
                            }, (err) => {
                              console.log("err", err);
                              toast.error("Something went wrong", {
                                autoClose: 2000,
                              });
                              setDownloadTemplate(false);
                            })
                          }}
                        disabled={downloadTemplate}
                      >
                        <FontAwesomeIcon
                          style={{ color: "#fff" }}
                          icon={faDownload}
                          size="sm"
                        />{" "}{downloadTemplate && <Spinner animation="border" size="sm" />}
                      </button>

                      <Tooltip id={"upload-tooltip"} place="top" />
                      <input
                        type="file"
                        accept=".xls, .xlsx" // Define allowed file types
                        onChange={handleFileChange}
                        style={{ display: "none" }} // Hide the input element
                        id="fileInput"
                      />
                      <label htmlFor="fileInput">
                        <button
                          className="btn btn-grey me-0 me-md-2 mt-2 mt-md-0"
                          data-tooltip-id={"upload-tooltip"}
                          data-tooltip-content={
                            constLabel?.lbl_bulk_import
                              ? constLabel.lbl_bulk_import
                              : "Bulk Import"}
                          onClick={() =>
                            document.getElementById("fileInput").click()
                          }
                          onChange={handleFileChange}
                        >
                          <FontAwesomeIcon
                            style={{ color: "#fff" }}
                            icon={faUpload}
                            size="sm"
                          />{" "}{importData && <Spinner animation="border" size="sm" />}
                        </button>
                      </label>
                    </>
                  }

                    <Tooltip id={"show-tooltip"} place="top" />
                    <div
                      className="dropdown"
                      data-tooltip-id={"show-tooltip"}
                      data-tooltip-content={"Show / Hide Columns"}
                    >
                      <div
                        className="d-md-flex justify-content-start align-items-center"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {/* <FontAwesomeIcon icon={faListCheck} size="lg" /> */}
                        {/* <List size={24} className="menu_icon_grey cursor_pointer"/> */}
                        <img
                          src={List}
                          height="32"
                          width="32"
                          className="cursor_pointer"
                        />
                      </div>

                      <ul className="dropdown-menu px-2">
                        {showLabel !== undefined && showLabel.length > 0
                          ? showLabel.map((val, ind, arr) => (
                            <li key={ind}>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  style={{ width: "20px", height: "20px" }}
                                  onChange={() => handleClick(ind)}
                                  value=""
                                  checked={val.flag}
                                />
                                <label
                                  className=""
                                  style={{ fontSize: "13px" }}
                                  htmlFor="flexCheckDefault"
                                >
                                  {val.label}
                                </label>
                              </div>
                            </li>
                          ))
                          : null}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  {dataList.loading ? (
                    <div className="text-center">
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ) : dataList.data && dataList.data.length > 0 ? (
                    <>
                      <div className="table-responsive">
                        <table
                          className="table table-bordered"
                        >
                          <thead className="table-grey roboto">
                            <tr className="f-14 ">
                              <th scope="col">{"Sr.No"}</th>
                              {showLabel.map((v, i) =>
                                v.label === "Supplier Name" && v.flag ? (
                                  <th key={i} scope="col">
                                    {"Supplier Name"}
                                  </th>
                                ) : v.label === "Supplier Type" && v.flag ? (
                                  <th key={i} scope="col">
                                    {"Supplier Type"}
                                  </th>
                                ) : v.label === "Contact No" && v.flag ? (
                                  <th key={i} scope="col">
                                    {"Contact No"}
                                  </th>
                                ) : v.label === "Email ID" && v.flag ? (
                                  <th key={i} scope="col">
                                    {"Email ID"}
                                  </th>
                                ) : v.label === "Website" && v.flag ? (
                                  <th key={i} scope="col">
                                    {"Website"}
                                  </th>
                                ) : v.label === "GST Type" && v.flag ? (
                                  <th key={i} scope="col">
                                    {"GST Type"}
                                  </th>
                                ) : v.label === "GST No" && v.flag ? (
                                  <th key={i} scope="col">
                                    {"GST No"}
                                  </th>
                                ) : v.label === "PAN No" && v.flag ? (
                                  <th key={i} scope="col">
                                    {"PAN No"}
                                  </th>
                                ) : v.label === "Vendor Code" && v.flag ? (
                                  <th key={i} scope="col">
                                    {"Vendor Code"}
                                  </th>
                                ) : v.label === "Contact Person Name" &&
                                  v.flag ? (
                                  <th key={i} scope="col">
                                    {"Contact Person Name"}
                                  </th>
                                ) : v.label === "Country" && v.flag ? (
                                  <th key={i} scope="col">
                                    {"Country"}
                                  </th>
                                ) : v.label === "State" && v.flag ? (
                                  <th key={i} scope="col">
                                    {"State"}
                                  </th>
                                ) : v.label === "City" && v.flag ? (
                                  <th key={i} scope="col">
                                    {"City"}
                                  </th>
                                ) : null
                              )}

                              <th scope="col" width="15%">
                                {"Action"}
                              </th>
                            </tr>
                          </thead>
                          <tbody className=" roboto">
                            {dataList.data.map((val, i) => (
                              <tr key={i}>
                                <td>{i + srNo + 1}</td>
                                {showLabel.map((item, idx) =>
                                  item.label === "Supplier Name" && item.flag ? (
                                    <td key={idx}>
                                      {val.name !== "" && val.name !== null
                                        ? val.name
                                        : "NA"}
                                    </td>
                                  ) : item.label === "Supplier Type" &&
                                    item.flag ? (
                                    <td key={idx}>{val.supp_type}</td>
                                  ) : item.label === "Contact No" && item.flag ? (
                                    <td key={idx}>
                                      {val.contactPersonContact !== "" &&
                                        val.contactPersonContact !== null
                                        ? val.contactPersonContact
                                        : "NA"}
                                    </td>
                                  ) : item.label === "Email ID" && item.flag ? (
                                    <td key={idx}>
                                      {val.email !== "" && val.email !== null
                                        ? val.email
                                        : "NA"}
                                    </td>
                                  ) : item.label === "Website" && item.flag ? (
                                    <td key={idx}>
                                      {val.website !== "" && val.website !== null
                                        ? val.website
                                        : "NA"}
                                    </td>
                                  ) : item.label === "GST Type" && item.flag ? (
                                    <td key={idx}>{val.gst_treatment}</td>
                                  ) : item.label === "GST No" && item.flag ? (
                                    <td key={idx}>
                                      {val.gst !== "" && val.gst !== null
                                        ? val.gst
                                        : "NA"}
                                    </td>
                                  ) : item.label === "PAN No" && item.flag ? (
                                    <td key={idx}>
                                      {val.PANNO !== "" && val.PANNO !== null
                                        ? val.PANNO
                                        : "NA"}
                                    </td>
                                  ) : item.label === "Vendor Code" &&
                                    item.flag ? (
                                    <td key={idx}>
                                      {val.vendor_code !== "" &&
                                        val.vendor_code !== null
                                        ? val.vendor_code
                                        : "NA"}
                                    </td>
                                  ) : item.label === "Contact Person Name" &&
                                    item.flag ? (
                                    <td key={idx}>
                                      {val.contactPersonName !== "" &&
                                        val.contactPersonName !== null
                                        ? val.contactPersonName
                                        : "NA"}
                                    </td>
                                  ) : item.label === "Country" && item.flag ? (
                                    <td key={idx}>
                                      {val.country_name !== "" &&
                                        val.country_name !== null
                                        ? val.country_name
                                        : "NA"}
                                    </td>
                                  ) : item.label === "State" && item.flag ? (
                                    <td key={idx}>
                                      {val.state_name !== "" &&
                                        val.state_name !== null
                                        ? val.state_name
                                        : "NA"}
                                    </td>
                                  ) : item.label === "City" && item.flag ? (
                                    <td key={idx}>
                                      {val.city_name !== "" &&
                                        val.city_name !== null
                                        ? val.city_name
                                        : "NA"}
                                    </td>
                                  ) : null
                                )}

                                <td className="">
                                  {/* <div className="editIcon me-2" onClick={() => delSupplier(val)}>
                                  <Edit2 color="#FFFFFF" size={18} />
                                </div>
                                */}

                                  {/* {privilage.write && <EditSupplier data={val} />} */}
                                  {privilage.write && (
                                    <>
                                      <Tooltip
                                        id={"edit-tooltip" + 1}
                                        place="top"
                                      />
                                      <Edit
                                        data-tooltip-id={"edit-tooltip" + 1}
                                        data-tooltip-content={
                                          constLabel?.lbl_edit_supplier
                                            ? constLabel.lbl_edit_supplier
                                            : "Edit Supplier"
                                        }
                                        className="menu_icon_grey cursor_pointer me-2"
                                        size={20}
                                        onClick={() =>
                                          navigate("/pcms/editSupplier", {
                                            state: val,
                                          })
                                        }
                                      />
                                    </>
                                  )}

                                  {privilage.read && (
                                    <>
                                      <Tooltip
                                        id={"view-tooltip" + 1}
                                        place="top"
                                      />
                                      <Eye
                                        data-tooltip-id={"view-tooltip" + 1}
                                        data-tooltip-content={
                                          constLabel?.lbl_view_supplier
                                            ? constLabel.lbl_view_supplier
                                            : "View Supplier"
                                        }
                                        className="menu_icon_grey cursor_pointer me-2"
                                        size={20}
                                        onClick={() =>
                                          navigate("/pcms/supplierDetails/", {
                                            state: val,
                                          })
                                        }
                                      />
                                    </>
                                  )}

                                  {privilage.delete && (
                                    <>
                                      <Tooltip
                                        id={"delete-tooltip" + i}
                                        place="top"
                                      />
                                      <Trash2
                                        data-tooltip-id={"delete-tooltip" + i}
                                        data-tooltip-content={
                                          constLabel?.lbl_delete_supplier
                                            ? constLabel.lbl_delete_supplier
                                            : "Delete Supplier"
                                        }
                                        className="menu_icon_red cursor_pointer"
                                        size={20}
                                        onClick={() => delSupplier(val)}
                                      />
                                    </>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <div className="d-flex justify-content-between align-items-center mt-2">
                        <p className="mb-0">
                          Show
                          <select
                            className="mx-1"
                            defaultValue={entriesPerPage}
                            onChange={(e) => {
                              setEntriesPerPage(e.target.value);
                              fetchSuppliers(1, e.target.value);
                              setSrNo(0);
                              setPage(1);
                            }}
                          >
                            <option value={"10"}>10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                          Entries
                        </p>
                        <p className="mb-0">{`Showing ${parseInt(srNo) + 1
                          } to ${Math.min(
                            parseInt(entriesPerPage) + parseInt(srNo),
                            dataList.totalRecords
                          )} of ${dataList.totalRecords} entries`}</p>
                        <Pagination className="my-2">
                          <Pagination.Prev
                            disabled={page === 1 ? true : false}
                            onClick={() => {
                              fetchSuppliers(
                                page - 1,
                                entriesPerPage,
                                parseInt(srNo) - parseInt(entriesPerPage)
                              );
                              setSrNo((prevC) =>
                                page - 1 == 1 ? 0 : prevC - entriesPerPage
                              );
                              setPage(page - 1);
                            }}
                          >
                            {"Prev"}
                          </Pagination.Prev>

                          <Pagination.Item active>{page}</Pagination.Item>

                          <Pagination.Next
                            disabled={
                              page === maxPage ||
                                maxPage === 0 ||
                                entriesPerPage > dataList.data.length
                                ? true
                                : false
                            }
                            onClick={() => {
                              fetchSuppliers(
                                page + 1,
                                entriesPerPage,
                                parseInt(srNo) + parseInt(entriesPerPage)
                              );
                              setSrNo(
                                (prevC) => prevC + parseInt(entriesPerPage)
                              );
                              setPage(page + 1);
                            }}
                          >
                            {"Next"}
                          </Pagination.Next>
                        </Pagination>
                      </div>
                    </>
                  ) : (
                    <div
                      className="alert alert-danger mb-0 text-center my-4"
                      role="alert"
                    >
                      {"No supplier data found !"}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </React.Fragment>
  );
}

export default Supplier;
