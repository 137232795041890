import React, { useEffect, useState } from "react";
import "../../../TreeView.css";
import {
  getInternalCompany,
  get_store,
  getLocation,
  deleteLocation,
  delete_internal_company,
  delLocation,
  delete_store,
} from "../../../api";
import {
  faAdd,
  faFilter,
  faTrashAlt,
  faEdit,
  faAlignCenter,
  faCaretDown,
  faCaretUp,
  faList,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import Building from "../../../assets/images/office.png";
import store from "../../../assets/images/shop.png";
import EnterKey from "../../../assets/images/EnterKey.png";
import { Tooltip } from "react-tooltip";
import { PlusSquare, Trash2, Edit2, Eye } from "react-feather";
import SweetAlert from "sweetalert2";
import CreateLocation from "./CreateLocation";
import EditLocation from "./EditLocation";
import { ToastContainer, toast } from "react-toastify";
import CreateStore from "./CreateStore";
import EditStore from "./EditStore";
import { useSelector } from "react-redux";
import { setAccessPrivilage } from "../../../common/Functions/CommonFunctions";
import AccessDenied from "../../Common/AccessDenied";

function InterCom() {
  const [company, setCompany] = useState({});
  const [selLoc, setSelLoc] = useState({});
  const [selStore, setSelStore] = useState({});
  const [active, setActive] = useState({ val: {}, flag: false });

  const [treeobj, setTreeobj] = useState([]);
  const [loader, setLoader] = useState(true);

  const [locationModal, setLocationModal] = useState(false);
  const [storeModal, setStoreModal] = useState(false);
  const [storeModal2, setStoreModal2] = useState(false);

  const [locationModal2, setLocationModal2] = useState(false);
  const [storeList, setStoreList] = useState({ loading: true, data: [] });
  const [locationList, setLocationList] = useState({ loading: true, data: [] });

  let navigate = useNavigate();

  const loginData = useSelector(
    (state) => state.auth?.loginData?.data?.response
  );

  const [privilage] = useState(setAccessPrivilage(loginData, "Organization"));
  const [privilage1] = useState(setAccessPrivilage(loginData, "Location"));
  const [privilage2] = useState(setAccessPrivilage(loginData, "Store"));

  const [filterConfig, setFilterConfig] = useState({
      comName: "",
    });

  useEffect(() => {
    toggleNav();
    // fetchInternal_company();
  }, []);

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      fetchInternal_company();
    }, 1000); // Adjust the delay as needed (e.g., 500 milliseconds)

    return () => {
      clearTimeout(debounceTimeout); // Clear the timeout if searchTerm changes before the timeout expires
    };
  }, [filterConfig]);

  const handleClick = (val) => {
    // setStoreList({ ...storeList, loading: false, data: [] });
    setCompany(val);
    setActive({ ...active, val: val, flag: true });

    setLocationList({ ...locationList, loading: true });
    getLocation({ company_id: val.uuid }).then(
      (res) => {
        setLocationList({
          ...locationList,
          data: res.data.data,
          loading: false,
        });
        if (res.data.data.length > 0) {
          fetchStoreList(res.data?.data[0]);
          setSelLoc(res.data?.data[0]);
        } else {
          setStoreList({ ...storeList, loading: false, data: [] });
          setSelLoc([]);
        }
      },
      (err) => {
        setLocationList({ ...locationList, data: [], loading: false });
      }
    );
  };

  const fetchInternal_company = () => {
    const payload = {
      with_location: 1
    }

    if (filterConfig.comName !== "") {
      payload.comName = filterConfig.comName.trim(); //Search by company name
    } else {
      delete payload.comName;
    }

    getInternalCompany(payload)
      .then((res) => {
        const result = res.data.data.filter((val) => {
          return val.parent_id == null;
        });
        handleClick(result[0]);
        setTreeobj(result);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });
  };
  function openNav() {
    document.getElementById("mySidebar").style.width = "320px";
    document.getElementById("root_div_main").style.marginLeft = "320px";
    setTimeout(() => {
      document.getElementById("mySidebar").style.overflowX = "visible";
    }, [200]);
  }

  function closeNav() {
    document.getElementById("mySidebar").style.width = "0";
    document.getElementById("root_div_main").style.marginLeft = "0";

    document.getElementById("mySidebar").style.overflowX = "hidden";
  }

  function fetchStoreList(val) {
    setStoreList({ ...storeList, loading: true, data: [] });

    get_store({ location_id: val?.uuid }).then(
      (res) => {
        setStoreList({
          ...storeList,
          loading: false,
          data: res.data.data,
        });

        // handleClick(company);
      },
      (err) => {
        setStoreList({ ...storeList, loading: false, data: [] });
      }
    );
  }

  function CommonDelete(type, val) {
    SweetAlert.fire({
      title: "Are you sure to delete ?",
      // text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        if (type == "loc") {
          delLocation(val);
        } else if (type == "store") {
          delStore(val);
        }
      }
    });
  }

  const delLocation = (val) => {
    const payload = {
      uuid: val.uuid,
    };
    deleteLocation(payload).then(
      (res) => {
        toast.success("Location deleted successfully !", {
          autoClose: 3000,
        });
        handleClick(company);
      },
      (err) => {
        toast.error("Something went wrong !", { autoClose: 3000 });
      }
    );
  };

  const delStore = (val) => {
    const payload = {
      uuid: val.uuid,
    };
    delete_store(payload).then(
      (res) => {
        toast.success("Store deleted successfully !", {
          autoClose: 3000,
        });
        fetchStoreList(selLoc);
      },
      (err) => {
        toast.error("Something went wrong !", { autoClose: 3000 });
      }
    );
  };

  function toggleNav() {
    const sidebar = document.getElementById("mySidebar");
    const mainDiv = document.getElementById("root_div_main");

    if (sidebar.style.width === "320px") {
      // Sidebar is open, so close it
      sidebar.style.width = "0";
      mainDiv.style.marginLeft = "0";
      sidebar.style.overflowX = "hidden";
    } else {
      // Sidebar is closed, so open it
      sidebar.style.width = "320px";
      mainDiv.style.marginLeft = "320px";
      setTimeout(() => {
        sidebar.style.overflowX = "visible";
      }, 200);
    }
  }

  function clearFilter() {
    let obj = {
      comName: "",
    };
    setFilterConfig({ ...obj });
  }

  return (
    <React.Fragment>
      <CreateLocation
        locationModal={locationModal}
        setLocationModal={setLocationModal}
        company_data={company}
        handleClick={handleClick}
      // fetchInternal_company={fetchInternal_company}
      />

      <EditLocation
        locationModal2={locationModal2}
        setLocationModal2={setLocationModal2}
        selLoc={selLoc}
        company_data={company}
        handleClick={handleClick}
      // fetchInternal_company={fetchInternal_company}
      />

      {storeModal ? (
        <CreateStore
          storeModal={storeModal}
          setStoreModal={setStoreModal}
          selLoc={selLoc}
          company_data={company}
          fetchStoreList={fetchStoreList}
        />
      ) : null}

      <EditStore
        storeModal2={storeModal2}
        setStoreModal2={setStoreModal2}
        selLoc={selLoc}
        selStore={selStore}
        company_data={company}
        fetchStoreList={fetchStoreList}
      />

      {!privilage.read ?
        <AccessDenied />
        :

        <div className="min-vh-100 internal_com" id="root_div_main">
          <div
            id="mySidebar"
            className="customsidebar "
            style={{ backgroundColor: "white", overflowX: "auto" }}
          >
            <div className="">
              <input
                className="form-control m-2"
                type="text"
                placeholder="Search by company name"
                value={filterConfig.comName}
                onChange={(val) => {
                  const inputValue = val.target.value;
                  if (!inputValue.trim()) {
                    // Input contains only whitespace, don't trigger the search.
                    if (filterConfig.comName.length == 1) {
                      setFilterConfig({ ...filterConfig, comName: "" });
                    }

                    if (inputValue.length == 0) {
                      setFilterConfig({ ...filterConfig, comName: "" });
                    }
                  } else {
                    setFilterConfig({
                      ...filterConfig,
                      comName: inputValue,
                    });
                  }
                }}
                style={{ width: "265px" }}
              />
            </div>
            <a className="closebtn" onClick={() => clearFilter()}>
              ×
            </a>

            {loader ? (
              <div className="text-center">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              <div className="content">
                <div className="w-100" style={{ background: "#344454" }}>
                  <p
                    className="mb-0 px-3 py-2 roboto-bold"
                    style={{ color: "white" }}
                  >
                    Organisation Hierarchy
                  </p>
                </div>

                <TreeView
                  treeobj={treeobj}
                  handleClick={handleClick}
                  active={active}
                  fetchInternal_company={fetchInternal_company}
                  privilage={privilage}
                />
              </div>
            )}
          </div>

          <div className="content-wrapper">
            <div className="d-flex justify-content-start align-items-center mb-2">
              <button
                style={{ maxHeight: "30px" }}
                onClick={() => toggleNav()}
                className="btn btn-sm btn-grey my-auto"
              >
                <FontAwesomeIcon
                  style={{
                    color: "white",
                  }}
                  icon={faList}
                  size="sm"
                />
              </button>
              <h1 className="bold ms-2 pt-2">{"Organisation Hierarchy"}</h1>

              {/* <span className='cursor_pointer px-3'
                            onClick={() => {
                                openNav()
                            }}>
                            <FontAwesomeIcon
                                style={{ marginRight: "5px" }}
                                icon={faFilter}
                                size="sm"
                            />View Organisation List</span> */}
            </div>

            <div className="col-12 mt-3">
              <div className="card">
                <div className="card-header bg-grey d-flex align-items-center">
                  {/* <button
                                    className="btn btn-yellow"
                                    onClick={() => navigate(-1)}
                                >
                                    <FontAwesomeIcon icon={faArrowLeft} size="sm" />
                                </button> */}

                  <p
                    className="mb-0 roboto-bold py-1"
                    style={{ fontSize: "16px" }}
                  >
                    {Object.keys(company).length > 0 ? (
                      company.comName
                    ) : (
                      <span className="invisible">Company Name</span>
                    )}
                  </p>
                </div>
                <div className="card-body">
                  {Object.keys(company).length > 0 ? (
                    <div>
                      <div className="row">
                        <h4 className="mb-0 roboto-bold">Company Details</h4>

                        <hr className="my-3 mx-2" />

                        <div className="col-12 col-md-4">
                          <div>
                            <p className="m-0 roboto-bold">{`Parent`}</p>
                            <p className="mt-1 mb-0 ">
                              {company.parent_name !== null
                                ? company.parent_name
                                : "NA"}
                            </p>
                          </div>
                        </div>

                        <div className="col-12 col-md-4">
                          <div>
                            <p className="m-0 roboto-bold">{`Company Name`}</p>
                            <p className="mt-1 mb-0 ">
                              {company.comName !== null ? company.comName : "NA"}
                            </p>
                          </div>
                        </div>

                        <div className="col-12 col-md-4">
                          <div>
                            <p className="m-0 roboto-bold">{`Company Type`}</p>
                            <p className="mt-1 mb-0 ">
                              {company.company_type !== null
                                ? company.company_type
                                : "NA"}
                            </p>
                          </div>
                        </div>

                        <div className="col-12 col-md-4 mt-4">
                          <div>
                            <p className="m-0 roboto-bold">{`First Name`}</p>
                            <p className="mt-1 mb-0 ">
                              {company.first_name !== null
                                ? company.first_name
                                : "NA"}
                            </p>
                          </div>
                        </div>

                        <div className="col-12 col-md-4 mt-4">
                          <div>
                            <p className="m-0 roboto-bold">{`Middle Name`}</p>
                            <p className="mt-1 mb-0 ">
                              {company.middle_name !== null
                                ? company.middle_name
                                : "NA"}
                            </p>
                          </div>
                        </div>

                        <div className="col-12 col-md-4 mt-4">
                          <div>
                            <p className="m-0 roboto-bold">{`Last Name`}</p>
                            <p className="mt-1 mb-0 ">
                              {company.last_name !== null
                                ? company.last_name
                                : "NA"}
                            </p>
                          </div>
                        </div>

                        <div className="col-12 col-md-4 mt-4">
                          <div>
                            <p className="m-0 roboto-bold">{`GST Number`}</p>
                            <p className="mt-1 mb-0 ">
                              {company.comGSTnumber !== null &&
                                company.comGSTnumber.length > 0
                                ? company.comGSTnumber
                                : "NA"}
                            </p>
                          </div>
                        </div>

                        <div className="col-12 col-md-4 mt-4">
                          <div>
                            <p className="m-0 roboto-bold">{`Phone Number`}</p>
                            <p className="mt-1 mb-0 ">
                              {company.phone !== null ? company.phone : "NA"}
                            </p>
                          </div>
                        </div>
                        <div className="col-12 col-md-4 mt-4">
                          <div>
                            <p className="m-0 roboto-bold">{`Organization Type`}</p>
                            <p className="mt-1 mb-0 ">
                              {company.orgnisation_type !== null
                                ? company.orgnisation_type
                                : "NA"}
                            </p>
                          </div>
                        </div>

                        <div className="col-12 col-md-4 mt-4">
                          <div>
                            <p className="m-0 roboto-bold">{`GST Type`}</p>
                            <p className="mt-1 mb-0 ">
                              {company.gst_type !== null
                                ? company.gst_type
                                : "NA"}
                            </p>
                          </div>
                        </div>

                        <div className="col-12 col-md-4 mt-4">
                          <div>
                            <p className="m-0 roboto-bold">{`Company Address`}</p>
                            <p className="mt-1 mb-0 ">
                              {company.comaddress !== null
                                ? company.comaddress
                                : "NA"}
                            </p>
                          </div>
                        </div>

                        <div className="col-12 col-md-4 mt-4">
                          <div>
                            <p className="m-0 roboto-bold">{`Industry`}</p>
                            <p className="mt-1 mb-0 ">
                              {company.industry !== null &&
                                company.industry.length > 0
                                ? company.industry
                                : "NA"}
                            </p>
                          </div>
                        </div>

                        <div className="col-12 col-md-4 mt-4">
                          <div>
                            <p className="m-0 roboto-bold">{`Mail`}</p>
                            <p className="mt-1 mb-0 ">
                              {company.mail !== null ? company.mail : "NA"}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="row mt-5">
                        <div className="d-flex justify-content-between align-items-center">
                          <h4 className="mb-0 roboto-bold">Location</h4>

                          <div>
                            {privilage1?.write &&
                              <>
                                <Tooltip id={"c-po-tooltip"} place="top" />
                                <div
                                  data-tooltip-id={"c-po-tooltip"}
                                  data-tooltip-content="Create Location"
                                  className="createIcon_custom cursor_pointer ms-2 position-relative"
                                  onClick={() => {
                                    setLocationModal(true);
                                  }}
                                >
                                  <FontAwesomeIcon
                                    color="#FFFFFF"
                                    icon={faAdd}
                                    size={"sm"}
                                    style={{
                                      position: "absolute",
                                      top: "50%",
                                      left: "50%",
                                      transform: "translate(-50%,-50%)",
                                    }}
                                  />
                                </div>
                              </>
                            }
                          </div>
                        </div>

                        <hr className="my-3 mx-2" />

                        {locationList.loading ? (
                          <div className="text-center">
                            <div className="spinner-border" role="status">
                              <span className="visually-hidden">Loading...</span>
                            </div>
                          </div>
                        ) : (
                          <div>
                            {locationList.data?.length > 0 ? (
                              <div className="table-responsive">
                                <table className="table mt-2 table-bordered">
                                  <thead className="table-grey roboto-bold">
                                    <tr className="">
                                      <th scope="col" className="py-3">
                                        {"Sr.No"}
                                      </th>
                                      <th scope="col" className="py-3">
                                        {"Name"}
                                      </th>
                                      <th scope="col" className="py-3">
                                        {"Address"}
                                      </th>

                                      <th
                                        scope="col"
                                        className="py-3"
                                        width="15%"
                                      >
                                        {"Action"}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="roboto">
                                    {locationList.data.map((val, i) => (
                                      <tr className="" key={i}>
                                        <td>{i + 1}</td>
                                        <td>{val.locationname}</td>
                                        <td>{val.locationaddress}</td>

                                        <td>
                                          {privilage1?.read &&
                                            <>
                                              <Tooltip
                                                id={"view-tooltip" + i}
                                                place="top"
                                              />
                                              <div
                                                data-tooltip-id={"view-tooltip" + i}
                                                data-tooltip-content="View Stores"
                                                onClick={() => {
                                                  setSelLoc(val);
                                                  fetchStoreList(val);
                                                }}
                                                className="editIcon m-1 cursor_pointer"
                                              >
                                                <img
                                                  src={store}
                                                  height={18}
                                                  width={18}
                                                />
                                              </div>
                                            </>
                                          }

                                          {privilage1?.write &&
                                            <>
                                              <Tooltip
                                                id={"edit-tooltip" + i}
                                                place="top"
                                              />
                                              <div
                                                data-tooltip-id={"edit-tooltip" + i}
                                                data-tooltip-content="Update Location"
                                                className="downloadIcon m-1 cursor_pointer"
                                                onClick={() => {
                                                  setSelLoc(val);
                                                  setLocationModal2(true);
                                                }}
                                              >
                                                <Edit2 color="#FFFFFF" size={18} />
                                              </div>
                                            </>
                                          }

                                          {privilage1?.delete &&
                                            <>
                                              <Tooltip
                                                id={"delete-tooltip" + i}
                                                place="top"
                                              />
                                              <div
                                                data-tooltip-id={"delete-tooltip" + i}
                                                data-tooltip-content="Delete Location"
                                                className="deleteIcon m-1 cursor_pointer"
                                                onClick={() =>
                                                  CommonDelete("loc", val)
                                                }
                                              >
                                                <Trash2 color="#FFFFFF" size={18} />
                                              </div>
                                            </>
                                          }
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            ) : (
                              <div
                                className="alert alert-danger mb-0 text-center"
                                role="alert"
                              >
                                {"No Location Found"}
                              </div>
                            )}
                          </div>
                        )}
                      </div>

                      <div className="row mt-5">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="d-flex">
                            <h4 className="mb-0 roboto-bold">Stores</h4>

                            {Object.keys(selLoc).length > 0 ? (
                              <p
                                className="mb-0 text-muted mt-auto ms-2"
                                style={{ fontSize: "14px" }}
                              >
                                {`(Showing stores for ${selLoc.locationname})`}
                              </p>
                            ) : null}
                          </div>

                          <div>
                            {privilage2?.write &&
                              <>
                                <Tooltip id={"c-po-tooltip"} place="top" />
                                <div
                                  data-tooltip-id={"c-po-tooltip"}
                                  data-tooltip-content="Create Store"
                                  className="createIcon_custom cursor_pointer ms-2 position-relative"
                                  onClick={() => {
                                    if (Object.keys(selLoc).length !== 0) {
                                      setStoreModal(true);
                                    } else {
                                      toast.error("Select a location !", {
                                        autoClose: 3000,
                                      });
                                    }
                                  }}
                                >
                                  <FontAwesomeIcon
                                    color="#FFFFFF"
                                    icon={faAdd}
                                    size={"sm"}
                                    style={{
                                      position: "absolute",
                                      top: "50%",
                                      left: "50%",
                                      transform: "translate(-50%,-50%)",
                                    }}
                                  />
                                </div>
                              </>
                            }
                          </div>
                        </div>

                        <hr className="my-3 mx-2" />
                        {storeList.loading ? (
                          <div className="text-center">
                            <div className="spinner-border" role="status">
                              <span className="visually-hidden">Loading...</span>
                            </div>
                          </div>
                        ) : (
                          <div>
                            <div>
                              {storeList.data?.length > 0 ? (
                                <div className="table-responsive">
                                  <table className="table mt-2 table-bordered">
                                    <thead className="table-grey roboto-bold">
                                      <tr className="">
                                        <th scope="col" className="py-3">
                                          {"Sr.No"}
                                        </th>
                                        <th scope="col" className="py-3">
                                          {"Store Name"}
                                        </th>
                                        <th scope="col" className="py-3">
                                          {"Store Type"}
                                        </th>

                                        <th
                                          scope="col"
                                          className="py-3"
                                          width="15%"
                                        >
                                          {"Action"}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="roboto">
                                      {storeList.data.map((val, i) => (
                                        <tr className="cursor_pointer" key={i}>
                                          <td>{i + 1}</td>
                                          <td>{val.name}</td>
                                          <td>{val.store_types}</td>

                                          <td>
                                            {privilage2?.write &&
                                              <>
                                                <Tooltip
                                                  id={"edit-tooltip" + i}
                                                  place="top"
                                                />
                                                <div
                                                  data-tooltip-id={"edit-tooltip" + i}
                                                  data-tooltip-content="Update Store"
                                                  className="downloadIcon m-1 cursor_pointer"
                                                  onClick={() => {
                                                    setStoreModal2(true);
                                                    setSelStore(val);
                                                  }}
                                                >
                                                  <Edit2 color="#FFFFFF" size={18} />
                                                </div>
                                              </>
                                            }

                                            {privilage2?.delete &&
                                              <>
                                                <Tooltip
                                                  id={"delete-tooltip" + i}
                                                  place="top"
                                                />
                                                <div
                                                  data-tooltip-id={
                                                    "delete-tooltip" + i
                                                  }
                                                  data-tooltip-content="Delete Store"
                                                  className="deleteIcon m-1 cursor_pointer"
                                                  onClick={() =>
                                                    CommonDelete("store", val)
                                                  }
                                                >
                                                  <Trash2 color="#FFFFFF" size={18} />
                                                </div>
                                              </>
                                            }
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              ) : (
                                <div
                                  className="alert alert-danger mb-0 text-center"
                                  role="alert"
                                >
                                  {"No Store Found"}
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div
                      className="alert alert-danger mb-0 text-center"
                      role="alert"
                    >
                      {"Select a organisation"}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </React.Fragment>
  );
}

export default InterCom;

function delInternalOrg(val, fetchInternal_company) {
  SweetAlert.fire({
    title: "Are you sure to delete \n " + val.comName + " ?",
    // text: "Once deleted, you will not be able to recover this record !",
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: "Ok",
    cancelButtonText: "cancel",
    reverseButtons: true,
  }).then((result) => {
    if (result.value) {
      delete_internal_company({
        uuid: val.uuid,
      })
        .then((res) => {
          toast.success("Organization deleted successfully !", {
            autoClose: 2000,
          });
          fetchInternal_company();
        })
        .catch((err) => {
          if (err.response.status === 400) {
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
          } else {
            toast.error("Something went wrong", {
              autoClose: 2000,
            });
          }
        });
    }
  });
}

const TreeNode = ({
  node,
  handleClick,
  ren,
  active,
  fetchInternal_company,
  privilage
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  let navigate = useNavigate();
  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="tree-node">
      <div
        onClick={(event) => {
          if (
            node.internal_company_item !== undefined &&
            node.internal_company_item.length > 0
          ) {
            handleToggle();
          }
          handleClick(node);
          // var clickedDiv = event.target;
          // var parentDiv = clickedDiv.parentNode;

          // const spanEle = parentDiv.querySelector('#company_name');

          // spanEle.style.color = 'red';
        }}
        className={`node-toggle px-2 ${isExpanded ? "expanded" : ""}`}
      >
        <div className="d-flex justify-content-between align-items-center">
          <span
            id="company_name"
            className={
              active.flag == true &&
              active.val.uuid == node.uuid &&
              "active-org"
            }
          >
            {ren == "child" && node.internal_company_item.length > 0 ? (
              <img src={EnterKey} height="30" width="30" className="me-1" />
            ) : (
              ren == "child" && (
                <img
                  src={EnterKey}
                  height="30"
                  width="30"
                  className="me-1 invisible"
                />
              )
            )}
            <img src={Building} height="30" width="30" className="me-2" />
            {node.comName}
          </span>

          <div className="d-flex justify-content-center">
            {privilage?.write && (
              <FontAwesomeIcon
                icon={faAdd}
                size="sm"
                color="#817f75"
                onClick={() => {
                  navigate("/pcms/orgnization/create", { state: node });
                }}
              />
            )}

            {privilage?.write && (
              <FontAwesomeIcon
                icon={faEdit}
                size="sm"
                color="#817f75"
                className="ms-2"
                onClick={() => {
                  navigate("/pcms/orgnization/update", { state: node });
                }}
              />
            )}

            {privilage?.delete && (
              <FontAwesomeIcon
                icon={faTrashAlt}
                size="sm"
                color="#817f75"
                className="ms-2 me-2"
                onClick={() => delInternalOrg(node, fetchInternal_company)}
              />
            )}

            {isExpanded ? (
              <FontAwesomeIcon
                icon={faCaretUp}
                size="lg"
                color="#000"
                className="ms-1"
              />
            ) : (
              node.internal_company_item.length > 0 && (
                <FontAwesomeIcon
                  icon={faCaretDown}
                  size="lg"
                  color="#000"
                  className="ms-1"
                />
              )
            )}
          </div>
        </div>
      </div>
      {isExpanded && (
        <ul className="child-nodes" style={{ marginBottom: "" }}>
          {node.internal_company_item.map((childNode) => (
            <>
              <hr className="my-2" />
              <li key={childNode.uuid}>
                <TreeNode
                  node={childNode}
                  handleClick={handleClick}
                  ren="child"
                  active={active}
                  privilage={privilage}
                />
              </li>
            </>
          ))}
        </ul>
      )}
    </div>
  );
};

const TreeView = (props) => {
  return (
    <div>
      {props.treeobj.map((rootNode, i) => (
        <>
          <hr className={`${i == 0 ? "mb-2 mt-0" : "my-2"}`} />
          <TreeNode
            key={rootNode.uuid}
            node={rootNode}
            handleClick={props.handleClick}
            active={props.active}
            fetchInternal_company={props.fetchInternal_company}
            privilage={props.privilage}
          />
          {/* <hr /> */}
        </>
      ))}
    </div>
  );
};
