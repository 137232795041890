import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faImage,
  faAdd,
  faCircle,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import SweetAlert from "sweetalert2";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import BarCode from "react-barcode";
import { useLocation, useNavigate } from "react-router-dom";
import { getLabels } from "../../../common/Functions/CommonFunctions";
import { Spinner } from "react-bootstrap";

function ProductBarcode() {
  let navigate = useNavigate();
  let location = useLocation();
  const [values, setValues] = useState([""]); // Initialize with one empty string
  const [constLabel, setConstLabel] = useState({});
  const [lockBtn, setLockBtn] = useState(false);

  useEffect(() => {
    setConstLabel(getLabels("Master", "Product"));
  }, []);

  const generatePDF = async () => {
    // Initialize jsPDF with the specific label size (70mm x 55mm)
    const pdf = new jsPDF({
      unit: "mm",
      // format: [70, 55], // Label size in mm
      format: [70, 40], // Label size in mm
      orientation: "landscape", // Label orientation
    });

    for (let i = 0; i < location.state?.product_list?.length; i++) {
      setLockBtn(true);
      const canvas = await html2canvas(
        document.getElementById(`barcode-${i}`),
        {
          scale: 3, // High resolution
        }
      );
      const imgData = canvas.toDataURL("image/png");

      // Add barcode image to the PDF (fits the label size exactly)
      pdf.addImage(imgData, "PNG", 2, 2, 66, 36); // Ensure margins are correct
      // pdf.addImage(imgData, "PNG", 1, 1, 68, 38); // Ensure margins are correct

      // Add a new page for the next label, unless it's the last label
      if (i < location.state?.product_list?.length - 1) pdf.addPage();
    }

    pdf.save("product-barcode.pdf"); // Save the PDF with the generated barcodes
    setLockBtn(false);
  };

  return (
    <div className="purchase_order mb-4">
      <div className="card">
        <div className="card-header bg-grey d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <button
              className="btn btn-yellow"
              onClick={() => {
                SweetAlert.fire({
                  title: "Are you sure?",
                  text: "Your present data will be lost!",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes, go back!",
                }).then((result) => {
                  if (result.isConfirmed) {
                    window.onpopstate = undefined;

                    navigate("/pcms/product");
                  }
                });
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} size="sm" />
            </button>

            <p className="mb-0 ms-3" style={{ fontSize: "24px" }}>
              {constLabel?.lbl_product_barcode
                ? constLabel.lbl_product_barcode
                : "Product Barcode"}
            </p>
          </div>
          <div className="d-flex align-items-center">
            <button
              className="btn button-primary"
              onClick={generatePDF}
              disabled={lockBtn}
            >
              {"Generate PDF"}{" "}
              {lockBtn ? <Spinner animation="border" size="sm" /> : null}
            </button>
          </div>
        </div>

        <div className="card-body">
          <div className="my-3 d-flex justify-content-between">
            <i>
              <span className="text-danger roboto-bold">Note: </span>
              <FontAwesomeIcon
                style={{ color: "#c7c7c7" }}
                icon={faCircle}
                size="2xs"
                className="mx-2"
              />
              {"For better results, please open the PDF with "}
              <b>Adobe Acrobat Reader</b>
              {" or "}
              <b>Microsoft Edge</b>.
            </i>
          </div>
          <div className="createPO container-fluid">
            {location.state?.product_list?.length > 0 ? (
              <div className="table-responsive">
                <table className="table mt-2 table-bordered">
                  <thead className="table-grey  roboto-bold">
                    <tr>
                      <th>{"Sr.No"}</th>
                      <th>{"Product"}</th>
                      <th>{"Product Code / Part No"}</th>
                      <th>{"Barcode"}</th>
                    </tr>
                  </thead>
                  <tbody className=" roboto">
                    {location.state?.product_list?.map((val, i) => (
                      <tr key={i}>
                        <td>{parseInt(i) + 1}</td>
                        <td>{val.productName}</td>
                        <td>
                          {val.OEMnumber !== "" && val.OEMnumber !== null
                            ? val.OEMnumber
                            : "NA"}
                        </td>
                        <td>
                          <div
                            key={i}
                            id={`barcode-${i}`}
                            style={{
                              //   display: "flex",
                              //   flexDirection: 'column',
                              alignItems: "center",
                              justifyContent: "center",
                              border: "1px solid black",
                              // padding: "5px",
                              // marginBottom: "10px",
                              overflow: "hidden",
                              height: "120px",
                              //   paddingLeft: "5px",
                              //   paddingRight: "5px",
                              width: "350px",
                            }}
                          >
                            {/* Product name above the barcode */}
                            {/* <div
                              style={{ fontSize: "14px", marginBottom: "2px" }}
                            >
                              {val.productName || " "}
                            </div> */}

                            {/* Barcode */}
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <BarCode
                                value={
                                  val.OEMnumber && val.OEMnumber.trim() !== ""
                                    ? val.OEMnumber
                                    : val.productName || " "
                                }
                                width={1.8} // Width of individual barcode bars
                                height={60} // Height of barcode 60
                                fontSize={14} // Font size for the barcode text
                                displayValue={false} // Disable default text display
                                // background="red"
                              />
                            </div>

                            {/* OEM number below the barcode */}
                            <div
                              className="text-center"
                              style={{
                                fontSize: "14px",
                                letterSpacing: "0.5px",
                                // marginBottom: "2px",
                              }}
                            >
                              {val.OEMnumber && val.OEMnumber.trim() !== ""
                                ? `${val.OEMnumber} - ${val.productName}`
                                : val.productName}
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div>{"Product not selected for print barcode!"}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductBarcode;
