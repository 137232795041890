import React, { useEffect, useState } from "react";
import { get_biometric_attendance, get_biometric_report, upload_biometric } from "../../../api/index";
import { ToastContainer, toast } from "react-toastify";
import { Typeahead } from "react-bootstrap-typeahead";
import DatePicker from "react-datepicker";
import {
  calMaxPage,
  getLabels,
  setAccessPrivilage,
  getFinancialYearStartDate,
  isExcelFile,
  convertDateYYMMDD,
  setTimeToZero
} from "../../../common/Functions/CommonFunctions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBan,
  faDownload,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { useForm, Controller } from "react-hook-form";
import { Tooltip } from "react-tooltip";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Pagination, Spinner } from "react-bootstrap";
import { Biometric_Col_Name } from "../../../common/Labels/labelConstant";
import List from "../../../assets/images/icons/project_menu/checklist.png";
import Slide from "../../../assets/images/icons/project_menu/collapse.png";
import AccessDenied from "../../Common/AccessDenied";

function BiometricAttendance() {
  let navigate = useNavigate();
  const [biometricList, setBiometricList] = useState({
    data: [],
    loading: true,
    totalRecords: "",
  });
  const [page, setPage] = useState(1);
  const [srNo, setSrNo] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [downloadReport, setDownloadReport] = useState(false);
  const [importData, setImportData] = useState(false);

  const loginData = useSelector(
    (state) => state.auth?.loginData?.data?.response
  );
  const [privilage, setPrivilage] = useState({
    read: true,
    write: true,
    view: true,
    delete: true,
    report: true,
    no_access: false,
  });

  const [showLabel, setShowLabel] = useState(Biometric_Col_Name);
  const [constLabel, setConstLabel] = useState({});
  const [filterConfig, setFilterConfig] = useState({
    punch_id: "",
    employee_name: "",
  });

  const { register, handleSubmit, formState, control, reset, watch } = useForm({
    mode: "onChange",
  }); // initialise the hook

  function openNav() {
    document.getElementById("mySidebar").style.width = "320px";
    document.getElementById("root_div_main").style.marginLeft = "320px";
    setTimeout(() => {
      document.getElementById("mySidebar").style.overflowX = "visible";
    }, [200]);
  }

  function closeNav() {
    document.getElementById("mySidebar").style.width = "0";
    document.getElementById("root_div_main").style.marginLeft = "0";

    document.getElementById("mySidebar").style.overflowX = "hidden";
  }

  useEffect(() => {
    setPrivilage(setAccessPrivilage(loginData, "Biometric Attendance"));
    setConstLabel(getLabels("Master", "Biometric Attendance"));
  }, []);

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      setPage(1);
      fetchAttendace(1, entriesPerPage);
    }, 1000); // Adjust the delay as needed (e.g., 500 milliseconds)

    return () => {
      clearTimeout(debounceTimeout); // Clear the timeout if searchTerm changes before the timeout expires
    };
  }, [filterConfig, startDate, endDate]);

  function fetchAttendace(pg, epp) {
    setBiometricList({ ...biometricList, loading: true });
    const payload = {
      pageNo: pg,
      entriesPerPage: epp,
    };

    if(startDate && endDate){
      payload.startDate = convertDateYYMMDD(new Date(startDate), "-");
      payload.endDate = convertDateYYMMDD(new Date(endDate), "-");
    }

    if (filterConfig.punch_id !== "") {
      payload.punch_id = filterConfig.punch_id.trim(); //Search by punch id
    } else {
      delete payload.punch_id;
    }

    if (filterConfig.employee_name !== "") {
      payload.employee_name = filterConfig.employee_name.trim(); //Search by employee name
    } else {
      delete payload.employee_name;
    }

    get_biometric_attendance(payload).then(
      (res) => {
        setBiometricList({
          ...biometricList,
          data: res.data.data,
          loading: false,
          totalRecords: res.data.total,
        });
        setMaxPage(res.data.total);
        if (pg == 1) {
          setSrNo(0);
          setPage(1);
        }
      },
      (err) => {
        setBiometricList({ ...biometricList, data: [], loading: false });
        toast.error(err.response.data.message, {
          autoClose: 2000,
        });
      }
    );
  }

  function handleClick(ind) {
    // let tempLabels = { ...constLabel };

    // tempLabels.tbl_column = tempLabels.tbl_column.map((val, i) => {
    //   if (ind === i) {
    //     // Toggle the flag for the clicked checkbox
    //     return { ...val, flag: !val.flag };
    //   }
    //   return val;
    // });
    // setConstLabel(tempLabels);
    // setConstLabel((val) => {
    //     const updatedCardShow = val.tbl_column.map((val, i) => {
    //         if (ind === i) {
    //             // Toggle the flag for the clicked checkbox
    //             return { ...val, flag: !val.flag };
    //         }
    //         return val;
    //     });

    //     return { tbl_column: updatedCardShow };
    // });

    setShowLabel((showLabel) => {
      const updatedCardShow = showLabel.map((val, i) => {
        if (ind === i) {
          // Toggle the flag for the clicked checkbox
          return { ...val, flag: !val.flag };
        }
        return val;
      });

      return updatedCardShow;
    });
  }

  function clearFilter() {
    let obj = {
      punch_id: "",
      employee_name: "",
    };
    setFilterConfig({ ...obj });
    setSrNo(0);
    setPage(1);
    setStartDate(null);
    setEndDate(null);
  }

  const handleFromDateChange = (date) => {
    if (endDate && setTimeToZero(date) > setTimeToZero(endDate)) {
      return;
    }
    setStartDate(date);
  };

  const handleToDateChange = (date) => {
    if(date === null){
      setEndDate(null);
      return;
    }
    if (startDate && setTimeToZero(date) < setTimeToZero(startDate)) {
      return;
    }
    setEndDate(date);
  };

  function toggleNav() {
    const sidebar = document.getElementById("mySidebar");
    const mainDiv = document.getElementById("root_div_main");

    if (sidebar.style.width === "320px") {
      // Sidebar is open, so close it
      sidebar.style.width = "0";
      mainDiv.style.marginLeft = "0";
      sidebar.style.overflowX = "hidden";
    } else {
      // Sidebar is closed, so open it
      sidebar.style.width = "320px";
      mainDiv.style.marginLeft = "320px";
      setTimeout(() => {
        sidebar.style.overflowX = "visible";
      }, 200);
    }
  }

  function formatPunchTime(punch_time) {
    const date = new Date(punch_time);
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getUTCFullYear();
    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");

    return `${day}-${month}-${year} ${hours}:${minutes}`;
  }

  function base64ToExcel(response) {
      const { statusCode, headers, body, isBase64Encoded } = response;
  
      // Assume base64String contains the base64 representation of the Excel file
      const base64String = body; // Replace this with your base64 string
  
      // Convert the base64 string into a Uint8Array
      const bytes = Uint8Array.from(atob(base64String), (c) => c.charCodeAt(0));
  
      // Create a Blob object from the Uint8Array
      const blob = new Blob([bytes], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
  
      // Create a URL for the Blob object
      const url = URL.createObjectURL(blob);
  
      // Create a link element with download attribute
      const link = document.createElement("a");
      link.href = url;
      const currentDate = new Date().toDateString().split(" ").join("_");
      link.download = `Biometric_Attendance_${currentDate}.xlsx`;
  
      // Programmatically click on the link to trigger the download
      setDownloadReport(false);
      document.body.appendChild(link);
      link.click();
  
      // Cleanup: remove the link and revoke the URL
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    }
  
  const handleFileChange = async (event) => {
    const file = event.target.files[0]; // Get the selected file
    // console.log("file", file);
  
    if (!file) return;
  
    if (isExcelFile(file.name)) {
      try {
        const reader = new FileReader();
  
        reader.readAsDataURL(file);
        reader.onload = () => {
          const base64String = reader.result.split(',')[1];
          setImportData(true);
  
          upload_biometric({ file_base_64: base64String })
            .then((res) => {
              if (res.status === 200 || res.status === 201) {
                toast.success("Biometric attendance imported successfully !", {
                  autoClose: 3000,
                });
                fetchAttendace(page, entriesPerPage);
              }
            })
            .catch((err) => {
              console.log("err", err)
              if (err.response?.status === 400) {
                toast.error(err.response?.data?.error, {
                  autoClose: 2000,
                });
              }
            })
            .finally(() => {
              setImportData(false);
            });
        };
  
        reader.onerror = () => {
          toast.error("Error creating base 64 string !", {
            autoClose: 2000,
          });
        };
      } catch (err) {
        toast.error("Something went wrong !", {
          autoClose: 2000,
        });
      }
    } else {
      toast.error("Import valid excel file !", {
        autoClose: 2000,
      });
    }
  
    // Clear file input
    event.target.value = "";
  };

  async function goToDownload() {
      setDownloadReport(true);
      const payload = {
        startDate: convertDateYYMMDD(new Date(startDate), "-"),
        endDate: convertDateYYMMDD(new Date(endDate), "-"),
      };

      if (filterConfig.punch_id !== "") {
        payload.punch_id = filterConfig.punch_id.trim(); //Search by punch id
      } else {
        delete payload.punch_id;
      }
  
      if (filterConfig.employee_name !== "") {
        payload.employee_name = filterConfig.employee_name.trim(); //Search by employee name
      } else {
        delete payload.employee_name;
      }

      await get_biometric_report(payload).then(
        (res) => {
          //
          if (res) {
            base64ToExcel(res.data);
          }
        },
        (err) => {
          console.log("err", err);
          toast.error(err.response?.data?.message, {
            autoClose: 2000,
          });
          setDownloadReport(false);
        }
      );
    }
    

  return (
    <React.Fragment>
      <ToastContainer />
      {!privilage.read ? (
        <AccessDenied />
      ) : (
        <>
          <div id="mySidebar" className="customsidebar ">
            <a className="closebtn" onClick={() => toggleNav()}>
              ×
            </a>

            <div className=" content">
              <div className="filter row">
                <label
                  className="filterLabel mb-2 roboto-bold"
                  style={{ fontSize: "14px" }}
                >
                  {constLabel?.lbl_selectDateRange
                    ? constLabel.lbl_selectDateRange
                    : "Select Date Range"}
                </label>
                <div className="fromDate col-12 col-md-6">
                  <DatePicker
                    id="fromDate"
                    className="form-control form-control-sm"
                    selected={startDate}
                    showYearDropdown
                    scrollableMonthYearDropdown
                    dateFormat="dd/MM/yyyy"
                    placeholderText="From Date"
                    onChange={(date) => handleFromDateChange(date)}
                    maxDate={new Date()}
                  />
                </div>

                <div className="toDate col-12 col-md-6">
                  <DatePicker
                    id="toDate"
                    className="form-control form-control-sm"
                    showYearDropdown
                    scrollableMonthYearDropdown
                    selected={endDate}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="To Date"
                    onChange={(date) => handleToDateChange(date)}
                    maxDate={new Date()}
                  />
                </div>
              </div>

              <div className="mt-2">
                <label
                  className="filterLabel mb-1 roboto-bold"
                  style={{ fontSize: "14px" }}
                >
                  {constLabel?.lbl_punch_id
                    ? constLabel?.lbl_punch_id
                    : "Punch Id"}
                </label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Search by punch id"
                  value={filterConfig.punch_id}
                  onChange={(val) => {
                    const inputValue = val.target.value;
                    if (!inputValue.trim()) {
                      // Input contains only whitespace, don't trigger the search.
                      if (filterConfig.punch_id.length == 1) {
                        setFilterConfig({ ...filterConfig, punch_id: "" });
                      }
                    } else {
                      setFilterConfig({
                        ...filterConfig,
                        punch_id: inputValue,
                      });
                    }
                    if (inputValue.length == 0) {
                      setFilterConfig({ ...filterConfig, punch_id: "" });
                    }
                  }}
                />
              </div>

              <div className="mt-2">
                <label
                  className="filterLabel mb-1 roboto-bold"
                  style={{ fontSize: "14px" }}
                >
                  {constLabel?.lbl_employee_name
                    ? constLabel?.lbl_employee_name
                    : "Employee Name"}
                </label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Search by employee name"
                  value={filterConfig.employee_name}
                  onChange={(val) => {
                    const inputValue = val.target.value;
                    if (!inputValue.trim()) {
                      // Input contains only whitespace, don't trigger the search.
                      if (filterConfig.employee_name.length == 1) {
                        setFilterConfig({ ...filterConfig, employee_name: "" });
                      }
                    } else {
                      setFilterConfig({
                        ...filterConfig,
                        employee_name: inputValue,
                      });
                    }
                    if (inputValue.length == 0) {
                      setFilterConfig({ ...filterConfig, employee_name: "" });
                    }
                  }}
                />
              </div>

              <div className="my-3 d-flex justify-content-end">
                <button
                  onClick={() => clearFilter()}
                  className="btn btn-sm btn-yellow "
                >
                  <FontAwesomeIcon
                    style={{ color: "#344454" }}
                    icon={faBan}
                    size="sm"
                  />{" "}
                  {constLabel?.lbl_clear_filter
                    ? constLabel?.lbl_clear_filter
                    : "Clear Filter"}
                </button>
              </div>
            </div>
          </div>
          <div className="min-vh-100" id="root_div_main">
            <div className="content-wrapper">
              <div className="card border-0">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="d-md-flex justify-content-start col-12 col-md-6">
                      <Tooltip id={"open-tooltip"} place="top" />
                      <img
                        src={Slide}
                        height="32"
                        width="32"
                        className="cursor_pointer my-1 me-2"
                        onClick={() => toggleNav()}
                        data-tooltip-id={"open-tooltip"}
                        data-tooltip-content={"Filters"}
                      />

                      <h1 className="bold">
                        {constLabel?.lbl_biometric_attendance
                          ? constLabel.lbl_biometric_attendance
                          : "Biometric Attendance"}
                      </h1>
                    </div>
                    <div className="d-md-flex justify-content-end align-items-center col-12 col-md-6">
                      {privilage.report && (
                        <>
                          <Tooltip id={"upload-tooltip"} place="top" />
                          <input
                            type="file"
                            accept=".xls, .xlsx, .csv" // Define allowed file types
                            onChange={handleFileChange}
                            style={{ display: "none" }} // Hide the input element
                            id="fileInput"
                          />
                          <label htmlFor="fileInput">
                            <button
                              className="btn btn-grey me-0 me-md-2 mt-2 mt-md-0"
                              data-tooltip-id={"download-tooltip"}
                              data-tooltip-content={
                                constLabel?.lbl_upload_file
                                  ? constLabel.lbl_upload_file
                                  : "Upload File"
                              }
                              onClick={() =>
                                document.getElementById("fileInput").click()
                              }
                              onChange={handleFileChange}
                              disabled={importData}
                            >
                              <FontAwesomeIcon
                                style={{ color: "#fff" }}
                                icon={faUpload}
                                size="sm"
                              />{" "}
                              {importData && (
                                <Spinner animation="border" size="sm" />
                              )}
                            </button>
                          </label>
                        </>
                      )}

                      {privilage.report && (
                        <>
                          <Tooltip id={"download-tooltip"} place="top" />
                          <button
                            className="btn btn-light-grey me-0 me-md-2 mt-2 mt-md-0"
                            data-tooltip-id={"download-tooltip"}
                            data-tooltip-content={
                              constLabel?.lbl_download_report
                                ? constLabel.lbl_download_report
                                : "Download Report"
                            }
                            onClick={() => { goToDownload(); }}
                            disabled={downloadReport}
                          >
                            <FontAwesomeIcon
                              style={{ color: "#fff" }}
                              icon={faDownload}
                              size="sm"
                            />{" "}
                            {downloadReport && (
                              <Spinner animation="border" size="sm" />
                            )}
                          </button>
                        </>
                      )}

                      <Tooltip id={"show-tooltip"} place="top" />
                      <div
                        className="dropdown"
                        data-tooltip-id={"show-tooltip"}
                        data-tooltip-content={"Show / Hide Columns"}
                      >
                        <div
                          className="d-md-flex justify-content-start align-items-center"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img
                            src={List}
                            height="32"
                            width="32"
                            className="cursor_pointer"
                          />
                        </div>

                        <ul className="dropdown-menu px-2">
                          {showLabel !== undefined && showLabel.length > 0
                            ? showLabel.map((val, ind, arr) => (
                                <li key={ind}>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      style={{ width: "20px", height: "20px" }}
                                      onChange={() => handleClick(ind)}
                                      value=""
                                      checked={val.flag}
                                    />
                                    <label
                                      className=""
                                      style={{ fontSize: "13px" }}
                                      htmlFor="flexCheckDefault"
                                    >
                                      {val.label}
                                    </label>
                                  </div>
                                </li>
                              ))
                            : null}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="mt-3">
                    {biometricList.loading ? (
                      <div className="text-center">
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    ) : biometricList.data && biometricList.data.length > 0 ? (
                      <div className="table-responsive">
                        <table className="table table-bordered">
                          <thead className="table-grey roboto">
                            <tr className="f-14">
                              <th scope="col">{"Sr.No"}</th>
                              {showLabel.map((v, i) =>
                                v.label === "Employee Name" && v.flag ? (
                                  <th key={i} scope="col">
                                    {"Employee Name"}
                                  </th>
                                ) : v.label === "Punch Id" && v.flag ? (
                                  <th key={i} scope="col">
                                    {"Punch Id"}
                                  </th>
                                ) : v.label === "Device Code" && v.flag ? (
                                  <th key={i} scope="col">
                                    {"Device Code"}
                                  </th>
                                ) : v.label === "Punch Time" && v.flag ? (
                                  <th key={i} scope="col">
                                    {"Punch Time"}
                                  </th>
                                ) : v.label === "IsSync" && v.flag ? (
                                  <th key={i} scope="col">
                                    {"IsSync"}
                                  </th>
                                ) : v.label === "Last Activity" && v.flag ? (
                                  <th key={i} scope="col">
                                    {"Last Activity"}
                                  </th>
                                ) : v.label === "Entry Date" && v.flag ? (
                                  <th key={i} scope="col">
                                    {"Entry Date"}
                                  </th>
                                ) : v.label === "Remarks" && v.flag ? (
                                  <th key={i} scope="col">
                                    {"Remarks"}
                                  </th>
                                ) : v.label === "Remarks Date" && v.flag ? (
                                  <th key={i} scope="col">
                                    {"Remarks Date"}
                                  </th>
                                ) : null
                              )}
                            </tr>
                          </thead>
                          <tbody className="roboto">
                            {biometricList.data.map((val, i) => (
                              <tr key={i}>
                                <td>{i + parseInt(srNo) + 1}</td>
                                {showLabel.map((item, idx) =>
                                  item.label === "Employee Name" &&
                                  item.flag ? (
                                    <td key={idx}>
                                      {val.employee_name !== "" &&
                                      val.employee_name !== null
                                        ? val.employee_name
                                        : "NA"}
                                    </td>
                                  ) : item.label === "Punch Id" && item.flag ? (
                                    <td key={idx}>
                                      {val.punch_id !== "" &&
                                      val.punch_id !== null
                                        ? val.punch_id
                                        : "NA"}
                                    </td>
                                  ) : item.label === "Device Code" &&
                                    item.flag ? (
                                    <td key={idx}>
                                      {val.device_code !== "" &&
                                      val.device_code !== null
                                        ? val.device_code
                                        : "NA"}
                                    </td>
                                  ) : item.label === "Punch Time" &&
                                    item.flag ? (
                                    <td key={idx}>
                                      {val.punch_time !== "" &&
                                      val.punch_time !== null
                                        ? formatPunchTime(val.punch_time)
                                        : "NA"}
                                    </td>
                                  ) : item.label === "IsSync" && item.flag ? (
                                    <td key={idx}>
                                      {val.issync ? "True" : "False"}
                                    </td>
                                  ) : item.label === "Last Activity" &&
                                    item.flag ? (
                                    <td key={idx}>
                                      {val.last_activity !== "" &&
                                      val.last_activity !== null
                                        ? formatPunchTime(val.last_activity)
                                        : "NA"}
                                    </td>
                                  ) : item.label === "Entry Date" &&
                                    item.flag ? (
                                    <td key={idx}>
                                      {val.entry_date !== "" &&
                                      val.entry_date !== null
                                        ? formatPunchTime(val.entry_date)
                                        : "NA"}
                                    </td>
                                  ) : item.label === "Remarks" && item.flag ? (
                                    <td key={idx}>
                                      {val.remark !== "" && val.remark !== null
                                        ? val.remark
                                        : "NA"}
                                    </td>
                                  ) : item.label === "Remarks Date" &&
                                    item.flag ? (
                                    <td key={idx}>
                                      {val.remarks_date !== "" &&
                                      val.remarks_date !== null
                                        ? formatPunchTime(val.remarks_date)
                                        : "NA"}
                                    </td>
                                  ) : null
                                )}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <p className="text-danger mb-0 f-22 text-center mt-4">
                        {"Biometric attendance list is empty"}
                      </p>
                    )}

                    {biometricList.loading ? null : (
                      <div className="d-flex justify-content-between align-items-center mt-2">
                        <p className="mb-0">
                          Show
                          <select
                            className="mx-1"
                            defaultValue={entriesPerPage}
                            onChange={(e) => {
                              setEntriesPerPage(e.target.value);
                              fetchAttendace(1, e.target.value);
                              setPage(1);
                              setSrNo(0);
                            }}
                          >
                            <option value={"10"}>10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                          Entries
                        </p>
                        <p className="mb-0">{`Showing ${
                          parseInt(srNo) + 1
                        } to ${Math.min(
                          parseInt(entriesPerPage) + parseInt(srNo),
                          biometricList.totalRecords
                        )} of ${biometricList.totalRecords} entries`}</p>
                        <Pagination className="my-2">
                          <Pagination.Prev
                            disabled={page === 1 ? true : false}
                            onClick={() => {
                              fetchAttendace(
                                page - 1,
                                entriesPerPage,
                                parseInt(srNo) - parseInt(entriesPerPage)
                              );
                              setSrNo((prevC) =>
                                page - 1 == 1
                                  ? 0
                                  : prevC - parseInt(entriesPerPage)
                              );
                              setPage(page - 1);
                            }}
                          >
                            {"Prev"}
                          </Pagination.Prev>

                          <Pagination.Item active>{page}</Pagination.Item>

                          <Pagination.Next
                            disabled={
                              page === maxPage ||
                              maxPage === 0 ||
                              entriesPerPage > biometricList.data.length
                                ? true
                                : false
                            }
                            onClick={() => {
                              fetchAttendace(
                                page + 1,
                                entriesPerPage,
                                parseInt(srNo) + parseInt(entriesPerPage)
                              );
                              setSrNo(
                                (prevC) => prevC + parseInt(entriesPerPage)
                              );
                              setPage(page + 1);
                            }}
                          >
                            {"Next"}
                          </Pagination.Next>
                        </Pagination>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </React.Fragment>
  );
}

export default BiometricAttendance;
