import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faAdd,
  faEye,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import { Typeahead } from "react-bootstrap-typeahead";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Trash2 } from "react-feather";
import {
  getProdList,
  getUOM,
  createBOM,
  getProduct_Family,
  getBOM,
  get_product_light,
} from "../../../api/index";
import { ToastContainer, toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { Pagination, OverlayTrigger, Tooltip } from "react-bootstrap";

const schema = yup
  .object()
  .shape({
    product: yup.array().notRequired(),
    productFamily: yup.array().notRequired(),

    quantity: yup
      .string()
      .required("Quantity is required !")
      // .matches(/^\d+$/, "Enter valid quantity")
      .matches(/^\d+(\.\d+)?$/, "Enter valid quantity")
      .typeError("Enter valid quantity"),
    // .integer('Enter valid quantity'),
    bomType: yup.string().required("Please select bill of material"),
    bom_name: yup.string().required("Bom name is required!"),
    remarks: yup.string().required("Remark is required"),
    items: yup.array().of(
      yup.object().shape({
        prodFam: yup.array().notRequired(),
        component: yup.array().notRequired(),
        quantity: yup
          .string()
          .required("Quantity is required !")
          // .matches(/^\d+$/, "Enter valid quantity")
          .matches(/^\d+(\.\d+)?$/, "Enter valid quantity")
          .typeError("Enter valid quantity"),
        unitOfMeasure: yup
          .array()
          .min(1, "Please select unit of measure")
          .required("Please select unit of measure"),
      })
    ),
  })
  .required();

function CreateBom() {
  let navigate = useNavigate();
  let location = useLocation();

  const {
    register,
    handleSubmit,
    formState,
    control,
    reset,
    watch,
    setValue,
    getValues,
    setError,
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      items: [{}],
    },
  });

  let { errors } = formState;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "items",
  });

  const [prodList, setProdList] = useState({ data: [], loading: false });
  const [prodFamily, setProdFamily] = useState({ data: [], loading: false });
  const [uom, setUom] = useState({ data: [], loading: false });
  const [itemsData, setItemsData] = useState([{ prodFam: [], component: [] }]);
  const [disableBtn, setDisableBtn] = useState(false);
  let masterProd = watch("product");
  let masterProdFam = watch("productFamily");

  const [isProdFam, setIsProdFam] = useState(false);
  const [prod, setProd] = useState("");

  useEffect(() => {
    // if (location.state !== null && location.state !== undefined) {

    //     reset({ product: [location.state.product] })
    // }
    // fetchProdList();
    fetchUOM();
    fetchProdFamily();
  }, []);

  useEffect(() => {
      const debounceTimeout = setTimeout(() => {
        if (prod.trim().length >= 4) {
          fetchProdList(prod);
        }
      }, 1000); // Adjust the delay as needed (e.g., 500 milliseconds)
  
      return () => {
        clearTimeout(debounceTimeout); // Clear the timeout if searchTerm changes before the timeout expires
      };
    }, [prod]);
  

  useEffect(() => {
    validateProdErr();
    // console.log("masterProd", masterProd);
    // console.log("masterProdFam", masterProdFam);
    if (masterProd !== undefined && masterProd.length > 0) {
      setValue("productFamily", []);
      fetchBOM(masterProd?.[0]?.productName);
    }
    setValue("bom_name", "");
  }, [masterProd]);

  useEffect(() => {
    validateProdErr();

    if (masterProdFam !== undefined && masterProdFam.length > 0) {
      setValue("product", []);
      fetchBOM(masterProdFam?.[0]?.familyname);
    }
    setValue("bom_name", "");
  }, [masterProdFam]);

  function validateProdErr() {
    if (masterProd !== undefined) {
      if (itemsData.length > 0) {
        for (let i = 0; i < itemsData.length; i++) {
          if (itemsData[i].component.length > 0) {
            if (
              masterProd.length > 0 &&
              masterProd[0].uuid == itemsData[i].component[0].uuid
            ) {
              setError(`items[${i}].component`, {
                type: "custom",
                message: "Master product and line product cannot be same !",
              });
            } else {
              clearErrors(`items[${i}].component`);
            }
          }
        }
      }
    }

    if (masterProdFam !== undefined) {
      if (itemsData.length > 0) {
        for (let i = 0; i < itemsData.length; i++) {
          if (itemsData[i].prodFam.length > 0) {
            if (
              masterProdFam.length > 0 &&
              masterProdFam[0].uuid == itemsData[i].prodFam[0].uuid
            ) {
              setError(`items[${i}].prodFam`, {
                type: "custom",
                message:
                  "Master product Family and line product family cannot be same !",
              });
            } else {
              clearErrors(`items[${i}].prodFam`);
            }
          }
        }
      }
    }
  }

  function fetchProdFamily() {
    setProdFamily({ ...prodFamily, data: [], loading: true });
    getProduct_Family().then(
      (res) => {
        let arr = res.data.data.filter(
          (item) => item.familyname !== null || item.familyname !== undefined
        );
        setProdFamily({ ...prodFamily, data: arr, loading: false });
      },
      (err) => {
        setProdFamily({ ...prodFamily, data: [], loading: false });
      }
    );
  }

  function fetchUOM() {
    setUom({ ...uom, data: [], loading: true });
    getUOM().then(
      (res) => {
        setUom({ ...uom, data: res.data.data, loading: false });
      },
      (err) => {
        setUom({ ...uom, data: [], loading: false });
      }
    );
  }

  function fetchProdList(prod) {
    setProdList({ ...prodList, data: [], loading: true });

    get_product_light({searchVal: prod}).then(
      (res) => {
        let arr = res.data.data.filter(
          (item) => item.productName !== null || item.productName !== undefined
        );
        setProdList({ ...prodList, data: arr, loading: false });
      },
      (err) => {
        setProdList({ ...prodList, data: [], loading: false });
      }
    );
  }

  const onSubmit = (data) => {
    // console.log("data", data);
    // console.log("itemsData", itemsData);
    setDisableBtn(true);
    validateProdErr();

    if (Object.keys(errors).length == 0) {
      let tempLineItems = [];
      for (let i = 0; i < itemsData.length; i++) {
        if (
          itemsData[i].component.length == 0 &&
          itemsData[i].prodFam.length == 0
        ) {
          setDisableBtn(false);
          toast.error("Please select either one product family or component", {
            autoClose: 5000,
          });
          return;
        } else {
          // for (let i = 0; i < data.items.length; i++) {
          if (itemsData[i].component.length > 0) {
            tempLineItems.push({
              product_id: itemsData[i].component[0].uuid,
              product_type_id: itemsData[i].component[0].product_type_id.uuid,
              productType: itemsData[i].component[0].productTypename,
              productId: itemsData[i].component[0].productId,
              productname: itemsData[i].component[0].productName,
              qty: data.items[i].quantity,
              unitname: data.items[i].unitOfMeasure[0].uom,
              unit_id: data.items[i].unitOfMeasure[0].uuid,
              unit: data.items[i].unitOfMeasure[0].uomid,
            });
          }

          if (itemsData[i].prodFam.length > 0) {
            tempLineItems.push({
              product_family_id: itemsData[i].prodFam[0].uuid,
              productId: itemsData[i].prodFam[0].familyId,
              productname: itemsData[i].prodFam[0].familyname,
              qty: data.items[i].quantity,
              unitname: data.items[i].unitOfMeasure[0].uom,
              unit_id: data.items[i].unitOfMeasure[0].uuid,
              unit: data.items[i].unitOfMeasure[0].uomid,
            });
          }
          // }
        }
      }

      const payload = {
        // "productId": data.product[0].productId,
        // product_family_id: data.product[0].prod_family_id != undefined && data.product[0].prod_family_id != null ?
        //  data.product[0].prod_family_id.uuid : null,

        qty: data.quantity,
        // "productname": data.product[0].productName,
        // "product_id": data.product[0].uuid,
        // "product_type_id": data.product[0].product_type_id.uuid,
        remarks: data.remarks,
        bom_type: data.bomType,
        bom_line_item: tempLineItems,
        bom_name: data.bom_name
      };

      if (data.product.length > 0) {
        payload.product_family_id =
          data.product[0].prod_family_id != undefined &&
            data.product[0].prod_family_id != null
            ? data.product[0].prod_family_id.uuid
            : null;
        payload.productType = data.product[0].productTypename;
        payload.productname = data.product[0].productName;
        payload.productId = data.product[0].productId;
        payload.product_id = data.product[0].uuid;
        payload.product_type_id = data.product[0].product_type_id.uuid;
      } else {
        payload.productType = null;
        payload.productname = data.productFamily[0].familyname;
        payload.product_id = null;
        payload.productId = data.productFamily[0].familyId;
        payload.product_type_id = null;
      }

      if (data.productFamily.length > 0) {
        payload.product_family_id = data.productFamily[0].uuid;
      }

      // console.log("payload", payload);

      createBOM(payload).then(
        (res) => {
          toast.success("BOM created successfully", {
            autoClose: 2000,
          });
          setTimeout(() => {
            setDisableBtn(false);
            navigate("/pcms/bill-of-material");
          }, [2000]);
        },
        (err) => {
          setDisableBtn(false);
          toast.error(err.response?.data?.message, {
            autoClose: 2000,
          });
        }
      );
    }
  };

  function handleProdFam(e, ind) {
    const temp = [...itemsData];

    temp[ind].prodFam = e;
    temp[ind].component = [];
    validateProdErr();
    setItemsData(temp);
    clearErrors(`items[${ind}].component`);
  }

  function handleProdComp(e, ind) {
    const temp = [...itemsData];

    temp[ind].component = e;
    temp[ind].prodFam = [];

    validateProdErr();
    setItemsData(temp);
  }

  function fetchBOM(bom_prod){
    getBOM({productname : bom_prod}).then(
      (res) => {
        // console.log("res", res);
        if (res && res.data.data.length > 0) {
          const latestBomName = getLatestBom(res.data.data);
          const latest_name = getNextBomName(latestBomName, bom_prod);
          setValue("bom_name", latest_name);
        } 
      },
      (err) => {
        // console.log("err", err);
        if(err.response.status == 404 && err.response.data.data.length == 0){
          setValue("bom_name", `${bom_prod}-BOM-01`);
        }
      }
    );
  }

  function getLatestBom(bomList){
    return bomList.reduce((latest, current) => 
      new Date(current.created_at) > new Date(latest.created_at) ? current : latest
    );
  };

  function getNextBomName(latestBom, baseName){
    if (!latestBom || !latestBom.bom_name) {
      // If latestBom has no value, use base name with "-01"
      return `${baseName}-BOM-01`;
    }
  
    // Split bom_name from the hyphen
    const [name, mid, number] = latestBom.bom_name.split('-');
  
    // Increment the number by 1, or default to 1 if no valid number
    const nextNumber = parseInt(number) + 1 || 1;
  
    // Return the new name with incremented number
    return `${name}-${mid}-${nextNumber.toString().padStart(2, '0')}`;
  };

  return (
    <div className="bom-creation">
      <ToastContainer />
      <div className="row">
        <div className="col-12">
          <div className="card border-0">
            <div className="card-header bg-grey d-flex align-items-center">
              <button
                className="btn btn-yellow"
                onClick={() => {
                  if (
                    window.confirm(
                      "Are you sure to go back, your present data will be lost !"
                    )
                  ) {
                    window.onpopstate = undefined;
                    window.history.back();

                    setTimeout(() => {
                      navigate("/pcms/bill-of-material");
                    }, [1000]);
                  }
                }}
              >
                <FontAwesomeIcon icon={faArrowLeft} size="sm" />
              </button>

              <p className="mb-0 ms-3" style={{ fontSize: "24px" }}>
                {location.state?.lbl_create_billOfMaterial
                  ? location.state.lbl_create_billOfMaterial
                  : "Create Bill of Material"}
              </p>
            </div>

            <div className="card-body p-4 ">
              <div className="row">
                <p className="mb-3">
                  <i>
                    {" "}
                    <span className="text-danger roboto-bold">Note: </span>
                    Either select product family or product
                  </i>
                </p>

                <div
                  className="form-check form-switch"
                  style={{ paddingLeft: "3rem" }}
                >
                  <label
                    className="form-check-label lbl-style roboto-bold"
                    htmlFor="flexSwitchCheckChecked"
                  >
                    {"Product Name / Product Family"}
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="flexSwitchCheckChecked"
                    checked={isProdFam}
                    onChange={() => {
                      setIsProdFam(!isProdFam);
                    }}
                    disabled={(masterProd !== undefined && masterProd.length > 0) || (masterProdFam !== undefined && masterProdFam.length > 0) ? true : false}
                  />
                </div>

                {isProdFam == false ?
                  <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                    <label
                      style={{ fontSize: "14px" }}
                      className="col-form-label col-12 col-md-12 roboto-bold"
                    >
                      {location.state?.lbl_product_name
                        ? location.state.lbl_product_name
                        : "Product "}
                    </label>

                    <div className="col-12 col-md-9">
                      <Controller
                        name={`product`}
                        control={control}
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                          <Typeahead
                            id="basic-typeahead-single"
                            labelKey="productName"
                            multiple={false}
                            options={prodList.data}
                            isLoading={prodList.loading}
                            placeholder="Search product for selection"
                            onChange={onChange}
                            onBlur={onBlur}
                            selected={value}
                            isInvalid={errors.product ? true : false}
                            onInputChange={(text) => setProd(text)}
                          />
                        )}
                      />
                      {errors.product && (
                        <span className="text-danger err-msg">
                          {errors.product.message}
                        </span>
                      )}
                    </div>
                  </div>
                  :
                  <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                    <label
                      style={{ fontSize: "14px" }}
                      className="col-form-label col-12 col-md-12 roboto-bold"
                    >
                      {location.state?.lbl_product_fam
                        ? location.state.lbl_product_fam
                        : "Product Family"}
                    </label>

                  <div className="col-12 col-md-9">
                    <Controller
                      name={`productFamily`}
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          id="basic-typeahead-single"
                          labelKey="familyname"
                          multiple={false}
                          options={prodFamily.data}
                          isLoading={prodFamily.loading}
                          placeholder="Select Product Family"
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          isInvalid={errors.productFamily ? true : false}
                        />
                      )}
                    />
                    {errors.productFamily && (
                      <span className="text-danger err-msg">
                        {errors.productFamily.message}
                      </span>
                    )}
                  </div>
                </div>
              }

                <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label col-12 col-md-12 roboto-bold"
                  >
                    {location.state?.lbl_BOM_name
                      ? location.state.lbl_BOM_name
                      : "BOM Name"}
                      <span className="text-danger"> *</span>
                  </label>

                  <div className="col-12 col-md-9">
                    <input
                      type="text"
                      className={
                        errors.bom_name
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      {...register("bom_name")}
                      placeholder="Enter bom name"
                      // disabled
                    />
                    {errors.bom_name && (
                      <span className="text-danger err-msg">
                        {errors.bom_name.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label col-12 col-md-12 roboto-bold"
                  >
                    {location.state?.lbl_quantity
                      ? location.state.lbl_quantity
                      : "Quantity"}
                    <span className="text-danger">*</span>
                  </label>

                  <div className="col-12 col-md-9">
                    <input
                      type="text"
                      className={
                        errors.quantity
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      {...register("quantity")}
                      placeholder="Enter Quantity"
                    />

                    {errors.quantity && (
                      <span className="text-danger err-msg">
                        {errors.quantity.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label col-12 col-md-3 roboto-bold"
                  >
                    {location.state?.lbl_bom_type
                      ? location.state.lbl_bom_type
                      : "BOM Type"}
                    <span className="text-danger"> *</span>
                  </label>

                  <div className="col-12 col-md-9">
                    <div className="form-check mt-2">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="bomType"
                        id="exampleRadios1"
                        value="Manufacture this product"
                        defaultChecked
                        {...register("bomType")}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="exampleRadios1"
                      >
                        Manufacture this product
                      </label>
                    </div>
                    <div className="form-check mt-2">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="bomType"
                        id="exampleRadios2"
                        value="Kit"
                        {...register("bomType")}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="exampleRadios2"
                      >
                        Kit
                      </label>
                    </div>
                    <div className="form-check mt-2">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="bomType"
                        id="exampleRadios3"
                        value="Subcontracting"
                        {...register("bomType")}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="exampleRadios3"
                      >
                        Subcontracting
                      </label>
                    </div>

                    {errors.bomType && (
                      <span className="text-danger err-msg">
                        {errors.bomType.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label col-12 col-md-12 roboto-bold"
                  >
                    {location.state?.lbl_remark
                      ? location.state.lbl_remark
                      : "Remark"}
                    <span className="text-danger">*</span>
                  </label>

                  <div className="col-12 col-md-9">
                    <textarea
                      className={
                        errors.remarks
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      rows={"2"}
                      {...register("...remarks")}
                      placeholder="Enter remark"
                    ></textarea>

                    {errors.remarks && (
                      <span className="text-danger err-msg">
                        {errors.remarks.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12">
                  <div
                    className=" py-3"
                  // style={{ overflowX: "scroll" }}
                  >
                    <table className="table table-responsive table-bordered">
                      <thead className="thead-light text-center">
                        <tr>
                          <th scope="col">
                            {location.state?.lbl_product_family
                              ? location.state.lbl_product_family
                              : "Product Family"}
                          </th>
                          <th scope="col">
                            {location.state?.lbl_product_name
                              ? location.state.lbl_product_name
                              : "Product"}
                          </th>
                          <th scope="col" width={"10%"}>
                            {location.state?.lbl_quantity
                              ? location.state.lbl_quantity
                              : "Quantity"}
                          </th>
                          <th scope="col" width={"15%"}>
                            {location.state?.lbl_product_unitOfMeasure
                              ? location.state.lbl_product_unitOfMeasure
                              : "Product Unit of Measure"}
                          </th>
                          <th scope="col">{"Action"}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {fields.map(({ id }, index) => (
                          <tr key={id} className="f-18">
                            <td>
                              <Typeahead
                                id="basic-typeahead-single"
                                labelKey="familyname"
                                multiple={false}
                                options={prodFamily.data}
                                isLoading={prodFamily.loading}
                                placeholder="Select product family"
                                onChange={(e) => {
                                  handleProdFam(e, index)
                                  setValue(`items[${index}].unitOfMeasure`, [], { shouldValidate : true})
                                }}
                                isInvalid={
                                  errors.items?.[index]?.prodFam ? true : false
                                }
                                selected={itemsData[index].prodFam}
                              />

                              {errors.items?.[index]?.prodFam && (
                                <span className="text-danger err-msg">
                                  {errors.items?.[index]?.prodFam.message}
                                </span>
                              )}

                              {index + 1 == fields.length ? (
                                <div>
                                  <button
                                    style={{ fontSize: "16px" }}
                                    className="btn btn-sm button-primary px-2 mt-2"
                                    onClick={() => {
                                      let temp = [...itemsData];
                                      temp.push({ prodFam: [], component: [] });
                                      setItemsData(temp);
                                      append({});
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      style={{
                                        color: "#ffffff",
                                        marginRight: "5px",
                                      }}
                                      icon={faAdd}
                                      size="sm"
                                    />{" "}
                                    {location.state?.lbl_add
                                      ? location.state.lbl_add
                                      : "Add"}
                                  </button>
                                </div>
                              ) : null}
                            </td>
                            <td style={{ verticalAlign: "top" }}>
                              <Typeahead
                                id="basic-typeahead-single"
                                labelKey="productName"
                                multiple={false}
                                options={prodList.data}
                                isLoading={prodList.loading}
                                placeholder="Search product for selection"
                                onChange={(e) => {
                                  handleProdComp(e, index)
                                  if(e.length > 0 && e?.[0]?.unit_id){
                                    setValue(`items[${index}].unitOfMeasure`,[e?.[0]?.unit_id], { shouldValidate : true})
                                  } else {
                                    setValue(`items[${index}].unitOfMeasure`, [], { shouldValidate : true})
                                  }
                                }}
                                isInvalid={
                                  errors.items?.[index]?.component
                                    ? true
                                    : false
                                }
                                selected={itemsData[index].component}
                                onInputChange={(text) => setProd(text)}
                              />

                              {errors.items?.[index]?.component && (
                                <span className="text-danger err-msg">
                                  {errors.items?.[index]?.component.message}
                                </span>
                              )}
                            </td>

                            <td style={{ verticalAlign: "top" }}>
                              <input
                                className={
                                  errors.items?.[index]?.quantity
                                    ? "form-control is-invalid"
                                    : "form-control"
                                }
                                type="text"
                                name={`items[${index}].quantity`}
                                {...register(`items[${index}].quantity`)}
                                // onChange={(e) => handleFileChange(e, index)}
                                placeholder="Enter quantity"
                              />

                              {errors.items?.[index]?.quantity && (
                                <span className="text-danger err-msg">
                                  {errors.items?.[index]?.quantity.message}
                                </span>
                              )}
                            </td>

                            <td style={{ verticalAlign: "top" }}>
                              <Controller
                                name={`items[${index}].unitOfMeasure`}
                                control={control}
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                }) => (
                                  <Typeahead
                                    id="basic-typeahead-single"
                                    labelKey="uom"
                                    multiple={false}
                                    options={uom.data}
                                    isLoading={uom.loading}
                                    placeholder="Select Unit Of Measure"
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    selected={value}
                                    isInvalid={
                                      errors.items?.[index]?.unitOfMeasure
                                        ? true
                                        : false
                                    }
                                    disabled={itemsData[index]?.component?.length > 0 ? true : false}
                                  />
                                )}
                              />

                              {errors.items?.[index]?.unitOfMeasure && (
                                <span className="text-danger err-msg">
                                  {errors.items?.[index]?.unitOfMeasure.message}
                                </span>
                              )}
                            </td>

                            <td
                              style={{
                                textAlign: "center",
                                verticalAlign: "top",
                                width: "10%",
                              }}
                            >
                              <OverlayTrigger
                                placement="bottom"
                                overlay={
                                  <Tooltip id="tooltip">
                                    {location.state?.lbl_delete_bom_item
                                      ? location.state.lbl_delete_bom_item
                                      : "Delete BOM Item"}
                                  </Tooltip>
                                }
                              >
                                <div
                                  className="deleteIcon cursor_pointer"
                                  onClick={
                                    index > 0
                                      ? () => {
                                        remove(index);
                                        let temp = [...itemsData];
                                        temp.splice(index, 1);

                                        setItemsData(temp);
                                      }
                                      : null
                                  }
                                >
                                  <Trash2 color="#FFFFFF" size={18} />
                                </div>
                              </OverlayTrigger>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="col-12 mt-4">
                  <button
                    disabled={disableBtn}
                    className="btn btn-yellow px-3 float-end"
                    onClick={handleSubmit(onSubmit)}
                  >
                    {location.state?.lbl_save
                      ? location.state.lbl_save
                      : "Save"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateBom;
