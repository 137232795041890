import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SweetAlert from "sweetalert2";
import { faBan, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Pagination } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { Eye, Trash2, Edit2, Edit, FileText ,Pocket} from "react-feather";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  getCustomer,
  get_state,
  get_product_light,
  get_proforma_invoice,
  delete_proforma_invoice,
} from "../../../api/index";
import { Tooltip } from "react-tooltip";
import Multiselect from "multiselect-react-dropdown";
import {
  calMaxPage,
  formatDate5,
  getLabels,
  setAccessPrivilage,
} from "../../../common/Functions/CommonFunctions";
import { Proforma_Invoice_Col_Name } from "../../../common/Labels/labelConstant";
import Slide from "../../../assets/images/icons/project_menu/collapse.png";
import List from "../../../assets/images/icons/project_menu/checklist.png";
import AccessDenied from "../../Common/AccessDenied";
import { useSelector } from "react-redux";
import PaymentReceipt from "./PaymentReceipt";

function Proforma_Invoice() {
  const [showLabel, setShowLabel] = useState(Proforma_Invoice_Col_Name);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [srNo, setSrNo] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState({});
  const [dataList, setDataList] = useState({
    data: [],
    loading: true,
    totalRecords: "",
  });
  const [filterConfig, setFilterConfig] = useState({
    customer_name: [], //array
    so_status: [], //string
    startPrice: "",
    endPrice: "",
    gst_number: [], //array
    productName: [], //array
    stateName: [],
  });

  const [priceRange, setPriceRange] = useState({ from: 1, to: 1000 });
  const [customerList, setCustomerList] = useState({
    data: [],
    loading: false,
  });
  const [gst_list, setGST_list] = useState({ data: [], loading: false });
  const [state_list, setState_list] = useState({ data: [], loading: false });

  const [prod_List, setProd_List] = useState({ data: [], loading: false });
  const [constLabel, setConstLabel] = useState({});
  const [errorMessage, setErrorMessage] = useState("");



  let navigate = useNavigate();

  const loginData = useSelector(
    (state) => state.auth?.loginData?.data?.response
  );

  const [privilage, setPrivilage] = useState({
    read: true,
    write: true,
    view: true,
    delete: true,
    report: true,
    no_access: false,
  });

  const localData = JSON.parse(localStorage.getItem("localData"));

  const handleSliderChange = (event, sliderName) => {
    const value = parseInt(event.target.value, 10);
    let obj = {};
    // Reset error message
    setErrorMessage("");

    if (sliderName == "from") {
      obj.startPrice = value;
      obj.endPrice = priceRange.to;

      // Check if startPrice is greater than or equal to endPrice, then set error message
      if (value >= priceRange.to) {
        setErrorMessage("From price must be less than To price");
        return;
      }
    } else {
      obj.endPrice = value;
      obj.startPrice = priceRange.from;

      // Check if endPrice is less than or equal to startPrice, then set error message
      if (value <= priceRange.from) {
        setErrorMessage("To price must be greater than From price");
        return;
      }
    }
    setPriceRange({ ...priceRange, [sliderName]: value });
    setFilterConfig({ ...filterConfig, ...obj });
  };

  useEffect(() => {
    setConstLabel(getLabels("Sales", "Proforma Invoice"));
    const debounceTimeout = setTimeout(() => {
      fetchProformaInvoiceList(1, entriesPerPage);
    }, 1000); // Adjust the delay as needed (e.g., 500 milliseconds)

    return () => {
      clearTimeout(debounceTimeout); // Clear the timeout if searchTerm changes before the timeout expires
    };
  }, [filterConfig]);

  useEffect(() => {
    // console.log(loginData)
    setPrivilage(setAccessPrivilage(loginData, "Sales Order"));
  }, []);

  function fetchProformaInvoiceList(pg, epp) {
    setDataList({ ...dataList, data: [], loading: true });
    const payload = {
      entriesPerPage: epp,
      pageNo: pg,
    };

    if (filterConfig?.customer_name?.length > 0) {
      payload.customer_name = JSON.stringify(
        filterConfig.customer_name.map((item) => item.organization)
      );
    } else {
      delete payload.customer_name;
    }

    if (filterConfig?.gst_number?.length > 0) {
      //  payload.gst = filterConfig.gst[0].gst;
      payload.gst_number = JSON.stringify(
        filterConfig.gst_number.map((item) => item.gst)
      );
    } else {
      delete payload.gst_number;
    }

    if (filterConfig?.stateName?.length > 0) {
      // payload.state = filterConfig.state[0].stateName;
      payload.state = JSON.stringify(
        filterConfig.stateName.map((item) => item.stateName)
      );
    } else {
      delete payload.stateName;
    }

    if (filterConfig?.so_status?.length > 0) {
      payload.so_status = filterConfig.so_status[0].status_name;
    } else {
      delete payload.so_status;
    }

    if (filterConfig?.productName?.length > 0) {
      //  payload.productName = filterConfig.productName[0].productName;
      payload.productName = JSON.stringify(
        filterConfig.productName.map((item) => item.productName)
      );
    } else {
      delete payload.productName;
    }

    if (filterConfig?.startPrice && filterConfig?.endPrice) {
      payload.startPrice = filterConfig.startPrice;
      payload.endPrice = filterConfig.endPrice;
    } else {
      delete payload.startPrice;
      delete payload.endPrice;
    }

    get_proforma_invoice(payload).then(
      (res) => {
        setDataList({
          ...dataList,
          data: res.data.data,
          loading: false,
          totalRecords: res.data.total,
        });
        // setMaxPage(calMaxPage_new(parseInt(res.data.total), epp));

        setMaxPage(calMaxPage(res.data.total));
        if (pg == 1) {
          setSrNo(0);
          setPage(1);
        }
      },
      (err) => {
        setDataList({ ...dataList, data: [], loading: false });

        // if(err.response.status == 400){
        //   toast.error(err.response.data.message, {
        //     autoClose: 2000,
        //   });
        //   setPriceRange({from: 0, to: 100});
        // }
      }
    );
  }

  const delProformaInvoice = (val) => {
    SweetAlert.fire({
      title: "Are you sure to delete ?",
      text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        delete_proforma_invoice({ uuid: val.uuid }).then(
          (res) => {
            toast.success("Proforma Invoice deleted successfully !", {
              autoClose: 2000,
            });
            if (dataList.data.length == 1 && page > 1) {
              fetchProformaInvoiceList(page - 1, entriesPerPage);
              setSrNo(parseInt(srNo - entriesPerPage));
              setPage(page - 1);
            } else {
              fetchProformaInvoiceList(page, entriesPerPage);
            }
          },
          (err) => {
            toast.error("Something went wrong !", {
              autoClose: 2000,
            });
          }
        );
      }
    });
  };

  function fetchCustomers() {
    setCustomerList({ ...customerList, loading: true, data: [] });
    setGST_list({ data: [], loading: true });
    getCustomer({ partyType: "Customer" }).then(
      (res) => {
        const result = res.data.data.filter(
          (item) => item.organization !== null
        );
        const arr = res.data.data.filter(
          (item) =>
            item.gst !== null && item.gst !== undefined && item.gst !== ""
        );
        setGST_list({ data: arr, loading: false });
        setCustomerList({
          ...customerList,
          loading: false,
          data: result,
        });
      },
      (err) => {
        //
        setCustomerList({ ...customerList, loading: false, data: [] });
        setGST_list({ data: [], loading: false });
        // toast.error("Something went wrong in suppliers!", {
        //   autoClose: 3000,
        // });
      }
    );
  }

  function fetchProdList() {
    setProd_List({ ...prod_List, loading: true, data: [] });

    get_product_light().then(
      (res) => {
        let result = res.data.data.filter(
          (item) =>
            item.productName !== null &&
            item.productName !== undefined &&
            item.productName !== ""
        );
        setProd_List({ ...prod_List, loading: false, data: result });
      },
      (err) => {}
    );
  }

  function fetch_state() {
    setState_list({ data: [], loading: true });

    const payload = {
      country_id:
        process.env.REACT_APP_ENV == "development"
          ? "faa26b30-9669-44e6-b943-e594026dd2ba"
          : "a0599863-9521-4e78-8654-4d49918f943b",
    };
    get_state(payload).then(
      (res) => {
        let arr = res.data.data.filter(
          (item) =>
            item.stateName !== null &&
            item.stateName !== undefined &&
            item.stateName !== ""
        );
        setState_list({ data: arr, loading: false });
      },
      (err) => {
        setState_list({ data: [], loading: false });
      }
    );
  }

  function clearFilter() {
    let obj = {
      customer_name: [], //array
      so_status: [], //string
      startPrice: "",
      endPrice: "",
      gst_number: [], //array
      productName: [], //array
      stateName: [],
    };
    setFilterConfig({ ...obj });
    setPriceRange({ from: 1, to: 1000 });
    fetchCustomers();
    fetchProdList();
    fetch_state();
  }

  function openNav() {
    document.getElementById("mySidebar").style.width = "320px";
    document.getElementById("root_div_main").style.marginLeft = "320px";
    setTimeout(() => {
      document.getElementById("mySidebar").style.overflowX = "visible";
    }, [200]);
  }

  function closeNav() {
    document.getElementById("mySidebar").style.width = "0";
    document.getElementById("root_div_main").style.marginLeft = "0";

    document.getElementById("mySidebar").style.overflowX = "hidden";
  }

  function handleClick(ind) {
    setShowLabel((showLabel) => {
      const updatedCardShow = showLabel.map((val, i) => {
        if (ind === i) {
          // Toggle the flag for the clicked checkbox
          return { ...val, flag: !val.flag };
        }
        return val;
      });

      return updatedCardShow;
    });
  }

  function toggleNav() {
    const sidebar = document.getElementById("mySidebar");
    const mainDiv = document.getElementById("root_div_main");

    if (sidebar.style.width === "320px") {
      // Sidebar is open, so close it
      sidebar.style.width = "0";
      mainDiv.style.marginLeft = "0";
      sidebar.style.overflowX = "hidden";
    } else {
      // Sidebar is closed, so open it
      sidebar.style.width = "320px";
      mainDiv.style.marginLeft = "320px";
      setTimeout(() => {
        sidebar.style.overflowX = "visible";
      }, 200);

      fetchCustomers();
      fetchProdList();
      fetch_state();
    }
  }

  return (
    <React.Fragment>
      <ToastContainer />

      <PaymentReceipt visible={visible} setVisible={setVisible} data={data}/>
      {!privilage.read ? (
        <AccessDenied />
      ) : (
        <>
          <div id="mySidebar" className="customsidebar ">
            <a className="closebtn" onClick={() => toggleNav()}>
              ×
            </a>

            <div className=" content">
              <div className="form-group typeahead-container">
                <label
                  className="filterLabel my-2 roboto-bold"
                  style={{ fontSize: "14px" }}
                >
                  {constLabel?.lbl_select_customer
                    ? constLabel?.lbl_select_customer
                    : "Select Customer"}
                </label>

                <Multiselect
                  options={customerList.data}
                  onSelect={(val) => {
                    if (val.length > 0) {
                      setFilterConfig({ ...filterConfig, customer_name: val });
                    } else {
                      setFilterConfig({ ...filterConfig, customer_name: [] });
                    }
                  }}
                  onRemove={(e) => {
                    setFilterConfig({ ...filterConfig, customer_name: e });
                  }}
                  selectedValues={filterConfig.customer_name}
                  displayValue="organization"
                  placeholder="Select customer name"
                  isLoading={customerList.loading}
                  customCloseIcon={
                    <p
                      className="ms-2 mb-0 p-0"
                      style={{ fontStyle: "normal" }}
                    >
                      x
                    </p>
                  }
                />
                {!customerList.loading && <span className="arrow-icon"></span>}
              </div>

              <div className="form-group typeahead-container">
                <label
                  className="filterLabel my-2 roboto-bold"
                  style={{ fontSize: "14px" }}
                >
                  {"Select GST"}
                </label>

                <Multiselect
                  options={gst_list.data}
                  onSelect={(val) => {
                    if (val.length > 0) {
                      setFilterConfig({ ...filterConfig, gst_number: val });
                    } else {
                      setFilterConfig({ ...filterConfig, gst_number: [] });
                    }
                  }}
                  onRemove={(e) => {
                    setFilterConfig({ ...filterConfig, gst_number: e });
                  }}
                  selectedValues={filterConfig.gst_number}
                  displayValue="gst"
                  placeholder="Search by GST number"
                  isLoading={gst_list.loading}
                  customCloseIcon={
                    <p
                      className="ms-2 mb-0 p-0"
                      style={{ fontStyle: "normal" }}
                    >
                      x
                    </p>
                  }
                />
                {!gst_list.loading && <span className="arrow-icon"></span>}
              </div>

              <div className="form-group typeahead-container">
                <label
                  className="filterLabel my-2 roboto-bold"
                  style={{ fontSize: "14px" }}
                >
                  {"Select State"}
                </label>

                <Multiselect
                  options={state_list.data}
                  onSelect={(val) => {
                    if (val.length > 0) {
                      setFilterConfig({ ...filterConfig, stateName: val });
                    } else {
                      setFilterConfig({ ...filterConfig, stateName: [] });
                    }
                  }}
                  onRemove={(e) => {
                    setFilterConfig({ ...filterConfig, stateName: e });
                  }}
                  selectedValues={filterConfig.stateName}
                  displayValue="stateName"
                  placeholder="Search by state"
                  isLoading={state_list.loading}
                  customCloseIcon={
                    <p
                      className="ms-2 mb-0 p-0"
                      style={{ fontStyle: "normal" }}
                    >
                      x
                    </p>
                  }
                />
                {!state_list.loading && <span className="arrow-icon"></span>}
              </div>

              <div className="from-group typeahead-container">
                <label
                  className="filterLabel my-2 roboto-bold"
                  style={{ fontSize: "14px" }}
                >
                  {"Select Product "}
                </label>

                <Multiselect
                  options={prod_List.data}
                  onSelect={(val) => {
                    if (val.length > 0) {
                      setFilterConfig({ ...filterConfig, productName: val });
                    } else {
                      setFilterConfig({ ...filterConfig, productName: [] });
                    }
                  }}
                  onRemove={(e) => {
                    setFilterConfig({ ...filterConfig, productName: e });
                  }}
                  selectedValues={filterConfig.productName}
                  displayValue="productName"
                  placeholder="Search by product"
                  isLoading={prod_List.loading}
                  customCloseIcon={
                    <p
                      className="ms-2 mb-0 p-0"
                      style={{ fontStyle: "normal" }}
                    >
                      x
                    </p>
                  }
                />
                {!prod_List.loading && <span className="arrow-icon"></span>}
              </div>

              <div>
                <label
                  className="filterLabel my-2 roboto-bold"
                  style={{ fontSize: "14px" }}
                >
                  From: {priceRange.from}
                </label>

                <input
                  className="form-range"
                  type="range"
                  min={0}
                  max={100000}
                  step={1000}
                  value={priceRange.from}
                  onChange={(event) => handleSliderChange(event, "from")}
                />
                <label
                  className="filterLabel my-2 roboto-bold"
                  style={{ fontSize: "14px" }}
                >
                  To: {priceRange.to}
                </label>
                <input
                  className="form-range"
                  type="range"
                  min={0}
                  max={100000}
                  step={1000}
                  value={priceRange.to}
                  onChange={(event) => handleSliderChange(event, "to")}
                />

                {errorMessage && (
                  <span className="text-danger err-msg">{errorMessage}</span>
                )}
              </div>

              <div className="my-3 d-flex justify-content-end">
                <button
                  onClick={() => clearFilter()}
                  className="btn btn-sm btn-yellow "
                >
                  <FontAwesomeIcon
                    style={{ color: "#344454" }}
                    icon={faBan}
                    size="sm"
                  />{" "}
                  {constLabel?.lbl_clear_filter
                    ? constLabel.lbl_clear_filter
                    : "Clear Filter"}
                </button>
              </div>
            </div>
          </div>
          <div className="min-vh-100" id="root_div_main">
            <div className="content-wrapper">
              <div className="card border-0">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="d-md-flex justify-content-start col-12 col-md-5">
                      <Tooltip id={"open-tooltip"} place="top" />
                      {/* <img
                        src={Slide}
                        height="32"
                        width="32"
                        className="cursor_pointer my-1 me-2"
                        onClick={() => toggleNav()}
                        data-tooltip-id={"open-tooltip"}
                        data-tooltip-content={"Filters"}
                      /> */}
                      <h1 className="bold">
                        {constLabel?.lbl_proforma_invoice
                          ? constLabel.lbl_proforma_invoice
                          : "Proforma Invoice"}
                      </h1>
                    </div>

                    <div className="d-md-flex justify-content-end align-items-center col-12 col-md-7">
                      <Tooltip id={"show-tooltip"} place="top" />
                      <div
                        className="dropdown"
                        data-tooltip-id={"show-tooltip"}
                        data-tooltip-content={"Show / Hide Columns"}
                      >
                        <div
                          className="d-md-flex justify-content-start align-items-center"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {/* <FontAwesomeIcon icon={faListCheck} size="lg" /> */}
                          <img
                            src={List}
                            height="32"
                            width="32"
                            className="cursor_pointer"
                          />
                        </div>

                        <ul className="dropdown-menu px-2">
                          {showLabel !== undefined && showLabel.length > 0
                            ? showLabel.map((val, ind, arr) => (
                                <li key={ind}>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      style={{ width: "20px", height: "20px" }}
                                      onChange={() => handleClick(ind)}
                                      value=""
                                      checked={val.flag}
                                    />
                                    <label
                                      className=""
                                      style={{ fontSize: "13px" }}
                                      htmlFor="flexCheckDefault"
                                    >
                                      {val.label}
                                    </label>
                                  </div>
                                </li>
                              ))
                            : null}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="mt-3">
                    {dataList.loading ? (
                      <div className="text-center">
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    ) : dataList.data.length > 0 ? (
                      <>
                        <div className="table-responsive">
                          <table className="table table-bordered">
                            <thead className="table-grey roboto-bold">
                              <tr className="">
                                <th scope="col">{"Sr.No"}</th>
                                {showLabel.map((v, i) =>
                                  v.label === "Invoice Id" && v.flag ? (
                                    <th key={i} scope="col">
                                      {constLabel?.lbl_invoice_id
                                        ? constLabel.lbl_invoice_id
                                        : "Invoice Id"}
                                    </th>
                                  ) : v.label === "Invoice Date" && v.flag ? (
                                    <th key={i} scope="col">
                                      {constLabel?.lbl_invoice_date
                                        ? constLabel.lbl_invoice_date
                                        : "Invoice Date"}
                                    </th>
                                  ) : v.label === "Customer" && v.flag ? (
                                    <th key={i} scope="col">
                                      {constLabel?.lbl_customer_name
                                        ? constLabel.lbl_customer_name
                                        : "Customer"}
                                    </th>
                                  ) : v.label === "Total Amount ₹" && v.flag ? (
                                    <th key={i} scope="col">
                                      {constLabel?.lbl_total_amount
                                        ? constLabel.lbl_total_amount
                                        : "Total Amount ₹"}
                                    </th>
                                  ) : v.label === "GST Amount ₹" && v.flag ? (
                                    <th key={i} scope="col">
                                      {constLabel?.lbl_gst_amount
                                        ? constLabel.lbl_gst_amount
                                        : "GST Amount ₹"}
                                    </th>
                                  ) : v.label === "Advance Amount ₹" &&
                                    v.flag ? (
                                    <th key={i} scope="col">
                                      {constLabel?.lbl_advance_amount
                                        ? constLabel.lbl_advance_amount
                                        : "Advance Amount ₹"}
                                    </th>
                                  ) : v.label === "Outstanding Amount ₹" &&
                                    v.flag ? (
                                    <th key={i} scope="col">
                                      {constLabel?.lbl_outsanding_amount
                                        ? constLabel.lbl_outsanding_amount
                                        : "Outstanding Amount ₹"}
                                    </th>
                                  ) : null
                                )}
                                <th scope="col" width="10%">
                                  {"Action"}
                                </th>
                              </tr>
                            </thead>
                            <tbody className="roboto">
                              {dataList.data.map((val, ind) => (
                                <tr key={ind}>
                                  <td>{parseInt(ind) + 1 + parseInt(srNo)}</td>
                                  {showLabel.map((item, idx) =>
                                    item.label === "Invoice Id" && item.flag ? (
                                      <td key={idx}>
                                        {val.invoice_id != "" &&
                                        val.invoice_id !== null
                                          ? val.invoice_id
                                          : "NA"}
                                      </td>
                                    ) : item.label === "Invoice Date" &&
                                      item.flag ? (
                                      <td key={idx}>
                                        {val.invoice_date != "" &&
                                        val.invoice_date !== null
                                          ? formatDate5(val.invoice_date, "-")
                                          : "NA"}
                                      </td>
                                    ) : item.label === "Customer" &&
                                      item.flag ? (
                                      <td key={idx}>
                                        {val?.party_details?.name != "" &&
                                        val?.party_details?.name != null
                                          ? val?.party_details?.name
                                          : "NA"}
                                      </td>
                                    ) : item.label === "Total Amount ₹" &&
                                      item.flag ? (
                                      <td key={idx}>
                                        {val.sub_total != "" &&
                                        val.sub_total != null
                                          ? val.sub_total
                                          : "NA"}
                                      </td>
                                    ) : item.label === "GST Amount ₹" &&
                                      item.flag ? (
                                      <td key={idx}>
                                        {val.gst_amount != "" &&
                                        val.gst_amount != null
                                          ? val.gst_amount
                                          : "NA"}
                                      </td>
                                    ) : item.label === "Advance Amount ₹" &&
                                      item.flag ? (
                                      <td key={idx}>
                                        {val.advance_amount != "" &&
                                        val.advance_amount != null
                                          ? val.advance_amount
                                          : "NA"}
                                      </td>
                                    ) : item.label === "Outstanding Amount ₹" &&
                                      item.flag ? (
                                      <td key={idx}>
                                        {val.outstanding_amount != "" &&
                                        val.outstanding_amount != null
                                          ? val.outstanding_amount
                                          : "NA"}
                                      </td>
                                    ) : null
                                  )}
                                  <td>
                                    {privilage.write && (
                                      <>
                                        <Tooltip
                                          id={"edit-tooltip" + ind}
                                          place="top"
                                        />
                                        <Edit
                                          data-tooltip-id={"edit-tooltip" + ind}
                                          data-tooltip-content={
                                            constLabel?.lbl_profoma_invoice
                                              ? constLabel.lbl_profoma_invoice
                                              : "Edit Proforma Invoice"
                                          }
                                          className="menu_icon_grey cursor_pointer me-2"
                                          size={20}
                                          onClick={() => {
                                            navigate(`/pcms/update-proforma-invoice`, {
                                              state: val ,
                                            })
                                          }}
                                        />
                                      </>
                                    )}

                                    {privilage.read && (
                                      <>
                                        <Tooltip
                                          id={"view-tooltip" + ind}
                                          place="top"
                                        />
                                        <Eye
                                          data-tooltip-id={"view-tooltip" + ind}
                                          data-tooltip-content={
                                            constLabel?.lbl_view_proforma_invoice
                                              ? constLabel.lbl_view_proforma_invoice
                                              : "View Proforma Invoice"
                                          }
                                          className="menu_icon_grey cursor_pointer me-2"
                                          size={20}
                                          onClick={() =>
                                            console.log("View Proforma Invoice")
                                          }
                                        />
                                      </>
                                    )}

                                    {privilage.delete && (
                                      <>
                                        <Tooltip
                                          id={"delete-tooltip" + ind}
                                          place="top"
                                        />
                                        <Trash2
                                          data-tooltip-id={
                                            "delete-tooltip" + ind
                                          }
                                          data-tooltip-content={
                                            constLabel?.lbl_delete_proforma_invoice
                                              ? constLabel.lbl_delete_proforma_invoice
                                              : "Delete Proforma Invoice"
                                          }
                                          className="menu_icon_red cursor_pointer me-2"
                                          size={20}
                                          onClick={() =>
                                            delProformaInvoice(val)
                                          }
                                        />
                                      </>
                                    )}

                                    {privilage.read && (
                                      <>
                                        <Tooltip
                                          id={"pdf-tooltip" + ind}
                                          place="top"
                                        />
                                        <FileText
                                          data-tooltip-id={"pdf-tooltip" + ind}
                                          data-tooltip-content={
                                            constLabel?.lbl_generate_pdf
                                              ? constLabel.lbl_generate_pdf
                                              : "Generate PDF"
                                          }
                                          className="menu_icon_grey cursor_pointer me-2"
                                          size={20}
                                          onClick={() => {
                                            navigate(`/pcms/proforma-invoice-pdf`, {
                                              state: val,
                                            });
                                          }}
                                        />
                                      </>
                                    )}

                                    {privilage.write && (
                                      <>
                                        <Tooltip
                                          id={"payment-tooltip" + ind}
                                          place="top"
                                        />
                                        <Pocket
                                          data-tooltip-id={"payment-tooltip" + ind}
                                          data-tooltip-content={
                                            constLabel?.lbl_create_payment_receipt
                                              ? constLabel.lbl_create_payment_receipt
                                              : "Create Payment Receipt"
                                          }
                                          className="menu_icon_grey cursor_pointer me-2"
                                          size={20}
                                          onClick={() => {
                                            setVisible(true);
                                            setData(val)
                                          }}
                                        />
                                      </>
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>

                        <div className="d-flex justify-content-between align-items-center mt-2">
                          <p className="mb-0">
                            Show
                            <select
                              className="mx-1"
                              defaultValue={entriesPerPage}
                              onChange={(e) => {
                                setEntriesPerPage(e.target.value);
                                fetchProformaInvoiceList(1, e.target.value);
                              }}
                            >
                              <option value={"10"}>10</option>
                              <option value="25">25</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                            </select>
                            Entries
                          </p>
                          <p className="mb-0">{`Showing ${
                            parseInt(srNo) + 1
                          } to ${Math.min(
                            parseInt(entriesPerPage) + parseInt(srNo),
                            dataList.totalRecords
                          )} of ${dataList.totalRecords} entries`}</p>
                          <Pagination className="my-2">
                            <Pagination.Prev
                              disabled={page === 1 ? true : false}
                              onClick={() => {
                                fetchProformaInvoiceList(
                                  page - 1,
                                  entriesPerPage,
                                  parseInt(srNo) - parseInt(entriesPerPage)
                                );
                                setSrNo((prevC) =>
                                  page - 1 == 1
                                    ? 0
                                    : prevC - parseInt(entriesPerPage)
                                );
                                setPage(page - 1);
                              }}
                            >
                              {"Prev"}
                            </Pagination.Prev>

                            <Pagination.Item active>{page}</Pagination.Item>

                            <Pagination.Next
                              disabled={
                                page === maxPage ||
                                maxPage === 0 ||
                                entriesPerPage > dataList.data.length
                                  ? true
                                  : false
                              }
                              onClick={() => {
                                fetchProformaInvoiceList(
                                  page + 1,
                                  entriesPerPage,
                                  parseInt(srNo) + parseInt(entriesPerPage)
                                );
                                setSrNo(
                                  (prevC) => prevC + parseInt(entriesPerPage)
                                );
                                setPage(page + 1);
                              }}
                            >
                              {"Next"}
                            </Pagination.Next>
                          </Pagination>
                        </div>
                      </>
                    ) : (
                      <div
                        className="alert alert-danger mb-0 text-center my-4"
                        role="alert"
                      >
                        No Proforma Invoice Found !
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </React.Fragment>
  );
}

export default Proforma_Invoice;
