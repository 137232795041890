import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Modal, Spinner } from "react-bootstrap";
import {
  getMarket_Group,
  getProduct_Family,
  getUOM,
  saveProduct,
} from "../../../api";
import { formatDate5, getLabels } from "../../../common/Functions/CommonFunctions";
import { Tooltip } from "react-tooltip";
import info from "../../../assets/images/info.png";

const schema = yup
  .object()
  .shape({
    productFamily: yup
      .array()
      .required("Please select product family")
      .min(1, "Please select product family"),
    productName: yup.string().required("Product name is required !"),
    salesPrice: yup
      .string()
      .required("Selling price is required !")
      .matches(/^\d+(\.\d+)?$/, "Enter valid selling price"),
  })
  .required();

function Add_Product(props) {
  const { fetchProdList, prodType, setVisible, visible, gender, newProduct } = props;

  const [marketGroup, setMarketGroup] = useState({ data: [], loading: false });
  const [prodFamily, setProdFamily] = useState({ data: [], loading: false });
  const [uomList, setUOMList] = useState({ data: [], loading: false });
  const [lockBtn, setLockBtn] = useState(false);

  const [constLabel, setConstLabel] = useState({});

  const { register, handleSubmit, formState, control, reset, watch, setValue } =
    useForm({
      resolver: yupResolver(schema),
      mode: "onChange",
    }); // initialise the hook

  let { errors } = formState;
  useEffect(() => {
    setConstLabel(getLabels("Master", "Product"));
    fetchMarketGroup();
    fetchProductFamily();
    fetchUOM();
  }, []);

  useEffect(() => {
    if(newProduct !== ""){
      setValue("productName", newProduct, {shouldValidate: true});
    }
  }, [newProduct])
  

  function fetchProductFamily() {
    setProdFamily({ ...prodFamily, data: [], loading: true });
    getProduct_Family().then(
      (res) => {
        if (res && res.data.data?.length > 0) {
          setProdFamily({
            ...prodFamily,
            data: res.data.data,
            loading: false,
          });
        } else {
          setProdFamily({ ...prodFamily, data: [], loading: false });
        }
      },
      (err) => {
        setProdFamily({ ...prodFamily, data: [], loading: false });
      }
    );
  }

  function fetchUOM() {
    setUOMList({ ...uomList, data: [], loading: true });
    getUOM().then(
      (res) => {
        if (res && res.data.data?.length > 0) {
          const result = res.data.data?.filter((item) => item.uom.toUpperCase() == "NOS");
          setUOMList({ ...uomList, data: result, loading: false });
          // setValue("unit", [res.data.data?.[0]]);
        } else {
          setUOMList({ ...uomList, data: [], loading: false });
        }
      },
      (err) => {
        setUOMList({ ...uomList, data: [], loading: false });
      }
    );
  }

  function fetchMarketGroup() {
      setMarketGroup({ ...marketGroup, data: [], loading: true });
      getMarket_Group().then(
        (res) => {
          if(res && res.data.data?.length > 0){
            setMarketGroup({
              ...marketGroup,
              data: res.data.data,
              loading: false,
            });
          } else {
            setMarketGroup({ ...marketGroup, data: [], loading: false });
          }  
        },
        (err) => {
          setMarketGroup({ ...marketGroup, data: [], loading: false });
        }
      );
    }

  function getInitials(str) {
    return str
      .split(/[\s\-_()]+/) // Split using space, hyphen, underscore, and parentheses
      .filter(word => word.length > 0) // Remove empty entries
      .map(word => word[0].toUpperCase()) // Get first letter and convert to uppercase
      .join(""); // Join the initials into a single string
  }

  const onSubmit = (data) => {
    setLockBtn(true);
    const res_wear = (gender == "Male" ? "Men's Wear" : "Women's Wear");
    const wearType = marketGroup.data?.filter((item) => (item.groupname).toUpperCase() == res_wear.toUpperCase());
    const new_part_no = `${gender == "Male" ? "MS" : "WS"}-${getInitials(data.productName)}-${formatDate5(new Date())}`

    const payload = {
      productType: prodType.data?.[0]?.prodTypeId,
      productTypename: prodType.data?.[0]?.productType,
      product_type_id: prodType.data?.[0]?.uuid,
      category: null,
      category_id: null,
      prodfamily: data.productFamily?.[0]?.familyId,
      prod_family_id: data.productFamily?.[0]?.uuid,
      productName: data.productName,
      OEMnumber: new_part_no,
      partNo: new_part_no,
      salesPrice: data.salesPrice,
      machineCost: "0",
      can_be_sold: true,
      can_be_purchased: false,
      purchase_price: 0,
      unit_id: uomList.data?.[0]?.uuid,
      batch_tracking: false,
      market_grp_id: wearType?.[0]?.uuid,
      marketgrp: wearType?.[0]?.grpId,
    };

    // console.log("payload", payload);
    saveProduct(payload).then(
      (res) => {
        if (res.status === 200 || res.status === 201) {
          toast.success("Finished goods product saved successfully !", {
            autoClose: 1000,
          });
          setTimeout(() => {
            fetchProdList([prodType.data?.[0]?.uuid]);
            setVisible(false);
            setLockBtn(false);
            reset({
              productFamily: [],
              salesPrice: "0.00",
              productName: "",
            });
          }, 1200);
        }
      },
      (err) => {
        if (err.response.status === 400) {
          toast.error(err.response.data.message, {
            autoClose: 1200,
          });
          // reset();
          setLockBtn(false);
          // reset({
          //   productFamily: [],
          //   salesPrice: "0.00",
          //   productName: "",
          // });
        }
      }
    );
  };

  return (
    <React.Fragment>
      {/* <Tooltip id={"add-tooltip"} place="top" />
      <div
        data-tooltip-id={"add-tooltip"}
        data-tooltip-content={
          props.constLabel?.lbl_add_product
            ? props.constLabel?.lbl_add_product
            : "Add Product"
        }
        className="cursor_pointer me-2"
      >
        <a
          className="px-2 mt-2 cursor_pointer"
          onClick={(e) => {
            e.preventDefault();
            setVisible(true);
            reset();
          }}
        >
          {constLabel?.lbl_add_product
            ? constLabel.lbl_add_product
            : "Add Product"}
        </a>
      </div> */}
      <Modal
        show={visible}
        onHide={() => {
          setVisible(false);
          reset({
            productFamily: [],
            salesPrice: "0.00",
            productName: "",
          });
        }}
        size={"sm"}
      >
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>
            {constLabel?.lbl_add_product
              ? constLabel.lbl_add_product
              : "Add Product"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="form-group typeahead-container mt-md-0 mt-3">
              <label className="lbl-style roboto-bold d-flex align-items-center">
                {props.constLabel?.lbl_product_family
                  ? props.constLabel?.lbl_product_family
                  : "Product Family"}{" "}
                <span className="text-danger"> *</span>
                <Tooltip
                  id={"open-tooltip2"}
                  place="top"
                  style={{ zIndex: 9999 }}
                />
                <img
                  src={info}
                  alt="info"
                  className="cursor_pointer"
                  data-tooltip-id={"open-tooltip2"}
                  data-tooltip-html=" Groups products that share common characteristics, functionalities, or manufacturing processes.<br /> 
                       This field helps categorize products into broader families, such as similar types of finished goods,<br /> 
                       components, or raw materials, enabling more efficient inventory management, reporting, and product<br />
                       lifecycle tracking."
                  height={20}
                  width={20}
                />
              </label>
              <Controller
                name="productFamily"
                control={control}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Typeahead
                    id="basic-typeahead-single"
                    labelKey="familyname"
                    className="mt-2"
                    multiple={false}
                    options={prodFamily.data}
                    isLoading={prodFamily.loading}
                    placeholder="Select product family"
                    onChange={onChange}
                    selected={value}
                    onBlur={onBlur}
                    isInvalid={errors.productFamily ? true : false}
                  />
                )}
              />
              {!prodFamily.loading && !errors.productFamily && (
                <span className="arrow-icon"></span>
              )}
              {errors.productFamily && (
                <span className="text-danger err-msg">
                  {errors.productFamily.message}
                </span>
              )}
            </div>
          </div>

          <div className="mt-3">
            <div className="form-group mt-md-0 mt-3">
              <label className="lbl-style roboto-bold">
                {props.constLabel?.lbl_product_name
                  ? props.constLabel.lbl_product_name
                  : "Product Name"}
                <span className="text-danger"> *</span>
              </label>
              <input
                className={
                  errors.productName
                    ? "form-control mt-2 is-invalid"
                    : "form-control mt-2"
                }
                type="text"
                // defaultValue={newProduct || ""}
                placeholder="Enter product name"
                name="productName"
                {...register("productName")}
              />
              {errors.productName && (
                <span className="text-danger err-msg">
                  {errors.productName.message}
                </span>
              )}
            </div>
          </div>

          <div className="mt-3">
            <div className="form-group">
              <label className="lbl-style roboto-bold">
                {props.constLabel?.lbl_selling_price
                  ? props.constLabel.lbl_selling_price
                  : "Selling Price"}{" "}
                <span>&#8377;</span>
              </label>
              <input
                className={
                  errors.salesPrice
                    ? "form-control mt-2 is-invalid"
                    : "form-control mt-2"
                }
                type="text"
                placeholder="Enter selling price"
                name="salesPrice"
                defaultValue="0.00"
                {...register("salesPrice")}
              />
              {errors.salesPrice && (
                <span className="text-danger err-msg">
                  {errors.salesPrice.message}
                </span>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn button-primary px-4 me-2"
            onClick={handleSubmit(onSubmit)}
            disabled={lockBtn}
          >
            {constLabel?.lbl_save ? constLabel.lbl_save : "Save"}{" "}
            {lockBtn ? <Spinner animation="border" size="sm" /> : null}{" "}
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default Add_Product;
