import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faAdd,
  faEye,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import { Typeahead } from "react-bootstrap-typeahead";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Trash2 } from "react-feather";
import {
  getProdList,
  getUOM,
  createBOM,
  getProduct_Family,
  getBOM,
  get_product_light,
} from "../../../../../api/index";
import { ToastContainer, toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { getLabels } from "../../../../../common/Functions/CommonFunctions";

const schema = yup
  .object()
  .shape({
    quantity: yup.string()
      .required("Quantity is required")
      // .matches(/^\d+$/, "Enter valid quantity")
      .matches(/^\d+(\.\d+)?$/, "Enter valid quantity")
      .typeError('Enter valid quantity'),
    bomType: yup.string().required("Please select bill of material"),
    bom_name: yup.string().required("Bom name is required!"),
    remarks: yup.string().required("Remark is required"),
    items: yup.array().of(
      yup.object().shape({
        prodFamily: yup.array().notRequired(),
        component: yup.array().notRequired(),
        quantity: yup.string()
          .required("Quantity is required")
          // .matches(/^\d+$/, "Enter valid quantity")
          .matches(/^\d+(\.\d+)?$/, "Enter valid quantity")
          .typeError('Enter valid quantity'),
        unitOfMeasure: yup.array()
          .min(1, "Please select unit of measure")
          .required("Please select unit of measure"),
      })
    ),
  })
  .required();

function CreateBOM({
    nextStep,
    previousStep,
    setBomShow,
    bomShow,
    main_obj,
    setMain_obj,
  }) {
    let navigate = useNavigate();
    let location = useLocation();
    const [itemsData, setItemsData] = useState([{ prodFam: [], component: [] }]);
    const [btnDisable, setBtnDisable] = useState(false);
  
    const {
      register,
      handleSubmit,
      formState,
      control,
      reset,
      watch,
      setValue,
      getValues,
      setError,
    } = useForm({
      resolver: yupResolver(schema),
      mode: "onChange",
      defaultValues: {
        items: [{}],
      },
    });
  
    let { errors } = formState;
  
    const { fields, append, remove } = useFieldArray({
      control,
      name: "items",
    });
  
    const [prodList, setProdList] = useState({ data: [], loading: false });
    const [prodFamily, setProdFamily] = useState({ data: [], loading: false });
    const [uom, setUom] = useState({ data: [], loading: false });
  
    const [constLabel, setConstLabel] = useState({});
    const [prod, setProd] =  useState("");
  
    useEffect(() => {
      
      setConstLabel(getLabels('Master', 'Product'));
      if (location.state !== null && location.state !== undefined) {
        reset({ product: [location.state.product] });
      }
      // fetchProdList();
      fetchUOM();
      fetchProdFamily();
    }, []);

    useEffect(() => {
          const debounceTimeout = setTimeout(() => {
            if (prod.trim().length >= 4) {
              fetchProdList(prod);
            }
          }, 1000); // Adjust the delay as needed (e.g., 500 milliseconds)
      
          return () => {
            clearTimeout(debounceTimeout); // Clear the timeout if searchTerm changes before the timeout expires
          };
        }, [prod]);

    useEffect(() => {
      // console.log("main_obj", main_obj);
      fetchBOM(main_obj?.prod_overview?.productName)
    }, [main_obj])
    
  
    function fetchProdFamily() {
      setProdFamily({ ...prodFamily, data: [], loading: true });
      getProduct_Family().then(
        (res) => {
          
          setProdFamily({ ...prodFamily, data: res.data.data, loading: false });
        },
        (err) => {
          
          setProdFamily({ ...prodFamily, data: [], loading: false });
        }
      );
    }
  
    function fetchUOM() {
      setUom({ ...uom, data: [], loading: true });
      getUOM().then(
        (res) => {
          
          setUom({ ...uom, data: res.data.data, loading: false });
        },
        (err) => {
          
          setUom({ ...uom, data: [], loading: false });
        }
      );
    }
  
    function fetchProdList(prod) {
      setProdList({ ...prodList, data: [], loading: true });
  
      get_product_light({searchVal: prod}).then(
        (res) => {
          
          let arr = res.data.data.filter(
            (item) => item.productName !== null || item.productName !== undefined
          );
          setProdList({ ...prodList, data: arr, loading: false });
        },
        (err) => {
          
          setProdList({ ...prodList, data: [], loading: false });
        }
      );
    }
  
    const onSubmit = (data) => {
      
      setBtnDisable(true);
  
      let tempLineItems = [];
      for (let i = 0; i < itemsData.length; i++) {
        if (
          itemsData[i].component.length == 0 &&
          itemsData[i].prodFam.length == 0
        ) {
          toast.error("Please select either one product family or component", {
            autoClose: 5000,
          });
          return;
        } else {
          // for (let i = 0; i < data.items.length; i++) {
          if (itemsData[i].component.length > 0) {
            tempLineItems.push({
              product_id: itemsData[i].component[0].uuid,
              productType: itemsData[i].component[0].productTypename,
              productId: itemsData[i].component[0].productId,
              productname: itemsData[i].component[0].productName,
              qty: data.items[i].quantity,
              unitname: data.items[i].unitOfMeasure[0].uom,
              unit: data.items[i].unitOfMeasure[0].uomid,
              unit_id: data.items[i].unitOfMeasure[0].uuid
            });
          }
  
          if (itemsData[i].prodFam.length > 0) {
            tempLineItems.push({
              product_family_id: itemsData[i].prodFam[0].uuid,
              productname: itemsData[i].prodFam[0].familyname,
              productId: itemsData[i].prodFam[0].familyId,
              qty: data.items[i].quantity,
              unitname: data.items[i].unitOfMeasure[0].uom,
              unit: data.items[i].unitOfMeasure[0].uomid,
              unit_id: data.items[i].unitOfMeasure[0].uuid
            });
          }
          // }
        }
      }
      
      const payload = {
        // "productId": data.product[0].productId,
        productType: main_obj && main_obj?.prod_overview?.productTypename,
        qty: data.quantity,
        productname: main_obj && main_obj?.prod_overview?.productName,
        product_id: main_obj && main_obj?.prod_overview?.uuid,
        productId: main_obj && main_obj?.prod_overview?.productId,
        product_family_id: main_obj && main_obj?.prod_overview?.prod_family_id?.uuid,
        product_type_id: main_obj && main_obj?.prod_overview?.product_type_id?.uuid,
        remarks: data.remarks,
        bom_type: data.bomType,
        bom_line_item: tempLineItems,
        bom_name: data.bom_name
      };
  
      // console.log("payload", payload); return;
      createBOM(payload).then(
        (res) => {
          
          toast.success("BOM created successfully !", {
            autoClose: 2000,
          });
  
          setTimeout(() => {
            setMain_obj({...main_obj,  obj_bom:{ ...main_obj.obj_bom, data: res.data}})
            nextStep()
            setBtnDisable(false);
          }, [2300]);
        },
        (err) => {
          setBtnDisable(false);
          
          toast.error("Something went wrong !", {
            autoClose: 2000,
          });
        }
      );
    };
  
    function handleProdFam(e, ind) {
      const temp = [...itemsData];
      
      
      
      temp[ind].prodFam = e;
      temp[ind].component = [];
  
      setItemsData(temp);
    }
  
    function handleProdComp(e, ind) {
      const temp = [...itemsData];
      
      
      
      temp[ind].component = e;
      temp[ind].prodFam = [];
  
      setItemsData(temp);
    }

    function fetchBOM(bom_prod){
        getBOM({productname : bom_prod}).then(
          (res) => {
            // console.log("res", res);
            if (res && res.data.data.length > 0) {
              const latestBomName = getLatestBom(res.data.data);
              const latest_name = getNextBomName(latestBomName, bom_prod);
              setValue("bom_name", latest_name);
            } 
          },
          (err) => {
            // console.log("err", err);
            if(err.response.status == 404 && err.response.data.data.length == 0){
              setValue("bom_name", `${bom_prod}-BOM-01`);
            }
          }
        );
      }
    
      function getLatestBom(bomList){
        return bomList.reduce((latest, current) => 
          new Date(current.created_at) > new Date(latest.created_at) ? current : latest
        );
      };
    
      function getNextBomName(latestBom, baseName){
        if (!latestBom || !latestBom.bom_name) {
          // If latestBom has no value, use base name with "-01"
          return `${baseName}-BOM-01`;
        }
      
        // Split bom_name from the hyphen
        const [name, mid, number] = latestBom.bom_name.split('-');
      
        // Increment the number by 1, or default to 1 if no valid number
        const nextNumber = parseInt(number) + 1 || 1;
      
        // Return the new name with incremented number
        return `${name}-${mid}-${nextNumber.toString().padStart(2, '0')}`;
      };

  return (
    <React.Fragment>
      <div className="bom-creation">
        <div className="row">
          <div className="col-12">
            <div className="card" style={{ borderRadius: '2px' }}>
              <div className="card-body">
                <label className="mb-4 roboto-bold">
                  {constLabel?.lbl_create_billOfMaterial ? constLabel.lbl_create_billOfMaterial : "Create Bill Of Material"}
                </label>
                <div className="row">

                <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                    <label
                      style={{ fontSize: "14px" }}
                      className="col-form-label col-12 col-md-3 roboto-bold"
                    >
                      {constLabel?.lbl_BOM_name ? constLabel.lbl_BOM_name : "BOM Name"} <span className='text-danger'> *</span>
                    </label>

                    <div className="col-12 col-md-9">
                      <input
                        type="text"
                        className={
                          errors.bom_name
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        {...register("bom_name")}
                        placeholder="Enter bom name"
                      />

                      {errors.bom_name && (
                        <span className="text-danger err-msg">
                          {errors.quantity.message}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                    <label
                      style={{ fontSize: "14px" }}
                      className="col-form-label col-12 col-md-3 roboto-bold"
                    >
                      {constLabel?.lbl_quantity ? constLabel.lbl_quantity : "Quantity"} <span className='text-danger'> * </span>
                    </label>

                    <div className="col-12 col-md-9">
                      <input
                        type="text"
                        className={
                          errors.quantity
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        {...register("quantity")}
                        placeholder="Enter Quantity"
                      />

                      {errors.quantity && (
                        <span className="text-danger err-msg">
                          {errors.quantity.message}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                    <label
                      style={{ fontSize: "14px" }}
                      className="col-form-label col-12 col-md-3 roboto-bold"
                    >
                      {constLabel?.lbl_bom_type ? constLabel.lbl_bom_type : "BOM Type"} <span className='text-danger'> * </span>
                    </label>

                    <div className="col-12 col-md-9">
                      <div className="form-check mt-2">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="bomType"
                          id="exampleRadios1"
                          value="Manufacture this product"
                          defaultChecked
                          {...register("bomType")}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="exampleRadios1"
                        >
                          {constLabel?.lbl_manufacture_this_product ? constLabel.lbl_manufacture_this_product : "Manufacture this product"}
                        </label>
                      </div>
                      <div className="form-check mt-2">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="bomType"
                          id="exampleRadios2"
                          value="Kit"
                          {...register("bomType")}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="exampleRadios2"
                        >
                          {constLabel?.lbl_kit ? constLabel.lbl_kit : "Kit"}
                        </label>
                      </div>
                      <div className="form-check mt-2">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="bomType"
                          id="exampleRadios3"
                          value="Subcontracting"
                          {...register("bomType")}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="exampleRadios3"
                        >
                          {constLabel?.lbl_subcontracting ? constLabel.lbl_subcontracting : "Subcontracting"}
                        </label>
                      </div>

                      {errors.bomType && (
                        <span className="text-danger err-msg">
                          {errors.bomType.message}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                    <label
                      style={{ fontSize: "14px" }}
                      className="col-form-label col-12 col-md-3 roboto-bold"
                    >
                      {constLabel?.lbl_remark ? constLabel.lbl_remark : "Remark"} <span className='text-danger'> * </span>
                    </label>

                    <div className="col-12 col-md-9">
                      <textarea
                        className={
                          errors.remarks
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        rows={"2"}
                        {...register("...remarks")}
                        placeholder="Enter remark"
                      ></textarea>

                      {errors.remarks && (
                        <span className="text-danger err-msg">
                          {errors.remarks.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div
                    className="py-3">
                    <table className="table table-responsive table-bordered">
                      <thead className="thead-light text-center">
                        <tr>
                          <th scope="col">{constLabel?.lbl_product_family ? constLabel.lbl_product_family : "Product Family"}</th>
                          <th scope="col">{constLabel?.lbl_component ? constLabel.lbl_component : "Component"}</th>
                          <th scope="col">{constLabel?.lbl_quantity ? constLabel.lbl_quantity : "Quantity"}</th>
                          <th scope="col">{constLabel?.lbl_product_unitOfMeasure ? constLabel.lbl_product_unitOfMeasure : "Product Unit of Measure"}</th>
                          <th scope="col">{"Action"}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {fields.map(({ id }, index) => (
                          <tr key={id} className="f-18">
                            <td>
                              <Typeahead
                                id="basic-typeahead-single"
                                labelKey="familyname"
                                multiple={false}
                                options={prodFamily.data}
                                isLoading={prodFamily.loading}
                                placeholder="Select product family"
                                onChange={(e) => {
                                  handleProdFam(e, index)
                                  setValue(`items[${index}].unitOfMeasure`, [], { shouldValidate : true})
                                }}
                                isInvalid={
                                  errors.items?.[index]?.prodFamily
                                    ? true
                                    : false
                                }
                                selected={itemsData[index].prodFam}
                              />

                              {errors.items?.[index]?.prodFamily && (
                                <span className="text-danger err-msg">
                                  {errors.items?.[index]?.prodFamily.message}
                                </span>
                              )}

                              {index + 1 == fields.length ? (
                                <div>
                                  <button
                                    style={{ fontSize: "16px" }}
                                    className="btn btn-sm button-primary px-2 mt-2"
                                    onClick={() => {
                                      let temp = [...itemsData];
                                      temp.push({ prodFam: [], component: [] });
                                      setItemsData(temp);
                                      append({});
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      style={{
                                        color: "#ffffff",
                                        marginRight: "5px",
                                      }}
                                      icon={faAdd}
                                      size="sm"
                                    />{" "}
                                    {constLabel?.lbl_add ? constLabel.lbl_add : "Add"}
                                  </button>
                                </div>
                              ) : null}
                            </td>
                            <td style={{ verticalAlign: "top" }}>
                              <Typeahead
                                id="basic-typeahead-single"
                                labelKey="productName"
                                multiple={false}
                                options={prodList.data}
                                isLoading={prodList.loading}
                                placeholder="Search component for selection"
                                onChange={(e) => {
                                  handleProdComp(e, index)
                                  if(e.length > 0 && e?.[0]?.unit_id){
                                    setValue(`items[${index}].unitOfMeasure`,[e?.[0]?.unit_id], { shouldValidate : true})
                                  } else {
                                    setValue(`items[${index}].unitOfMeasure`, [], { shouldValidate : true})
                                  }
                                }}
                                isInvalid={
                                  errors.items?.[index]?.component
                                    ? true
                                    : false
                                }
                                selected={itemsData[index].component}
                                onInputChange={(text) => setProd(text)}
                              />

                              {errors.items?.[index]?.component && (
                                <span className="text-danger err-msg">
                                  {errors.items?.[index]?.component.message}
                                </span>
                              )}
                            </td>

                            <td style={{ verticalAlign: "top" }}>
                              <input
                                className={
                                  errors.items?.[index]?.quantity
                                    ? "form-control is-invalid"
                                    : "form-control"
                                }
                                type="text"
                                name={`items[${index}].quantity`}
                                {...register(`items[${index}].quantity`)}
                                // onChange={(e) => handleFileChange(e, index)}
                                placeholder="Enter quantity"
                              />

                              {errors.items?.[index]?.quantity && (
                                <span className="text-danger err-msg">
                                  {errors.items?.[index]?.quantity.message}
                                </span>
                              )}
                            </td>

                            <td style={{ verticalAlign: "top" }}>
                              <Controller
                                name={`items[${index}].unitOfMeasure`}
                                control={control}
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                }) => (
                                  <Typeahead
                                    id="basic-typeahead-single"
                                    labelKey="uom"
                                    multiple={false}
                                    options={uom.data}
                                    isLoading={uom.loading}
                                    placeholder="Select Unit Of Measure"
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    selected={value}
                                    isInvalid={
                                      errors.items?.[index]?.unitOfMeasure
                                        ? true
                                        : false
                                    }
                                    disabled={itemsData[index]?.component?.length > 0 ? true : false}
                                  />
                                )}
                              />

                              {errors.items?.[index]?.unitOfMeasure && (
                                <span className="text-danger err-msg">
                                  {errors.items?.[index]?.unitOfMeasure.message}
                                </span>
                              )}
                            </td>

                            <td
                              style={{
                                textAlign: "center",
                                verticalAlign: "top",
                                width: "10%",
                              }}
                            >
                              <OverlayTrigger
                                placement="bottom"
                                overlay={<Tooltip id="tooltip">{constLabel?.lbl_delete_bom_item ? constLabel.lbl_delete_bom_item : "Delete BOM Item"}</Tooltip>}
                              >
                                <div
                                  className="deleteIcon cursor_pointer"
                                  onClick={
                                    index > 0
                                      ? () => {
                                        remove(index);
                                        let temp = [...itemsData];
                                        temp.splice(index, 1);

                                        remove(index);
                                      }
                                      : null
                                  }
                                >
                                  <Trash2 color="#FFFFFF" size={18} />
                                </div>
                              </OverlayTrigger>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="d-flex justify-content-end mt-4">
                  <button
                    className="btn btn-grey px-4 me-0 me-md-2"
                    // onClick={() => setBomShow(false) }
                    onClick={() => previousStep()}
                  >
                    {constLabel?.lbl_previous ? constLabel?.lbl_previous : "Previous"}
                  </button>
                  <button
                    className="btn button-primary px-4 me-2"
                    onClick={handleSubmit(onSubmit)}
                    disabled={btnDisable}
                  >
                    {constLabel?.lbl_save_continue ? constLabel?.lbl_save_continue : "Save & Continue"}
                  </button>
                  <button
                  className="btn btn-grey px-4 me-2"
                  onClick={() => nextStep()}
                >
                  {constLabel?.lbl_skip ? constLabel?.lbl_skip : "Skip"}
                </button>
                </div>

                
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default CreateBOM