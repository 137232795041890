import React, { useState, useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { Typeahead, TypeaheadInputMulti } from "react-bootstrap-typeahead";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  get_city,
  get_contact_relation,
  get_country,
  get_state,
  getCustomer,
  getCustomerType,
  saveCustomer,
  updateCustomer,
} from "../../../api";
import { ToastContainer, toast } from "react-toastify";
import DatePicker from "react-datepicker";
import {
  calMaxPage,
  convertDateYYMMDD,
} from "../../../common/Functions/CommonFunctions";

function Add_Customer(props) {
  const {
    visible,
    setVisible,
    cust_data,
    setCust_Data,
    main_obj,
    setMain_obj,
    cust_search,
    setCust_Search,
    searched,
    setSearched,
  } = props;

  const [countryList, setCountryList] = useState({ data: [], state: false });
  const [stateList, setStateList] = useState({ data: [], state: false });
  const [cityList, setCityList] = useState({ data: [], state: false });
  const [constLabel, setConstLabel] = useState({});
  const [lockBtn, setLockBtn] = useState(false);
  const [custType, setCustType] = useState([]);

  const [relation, setRelation] = useState({ data: [], loading: false });

  const localData = JSON.parse(localStorage.getItem("localData"));
  const def_org = localData?.organizations?.filter(
    (item) => item.default_org == true
  );

  const schema = yup
    .object()
    .shape({
      organization: yup
        .string()
        .required("Customer name is required !")
        .matches(/^[a-zA-Z&./ ]*$/, "Enter valid customer name"),
      primaryContactNo: yup
        .string()
        .matches(/^[6-9]\d{9}$/, "Enter a valid 10-digit mobile number!") // Ensures it starts with 6-9 and is exactly 10 digits
        .required("Enter primary contact no!"),
      cState: yup
        .array()
        .min(1, "Please select state !")
        .required("Please select state !"),
      cCountry: yup
        .array()
        .min(1, "Please select country !")
        .required("Please select country !"),
      cCity: yup
        .array()
        .min(1, "Please select city !")
        .required("Please select city !"),
      billing_address: yup.string().required("Enter billing address!"),
      shipping_address: yup.string().required("Enter shipping address!"),
      gender: yup
        .string()
        .required("Please select gender!")
        .typeError("Please select gender!"),
      rel_cont: yup
        .array()
        .test("is-required", "Please select relation!", function (value) {
          // Condition check
          const shouldValidate =
            main_obj?.customer_data?.length !== 0 &&
            cust_data?.[0]?.hasParent !== false;

          // Apply validation only when required
          if (shouldValidate) {
            return Array.isArray(value) && value.length > 0;
          }

          return true; // No validation needed
        }),
    })
    .required();

  const { register, handleSubmit, formState, reset, watch, control, setValue } =
    useForm({
      resolver: yupResolver(schema),
      mode: "onChange",
    });

  let { errors } = formState;

  const selectedCountry = watch("cCountry");
  const selectedState = watch("cState");

  useEffect(() => {
    // resetting the form
    // reset();
    setCountryList({ ...countryList, state: true, data: [] });
    get_country().then(
      (res) => {
        let result = res.data.data.filter(
          (item) => item.countryName === "India"
        );
        setValue("cCountry", result);

        setCountryList({
          ...countryList,
          state: false,
          data: res.data.data,
        });
      },
      (err) => {
        setCountryList({ ...countryList, state: false, data: [] });
        toast.error(err.response.data.message, {
          autoClose: 3000,
        });
      }
    );

    getCustomerType().then(
      (res) => {
        if (res && res.data.data?.length > 0) {
          const result = res.data.data.filter(
            (item) => item.typename == "Retail"
          );
          setCustType(result);
        } else {
          setCustType([]);
        }
      },
      (err) => {
        setCustType([]);
      }
    );

    getContactRelation();
  }, []);

  useEffect(() => {
    // fetching states for selected country through api
    if (selectedCountry !== undefined && selectedCountry.length !== 0) {
      setStateList({ ...stateList, data: [], state: true });

      const payload = {
        country_id: selectedCountry[0]?.uuid,
      };
      get_state(payload).then(
        (res) => {
          setStateList({
            ...stateList,
            data: res.data.data,
            state: false,
          });
        },
        (err) => {
          setStateList({ ...stateList, data: [], state: false });
        }
      );
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedState !== undefined && selectedState.length > 0) {
      setCityList({ ...cityList, data: [], state: true });
      const payload = {
        state_id: selectedState[0]?.uuid,
      };
      get_city(payload).then(
        (res) => {
          setCityList({ ...cityList, data: res.data.data, state: false });
        },
        (err) => {
          setCityList({ ...cityList, data: [], state: false });
        }
      );
    }
  }, [selectedState]);

  // Effect to update form values when cust_data changes
  useEffect(() => {
    if (cust_data && cust_data?.length > 0) {
      setValue("organization", cust_data[0]?.organization || "");
      setValue("primaryContactNo", cust_data[0]?.primaryContactNo || "");
      setValue(
        "sales_person_name",
        localData.employee_id !== null
          ? `${localData.employee_id?.FirstName} ${localData.employee_id?.LastName}`
          : `${localData.user?.first_name} ${localData.employee_id?.last_name}`
      );
      setValue("gender", cust_data[0]?.gender || "");
      setValue("kids_wear", cust_data[0]?.kids_wear || false);
      setValue(
        "billing_address",
        cust_data[0]?.billingAddresses?.[0]?.addressLine1 || ""
      );
      setValue(
        "shipping_address",
        cust_data[0]?.shippingAddresses?.[0]?.addressLine1 || ""
      );
      setValue(
        "rel_cont",
        cust_data[0]?.contact_relation_id !== null
          ? [cust_data[0]?.contact_relation]
          : []
      );
      // Set controlled fields (e.g., Typeahead)
      setValue(
        "cCountry",
        cust_data[0]?.country
          ? [
              {
                id: cust_data[0]?.country,
                countryName: cust_data[0]?.country_name,
                uuid: cust_data[0]?.country_id,
              },
            ]
          : []
      );

      setValue(
        "cState",
        cust_data[0]?.state
          ? [
              {
                id: cust_data[0]?.state,
                stateName: cust_data[0]?.state_name,
                uuid: cust_data[0]?.state_id,
              },
            ]
          : []
      );

      setValue(
        "cCity",
        cust_data[0]?.city
          ? [
              {
                id: cust_data[0]?.city,
                cityName: cust_data[0]?.city_name,
                uuid: cust_data[0]?.city_id,
              },
            ]
          : []
      );

      setValue(
        "birth_date",
        cust_data[0]?.dob !== null
          ? new Date(cust_data[0]?.dob)
          : null
      );
    } else {
      setValue("organization", searched?.name || "");
      setValue("primaryContactNo", searched?.number || "");
      setValue(
        "sales_person_name",
        localData.employee_id !== null
          ? `${localData.employee_id?.FirstName} ${localData.employee_id?.LastName}`
          : `${localData.user?.first_name} ${localData.employee_id?.last_name}`
      );
      setValue("gender", "");
      setValue("kids_wear", false);
      setValue("billing_address", "");
      setValue("shipping_address", "");

      // Set controlled fields (e.g., Typeahead)
      setValue("cCountry", []);
      setValue("cState", []);
      setValue("cCity", []);
      setValue("birth_date", null);
    }
  }, [cust_data, setValue]);

  function getContactRelation() {
    setRelation({ ...relation, data: [], loading: true });
    get_contact_relation().then(
      (res) => {
        if (res && res.data.data?.length > 0) {
          setRelation({ ...relation, data: res.data.data, loading: false });
        } else {
          setRelation({ ...relation, data: [], loading: false });
        }
      },
      (err) => {
        setRelation({ ...relation, data: [], loading: false });
      }
    );
  }

  const onSubmit = (data) => {
    // console.log("cust_data", cust_data);
    // console.log("data", data);

    setLockBtn(true);

    const payload = {
      // "uuid": "cc34d47f-38ac-4050-aca1-eb1ed50c9e69",
      party_contact: [
        {
          firstName: data.organization,
          country_code: 32,
          mobileNo: data.primaryContactNo,
          alternateMobileNo: "",
          emailid: "",
          remark: "",
          lastName: data.organization,
          landlineExtentionNo: "",
          landlineNo: "",
          alternateLandlineExtentionNo: "",
          alternateLandlineNo: "",
          designation: "",
        },
      ],
      billingAddresse: [
        {
          // uuid : "d9f6f10f-2258-4ad9-a349-9d1cc37cd3a8",
          addressType: "billing",
          addressLine1: data.billing_address,
          addressLine2: null,
          postalCode: "416001",
          country: (data?.cCountry[0]?.id).toString(),
          state: (data.cState[0]?.id).toString(),
          city: (data.cCity[0]?.id).toString(),
          country_name: data.cCountry[0]?.countryName,
          state_name: data.cState[0]?.stateName,
          city_name: data.cCity[0]?.cityName,
          country_id: data.cCountry[0]?.uuid,
          state_id: data.cState[0]?.uuid,
          city_id: data.cCity[0]?.uuid,
        },
      ],
      shippingAddresse: [
        {
          // uuid : "dc7929c8-c1b9-4a42-8b6d-f0ac26d04710",
          addressType: "shipping",
          addressLine1: data.shipping_address,
          addressLine2: "",
          postalCode: "416001",
          country: (data?.cCountry[0]?.id).toString(),
          state: (data.cState[0]?.id).toString(),
          city: (data.cCity[0]?.id).toString(),
          country_name: data.cCountry[0]?.countryName,
          state_name: data.cState[0]?.stateName,
          city_name: data.cCity[0]?.cityName,
          country_id: data.cCountry[0]?.uuid,
          state_id: data.cState[0]?.uuid,
          city_id: data.cCity[0]?.uuid,
        },
      ],
      partyType: "Customer",
      organization: data.organization,
      org_id: def_org?.[0]?.uuid,
      website: null,
      name: data.organization,
      country_code: 91,
      primaryContactNo: data.primaryContactNo,
      secondaryContactNo: null,
      email: null,
      country: (data?.cCountry[0]?.id).toString(),
      state: (data.cState[0]?.id).toString(),
      city: (data.cCity[0]?.id).toString(),
      country_name: data.cCountry[0]?.countryName,
      state_name: data.cState[0]?.stateName,
      city_name: data.cCity[0]?.cityName,
      country_id: data.cCountry[0]?.uuid,
      state_id: data.cState[0]?.uuid,
      city_id: data.cCity[0]?.uuid,
      address: data.billing_address,
      postalCode: "416001",
      createdBy: 1,
      modifiedBy: 1,
      TIN: "",
      TAN: "",
      VATNO: null,
      CSTNO: null,
      gst: "",
      serviceTaxNo: null,
      gst_treatment: "Unregistered",
      PANNO: null,
      gender: data.gender,
      dob:
        data.birth_date !== undefined && data.birth_date !== null
          ? convertDateYYMMDD(data.birth_date, "-")
          : null,
      kids_wear: data.kids_wear,
      contact_relation_id:
        main_obj.customer_data?.length == 0 ? null : data.rel_cont?.[0]?.uuid,
    };

    if (cust_data?.length > 0) {
      payload.customerType = cust_data?.[0]?.customerType;
      payload.customer_type_id = cust_data?.[0]?.customer_type_id;

      if (cust_data?.[0]?.hasParent === true) {
        payload.parent_id = cust_data?.[0]?.parent_id;
        payload.hasParent = true;
      } else {
        payload.hasParent = false;
      }

      // Add existing UUIDs if present
      if (cust_data?.[0]?.uuid) {
        payload.uuid = cust_data[0].uuid;
      }
      if (cust_data?.[0]?.billingAddresses?.[0]?.uuid) {
        payload.billingAddresse[0].uuid = cust_data[0].billingAddresses[0].uuid;
      }
      if (cust_data?.[0]?.shippingAddresses?.[0]?.uuid) {
        payload.shippingAddresse[0].uuid =
          cust_data[0].shippingAddresses[0].uuid;
      }
    } else {
      payload.customerType = custType?.[0]?.typename;
      payload.customer_type_id = custType?.[0]?.uuid;
    }

    if (cust_search.length > 0 && cust_search?.[0]?.uuid !== payload.uuid) {
      payload.hasParent = true;
      payload.parent_id = cust_search?.[0]?.uuid;
    }

    // console.log("payload", payload);
    // return;

    // Determine whether to update or create
    if (cust_data?.[0]?.uuid) {
      

      updateCustomer(payload).then(
        async (res) => {
          // console.log("res", res);
          if (res.status === 200 || res.status === 201) {
            toast.success("Customer details updated successfully!", {
              autoClose: 1000,
            });
      
            // Fetch updated customer details using response uuid
            const updatedCustomer = await getCustomer({
              partyType: "Customer",
              uuid: res.data?.party?.uuid, // Fetch customer with response uuid
            }).then((resss) => resss.data?.data?.[0] || null); // Ensure we get a valid object
      
            if (updatedCustomer) {
              setCust_Data([updatedCustomer]);
              setCust_Search([updatedCustomer]);
      
              await getCustomer({
                partyType: "Customer",
                parent_id: updatedCustomer.uuid, // Use updated customer UUID
              }).then(
                (ress) => {
                  const result = ress.data?.data || []; // Ensure result is an array
      
                  setMain_obj((prevState) => {
                    let updatedCustomerData = prevState.customer_data.filter(
                      (c) => !result.some((r) => r.uuid === c.uuid)
                    ); // Remove old versions
      
                    updatedCustomerData = [...updatedCustomerData, ...result];
      
                    if (
                      updatedCustomer &&
                      !updatedCustomerData.some((c) => c.uuid === updatedCustomer.uuid)
                    ) {
                      updatedCustomerData.push(updatedCustomer);
                    }
      
                    return {
                      ...prevState,
                      customer_data: updatedCustomerData.map((val, ind) => ({
                        ...val,
                        selected: ind === 0, // Set first item as selected
                      })),
                    };
                  });
                },
                (err) => {
                  setMain_obj((prevState) => {
                    const updatedCustomerData = prevState.customer_data.map((cust) =>
                      cust.uuid === updatedCustomer?.uuid ? updatedCustomer : cust
                    );
                  
                    // If updatedCustomer doesn't exist in prevState, add it
                    if (
                      updatedCustomer &&
                      !updatedCustomerData.some((cust) => cust.uuid === updatedCustomer.uuid)
                    ) {
                      updatedCustomerData.push(updatedCustomer);
                    }
                  
                    return {
                      ...prevState,
                      customer_data: updatedCustomerData.map((val, ind) => ({
                        ...val,
                        selected: ind === 0, // Set first item as selected
                      })),
                    };
                  });
                }
              );
            }
      
            setTimeout(() => {
              setLockBtn(false);
              setVisible(false);
              setSearched({ name: "", number: "" });
            }, 1200);
          }
        },
        (err) => {
          if (err.response?.status === 400) {
            toast.error(err.response.data.message, { autoClose: 3000 });
          }
          setLockBtn(false);
        }
      );

    } else {
      saveCustomer(payload).then(
        async (res) => {
          if (res.status === 200 || res.status === 201) {
            toast.success("Customer details created successfully !", {
              autoClose: 1500,
            });

            getCustomer({
              partyType: "Customer",
              uuid: res.data.party.uuid,
            }).then(
              (ress) => {
                const result = ress.data?.data || []; // Ensure result is an array
                setCust_Data(ress.data?.data);
                setCust_Search(ress.data?.data);
                if (result.length > 0) {
                  setMain_obj((prevState) => {
                    const updatedCustomerData = [
                      ...prevState.customer_data.filter(
                        (c) => !result.some((r) => r.uuid === c.uuid)
                      ), // Remove old versions
                      ...result, // Add new updated values
                    ];
                
                    return {
                      ...prevState,
                      customer_data: updatedCustomerData.map((val, ind) => ({
                        ...val,
                        selected:
                          prevState.customer_data.length > 0
                            ? ind >= prevState.customer_data.length // Newly added item gets selected
                            : ind === 0, // If no previous customers, first item gets selected
                      })),
                    };
                  });
                }
              },
              (err) => {}
            );

            setTimeout(() => {
              setLockBtn(false);
              setVisible(false);
              setCust_Data([]);
              setSearched({ name: "", number: "" });
            }, 1800);
          }
        },
        (err) => {
          if (err.response.status === 400) {
            toast.error(err.response.data.message, {
              autoClose: 3000,
            });
            setLockBtn(false);
          }
        }
      );
    }
  };

  return (
    <React.Fragment>
      <Modal
        show={visible}
        onHide={() => {
          setVisible(false);
          setCust_Data([]);
          setSearched({ name: "", number: "" });
        }}
        size="lg"
        backdrop="static"
      >
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>
            {constLabel?.lbl_customer_creation
              ? constLabel.lbl_customer_creation
              : "Customer"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action="">
            <div className="row">
              {/* Customer name */}
              <div className="col-12 col-md-4">
                <div className="form-group typeahead-container">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label roboto-bold"
                  >
                    {constLabel?.lbl_customer_name
                      ? constLabel.lbl_customer_name
                      : "Customer Name"}{" "}
                    <span className="text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className={
                      errors.organization
                        ? "form-control  is-invalid"
                        : "form-control "
                    }
                    id="organization"
                    name="organization"
                    // defaultValue={cust_data?.[0]?.organization ?? ""}
                    placeholder="Enter customer name"
                    {...register("organization")}
                  />
                  <span className="text-danger err-msg">
                    {errors.name?.message}
                  </span>
                </div>
              </div>

              <div className="col-12 col-md-4">
                <div className="form-group typeahead-container">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label roboto-bold"
                  >
                    {constLabel?.lbl ? constLabel.lbl_contact_no : "Contact No"}{" "}
                    <span className="text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className={
                      errors.primaryContactNo
                        ? "form-control  is-invalid"
                        : "form-control "
                    }
                    id="primaryContactNo"
                    maxLength={10}
                    name="primaryContactNo"
                    // defaultValue={cust_data?.[0]?.primaryContactNo ?? ""}
                    placeholder="Enter contact no"
                    {...register("primaryContactNo")}
                  />
                  <span className="text-danger err-msg">
                    {errors.primaryContactNo?.message}
                  </span>
                </div>
              </div>

              <div className="col-12 col-md-4">
                <div className="form-group typeahead-container">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label roboto-bold"
                  >
                    {constLabel?.lbl_sale_person_name
                      ? constLabel.lbl_sale_person_name
                      : "Sales Person Name"}{" "}
                    <span className="text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className={
                      errors.name ? "form-control  is-invalid" : "form-control "
                    }
                    id="sales_person_name"
                    name="sales_person_name"
                    placeholder="Enter sales person name"
                    // defaultValue={
                    //   localData.employee_id !== null
                    //     ? `${localData.employee_id?.FirstName} ${localData.employee_id?.LastName}`
                    //     : `${localData.user?.first_name} ${localData.employee_id?.last_name}`
                    // }
                    disabled
                    {...register("sales_person_name")}
                  />
                  <span className="text-danger err-msg">
                    {errors.sales_person_name?.message}
                  </span>
                </div>
              </div>

              <div className="col-12 col-md-4 mt-3">
                <div className="form-group row">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label roboto-bold"
                  >
                    {constLabel?.lbl_gender ? constLabel.lbl_gender : "Gender"}
                    <span className="text-danger"> *</span>
                  </label>

                  <div className="col-md-12">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="inlineRadioOptions"
                        id="maleRadio"
                        {...register("gender")}
                        // defaultChecked={cust_data?.[0]?.gender === "Male"}
                        value="Male"
                      />
                      <label className="form-check-label" htmlFor="maleRadio">
                        Male
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="inlineRadioOptions"
                        id="femaleRadio"
                        // defaultChecked={cust_data?.[0]?.gender === "Female"}
                        {...register("gender")}
                        value="Female"
                      />
                      <label className="form-check-label" htmlFor="femaleRadio">
                        Female
                      </label>
                    </div>
                    <br />
                    <span className="text-danger err-msg">
                      {errors.gender?.message}
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-4 mt-3">
                <label
                  style={{ fontSize: "14px" }}
                  className="col-form-label roboto-bold invisible"
                >
                  {constLabel?.lbl_kids_wear
                    ? constLabel?.lbl_kids_wear
                    : "Kids Wear"}
                </label>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    // defaultChecked={
                    //   cust_data?.[0]?.kids_wear
                    //     ? cust_data?.[0]?.kids_wear
                    //     : false
                    // }
                    name="kids_wear"
                    {...register("kids_wear")}
                  />
                  <label className="form-check-label lbl-style roboto-bold">
                    {constLabel?.lbl_kids_wear
                      ? constLabel?.lbl_kids_wear
                      : "Kids Wear"}
                  </label>
                </div>
              </div>

              <div className="col-12 col-sm-4 mt-3">
                <div className="form-group">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label roboto-bold"
                  >
                    {constLabel?.lbl_birth_date
                      ? constLabel.lbl_birth_date
                      : "Birth Date"}
                  </label>
                  <Controller
                    name="birth_date"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        className="form-control"
                        {...field}
                        showYearDropdown
                        scrollableMonthYearDropdown
                        dateFormat="dd/MM/yyyy"
                        selected={field.value || null}
                        placeholderText="Select birth date"
                        maxDate={new Date()}
                        autoComplete="off"
                      />
                    )}
                  />
                  {/* {errors.birth_date && (
                      <span className="text-danger err-msg">
                        {errors.birth_date.message}
                      </span>
                    )} */}
                </div>
              </div>

              {main_obj.customer_data?.length == 0 ||
              cust_data?.[0]?.hasParent == false ? null : (
                <div className="col-12 col-md-4 mt-3">
                  <div className="form-group typeahead-container">
                    <label
                      style={{ fontSize: "14px" }}
                      className="col-form-label roboto-bold"
                    >
                      {constLabel?.lbl_relation
                        ? constLabel.lbl_relation
                        : "Relation"}{" "}
                      <span className="text-danger"> *</span>
                    </label>
                    <Controller
                      name={`rel_cont`}
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          id="basic-typeahead-single"
                          labelKey="relation_type"
                          multiple={false}
                          options={relation.data}
                          isLoading={relation.loading}
                          placeholder="Select contact relation"
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          isInvalid={errors.rel_cont ? true : false}
                        />
                      )}
                    />
                    {!relation.loading && !errors.rel_cont && (
                      <span className="arrow-icon"></span>
                    )}
                    {errors.rel_cont && (
                      <span className="text-danger err-msg">
                        {errors.rel_cont.message}
                      </span>
                    )}
                  </div>
                </div>
              )}

              <div className="col-12 col-md-4 mt-3">
                <div className="form-group typeahead-container">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label roboto-bold"
                  >
                    {constLabel?.lbl_country
                      ? constLabel.lbl_country
                      : "Country"}{" "}
                    <span className="text-danger"> *</span>
                  </label>
                  <Controller
                    name="cCountry"
                    control={control}
                    // defaultValue={
                    //   cust_data?.[0]?.country &&
                    //   (cust_data?.[0]?.country_name !== null ||
                    //     cust_data?.[0]?.country_id !== null)
                    //     ? [
                    //         {
                    //           id: cust_data?.[0]?.country,
                    //           countryName: cust_data?.[0]?.country_name,
                    //           uuid: cust_data?.[0]?.country_id,
                    //         },
                    //       ]
                    //     : []
                    // }
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        className={""}
                        id="basic-typeahead-single"
                        labelKey="countryName"
                        multiple={false}
                        options={
                          countryList.data !== undefined ? countryList.data : []
                        }
                        isLoading={countryList.state}
                        // defaultSelected={
                        //   cust_data?.[0]?.country &&
                        //   (cust_data?.[0]?.country_name !== null ||
                        //     cust_data?.[0]?.country_id !== null)
                        //     ? [
                        //         {
                        //           id: cust_data?.[0]?.country,
                        //           countryName: cust_data?.[0]?.country_name,
                        //           uuid: cust_data?.[0]?.country_id,
                        //         },
                        //       ]
                        //     : []
                        // }
                        placeholder="Select country"
                        onChange={(selected) => {
                          onChange(selected);
                          if (selected && selected?.length === 0) {
                            setValue("cState", [], { shouldValidate: true });
                            setCityList({
                              ...stateList,
                              data: [],
                              state: false,
                            });
                            setValue("cCity", [], { shouldValidate: true });
                            setCityList({
                              ...cityList,
                              data: [],
                              state: false,
                            });
                          }
                        }}
                        onBlur={onBlur}
                        selected={value}
                        isInvalid={errors.cCountry ? true : false}
                      />
                    )}
                  />
                  {!countryList.state && !errors.cCountry && (
                    <span className="arrow-icon"></span>
                  )}
                  <span className="text-danger err-msg">
                    {errors.cCountry?.message}
                  </span>
                </div>
              </div>

              <div className="col-12 col-md-4 mt-3">
                <div className="form-group typeahead-container">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label roboto-bold"
                  >
                    {constLabel?.lbl_state ? constLabel.lbl_state : "State"}{" "}
                    <span className="text-danger"> *</span>
                  </label>
                  <Controller
                    name="cState"
                    control={control}
                    // defaultValue={
                    //   cust_data?.[0]?.state &&
                    //   (cust_data?.[0]?.state_name !== null ||
                    //     cust_data?.[0]?.state_id !== null)
                    //     ? [
                    //         {
                    //           id: cust_data?.[0]?.state,
                    //           stateName: cust_data?.[0]?.state_name,
                    //           uuid: cust_data?.[0]?.state_id,
                    //         },
                    //       ]
                    //     : []
                    // }
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        className={""}
                        id="basic-typeahead-single"
                        labelKey="stateName"
                        multiple={false}
                        options={
                          stateList.data !== undefined ? stateList.data : []
                        }
                        isLoading={stateList.state}
                        // defaultSelected={
                        //   cust_data?.[0]?.state &&
                        //   (cust_data?.[0]?.state_name !== null ||
                        //     cust_data?.[0]?.state_id !== null)
                        //     ? [
                        //         {
                        //           id: cust_data?.[0]?.state,
                        //           stateName: cust_data?.[0]?.state_name,
                        //           uuid: cust_data?.[0]?.state_id,
                        //         },
                        //       ]
                        //     : []
                        // }
                        placeholder="Select state"
                        onChange={(selected) => {
                          onChange(selected);
                          if (selected && selected?.length === 0) {
                            setValue("cCity", [], { shouldValidate: true });
                            setCityList({
                              ...cityList,
                              data: [],
                              state: false,
                            });
                          }
                        }}
                        onBlur={onBlur}
                        selected={value}
                        isInvalid={errors.cState ? true : false}
                      />
                    )}
                  />
                  {!stateList.state && !errors.cState && (
                    <span className="arrow-icon"></span>
                  )}
                  <span className="text-danger err-msg">
                    {errors.cState?.message}
                  </span>
                </div>
              </div>

              <div className="col-12 col-md-4 mt-3">
                <div className="form-group typeahead-container">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label roboto-bold"
                  >
                    {constLabel?.lbl_city ? constLabel.lbl_city : "City"}{" "}
                    <span className="text-danger"> *</span>
                  </label>
                  <Controller
                    name="cCity"
                    control={control}
                    // defaultValue={
                    //   cust_data?.[0]?.city &&
                    //   (cust_data?.[0]?.city_name !== null ||
                    //     cust_data?.[0]?.city_id !== null)
                    //     ? [
                    //         {
                    //           id: cust_data?.[0]?.city,
                    //           cityName: cust_data?.[0]?.city_name,
                    //           uuid: cust_data?.[0]?.city_id,
                    //         },
                    //       ]
                    //     : []
                    // }
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        className={""}
                        id="basic-typeahead-single"
                        labelKey="cityName"
                        multiple={false}
                        options={
                          cityList.data !== undefined ? cityList.data : []
                        }
                        isLoading={cityList.state}
                        // defaultSelected={
                        //   cust_data?.[0]?.city &&
                        //   (cust_data?.[0]?.city_name !== null ||
                        //     cust_data?.[0]?.city_id !== null)
                        //     ? [
                        //         {
                        //           id: cust_data?.[0]?.city,
                        //           cityName: cust_data?.[0]?.city_name,
                        //           uuid: cust_data?.[0]?.city_id,
                        //         },
                        //       ]
                        //     : []
                        // }
                        placeholder="Select city"
                        // onChange={onChange}
                        onChange={(selected) => {
                          onChange(selected);
                          if (selected.length > 0) {
                            setValue("billing_address", selected[0]?.cityName, {
                              shouldValidate: true,
                            });
                            setValue(
                              "shipping_address",
                              selected[0]?.cityName,
                              { shouldValidate: true }
                            );
                          } else {
                            setValue("billing_address", selected[0]?.cityName, {
                              shouldValidate: true,
                            });
                            setValue(
                              "shipping_address",
                              selected[0]?.cityName,
                              { shouldValidate: true }
                            );
                          }
                        }}
                        onBlur={onBlur}
                        selected={value}
                        isInvalid={errors.cCity ? true : false}
                      />
                    )}
                  />
                  {!cityList.state && !errors.cCity && (
                    <span className="arrow-icon"></span>
                  )}
                  <span className="text-danger err-msg">
                    {errors.cCity?.message}
                  </span>
                </div>
              </div>

              <div className="col-12 col-md-4 mt-3">
                <div className="form-group typeahead-container">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label roboto-bold"
                  >
                    {constLabel?.lbl_billing_address
                      ? constLabel.lbl_billing_address
                      : "Billing Address"}{" "}
                    <span className="text-danger"> *</span>
                  </label>
                  <textarea
                    type="text"
                    className={
                      errors.name ? "form-control  is-invalid" : "form-control "
                    }
                    id="billing_address"
                    name="billing_address"
                    placeholder="Enter billing address"
                    // defaultValue={
                    //   cust_data.length > 0
                    //     ? cust_data?.[0]?.billingAddresses !== null
                    //       ? `${cust_data?.[0]?.billingAddresses?.[0]?.addressLine1}`
                    //       : ""
                    //     : ""
                    // }
                    {...register("billing_address")}
                  ></textarea>
                  <span className="text-danger err-msg">
                    {errors.billing_address?.message}
                  </span>
                </div>
              </div>

              <div className="col-12 col-md-4 mt-3">
                <div className="form-group typeahead-container">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label roboto-bold"
                  >
                    {constLabel?.lbl_shipping_address
                      ? constLabel.lbl_shipping_address
                      : "Shipping Address"}{" "}
                    <span className="text-danger"> *</span>
                  </label>
                  <textarea
                    type="text"
                    className={
                      errors.name ? "form-control  is-invalid" : "form-control "
                    }
                    id="shipping_address"
                    name="shipping_address"
                    placeholder="Enter shipping address"
                    // defaultValue={
                    //   cust_data.length > 0
                    //     ? cust_data?.[0]?.shippingAddresses !== null
                    //       ? `${cust_data?.[0]?.shippingAddresses?.[0]?.addressLine1}`
                    //       : ""
                    //     : ""
                    // }
                    {...register("shipping_address")}
                  ></textarea>
                  <span className="text-danger err-msg">
                    {errors.shipping_address?.message}
                  </span>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-grey px-4"
            onClick={handleSubmit(onSubmit)}
            disabled={lockBtn}
          >
            {constLabel?.lbl_save ? constLabel.lbl_save : "Save"}{" "}
            {lockBtn ? <Spinner animation="border" size="sm" /> : null}{" "}
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default Add_Customer;
