import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import SweetAlert from "sweetalert2";
import { ToastContainer } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import Step_One from "./Step_One";
import Step_Two from "./Step_Two";


function Create_Update_Sales_Order() {
  let navigate = useNavigate();
  let location = useLocation();

  const [main_obj, setMain_obj] = useState({
    customer_data: [],
    so_data: [],
  });

  const [currentStep, setCurrentStep] = useState(1);
    const steps_array = [
      "Customer Overview",
      "Sales Order Details",
    ];
    const numSteps = 2;
    
  const [constLabel, setConstLabel] = useState({});

  // useEffect(() => {
  // //  console.log("location ", location);
  //  if(location.state?.action === "update"){

  //   // Add `data` into `so_data` with `isSelected: true`
  //   setMain_obj(prev => ({
  //     ...prev,
  //     so_data: Object.keys(location.state?.data).length > 0 
  //       ? [...prev.so_data, { ...location.state?.data, isSelected: true }] 
  //       : prev.so_data
  //   }));

  //   setTimeout(() => {
  //     setCurrentStep(2);
  //   }, 50);
  //  }
  // }, [location.state])

  useEffect(() => {
    return () => {
      sessionStorage.removeItem("savedLocationState");
    };
  }, []);
  

  useEffect(() => {
    let savedState = sessionStorage.getItem("savedLocationState");
    let state = location.state || (savedState ? JSON.parse(savedState) : null);
  
    if (state?.action === "update") {
      // console.log("state", state);
      // Store the state in sessionStorage
      sessionStorage.setItem("savedLocationState", JSON.stringify(state));
  
      // Add `data` into `so_data` with `isSelected: true`
      setMain_obj(prev => ({
        ...prev,
        so_data: state?.data && Object.keys(state?.data).length > 0 
          ? [...prev.so_data, { ...state.data, isSelected: true }] 
          : prev.so_data
      }));
  
      setTimeout(() => {
        setCurrentStep(2);
      }, 50);
    }
  }, [location.state]);

  const nextStep = () => {
    let next = currentStep + 1;
    setCurrentStep(next);
  };

  const previousStep = (stepNumber) => {
    if (stepNumber !== undefined && stepNumber >= 1 && stepNumber <= numSteps) {
      setCurrentStep(stepNumber);
    } else {
      let prev = currentStep - 1;
      if (prev < 1) {
        prev = numSteps;
      }
      setCurrentStep(prev);
    }
  };

  
  return (
    <React.Fragment>
      <ToastContainer />

      <div className="purchase_order mb-4">
        <div className="card">
          <div className="card-header bg-grey d-flex align-items-center">
            <button
              className="btn btn-yellow"
              onClick={() => {
                SweetAlert.fire({
                  title: "Are you sure?",
                  text: "Your present data will be lost!",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes, go back!",
                }).then((result) => {
                  if (result.isConfirmed) {
                    window.onpopstate = undefined;
                    navigate("/pcms/sales-order");
                  }
                });
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} size="sm" />
            </button>

            <p className="mb-0 ms-3" style={{ fontSize: "24px" }}>
              {location.state?.action === "update" ? (constLabel?.lbl_update_sales_order
                ? constLabel.lbl_update_sales_order
                : "Update Sales Order") : (constLabel?.lbl_create_sales_order
                ? constLabel.lbl_create_sales_order
                : "Create Sales Order")}
            </p>
          </div>
          <div className="card-body">
            <div className="stepper-horizontal" id="stepper1" style={{width: "60%"}}>
              {[...Array(numSteps)].map((_, index) => {
                const stepNum = index + 1;
                const isEditing = stepNum === currentStep ? "editing" : "";
                const isDone = stepNum < currentStep ? "done" : "";

                return (
                  <div className={`step ${isEditing} ${isDone}`} key={index}>
                    <div className="step-circle">
                      <span>{stepNum}</span>
                    </div>
                    <div className="step-title">{steps_array[stepNum - 1]}</div>
                    <div className="step-bar-left"></div>
                    <div className="step-bar-right"></div>
                  </div>
                );
              })}
            </div>
            {currentStep == 1 && (
              <Step_One
                main_obj={main_obj}
                setMain_obj={setMain_obj}
                nextStep={nextStep}
              />
            )}
            {currentStep == 2 && (
              <Step_Two
                main_obj={main_obj}
                setMain_obj={setMain_obj}
                previousStep={previousStep}
                nextStep={nextStep}
              />
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Create_Update_Sales_Order;