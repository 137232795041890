// features/auth/authSlice.js
import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        loginData: null,    // Store loginData data upon successful login
        error: null,   // Store error message upon login failure
    },
    reducers: {
        loginSuccess: (state, action) => {
            // console.log("action.payload", action.payload);
            let congnito=localStorage.getItem("congnito");
            // let normal=localStorage.getItem("normal");
                      let obj={};
                if(congnito==="true"){
                       obj.response={
                        accessToken:action?.payload?.data?.response?.accessToken,
                        idToken:action?.payload?.data?.response?.idToken,
                        refreshToken:action?.payload?.data?.response?.refreshToken,
                        client_id:action?.payload?.data?.response?.response?.client_id,
                        app_id:action?.payload?.data?.response?.response?.app_id,
                        role_name:action?.payload?.data?.response?.response?.role_name,
                        role_id:action?.payload?.data?.response?.response?.role_id,
                        user_id:action?.payload?.data?.response?.response?.user_id,
                        module_list:action?.payload?.data?.response?.response?.module_list,
                        configuration:action?.payload?.data?.response?.response?.configuration,
                        industry_type:action?.payload?.data?.response?.response?.industry_type,
                        organizations:action?.payload?.data?.response?.response?.organizations,
                        employee_id:action?.payload?.data?.response?.response?.employee_id,
                        user:action?.payload?.data?.response?.response?.user,
                       }
                       obj.statusCode=action?.payload?.data?.statusCode;
                      action.payload.data={...obj} 
                    }
                    //   console.log("action",action.payload);
            if (action.payload?.data?.response?.role_name === 'client_admin' || action.payload?.data?.response?.role_name === 'admin') {
                let list = { ...action.payload.data.response }

                for (let i = 0; i < list.module_list.length; i++) {
                    for (const key in list.module_list[i]) {
                        if (typeof list.module_list[i][key] === 'boolean') {
                            // console.log("list.module_list[i]", list.module_list[i]);
                            list.module_list[i][key] = true;
                        }
                    }
                    list.module_list[i].delete = true;
                }
                // console.log("list", list);
                return {
                    ...state,
                    loginData: {
                        data: {
                            response: list,
                            statusCode: action.payload?.data?.statusCode
                        }
                    },
                    error: null
                };
            } else {
                return {
                    ...state,
                    loginData: action.payload,
                    error: null
                };
            }
            // state.loginData = action.payload;
            // state.error = null;
        },
        loginFailure: (state, action) => {
            return {
                ...state,
                loginData: null,
                error: action.payload
            };
            // state.loginData = null;
            // state.error = action.payload;
        },
        logout: (state) => {
            return {
                ...state,
                loginData: null,
                error: null
            };
            // state.loginData = null;
            // state.error = null;
        },
    },
});

export const { loginSuccess, loginFailure, logout } = authSlice.actions;
export default authSlice.reducer;
