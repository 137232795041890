import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faAdd,
  faEye,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import { Modal, Spinner, Tab, Tabs } from "react-bootstrap";
import { Check, Trash2 } from "react-feather";
import { Typeahead } from "react-bootstrap-typeahead";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import DatePicker from "react-datepicker";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import {

  get_challan_transport,
  get_challan_internal_transport,
  get_challan,
  get_last_challan,
  update_challan,
  get_purchaseInward_node,
  get_dispatch_pkg_store_qty,
  get_adjustment_reason,
  get_dispatch,
  get_stock_by_product,
} from "../../api/index";
import {
  convertDateYYMMDD,
  convertDate,
  getLabels,
} from "../../common/Functions/CommonFunctions";
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";

function EditGoodsChallan() {
  let navigate = useNavigate();
  const location = useLocation();
  const [locList, setLocList] = useState({ data: [], loading: false });
  const [opList, setOpList] = useState({ data: [], loading: false });
  const [challantypeList, setchallantypeList] = useState({
    data: [],
    loading: false,
  });

  const [products, setproducts] = useState({
    data: [],
    loading: false,
  });
  const [storeList, setstoreList] = useState({ data: [], loading: false });
  const [uomList, setUOMList] = useState({ data: [], loading: false });
  const [fromStorelist, setfromStorelist] = useState({
    data: [],
    loading: false,
  });
  const [toStorelist, settoStorelist] = useState({ data: [], loading: false });
  const [trasnportList, settransportList] = useState({
    data: [],
    loading: false,
  });
  const [internaltrasnportList, setinternaltransportList] = useState({
    data: [],
    loading: false,
  });
  const [workorderList, setworkorderList] = useState({
    data: [],
    loading: false,
  });

  const [jobcardList, setjobcardList] = useState({
    data: [],
    loading: false,
  });
  const [challanList, setchallanList] = useState({
    data: [],
    loading: true,
  });
  const [challantype, setchallantype] = useState([]);
  const [transporttype, settranporttype] = useState(null);
  const [constLabel, setConstLabel] = useState({});
  const [qty_data, setqty_data] = useState(null);
  const [show_work_quantity, setshow_work_quantity] = useState([
    { index: 0, show: false, value: 0 },
  ]);
  const [available_product_qty, setavailable_product_qty] = useState([]);

  const [buttonclick, setbuttonclick] = useState(false);

  const [piList, setpiList] = useState({ data: [], loading: true });
  const [adjustmentlist, setadjustmentlist] = useState({
    data: [],
    loading: true,
  });
  const adjustment_types = [{ name: "Issue" }, { name: "Receipt" }];
  const [adj_type, setadj_type] = useState(undefined);
  const [dispatchList, setDispatchList] = useState({
    data: [],
    loading: false,
  });
  const schema = yup
    .object()
    .shape({
      date: yup.date().required("Please select date !").typeError("Please select date !"),
      challanType: yup
        .array()
        .min(1, "Please select challan type !")
        .required("Please select challan type !"),
      outwardChallanRef: yup
        .array()
        .when(["challanType"], (challanType, schema) => {
          if (
            challanType !== undefined &&
            challanType.length > 0 &&
            challanType[0].typename.endsWith("Inward") &&
            challanType[0].typename !== "Customer Return Material Inward"&&
             challanType[0].typename !== "General Inward"
          ) {
            return schema
              .min(1, "Please select inward challan !")
              .required("Please select inward challan !");
          } else {
            return schema;
          }
        }),
      fromLocation: yup
        .array()
        .min(1, "Please select from location !")
        .required("Please select from location !"),
      fromstore: yup
        .array()
        .min(1, "Please select from store !")
        .required("Please select from store !"),
      toLocation: yup
        .array()
        .min(1, "Please select to location !")
        .required("Please select to location !"),
      tostore: yup
        .array()
        .min(1, "Please select to store !")
        .required("Please select to store !"),
      transportType: yup
        .array()
        .min(1, "Please select to transport type !")
        .required("Please select to transport type !"),
      driverName: yup.string().required("Driver name is required !"),
      internaltransport: yup
        .array()
        .when(["transportType"], (transportType, schema) => {
          if (
            transportType !== undefined &&
            transportType.length > 0 &&
            transportType[0].name == "Internal"
          ) {
            return schema
              .min(1, "Please select internal transport !")
              .required("Please select internal transport !");
          } else {
            return schema;
          }
        }),
      extTransport: yup
        .string()
        .when(["transportType"], (transportType, schema) => {
          if (
            transportType !== undefined &&
            transportType.length > 0 &&
            transportType[0].name == "External"
          ) {
            return schema.required("Vehical number is required !");
          } else {
            return schema;
          }
        }),
      airwayTransport: yup
        .string()
        .when(["transportType"], (transportType, schema) => {
          if (
            transportType !== undefined &&
            transportType.length > 0 &&
            transportType[0].name == "Airway"
          ) {
            return schema.required("Flight number is required !");
          } else {
            return schema;
          }
        }),
      items: yup.array().when("challanType", {
        is: (challanType) =>
          challanType &&
          challanType.length > 0 &&
          (
            // challanType[0].typename === "Job Work Outward" ||
            challanType[0].typename === "Job Work Inward" ||
            challanType[0].typename === "Packing"),
        then: yup.array().of(
          yup.object().shape({
            particulars: yup.string().required("Particulars is required!"),
            uom: yup
              .array()
              .min(1, "Please select unit!")
              .required("Please select unit!"),
            qty: yup
              .string()
              .required("Quantity is required!")
              .matches(/^[0-9]+(\.[0-9]+)?$/, "Enter valid quantity")
              .typeError("Enter valid quantity")
              .test("max-qty", function (value, context) {
                const index = context.path.match(/\d+/);
                // console.log("index",index)
                console.log("value",value)
                if (index && available_product_qty[index[0]] !== undefined) {
                  const maxQty = available_product_qty[index[0]];
                  console.log("maxQty",maxQty)
                  return (
                    value <= maxQty.qty ||
                    new yup.ValidationError(
                      `Quantity cannot exceed ${maxQty.qty}`,
                      null,
                      context.path
                    )
                  );
                }
                return true;
              }),
            operation: yup
              .array()
              .min(1, "Please select operation!")
              .required("Please select operation!"),
            job_card: yup
              .array()
              .min(1, "Please select job card!")
              .required("Please select job card!"),
            work_order: yup
              .array()
              .min(1, "Please select work order!")
              .required("Please select work order!"),
          })
        ),
        otherwise: yup.array().of(
          yup.object().shape({
            particulars: yup.string().required("Particulars is required!"),
            uom: yup
              .array()
              .min(1, "Please select unit!")
              .required("Please select unit!"),
            qty: yup
              .string()
              .required("Quantity is required!")
              .matches(/^[0-9]+(\.[0-9]+)?$/, "Enter valid quantity")
              .typeError("Enter valid quantity")
              .test("max-qty", function (value, context) {
                              const index = context.path.match(/\d+/);
                              // console.log("index",index)
                              // console.log("value",value)
                              if (index && available_product_qty[index[0]] !== undefined) {
                                const maxQty = available_product_qty[index[0]];
                                // console.log("maxQty",maxQty)
                                return (
                                  value <= maxQty.qty ||
                                  new yup.ValidationError(
                                    `Quantity cannot exceed ${maxQty.qty}`,
                                    null,
                                    context.path
                                  )
                                );
                              }
                              return true;
                            }),
          })
        ),
      }),
      purchase_inward: yup
        .array()
        .when(["challanType"], (challanType, schema) => {
          if (
            challanType !== undefined &&
            challanType.length > 0 &&
            challanType[0].typename == "Goods Return"
          ) {
            return schema
              .min(1, "Please select purchase inward !")
              .required("Please select purchase inward !");
          } else {
            return schema;
          }
        }),
      adjustment_reason: yup
        .array()
        .when(["challanType"], (challanType, schema) => {
          if (
            challanType !== undefined &&
            challanType.length > 0 &&
            challanType[0].typename == "Adjustment Challan"
          ) {
            return schema
              .min(1, "Please select adjustment reason !")
              .required("Please select adjustment reason !");
          } else {
            return schema;
          }
        }),
      adjustment_type: yup
        .array()
        .when(["challanType"], (challanType, schema) => {
          if (
            challanType !== undefined &&
            challanType.length > 0 &&
            challanType[0].typename == "Adjustment Challan"
          ) {
            return schema
              .min(1, "Please select adjustment type !")
              .required("Please select adjustment type !");
          } else {
            return schema;
          }
        }),
      organization: yup
        .array()
        .min(1, "Please select organization !")
        .required("Please select organization !"),   
    })
    .required();
  const {
    register,
    handleSubmit,
    formState,
    control,
    reset,
    watch,
    setValue,
    getValues,
    setError,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      items: [
        {
          product: [],
          particulars: "",
          qty: "",
          uom: [],
          operation: [],
          job_card: [],
          work_order: [],
        },
      ],
    },
  });

  const localData = JSON.parse(localStorage.getItem("localData"));

  const { fields, append, remove } = useFieldArray({ control, name: "items" });

  let { errors } = formState;

  function getFinancialYearStartDate() {
    const today = new Date();
    const year = today.getMonth() >= 3 ? today.getFullYear() - 1 : today.getFullYear() - 2;
    return new Date(year, 3, 1);
  }
  
  useEffect(() => {
    setConstLabel(getLabels("Inventory", "Goods Challan"));
    //  console.log("location",location);
    if(location.state.org_id){
      const result = localData?.organizations?.filter((item) => item.uuid == location.state.org_id);
      setValue("organization", result);

    }else{
      const result = localData?.organizations?.filter((item) => item.default_org == true);
    console.log("result",result)
    setValue("organization", result);
    }
    if (location.state == null) {
      navigate("/pcms/goodsMovementChallan");
      return;
    }
    if (location.state.outwardchallanid) {
      if (location.state.outwardchallanid.length > 0) {
        const { challanid, ...rest } = location.state.outwardchallanid[0];
        setValue("outwardChallanRef", [{ ...rest, gmchallanid: challanid }]);
        get_available_quatity(location.state.outwardchallanid[0].challanid);
      }
    }
    setValue("challanType", [{ typename: location.state.challantype }]);
    setchallantype([{ typename: location.state.challantype }]);
    setValue("transportType", [
      {
        id: location.state.transporttype,
        name: location.state.transporttypename,
      },
    ]);
    settranporttype([
      {
        id: location.state.transporttype,
        name: location.state.transporttypename,
      },
    ]);
    if (location.state.transporttype == 1) {
      //  console.log("a")
      setValue("internaltransport", [{ name: location.state.vehicalnumber }]);
    } else if (location.state.transporttype == 2) {
      setValue("extTransport", location.state.vehicalnumber);
    } else {
      setValue("airwayTransport", location.state.vehicalnumber);
    }
    setValue("driverName", location.state.drivername);
    setValue("status", location.state.challanstate);
    setValue("lrn", location.state.lrn);
    setValue("remark", location.state.remark);
    setValue("date", new Date(location?.state?.challandate));
    if (location.state.fromDetails && location.state.fromDetails.uuid) {
      setValue("fromLocation", [{ ...location.state.fromDetails }]);
    } else {
      setValue("fromLocation", [{ locationname: location.state.fromlocation }]);
    }
    if (location.state.toDetails && location.state.toDetails.uuid) {
      setValue("toLocation", [{ ...location.state.toDetails }]);
    } else {
      setValue("toLocation", [{ locationname: location.state.tolocation }]);
    }

    if (location.state.tostoreDetails && location.state.tostoreDetails.uuid) {
      setValue("tostore", [{ ...location.state.tostoreDetails }]);
    }

    if (location.state.fromstoreDetails && location.state.fromstoreDetails) {
      setValue("fromstore", [{ ...location.state.fromstoreDetails }]);
    }

    if (
      location.state.challan_line_items &&
      location.state.challan_line_items.length > 0
    ) {
      let arr2 = [];

      let arr = location.state.challan_line_items.map((item, ind) => {
        // let job_card_find=jobcardList.data.filter((ele)=>ele.uuid==item.job_card_id);
        // let work_order_find=workorderList.data.filter((ele)=>ele.uuid==item.work_order_id);

        // console.log("job_card_find",job_card_find)

        let obj = {
          product: [
            {
              productName: item.product_name,
              uuid: item.product_id,
              productId: item.productId,
              partNo: item.partNo,
              product_type_id: item.product_type_id,
              category_id: item.product_category_id,
              productTypename: item.product_type_name,
            },
          ],
          particulars: item.product_name,
          operation:
            item.operation_name && item.operation_id
              ? [
                  {
                    operationName: item.operation_name,
                    uuid: item.operation_id,
                  },
                ]
              : [],
          uom: [{ uom: item.uom }],
          work_order:
            item.work_order_id && item.work_order_no
              ? [
                  {
                    uuid: item.work_order_id,
                    work_order_no: item.work_order_no,
                    work_order_status: item.work_order_status,
                    quantity: item.work_order_quantity,
                  },
                ]
              : [],
          job_card:
            item.job_card_id && item.job_card_no
              ? [{ uuid: item.job_card_id, job_card_no: item.job_card_no }]
              : [],
          qty: item.qty,
        };
        //  let  challan_type=location.state.challantype
        // if(challan_type=="Job Work Inward" ||challan_type=="Job Work Outward" || challan_type=="Dispatch Challan" ){
        obj.hsn = item.hsn ? item.hsn : 0;
        obj.amount = item.amount ? item.amount : 0;
        obj.igst_rate = item.igst_rate ? item.igst_rate : 0;
        obj.igst_amount = item.igst_amount ? item.igst_amount : 0;
        obj.cgst_rate = item.cgst_rate ? item.cgst_rate : 0;
        obj.cgst_amount = item.cgst_amount ? item.cgst_amount : 0;
        obj.sgst_rate = item.sgst_rate ? item.sgst_rate : 0;
        obj.sgst_amount = item.sgst_amount ? item.sgst_amount : 0;
        obj.ugst_rate = item.ugst_rate ? item.ugst_rate : 0;
        obj.ugst_amount = item.ugst_amount ? item.ugst_amount : 0;
        // }

        let obj2 = {
          show: true,
          value: item.work_order_quantity ? item.work_order_quantity : 0,
          index: ind,
        };
        arr2.push(obj2);
        return obj;
      });

      console.log("arr2", arr2);
      setshow_work_quantity(arr2);
      console.log("arr", arr);
      setValue("items", arr);
    }

    if (location.state.challanno && location.state.challanno.length > 0) {
      setValue("challanNo", location.state.challanno);
    }

    if (location.state.challanRef && location.state.challanRef.length > 0) {
      setValue("challanRefNo", location.state.challanRef);
    }

    const fetchData = async () => {
      try {
        const apiCalls = [];
        let product_arr = [];
        for (let i = 0; i < location.state.challan_line_items.length; i++) {
          let item = location.state.challan_line_items[i];
          const payload = {
            work_order_uuid: item.work_order_id,
            location_uuid: location.state.fromDetails.uuid,
            wip: true,
          };
          product_arr.push({
            index: i,
            productName: item.product_name,
            previous_issued: item.qty,
            qty: item.qty,
          });
          apiCalls.push(get_dispatch_pkg_store_qty(payload));
        }

        const responses = await Promise.all(apiCalls);
        console.log("product_arr", product_arr);
        const apiResults = responses.map((res, ind) => {
          let obj = { ...product_arr[ind] };

          obj.previous_issued = res.data.data.storeDetails.issue - obj.qty;
          obj.qty = res.data.data.storeDetails.availqty + Number(obj.qty);

          return obj;
        });
        console.log("apiresults", apiResults);
        setavailable_product_qty(apiResults);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchData2 =async()=>{
      try {
        const apiCalls = [];
        let product_arr = [];
        for (let i = 0; i < location.state.challan_line_items.length; i++) {
          let item = location.state.challan_line_items[i];
          
          let  location_id= location.state.fromDetails.uuid;
          let  store_id=location.state.from_store_id;
          const payload = {
            entriesPerPage: 10,
            pageNo: 1,
            startDate: convertDateYYMMDD(
              new Date(getFinancialYearStartDate()),
              "-"
            ),
            endDate: convertDateYYMMDD(new Date(), "-"),
            productid_id: item.product_id,
            location_id: location_id,
            store_id: store_id,
            showZero: false,
          };

          product_arr.push({
            index: i,
            productName: item.product_name,
            previous_issued: item.qty,
            qty: item.qty,
          });
          
          apiCalls.push(get_stock_by_product(payload));
        }

        const responses = await Promise.all(apiCalls);
        console.log("product_arr", product_arr);
        const apiResults = responses.map((res, ind) => {
          let obj = { ...product_arr[ind] };

          obj.previous_issued = obj.qty;
          obj.qty = res.data.data[0].quantity;

          return obj;
        });
        console.log("apiresults", apiResults);
        setavailable_product_qty(apiResults);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    if (location.state.challantype == "Adjustment Challan") {
      fetchAdjustmentReasonList();
      if (location.state.adjustmentDetails) {
        setValue("adjustment_reason", [location.state.adjustmentDetails]);
      } else {
        setValue("adjustment_reason", []);
      }
      if (location.state.challanstate == "Issue") {
        setadj_type([{ name: "Issue" }]);
        setValue("adjustment_type", [{ name: "Issue" }]);
      } else {
        setadj_type([{ name: "Receipt" }]);
        setValue("adjustment_type", [{ name: "Receipt" }]);
      }
    }
    if (
      location.state.challantype.endsWith("Outward") ||
      location.state.challantype == "Packing"
    ) {
      fetchData();
    }

    if (
      location.state.challantype=="General Outward" ||
      location.state.challantype == "Customer Return Material Inward" ||
      location.state.challantype == "Inter Store Transfer"
    ) {
      fetchData2();
    }

    if(location.state.challantype == "Customer Return Material Inward"){
      fetchDispatchData()
    }

    fetchtransport();
    fetchinternaltransport();
    
    fetchPOInvList();
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = function () {
      window.history.pushState(null, "", window.location.href);
    };
  }, []);

  function fetchtransport() {
    settransportList({ data: [], loading: true });
    get_challan_transport().then(
      (res) => {
        settransportList({ data: res.data.transportlist, loading: false });
      },
      (err) => {
        settransportList({ data: [], loading: false });
      }
    );
  }
  function fetchinternaltransport() {
    setinternaltransportList({ data: [], loading: true });
    get_challan_internal_transport().then(
      (res) => {
        setinternaltransportList({
          data: res.data.InternalTransportlist,
          loading: false,
        });
      },
      (err) => {
        setinternaltransportList({ data: [], loading: false });
      }
    );
  }

  function fetchAdjustmentReasonList() {
    setadjustmentlist({ data: [], loading: true });
    get_adjustment_reason().then(
      (res) => {
        setadjustmentlist({ data: res.data.data, loading: false });
      },
      (err) => {
        setadjustmentlist({ data: [], loading: false });
      }
    );
  }

  function filter_store(uuid, type) {
    // console.log("uuid", uuid);
    let arr = storeList.data.filter((item) => item.location_id == uuid);
    // console.log("type", type);

    // console.log("arr", arr);
    if (type == "to") {
      settoStorelist({ data: arr, loading: false });
    } else {
      setfromStorelist({ data: arr, loading: false });
    }
  }

  function fetch_outward_challan() {
    setchallanList({ loading: true, data: [] });

    const payload = {
      pageNo: 1,
      entriesPerPage: 100,
      outward: true,
    };

    get_challan(payload).then(
      (res) => {
        setchallanList({
          loading: false,
          data: res.data.data,
        });
      },
      (err) => {
        setchallanList({ loading: false, data: [] });
      }
    );
  }

  function fetchDispatchData() {
      setDispatchList({ ...dispatchList, loading: true, data: [] });
      const payload = {
        entriesPerPage: 100,
        pageNo: 1,
      };
      get_dispatch(payload).then(
        (res) => {
          // console.log("res", res);
          setDispatchList({
            loading: false,
            data: res.data.data,
          });
          if(location.state.dispatch_id){
            let arr=res.data.data.filter((item)=>item.uuid==location.state.dispatch_id); 
            setValue("dispatch",arr)
          }
        },
        (err) => {
          setDispatchList({ loading: false, data: [] });
        }
      );
    }

    
  

  const onSubmit = (data) => {
    console.log("data", data);
    // console.log(" convertDateYYMMDD(data.date)",  convertDateYYMMDD(data.date,"-"));
    setbuttonclick(true);

    let products_array = data.items.map((item) => {
      let obj = {
        qty: item.qty,
        uom: item.uom[0].uom,
        product_id: item.product[0].uuid,
        productId: item.product[0].productId,
        product_name:
          item.product[0].productName !== "Other"
            ? item.product[0].productName
            : item.particulars,
        partNo: item.product[0].partNo ? item.product[0].partNo : null,
        product_type_id:item?.product?.[0]?.product_type_id?item.product[0].product_type_id:null,
        product_type_name:item?.product?.[0]?.productTypename?item?.product?.[0]?.productTypename:null,
        product_category_id:item?.product?.[0]?.category_id?item?.product?.[0]?.category_id:null,
        operation_id:
          item.operation && item.operation.length > 0
            ? item.operation[0].uuid
              ? item.operation[0].uuid
              : null
            : null,
        operation_name:
          item.operation && item.operation.length > 0
            ? item.operation[0].operationName
              ? item.operation[0].operationName
              : null
            : null,
        job_card_id:
          item.job_card && item.job_card.length > 0
            ? item.job_card[0].uuid
              ? item.job_card[0].uuid
              : null
            : null,
        job_card_no:
          item.job_card && item.job_card.length > 0
            ? item.job_card[0].uuid
              ? item.job_card[0].job_card_no
              : null
            : null,
        work_order_id:
          item.work_order && item.work_order.length > 0
            ? item.work_order[0].uuid
              ? item.work_order[0].uuid
              : null
            : null,
        work_order_no:
          item.work_order && item.work_order.length > 0
            ? item.work_order[0].work_order_no
              ? item.work_order[0].work_order_no
              : null
            : null,
        work_order_status:
          item.work_order && item.work_order.length > 0
            ? item.work_order[0].work_order_status
              ? item.work_order[0].work_order_status
              : null
            : null,
        work_order_quantity:
          item.work_order && item.work_order.length > 0
            ? item.work_order[0].quantity
              ? `${item.work_order[0].quantity}`
              : null
            : null,
      };
      if (obj.operation_id == null) {
        delete obj.operation_id;
        delete obj.operation_name;
      }
      if (obj.job_card_id == null) {
        delete obj.job_card_id;
        delete obj.job_card_no;
      }
      if (obj.work_order_id == null) {
        delete obj.work_order_id;
        delete obj.work_order_no;
        delete obj.work_order_status;
        delete obj.work_order_quantity;
      }
      if (obj.partNo == null) {
        delete obj.partNo;
      }

      if (obj.product_category_id == null) {
        delete obj.product_category_id;
      }
      if (obj.product_type_id == null) {
        delete obj.product_type_id;
      }
      if (obj.product_type_name == null) {
        delete obj.product_type_name;
      }

      if (
        data.challanType[0].typename == "Job Work Inward" ||
        data.challanType[0].typename == "Job Work Outward" ||
        data.challanType[0].typename == "Dispatch Challan" ||
        data.challanType[0].typename == "Customer Return Material Inward"
      ) {
        obj.hsn = Number(item.hsn);
        obj.amount = item.amount;
        if (item.igst_rate !== 0) {
          obj.igst_rate = item.igst_rate;
          obj.igst_amount = Number(item.amount * item.igst_rate * item.qty);
          obj.cgst_rate = 0;
          obj.cgst_amount = 0;
          obj.sgst_rate = 0;
          obj.sgst_amount = 0;
          obj.ugst_rate = 0;
          obj.ugst_amount = 0;
        } else if (item.ugst_rate !== 0) {
          obj.igst_rate = 0;
          obj.igst_amount = 0;
          obj.cgst_rate = item.cgst_rate;
          obj.cgst_amount = item.cgst_rate * item.amount * item.qty;
          obj.sgst_rate = 0;
          obj.sgst_amount = 0;
          obj.ugst_rate = item.ugst_rate;
          obj.ugst_amount = item.ugst_rate * item.amount * item.qty;
        } else {
          obj.igst_rate = 0;
          obj.igst_amount = 0;
          obj.cgst_rate = item.cgst_rate;
          obj.cgst_amount = item.cgst_rate * item.amount * item.qty;
          obj.sgst_rate = item.sgst_rate;
          obj.sgst_amount = item.sgst_rate * item.amount * item.qty;
          obj.ugst_rate = 0;
          obj.ugst_amount = 0;
        }
      }
      return obj;
    });

    let payload_obj = {
      uuid: location.state.uuid,
      challantype: data.challanType[0].typename,
      vehicalnumber:
        data.transportType[0].id == 1
          ? data.internaltransport[0].name
          : data.transportType[0].id == 2
          ? data.extTransport
          : data.airwayTransport,
      transporttype: `${data.transportType[0].id}`,
      challanstate: data.status,
      drivername: data.driverName,
      remark: data.remark,
      challandate: convertDateYYMMDD(data.date, "-"),
      org_id: data.organization[0].uuid,
      from_store_id: data.fromstore[0].uuid,
      from_store_name: data.fromstore[0].name,
      from_location_id: data.fromLocation[0].uuid,
      to_store_id: data.tostore[0].uuid,
      to_store_name: data.tostore[0].name,
      to_location_id: data.toLocation[0].uuid,
      Productdetails: products_array,
      challanno: data.challanNo,
      challanRef: data.challanRefNo,
    };

    if (data.challanType[0].typename == "Goods Return") {
      payload_obj.pi_id = data.purchase_inward[0].uuid;
    }

    if (data.challanType[0].typename.endsWith("Inward")) {
     if(data.outwardChallanRef){
       payload_obj.outwardchallanid = [
        {
          challanid: qty_data.outwardchallan_id,
        },
      ];}else{
        payload_obj.outwardchallanid=[]
      }
      payload_obj.party_id = data.fromLocation[0].party_id
        ? data.fromLocation[0].party_id
        : null;
    } else {
      payload_obj.outwardchallanid = [];
      payload_obj.party_id = data.toLocation[0].party_id
        ? data.toLocation[0].party_id
        : null;
    }

    if (data.challanType[0].typename == "Dispatch Challan") {
      payload_obj.dispatch_id = location.state.dispatch_id;
    }
    if (data.lrn) {
      payload_obj.lrn = data.lrn;
    }

    if (data.challanType[0].typename == "Adjustment Challan") {
      payload_obj.adj_reason_id = data.adjustment_reason[0].uuid;
      payload_obj.remark = data.adjustment_reason[0].name;
    }

    if(data.challanType[0].typename =="Customer Return Material Inward"){
      payload_obj.dispatch_id = data?.dispatch?.[0]?.uuid
      ? data.dispatch[0].uuid
      : null;
    }

    console.log("payoad", payload_obj);

    update_challan(payload_obj).then(
      (res) => {
        if (res.status === 200 || res.status === 201) {
          toast.success("Challan updated successfully !", {
            autoClose: 2000,
          });
          setbuttonclick(false);

          setTimeout(() => {
            navigate("/pcms/goodsMovementChallan");
          }, 2200);
        }
      },
      (err) => {
        // console.log("err",err)
        setbuttonclick(false);

        if (err.response.status === 400) {
          toast.error(err.response.data.message, {
            autoClose: 2000,
          });
          // reset();
        } else {
          if (err.response.status === 500) {
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
          } else {
            toast.error("Something went wrong ", {
              autoClose: 2000,
            });
          }
        }
      }
    );
  };

  function set_data_inward(val) {
    // console.log("val", val);

    setValue("fromLocation", [{ ...val.toDetails }], { shouldValidate: true });
    setValue("fromstore", [{ ...val.tostoreDetails }], {
      shouldValidate: true,
    });
    setValue("toLocation", [{ ...val.fromDetails }], { shouldValidate: true });
    setValue("tostore", [{ ...val.fromstoreDetails }], {
      shouldValidate: true,
    });

    // console.log("valdfgs",val.challan_line_items.length);

    let arr = val.challan_line_items.map((item) => {
      let job_card_find = jobcardList.data.filter(
        (ele) => ele.uuid == item.job_card_id
      );
      let work_order_find = workorderList.data.filter(
        (ele) => ele.uuid == item.work_order_id
      );

      // console.log("job_card_find",job_card_find)

      let obj = {
        product: [
          {
            productName: item.product_name,
            uuid: item.product_id,
            productId: item.productId,
          },
        ],
        particulars: item.product_name,
        operation:
          item.operation_name && item.operation_id
            ? [{ operationName: item.operation_name, uuid: item.operation_id }]
            : [],
        uom: [{ uom: item.uom }],
        work_order: work_order_find,
        job_card: job_card_find,
      };
      return obj;
    });

    // console.log("arr",arr)
    setValue("items", arr);

    get_available_quatity(val.gmchallanid);
  }

  function get_available_quatity(outchallanid) {
    let payload = {
      outwardchallan_id: outchallanid,
    };
    get_last_challan(payload).then(
      (res) => {
        // console.log("res", res.data);
        let arr = res.data.product_arr_value.map((item) => {
          let find_product = location.state.challan_line_items.filter(
            (ele) => ele.product_name == item.productName
          );
          //  console.log("find",find_product);
          if (find_product.length > 0) {
            item.availQty = Number(find_product[0].qty) + Number(item.availQty);
          } else {
            item.availQty = Number(item.availQty);
          }
          return item;
        });
        // console.log('arr',arr);
        res.data.product_arr_value = arr;
        setqty_data(res.data);
      },
      (err) => {
        setqty_data(null);
      }
    );
  }

  function fetchPOInvList() {
    setpiList({ data: [], loading: true });
    get_purchaseInward_node().then(
      (res) => {
        let arr = res.data.data.map((item) => {
          let obj = {
            ...item,
            poid: item?.po_details?.poid,
          };
          return obj;
        });
        if (location?.state?.pi_id) {
          let po = arr.filter((item) => item.uuid == location.state.pi_id);
          setValue("purchase_inward", po);
        }
        setpiList({ data: arr, loading: false });
      },
      (err) => {
        setpiList({ data: [], loading: false });
      }
    );
  }

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="manufac_goods_form mb-4">
        <div className="card">
          <div className="card-header bg-grey d-flex align-items-center">
            <button
              className="btn btn-yellow"
              onClick={() => {
                navigate("/pcms/goodsMovementChallan");
                // if (
                //   window.confirm(
                //     "Are you sure to go back, your present data will be lost !"
                //   )
                // ) {
                //   window.onpopstate = undefined;
                //   window.history.back();

                //   setTimeout(() => {
                //     navigate("/pcms/goodsMovementChallan");
                //   }, [1000]);
                // }
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} size="sm" />
            </button>

            <p className="mb-0 ms-3" style={{ fontSize: "24px" }}>
              {constLabel?.lbl_edit_goods_challan
                ? constLabel.lbl_edit_goods_challan
                : "Edit Good Challan"}
            </p>
          </div>
          <div className="card-body">
            <div className="createNc container-fluid">
              <p className="roboto-bold mb-0" style={{ fontSize: "20px" }}>
                {constLabel?.lbl_challan_details
                  ? constLabel.lbl_challan_details
                  : "Challan Details"}
              </p>
              <hr className="my-2" />

              <div className="row mt-2 col-12">
                <div className="col-md-3 mt-3">
                  <div className="form-group mt-md-0 mt-3 ">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_challan_type
                        ? constLabel.lbl_challan_type
                        : "Challan Type"}
                      <span>
                        <i style={{ color: "red" }}>*</i>
                      </span>
                    </label>

                    <Controller
                      name={`challanType`}
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          id="basic-typeahead-single"
                          labelKey="typename"
                          size="sm"
                          multiple={false}
                          options={challantypeList.data}
                          isLoading={challantypeList.loading}
                          placeholder="Select challan type"
                          disabled={true}
                          onChange={(selected) => {
                            onChange(selected);
                            // console.log("selected", selected);
                            if (selected && selected.length > 0) {
                              setchallantype(selected);
                              if (selected[0].typename.endsWith("Inward")) {
                                setValue("status", "Received");

                                fetch_outward_challan();
                              } else {
                                setValue("status", "Issued");
                              }
                            }
                          }}
                          onBlur={onBlur}
                          selected={value}
                          isInvalid={errors.challanType ? true : false}
                        />
                      )}
                    />
                    {errors.challanType && (
                      <span className="text-danger err-msg">
                        {errors.challanType.message}
                      </span>
                    )}
                  </div>
                </div>
                {challantype &&
                  challantype.length > 0 &&
                  challantype[0].typename == "Adjustment Challan" && (
                    <div className="col-md-3 mt-3">
                      <div className="form-group mt-md-0 mt-3 ">
                        <label className="lbl-style roboto-bold">
                          Adjustment Type
                          <span>
                            <i style={{ color: "red" }}>*</i>
                          </span>
                        </label>

                        <Controller
                          name={`adjustment_type`}
                          control={control}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <Typeahead
                              id="basic-typeahead-single"
                              labelKey="name"
                              size="sm"
                              multiple={false}
                              options={adjustment_types}
                              // loading={adjustmentlist.loading}
                              disabled={true}
                              placeholder="Select adjustment type "
                              onChange={(selected) => {
                                onChange(selected);
                                if (selected && selected.length > 0) {
                                  setadj_type(selected);
                                } else {
                                  setadj_type(undefined);
                                  setValue("fromLocation", []);
                                  setValue("fromstore", []);
                                  setValue("toLocation", []);
                                  setValue("tostore", []);
                                }
                              }}
                              onBlur={onBlur}
                              selected={value}
                              isInvalid={errors.adjustment_type ? true : false}
                            />
                          )}
                        />
                        {errors.adjustment_type && (
                          <span className="text-danger err-msg">
                            {errors.adjustment_type.message}
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                {challantype &&
                  challantype.length > 0 &&
                  challantype[0].typename.endsWith("Inward") &&
                  challantype[0].typename !==
                  "Customer Return Material Inward" &&
                  (
                    <div className="col-md-3 mt-3">
                      <div className="form-group mt-md-0 mt-3 ">
                        <label className="lbl-style roboto-bold">
                          Outward challan ref.
                          {/* <span>
                            <i style={{ color: "red" }}>*</i>
                          </span> */}
                        </label>

                        <Controller
                          name={`outwardChallanRef`}
                          control={control}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <Typeahead
                              id="basic-typeahead-single"
                              labelKey="gmchallanid"
                              size="sm"
                              multiple={false}
                              options={challanList.data}
                              loading={challanList.loading}
                              disabled={true}
                              placeholder="Select outward ref challan "
                              onChange={(selected) => {
                                onChange(selected);
                                if (selected && selected.length > 0) {
                                  set_data_inward(selected[0]);
                                }
                              }}
                              onBlur={onBlur}
                              selected={value}
                              isInvalid={
                                errors.outwardChallanRef ? true : false
                              }
                            />
                          )}
                        />
                        {/* {errors.outwardChallanRef && (
                          <span className="text-danger err-msg">
                            {errors.outwardChallanRef.message}
                          </span>
                        )} */}
                      </div>
                    </div>
                  )}

                <div className="col-md-3 mt-3">
                  <div className="form-group mt-md-0 mt-3">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_date ? constLabel.lbl_date : "Date"}
                      <span>
                        <i style={{ color: "red" }}>*</i>
                      </span>
                    </label>

                    <Controller
                      name="date"
                      control={control}
                      render={({ field }) => (
                        <DatePicker
                          className={
                            errors.date
                              ? "form-control form-control-sm is-invalid"
                              : "form-control form-control-sm"
                          }
                          {...field}
                          showYearDropdown
                          scrollableMonthYearDropdown
                          dateFormat="dd/MM/yyyy"
                          selected={field.value}
                          maxDate={new Date()}
                          placeholderText="Select a date"
                          autoComplete="off"
                        />
                      )}
                    />
                    {errors.date && (
                      <span className="text-danger err-msg">
                        {errors.date.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-md-3 mt-3">
                  <div className="form-group mt-md-0 mt-3">
                    <label className="lbl-style roboto-bold">
                   {constLabel?.lbl_organization ? constLabel.lbl_organization : "Organization"}
                      <span>
                        <i style={{ color: "red" }}>*</i>
                      </span>
                    </label>

                    <Controller
                      name={`organization`}
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          id="basic-typeahead-single"
                          labelKey="comName"
                          size="sm"
                          multiple={false}
                          options={localData?.organizations}
                          placeholder="Select organization"
                          
                          onChange={(selected) => {
                            onChange(selected);
                          }}
                          onBlur={onBlur}
                          selected={value}
                          isInvalid={errors.organization ? true : false}
                        />
                      )}
                    />
                    {errors.organization && (
                      <span className="text-danger err-msg">
                        {errors.organization.message}
                      </span>
                    )}
                  </div>
                </div>

                {(adj_type == undefined ||
                  (adj_type &&
                    adj_type.length > 0 &&
                    adj_type[0].name == "Issue")) && (
                  <div className="col-md-3 mt-3">
                    <div className="form-group mt-md-0 mt-3">
                      <label className="lbl-style roboto-bold">
                        {challantype &&
                        challantype.length > 0 &&
                        challantype[0].typename == "Adjustment Challan"
                          ? "Location"
                          : constLabel?.lbl_from_location
                          ? constLabel.lbl_from_location
                          : "From Location"}
                        <span>
                          <i style={{ color: "red" }}>*</i>
                        </span>
                      </label>

                      <Controller
                        name={`fromLocation`}
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Typeahead
                            id="basic-typeahead-single"
                            labelKey="locationname"
                            size="sm"
                            multiple={false}
                            options={locList.data}
                            isLoading={locList.loading}
                            placeholder="Select from location"
                            disabled={true}
                            onChange={(selected) => {
                              onChange(selected);
                              // console.log("selected", selected);
                              if (selected && selected.length > 0) {
                                filter_store(selected[0].uuid, "from");
                              }
                            }}
                            onBlur={onBlur}
                            selected={value}
                            isInvalid={errors.fromLocation ? true : false}
                          />
                        )}
                      />
                      {errors.fromLocation && (
                        <span className="text-danger err-msg">
                          {errors.fromLocation.message}
                        </span>
                      )}
                    </div>
                  </div>
                )}
                {(adj_type == undefined ||
                  (adj_type &&
                    adj_type.length > 0 &&
                    adj_type[0].name == "Issue")) && (
                  <div className="col-md-3 mt-3">
                    <div className="form-group mt-md-0 mt-3">
                      <label className="lbl-style roboto-bold">
                        {challantype &&
                        challantype.length > 0 &&
                        challantype[0].typename == "Adjustment Challan"
                          ? "Store"
                          : constLabel?.lbl_from_store
                          ? constLabel.lbl_from_store
                          : "	From Store"}
                        <span>
                          <i style={{ color: "red" }}>*</i>
                        </span>
                      </label>

                      <Controller
                        name={`fromstore`}
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Typeahead
                            id="basic-typeahead-single"
                            labelKey="name"
                            size="sm"
                            multiple={false}
                            options={fromStorelist.data}
                            isLoading={fromStorelist.loading}
                            disabled={true}
                            placeholder="Select from store"
                            onChange={onChange}
                            onBlur={onBlur}
                            selected={value}
                            isInvalid={errors.fromstore ? true : false}
                          />
                        )}
                      />
                      {errors.fromstore && (
                        <span className="text-danger err-msg">
                          {errors.fromstore.message}
                        </span>
                      )}
                    </div>
                  </div>
                )}

                {(adj_type == undefined ||
                  (adj_type &&
                    adj_type.length > 0 &&
                    adj_type[0].name == "Receipt")) && (
                  <div className="col-md-3 mt-3">
                    <div className="form-group mt-md-0 mt-3">
                      <label className="lbl-style roboto-bold">
                        {challantype &&
                        challantype.length > 0 &&
                        challantype[0].typename == "Adjustment Challan"
                          ? "Location"
                          : constLabel?.lbl_to_location
                          ? constLabel.lbl_to_location
                          : "To Location"}
                        <span>
                          <i style={{ color: "red" }}>*</i>
                        </span>
                      </label>

                      <Controller
                        name={`toLocation`}
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Typeahead
                            id="basic-typeahead-single"
                            labelKey="locationname"
                            size="sm"
                            multiple={false}
                            options={locList.data}
                            isLoading={locList.loading}
                            placeholder="Select to location"
                            disabled={true}
                            onChange={(selected) => {
                              onChange(selected);
                              if (selected && selected.length > 0) {
                                filter_store(selected[0].uuid, "to");
                              }
                            }}
                            onBlur={onBlur}
                            selected={value}
                            isInvalid={errors.toLocation ? true : false}
                          />
                        )}
                      />
                      {errors.toLocation && (
                        <span className="text-danger err-msg">
                          {errors.toLocation.message}
                        </span>
                      )}
                    </div>
                  </div>
                )}

                {(adj_type == undefined ||
                  (adj_type &&
                    adj_type.length > 0 &&
                    adj_type[0].name == "Receipt")) && (
                  <div className="col-md-3 mt-3">
                    <div className="form-group mt-md-0 mt-3">
                      <label className="lbl-style roboto-bold">
                        {challantype &&
                        challantype.length > 0 &&
                        challantype[0].typename == "Adjustment Challan"
                          ? "Store"
                          : constLabel?.lbl_to_store
                          ? constLabel.lbl_to_store
                          : "To Store"}
                        <span>
                          <i style={{ color: "red" }}>*</i>
                        </span>
                      </label>

                      <Controller
                        name={`tostore`}
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Typeahead
                            id="basic-typeahead-single"
                            labelKey="name"
                            size="sm"
                            multiple={false}
                            options={toStorelist.data}
                            disabled={true}
                            isLoading={toStorelist.loading}
                            placeholder="Select to store"
                            onChange={onChange}
                            onBlur={onBlur}
                            selected={value}
                            isInvalid={errors.tostore ? true : false}
                          />
                        )}
                      />
                      {errors.tostore && (
                        <span className="text-danger err-msg">
                          {errors.tostore.message}
                        </span>
                      )}
                    </div>
                  </div>
                )}

                <div className="col-md-3 mt-3">
                  <div className="form-group mt-md-0 mt-3">
                    <label className="lbl-style roboto-bold">
                      Challan Ref No.
                    </label>

                    <input
                      name="challanRefNo"
                      className={
                        errors.challanRefNo
                          ? "form-control form-control-sm is-invalid"
                          : "form-control form-control-sm"
                      }
                      type="text"
                      placeholder="Enter challan ref no"
                      {...register("challanRefNo")}
                    />
                    {errors.challanRefNo && (
                      <span className="text-danger err-msg">
                        {errors.challanRefNo.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-md-3 mt-3">
                  <div className="form-group mt-md-0 mt-3">
                    <label className="lbl-style roboto-bold">
                      Challan Number
                    </label>

                    <input
                      name="challanNo"
                      className={
                        errors.challanNo
                          ? "form-control form-control-sm is-invalid"
                          : "form-control form-control-sm"
                      }
                      type="text"
                      placeholder="Enter challan number"
                      {...register("challanNo")}
                    />
                    {errors.challanNo && (
                      <span className="text-danger err-msg">
                        {errors.challanNo.message}
                      </span>
                    )}
                  </div>
                </div>

                {challantype &&
                  challantype.length > 0 &&
                  challantype[0].typename == "Goods Return" && (
                    <div className="col-md-3 mt-3">
                      <div className="form-group mt-md-0 mt-3 ">
                        <label className="lbl-style roboto-bold">
                          {constLabel?.lbl_purchase_inward
                            ? constLabel.lbl_purchase_inward
                            : "Purchase Inward"}
                          <span>
                            <i style={{ color: "red" }}>*</i>
                          </span>
                        </label>

                        <Controller
                          name={`purchase_inward`}
                          control={control}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <Typeahead
                              id="basic-typeahead-single"
                              labelKey="poid"
                              size="sm"
                              multiple={false}
                              options={piList.data}
                              isLoading={piList.loading}
                              placeholder="Select purchase inward"
                              onChange={onChange}
                              onBlur={onBlur}
                              selected={value}
                              isInvalid={errors.purchase_inward ? true : false}
                            />
                          )}
                        />
                        {errors.purchase_inward && (
                          <span className="text-danger err-msg">
                            {errors.purchase_inward.message}
                          </span>
                        )}
                      </div>
                    </div>
                  )}

                {challantype &&
                  challantype.length > 0 &&
                  challantype[0].typename == "Adjustment Challan" && (
                    <div className="col-md-3 mt-3">
                      <div className="form-group mt-md-0 mt-3 ">
                        <label className="lbl-style roboto-bold">
                          Adjustment Reason
                          <span>
                            <i style={{ color: "red" }}>*</i>
                          </span>
                        </label>

                        <Controller
                          name={`adjustment_reason`}
                          control={control}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <Typeahead
                              id="basic-typeahead-single"
                              labelKey="name"
                              size="sm"
                              multiple={false}
                              options={adjustmentlist.data}
                              loading={adjustmentlist.loading}
                              placeholder="Select adjustment reason "
                              onChange={(selected) => {
                                onChange(selected);
                              }}
                              onBlur={onBlur}
                              selected={value}
                              isInvalid={
                                errors.adjustment_reason ? true : false
                              }
                            />
                          )}
                        />
                        {errors.adjustment_reason && (
                          <span className="text-danger err-msg">
                            {errors.adjustment_reason.message}
                          </span>
                        )}
                      </div>
                    </div>
                  )}

                  {challantype &&
                                    challantype.length > 0 &&
                                    challantype[0].typename ==
                                      "Customer Return Material Inward" && (
                                      <div className="col-md-3 mt-3">
                                        <div className="form-group typeahead-container mt-md-0 mt-3 ">
                                          <label className="lbl-style roboto-bold">
                                            {constLabel?.lbl_dispatch
                                              ? constLabel.lbl_dispatch
                                              : "Dispatch"}
                                          </label>
                  
                                          <Controller
                                            name={`dispatch`}
                                            control={control}
                                            render={({
                                              field: { onChange, onBlur, value, ref },
                                            }) => (
                                              <Typeahead
                                                id="basic-typeahead-single"
                                                labelKey="dispatchId"
                                                multiple={false}
                                                options={dispatchList.data}
                                                isLoading={dispatchList.loading}
                                                placeholder="Select dispatch"
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                selected={value}
                                              />
                                            )}
                                          />
                                        </div>
                                      </div>
                                    )}
              </div>

              {challantype &&
                challantype.length > 0 &&
                qty_data &&
                challantype[0].typename.endsWith("Inward") && (
                  <>
                    <hr className="mt-4" />
                    <p
                      className="roboto-bold mb-0"
                      style={{ fontSize: "20px" }}
                    >
                      {constLabel?.lbl_outward_challan_ref_details
                        ? constLabel.lbl_outward_challan_ref_details
                        : "	Outward Challan Ref Details"}
                    </p>
                    <hr className="mt-4" />
                    <div className="my-3" style={{ maxHeight: "80vh" }}>
                      <table className="table table-responsive table-bordered">
                        <thead className="thead-light text-center">
                          <tr>
                            <th scope="col">
                              {constLabel?.lbl_outward_challan_id
                                ? constLabel.lbl_outward_challan_id
                                : "Outward Challan Id"}
                            </th>
                            <th scope="col">
                              {constLabel?.lbl_productname
                                ? constLabel.lbl_productname
                                : "Product Name"}
                            </th>
                            <th scope="col">
                              {constLabel?.lbl_available_qty
                                ? constLabel.lbl_available_qty
                                : "Available Qty"}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {qty_data.product_arr_value.map((item) => {
                            return (
                              <tr className="f-18">
                                <td
                                  style={{
                                    textAlign: "center",
                                    verticalAlign: "top",
                                    width: "10%",
                                  }}
                                >
                                  {qty_data && qty_data.outwardchallan_id}
                                </td>
                                <td
                                  style={{
                                    textAlign: "center",
                                    verticalAlign: "top",
                                    width: "10%",
                                  }}
                                >
                                  {item.productName}
                                </td>
                                <td
                                  style={{
                                    textAlign: "center",
                                    verticalAlign: "top",
                                    width: "10%",
                                  }}
                                >
                                  {item.availQty}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </>
                )}

              {challantype &&
                challantype.length > 0 &&
                available_product_qty.length > 0 &&
                (challantype[0].typename.endsWith("Outward") ||
                  challantype[0].typename == "Packing" ||
                  challantype[0].typename ==
                    "Customer Return Material Inward" ||
                  challantype[0].typename == "Inter Store Transfer") && (
                  <>
                    <hr className="mt-4" />
                    <p
                      className="roboto-bold mb-0"
                      style={{ fontSize: "20px" }}
                    >
                      {constLabel?.lbl_available_product_qty
                        ? constLabel.lbl_available_product_qty
                        : "Available Product Quantity"}
                    </p>
                    <hr className="mt-4" />
                    <div className="my-3" style={{ maxHeight: "80vh" }}>
                      <table className="table table-responsive table-bordered">
                        <thead className="thead-light text-center">
                          <tr>
                            <th scope="col">
                              {constLabel?.lbl_productname
                                ? constLabel.lbl_productname
                                : "Product Name"}
                            </th>
                            <th scope="col">
                              {constLabel?.lbl_previous_issued
                                ? constLabel.lbl_previous_issued
                                : "Previous Issued"}
                            </th>
                            <th scope="col">
                              {constLabel?.lbl_available_qty
                                ? constLabel.lbl_available_qty
                                : "Available Qty"}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {available_product_qty.map((item) => {
                            return (
                              <tr className="f-18">
                                <td
                                  style={{
                                    textAlign: "center",
                                    verticalAlign: "top",
                                    width: "10%",
                                  }}
                                >
                                  {item.productName}
                                </td>
                                <td
                                  style={{
                                    textAlign: "center",
                                    verticalAlign: "top",
                                    width: "10%",
                                  }}
                                >
                                  {item.previous_issued}
                                </td>
                                <td
                                  style={{
                                    textAlign: "center",
                                    verticalAlign: "top",
                                    width: "10%",
                                  }}
                                >
                                  {item.qty}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </>
                )}

              <hr className="mt-4" />
              <p className="roboto-bold mb-0" style={{ fontSize: "20px" }}>
                {constLabel?.lbl_product_details
                  ? constLabel.lbl_product_details
                  : "	Product Details"}
              </p>
              <hr className="my-4" />
              <div className="my-3" style={{ maxHeight: "80vh" }}>
                <table className="table table-responsive table-bordered">
                  <thead className="thead-light text-center">
                    <tr>
                      <th scope="col">
                        {constLabel?.lbl_work_order
                          ? constLabel.lbl_work_order
                          : "Work Order"}
                      </th>
                      {show_work_quantity[0].show && (
                        <th scope="col">
                          {constLabel?.lbl_work_order_quantity
                            ? constLabel.lbl_work_order_quantity
                            : "Work Order Quanity"}
                        </th>
                      )}
                      {challantype &&
                        challantype.length > 0 &&
                        challantype[0].typename !== "Dispatch Challan" && (
                          <th scope="col">
                            {constLabel?.lbl_job_card
                              ? constLabel.lbl_job_card
                              : "Job card"}
                          </th>
                        )}
                      {challantype &&
                        challantype.length > 0 &&
                        challantype[0].typename !== "Dispatch Challan" && (
                          <th scope="col">
                            {constLabel?.lbl_operation
                              ? constLabel.lbl_operation
                              : "Operation"}
                          </th>
                        )}
                      <th scope="col">
                        {constLabel?.lbl_productname
                          ? constLabel.lbl_productname
                          : "Product"}
                      </th>
                      <th scope="col">
                        {constLabel?.lbl_particulars
                          ? constLabel.lbl_particulars
                          : "Particulars"}
                      </th>

                      <th scope="col">
                        {constLabel?.lbl_quantity
                          ? constLabel.lbl_quantity
                          : "Quantity"}
                      </th>
                      <th scope="col">
                        {constLabel?.lbl_uom ? constLabel.lbl_uom : "Unit"}
                      </th>

                      <th scope="col">{"Action"}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {fields.map(({ id }, index) => (
                      <tr key={id} className="f-18">
                        <td
                          style={{
                            textAlign: "center",
                            verticalAlign: "top",
                            width: "10%",
                          }}
                        >
                          <Controller
                            name={`items[${index}].work_order`}
                            control={control}
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <Typeahead
                                id="basic-typeahead-single"
                                labelKey="work_order_no"
                                size="sm"
                                multiple={false}
                                options={workorderList.data}
                                isLoading={workorderList.loading}
                                placeholder="Select work order"
                                disabled={true}
                                onChange={onChange}
                                onBlur={onBlur}
                                selected={value}
                                isInvalid={
                                  errors.items?.[index]?.work_order
                                    ? true
                                    : false
                                }
                              />
                            )}
                          />
                          {errors.items?.[index]?.work_order && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.work_order.message}
                            </span>
                          )}
                        </td>
                        {show_work_quantity[index].show && (
                          <td
                            style={{
                              textAlign: "center",
                              verticalAlign: "top",
                              width: "10%",
                            }}
                          >
                            <input
                              className={"form-control form-control-sm"}
                              type="text"
                              value={`${show_work_quantity[index].value}`}
                              disabled={true}
                              // placeholder="Enter quantity"
                            />
                          </td>
                        )}
                        {challantype &&
                          challantype.length > 0 &&
                          challantype[0].typename !== "Dispatch Challan" && (
                            <td
                              style={{
                                textAlign: "center",
                                verticalAlign: "top",
                                width: "10%",
                              }}
                            >
                              <Controller
                                name={`items[${index}].job_card`}
                                control={control}
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                }) => (
                                  <Typeahead
                                    id="basic-typeahead-single"
                                    labelKey="job_card_no"
                                    size="sm"
                                    multiple={false}
                                    options={jobcardList.data}
                                    isLoading={jobcardList.loading}
                                    disabled={true}
                                    placeholder="Select job card"
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    selected={value}
                                    isInvalid={
                                      errors.items?.[index]?.job_card
                                        ? true
                                        : false
                                    }
                                  />
                                )}
                              />
                              {errors.items?.[index]?.job_card && (
                                <span className="text-danger err-msg">
                                  {errors.items?.[index]?.job_card.message}
                                </span>
                              )}
                            </td>
                          )}
                        {challantype &&
                          challantype.length > 0 &&
                          challantype[0].typename !== "Dispatch Challan" && (
                            <td
                              style={{
                                textAlign: "center",
                                verticalAlign: "top",
                                width: "10%",
                              }}
                            >
                              <Controller
                                name={`items[${index}].operation`}
                                control={control}
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                }) => (
                                  <Typeahead
                                    id="basic-typeahead-single"
                                    labelKey="operationName"
                                    size="sm"
                                    multiple={false}
                                    options={opList.data}
                                    isLoading={opList.loading}
                                    placeholder="Select operation"
                                    disabled={true}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    selected={value}
                                    isInvalid={
                                      errors.items?.[index]?.operation
                                        ? true
                                        : false
                                    }
                                  />
                                )}
                              />
                              {errors.items?.[index]?.operation && (
                                <span className="text-danger err-msg">
                                  {errors.items?.[index]?.operation.message}
                                </span>
                              )}
                            </td>
                          )}
                        <td
                          style={{
                            // textAlign: "center",
                            justifyContent: "start",
                            verticalAlign: "top",
                            width: "20%",
                          }}
                        >
                          <Controller
                            name={`items[${index}].product`}
                            control={control}
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <Typeahead
                                id="basic-typeahead-single"
                                labelKey="productName"
                                size="sm"
                                multiple={false}
                                options={products.data}
                                isLoading={products.loading}
                                placeholder="Select product"
                                onChange={(selected) => {
                                  onChange(selected);
                                  // console.log("selected",selected)
                                  if (
                                    selected.length > 0 &&
                                    selected[0].productName !== "Other"
                                  ) {
                                    setValue(
                                      `items[${index}].particulars`,
                                      selected[0].productName,
                                      { shouldValidate: true }
                                    );
                                  } else {
                                    setValue(`items[${index}].particulars`, "");
                                  }
                                }}
                                disabled={true}
                                onBlur={onBlur}
                                selected={value}
                                isInvalid={errors.product ? true : false}
                              />
                            )}
                          />
                          {errors.items?.[index]?.product && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.product.message}
                            </span>
                          )}
                        </td>
                        <td
                          style={{
                            // textAlign: "center",
                            verticalAlign: "top",
                            width: "10%",
                          }}
                        >
                          <input
                            className={
                              errors.items?.[index]?.particulars
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            type="text"
                            name={`items[${index}].particulars`}
                            disabled={true}
                            placeholder="Enter particular"
                            {...register(`items[${index}].particulars`)}
                          />
                          {errors.items?.[index]?.particulars && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.particulars.message}
                            </span>
                          )}
                        </td>

                        <td
                          style={{
                            textAlign: "center",
                            verticalAlign: "top",
                            width: "10%",
                          }}
                        >
                          <input
                            className={
                              errors.items?.[index]?.qty
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            type="text"
                            name={`items[${index}].qty`}
                            placeholder="Enter quantity"
                            disabled={
                              challantype &&
                              challantype.length > 0 &&
                              challantype[0].typename == "Dispatch Challan"
                                ? true
                                : false
                            }
                            {...register(`items[${index}].qty`)}
                          />

                          {errors.items?.[index]?.qty && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.qty.message}
                            </span>
                          )}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            verticalAlign: "top",
                            width: "10%",
                          }}
                        >
                          <Controller
                            name={`items[${index}].uom`}
                            control={control}
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <Typeahead
                                id="basic-typeahead-single"
                                labelKey="uom"
                                size="sm"
                                multiple={false}
                                options={uomList.data}
                                isLoading={uomList.loading}
                                disabled={true}
                                placeholder="Select unit"
                                onChange={onChange}
                                onBlur={onBlur}
                                selected={value}
                                isInvalid={
                                  errors.items?.[index]?.uom ? true : false
                                }
                              />
                            )}
                          />
                          {errors.items?.[index]?.uom && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.uom.message}
                            </span>
                          )}
                        </td>

                        <td
                          style={{
                            textAlign: "center",
                            verticalAlign: "top",
                            width: "10%",
                          }}
                        >
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip id="tooltip">
                                {"Delete Particulars"}
                              </Tooltip>
                            }
                          >
                            {
                              <div
                                className="deleteIcon cursor_pointer"
                                onClick={
                                  index > 0
                                    ? () => {
                                        remove(index);
                                        let arr = [...show_work_quantity];
                                        arr.pop();
                                        setshow_work_quantity(arr);
                                      }
                                    : null
                                }
                              >
                                <Trash2 color="#FFFFFF" size={18} />
                              </div>
                            }
                          </OverlayTrigger>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <hr className="mt-4" />
              {challantype &&
                challantype.length > 0 &&
                challantype[0].typename !== "Packing" &&
                challantype[0].typename !== "Adjustment Challan" && (
                  <>
                    <p
                      className="roboto-bold mb-0"
                      style={{ fontSize: "20px" }}
                    >
                      {constLabel?.lbl_tranport_details
                        ? constLabel.lbl_tranport_details
                        : "Transport Details"}
                    </p>
                    <hr className="my-4" />
                    <div className="row mt-2 col-12">
                      <div className="col-md-3 mt-3">
                        <div className="form-group mt-md-0 mt-3 ">
                          <label className="lbl-style roboto-bold">
                            {constLabel?.lbl_transport_type
                              ? constLabel.lbl_transport_type
                              : "Transport Type"}
                            <span>
                              <i style={{ color: "red" }}>*</i>
                            </span>
                          </label>

                          <Controller
                            name={`transportType`}
                            control={control}
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <Typeahead
                                id="basic-typeahead-single"
                                labelKey="name"
                                size="sm"
                                multiple={false}
                                options={trasnportList.data}
                                isLoading={trasnportList.loading}
                                placeholder="Select transport type"
                                onChange={(selected) => {
                                  onChange(selected);
                                  if (selected && selected.length > 0) {
                                    settranporttype(selected);
                                  }
                                }}
                                onBlur={onBlur}
                                selected={value}
                                isInvalid={errors.transportType ? true : false}
                              />
                            )}
                          />
                          {errors.transportType && (
                            <span className="text-danger err-msg">
                              {errors.transportType.message}
                            </span>
                          )}
                        </div>
                      </div>

                      {transporttype && transporttype[0].name == "Internal" && (
                        <div className="col-md-3 mt-3">
                          <div className="form-group mt-md-0 mt-3 ">
                            <label className="lbl-style roboto-bold">
                              {constLabel?.lbl_internal_transport
                                ? constLabel.lbl_internal_transport
                                : "Int. Transport"}
                              <span>
                                <i style={{ color: "red" }}>*</i>
                              </span>
                            </label>

                            <Controller
                              name={`internaltransport`}
                              control={control}
                              render={({
                                field: { onChange, onBlur, value, ref },
                              }) => (
                                <Typeahead
                                  id="basic-typeahead-single"
                                  labelKey="name"
                                  size="sm"
                                  multiple={false}
                                  options={internaltrasnportList.data}
                                  isLoading={internaltrasnportList.loading}
                                  placeholder="Select internal transport"
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  selected={value}
                                  isInvalid={
                                    errors.internaltransport ? true : false
                                  }
                                />
                              )}
                            />
                            {errors.internaltransport && (
                              <span className="text-danger err-msg">
                                {errors.internaltransport.message}
                              </span>
                            )}
                          </div>
                        </div>
                      )}

                      {transporttype && transporttype[0].name == "External" && (
                        <div className="col-md-3 mt-3">
                          <div className="form-group mt-md-0 mt-3 ">
                            <label className="lbl-style roboto-bold">
                              {constLabel?.lbl_ext_transport
                                ? constLabel.lbl_ext_transport
                                : "External Transport"}
                              <span>
                                <i style={{ color: "red" }}>*</i>
                              </span>
                            </label>

                            <input
                              name="extTransport"
                              className={
                                errors.extTransport
                                  ? "form-control form-control-sm is-invalid"
                                  : "form-control form-control-sm"
                              }
                              type="text"
                              placeholder="Enter vehical number"
                              {...register("extTransport")}
                            />
                            {errors.extTransport && (
                              <span className="text-danger err-msg">
                                {errors.extTransport.message}
                              </span>
                            )}
                          </div>
                        </div>
                      )}

                      {transporttype && transporttype[0].name == "Airway" && (
                        <div className="col-md-3 mt-3">
                          <div className="form-group mt-md-0 mt-3 ">
                            <label className="lbl-style roboto-bold">
                              {constLabel?.lbl_airway_transport
                                ? constLabel.lbl_airway_transport
                                : "Airway Transport"}
                              <span>
                                <i style={{ color: "red" }}>*</i>
                              </span>
                            </label>

                            <input
                              name="airwayTransport"
                              className={
                                errors.airwayTransport
                                  ? "form-control form-control-sm is-invalid"
                                  : "form-control form-control-sm"
                              }
                              type="text"
                              placeholder="Enter flight number"
                              {...register("airwayTransport")}
                            />
                            {errors.airwayTransport && (
                              <span className="text-danger err-msg">
                                {errors.airwayTransport.message}
                              </span>
                            )}
                          </div>
                        </div>
                      )}

                      <div className="col-md-3 mt-3">
                        <div className="form-group mt-md-0 mt-3 ">
                          <label className="lbl-style roboto-bold">
                            {constLabel?.lbl_driver_name
                              ? constLabel.lbl_driver_name
                              : "Driver Name"}
                            <span>
                              <i style={{ color: "red" }}>*</i>
                            </span>
                          </label>

                          <input
                            name="driverName"
                            className={
                              errors.driverName
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            type="text"
                            placeholder="Enter driver name "
                            {...register("driverName")}
                          />
                          {errors.driverName && (
                            <span className="text-danger err-msg">
                              {errors.driverName.message}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="col-md-3 mt-3">
                        <div className="form-group mt-md-0 mt-3 ">
                          <label className="lbl-style roboto-bold">
                            {constLabel?.lbl_status
                              ? constLabel.lbl_status
                              : "Status"}
                          </label>

                          <input
                            name="status"
                            className={
                              errors.status
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            type="text"
                            placeholder="Enter status"
                            disabled={true}
                            {...register("status")}
                          />
                          {errors.status && (
                            <span className="text-danger err-msg">
                              {errors.status.message}
                            </span>
                          )}
                        </div>
                      </div>

                      {challantype &&
                        challantype.length > 0 &&
                        challantype[0].typename == "Dispatch Challan" && (
                          <div className="col-md-3 mt-3">
                            <div className="form-group mt-md-0 mt-3 ">
                              <label className="lbl-style roboto-bold">
                                {constLabel?.lrn
                                  ? constLabel.lrn
                                  : "Lorry Receipt Number"}
                              </label>

                              <input
                                name="lrn"
                                className={
                                  errors.lrn
                                    ? "form-control form-control-sm is-invalid"
                                    : "form-control form-control-sm"
                                }
                                type="text"
                                placeholder="Enter lorry receipt number"
                                {...register("lrn")}
                              />
                              {/* {errors.lrn && (
                      <span className="text-danger err-msg">
                        {errors.lrn.message}
                      </span>
                    )} */}
                            </div>
                          </div>
                        )}

                      <div className="col-md-3 mt-3">
                        <div className="form-group mt-md-0 mt-3 ">
                          <label className="lbl-style roboto-bold">
                            {constLabel?.lbl_remark
                              ? constLabel.lbl_remark
                              : "Remark"}
                          </label>

                          <textarea
                            className={
                              errors.remark
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            type="text"
                            placeholder="Enter remark"
                            {...register("remark")}
                          ></textarea>
                          {errors.remark && (
                            <span className="text-danger err-msg">
                              {errors.remark.message}
                            </span>
                          )}
                        </div>
                      </div>
                      {/* 
                <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label col-12 col-md-3"
                  >
                    NC Details
                  </label>

                  <div className="col-12 col-md-9">
                    <div className="form-control form-control-sm">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="ncDetail"
                        name="ncDetail"
                        {...register("ncDetail")}
                      />
                      <label className="form-check-label form_label ms-2">
                        {" "}
                        {" Has NC ?"}
                      </label>
                    </div>
                  </div>
                </div> */}
                    </div>
                    <hr className="my-4" />{" "}
                  </>
                )}
            </div>
          </div>

          <div className="card-footer py-3">
            <button
              className="btn btn-yellow px-4 float-end"
              disabled={buttonclick}
              onClick={handleSubmit(onSubmit)}
            >
              {constLabel?.lbl_save ? constLabel.lbl_save : "Save"}{" "}
              {buttonclick ? <Spinner animation="border" size="sm" /> : null}{" "}
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default EditGoodsChallan;
