import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faFileAlt, faMusic } from "@fortawesome/free-solid-svg-icons";
import { Modal, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import SweetAlert from "sweetalert2";
import {
  del_master_file,
  get_master_files,
  master_multi_file_upload,
  update_master_file,
} from "../../api";
import {
  convertBase64,
  getLabels,
  truncateText,
} from "../../common/Functions/CommonFunctions";
import { s3_image_upload } from "../../constant";
import axios from "axios";
import { screenList } from "../../common/Labels/screenList";
import audio from "../../assets/images/audio.png"

const imageFileTypes = ["image/jpeg", "image/jpg", "image/png"];
const audioFileTypes = ["audio/mpeg", "audio/wav", "audio/mp3"];

const schema = yup.object().shape({
  image: yup
    .mixed()
    .test(
      "fileSize",
      "File can be uploaded up to 10 MB",
      (value) => 
        !value || Array.from(value).every((file) => file.size <= 10 * 1024 * 1024)
    )
    .test(
      "fileType",
      "Only image or audio files are allowed",
      (value) =>
        !value || Array.from(value).every((file) => 
          imageFileTypes.includes(file.type) || audioFileTypes.includes(file.type)
        )
    )
    .required("File is required"),
});

function FilesUpload(props) {
  const { so_data, visible, setVisible } = props;

  const [so_files, set_so_files] = useState({ data: [], loading: false });
  const [new_files, set_new_files] = useState([]);
  const [successMessageShown, setSuccessMessageShown] = useState(false);
  const [lockBtn, setLockBtn] = useState(false);

  const [constLabel, setConstLabel] = useState({});

  const localData = JSON.parse(localStorage.getItem("localData"));

  const {
    register,
    handleSubmit,
    formState,
    setValue,
    reset,
    setError,
    clearErrors
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  let { errors } = formState;

  useEffect(() => {
    if(visible){
        setConstLabel(getLabels("Sales", "Sales Order"));
        fetchImage();
    }
  }, [visible]);

  async function fetchImage() {
      set_so_files((prev) => ({ ...prev, loading: true, data: [] }));
      await get_master_files({
        ref_model_uuid: so_data?.uuid,
      }).then(
        (res) => {
          set_so_files((prev) => ({ ...prev, loading: false, data: res.data.data }));
        },
        (err) => {
          set_so_files((prev) => ({ ...prev, loading: true, data: [] }));
        }
      );
    }

  function deleteFile(val) {
    SweetAlert.fire({
      title: "Are you sure to delete file ? \n",
      // text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        del_master_file({ uuid: val.uuid }).then(
          (res) => {
            toast.success("File deleted successfully", {
              autoClose: 1000,
            });
            setTimeout(() => {
              fetchImage();
            }, 1200);
            // props.setGalVisible(false);
          },
          (err) => {
            toast.error("Something went wrong", {
              autoClose: 2000,
            });
          }
        );
      }
    });
  }


  const submitFiles = async () => {
    setLockBtn(true);
    if (new_files?.length > 0) {
      const url = process.env.REACT_APP_COGNITO_URL + s3_image_upload;
      const idToken = localStorage.getItem("id");
      const imgArr = [];

        for (let a = 0; a < new_files.length; a++) {
            imgArr.push({
            fileName: (new_files[a]?.file?.name)?.replace(/[^a-zA-Z0-9.]+/g, ''),
            contentType: new_files[a]?.file?.type,
            model: {
                ref_model_uuid: so_data?.uuid,
                model_name: screenList.sales_order_screen.replace(/\s+/g, ""),
                name: (new_files[a]?.file?.name)?.replace(/[^a-zA-Z0-9.]+/g, ''),
                document_type: new_files[a]?.file?.type.startsWith("image/") ? "image" : 
                new_files[a]?.file?.type.startsWith("audio/") ? "audio" : "video",
                created_by_user: localData?.user_id,
                client_id: localData?.client_id
            }
            });
        }

        const payload = {
            files: imgArr,
        };

      await axios
        .post(url, payload, {
          headers: {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
          },
        })
        .then(
          async (res) => {
            for (let x = 0; x < res.data.data?.body?.length; x++) {
              const url2 = res.data.data?.body?.[x]?.url;
            
              try {
                await axios.put(url2, new_files[x]?.file, {
                  headers: {
                    "Content-Type": new_files[x]?.file?.type,
                  },
                });
            
                // Add delay of 1.5 second before processing the next file
                await new Promise((resolve) => setTimeout(resolve, 1500));
            
              } catch (errr) {
                console.log("errr", errr);
            
                if (errr.response?.status === 413) {
                  toast.error("Design sheet upload failed!", { autoClose: 5000 });
                }
            
                setLockBtn(true);
              }
            }
            toast.success("Design sheet upload successfully!", { autoClose: 2500 });
                setLockBtn(false);
            // Clear files and fetch images after all uploads are complete
            setTimeout(() => {
              set_new_files([]);
              fetchImage();
            }, 2800);
            
          },
          (err) => {
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
            setLockBtn(true);
          }
        );
    }
  };

  const renderAttachment = (type, attachment, name) => {
    const fileURL = `${process.env.REACT_APP_IMAGE_URL}${attachment}`;
  
    switch (type) {
      case "image":
        return (
          <a href={fileURL} target="_blank" rel="noopener noreferrer">
          <img src={fileURL} className="img-fluid" alt={name || "Image"} />
          </a>
        );
      case "audio":
        return (
          <a href={fileURL} target="_blank" rel="noopener noreferrer">
            <img 
              src={audio} 
              alt="Audio File" 
              className="audio-icon" 
              style={{ width: "100px", height: "50px" }} 
            />
          </a>
        );
      default:
        return (
          <div>
            <FontAwesomeIcon
              className="icon-file-default"
              style={{ color: "#fff" }}
              icon={faFileAlt} // Icon for default file type
              size="3x"
            />
            <span className="ms-2 text-secondary">{name || "Unsupported File"}</span>
          </div>
        );
    }
  };

  const handleInputChange = (data) => {
    let tempImg = [...new_files];
    tempImg.push({
      file: data.image[0],
    });
    set_new_files(tempImg);

    reset({
      image: null,
    });
  };

  const [dragActive, setDragActive] = useState(false);
  const fileInputRef = useRef(null);

  // const handleFiles = (files) => {
  //     set_new_files((prevFiles) => [
  //       ...prevFiles,
  //       ...Array.from(files).map((file) => ({ file })) // Convert each file to an object
  //     ]);
  // };

  const handleFiles = (files) => {
    const newFilesArray = Array.from(files).map((file) => ({ file }));

    set_new_files((prevFiles) => {
        // Extract existing file names to avoid duplication
        const existingFileNames = new Set(prevFiles.map(f => f.file.name));

        // Filter out duplicates before adding
        const filteredFiles = newFilesArray.filter(f => !existingFileNames.has(f.file.name));

        // If no new files, return previous state
        if (filteredFiles.length === 0) return prevFiles;

        const updatedFiles = [...prevFiles, ...filteredFiles];
        // console.log("Updated state files:", updatedFiles);
        return updatedFiles;
    });
};

  const removeFile = (index) => {
    set_new_files((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleDrag = (event, isActive) => {
    event.preventDefault();
    setDragActive(isActive);
  };

  // const handleFileInputChange = (e) => {
  //   handleFiles(e.target.files);
  //   e.target.value = null; // Clear the input value
  // };

  const handleFileInputChange = (e) => {
    const files = e.target.files;
    console.log("files", files);
    if (files.length === 0) return; // Prevent empty selections
  
    // Check if all selected files are image or audio
    const isValid = Array.from(files).every(file => 
      file.type.startsWith("image/") || file.type.startsWith("audio/")
    );
  
    if (!isValid) {
      setError("image", {
        type: "manual",
        message: "Only image or audio files are allowed!",
      });
      e.target.value = null; // Reset file input
      return;
    }
  
    // If valid, update the field and trigger validation
    clearErrors("image"); // Clear previous errors
    handleFiles(files);
    // setValue("image", files, { shouldValidate: true });
  
    e.target.value = null; // Clear input value after processing
  };
  
  return (
    <React.Fragment>
      <ToastContainer />

      <Modal
        show={visible}
        onHide={() => {
          setVisible(false);
          set_new_files([]);
        }}
        size="lg"
        backdrop="static"
      >
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>
            {constLabel?.lbl_design_sheet
              ? constLabel.lbl_design_sheet
              : "Design Sheet"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
            <div className="row mb-4">
            <div className="col-12">
                <div
                    className={`file-upload-container ${dragActive ? "drag-active" : ""} d-flex flex-column align-items-center justify-content-center p-5 text-center border border-secondary border-dashed`}
                    onDragOver={(e) => handleDrag(e, true)}
                    onDragLeave={(e) => handleDrag(e, false)}
                    onDrop={(e) => { handleDrag(e, false); handleFiles(e.dataTransfer.files); }}
                    onClick={() => fileInputRef.current.click()}
                >
                    <input
                    className={`form-control form-control-sm ${errors?.image ? "is-invalid" : ""}`}
                    type="file"
                    multiple
                    accept="image/*, audio/*"
                    name="image"
                    // onChange={(e) => {
                    //     console.log("e.target.files", e.target.files )
                    //     handleFiles(e.target.files)
                    // }}
                    onChange={handleFileInputChange}
                    ref={fileInputRef}
                    hidden
                    />
                    <span className="fw-bold">
                    Drag and drop or <span className="file-link text-primary">Choose your files</span>
                    </span>
                </div>
                {errors?.image && <span className="text-danger err-msg">{errors.image.message}</span>}
                </div>
            </div>

            {new_files?.loading ? (
                <div className="text-center mt-4">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            ) : new_files?.length > 0 ? (
                <div className="row drawing_gallery row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4">
                {new_files.map((val, i) => {
                    const fileType = val.file?.type || ""; // Get the file type
                    const fileURL = URL.createObjectURL(val?.file); // Create object URL for the file
                    return (
                        <div className="col text-center" key={i}>
                        <div className="image-container">
                            {fileType.includes("image") ? (
                                <a href={fileURL} target="_blank" rel="noopener noreferrer">
                            <img
                                src={fileURL}
                                className="img-fluid"
                                alt={val.file?.name || "Image"}
                            />
                            </a>
                            ) : fileType.includes("audio") ? (
                            <a href={fileURL} target="_blank" rel="noopener noreferrer">
                                <img 
                                src={audio} 
                                alt="Audio File" 
                                className="audio-icon" 
                                style={{ width: "100px", height: "50px" }} 
                                />
                            </a>
                            ) : (
                            <div
                                onClick={() => window.open(fileURL, "_blank")}
                                className="other_doc d-flex justify-content-center align-items-center cursor-pointer"
                            >
                                <FontAwesomeIcon
                                className="icon-file-default"
                                style={{ color: "#fff" }}
                                icon={faFileAlt} // Icon for default file type
                                size="3x"
                                />
                            </div>
                            )}

                            <FontAwesomeIcon
                            onClick={() => removeFile(i)}
                            className="icon-close"
                            style={{ color: "#000" }}
                            icon={faClose}
                            size="lg"
                            />
                        </div>

                        <span className="file-name cursor-pointer">
                            {truncateText((val.file?.name), 15)}
                        </span>
                        </div>
                    );
                })}
                </div>
            ) : (
                <div
                  className="alert alert-danger mb-0 text-center my-4"
                  role="alert"
                >
                 {"Please select and upload files!"}
                </div>
            )}

            <hr />
            <p className="roboto-bold">{"Previous Uploaded Files"}</p>
            <hr />

            {so_files?.loading ? (
                <div className="text-center mt-4">
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
                </div>
            ) : so_files?.data?.length > 0 ? (
                <div className="row drawing_gallery row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4">
                {so_files?.data.map((val, i) =>
                    val.attachment !== null ? (
                    <div className="col text-center" key={i}>
                        <div className="image-container">
                        {renderAttachment(val.document_type, val.attachment, val.name)}

                        <FontAwesomeIcon
                            onClick={() => deleteFile(val)}
                            className="icon-close"
                            style={{ color: "#000" }}
                            icon={faClose}
                            size="lg"
                        />
                        </div>

                        <span
                        className="image-name cursor_pointer"
                        >
                        {truncateText((val.name), 15)}
                        </span>
                    </div>
                    ) : null
                )}
                </div>
            ) : (
                <div
                  className="alert alert-danger mb-0 text-center my-4"
                  role="alert"
                >
                 {"No files found!"}
                </div>
            )}
        </Modal.Body>
        <Modal.Footer>
            <button
                className="btn btn-sm btn-yellow px-3"
                // onClick={handleSubmit(onSubmit)}
                onClick={() => submitFiles()}
                disabled={lockBtn || new_files.length === 0} // Disable if no files
                >
                {constLabel?.lbl_submit ? constLabel.lbl_submit : "Submit"}{" "}
                {lockBtn ? <Spinner animation="border" size="sm" /> : null}
            </button>
          <button
            className="btn btn-sm btn-grey px-4"
            onClick={() => {
              setVisible(false);
              set_new_files([]);
            }}
          >
            {constLabel?.lbl_close ? constLabel.lbl_close : "Close"}
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default FilesUpload;