import React, { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Modal, Form } from "react-bootstrap";

function Select_Customer_And_Measurement(props) {
  const { visible, setVisible, main_obj, setMain_obj, nextStep } = props;

  useEffect(() => {
    // console.log("main_obj.so_data", main_obj.so_data);
    if (
      main_obj.so_data?.length === 1 &&
      main_obj.so_data[0].customer?.measurements?.length === 1
    ) {
      const updatedObj = { ...main_obj };
      updatedObj.so_data[0].customer.measurements[0].isSelected = true;
      updatedObj.so_data[0].isSelected = true; // Also update customer selection
      setMain_obj(updatedObj);
    }
  }, [JSON.stringify(main_obj.so_data)]);

  const handleCheckboxChange = useCallback((customerIndex, measurementIndex) => {
    setMain_obj((prevState) => {
      const updatedSoData = prevState.so_data.map((so, cIndex) => {
        if (cIndex === customerIndex) {
          const updatedMeasurements = so.customer?.measurements?.map((measurement, mIndex) => ({
            ...measurement,
            isSelected: mIndex === measurementIndex ? !measurement.isSelected : false,
          }));

          return {
            ...so,
            isSelected: updatedMeasurements.some(m => m.isSelected),
            customer: {
              ...so.customer,
              measurements: updatedMeasurements,
            },
          };
        }
        return so;
      });

      return { ...prevState, so_data: updatedSoData };
    });
  }, []);

  const isProceedDisabled = !(
    main_obj.so_data?.some(
      (so) =>
        so.isSelected &&
        so.customer?.measurements?.some((meas) => meas.isSelected)
    )
  );

  return (
    <Modal show={visible} onHide={() => setVisible(false)} size="lg">
      <Modal.Header className="bg-dark text-white" closeButton>
        <Modal.Title>Select Measurement</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form>
          <div className="row g-3">
            {main_obj.so_data?.map((customer, customerIndex) => (
              <div key={customerIndex} className="col-12 col-md-6">
                <div className="card shadow-sm border-0">
                  <div className="card-body">
                    <h5 className="card-title roboto-bold">
                      {customer.customer?.organization}
                    </h5>
                    <hr />
                    <div className="d-flex flex-column">
                      {customer.customer?.measurements?.map(
                        (measurement, measurementIndex) => (
                          <div key={measurementIndex} className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id={`checkbox-${customerIndex}-${measurementIndex}`}
                              checked={measurement.isSelected || false}
                              onChange={() =>
                                handleCheckboxChange(customerIndex, measurementIndex)
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`checkbox-${customerIndex}-${measurementIndex}`}
                            >
                              {measurement.name}
                            </label>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-primary px-4 me-2"
          onClick={nextStep}
          disabled={isProceedDisabled}
        >
          Let's Proceed
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default Select_Customer_And_Measurement;
