import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faCircle,
  faFilter,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";
import { get_job_card, get_quick_view, get_WorkOrder, getGlobalRouting, getProdConfig } from "../../api";
import ViewJobCard from "./ViewJobCard";
import { Tooltip } from "react-tooltip";
import {
  getLabels,
  getShortReadableId,
  setAccessPrivilage,
  truncateText,
} from "../../common/Functions/CommonFunctions";
import Box from "../../assets/images/icons/project_menu/carton-box.png";
import { useSelector } from "react-redux";
import AccessDenied from "../Common/AccessDenied";

function JobCardBoard() {
  let navigate = useNavigate();
  let location = useLocation();

  const [selItem, setSelItem] = useState({});
  const [dataList, setDataList] = useState({ data: [], loading: true });
  const [visible, setVisible] = useState(false);
  const [jobCardList, setJobCardList] = useState({ data: [], loading: true });
  const [workOrderNum, setWorkOrderNum] = useState("");
  const [quickViewData, setQuickViewData] = useState({
    data: [],
    loading: true,
  });
  const [workOrderDetail, setWorkOrderDetail] = useState([]);
  const [operations, setOperations] = useState({ data: [], loading: false });

  const [constLabel, setConstLabel] = useState({});

  const loginData = useSelector(
    (state) => state.auth?.loginData?.data?.response
  );
  const [privilage, setPrivilage] = useState({
    read: true,
    write: true,
    view: true,
    delete: true,
    report: true,
    no_access: false,
  });

  useEffect(() => {
    // console.log("location", location);
    setPrivilage(setAccessPrivilage(loginData, "Job Card Board"));
    setConstLabel(getLabels("Production", "Job Card Board"));
    if (location.state !== null) {
      const payload = {
        uuid: location.state?.work_order_id || location.state?.uuid,
      };
      get_WorkOrder(payload).then(
        (res) => {
          // console.log("res", res);
          if (res) {
            setWorkOrderDetail(res.data?.data?.[0]);
            setWorkOrderNum(res.data?.data?.[0]?.work_order_no);
            quickViewModal(res.data?.data?.[0]?.uuid);
            if(res.data?.data?.[0]?.route_uuid){
              fetchProductOperations(res.data?.data?.[0]?.product_id, res.data?.data?.[0]?.route_uuid )
            } else {
              fetchGlobalRoute(res.data?.data?.[0]?.global_route_uuid)
            }
          }
        },
        (err) => {
          console.log("err", err);
        }
      );
      // setWorkOrderNum(location.state.work_order_no);
      // quickViewModal(location?.state?.uuid);
    }
  }, [location.state]);

  function formatDate(inputDate) {
    const options = { month: "short", day: "numeric", year: "numeric" };
    const date = new Date(inputDate);
    return date.toLocaleDateString("en-US", options);
  }

  function handleClick(ind) {
    // console.log("ind", ind);

    let arr = dataList.data.map((item, i) => {
      if (ind === i) {
        item.flag = !item.flag;
        return item;
      } else {
        return item;
      }
    });
    setDataList({ ...dataList, data: arr });
  }

  function getBadgeClass(status) {
    if (status === "Open") {
      return "bg-dark";
    } else if (status === "Start") {
      return "bg-primary";
    } else if (status === "Hold") {
      return "bg-secondary";
    } else if (status === "Closed") {
      return "bg-success";
    } else if (status === "Cancelled") {
      return "bg-danger";
    } else {
      return "bg-info"; // default class if none match
    }
  }

  function quickViewModal(wo_uuid) {
    setQuickViewData({ ...quickViewData, data: [], loading: true });
    setDataList({ ...dataList, data: [], loading: true });
    get_quick_view({ work_order_id: wo_uuid }).then(
      (res) => {
        // console.log("quick view res", res);
        setQuickViewData({
          ...quickViewData,
          data: res.data.data,
          loading: false,
        });

        // Assuming res.data.data[0].operation is an array of objects
        const updatedOperation = res.data.data[0].operation.map(
          (operation) => ({
            ...operation,
            flag: operation.job_cards.length > 0 ? true : false,
          })
        );

        // console.log("updatedOperation", updatedOperation);
        setDataList({
          ...dataList,
          data: updatedOperation,
          loading: false,
        });
      },
      (err) => {
        console.log("err", err);
        setQuickViewData({ ...quickViewData, data: [], loading: false });
        setDataList({ ...dataList, data: [], loading: false });
      }
    );
  }

  function fetchProductOperations(prod_id, route_uuid) {
      setOperations({ ...operations, data: [], loading: true });
  
      getProdConfig({
        product_id: prod_id,
        routes_uuid: route_uuid,
      }).then(
        (res) => {
          // console.log("prod opr res", res);
          setOperations({ ...operations, data: res.data.data, loading: false });
        },
        (err) => {
          setOperations({ ...operations, data: [], loading: false });
        }
      );
    }

    function fetchGlobalRoute(glob_route_uuid) {
      setOperations({ ...operations, data: [], loading: true });
        getGlobalRouting({ uuid: glob_route_uuid }).then(
          (res) => {
            // console.log("res", res);
            setOperations({
              ...operations,
              data: res.data.data?.[0]?.routeData,
              loading: false,
            });
          },
          (err) => {
            console.log("err", err);
            setOperations({ ...operations, data: [], loading: false });
          }
        );
      }

  return (
    <React.Fragment>
      {!privilage.read ? (
        <AccessDenied />
      ) : (
        <>
          {visible && (
            <ViewJobCard
              visible={visible}
              setVisible={setVisible}
              selItem={selItem}
            />
          )}
          <div className="mb-4">
            <div className="card">
              <div className="card-header bg-grey d-flex align-items-center">
                <button
                  className="btn btn-yellow"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <FontAwesomeIcon icon={faArrowLeft} size="sm" />
                </button>

                <p className="mb-0 ms-3" style={{ fontSize: "24px" }}>
                  {constLabel?.lbl_job_card_board
                    ? constLabel.lbl_job_card_board
                    : `Job Card Board`}{" "}
                  - {getShortReadableId(workOrderNum)}
                </p>
              </div>

              <div className="bg-lightgrey m-2">
                <div className="d-md-flex align-items-center m-2">
                  <div
                    className="card border-0 p-0 mx-1 my-1"
                    style={{ width: "17rem" }}
                  >
                    <div className="d-flex align-items-center">
                      <div className="px-2">
                        {/* <img src="your-image-url.jpg" className="img-fluid" alt="Image" style={{maxWidth: "100px"}} /> */}
                        <img
                          src={Box}
                          className="img-fluid svg-img"
                          alt="Card"
                          width={40}
                          height={40}
                        />
                      </div>

                      <div className="d-flex flex-column justify-content-center py-3">
                        <h6 className="card-subtitle text_muted mb-2">
                          {constLabel?.lbl_product_name
                            ? constLabel?.lbl_product_name
                            : `Product Name`}
                        </h6>
                        <h6 className="roboto-bold">
                          {quickViewData.data?.[0]?.product_name !== "" &&
                          quickViewData.data?.[0]?.product_name !== null
                            ? quickViewData.data?.[0]?.product_name
                            : "NA"}
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div
                    className="card border-0 p-0 mx-1 my-1"
                    style={{ width: "10rem" }}
                  >
                    <div className="d-flex align-items-center">
                      <div className="px-2">
                        {/* <img src="your-image-url.jpg" className="img-fluid" alt="Image" style={{maxWidth: "100px"}} /> */}
                        <img
                          src={Box}
                          className="img-fluid svg-img"
                          alt="Card"
                          width={40}
                          height={40}
                        />
                      </div>

                      <div className="d-flex flex-column justify-content-center py-3">
                        <h6 className="card-subtitle text_muted mb-2">
                          {" "}
                          {constLabel?.lbl_dispatch_qty
                            ? constLabel.lbl_dispatch_qty
                            : `Dispatch Qty`}
                        </h6>
                        <h6 className="roboto-bold">
                          {quickViewData.data?.[0]?.dispatched_qty !== "" &&
                          quickViewData.data?.[0]?.dispatched_qty !== null
                            ? quickViewData.data?.[0]?.dispatched_qty
                            : "NA"}
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div
                    className="card border-0 p-0 mx-1 my-1"
                    style={{ width: "12rem" }}
                  >
                    <div className="d-flex align-items-center">
                      <div className="px-2">
                        {/* <img src="your-image-url.jpg" className="img-fluid" alt="Image" style={{maxWidth: "100px"}} /> */}
                        <img
                          src={Box}
                          className="img-fluid svg-img"
                          alt="Card"
                          width={40}
                          height={40}
                        />
                      </div>

                      <div className="d-flex flex-column justify-content-center py-3">
                        <h6 className="card-subtitle text_muted mb-2">
                          {" "}
                          {constLabel?.lbl_work_order_qty
                            ? constLabel.lbl_work_order_qty
                            : `Work Order Qty`}
                        </h6>
                        <h6 className="roboto-bold">
                          {quickViewData.data?.[0]?.work_order_qty !== "" &&
                          quickViewData.data?.[0]?.work_order_qty !== null
                            ? quickViewData.data?.[0]?.work_order_qty
                            : "NA"}
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div
                    className="card border-0 p-0 mx-1 my-1"
                    style={{ width: "10rem" }}
                  >
                    <div className="d-flex align-items-center">
                      <div className="px-2">
                        {/* <img src="your-image-url.jpg" className="img-fluid" alt="Image" style={{maxWidth: "100px"}} /> */}
                        <img
                          src={Box}
                          className="img-fluid svg-img"
                          alt="Card"
                          width={40}
                          height={40}
                        />
                      </div>

                      <div className="d-flex flex-column justify-content-center py-3">
                        <h6 className="card-subtitle text_muted mb-2">
                          {" "}
                          {constLabel?.lbl_nc_qty
                            ? constLabel.lbl_nc_qty
                            : `NC Qty`}
                        </h6>
                        <h6 className="roboto-bold">
                          {quickViewData.data?.[0]?.nc_count !== "" &&
                          quickViewData.data?.[0]?.nc_count !== null
                            ? quickViewData.data?.[0]?.nc_count
                            : "NA"}
                        </h6>
                      </div>
                    </div>
                  </div>

                  <div
                    className="d-md-flex align-items-end justify-content-end"
                    style={{ marginLeft: "auto" }}
                  >
                    <div className="m-2 align-items-center">
                      <div
                        className="d-flex align-items-center"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <FontAwesomeIcon
                          style={{ color: "#000000" }}
                          icon={faFilter}
                          className="me-1"
                        />{" "}
                        {"Filter"}
                      </div>

                      <ul className="dropdown-menu px-2">
                        {dataList.data !== undefined &&
                        dataList.data?.length > 0
                          ? dataList.data.map((val, ind) => (
                              <li key={ind}>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    style={{ width: "20px", height: "20px" }}
                                    onChange={() => handleClick(ind)}
                                    checked={val.flag}
                                  />
                                  <label
                                    className=""
                                    style={{ fontSize: "13px" }}
                                    htmlFor="flexCheckDefault"
                                  >
                                    {val.operationname}
                                  </label>
                                </div>
                              </li>
                            ))
                          : null}
                      </ul>
                    </div>

                    <div>
                      <button
                        className="btn button-primary ms-3"
                        onClick={() =>
                          navigate(`/pcms/create-job-card`, {
                            // state: { ...workOrderDetail, jobCardBoard: true },
                            state: { 
                              work_order_details: workOrderDetail, 
                              operation: operations.data,
                              jobCardBoard: true },
                          })
                        }
                      >
                        <FontAwesomeIcon
                          style={{ color: "#fff" }}
                          icon={faPlus}
                          size="sm"
                        />{" "}
                        {constLabel?.lbl_create_job_card
                          ? constLabel.lbl_create_job_card
                          : `Create Job Card`}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {dataList.loading ? (
                <div className="text-center">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : dataList.data?.length > 0 ? (
                <div style={{ maxHeight: "80vh", overflowY: "auto" }}>
                  {dataList.data &&
                    dataList.data?.map((item, idx) =>
                      item.flag ? (
                        <>
                          <div className="d-md-flex align-items-center m-2">
                            <Tooltip id={"view-tooltip" + idx} place="top" />
                            <h5
                              className="roboto-bold cursor_pointer me-4 my-0"
                              data-tooltip-id={"view-tooltip" + idx}
                              data-tooltip-content={item.operationname}
                            >
                              {truncateText(
                                item.operationname.toUpperCase(),
                                18
                              )}
                            </h5>
                            <FontAwesomeIcon
                              icon={faCircle}
                              size={18}
                              className={`${
                                item.avg_efficiency >= 91
                                  ? "ind_green"
                                  : item.avg_efficiency >= 81 &&
                                    item.avg_efficiency <= 90
                                  ? "ind_orange"
                                  : "ind_red"
                              } cursor_pointer me-4`}
                              data-tooltip-id={"indicat-tooltip" + idx}
                            />
                            <Tooltip id={"indicat-tooltip" + idx} place="left">
                              <div className="bold">
                                Good -{" "}
                                <FontAwesomeIcon
                                  icon={faCircle}
                                  size={12}
                                  className="ind_green"
                                />{" "}
                                - 91 and above
                              </div>
                              <div className="bold">
                                Avg -{" "}
                                <FontAwesomeIcon
                                  icon={faCircle}
                                  size={12}
                                  className="ind_orange"
                                />{" "}
                                - 81 to 90
                              </div>
                              <div className="bold">
                                Bad -{" "}
                                <FontAwesomeIcon
                                  icon={faCircle}
                                  size={12}
                                  className="ind_red"
                                />{" "}
                                - 80 and below
                              </div>
                            </Tooltip>
                            <div
                              className="card border-0 shadow-sm p-0 mx-1 my-1"
                              style={{ width: "10rem" }}
                            >
                              <div className="d-flex align-items-center">
                                <div className="px-2">
                                  <img
                                    src={Box}
                                    className="img-fluid svg-img"
                                    alt="Card"
                                    width={40}
                                    height={40}
                                  />
                                </div>
                                <div className="d-flex flex-column justify-content-center py-3">
                                  <h6 className="card-subtitle text-muted mb-2">
                                    {constLabel?.lbl_planned_qty
                                      ? constLabel?.lbl_planned_qty
                                      : `Planned Qty`}
                                  </h6>
                                  <h6 className="roboto-bold">
                                    {item.planned_quantity !== "" &&
                                    item.planned_quantity !== null
                                      ? item.planned_quantity
                                      : "NA"}
                                  </h6>
                                </div>
                              </div>
                            </div>
                            <div
                              className="card border-0 shadow-sm p-0 mx-1 my-1"
                              style={{ width: "10rem" }}
                            >
                              <div className="d-flex align-items-center">
                                <div className="px-2">
                                  <img
                                    src={Box}
                                    className="img-fluid svg-img"
                                    alt="Card"
                                    width={40}
                                    height={40}
                                  />
                                </div>
                                <div className="d-flex flex-column justify-content-center py-3">
                                  <h6 className="card-subtitle text-muted mb-2">
                                    {constLabel?.lbl_produced_qty
                                      ? constLabel.lbl_produced_qty
                                      : `Produced Qty`}
                                  </h6>
                                  <h6 className="roboto-bold">
                                    {item.produced_quantity !== "" &&
                                    item.produced_quantity !== null
                                      ? item.produced_quantity
                                      : "NA"}
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="bg-lightgrey py-1 m-2">
                            <div className="d-flex overflow-auto">
                              {item.job_cards.length > 0
                                ? item.job_cards?.map((val, i) => (
                                    <div
                                      key={i}
                                      className="card col-12 col-md-3 m-2"
                                      style={{ minWidth: "18rem" }}
                                    >
                                      <div className="card-body p-2">
                                        <div className="d-flex justify-content-between">
                                          <a
                                            className="roboto-bold cursor_pointer mb-2"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              setVisible(true);
                                              setSelItem(val);
                                            }}
                                          >
                                            {val.job_card_no}
                                          </a>
                                          <FontAwesomeIcon
                                            icon={faCircle}
                                            size={18}
                                            className={`${
                                              val.efficiency >= 91
                                                ? "ind_green"
                                                : val.efficiency >= 81 &&
                                                  val.efficiency <= 90
                                                ? "ind_orange"
                                                : "ind_red"
                                            } cursor_pointer`}
                                            data-tooltip-id={
                                              "indicator-tooltip" + idx + i
                                            }
                                          />
                                          <Tooltip
                                            id={"indicator-tooltip" + idx + i}
                                            place="left"
                                          >
                                            <div className="bold">
                                              Good -{" "}
                                              <FontAwesomeIcon
                                                icon={faCircle}
                                                size={12}
                                                className="ind_green"
                                              />{" "}
                                              - 91 and above
                                            </div>
                                            <div className="bold">
                                              Avg -{" "}
                                              <FontAwesomeIcon
                                                icon={faCircle}
                                                size={12}
                                                className="ind_orange"
                                              />{" "}
                                              - 81 to 90
                                            </div>
                                            <div className="bold">
                                              Bad -{" "}
                                              <FontAwesomeIcon
                                                icon={faCircle}
                                                size={12}
                                                className="ind_red"
                                              />{" "}
                                              - 80 and below
                                            </div>
                                          </Tooltip>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                          <h6 className="card-subtitle mb-2 text-muted">
                                            {formatDate(val.start_time)}
                                          </h6>
                                          <Tooltip
                                            id={"view-tooltip" + idx + i}
                                            place="left"
                                          />
                                          <span
                                            className={`badge rounded-pill ${getBadgeClass(
                                              val.status
                                            )}`}
                                            data-tooltip-id={
                                              "view-tooltip" + idx + i
                                            }
                                            data-tooltip-content={
                                              "Job Card Status"
                                            }
                                          >
                                            {val.status}
                                          </span>
                                        </div>
                                        <hr />
                                        <div className="container">
                                          <div className="row text-center">
                                            <div className="col-12 col-md-6 mb-2">
                                              <div className="bg-orange-light p-1">
                                                <p className="body1 text-muted mb-0">
                                                  {constLabel?.lbl_planned_qty
                                                    ? constLabel?.lbl_planned_qty
                                                    : `Planned Qty`}
                                                </p>
                                                <p className="body1 roboto-bold mb-0">
                                                  {val.planned_qty !== "" &&
                                                  val.planned_qty !== null
                                                    ? val.planned_qty
                                                    : "NA"}
                                                </p>
                                              </div>
                                            </div>
                                            <div className="col-12 col-md-6 mb-2">
                                              <div
                                                className={`bg-green-light p-1`}
                                              >
                                                <p className="body1 text-muted mb-0">
                                                  {constLabel?.lbl_actual_qty
                                                    ? constLabel.lbl_actual_qty
                                                    : `Actual Qty`}
                                                </p>
                                                <p className="body1 roboto-bold mb-0">
                                                  {val.actual_qty !== "" &&
                                                  val.actual_qty !== null
                                                    ? val.actual_qty
                                                    : "NA"}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="row text-center">
                                            <div className="col-12 col-md-6 mb-2">
                                              <div className="bg-orange-light p-1">
                                                <p className="body1 text-muted mb-0">
                                                  {constLabel?.lbl_planned_CT
                                                    ? constLabel?.lbl_planned_CT
                                                    : `Planned CT`}
                                                </p>
                                                <p className="body1 roboto-bold mb-0">
                                                  {val.cycle_time !== "" &&
                                                  val.cycle_time !== null
                                                    ? val.cycle_time
                                                    : "NA"}{" "}
                                                  {"mins"}
                                                </p>
                                              </div>
                                            </div>
                                            <div className="col-12 col-md-6 mb-2">
                                              <div className="bg-orange-dark p-1">
                                                <p className="body1 text-muted mb-0">
                                                  {constLabel?.lbl_actual_CT
                                                    ? constLabel.lbl_actual_CT
                                                    : `Actual CT`}
                                                </p>
                                                <p className="body1 roboto-bold mb-0">
                                                  {val.actual_cycle_time !==
                                                    "" &&
                                                  val.actual_cycle_time !== null
                                                    ? val.actual_cycle_time
                                                    : "NA"}{" "}
                                                  {"mins"}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))
                                : null}
                            </div>
                          </div>
                        </>
                      ) : null
                    )}
                </div>
              ) : (
                <div
                  className="alert alert-danger mb-0 text-center"
                  role="alert"
                >
                  {`Operations and Job Cards not found for this Work Order - ${getShortReadableId(
                    workOrderNum
                  )} !`}
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </React.Fragment>
  );
}

export default JobCardBoard;
