import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { getLabels } from "../../../common/Functions/CommonFunctions";
import PIWithPO from "./PIWithPO";
import PIWithoutPO from "./PIWithoutPO";

function CreatePurchaseInv() {
  const [constLabel, setConstLabel] = useState({});
  const [isPO, setIsPO] = useState(true);

  // when component load then run below useEffect and call the fuctions inside useEffeact
  useEffect(() => {
    setConstLabel(getLabels("Purchase", "Purchase Inward"));
  }, []);

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="purchase_inward">
        <div className="row">
          <div className="d-flex justify-content-between mb-2">
            <h1 className="bold">
              {constLabel?.lbl_create_purchase_inward
                ? constLabel.lbl_create_purchase_inward
                : "Create Purchase Inward"}
            </h1>
          </div>
          <div className="d-flex mb-2">
            {/* <div
              className="form-check form-switch"
              style={{ paddingLeft: "3rem" }}
            >
              <label
                className="form-check-label lbl-style roboto-bold"
                htmlFor="flexSwitchCheckChecked"
              >
                {"With PO / Without PO"}
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                id="flexSwitchCheckChecked"
                checked={isPO}
                onChange={() => {
                  setIsPO(!isPO);
                }}
              />
            </div> */}

            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="poOption"
                id="againstPO"
                value="againstPO"
                checked={isPO === false}
                onChange={() => setIsPO(false)}
              />
              <label
                className="form-check-label lbl-style roboto-bold"
                htmlFor="againstPO"
              >
                {"Against PO"}
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="poOption"
                id="withoutPO"
                value="withoutPO"
                checked={isPO === true}
                onChange={() => setIsPO(true)}
              />
              <label
                className="form-check-label lbl-style roboto-bold"
                htmlFor="withoutPO"
              >
                {"Without PO"}
              </label>
            </div>
          </div>
          {
            isPO === false ? (
              <PIWithPO />
            ) : (
              // <div
              //   className="alert alert-danger mb-0 text-center my-2"
              //   role="alert"
              // >
              //   {"Page under maintenance!"}
              // </div>
              <PIWithoutPO />
            )
            
          }
        </div>
      </div>
    </React.Fragment>
  );
}

export default CreatePurchaseInv;
