import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";

import {
  getLabels,
} from "../../../common/Functions/CommonFunctions";
import { useLocation } from "react-router-dom";
import EditPIWithPO from "./EditPIWithPO";
import EditPIWithoutPO from "./EditPIWithoutPO";

function EditPurchaseInv() {
  let location = useLocation();
  const [isPO, setIsPO] = useState(location.state?.po_id_id ? false : true);
  const [constLabel, setConstLabel] = useState({});

  useEffect(() => {
    // console.log("location.state", location.state);
    setConstLabel(getLabels("Purchase", "Purchase Inward"));
  }, []);

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="purchase_inward">
        <div className="row">
          <div className="d-flex justify-content-between mb-2">
            <h1 className="bold">
              {constLabel?.lbl_update_purchase_inward
                ? constLabel.lbl_update_purchase_inward
                : "Update Purchase Inward"}
            </h1>
          </div>
          <div className="d-flex mb-2">
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="poOption"
                id="againstPO"
                value="againstPO"
                checked={isPO === false}
                onChange={() => setIsPO(false)}
                disabled
              />
              <label
                className="form-check-label lbl-style roboto-bold"
                htmlFor="againstPO"
              >
                {"Against PO"}
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="poOption"
                id="withoutPO"
                value="withoutPO"
                checked={isPO === true}
                onChange={() => setIsPO(true)}
                disabled
              />
              <label
                className="form-check-label lbl-style roboto-bold"
                htmlFor="withoutPO"
              >
                {"Without PO"}
              </label>
            </div>
          </div>
          {isPO === false ? 
            <EditPIWithPO location={{state : location.state}}/> : 
            // <div
            // className="alert alert-danger mb-0 text-center my-2"
            // role="alert"
            // >
            //   {"Page under maintenance!"}
            // </div>
            <EditPIWithoutPO location={{state : location.state}} />
          }
        </div>
      </div>
    </React.Fragment> 
  );
}

export default EditPurchaseInv;
