import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Pagination, Modal } from "react-bootstrap";
import { Edit, Trash2 } from "react-feather";
import SweetAlert from "sweetalert2";
import { Tooltip } from "react-tooltip";
import {
  delete_payment_receipt,
  get_payment_receipt,
  getInternalCompany,
  update_payment_receipt,
} from "../../../api";
import {
  calMaxPage_new,
  convertDateYYMMDD,
  formatDate5,
  getLabels,
  setAccessPrivilage,
} from "../../../common/Functions/CommonFunctions";
import { useSelector } from "react-redux";
import List from "../../../assets/images/icons/project_menu/checklist.png";
import DatePicker from "react-datepicker";
import AccessDenied from "../../Common/AccessDenied";
import { Payment_Receipts_Col_Name } from "../../../common/Labels/labelConstant";

function PaymentReceipts() {
  const [showLabel, setShowLabel] = useState(Payment_Receipts_Col_Name);
  const [visible, setVisible] = useState(false);
  const [OUTSTANDING_AMOUNT, setOUTSTANDING_AMOUNT] = useState(0);
  const [data_for_edit, setdata_for_edit] = useState({});

  const [page, setPage] = useState(1);
  const [srNo, setSrNo] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [dataList, setDataList] = useState({
    data: [],
    loading: true,
    totalRecords: "",
  });
  const [sea_val, setSea_val] = useState(false);

  const [filterConfig, setFilterConfig] = useState({ searchVal: "" });
  const [constLabel, setConstLabel] = useState({});
  const [loc_list, setLoc_List] = useState({ data: [], loading: false });
  const [discountType, setDiscountType] = useState("amount");

  const loginData = useSelector(
    (state) => state.auth?.loginData?.data?.response
  );

  const [privilage, setPrivilage] = useState({
    read: true,
    write: true,
    view: true,
    delete: true,
    report: true,
    no_access: false,
  });

  const schema = yup
    .object()
    .shape({
      payment_date: yup
        .date()
        .required("Please select invoice date !")
        .typeError("Please select invoice date !"),
      location: yup
        .array()
        .min(1, "Please select location !")
        .required("Please select location !"),
      mode_of_payment: yup
        .array()
        .min(1, "Please select mode of payment !")
        .required("Please select mode of payment !"),
      discount_amount: yup
        .number()
        .typeError("Enter a valid discount amount!")
        .min(0, "Discount cannot be negative!")
        .max(OUTSTANDING_AMOUNT, "Discount cannot exceed outstanding amount!")
        .when("discountType", {
          is: "amount",
          then: (schema) => schema.required("Discount amount is required!"),
          otherwise: (schema) => schema.notRequired(),
        }),
      discount_percentage: yup
        .number()
        .typeError("Enter a valid discount percentage!")
        .min(0, "Discount percentage cannot be negative!")
        .max(100, "Discount percentage cannot exceed 100%!")
        .when("discountType", {
          is: "percentage",
          then: (schema) => schema.required("Discount percentage is required!"),
          otherwise: (schema) => schema.notRequired(),
        }),
      amount: yup
        .number()
        .typeError("Enter a valid amount!")
        .required("Amount is required!")
        .positive("Amount must be greater than zero!")
        .test(
          "maxAmount",
          "Amount cannot exceed available outstanding amount!",
          function (value) {
            const discount = this.parent.discount_amount || 0;
            const availableAmount = OUTSTANDING_AMOUNT - discount;
            return value <= availableAmount;
          }
        ),
    })
    .required();
  const {
    register,
    handleSubmit,
    formState,
    control,
    reset,
    watch,
    setValue,
    clearErrors,
    setError,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook

  let { errors } = formState;
  const discountAmount = watch("discount_amount") || 0;
  const discountPercentage = watch("discount_percentage") || 0;
  const amount = watch("amount") || 0;

  const useDebounce = (value, delay = 300) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => clearTimeout(handler);
    }, [value, delay]);

    return debouncedValue;
  };

  // Debounced user inputs
  const debouncedDiscountAmount = useDebounce(discountAmount, 300);
  const debouncedDiscountPercentage = useDebounce(discountPercentage, 300);
  const debouncedAmount = useDebounce(amount, 300);

  useEffect(() => {
    if (!OUTSTANDING_AMOUNT) return;

    if (discountType === "amount") {
      const calculatedPercentage = (debouncedDiscountAmount / OUTSTANDING_AMOUNT) * 100;
      setValue("discount_percentage", parseFloat(calculatedPercentage.toFixed(2)) || 0);
    } else if (discountType === "percentage") {
      const calculatedAmount = (debouncedDiscountPercentage / 100) * OUTSTANDING_AMOUNT;
      setValue("discount_amount", parseFloat(calculatedAmount.toFixed(2)) || 0);
    }
  }, [debouncedDiscountAmount, debouncedDiscountPercentage, discountType, OUTSTANDING_AMOUNT, setValue]);

  useEffect(() => {
    if (!OUTSTANDING_AMOUNT) return;

    const discountedAmount = Math.max(OUTSTANDING_AMOUNT - debouncedDiscountAmount, 0);
    setValue("pending_amount", discountedAmount);
  }, [debouncedDiscountAmount, OUTSTANDING_AMOUNT, setValue]);

  useEffect(() => {
    if (!OUTSTANDING_AMOUNT) return;
  
    const discountedAmount = Math.max(OUTSTANDING_AMOUNT - debouncedDiscountAmount, 0);
    const pendingAmount = Math.max(discountedAmount - debouncedAmount, 0);
  
    setValue("pending_amount", pendingAmount);
  }, [debouncedDiscountAmount, debouncedAmount, OUTSTANDING_AMOUNT, setValue]);

  useEffect(() => {
    setConstLabel(getLabels("Sales Order", "Payment Receipt"));

    setPrivilage(setAccessPrivilage(loginData, "Payment Receipt"));
    fetchInternal_company();
    const debounceTimeout = setTimeout(() => {
      setPage(1);
      fetchPaymentReceipt(page, entriesPerPage);
    }, 1000); // Adjust the delay as needed (e.g., 500 milliseconds)

    return () => {
      clearTimeout(debounceTimeout); // Clear the timeout if searchTerm changes before the timeout expires
    };
  }, [filterConfig]);

  function clearFilter() {
    setFilterConfig({ ...filterConfig, searchVal: "" });
    setSrNo(0);
    setPage(1);
  }

  function fetchInternal_company() {
    setLoc_List({ data: [], loading: true });

    getInternalCompany({ with_location: 1 })
      .then((res) => {
        // console.log("res", res);
        const ress = res.data.data.filter((item) => item.default_org == true);
        // console.log("ress", ress);
        if (ress.length > 0) {
          const loc = ress?.[0]?.location_list;
          setLoc_List({ data: loc, loading: false });
        } else {
          toast.success("Default organization not found !", {
            autoClose: 3000,
          });
        }
      })
      .catch((err) => {
        setLoc_List({ data: [], loading: false });
      });
  }

  function fetchPaymentReceipt(pg, epp) {
    setDataList({ ...dataList, loading: true, data: [] });

    const payload = {
      pageNo: pg,
      entriesPerPage: epp,
    };

    if (filterConfig.searchVal !== "") {
      payload.searchVal = filterConfig.searchVal;

      if (
        filterConfig.searchVal?.length > 0 &&
        page !== 1 &&
        sea_val == false
      ) {
        payload.pageNo = 1;
        payload.entriesPerPage = entriesPerPage;

        setPage(1);
        setSrNo(0);
      }
    } else {
      delete payload.searchVal;
    }

    get_payment_receipt(payload).then(
      (res) => {
        // console.log("res shift", res);
        setDataList({
          ...dataList,
          loading: false,
          data: res.data.data,
          totalRecords: res.data.total,
        });
        if (filterConfig.search !== "" && filterConfig.searchVal?.length > 0) {
          setSea_val(true);
        } else {
          setSea_val(false);
        }
        setMaxPage(calMaxPage_new(res.data.total, epp));
        if (pg == 1) {
          setSrNo(0);
          setPage(1);
        }
      },
      (err) => {
        setDataList({ ...dataList, loading: false, data: [] });
      }
    );
  }

  const onSubmit = (data) => {
    const payload = {
      uuid:data_for_edit.uuid,
      performa_invoice_id: data_for_edit.performa_invoice_id,
      location_id: data.location[0].uuid,
      party_id: data_for_edit.party_id,
      amount: data.amount,
      discount_amount: data.discount_amount,
      discount_percentage: data.discount_amount == 0 ? 0 : data.discount_percentage,
      payment_date: convertDateYYMMDD(data.payment_date, "-"),
      pending_amount: data.pending_amount,
      mode_of_payment: data.mode_of_payment[0].name,
    };
    // console.log("payload", payload);

    update_payment_receipt(payload)
      .then((res) => {
        // console.log(res.data);

        setVisible(false);
        reset()
        toast.success("Payment receipt updated successfully !", {
          autoClose: 2000,
        });
        fetchPaymentReceipt(page,entriesPerPage)
      })
      .catch((err) => {
        console.log("err", err);

        toast.success("Something went wrong !", {
          autoClose: 2000,
        });
      });
  };

  // Deleting payment receipt
  const delPaymentReceipt = (val) => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        const payload = {
          uuid: val.uuid,
        };
        delete_payment_receipt(payload).then(
          (res) => {
            toast.success("Payment Receipt deleted successfully !", {
              autoClose: 3000,
            });
            if (dataList.data.length == 1 && page > 1) {
              fetchPaymentReceipt(page - 1, entriesPerPage);
              setSrNo(parseInt(srNo - entriesPerPage));
              setPage(page - 1);
            } else {
              fetchPaymentReceipt(page, entriesPerPage);
            }
          },
          (err) => {
            toast.error("Something went wrong !", { autoClose: 3000 });
          }
        );
      }
    });
  };

  function handleClick(ind) {
    setShowLabel((showLabel) => {
      const updatedCardShow = showLabel.map((val, i) => {
        if (ind === i) {
          // Toggle the flag for the clicked checkbox
          return { ...val, flag: !val.flag };
        }
        return val;
      });

      return updatedCardShow;
    });
  }

  const handleEdit = (val) => {
    setdata_for_edit(val);
    reset({
      payment_date: val.payment_date ? new Date(val.payment_date) : null,
      mode_of_payment: val.mode_of_payment ? [{ name: val.mode_of_payment }] : [],
      location: val.location_id
        ? loc_list.data.filter((ele) => ele.uuid === val.location_id)
        : [],
      amount: val.amount ? Number(val.amount) : 0,
      discount_amount: val.discount_amount ? Number(val.discount_amount) : 0,
      discount_percentage: val.discount_percentage ? Number(val.discount_percentage) : 0,
      pending_amount: val.pending_amount ? Number(val.pending_amount) : 0,
    });
  
    // Calculate Outstanding Amount only if necessary
    const updatedOutstanding = 
      Number(val.pending_amount || 0) + 
      Number(val.amount || 0) + 
      Number(val.discount_amount || 0);
  
    if (OUTSTANDING_AMOUNT !== updatedOutstanding) {
      setOUTSTANDING_AMOUNT(updatedOutstanding);
    }
  
    setVisible(true);
  };


  return (
    <React.Fragment>
      <ToastContainer />
      {!privilage.read ? (
        <AccessDenied />
      ) : (
        <>
          {/* Add Item Modal*/}
          <div className="min-vh-100" id="root_div_main">
            <Modal show={visible} onHide={() => setVisible(false)} size={"lg"}>
              <Modal.Header
                style={{ background: "#2A3643", color: "white" }}
                closeButton
              >
                <Modal.Title>
                  {constLabel?.lbl_payment_receipt
                    ? constLabel.lbl_payment_receipt
                    : "Edit Payment Receipt"}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form action="">
                  <div className="row mt-2 col-12">
                    <div className="col-md-6 mt-3">
                      <div className="form-group mt-md-0 mt-3">
                        <label className="lbl-style roboto-bold">
                          {constLabel?.lbl_payment_date
                            ? constLabel.lbl_payment_date
                            : "Invoice Date"}
                          <span className="text-danger"> *</span>
                        </label>

                        <Controller
                          name="payment_date"
                          control={control}
                          render={({ field }) => (
                            <DatePicker
                              className={
                                errors.payment_date
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                              {...field}
                              showYearDropdown
                              scrollableMonthYearDropdown
                              dateFormat="dd/MM/yyyy"
                              selected={field.value}
                              maxDate={new Date()}
                              placeholderText="Select a invoice date"
                              autoComplete="off"
                            />
                          )}
                        />
                        {errors.payment_date && (
                          <span className="text-danger err-msg">
                            {errors.payment_date.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className=" col-md-6 mt-3">
                      <div className="form-group typeahead-container">
                        <label className="lbl-style roboto-bold">
                          {constLabel?.lbl_location
                            ? constLabel.lbl_location
                            : "Location"}
                          {""}
                          <span className="text-danger"> *</span>
                        </label>
                        <Controller
                          name={`location`}
                          control={control}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <Typeahead
                              id="basic-typeahead-single"
                              labelKey="locationname"
                              multiple={false}
                              options={loc_list.data}
                              isLoading={loc_list.loading}
                              placeholder="Select location"
                              onChange={onChange}
                              onBlur={onBlur}
                              selected={value}
                              isInvalid={errors.location ? true : false}
                            />
                          )}
                        />

                        {errors.location && (
                          <span className="text-danger err-msg">
                            {errors.location.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className=" col-md-6 mt-3">
                      <div className="form-group typeahead-container">
                        <label className="lbl-style roboto-bold">
                          {constLabel?.lbl_mode_of_payment
                            ? constLabel.lbl_mode_of_payment
                            : "Mode of Payment"}
                          {""}
                          <span className="text-danger"> *</span>
                        </label>
                        <Controller
                          name={`mode_of_payment`}
                          control={control}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <Typeahead
                              id="basic-typeahead-single"
                              labelKey="name"
                              multiple={false}
                              options={[
                                { name: "Cash" },
                                { name: "Online" },
                                { name: "Cheque" },
                              ]}
                              placeholder="Select mode of payment"
                              onChange={onChange}
                              onBlur={onBlur}
                              selected={value}
                              isInvalid={errors.mode_of_payment ? true : false}
                            />
                          )}
                        />

                        {errors.mode_of_payment && (
                          <span className="text-danger err-msg">
                            {errors.mode_of_payment.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 mt-3">
                      <div className="form-group typeahead-container">
                        <label className="lbl-style roboto-bold">
                          Discounted Outstanding Amount
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          value={OUTSTANDING_AMOUNT - discountAmount}
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mt-3">
                      <label className="lbl-style roboto-bold">
                        Discount By:
                      </label>
                      <div>
                        <input
                          type="radio"
                          id="discountAmount"
                          name="discountType"
                          value="amount"
                          checked={discountType === "amount"}
                          onChange={() => setDiscountType("amount")}
                        />
                        <label htmlFor="discountAmount" className="ml-2">
                          Amount
                        </label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="discountPercentage"
                          name="discountType"
                          value="percentage"
                          checked={discountType === "percentage"}
                          onChange={() => setDiscountType("percentage")}
                        />
                        <label htmlFor="discountPercentage" className="ml-2">
                          Percentage
                        </label>
                      </div>
                    </div>

                    {discountType === "amount" && (
                      <div className="col-md-6 mt-3">
                        <label className="lbl-style roboto-bold">
                          Discount Amount
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          defaultValue={data_for_edit.discount_amount&&data_for_edit.discount_amount}
                          {...register("discount_amount")}
                          // {...register("discount_amount", {
                          //   onChange: (e) => debouncedDiscountAmount(Number(e.target.value) || 0),
                          // })}
                        />
                        {errors.discount_amount && (
                          <span className="text-danger">
                            {errors.discount_amount.message}
                          </span>
                        )}
                      </div>
                    )}

                    {discountType === "percentage" && (
                      <div className="col-md-6 mt-3">
                        <label className="lbl-style roboto-bold">
                          Discount Percentage
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          defaultValue={data_for_edit.discount_percentage && data_for_edit.discount_percentage}
                          {...register("discount_percentage")}

                          // {...register("discount_percentage", {
                          //   onChange: (e) => debouncedDiscountPercentage(Number(e.target.value) || 0),
                          // })}
                        />
                        {errors.discount_percentage && (
                          <span className="text-danger">
                            {errors.discount_percentage.message}
                          </span>
                        )}
                      </div>
                    )}

                    <div className="col-md-6 mt-3">
                      <div className="form-group typeahead-container">
                        <label className="lbl-style roboto-bold">Amount</label>
                        <span className="text-danger"> *</span>
                        <input
                          type="number"
                          name="amount"
                          className="form-control"
                          placeholder="Enter amount"
                          {...register("amount")}
                          // {...register("amount", {
                          //   onChange: (e) => debouncedAmount(Number(e.target.value) || 0),
                          // })}
                        />
                        {errors.amount && (
                          <span className="text-danger err-msg">
                            {errors.amount.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6 mt-3">
                      <div className="form-group typeahead-container">
                        <label className="lbl-style roboto-bold">
                          Pending Amount
                        </label>
                        <input
                          type="number"
                          name="pending_amount"
                          className="form-control"
                          placeholder="Pending amount"
                          disabled={true}
                          {...register("pending_amount")}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </Modal.Body>
              <Modal.Footer>
                <button
                  onClick={handleSubmit(onSubmit)}
                  className="f-16 btn btn-yellow "
                >
                  {constLabel?.lbl_save ? constLabel.lbl_save : "Save"}
                </button>
              </Modal.Footer>
            </Modal>

            <div className="content-wrapper">
              <div className="card border-0">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="d-md-flex justify-content-start align-items-center col-12 col-md-6">
                      {/* <FontAwesomeIcon
                    onClick={() => toggleNav()}
                    className="cursor_pointer mb-2"
                    style={{
                      color: "Black",
                      marginRight: "10px",
                    }}
                    icon={faBars}
                    size="lg"
                  /> */}
                      <h1 className="bold">
                        {constLabel?.lbl_payment_receipt
                          ? constLabel?.lbl_payment_receipt
                          : "Payment Receipt"}
                      </h1>
                    </div>
                    <div className="d-md-flex justify-content-end align-items-center col-12 col-md-6">
                      {/* <div className="col-12 col-md-6 me-md-2 me-md-0">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Search by name"
                          value={filterConfig.searchVal}
                          onChange={(val) => {
                            const inputValue = val.target.value;
                            if (!inputValue.trim()) {
                              // Input contains only whitespace, don't trigger the search.
                              if (filterConfig.searchVal.length == 1) {
                                setFilterConfig({
                                  ...filterConfig,
                                  searchVal: "",
                                });
                              }

                              if (inputValue.length == 0) {
                                setFilterConfig({
                                  ...filterConfig,
                                  searchVal: "",
                                });
                              }
                            } else {
                              setFilterConfig({
                                ...filterConfig,
                                searchVal: inputValue,
                              });
                            }
                          }}
                        />
                      </div> */}

                      <Tooltip id={"show-tooltip"} place="top" />
                      <div
                        className="dropdown"
                        data-tooltip-id={"show-tooltip"}
                        data-tooltip-content={"Show / Hide Columns"}
                      >
                        <div
                          className="d-md-flex justify-content-start align-items-center"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {/* <FontAwesomeIcon icon={faCog} size="lg" /> */}
                          {/* <List size={24} className="menu_icon_grey cursor_pointer"/> */}
                          <img
                            src={List}
                            height="32"
                            width="32"
                            className="cursor_pointer"
                          />
                        </div>

                        <ul className="dropdown-menu px-2">
                          {showLabel !== undefined && showLabel.length > 0
                            ? showLabel.map((val, ind, arr) => (
                                <li key={ind}>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      style={{ width: "20px", height: "20px" }}
                                      onChange={() => handleClick(ind)}
                                      value=""
                                      checked={val.flag}
                                    />
                                    <label
                                      className=""
                                      style={{ fontSize: "13px" }}
                                      htmlFor="flexCheckDefault"
                                    >
                                      {val.label}
                                    </label>
                                  </div>
                                </li>
                              ))
                            : null}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="mt-3">
                    {dataList.loading ? (
                      <div className="text-center">
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    ) : dataList && dataList.data?.length > 0 ? (
                      <>
                        <div className="table-responsive">
                          <table className="table table-bordered">
                            <thead className="table-grey roboto">
                              <tr className="f-14 ">
                                <th scope="col">{"Sr.No"}</th>
                                {showLabel.map((v, i) =>
                                  v.label === "SO ID" && v.flag ? (
                                    <th key={i} scope="col">
                                      {constLabel?.lbl_so_id
                                        ? constLabel.lbl_so_id
                                        : "SO ID"}
                                    </th>
                                  ) : v.label === "Proforma Invoice Id" &&
                                    v.flag ? (
                                    <th key={i} scope="col">
                                      {constLabel?.lbl_proforma_invoice_id
                                        ? constLabel.lbl_proforma_invoice_id
                                        : "Proforma Invoice Id"}
                                    </th>
                                  ) : v.label === "Payment Date" && v.flag ? (
                                    <th key={i} scope="col">
                                      {constLabel?.lbl_payment_date
                                        ? constLabel.lbl_payment_date
                                        : "Payment Date"}
                                    </th>
                                  ) : v.label === "Mode Of Payment" &&
                                    v.flag ? (
                                    <th key={i} scope="col">
                                      {constLabel?.lbl_payment_date
                                        ? constLabel.lbl_payment_date
                                        : "Mode Of Payment"}
                                    </th>
                                  ) : v.label === "Location" && v.flag ? (
                                    <th key={i} scope="col">
                                      {constLabel?.lbl_location
                                        ? constLabel.lbl_location
                                        : "Location"}
                                    </th>
                                  ) : v.label === "Amount ₹" && v.flag ? (
                                    <th key={i} scope="col">
                                      {constLabel?.lbl_amount
                                        ? constLabel.lbl_amount
                                        : "Amount ₹"}
                                    </th>
                                  ) : v.label === "Discount ₹" && v.flag ? (
                                    <th key={i} scope="col">
                                      {constLabel?.lbl_payment_date
                                        ? constLabel.lbl_payment_date
                                        : "Discount ₹"}
                                    </th>
                                  ) : v.label === "Pending Amount ₹" &&
                                    v.flag ? (
                                    <th key={i} scope="col">
                                      {constLabel?.lbl_payment_date
                                        ? constLabel.lbl_payment_date
                                        : "Pending Amount ₹"}
                                    </th>
                                  ) : null
                                )}
                                <th scope="col">{"Action"}</th>
                              </tr>
                            </thead>
                            <tbody className="roboto">
                              {dataList.data?.map((val, i) => (
                                <tr key={i} className="">
                                  <td>{i + parseInt(srNo) + 1}</td>
                                  {showLabel.map((item, idx) =>
                                    item.label === "SO ID" && item.flag ? (
                                      <td key={idx}>
                                        {val?.sales_order_info?.so_id &&
                                        val?.sales_order_info?.so_id !== null
                                          ? val?.sales_order_info?.so_id
                                          : "NA"}
                                      </td>
                                    ) : item.label === "Proforma Invoice Id" &&
                                      item.flag ? (
                                      <td key={idx}>
                                        {val?.invoice_performa_info
                                          ?.invoice_id &&
                                        val?.invoice_performa_info
                                          ?.invoice_id !== null
                                          ? val?.invoice_performa_info
                                              ?.invoice_id
                                          : "NA"}
                                      </td>
                                    ) : item.label === "Payment Date" &&
                                      item.flag ? (
                                      <td key={idx}>
                                        {val.payment_date != "" &&
                                        val.payment_date !== null
                                          ? formatDate5(val.payment_date)
                                          : "NA"}
                                      </td>
                                    ) : item.label === "Mode Of Payment" &&
                                      item.flag ? (
                                      <td key={idx}>
                                        {val.mode_of_payment &&
                                        val.mode_of_payment !== null
                                          ? val.mode_of_payment
                                          : "NA"}
                                      </td>
                                    ) : item.label === "Location" &&
                                      item.flag ? (
                                      <td key={idx}>
                                        {val.payment_receipt_location && val.payment_receipt_location !== null
                                          ? val.payment_receipt_location?.locationname
                                          : "NA"}
                                      </td>
                                    ) : item.label === "Amount ₹" &&
                                      item.flag ? (
                                      <td key={idx}>
                                        {val.amount != "" && val.amount !== null
                                          ? val.amount
                                          : "NA"}
                                      </td>
                                    ) : item.label === "Discount ₹" &&
                                      item.flag ? (
                                      <td key={idx}>
                                        {val.discount_amount != "" &&
                                        val.discount_amount !== null
                                          ? val.discount_amount
                                          : "NA"}
                                      </td>
                                    ) : item.label === "Pending Amount ₹" &&
                                      item.flag ? (
                                      <td key={idx}>
                                        {val.pending_amount != "" &&
                                        val.pending_amount !== null
                                          ? val.pending_amount
                                          : "NA"}
                                      </td>
                                    ) : null
                                  )}
                                  <td>
                                    {privilage.write && (
                                      <>
                                        <Tooltip
                                          id={"delete-tooltip" + i}
                                          place="top"
                                        />
                                        <Edit
                                          data-tooltip-id={"delete-tooltip" + i}
                                          data-tooltip-content={
                                            constLabel?.lbl_edit_payment_receipt
                                              ? constLabel.lbl_edit_payment_receipt
                                              : "Edit Payment Receipt"
                                          }
                                          className="menu_icon_grey cursor_pointer"
                                          size={20}
                                          onClick={() => {
                                            handleEdit(val);
                                          }}
                                        />
                                      </>
                                    )}

                                    {privilage.delete && (
                                      <>
                                        <Tooltip
                                          id={"delete-tooltip" + i}
                                          place="top"
                                        />
                                        <Trash2
                                          data-tooltip-id={"delete-tooltip" + i}
                                          data-tooltip-content={
                                            constLabel?.lbl_delete_payment_receipt
                                              ? constLabel.lbl_delete_payment_receipt
                                              : "Delete Payment Receipt"
                                          }
                                          className="menu_icon_red cursor_pointer"
                                          size={20}
                                          onClick={() => delPaymentReceipt(val)}
                                        />
                                      </>
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <p className="mb-0">
                            Show
                            <select
                              className="mx-1"
                              defaultValue={entriesPerPage}
                              onChange={(e) => {
                                setEntriesPerPage(e.target.value);
                                fetchPaymentReceipt(1, e.target.value);
                              }}
                            >
                              <option value={"10"}>10</option>
                              <option value="25">25</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                            </select>
                            Entries
                          </p>
                          <p className="mb-0">{`Showing ${
                            parseInt(srNo) + 1
                          } to ${Math.min(
                            parseInt(entriesPerPage) + parseInt(srNo),
                            dataList.totalRecords
                          )} of ${dataList.totalRecords} entries`}</p>
                          <Pagination className="my-2">
                            <Pagination.Prev
                              disabled={page === 1 ? true : false}
                              onClick={() => {
                                fetchPaymentReceipt(
                                  page - 1,
                                  entriesPerPage,
                                  parseInt(srNo) - parseInt(entriesPerPage)
                                );
                                setSrNo((prevC) =>
                                  page - 1 == 1
                                    ? 0
                                    : prevC - parseInt(entriesPerPage)
                                );
                                setPage(page - 1);
                              }}
                            >
                              {"Prev"}
                            </Pagination.Prev>

                            <Pagination.Item active>{page}</Pagination.Item>

                            <Pagination.Next
                              disabled={
                                page === maxPage ||
                                maxPage === 0 ||
                                entriesPerPage > dataList.data.length
                                  ? true
                                  : false
                              }
                              onClick={() => {
                                fetchPaymentReceipt(
                                  page + 1,
                                  entriesPerPage,
                                  parseInt(srNo) + parseInt(entriesPerPage)
                                );
                                setSrNo(
                                  (prevC) => prevC + parseInt(entriesPerPage)
                                );
                                setPage(page + 1);
                              }}
                            >
                              {"Next"}
                            </Pagination.Next>
                          </Pagination>
                        </div>
                      </>
                    ) : (
                      <p className="text-danger mb-0 f-22 text-center mt-4">
                        {"Payment Receipt Not Found !"}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </React.Fragment>
  );
}

export default PaymentReceipts;
