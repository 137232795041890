import React, { useState, useEffect } from 'react'
import { fetch_prod_wise_bom } from "../../../api/index";
import { toast } from "react-toastify";
import { convertDate, formatDateString, getLabels, call_sweet_alert } from "../../../common/Functions/CommonFunctions";
import refresh from "../../../assets/images/refresh.png";
import { Typeahead } from "react-bootstrap-typeahead";
import { fetch_multi_prod_stock, getBOMListBYUUID } from "../../../api/index";
import { useSelector, useDispatch } from 'react-redux';
import { updateStepTwo } from '../../../slices/production_planning/production_plan_slice';
import { saveStepTwo } from '../../../slices/production_planning/production_plan_slice2';
import { Link } from "react-router-dom"
import CreateBom from "./CreateBom";
import AddProduct from './AddProduct';
import { Trash2, } from "react-feather";
import { Tooltip } from "react-tooltip";
import SweetAlert from "sweetalert2";
import { useMemo } from 'react';
import { FASHION, APPAREL } from "../../../common/Constants/constants"
import { Spinner } from "react-bootstrap";

function StepTwo(props) {
    const { nextStep, prevStep, prev_data } = props;

    const dispatch = useDispatch();
    const StepOneSavedData = useSelector((state) => state.steps.StepOne);
    const StepTwoSavedData = useSelector((state) => state.steps.StepTwo);
    const { data: productionplanDetails, status, error } = useSelector((state) => state.prod_plan_details);

    const [consolidate, setConsolidate] = useState(false);
    const [ignoreQty, setIgnoreQty] = useState(false);

    const [constLabel, setConstLabel] = useState({});
    const localData = JSON.parse(localStorage.getItem("localData"));


    const [prodListWithBom, setProdListWithBom] = useState({ loading: false, data: [], });
    const [unConsolidated_prodListWithBom, setunConsolidated_prodListWithBom] =
        useState({ loading: false, data: [], });


    const [subprodListWithBom, setSubProdListWithBom] = useState({ loading: false, data: [], firstTime: false });
    const [disableBtn, setDisableBtn] = useState({ state: false, msg: "" });

    const [modalData, setModalData] = useState({ state: false, data: null, ind: null });
    const [visible, setVisible] = useState({ state: false, data: [] });
    const [deletedProdList, setDeletedProdList] = useState({ data: [] });

    useEffect(() => {
        console.log("prodListWithBom", prodListWithBom);
        console.log("StepTwoSavedData", StepTwoSavedData);

        if (StepTwoSavedData?.subprodListWithBom?.data) {
            setSubProdListWithBom({
                ...subprodListWithBom,
                data: JSON.parse(JSON.stringify(StepTwoSavedData.subprodListWithBom.data))
            })
        }

        console.log("StepOneSavedData", StepOneSavedData);
        setConstLabel(getLabels('Production', 'Production Planning'));

        console.log("prev_data", prev_data);
        console.log("localData", localData);

    }, []);

    useEffect(() => {

        console.log("status", status);
        console.log("productionplanDetails", productionplanDetails);

        if (status !== "loading" && status !== "idle") {
            if (productionplanDetails
                && productionplanDetails?.mat_req_list.length > 0) {

                setConsolidate(productionplanDetails.consolidate_item);
                setIgnoreQty(productionplanDetails.ignore_available_qty);
                setDefaultProductsWithBom();
            } else {
                preparingBomList(consolidate);
            }
        }
    }, [productionplanDetails, status]);


    function findBomFromMR(productDetails) {
        console.log("productDetails", productDetails);
        console.log("productionplanDetails", productionplanDetails);
        console.log("prodListWithBom", prodListWithBom);

        let foundedBom = null;

        productionplanDetails.mat_req_list.forEach((mr) => {
            let result = mr.material_req_lineitems.find((item) =>
                item.sales_order_uuid == productDetails.so_uuid
                && item.sales_order_item_uuid == productDetails.so_item_uuid);
            if (result) {

                // bug needs to be fixed here 
                foundedBom = result.bom_data;
            }
        });

        return foundedBom;
    }

    function setDefaultProductsWithBom() {
        setProdListWithBom({
            ...prodListWithBom,
            loading: true,
            data: []
        });

        let uniqueProducts = [];
        const uuidSet = new Set();

        for (const so of productionplanDetails.draft_data.stepOneData.selectedSalesOrder) {
            for (const item of so.so_order_lines) {
                console.log("uuidSet", uuidSet);

                const productDetails = { ...item.product_id };
                productDetails.so_item_quantity = item.quantity;
                productDetails.order_date = so.order_date;
                productDetails.order_due_date = so.order_due_date;
                productDetails.so_uuid = so.uuid;
                productDetails.so_final_total = so.final_total;
                productDetails.so_customer_id = so.customer_id;
                productDetails.so_item_uuid = item.uuid;
                productDetails.so_bom_uuid = item.bom_id;

                const foundedBOM = findBomFromMR(productDetails);
                console.log("foundedBOM", foundedBOM);
                productDetails.selected_bom = foundedBOM ? [foundedBOM] : [];

                if (productionplanDetails.consolidate_item == false) {
                    uuidSet.add(productDetails.uuid);
                    uniqueProducts.push(productDetails);
                } else {
                    console.log(uuidSet.has(productDetails.uuid));
                    if (!uuidSet.has(productDetails.uuid)) {
                        uuidSet.add(productDetails.uuid);
                        uniqueProducts.push(productDetails);
                    } else {
                        uniqueProducts.map((val) => {
                            if (val.uuid == productDetails.uuid) {
                                val.so_item_quantity = val.so_item_quantity + productDetails.so_item_quantity;
                            }
                        });
                    }
                }
            }
        }
        console.log("uniqueProducts", uniqueProducts);

        fetch_prod_wise_bom({ prod_arr: uniqueProducts.map(val => val.uuid) }).then((res) => {
            console.log("res", res);

            const response = res.data.data;
            if (response.length > 0) {

                const prod_bom_unique_combo_list = [];
                // const product_unique_uuid = new Set();

                let j = 0;
                for (const prod of uniqueProducts) {
                    const bomListOfProd = response.filter(val => val.product_id == prod.uuid);

                    let newItem = null;
                    newItem = { ...prod };
                    newItem.bom = bomListOfProd;
                    console.log("bomListOfProd", bomListOfProd);

                    // if there are 2 manufactured products while creating material if it was created for
                    //  only one manufactured product then other product doesnt have selected bom to 
                    // display in this case we display first bom from list
                    if (newItem.selected_bom.length == 0) {
                        newItem.selected_bom = prod.so_bom_uuid ?
                            bomListOfProd.filter((bom) => bom.uuid == prod.so_bom_uuid)
                            : [bomListOfProd[0]];
                    }

                    prod_bom_unique_combo_list.push(newItem);
                    j++;
                }
                console.log("uniqueProducts", uniqueProducts);
                console.log("prod_bom_unique_combo_list", prod_bom_unique_combo_list);

                setProdListWithBom({
                    ...prodListWithBom, loading: false,
                    data: prod_bom_unique_combo_list
                });

                setunConsolidated_prodListWithBom({
                    ...unConsolidated_prodListWithBom,
                    loading: false, data: prod_bom_unique_combo_list
                });

            } else {
                setProdListWithBom({ ...prodListWithBom, loading: false, data: [] });
                toast.error("Something went wrong in fetching bom list", {
                    autoClose: 3000,
                });
            }

        }, (err) => {
            console.log("err", err);
            setProdListWithBom({ ...prodListWithBom, loading: false, data: [] });
            if (err.response.status !== 404) {
                toast.error("Something went wrong in fetching bom list", {
                    autoClose: 3000,
                });
            }
        })
    }


    function preparingBomList(consolidate_data, called_from) {
        console.log("called_from", called_from);
        console.log("modalData", modalData);



        if (called_from) {
            console.log("called_from", called_from);
            setProdListWithBom({
                ...prodListWithBom,
                loading: true,
                data: [...prodListWithBom.data]
            });
        } else {
            setProdListWithBom({ ...prodListWithBom, loading: true, data: [] });
        }
        setSubProdListWithBom({ ...subprodListWithBom, loading: false, data: [] });

        let uniqueProducts = [];


        const uuidSet = new Set();

        console.log("StepOneSavedData.selectedSalesOrder", StepOneSavedData.selectedSalesOrder);


        for (const so of StepOneSavedData.selectedSalesOrder) {
            for (const item of so.so_order_lines) {
                console.log("uuidSet", uuidSet);

                const productDetails = { ...item.product_id };
                productDetails.so_item_quantity = item.quantity;
                productDetails.order_date = so.order_date;
                productDetails.order_due_date = so.order_due_date;
                productDetails.so_uuid = so.uuid;
                productDetails.so_final_total = so.final_total;
                productDetails.so_customer_id = so.customer_id;
                productDetails.so_bom_uuid = item.bom_id;
                productDetails.so_item_uuid = item.uuid;
                // console.log("productDetails", productDetails);

                if (consolidate_data == false) {
                    // console.log("+++++");
                    uuidSet.add(productDetails.uuid);
                    uniqueProducts.push(productDetails);



                } else {
                    console.log(uuidSet.has(productDetails.uuid));
                    if (!uuidSet.has(productDetails.uuid)) {
                        // console.log("----------");
                        uuidSet.add(productDetails.uuid);
                        uniqueProducts.push(productDetails);

                    } else {
                        // Ths step is required to sum quantity of so lineitems where product is same
                        uniqueProducts.map((val) => {
                            if (val.uuid == productDetails.uuid) {
                                val.so_item_quantity = val.so_item_quantity + productDetails.so_item_quantity;
                            }
                        });


                    }
                }

            }
        }


        console.log("StepOneSavedData", StepOneSavedData);
        console.log("uniqueProducts", uniqueProducts);

        fetch_prod_wise_bom({ prod_arr: uniqueProducts.map(val => val.uuid) }).then((res) => {
            console.log("res", res);

            const response = res.data.data;
            if (response.length > 0) {

                const prod_bom_unique_combo_list = [];
                // const product_unique_uuid = new Set();

                let j = 0;
                for (const prod of uniqueProducts) {
                    const bomListOfProd = response.filter(val => val.product_id == prod.uuid);



                    let newItem = null;
                    newItem = { ...prod };
                    newItem.bom = bomListOfProd;
                    console.log("bomListOfProd", bomListOfProd);
                    console.log("modalData", modalData);
                    console.log("prodListWithBom", prodListWithBom);
                    console.log("j", j);

                    console.log("prodListWithBom", prodListWithBom);
                    if (prodListWithBom.data.length > 0) {
                        if (j == modalData.ind) {
                            newItem.selected_bom = [bomListOfProd[0]];
                        } else {
                            console.log("-------------");
                            // newItem.selected_bom = [bomListOfProd[0]];

                            // Below if condition explaination
                            // suppose 2 so are selected 2 duplicate products come from them now prodListWithBom
                            // has 2 unique prods with there bom but uniqueProducts variable stores all duplicate or not duplicate 
                            // when prods are unconsolidated the length can be 3 for unique prod based on so prods, in consolidated the
                            // the length will be 2 to handle this below is written

                            if ((j + 1) > prodListWithBom.data.length) {
                                const resultProdWithBom = prodListWithBom.data.find((val) => val.uuid == prod.uuid);
                                console.log("resultProdWithBom", resultProdWithBom);
                                newItem.selected_bom = resultProdWithBom ? resultProdWithBom.selected_bom : [];

                            } else {
                                newItem.selected_bom = prodListWithBom.data.length > 0 ?
                                    prodListWithBom.data[j].selected_bom : [];
                            }



                        }
                    } else {
                        // newItem.selected_bom = [];
                        // to check if sales order item has bom or not below console.log can be used
                        console.log("prod.so_bom_uuid", prod.so_bom_uuid);
                        newItem.selected_bom = prod.so_bom_uuid ?
                            bomListOfProd.filter((bom) => bom.uuid == prod.so_bom_uuid) : [];
                    }
                    newItem.errStatus = false;


                    prod_bom_unique_combo_list.push(newItem);
                    j++;
                }
                console.log("uniqueProducts", uniqueProducts);
                console.log("prod_bom_unique_combo_list", prod_bom_unique_combo_list);

                setProdListWithBom({
                    ...prodListWithBom, loading: false,
                    data: prod_bom_unique_combo_list
                });

                if (called_from) {
                    setModalData({ ...modalData, state: false, data: null, ind: null });
                }

                if (consolidate_data == false) {
                    setunConsolidated_prodListWithBom({
                        ...unConsolidated_prodListWithBom,
                        loading: false, data: prod_bom_unique_combo_list
                    });
                }
            } else {
                setProdListWithBom({ ...prodListWithBom, loading: false, data: [] });
                toast.error("Something went wrong in fetching bom list", {
                    autoClose: 3000,
                });
            }

        }, (err) => {
            console.log("err", err);
            setProdListWithBom({ ...prodListWithBom, loading: false, data: [] });
            if (err.response.status !== 404) {
                toast.error("Something went wrong in fetching bom list", {
                    autoClose: 3000,
                });
            }
        })
    }


    function fetchSubBomItems(do_ignore_qty, called_from) {
        const allOK = validateErr();
        if (!allOK) {
            console.log("asdasd");
            return
        } else {

            const icon = document.getElementById('refresh-icon-img');
            icon.classList.add('spin');

            setSubProdListWithBom({ loading: true, data: [], firstTime: false });

            console.log("prodListWithBom", prodListWithBom);

            const product_having_sel_bom = [];
            var finalSubProdList = [];

            prodListWithBom.data.map((val) => {
                console.log("val", val);
                console.log("val.bom", val.bom);
                console.log("val.selected_bom", val.selected_bom);

                const selBOM = val.bom.find(bom => bom.uuid == val.selected_bom[0].uuid);

                console.log("selBOM", selBOM);
                selBOM.so_item_quantity = val.so_item_quantity;

                product_having_sel_bom.push(selBOM);

                selBOM.bom_line_item.forEach((line_item) => {
                    console.log("line_item", line_item);

                    const new_obj = { ...selBOM };
                    delete new_obj.bom_line_item;
                    new_obj.reorder_qty = val.reorder_qty;
                    new_obj.lineItemDetails = line_item;
                    new_obj.lineItemDetails.qtyRequiredAsPerBom = 0;
                    new_obj.lineItemDetails.so_item_uuid = val.so_item_uuid;
                    new_obj.lineItemDetails.so_uuid = val.so_uuid;
                    finalSubProdList.push(new_obj);
                })

            });

            console.log("product_having_sel_bom", product_having_sel_bom);
            console.log("finalSubProdList", finalSubProdList);


            // this case should run in scenario of update only
            if (productionplanDetails && productionplanDetails?.mat_req_list?.length > 0) {
                const allProdFromMatReqsItems = [];
                productionplanDetails?.mat_req_list.forEach(mat_req => {
                    mat_req.material_req_lineitems.forEach(mat_item => {
                        allProdFromMatReqsItems.push(mat_item);
                    });
                });


                const list2UUIDs = new Set(finalSubProdList.map(item => item.lineItemDetails.product_id));

                console.log("allProdFromMatReqsItems", allProdFromMatReqsItems);
                allProdFromMatReqsItems.forEach(item => {
                    if (!list2UUIDs.has(item.product_uuid)) {
                        console.log("add hoc item must be push in here");
                        // finalSubProdList.push({ product_uuid: item.product_uuid });
                    }
                });
            }


            console.log("visible.data", visible.data);
            // calling from add product modal
            if (visible.data.length && !called_from) {
                finalSubProdList = [...finalSubProdList, ...visible.data];


            } else {
                setVisible({ state: false, data: [] });
            }

            console.log("finalSubProdList", finalSubProdList);

            if (finalSubProdList.length > 0) {
                if (productionplanDetails.mat_req_list) {

                    productionplanDetails.mat_req_list.forEach(val => {
                        // product_id_list.push(val.material_req_lineitems[0].product_uuid);

                        const founded_res_prod = finalSubProdList.find((item) =>
                            item.lineItemDetails.product_id == val.material_req_lineitems[0].product_uuid);
                        console.log("founded_res_prod", founded_res_prod);

                        if (!founded_res_prod) {
                            const obj = {
                                productId: val.material_req_lineitems[0].prod_data.productId,
                                productType: val.material_req_lineitems[0].prod_data.productType,
                                product_family_id_id: val.material_req_lineitems[0].prod_data.prod_family_id,
                                product_id: val.material_req_lineitems[0].prod_data.uuid,
                                product_type_id: val.material_req_lineitems[0].prod_data.product_type_id,
                                productname: val.material_req_lineitems[0].prod_data.productName,
                                qty: 0,
                                qtyRequiredAsPerBom: 0,
                                planToRequestQty: val.material_req_lineitems[0].qty
                            }

                            const product_to_append = { qty: 0, so_item_quantity: 0, addhoc_item: true };
                            product_to_append.lineItemDetails = obj;
                            finalSubProdList.push(product_to_append);
                        }
                    });
                }

                let product_id_list;
                // if (prev_data.mat_req_list.length > 0) {
                //     product_id_list = finalSubProdList.map(val => val.lineItemDetails.prod);
                // } else {
                product_id_list = finalSubProdList.map(val => val.lineItemDetails.product_id);
                // }

                // this logic is for manufacturing good sub bom items are coming but if add hoc item was added
                // while creating then to make it appear while editing this logic is necessary



                console.log("product_id_list", product_id_list);

                fetch_multi_prod_stock({
                    product_uuid_list: product_id_list,
                    location_uuid: StepOneSavedData?.formData?.location[0].uuid
                }).then((res) => {
                    const invResponse = res.data.data;
                    console.log("invResponse", invResponse);


                    // 
                    console.log("unConsolidated_prodListWithBom", unConsolidated_prodListWithBom);



                    const one_bom_one_lineitem_list = [];
                    const prod_bom_combi_Set = new Set();

                    finalSubProdList.forEach((prod, ind) => {
                        if (prod.lineItemDetails) {
                            const resProd = invResponse.find((val) => {
                                // if (prod.lineItemDetails.product_id) {
                                return val.product_id == prod.lineItemDetails.product_id
                                // } else {

                                //     return val.product_id == prod.lineItemDetails.prod
                                // }
                            });

                            console.log("resProd", resProd);

                            if (resProd) {
                                prod.last_po_qty = resProd.last_po_qty;
                                prod.last_po_details = resProd.last_po_details;
                                prod.lineItemDetails.active_po_qty = resProd.active_po_qty
                                // prod.lineItemDetails.last_po_details = resProd.last_po_details
                                prod.lineItemDetails.availqty = resProd.availqty
                                prod.lineItemDetails.reorder_qty = resProd.reorder_qty
                                prod.lineItemDetails.materialCode = resProd.materialCode ?? null
                                prod.lineItemDetails.data = resProd;

                                if (resProd.storeDetails) {
                                    prod.lineItemDetails.inventoryCost = resProd.storeDetails.inventoryCost;
                                    prod.lineItemDetails.consume_price = resProd.storeDetails.consume_price;
                                }
                            } else {
                                prod.lineItemDetails.last_po_qty = 0;
                                prod.last_po_details = null;
                                prod.lineItemDetails.active_po_qty = 0;
                                prod.lineItemDetails.availqty = 0;
                                prod.lineItemDetails.materialCode = null
                                prod.lineItemDetails.data = null;
                            }

                            const combination = `${prod.lineItemDetails.product_id}-${prod.bomId}`;

                            if (!prod_bom_combi_Set.has(combination)) {
                                prod_bom_combi_Set.add(combination);

                                const prodExistInDeleteList = deletedProdList.data.find(val =>
                                    val.product_uuid == prod.lineItemDetails.product_id
                                    && val.bom_uuid == prod.lineItemDetails.bomid_id);

                                console.log("prodExistInDeleteList", prodExistInDeleteList);
                                if (!prodExistInDeleteList) {
                                    one_bom_one_lineitem_list.push(prod);
                                }
                            } else {
                                console.log("one_bom_one_lineitem_list", one_bom_one_lineitem_list);
                                console.log("ind", ind);

                                const foundedIndex = one_bom_one_lineitem_list.findIndex((val) =>
                                    val.lineItemDetails.product_id == prod.lineItemDetails.product_id && val.bomId == prod.bomId);
                                console.log("foundedIndex", foundedIndex);

                                const temp = { ...one_bom_one_lineitem_list[foundedIndex] };
                                temp.so_item_quantity =
                                    temp.so_item_quantity + prod.so_item_quantity;
                                one_bom_one_lineitem_list[foundedIndex] = temp;

                            }
                        }

                    });

                    console.log("one_bom_one_lineitem_list", one_bom_one_lineitem_list);

                    for (const val of one_bom_one_lineitem_list) {
                        var qtyReqToMakeOne = parseFloat(val.lineItemDetails.qty) / parseFloat(val.qty);
                        console.log("qtyReqToMakeOne", qtyReqToMakeOne);
                        console.log("val.so_item_quantity", val.so_item_quantity);

                        if (Number.isNaN(qtyReqToMakeOne)) {
                            qtyReqToMakeOne = 0;
                        }

                        val.lineItemDetails.qtyRequiredAsPerBom = qtyReqToMakeOne * val.so_item_quantity;
                        // val.lineItemDetails.checked = (val.lineItemDetails?.active_po_qty + val.lineItemDetails?.availqty) >= val.lineItemDetails?.qtyRequiredAsPerBom ? false : true;                 
                        // // This is added for third step dependancy
                        console.log("val.lineItemDetails?.availqty", val.lineItemDetails?.availqty);
                        console.log("val.lineItemDetails?.qtyRequiredAsPerBom", val.lineItemDetails?.qtyRequiredAsPerBom);
                        val.lineItemDetails.checked =
                            val.lineItemDetails?.qtyRequiredAsPerBom > val.lineItemDetails?.availqty
                                ? true : false;

                        // if add hoc item is present directly setting it to checked
                        if (!val.uuid) {
                            val.lineItemDetails.checked = true;
                        }

                        // This is added for third step dependancy

                        // if (Number.isNaN(val.lineItemDetails.qtyRequiredAsPerBom)) {
                        //     val.lineItemDetails.qtyRequiredAsPerBom = val.plan_to_req_qty;
                        // }


                        if (!do_ignore_qty) {

                            if (!val.addhoc_item) {
                                if (val.lineItemDetails.availqty > val.lineItemDetails.qtyRequiredAsPerBom) {
                                    val.lineItemDetails.planToRequestQty = 0;
                                } else {
                                    val.lineItemDetails.planToRequestQty = val.lineItemDetails.qtyRequiredAsPerBom
                                        - val.lineItemDetails.availqty;
                                }
                            }

                        } else {
                            console.log("here 1");
                            if (val.addhoc_item) {
                                val.lineItemDetails.planToRequestQty = val.lineItemDetails.planToRequestQty;
                            } else {
                                val.lineItemDetails.planToRequestQty = val.lineItemDetails.qtyRequiredAsPerBom;
                            }
                        }


                    }

                    console.log("deletedProdList", deletedProdList);
                    console.log("StepOneSavedData", StepOneSavedData);
                    if (!StepOneSavedData.formData.rawMaterialPlanning &&
                        StepOneSavedData.formData.employeePlanning && productionplanDetails?.workorder?.length == 0) {
                        const prodHasZeroAvailQty = one_bom_one_lineitem_list.some(item => item.lineItemDetails?.availqty
                            < item.lineItemDetails.qtyRequiredAsPerBom);
                        console.log("prodHasZeroAvailQty", prodHasZeroAvailQty);
                        if (prodHasZeroAvailQty) {
                            call_sweet_alert("Cannot proceed",
                                "Cannot proceed as all products don't have the required available quantity."
                            );

                            setDisableBtn({
                                state: true,
                                msg: "Cannot proceed as all products dont have required available quantity"
                            });
                        } else {
                            setDisableBtn({
                                state: false,
                                msg: ""
                            });
                        }
                    }
                    console.log('one_bom_one_lineitem_list', one_bom_one_lineitem_list);
                    setSubProdListWithBom({
                        ...subprodListWithBom,
                        loading: false, data: one_bom_one_lineitem_list, firstTime: true
                    });
                    icon.classList.remove('spin');

                    if (visible.data.length > 0) {
                        setTimeout(() => {
                            const divToScroll = document.getElementById('bom_item_table_parent');
                            divToScroll.scrollTop = divToScroll.scrollHeight;
                        }, [500]);
                    }

                }, (err) => {
                    console.log("err", err);
                    icon.classList.remove('spin');
                    setSubProdListWithBom({ ...subprodListWithBom, loading: false, data: [], firstTime: true });
                    if (err.response.status !== 404) {
                        toast.error("Something went wrong in fetching stock", {
                            autoClose: 3000,
                        });
                    }
                }, (err) => {
                    console.log("err", err);
                });

            }

        }
    }


    function validateErr() {

        const tempData = { ...prodListWithBom };
        console.log("tempData", tempData);

        tempData.data.forEach((prod, ind) => {
            if (prod.selected_bom.length == 0) {
                console.log("prod", prod);
                prod.errStatus = true
                prod.msg = 'required'
            } else {
                prod.errStatus = false
                prod.msg = ''
            }
        });


        setProdListWithBom(tempData);

        const allValidated = tempData.data.every(item => item.errStatus === false);
        console.log("allValidated", allValidated);
        return allValidated;
    }


    function submit() {
        const allOK = validateErr();
        if (!allOK) {
            console.log("asdasd");
            return
        } else {
            console.log("unConsolidated_prodListWithBom", unConsolidated_prodListWithBom);

            const base_data = [...unConsolidated_prodListWithBom.data.map((val, ind) => {
                if (ind == 0) {
                    val.selected = true;
                } else {
                    val.selected = false;
                }
                // delete val.bom;
                return val
            })];

            const stepTwoData = {
                consolidate: consolidate,
                ignoreQty: ignoreQty,
                prodListWithBom: prodListWithBom,
                unConsolidated_prodListWithBom: unConsolidated_prodListWithBom,
                subprodListWithBom: subprodListWithBom,
                base_data: base_data,
            }
            console.log("stepTwoData", stepTwoData);

            dispatch(updateStepTwo({ data: stepTwoData, prev_data: productionplanDetails }));
            dispatch(saveStepTwo(stepTwoData));
            // localStorage.setItem("StepTwo", JSON.stringify(stepTwoData));
            nextStep();

        }
    }

    const rightSideRows = subprodListWithBom.data.map((val, ind) => {
        console.log("------------val-------", val);
        const tooltipContent = constLabel?.lbl_delete_product ?? "Delete Product";

        const { lineItemDetails } = val;

        // Default styles
        let color = "#343131";
        let background = "#FFFFFF";

        if (lineItemDetails.availqty < lineItemDetails.reorder_qty) {
            console.log(1);
            color = "#ffffff";
            background = "#FFBB5C";
        } else if (lineItemDetails.availqty < lineItemDetails.qtyRequiredAsPerBom) {
            console.log(2);
            color = "#ffffff";
            background = "#F6635C";
        }

        console.log("color", color);
        console.log("background", background);

        const style = {
            color: color,
            background: background,
        }


        return (
            <tr
                key={ind}

            >
                <td style={style}>{ind + 1}</td>
                <td style={style}>
                    {val.lineItemDetails?.productname ?? "NA"}
                </td>
                <td style={style}>
                    {val.bom_name ?? "NA"}
                </td>
                <td style={style}>
                    {val.lineItemDetails?.qtyRequiredAsPerBom ?? "NA"}
                </td>
                <td style={style}>
                    {val.last_po_qty ?? "NA"}
                </td>
                <td style={style}>
                    {val.last_po_details?.created_at
                        ? formatDateString(val.last_po_details?.created_at)
                        : "NA"}
                </td>
                <td style={style}>
                    {val.lineItemDetails
                        ? `${val.lineItemDetails?.availqty ?? "NA"} ${val.lineItemDetails?.unitname ?
                            val.lineItemDetails?.unitname
                            : val.lineItemDetails?.data?.unit ? val.lineItemDetails?.data?.unit : ""} `
                        : "NA"}
                </td>
                <td style={style}>
                    {val.lineItemDetails?.planToRequestQty !== null ?
                        parseFloat(val.lineItemDetails?.planToRequestQty).toFixed(2)
                        : "NA"}
                </td>
                <td style={style}>
                    <Tooltip id="delete-tooltip" place="top" />
                    <Trash2
                        data-tooltip-id="delete-tooltip"
                        data-tooltip-content={tooltipContent}
                        className="cursor_pointer"
                        style={{ color: style.color }}
                        size={20}
                        onClick={() => {
                            SweetAlert.fire({
                                title: "Are you sure to delete product ? \n",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonText: "Ok",
                                cancelButtonText: "Cancel",
                                reverseButtons: true,
                            }).then((result) => {
                                if (result.value) {
                                    const tempData = [...subprodListWithBom.data];
                                    tempData.splice(ind, 1);

                                    if (val.lineItemDetails.bomid_id) {
                                        const tempData = [...deletedProdList.data];

                                        setDeletedProdList({
                                            ...deletedProdList, data: [...tempData, {
                                                product_uuid: val.lineItemDetails.product_id,
                                                bom_uuid: val.lineItemDetails.bomid_id
                                            }]
                                        });
                                    }

                                    const productIndex = visible.data.findIndex(
                                        (item) =>
                                            item.lineItemDetails.product_id ===
                                            val.lineItemDetails.product_id
                                    );

                                    const tempVisible = [...visible.data];
                                    if (productIndex !== -1) {
                                        tempVisible.splice(productIndex, 1);
                                    }

                                    setVisible({
                                        ...visible,
                                        loading: false,
                                        data: tempVisible,
                                    });

                                    setSubProdListWithBom({
                                        loading: false,
                                        data: tempData,
                                    });
                                }
                            });
                        }}
                    />
                </td>
            </tr>
        );
    });





    return (
        <React.Fragment>
            {modalData.state &&
                <CreateBom
                    modalData={modalData}
                    setModalData={setModalData}
                    preparingBomList={preparingBomList}
                    consolidate={consolidate}
                />
            }

            {visible &&
                <AddProduct
                    visible={visible}
                    setVisible={setVisible}
                    fetchSubBomItems={fetchSubBomItems}
                    ignoreQty={ignoreQty}
                    subprodListWithBom={subprodListWithBom}
                />
            }

            <div className='stepTwo'>

                <div className='row'>
                    <div className='col-12'>
                        <div className='row'>


                            <div className='col-12 col-md-5'>
                                <div className='row mt-4'>
                                    <div className=''>
                                        <p className='mb-2 mt-1' id='pro_plan_label'>Selected Sales Order's Products</p>

                                        <div>
                                            {StepOneSavedData.selectedSalesOrder?.map((item, ind) => (
                                                <span
                                                    key={ind}
                                                    className="badge rounded-pill px-2 py-1 badge-outline text-dark
                                            text-center border me-2 mb-1"
                                                >
                                                    {item.so_id}
                                                </span>
                                            ))}
                                        </div>
                                    </div>




                                    <div className='col-12 mt-2'>
                                        <label><input
                                            className="form-check-input me-2 mb-2"
                                            type="checkbox"
                                            checked={consolidate}
                                            disabled={prodListWithBom.data.length == 0 ||
                                                (StepOneSavedData.formData.employeePlanning &&
                                                    !StepOneSavedData.formData.rawMaterialPlanning)
                                            }
                                            onChange={(e) => {
                                                setConsolidate(!consolidate);
                                                preparingBomList(!consolidate);
                                            }}
                                        />Consolidate items</label>

                                        <div className="notes">
                                            <i><p className="my-2 mb-0">
                                                <span className="text-danger roboto-bold">Note - </span>
                                                By Default BOM created in sales order is selected</p></i>
                                        </div>

                                        {
                                            prodListWithBom.loading ?
                                                <div className="text-center">
                                                    <div className="spinner-border" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                </div>
                                                :
                                                prodListWithBom.data.length > 0 ? (
                                                    // <div className='mt-2 ' style={{ maxHeight: '50vh', overflowX: "auto" }}>
                                                    <div className='mt-2 '>
                                                        {/* <div className="table-responsive"> */}
                                                        <table className="table table-bordered">
                                                            <thead className="table-grey roboto-bold">
                                                                <tr className="">

                                                                    <th scope="col"
                                                                        style={{ width: '10%' }}>{"Sr.No"}</th>


                                                                    <th scope="col"
                                                                        style={{ width: '35%' }}>
                                                                        {constLabel?.lbl_product
                                                                            ? constLabel.lbl_product
                                                                            : "Product"}
                                                                    </th>

                                                                    <th scope="col"
                                                                        style={{ width: '45%' }}>
                                                                        {constLabel?.lbl_bom
                                                                            ? constLabel.lbl_bom
                                                                            : "BOM"}
                                                                    </th>

                                                                    <th scope="col"
                                                                        style={{ width: '10%' }}>
                                                                        {constLabel?.lbl_sales_order_qty
                                                                            ? constLabel.lbl_sales_order_qty
                                                                            : "Sales Order Qty"}
                                                                    </th>


                                                                </tr>
                                                            </thead>
                                                            <tbody className="roboto">
                                                                {prodListWithBom.data.map((val, ind) => (
                                                                    <tr key={ind}>


                                                                        <td>{parseInt(ind) + 1}</td>

                                                                        <td>
                                                                            {val.productName !== null
                                                                                ? val.productName
                                                                                : "NA"}

                                                                            {localData.industry_type !== FASHION &&
                                                                                localData.industry_type !== APPAREL &&
                                                                                <div>
                                                                                    <p style={{
                                                                                        color: "blue",
                                                                                        cursor: "pointer"
                                                                                    }} onClick={() => {
                                                                                        setModalData({
                                                                                            ...modalData,
                                                                                            state: true,
                                                                                            data: val,
                                                                                            ind: ind
                                                                                        })
                                                                                    }}>
                                                                                        <u>Create BOM</u></p>
                                                                                </div>}
                                                                        </td>

                                                                        <td>
                                                                            {/* {val.bom !== null
                                                                                    ? val.bom.bomId
                                                                                    : "NA"} */}
                                                                            <div className='form-group typeahead-container'>

                                                                                <Typeahead
                                                                                    id={"basic-typeahead-single_" + ind}
                                                                                    labelKey="bom_name"
                                                                                    size="sm"
                                                                                    multiple={false}
                                                                                    options={val.bom}
                                                                                    loading={prodListWithBom.loading}
                                                                                    placeholder="Select BOM"
                                                                                    onChange={(selected) => {
                                                                                        console.log("selected", selected);
                                                                                        const temp = [...prodListWithBom.data];
                                                                                        temp[ind].selected_bom = selected;

                                                                                        if (selected.length == 0) {
                                                                                            temp[ind].errStatus = true
                                                                                        } else {
                                                                                            temp[ind].errStatus = false
                                                                                        }



                                                                                        setProdListWithBom({
                                                                                            ...prodListWithBom,
                                                                                            data: temp, loading: false
                                                                                        });

                                                                                        const temp_unConsolidated_prodListWithBom =
                                                                                            [...unConsolidated_prodListWithBom.data];

                                                                                        if (consolidate == true) {

                                                                                            temp_unConsolidated_prodListWithBom.filter(
                                                                                                (item) => item.uuid == val.uuid);

                                                                                            temp_unConsolidated_prodListWithBom.forEach((item2) => {
                                                                                                item2.selected_bom = selected;
                                                                                            })
                                                                                            setunConsolidated_prodListWithBom({
                                                                                                ...unConsolidated_prodListWithBom,
                                                                                                loading: false, data: temp_unConsolidated_prodListWithBom
                                                                                            });
                                                                                        }
                                                                                    }}
                                                                                    isInvalid={val.errStatus}
                                                                                    selected={prodListWithBom.data[ind].selected_bom}
                                                                                    disabled={localData.industry_type == FASHION ||
                                                                                        localData.industry_type == APPAREL}
                                                                                // selected={prodListWithBom.data[ind].selected_bom}
                                                                                />

                                                                                {!prodListWithBom.loading && !val.errStatus && <span className="arrow-icon-2"></span>}
                                                                                {val.errStatus == true &&
                                                                                    <span className='text-danger err-msg'>Required</span>}
                                                                            </div>

                                                                        </td>


                                                                        <td>
                                                                            {val.bom !== null
                                                                                ? val.so_item_quantity
                                                                                : "NA"}
                                                                        </td>

                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                        {/* </div> */}
                                                    </div>
                                                ) :
                                                    < div
                                                        className="alert alert-danger mb-0 text-center my-4"
                                                        role="alert"
                                                    >
                                                        BOM not found for any product from sales orders !
                                                    </div>
                                        }
                                    </div>

                                    <div className='col-12 d-flex justify-content-end mt-3'>

                                        <button
                                            disabled={subprodListWithBom.loading == true || prodListWithBom.data.length == 0}
                                            onClick={() => {
                                                deletedProdList.data = [];
                                                setDeletedProdList(deletedProdList);
                                                fetchSubBomItems(ignoreQty, "button")
                                            }}
                                            className='btn button-primary'>Get BOM Items
                                            <img className='ms-2' src={refresh}
                                                alt="refresh" id='refresh-icon-img' /></button>
                                    </div>
                                </div>

                            </div>



                            <div className='col-12 col-md-7'>
                                <div className='row mt-4'>
                                    <div className='d-flex'>
                                        <p className='mb-2 mt-1' id='pro_plan_label'>BOM Sub Items</p>

                                    </div>

                                    <div className='d-md-flex justify-content-between align-items-center'>
                                        <label><input
                                            className="form-check-input me-2 mb-2"
                                            type="checkbox"
                                            checked={ignoreQty}
                                            disabled={prodListWithBom.data.length == 0 ||
                                                (StepOneSavedData.formData.employeePlanning &&
                                                    !StepOneSavedData.formData.rawMaterialPlanning)
                                            }
                                            onChange={(e) => {
                                                setIgnoreQty(!ignoreQty);
                                                fetchSubBomItems(!ignoreQty);

                                            }}
                                        />Ignore Existing Available Quantity</label>

                                        <button
                                            disabled={subprodListWithBom.data.length == 0 ||
                                                (StepOneSavedData.formData.employeePlanning &&
                                                    !StepOneSavedData.formData.rawMaterialPlanning)
                                            }
                                            onClick={() => setVisible({ state: true, data: [...visible.data] })}
                                            className='btn btn-sm button-primary px-3'>Add Product</button>
                                    </div>

                                    <div>
                                    </div>

                                    <div className="notes">
                                        <i><p className="my-2 mb-0"><span className="text-danger roboto-bold">Note - </span>
                                            Only selected BOM's items will be displayed here.</p></i>
                                        <i><div className="d-flex">
                                            <div className="red_indicator"></div>
                                            <span className="ms-2">- Indicates available quantity is less than required quantity.</span>
                                        </div>
                                        </i>
                                        <i>
                                            <div className="d-flex">
                                                <div className="yellow_indicator"></div>
                                                <span className="ms-2">- Indicates available quantity is less than minimum stock quantity.</span>
                                            </div>
                                        </i>
                                    </div>



                                    <div className='col-12'>

                                        {
                                            subprodListWithBom.loading ?
                                                null
                                                :
                                                subprodListWithBom.data.length > 0 ? (
                                                    <div className='mt-2 '>
                                                        <div className='mt-2' id="bom_item_table_parent"
                                                            style={{ maxHeight: '60vh', overflowX: "auto" }}>
                                                            {/* <div className="table-responsive"> */}
                                                            <table className="table table-bordered">
                                                                <thead className="table-grey roboto-bold">
                                                                    <tr className="">


                                                                        <th scope="col">{"Sr.No"}</th>


                                                                        <th scope="col">
                                                                            {constLabel?.lbl_product
                                                                                ? constLabel.lbl_product
                                                                                : "Product"}
                                                                        </th>

                                                                        <th scope="col">
                                                                            {constLabel?.lbl_bom
                                                                                ? constLabel.lbl_bom
                                                                                : "BOM"}
                                                                        </th>

                                                                        <th scope="col">
                                                                            {constLabel?.lbl_required_qty
                                                                                ? constLabel.lbl_required_qty
                                                                                : "Required Qty"}
                                                                        </th>

                                                                        <th scope="col">
                                                                            {constLabel?.lbl_active_po_qty
                                                                                ? constLabel.lbl_active_po_qty
                                                                                : "Last PO Qty"}
                                                                        </th>

                                                                        <th scope="col">
                                                                            {constLabel?.lbl_last_po_date
                                                                                ? constLabel.lbl_last_po_date
                                                                                : "Last PO Date"}
                                                                        </th>

                                                                        <th scope="col">
                                                                            {constLabel?.lbl_total_available_qty
                                                                                ? constLabel.lbl_total_available_qty
                                                                                : "Total Available Qty"}
                                                                        </th>

                                                                        <th scope="col">
                                                                            {constLabel?.lbl_plan_to_request_qty
                                                                                ? constLabel.lbl_plan_to_request_qty
                                                                                : "Plan To Request Qty"}
                                                                        </th>

                                                                        <th scope="col">
                                                                            {constLabel?.lbl_action
                                                                                ? constLabel.lbl_action
                                                                                : "Action"}
                                                                        </th>

                                                                    </tr>
                                                                </thead>
                                                                <tbody className="roboto">
                                                                    {rightSideRows}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                ) :
                                                    subprodListWithBom.firstTime && !subprodListWithBom.loading &&
                                                    < div
                                                        className="alert alert-danger mb-0 text-center my-4"
                                                        role="alert"
                                                    >
                                                        BOM Items not found !
                                                    </div>
                                        }


                                        {/* {disableBtn.state &&
                                            < div
                                                className="alert alert-danger mb-0 text-center my-4"
                                                role="alert"
                                            >
                                                {disableBtn.msg}
                                            </div>
                                        } */}
                                    </div>
                                </div>

                            </div>





                            <div className='d-flex justify-content-end mt-3'>
                                <button className='btn btn-grey px-4'
                                    onClick={() => prevStep()}
                                >Previous</button>

                                <button className='btn button-primary px-4 ms-0 ms-md-2'
                                    disabled={subprodListWithBom.data.length == 0 || disableBtn.state}
                                    onClick={() => submit()}
                                >Next {disableBtn.state &&
                                    <Spinner animation="border" size="sm" className='ms-2' />}</button>


                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default StepTwo