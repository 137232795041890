import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import { Typeahead } from "react-bootstrap-typeahead";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getProdCategory,
  getCustomer,
  getMarket_Group,
  getProduct_Family,
  getUOM,
  saveProduct,
  getBOM,
  getProdList,
  updateProduct,
  deleteBOM,
  gethsn_master,
  createMarketGroup,
  createProduct_Family,
  createProdCategory,
} from "../../../../api";
import {
  calMaxPage,
  getLabels,
} from "../../../../common/Functions/CommonFunctions";
import { Accordion, Modal, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import SweetAlert from "sweetalert2";
import { Trash2 } from "react-feather";
import info from "../../../../assets/images/info.png"
import { Tooltip } from "react-tooltip";
import { APPAREL, FASHION, MANUFACTURED_GOODS } from "../../../../common/Constants/constants";
import AddProductFamily from "../../AddMasters/AddProductFamily";
import AddTargetSegment from "../../AddMasters/AddTargetSegment";
import AddProductCategory from "../../AddMasters/AddProductCategory";

const schema = yup
  .object()
  .shape({
    // category: yup
    //   .array()
    //   .required("Product category is required !")
    //   .min(1, "Select atleast one type !"),
    marketGroup: yup
      .array()
      .required("Please select market group")
      .min(1, "Please select market group"),
    productFamily: yup
      .array()
      .required("Please select product family")
      .min(1, "Please select product family"),
    openingStock: yup
      .string()
      .transform((value, originalValue) => originalValue.trim() === '' ? null : value)
      .matches(/^\d+(\.\d+)?$/, 'Enter valid opening stock')
      .nullable(),
    OEMnumber: yup
      .string()
      .nullable()
      .transform((value, originalValue) =>
        originalValue.trim() === "" ? null : value
      ),
      // .matches(/^[a-zA-Z0-9\s]*$/, 'Enter a valid OEM part no'),
    productName: yup.string().required("Product name is required !"),
    hsn: yup
      .string()
      .matches(/^[a-zA-Z0-9]*$/, "Enter valid hsn")
      .nullable(true),
    unit: yup
      .array()
      .required("Unit of measurement is required!")
      .min(1, "Please select unit of measurement!"),
    machineCost: yup
      .string()
      .nullable()
      .transform((value, originalValue) =>
        originalValue.trim() === "" ? null : value
      )
      .matches(/^\d+(\.\d+)?$/, "Enter valid cost of manufacturing"),
    // salesPrice: yup
    //   .string()
    //   .required("Selling price is required !")
    //   .matches(/^\d+(\.\d+)?$/, "Enter valid selling price"),
    // selling_price: yup
    //   .string()
    //   .required("Selling price is required !")
    //   .matches(/^\d+(\.\d+)?$/, "Enter valid selling price"),
    // purchase_price: yup
    //   .string()
    //   .required("Procurement price is required !")
    //   .matches(/^\d+(\.\d+)?$/, "Enter valid procurement price"),
    can_be_sold: yup.string().required("Required"),
    can_be_purchased: yup.string().required("Required"),
    // can_be_expensed: yup.string().required("Required"),
    // has_bom: yup
    //   .string()
    //   .required("Required"),
    reorder_qty: yup
    .string()
    .transform((value, originalValue) => originalValue.trim() === '' ? null : value)
    .matches(/^\d+(\.\d+)?$/, 'Enter valid reorder qty')
    .nullable(),
  })
  .required();

function CloneManufacturedGoods(props) {
  let navigate = useNavigate();
  let location = useLocation();

  const [prodCategory, setProdCategory] = useState({
    data: [],
    loading: false,
  });
  const [marketGroup, setMarketGroup] = useState({ data: [], loading: false });
  const [prodFamily, setProdFamily] = useState({ data: [], loading: false });
  const [uomList, setUOMList] = useState({ data: [], loading: false });
  const [hsnList, setHSNList] = useState({ data: [], loading: false });
  const [createBom, setCreateBom] = useState(
    props.main_obj.prod_overview.has_bom ? props.main_obj.prod_overview.has_bom : false
  );
  const [constLabel, setConstLabel] = useState({});
  const [btnDisable, setBtnDisable] = useState(false);
  const [particularBomList, setParticularBomList] = useState({
    data: [],
    loading: false,
  });

  const [filterConfig, setFilterConfig] = useState({hsn_sac_code: ""});

  const [modal, setModal] = useState(false);

  const { register, handleSubmit, formState, control, reset, watch, setError, setValue, clearErrors } = useForm({
    // resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook

  let { errors } = formState;
  let selUnit = watch("unit");
  let selCategory = watch("category");

  const localData = JSON.parse(localStorage.getItem("localData"));

  useEffect(() => {
    setConstLabel(getLabels("Master", "Product"));
    fetchProductCategory();
    fetchMarketGroup();
    fetchProductFamily();
    fetchUOM();
    // fetchHSN();
  }, []);

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      fetchHSN();
    }, 1000); // Adjust the delay as needed (e.g., 500 milliseconds)

    return () => {
      clearTimeout(debounceTimeout); // Clear the timeout if searchTerm changes before the timeout expires
    };
  }, [filterConfig]);
  

  function fetchProdList(product_uuid) {
    
    getProdList({ uuid: product_uuid }).then(
      (res) => {
        
        let bom_check = false;
        if (createBom == true) {
          bom_check = true;
        }
        props.setMain_obj({
          ...props.main_obj,
          prod_overview: res.data.data[0],
          obj_bom: { ...props.main_obj.obj_bom, flag: bom_check },
        });

        setTimeout(() => {
          props.nextStep();
        }, [2300]);
      },
      (err) => {
        
      }
    );
  }

  function fetchHSN() {
    setHSNList({ ...hsnList, data: [], loading: true });
    let payload = {
      pageNo: 1,
      entriesPerPage: 1000,
    };
    if (filterConfig.hsn_sac_code !== "" && filterConfig.hsn_sac_code.length >= 4) {
      payload.hsn_sac_code = filterConfig.hsn_sac_code.trim(); // Search by hsn sac code
      gethsn_master(payload).then(
        (res) => {
          setHSNList({ ...hsnList, data: res.data.data, loading: false });
        },
        (err) => {
          setHSNList({ ...hsnList, data: [], loading: false });
        }
      );
    } else {
      // delete payload.hsn_sac_code;
      setHSNList({ ...hsnList, data: [], loading: false });
    }
  }

  function fetchUOM() {
    setUOMList({ ...uomList, data: [], loading: true });
    getUOM().then(
      (res) => {
        
        setUOMList({ ...uomList, data: res.data.data, loading: false });
      },
      (err) => {
        
        setUOMList({ ...uomList, data: [], loading: false });
      }
    );
  }

  function fetchProductCategory() {
    setProdCategory({ ...prodCategory, data: [], loading: true });
    getProdCategory().then(
      (res) => {
        
        setProdCategory({
          ...prodCategory,
          data: res.data.data,
          loading: false,
        });
      },
      (err) => {
        
        setProdCategory({ ...prodCategory, data: [], loading: false });
      }
    );
  }

  function fetchMarketGroup() {
    setMarketGroup({ ...marketGroup, data: [], loading: true });
    getMarket_Group().then(
      (res) => {
        
        setMarketGroup({
          ...marketGroup,
          data: res.data.data,
          loading: false,
        });
      },
      (err) => {
        
        setMarketGroup({ ...marketGroup, data: [], loading: false });
      }
    );
  }

  function fetchProductFamily() {
    setProdFamily({ ...prodFamily, data: [], loading: true });
    getProduct_Family().then(
      (res) => {
        
        setProdFamily({
          ...prodFamily,
          data: res.data.data,
          loading: false,
        });
      },
      (err) => {
        
        setProdFamily({ ...prodFamily, data: [], loading: false });
      }
    );
  }

  const onSubmit = (data) => {
    console.log("data", data);
    // Check if the industry type is FASHION or APPAREL
    if ([FASHION, APPAREL].includes(localData?.industry_type)) {
      // If the category is empty, set an error for the category field
      if (!data.category?.length) {
        setError("category", {
          type: "custom",
          message: "Please select the category!",
        });
        return; // Stop further execution if validation fails
      }
    }

    if (data.can_be_sold === "true") {
      if (data.salesPrice === "" || !/^\d+(\.\d+)?$/.test(data.salesPrice)) {
        setError("salesPrice", {
          type: "custom",
          message: data.salesPrice === ""
            ? "Please enter selling price!"
            : "Enter a valid value!",
        });
        return; // Stop further execution if validation fails
      }
    }

    if (data.can_be_purchased === "true") {
      if (data.purchase_price === "" || !/^\d+(\.\d+)?$/.test(data.purchase_price)) {
        setError("purchase_price", {
          type: "custom",
          message: data.purchase_price === ""
            ? "Please enter procurement price!"
            : "Enter a valid value!",
        });
        return; // Stop further execution if validation fails
      }
    }

    setBtnDisable(true);
    if (data !== "") {
      const reqPayload = {
        productType: props.product_type?.prodTypeId,
        productTypename: props.product_type?.productType,
        product_type_id: props.product_type?.uuid,
        category: data.category?.length > 0 ? data.category?.[0]?.categoryname : null,
        category_id: data.category?.length > 0 ? data.category?.[0]?.uuid : null,
        marketgrp: data.marketGroup?.[0]?.grpId,
        market_grp_id: data.marketGroup?.[0]?.uuid,
        prodfamily: data.productFamily?.[0]?.familyId,
        prod_family_id: data.productFamily?.[0]?.uuid,
        // subcategory: data.subCategory,
        productName: data.productName,
        openingstock: data.openingStock,
        hsn: data.hsn.length > 0 ? data.hsn?.[0]?.hsn_sac_code : null,
        hsn_uuid: data.hsn.length > 0 ? data.hsn?.[0]?.uuid : null,
        machineCost: data.machineCost !== "" && data.machineCost !== null ? data.machineCost : "0.00",
        salesPrice: data.can_be_sold == "true" ? data.salesPrice : "0.00",
        can_be_sold: data.can_be_sold,
        can_be_purchased: data.can_be_purchased,
        OEMnumber: data.OEMnumber,
        partNo: data.OEMnumber,
        // can_be_expensed: data.can_be_expensed,
        // beacon_id: data.beacon_id,
        // qr_code_id: data.qr_code_id,
        // selling_price: data.selling_price,
        purchase_price: data.can_be_purchased == "true" ? data.purchase_price : "0.00",
        unit_id: data.unit ? data.unit?.[0]?.uuid : null,
        remark: data.remark,
        // party: [],
        batch_tracking: data.batch_tracking,
        reorder_qty: data.reorder_qty !== null ? data.reorder_qty : 0,
      };

      
      // props.nextStep();
      if (props.main_obj.prod_overview?.uuid) {
        reqPayload.uuid = props.main_obj.prod_overview.uuid;

        updateProduct(reqPayload).then(
          (res) => {
            
            setBtnDisable(false);
            if (res.status === 200 || res.status === 201) {
              if(props.product_type?.productType == MANUFACTURED_GOODS){
                toast.success(
                  "Manufactured goods product saved successfully !",
                  {
                    autoClose: 2000,
                  }
                );
              } else {
                toast.success(
                  "Finished goods product saved successfully !",
                  {
                    autoClose: 2000,
                  }
                );
              }
    
              fetchProdList(res.data.data?.[0]?.product_serializer?.uuid);
            }
          },
          (err) => {
            setBtnDisable(false);
            
            if (err.response.status === 400) {
              
              toast.error(err.response.data.message, {
                autoClose: 2000,
              });
            }
          }
        );
      } else {
        saveProduct(reqPayload).then(
          (res) => {
            
            setBtnDisable(false);

            if (res.status === 200 || res.status === 201) {
              toast.success("Manufactured goods product saved successfully !", {
                autoClose: 3000,
              });
              // fetchProdList(res.data.product.uuid);
              fetchProdList(res.data.result.uuid);
            }
          },
          (err) => {
            setBtnDisable(false);
            
            if (err.response.status === 400) {
              
              toast.error(err.response.data.message, {
                autoClose: 2000,
              });
            }
          }
        );
      }
    } else {
      errors.showMessages();
    }
  };

  function delete_bom(val) {
    
    SweetAlert.fire({
      title: "Are you sure to delete \n" + val.bom_type + " ?",
      // text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteBOM({ uuid: val.uuid }).then(
          (res) => {
            
            toast.success("Bill of material deleted successfully !", {
              autoClose: 2000,
            });
            fetchParticularBoms();
          },
          (err) => {
            
            toast.error("Something went wrong !", {
              autoClose: 2000,
            });
          }
        );
      }
    });
  }

  function fetchParticularBoms() {
    setParticularBomList({ ...particularBomList, data: [], loading: true });
    getBOM({ product_id: props.main_obj?.prod_overview?.uuid }).then(
      (res) => {
        
        setParticularBomList({
          ...particularBomList,
          data: res.data.data,
          loading: false,
        });
      },
      (err) => {
        
        setParticularBomList({
          ...particularBomList,
          data: [],
          loading: false,
        });
      }
    );
  }

  function handleCheckBoxChange(e, stringName){

    const isChecked = e.target.checked;

    if (isChecked) {
      // Make the field required with regex validation
      setError(stringName, {
        type: "manual",
        message: `${stringName == "salesPrice" ? "Selling price" : "Procurement price"} is required.`,
      });
    } else {
      // Only regex validation
      setValue(stringName, "", {shouldValidate: true});
      clearErrors(stringName);
    }
  }

  const handleInputChange = (e, fieldName, regex) => {
    const value = e.target.value;
  
    if (regex.test(value)) {
      // Valid input
      clearErrors(fieldName);
    } else {
      // Invalid input
      setError(fieldName, {
        type: "manual",
        message: "Enter a valid value.",
      });
    }
  };

  function addProductCategory(data){
        if (data !== "") {
          const reqPayload = {
            product_type: Object.keys(props.product_type).length > 0 ? props.product_type?.uuid : null,
            categoryname: data,
          };
          createProdCategory(reqPayload).then(
            (res) => {
              // console.log("res", res);
              if (res.status === 200 || res.status === 201) {
                toast.success("Product category created successfully !", {
                  autoClose: 1000,
                });
                setTimeout(() => {
                  fetchProductCategory();
                }, 1300);
                
                setValue("category", [res.data], { shouldValidate: true });
              }
            },
            (err) => {
              if (err.response.status === 400) {
                toast.error(err.response.data.message, {
                  autoClose: 2000,
                });
                reset();
              }
            }
          );
        } else {
          errors.showMessages();
        }
  }

  function addProductFamily(data){
    if (data !== "") {
      const reqPayload = {
        product_type: Object.keys(props.product_type).length > 0 ? props.product_type?.uuid : null,
        product_category_id: selCategory?.length > 0 ? selCategory?.[0]?.uuid : null,
        familyname: data,
      };
      createProduct_Family(reqPayload).then(
        (res) => {
          // console.log("res", res);
          if (res.status === 200 || res.status === 201) {
            toast.success("Product family created successfully !", {
              autoClose: 1000,
            });
            setTimeout(() => {
              fetchProductFamily();
            }, 1300);
            
            setValue("productFamily", [res.data], { shouldValidate: true });
          }
        },
        (err) => {
          if (err.response.status === 400) {
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
            reset();
          }
        }
      );
    } else {
      errors.showMessages();
    }
  }

  function addTargetSegment(data){
    if (data !== "") {
      const reqPayload = {
        groupname: data,
      };
      createMarketGroup(reqPayload).then(
        (res) => {
          // console.log("res", res);
          if (res.status === 200 || res.status === 201) {
            toast.success("Target segment created successfully !", {
              autoClose: 1000,
            });
            setTimeout(() => {
              fetchProductFamily();
            }, 1300);
            
            setValue("groupname", [res.data], { shouldValidate: true });
          }
        },
        (err) => {
          if (err.response.status === 400) {
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
            reset();
          }
        }
      );
    } else {
      errors.showMessages();
    }
  }

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="card shadow-sm rounded-0 p-2">
        <div className="card-body">
          <p className="pc_card_title roboto-bold">
            {constLabel?.lbl_product_summary
              ? constLabel?.lbl_product_summary
              : "Product Summary"}
          </p>
          <div className="row">
            <div className="col-md-3">
              <div className="form-group typeahead-container mt-md-0 mt-3">
              <label className="lbl-style roboto-bold d-flex align-items-center">
                  {constLabel?.lbl_product_category
                    ? constLabel?.lbl_product_category
                    : "Product Category"}
                 {[FASHION, APPAREL].includes(localData?.industry_type) ? <span className="text-danger"> *</span> : null }
                  <Tooltip id={"open-tooltip1"} place="top" style={{ zIndex: 9999 }}/>
                  <img src={info} alt='info' className='cursor_pointer'
                    data-tooltip-id={"open-tooltip1"}
                    data-tooltip-html="To classify products into distinct categories for easier<br />
                       management, searchability, and reporting within the system."
                    height={20} width={20} />
                    {/* <AddProductCategory fetchProductCategory={fetchProductCategory} type={props.product_type}/> */}
                </label>
                <Controller
                  name="category"
                  control={control}
                  defaultValue={
                    props.main_obj.prod_overview.category !== undefined &&
                      props.main_obj.prod_overview.category !== null
                      ? [props.main_obj.prod_overview?.category_id]
                      : []
                  }
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="categoryname"
                      className="mt-2"
                      multiple={false}
                      options={prodCategory.data}
                      isLoading={prodCategory.loading}
                      placeholder="Select product category"
                      // onChange={onChange}
                      onChange={(selected) => {
                        // If the new option is added
                        const isNewOption = selected && selected[0]?.customOption;
                        if (isNewOption) {
                          const newItem = selected[0]?.categoryname;
                          // Fire API to add the new item
                          addProductCategory(newItem);
                        }
                        onChange(selected);
                      }}
                      newSelectionPrefix="Add New: "
                      allowNew                // Enables adding new options
                      defaultValue={
                        props.main_obj.prod_overview.category !== undefined &&
                          props.main_obj.prod_overview.category !== null
                          ? [props.main_obj.prod_overview?.category_id]
                          : []
                      }
                      selected={value}
                      onBlur={onBlur}
                    // isInvalid={errors.category ? true : false}
                    isInvalid = {[FASHION, APPAREL].includes(localData?.industry_type) ? !!errors.category : null}
                    />
                  )}
                />
                {!prodCategory.loading && !errors.category && <span className="arrow-icon"></span>}
                {[FASHION, APPAREL].includes(localData?.industry_type) && errors.category && (
                  <span className="text-danger err-msg">
                    {errors.category.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group typeahead-container mt-md-0 mt-3">
                <label className="lbl-style roboto-bold d-flex align-items-center">
                  {constLabel?.lbl_target_segment
                    ? constLabel?.lbl_target_segment
                    : "Target Segment"}
                  <span className="text-danger"> *</span>
                  {/* <AddTargetSegment fetchMarketGroup={fetchMarketGroup} /> */}
                </label>
                <Controller
                  name="marketGroup"
                  control={control}
                  defaultValue={
                    props.main_obj.prod_overview.market_grp_id !== undefined &&
                      props.main_obj.prod_overview.market_grp_id !== null
                      ? [props.main_obj.prod_overview?.market_grp_id]
                      : []
                  }
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="groupname"
                      className="mt-2"
                      multiple={false}
                      options={marketGroup.data}
                      isLoading={marketGroup.loading}
                      placeholder="Select target segment"
                      // onChange={onChange}
                      onChange={(selected) => {
                        // If the new option is added
                        const isNewOption = selected && selected[0]?.customOption;
                        if (isNewOption) {
                          const newItem = selected[0]?.groupname;
                          // Fire API to add the new item
                          addTargetSegment(newItem);
                        }
                        onChange(selected);
                      }}
                      newSelectionPrefix="Add New: "
                      allowNew
                      defaultSelected={
                        props.main_obj.prod_overview.market_grp_id !==
                          undefined &&
                          props.main_obj.prod_overview.market_grp_id !== null
                          ? [props.main_obj.prod_overview?.market_grp_id]
                          : []
                      }
                      selected={value}
                      onBlur={onBlur}
                      isInvalid={errors.marketGroup ? true : false}
                    />
                  )}
                />
                {!marketGroup.loading && !errors.marketGroup && <span className="arrow-icon"></span>}
                {errors.marketGroup && (
                  <span className="text-danger err-msg">
                    {errors.marketGroup.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group typeahead-container mt-md-0 mt-3">
              <label className="lbl-style roboto-bold d-flex align-items-center">
                  {constLabel?.lbl_product_family
                    ? constLabel?.lbl_product_family
                    : "Product Family"}
                  <span className="text-danger"> *</span>
                  <Tooltip id={"open-tooltip2"} place="top" style={{ zIndex: 9999 }}/>
                  <img src={info} alt='info' className='cursor_pointer'
                    data-tooltip-id={"open-tooltip2"}
                    data-tooltip-html=" Groups products that share common characteristics, functionalities, or manufacturing processes.<br /> 
                       This field helps categorize products into broader families, such as similar types of finished goods,<br /> 
                       components, or raw materials, enabling more efficient inventory management, reporting, and product<br />
                       lifecycle tracking."
                    height={20} width={20} />
                    {/* <AddProductFamily fetchProductFamily={fetchProductFamily} type={props.product_type} category={selCategory} /> */}
                </label>
                <Controller
                  name="productFamily"
                  control={control}
                  defaultValue={
                    props.main_obj.prod_overview.prod_family_id !== undefined &&
                      props.main_obj.prod_overview.prod_family_id !== null
                      ? [props.main_obj.prod_overview?.prod_family_id]
                      : []
                  }
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="familyname"
                      className="mt-2"
                      multiple={false}
                      options={prodFamily.data}
                      isLoading={prodFamily.loading}
                      placeholder="Select product family"
                      // onChange={onChange}
                      onChange={(selected) => {
                        // If the new option is added
                        const isNewOption = selected && selected[0]?.customOption;
                        if (isNewOption) {
                          const newItem = selected[0]?.familyname;
                          // Fire API to add the new item
                          addProductFamily(newItem);
                        }
                        onChange(selected);
                      }}
                      newSelectionPrefix="Add New: "
                      allowNew
                      defaultSelected={
                        props.main_obj.prod_overview.prod_family_id !==
                          undefined &&
                          props.main_obj.prod_overview.prod_family_id !== null
                          ? [props.main_obj.prod_overview?.prod_family_id]
                          : []
                      }
                      selected={value}
                      onBlur={onBlur}
                      isInvalid={errors.productFamily ? true : false}
                    />
                  )}
                />
                {!prodFamily.loading && !errors.productFamily && <span className="arrow-icon"></span>}
                {errors.productFamily && (
                  <span className="text-danger err-msg">
                    {errors.productFamily.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_product_name
                    ? constLabel?.lbl_product_name
                    : "Product Name"}
                  <span className="text-danger"> *</span>
                </label>
                <input
                  className={
                    errors.productName
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Enter product name"
                  defaultValue={
                    props.main_obj.prod_overview.productName !== null &&
                      props.main_obj.prod_overview.productName !== ""
                      ? props.main_obj.prod_overview.productName
                      : ""
                  }
                  name="productName"
                  {...register("productName")}
                />
                {errors.productName && (
                  <span className="text-danger err-msg">
                    {errors.productName.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3 mt-3">
              <div className="form-group typeahead-container mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_unit_of_measurement ? constLabel?.lbl_unit_of_measurement : "Unit Of Measurement"}
                  <span className="text-danger"> *</span>
                </label>
                <Controller
                  name="unit"
                  control={control}
                  defaultValue={
                    props.main_obj.prod_overview.unit_id !== undefined &&
                      props.main_obj.prod_overview.unit_id !== null
                      ? [props.main_obj.prod_overview?.unit_id]
                      : []
                  }
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="uom"
                      className="mt-2"
                      multiple={false}
                      options={uomList.data}
                      isLoading={uomList.loading}
                      placeholder="Select unit of measurement"
                      onChange={onChange}
                      defaultSelected={
                        props.main_obj.prod_overview.unit_id !== undefined &&
                          props.main_obj.prod_overview.unit_id !== null
                          ? [props.main_obj.prod_overview?.unit_id]
                          : []
                      }
                      selected={value}
                      onBlur={onBlur}
                      isInvalid={errors.unit ? true : false}
                    />
                  )}
                />
                {!uomList.loading && !errors.unit && <span className="arrow-icon"></span>}
                {errors.unit && (
                  <span className="text-danger err-msg">
                    {errors.unit.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_opening_stock
                    ? constLabel?.lbl_opening_stock
                    : "Opening Stock"}{" / "}{selUnit && selUnit[0]?.uom}
                </label>
                <input
                  className="form-control mt-2"
                  type="text"
                  defaultValue={
                    props.main_obj.prod_overview.openingstock !== null &&
                      props.main_obj.prod_overview.openingstock !== ""
                      ? props.main_obj.prod_overview.openingstock
                      : 0
                  }
                  placeholder="Enter opening stock"
                  name="openingStock"
                  {...register("openingStock")}
                />
                {errors.openingStock && (
                  <span className="text-danger err-msg">
                    {errors.openingStock.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_reorder_qty
                    ? constLabel?.lbl_reorder_qty
                    : "Reorder Qty"}
                </label>
                <input
                  className="form-control mt-2"
                  type="text"
                  defaultValue={
                    props.main_obj.prod_overview.reorder_qty !== null &&
                      props.main_obj.prod_overview.reorder_qty !== ""
                      ? props.main_obj.prod_overview.reorder_qty
                      : 0
                  }
                  placeholder="Enter reorder qty"
                  name="reorder_qty"
                  {...register("reorder_qty")}
                />
                {errors.reorder_qty && (
                  <span className="text-danger err-msg">
                    {errors.reorder_qty.message}
                  </span>
                )}
              </div>
            </div>

            {/* <div className="col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_hsn ? constLabel?.lbl_hsn : "HSN"}
                </label>
                <input
                  className="form-control mt-2"
                  type="text"
                  placeholder="Enter hsn"
                  name="hsn"
                  defaultValue={
                    props.main_obj.prod_overview.hsn !== null &&
                      props.main_obj.prod_overview.hsn !== ""
                      ? props.main_obj.prod_overview.hsn
                      : ""
                  }
                  {...register("hsn")}
                />
                {errors.hsn && (
                  <span className="text-danger err-msg">
                    {errors.hsn.message}
                  </span>
                )}
              </div>
            </div> */}

            {/* HSN select box */}
            <div className="col-md-3 mt-3">
              <div className="form-group typeahead-container mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_hsn ? constLabel?.lbl_hsn : "HSN"}
                  {/* <span className="text-danger"> *</span> */}
                  <Tooltip id={"open-tooltip2"} place="top" style={{ zIndex: 9999 }}/>
                  <img src={info} alt='info' className='cursor_pointer'
                    data-tooltip-id={"open-tooltip2"}
                    data-tooltip-html="Enter four digits to search hsn for selection."
                    height={20} width={20} />
                </label>
                <Controller
                  name="hsn"
                  control={control}
                  defaultValue={
                    props.main_obj.prod_overview.hsn_uuid !== undefined &&
                      props.main_obj.prod_overview.hsn_uuid !== null
                      ? [props.main_obj.prod_overview?.hsn_uuid]
                      : []
                  }
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="hsn_sac_code"
                      className="mt-2"
                      multiple={false}
                      options={hsnList.data}
                      isLoading={hsnList.loading}
                      placeholder="Select hsn"
                      onChange={onChange}
                      defaultSelected={
                        props.main_obj.prod_overview.hsn_uuid !== undefined &&
                          props.main_obj.prod_overview.hsn_uuid !== null
                          ? [props.main_obj.prod_overview?.hsn_uuid]
                          : []
                      }
                      selected={value}
                      onBlur={onBlur}
                      // isInvalid={errors.hsn ? true : false}
                      onInputChange={(text) =>
                        setFilterConfig({ ...filterConfig, hsn_sac_code: text })
                      }
                    />
                  )}
                />
                {!hsnList.loading && <span className="arrow-icon"></span>}
                {/* {errors.hsn && (
                  <span className="text-danger err-msg">
                    {errors.hsn.message}
                  </span>
                )} */}
              </div>
            </div>
            {/* HSN select box */}

            <div className="col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_oem_part_no
                    ? constLabel?.lbl_oem_part_no
                    : "OEM Part No"}
                    <Tooltip id={"open-tooltip2"} place="top" style={{ zIndex: 9999 }}/>
                    <img src={info} alt='info' className='cursor_pointer'
                      data-tooltip-id={"open-tooltip2"}
                      data-tooltip-html="This field referred to enter part no, product code, OEM part no."
                      height={20} width={20} />
                </label>
                <input
                  className={
                    errors.OEMnumber
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Enter part no / product code"
                  name="OEMnumber"
                  defaultValue={
                    props.main_obj.prod_overview.OEMnumber !== null &&
                      props.main_obj.prod_overview.OEMnumber !== ""
                      ? props.main_obj.prod_overview.OEMnumber
                      : ""
                  }
                  {...register("OEMnumber")}
                />
                {errors.OEMnumber && (
                  <span className="text-danger err-msg">
                    {errors.OEMnumber.message}
                  </span>
                )}
              </div>
            </div>

            {location.pathname == "/pcms/editProductConfigurator" ? (
              <div className="col-md-3 mt-3">
                <label className="lbl-style roboto-bold invisible">
                  {"Unit"}
                </label>
                <div className="d-flex align-items-center">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultChecked={
                        props.main_obj.obj_bom.flag
                          ? props.main_obj.obj_bom.flag
                          : false
                      }
                      disabled
                      checked={
                        props.main_obj.obj_bom.flag
                          ? props.main_obj.obj_bom.flag
                          : false
                      }
                    // name="can_be_sold"
                    // {...register("can_be_sold")}
                    />
                    <label className="form-check-label lbl-style roboto-bold">
                      {constLabel?.lbl_has_bom
                        ? constLabel?.lbl_has_bom
                        : "Has Bom"}
                    </label>
                  </div>
                  <div className="ml-2">
                    <button
                      onClick={() => {
                        setModal(true);
                        fetchParticularBoms();
                      }}
                      className="btn btn-grey d-block px-3 mt-2"
                    >
                      {constLabel?.lbl_view_bill_of_material
                        ? constLabel?.lbl_view_bill_of_material
                        : "View Bill of Material"}
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="col-md-3">
                <label className="lbl-style roboto-bold invisible">
                  {"Unit"}
                  <span className="text-danger">*</span>
                </label>
                <div className="form-check mt-4">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="has_bom"
                    onChange={(e) => {
                      setCreateBom(e.target.checked);
                    }}
                    checked={createBom}
                  // {...register("has_bom")}
                  />
                  <label className="form-check-label lbl-style roboto-bold">
                    {constLabel?.create_bom
                      ? constLabel?.create_bom
                      : "Create BOM"}
                  </label>
                </div>
              </div>
            )}
          </div>

          <hr className="my-4" />
          <p className="pc_card_title roboto-bold">
            {constLabel?.lbl_product_detail
              ? constLabel?.lbl_product_detail
              : "Product Detail"}
          </p>
          <div className="row col-12">
            <div className="col-md-3 mt-3">
              <label className="lbl-style roboto-bold invisible">
                {constLabel?.lbl_can_be_sold
                  ? constLabel?.lbl_can_be_sold
                  : "Can be Sold"}
              </label>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultChecked={
                    props.main_obj.prod_overview.can_be_sold
                      ? props.main_obj.prod_overview.can_be_sold
                      : false
                  }
                  name="can_be_sold"
                  // onChange={(e) => handleCheckBoxChange(e, "salesPrice")}
                  {...register("can_be_sold", {
                    onChange: (e) => handleCheckBoxChange(e, "salesPrice"),
                  })}
                />
                <label className="form-check-label lbl-style roboto-bold">
                  {constLabel?.lbl_can_be_sold
                    ? constLabel?.lbl_can_be_sold
                    : "Can be Sold"}
                </label>
              </div>
            </div>

            <div className="col-md-3 mt-3">
              <label className="lbl-style roboto-bold invisible">
                {constLabel?.lbl_can_be_pruchase
                  ? constLabel?.lbl_can_be_pruchase
                  : "Can be Purchase"}
              </label>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultChecked={
                    props.main_obj.prod_overview.can_be_purchased
                      ? props.main_obj.prod_overview.can_be_purchased
                      : false
                  }
                  name="can_be_purchased"
                  // {...register("can_be_purchased")}
                  {...register("can_be_purchased", {
                    onChange: (e) => handleCheckBoxChange(e, "purchase_price")
                  })}
                />
                <label className="form-check-label lbl-style roboto-bold">
                  {constLabel?.lbl_can_be_pruchase
                    ? constLabel?.lbl_can_be_pruchase
                    : "Can be Purchase"}
                </label>
              </div>
            </div>

            <div className="col-md-3 mt-3">
              <label className="lbl-style roboto-bold invisible">
                {constLabel?.lbl_batch_tracking
                    ? constLabel?.lbl_batch_tracking
                    : "Batch Tracking"}
              </label>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultChecked={
                    props.main_obj.prod_overview.batch_tracking
                      ? props.main_obj.prod_overview.batch_tracking
                      : false
                  }
                  name="batch_tracking"
                  {...register("batch_tracking")}
                />
                <label className="form-check-label lbl-style roboto-bold">
                  {constLabel?.lbl_batch_tracking
                    ? constLabel?.lbl_batch_tracking
                    : "Batch Tracking"}
                </label>
              </div>
            </div>

            {/* <div className="col-md-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_beacon_id
                    ? constLabel?.lbl_beacon_id
                    : "Beacon Id"}
                </label>
                <input
                  className="form-control mt-2"
                  type="text"
                  defaultValue={
                    props.main_obj.prod_overview.beacon_id !== null &&
                      props.main_obj.prod_overview.beacon_id !== ""
                      ? props.main_obj.prod_overview.beacon_id
                      : ""
                  }
                  placeholder="Enter BeaconId"
                  name="beacon_id"
                  {...register("beacon_id")}
                />
                {errors.beacon_id && (
                  <span className="text-danger err-msg">
                    {errors.beacon_id.message}
                  </span>
                )}
              </div>
            </div> */}

            {/* <div className="col-md-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_qr_code_id
                    ? constLabel?.lbl_qr_code_id
                    : "QR Code Id"}
                </label>
                <input
                  className="form-control mt-2"
                  type="text"
                  defaultValue={
                    props.main_obj.prod_overview.qr_code_id !== null &&
                      props.main_obj.prod_overview.qr_code_id !== ""
                      ? props.main_obj.prod_overview.qr_code_id
                      : ""
                  }
                  placeholder="Enter QRCodeId"
                  name="qr_code_id"
                  {...register("qr_code_id")}
                />
                {errors.qr_code_id && (
                  <span className="text-danger err-msg">
                    {errors.qr_code_id.message}
                  </span>
                )}
              </div>
            </div> */}
          </div>

          <hr className="my-4" />
          <p className="pc_card_title roboto-bold">
            {constLabel?.lbl_product_price
              ? constLabel?.lbl_product_price
              : "Product Price"}
          </p>
          <div className="row col-12">
            <div className="col-md-3">
              <div className="form-group">
                <label className="lbl-style roboto-bold">
                {constLabel?.lbl_cost_of_mfg ? constLabel.lbl_cost_of_mfg : "Cost of Manufacturing"}
                (<span>&#8377;</span>){" / "}{selUnit && selUnit[0]?.uom}
                </label>
                <input
                  className={
                    errors.machineCost
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Enter cost of manufacturing"
                  name="machineCost"
                  defaultValue={
                    props.main_obj.prod_overview.machineCost !== null &&
                      props.main_obj.prod_overview.machineCost !== ""
                      ? props.main_obj.prod_overview.machineCost
                      : "0.00"
                  }
                  {...register("machineCost")}
                />
                {errors.machineCost && (
                  <span className="text-danger err-msg">
                    {errors.machineCost.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_selling_price
                    ? constLabel?.lbl_selling_price
                    : "Selling Price "}
                  {watch("can_be_sold") ? <span className="text-danger"> *</span> : null }
                  (<span> &#8377;</span>){" / "}{selUnit && selUnit[0]?.uom}
                </label>
                <input
                  className={
                    errors.salesPrice
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Enter selling price"
                  name="salesPrice"
                  defaultValue={
                    props.main_obj.prod_overview.salesPrice !== null &&
                      props.main_obj.prod_overview.salesPrice !== ""
                      ? props.main_obj.prod_overview.salesPrice
                      : "0.00"
                  }
                  {...register("salesPrice", {
                    required: watch("can_be_sold") ? "Selling price is required." : false,
                    pattern: {
                      value: /^\d+(\.\d+)?$/,
                      message: "Enter a valid selling price.",
                    },
                  })}
                  onChange={(e) => {
                    handleInputChange(e, "salesPrice", /^\d+(\.\d+)?$/);
                  }}
                />
                {errors.salesPrice && (
                  <span className="text-danger err-msg">
                    {errors.salesPrice.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_procurement_price
                    ? constLabel?.lbl_procurement_price
                    : "Procurement Price "}
                  {watch("can_be_purchased") ? <span className="text-danger"> *</span> : null }
                  (<span>&#8377;</span>){" / "}{selUnit && selUnit[0]?.uom}
                </label>
                <input
                  className={
                    errors.purchase_price
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Enter procurement price"
                  name="purchase_price"
                  defaultValue={
                    props.main_obj.prod_overview.purchase_price !== null &&
                      props.main_obj.prod_overview.purchase_price !== ""
                      ? props.main_obj.prod_overview.purchase_price
                      : "0.00"
                  }
                  {...register("purchase_price", {
                    required: watch("can_be_purchased") ? "Procurement price is required." : false,
                    pattern: {
                      value: /^\d+(\.\d+)?$/,
                      message: "Enter a valid procurement price.",
                    },
                  })}
                  onChange={(e) => {
                    handleInputChange(e, "purchase_price", /^\d+(\.\d+)?$/);
                  }}
                />
                {errors.purchase_price && (
                  <span className="text-danger err-msg">
                    {errors.purchase_price.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_remark ? constLabel?.lbl_remark : "Remark"}
                </label>
                <textarea
                  className="form-control mt-2"
                  id="remark"
                  type="text"
                  defaultValue={
                    props.main_obj.prod_overview.remark !== null &&
                      props.main_obj.prod_overview.remark !== ""
                      ? props.main_obj.prod_overview.remark
                      : ""
                  }
                  placeholder="Enter remark"
                  name="remark"
                  {...register("remark")}
                ></textarea>
              </div>
            </div>
          </div>
          <hr className="my-4" />
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-grey px-4 me-2"
              onClick={() => navigate("/pcms/product")}
            >
              {constLabel?.lbl_cancel ? constLabel?.lbl_cancel : "Cancel"}
            </button>
            <button
              className="btn button-primary px-4 me-2"
              onClick={handleSubmit(onSubmit)}
              disabled={btnDisable}
            // onClick={() => props.setCurrentStep(props.currentStep + 1)}
            >
              {constLabel?.lbl_save_continue
                ? constLabel?.lbl_save_continue
                : "Save & Continue"}
            </button>
            <button
              className="btn btn-grey px-4 me-2"
              disabled={
                (Object.keys(props.main_obj.prod_overview).length > 0 && props.main_obj.prod_overview.hasOwnProperty('uuid'))
                  ? false
                  : true
              }
              onClick={() => props.nextStep()}
            >
              {constLabel?.lbl_skip ? constLabel?.lbl_skip : "Skip"}
            </button>
          </div>
        </div>
      </div>

      <Modal
        show={modal}
        onHide={() => setModal(false)}
        size="xl"
        backdrop="static"
      >
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>{"Bill Of Material List"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Accordion>
            <div className="row prod_bom_accor">
              {particularBomList.loading ? (
                <div className="loader-box justify-content-center">
                  <Spinner />
                </div>
              ) : particularBomList.data.length > 0 ? (
                particularBomList.data.map((val, ind) => (
                  <div className="col-12 col-md-6 mb-3" key={ind}>
                    <Accordion.Item eventKey={ind}>
                      <Accordion.Header className="position-relative">
                        <div className="d-flex justify-content-between align-items-center">
                          {val.bom_type}
                        </div>

                        <Tooltip id={"delete-tooltip" + ind} place="top"/>
                          <Trash2
                            data-tooltip-id={"delete-tooltip" + ind}
                            data-tooltip-content={
                              constLabel?.lbl_delete_bom
                                ? constLabel?.lbl_delete_bom
                                : "Delete BOM"
                            }
                            style={{ marginLeft: "50%" }}
                            className="cursor_pointer text-light"
                            size={20} 
                            onClick={() => delete_bom(val)}
                          />
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="row">
                          <div className="col-12 col-md-8">
                            <p className="mb-1">
                              <span className="roboto-bold ">
                                {constLabel?.lbl_remark
                                  ? constLabel?.lbl_remark
                                  : "Remark"}{" "}
                                :{" "}
                              </span>
                              {`${val.remarks}`}
                            </p>
                          </div>
                          <div className="col-12 col-md-4">
                            <p>
                              <span className="roboto-bold">
                                {constLabel?.lbl_quantity
                                  ? constLabel?.lbl_quantity
                                  : "Quantity"}{" "}
                                :{" "}
                              </span>
                              {`${val.qty}`}
                            </p>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12 col-md-4 roboto-bold">
                            {constLabel?.lbl_product_family
                              ? constLabel?.lbl_product_family
                              : "Product Family"}
                          </div>
                          <div className="col-12 col-md-4 roboto-bold">
                            {constLabel?.lbl_product_name
                              ? constLabel?.lbl_product_name
                              : "Product Name"}
                          </div>
                          <div className="col-12 col-md-2 roboto-bold">
                            {constLabel?.lbl_quantity
                              ? constLabel?.lbl_quantity
                              : "Quantity"}
                          </div>
                          <div className="col-12 col-md-2 roboto-bold">
                            {constLabel?.lbl_unit
                              ? constLabel?.lbl_unit
                              : "Unit"}
                          </div>
                          {val.bom_line_item.map((item, ind) => (
                            <>
                              <div className="col-12 col-md-4">
                                {item.product_family_id !== null
                                  ? item.product_family_id?.familyname
                                  : "NA"}
                              </div>
                              <div className="col-12 col-md-4">
                                {item.productname !== null
                                  ? item.productname
                                  : "NA"}
                              </div>
                              <div className="col-12 col-md-2">{item.qty}</div>
                              <div className="col-12 col-md-2">
                                {item.unitname}
                              </div>
                            </>
                          ))}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </div>
                ))
              ) : (
                <div>
                  <div
                    className="alert alert-danger text-center p-4"
                    role="alert"
                  >
                    {"No Bill Of Material Found"}
                  </div>
                </div>
              )}
            </div>
          </Accordion>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-grey px-4" onClick={() => setModal(false)}>
            {constLabel?.lbl_close ? constLabel?.lbl_close : "Close"}
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default CloneManufacturedGoods;
